import { NewColorTheme } from '../../appConst';
import FaqPanel from './FaqPanel';
import PageHeader from './PageHeader';
import './faq.styles.css'
import { Card, message } from 'antd';

const Faq = () => {
    const [messageApi, contextHolder] = message.useMessage();

    const success = (msg) => {
        messageApi.open({
            type: 'success',
            content: msg,
            style: {
                marginTop: '30vh',
                height: '100vh',
                fontSize: '20px'
            },
            duration: 3
        });
    };

    const error = (msg) => {
        messageApi.open({
            type: 'error',
            content: msg,
            style: {
                marginTop: '30vh',
                height: '100vh',
                fontSize: '20px'
            },
            duration: 3
        });
    };

    const showMessage = (issuccess, msg) => {
        if (issuccess) {
            success(msg);
        } else {
            error(msg);
        }
    }

    return (
        <div style={{ overflow: "hidden" }}>
            {contextHolder}
            <div className="bgSidePanel" style={{ overflow: "hidden", background: NewColorTheme.primaryColor }}></div>
            <div className="sidepanel-left"></div>
            <PageHeader />
            <div style={{ display: 'flex', position: 'absolute', zIndex: 999, top: '12vh', left: '80vw' }}>
                <a href='/inquiries'>
                    <Card>
                        Add Inquiry
                    </Card>
                </a>
            </div>
            <FaqPanel />
        </div>
    );
}

export default Faq