import { Card, Col, FloatButton, Row, Typography, } from 'antd'
import React, { useEffect, useState } from 'react'
import './dashboard4.css'
import NewNavBar from '../Newdashoard3/Components/NewNavBar'
import { Fragment } from 'react';

import { CalendarOutlined } from "@ant-design/icons";

import Chat from '../../../Components/Chat';
import DBMainCard from './Components/DBMainCard';
import ActionCardContent from './Components/ActionCard/ActionCardContent';
import ActionStatus from './Components/ActionStatus/ActionStatus';
import DonorCard from './Components/DonorCard/DonorCard';
import NewInquiryCard from './Components/InquiryCard/NewInquiryCard';
import NewUserRequests from './Components/UserRequests/NewUserRequests';
import UpcomingEvents from './Components/UpcomingEvents/UpcomingEvents';
import NewConfiguration from './Components/Configuration/NewConfiguration';
import UpcomingTasks from './Components/UpcomingTasks/UpcomingTasks';
import { NewColorTheme } from '../../../appConst';





function Newdashboard4() {

    const [user_level, setUserLevel] = useState(null)
    const [currentAgency, setCurrentAgency] = useState()

    useEffect(() => {
        getUserLevel()

    }, [])

    const getUserLevel = async () => {
        const out = await window.localStorage.getItem('ntfc_level')
        console.log("out LEVEL", out)

        setUserLevel(out)
    }
    //Get Agency selected from the Navbar select
    const handleSelectChange = (agency) => {
        console.log('Agency', agency)
        setCurrentAgency(agency)
    }


    return (
        <Fragment>
            <div className='db-main-div' style={{ position: "relative", zIndex: 5 }}>
                <NewNavBar onSelectChange={handleSelectChange} />
                <div className='dash-main-div-new'>

                    <Row gutter={[16, 16]} >
                        <Col xs={24} md={12} lg={12} style={{ height: "88vh" }}>
                            <DBMainCard
                                onClick={(e) => {
                                    e.stopPropagation()
                                    window.location.href = "/tasksactionplan"

                                }}
                                title="Action Plan management"
                                backgroundColor="#b6cbff"
                                titleColor={NewColorTheme.primaryColor}
                                content={<ActionCardContent />}
                            />


                        </Col>
                        <Col xs={24} md={12} lg={5} style={{ height: "88vh" }}>
                            <Row gutter={[0, 16]} style={{ width: "100%" }}>
                                {user_level != 4 &&
                                    <Col xs={24}>
                                        <ActionStatus />
                                    </Col>
                                }

                                <Col xs={24} style={{ height: user_level != 4 ? "260px" : "400px" }} >
                                    <DBMainCard
                                        style={{ padding: 30 }} className='donor-card'
                                        onClick={() => {
                                            window.location.href = "/donormanagement"

                                        }}
                                        title="Donor management"
                                        backgroundColor="#6088ED"
                                        titleColor="#fffff"
                                        cardBackground="#2C66F6"
                                        content={<DonorCard techUser={user_level == 4 ? true : false} />}
                                    />

                                </Col>
                                <Col xs={24} style={{ height: user_level != 4 ? "260px" : "415px" }} >
                                    <DBMainCard
                                        style={{ padding: 30 }} className='donor-card' onClick={() => {
                                            window.location.href = "/inquiries"

                                        }}
                                        title="Inquiry management"
                                        backgroundColor="#CAC7D1"
                                        titleColor="#fffff"
                                        cardBackground="#EFE7E4"
                                        content={<NewInquiryCard techUser={user_level == 4 ? true : false} />}
                                    />

                                </Col>
                                {user_level != 4 &&
                                    <Col xs={24}>
                                        <NewUserRequests />
                                    </Col>
                                }


                            </Row>


                        </Col>
                        <Col xs={24} md={12} lg={7} style={{ height: "60vh" }}>
                            <Row gutter={[0, 16]} style={{ width: "100%" }}>
                                <Col xs={24}>
                                    <DBMainCard
                                        style={{
                                            padding: 20
                                        }}
                                        title="Upcoming Meetings/ Events"
                                        backgroundColor="#b6cbff"
                                        titleColor={NewColorTheme.primaryColor}
                                        content={<UpcomingEvents />}
                                        onClick={() => window.location.href = '/newcalender'}
                                    />
                                </Col>
                                {
                                    (user_level == 6 || user_level == 7) ?
                                        <Col xs={24} style={{ height: "27vh" }}>
                                            <DBMainCard
                                                style={{
                                                    padding: 20
                                                }}
                                                title="Configurations"
                                                backgroundColor="#6C70B0"
                                                titleColor="white"
                                                cardBackground="#3E439E"
                                                content={<NewConfiguration />}
                                            />
                                        </Col>
                                        : user_level == 5 || user_level == 4 ?
                                            <Col xs={24} style={{ height: "37vh" }}>
                                                <DBMainCard
                                                    style={{
                                                        padding: 20
                                                    }}
                                                    title="Upcoming User Tasks"
                                                    backgroundColor="#6C70B0"
                                                    titleColor="white"
                                                    cardBackground="#d6d8ff"
                                                    content={<UpcomingTasks />}
                                                />
                                            </Col>
                                            : null
                                }


                            </Row>


                        </Col>


                    </Row>

                </div>

            </div>
            <Chat />
            {/* <FloatButton
                className='custom-float'
                icon={<CalendarOutlined style={{
                    fontSize: '47px',
                    paddingLeft: '-5px',
                    marginLeft: '-12px',
                    marginBottom: 5
                }} />}
                shape="square"
                type="primary"
                description="Calender"
                style={{
                    right: '190px',
                    height: '100px',
                    width: '100px',
                    padding: '2px'
                }} onClick={() => window.location.href = '/newcalender'} /> */}
        </Fragment>
    )
}

export default Newdashboard4