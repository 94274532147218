import React from 'react'
import './donorv3.css'
import NewNavBar from '../Dashboard/Newdashoard3/Components/NewNavBar'
import { Card, message } from 'antd'
import DbCard from './Components/DBCard/DbCard'
import DonorDBTabs from './DonorDBTabs'
import { useState } from 'react'
import AllDonorRequests from '../DonorsV2/AllDonorRequests'
import MyDonorRequests from '../DonorsV2/MyDonorRequests'
import AllDonorInterests from '../DonorsV2/AllDonorInterests'
import DonerInterestsForProject from '../DonorsV2/DonerInterestsForProjects'
import AgencyInterestsForProject from '../DonorsV2/AgencyInterestsForProjects'

function DonorDashBord() {

    const [messageApi, contextHolder] = message.useMessage();

    const [tab, setTab] = useState(0);
    const [refreshTimestamp, setRefreshTimestamp] = useState('');
    const [openModel1, setOpenModel1] = useState(false)

    const success = (msg) => {
        messageApi.open({
            type: 'success',
            content: msg,
            style: {
                marginTop: '30vh',
                height: '100vh',
                fontSize: '20px'
            },
            duration: 3
        });
    };

    const error = (msg) => {
        messageApi.open({
            type: 'error',
            content: msg,
            style: {
                marginTop: '30vh',
                height: '100vh',
                fontSize: '20px'
            },
            duration: 3
        });
    };

    const showMessage = (issuccess, msg) => {
        if (issuccess) {
            success(msg);
        } else {
            error(msg);
        }
    }

    const handleAddClick = (value) => {
        if (value === 0) {
            setOpenModel1(true)
        }
    }

    return (
        <div className="donor-v3-main-div">
            <NewNavBar />

            <div className="donor-v3-card-container">

                <DonorDBTabs tab={tab} setTab={setTab} refreshTimestamp={refreshTimestamp} addCick={handleAddClick} />

            </div>
            <div className="donor-v3-table-container">
                <Card className='donor-v3-table-card' >
                    {tab === 0 ? <AllDonorRequests showMessage={showMessage} setTab={setTab} setRefreshTimestamp={setRefreshTimestamp} setOpen={openModel1} /> : null}
                    {/* {tab === 1 ? <MyDonorRequests showMessage={showMessage} setTab={setTab} setRefreshTimestamp={setRefreshTimestamp} /> : null} */}
                    {tab === 1 ? <AllDonorInterests showMessage={showMessage} setTab={setTab} setRefreshTimestamp={setRefreshTimestamp} /> : null}
                    {tab === 2 ? <DonerInterestsForProject showMessage={showMessage} setTab={setTab} setRefreshTimestamp={setRefreshTimestamp} /> : null}
                    {/* {tab === 4 ? <AgencyInterestsForProject showMessage={showMessage} setTab={setTab} setRefreshTimestamp={setRefreshTimestamp} /> : null} */}


                </Card>

            </div>


        </div>
    )
}

export default DonorDashBord