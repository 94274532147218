import { Col, Divider, Empty, Progress, Row, Skeleton, Tag, Typography } from 'antd'
import React, { useEffect, useState } from 'react'
import dayjs from 'dayjs';
import { GetAgencyWiseAllArticles } from '../../../../../api';
import './actionCard.css'

function MainCards({ president = false }) {

    const [measures, setMeasures] = useState();
    const [loaded, setLoad] = useState(false);
    const level = window.localStorage.getItem('ntfc_level');

    useEffect(() => {

        getArticles()
    }, []);


    const getArticles = async () => {
        setLoad(false)
        let agency
        if (await window.localStorage.getItem('ntfc_level') == 0 || await window.localStorage.getItem('ntfc_level') == 6 || await window.localStorage.getItem('ntfc_level') == -1 || await window.localStorage.getItem('ntfc_level') == 2) {
            agency = 0
        } else {
            agency = await window.localStorage.getItem('ntfc_agency')
        }


        GetAgencyWiseAllArticles(agency).then((out) => {
            console.log("measures-->>", out)
            let measures = []
            out.data.out.forEach(element => {
                element.measures.forEach(e => {
                    let maesure = {
                        measure_id: e.measure_id,
                        measure_no: e.measure_no,
                        measure: e.measure_summary,
                        def_date: dayjs(e.extended_def_date ? e.extended_def_date : e.measure_def_date).format("DD/MM/YYYY"),
                        category: e.category,
                        measure_completion: e.measure_completion,
                        definitiveDate: e.measure_def_date,
                        article_no: e.article_no,
                        article_id: e.article_id,
                        extented: e.extended_def_date ? true : false
                    }
                    measures.push(maesure)
                })


            });

            const currentDate = new Date();
            const maxDate = new Date();
            maxDate.setDate(currentDate.getDate() + 30);


            const filteredData = measures.filter(obj => {
                const defDate = new Date(obj.definitiveDate);
                return defDate >= currentDate;
            });

            let netSortedArr = filteredData.slice(0, 10).sort((a, b) => {
                const dateA = new Date(a.definitiveDate);
                const dateB = new Date(b.definitiveDate);
                return dateA - dateB;
            })

            setMeasures(netSortedArr)
            setLoad(true)

        })
    }


    function getColor(percentage) {

        if (percentage <= 25) {
            return "#aed8e6"
        }
        if (percentage > 25 && percentage <= 85) {
            return "#0492c2"
        }
        if (percentage > 100) {
            return "#0492c2"
        }

    }

    const TabTile = ({ measure, completion, date, category, key, measure_no, article_id, measure_id, extented }) => <Row

        key={key}
        style={{
            cursor: window.localStorage.getItem("ntfc_level") == 0 ? 'default' : 'pointer'

        }}

        onClick={() =>
            (window.localStorage.getItem("ntfc_level") == 0 || window.localStorage.getItem("ntfc_level") == 2 || window.localStorage.getItem("ntfc_level") == -1) ? null
                :
                window.location.href = `/tasksactionplan?param1=${article_id}&param2=${measure_id}`



        }>
        <Col xs={8} style={{
            textAlign: "left",
            justifyContent: "start",
            alignItems: "start",
            display: "flex",
            flexDirection: "column",
            gap: 3,

        }}>
            <Tag color='blue'>{"Measure" + " " + measure_no}</Tag>
            <Typography style={{ fontFamily: "Figtree", fontSize: 12, textAlign: "justify" }}>{measure}</Typography>

        </Col>
        <Col xs={5} style={{ textAlign: "center", display: "flex", justifyContent: "center", alignItems: "center", }}>
            <Progress
                className='my-progress'
                type="circle"
                strokeColor={getColor(completion)}
                // trailColor={getColor(action.action_completion)}
                percent={completion}
                strokeWidth={10}
                size={40}
            // format={(number) => `进行中，已完成${number}%`}
            />

        </Col>
        {extented ?
            <Col xs={5} style={{ textAlign: "center", display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" }}>
                <Typography style={{ fontSize: 12, whiteSpace: "nowrap" }}>{date} </Typography>
                {extented && <Tag style={{ marginLeft: 8 }} color="volcano">
                    Extended
                </Tag>}
            </Col> :
            <Col xs={5} style={{ textAlign: "center", display: "flex", justifyContent: "center", alignItems: "center" }}>
                <Typography style={{ fontSize: 12, whiteSpace: "nowrap" }}>{date} </Typography>
            </Col>}

        <Col xs={6} style={{ textAlign: "center", display: "flex", justifyContent: "center", alignItems: "center", paddingLeft: 15 }}>
            <Typography>{category === "A" ? <Tag color={"#99d8f5"}>A</Tag> : category === "B" ? <Tag color="#2db7f5">B</Tag> : <Tag color="#108ee9">C</Tag>}
            </Typography>
        </Col>
        <Col span={24}>
            <Divider style={{ margin: "5px 0px" }} />

        </Col>


    </Row>

    return (<>
        {loaded ?
            <div

                style={{
                    width: '100%',
                    marginTop: 3,
                    paddingLeft: '5px',
                    overflow: 'hidden',
                    height: president ? "63vh" : '20vh',
                    padding: ' 0px',
                    // overflowX: "hidden"
                }} >
                {measures.length > 0 ?
                    <div style={{ width: "100%" }}>
                        <Row gutter={[1, 1]}>
                            <Col xs={8} style={{ textAlign: "left" }}>
                                <Typography className="denifitive-table-column-label" style={president ? { fontSize: 12, textAlign: "left", paddingLeft: 7 } : { textAlign: "left", paddingLeft: 7 }}>Measure</Typography>

                            </Col>
                            <Col xs={5} style={{ textAlign: "center", display: "flex", justifyContent: "center" }}>
                                <Typography className="denifitive-table-column-label" style={president ? { fontSize: 12 } : null}>Completion</Typography>
                            </Col>
                            <Col xs={5} style={{ textAlign: "center", display: "flex", justifyContent: "center" }}>
                                <Typography className="denifitive-table-column-label" style={president ? { fontSize: 12 } : null}>Definitive Date</Typography>
                            </Col>
                            <Col xs={6} style={{ textAlign: "center", display: "flex", justifyContent: "center" }}>
                                <Typography className="denifitive-table-column-label" style={president ? { fontSize: 12 } : null}>Category</Typography>
                            </Col>
                            <Col span={24}>
                                <div
                                    className=' user-measure custom-scroll-div' style={{
                                        width: '100%',
                                        paddingTop: '20px',
                                        paddingLeft: '5px',
                                        overflow: 'hidden auto',
                                        height: president ? "63vh" : '20vh',
                                        paddingBottom: '29px',
                                        // borderTop: '3px solid #8f1fe5',
                                        borderRadius: '0px'
                                    }}
                                >
                                    {measures && measures.map((e, k) => {
                                        return <TabTile
                                            key={k}
                                            measure={e.measure}
                                            completion={e.measure_completion}
                                            date={e.def_date}
                                            category={e.category}
                                            measure_no={e.article_no + "." + e.measure_no + '. '}
                                            measure_id={e.measure_id}
                                            article_id={e.article_id}
                                            extented={e.extented}

                                        />
                                    })}
                                </div>
                            </Col>
                        </Row>
                    </div>
                    :
                    <Empty />
                }
            </div> :
            <div style={{ marginTop: 15, }}>
                <Skeleton
                    active
                    avatar
                    paragraph={{
                        rows: 4,
                    }}
                />
            </div>


        }


    </>

    )
}

export default MainCards