import PageHeader from "./PageHeader";
import "./inquiries.styles.css";
import InquiryTabs from "./InquiryTabs";
import { useState, useEffect, useMemo } from "react";
import MyInquiries from "./My";
import AnsweredInquiries from "./Answered";
import { Divider, Drawer, Typography, message } from "antd";
import PendingInquiries from "./Pending";
import FaqPanel from "./FaqPanel";
import { useLocation } from "react-router-dom";
import {
  MergeCellsOutlined,
  NumberOutlined,
  SendOutlined,
  MacCommandOutlined,
  OneToOneOutlined,
  HomeOutlined,
} from "@ant-design/icons";
import { ToastContainer, toast, Zoom } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getUserData } from "../Donors/getCounts";
import { NewColorTheme } from "../../appConst";
const Inquiries = () => {
  const [tab, setTab] = useState(0);
  const [refreshCounts, setRefreshCounts] = useState(false);

  const [messageApi, contextHolder] = message.useMessage();
  const location = useLocation();
  const [open, setOpenDrawer] = useState(false);
  const [user, setUser] = useState({});
  const [drawerList, setList] = useState();

  useMemo(() => {
    if (window.localStorage.getItem('ntfc_level') == 0) {
      window.location.href = "/presidentdashbaord";
    }
  }, []);

  useEffect(() => {
    const this_key = location.pathname.split("/")[2];
    if (!isNaN(this_key)) {
      setTab(parseInt(this_key));
    }
    console.log(this_key);
  }, []);

  const success = (msg) => {
    messageApi.open({
      type: "success",
      content: msg,
      style: {
        marginTop: "30vh",
        height: "100vh",
        fontSize: "20px",
      },
      duration: 3,
    });
  };

  const error = (msg) => {
    messageApi.open({
      type: "error",
      content: msg,
      style: {
        marginTop: "30vh",
        height: "100vh",
        fontSize: "20px",
      },
      duration: 3,
    });
  };

  const showMessage = (issuccess, msg) => {
    if (issuccess) {
      // success(msg);
      toast.success(msg, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    } else {
      // error(msg);
      toast.error(msg, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };

  return (
    <div style={{ overflow: "hidden" }}>
      {contextHolder}
      <div
        className="bgSidePanel"
        style={{ overflow: "hidden", background: NewColorTheme.primaryColor }}
      ></div>
      <div className="sidepanel-left" style={{ background: "white" }}></div>
      <div>
        <PageHeader />
        <div className="mob-hide">
          <InquiryTabs
            tab={tab}
            setTab={setTab}
            setRefreshCounts={setRefreshCounts}
            refreshCounts={refreshCounts}
          />
        </div>
        {tab === 0 ? (
          <FaqPanel
            showMessage={showMessage}
            setTab={setTab}
            setRefreshCounts={setRefreshCounts}
          />
        ) : null}
        {tab === 1 ? (
          <MyInquiries
            showMessage={showMessage}
            setTab={setTab}
            setRefreshCounts={setRefreshCounts}
          />
        ) : null}
        {tab === 2 ? (
          <PendingInquiries
            showMessage={showMessage}
            setTab={setTab}
            setRefreshCounts={setRefreshCounts}
          />
        ) : null}
        {tab === 3 ? (
          <AnsweredInquiries
            showMessage={showMessage}
            setTab={setTab}
            setRefreshCounts={setRefreshCounts}
          />
        ) : null}
      </div>
      <ToastContainer />
    </div>
  );
};

export default Inquiries;
