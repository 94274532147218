import React from 'react';
import ReactECharts from 'echarts-for-react';
import * as echarts from 'echarts/core';
const PresidentDonut = ({ report, lastItem, onClickDonut }) => {
    const getStyle = (type) => {
        if (type == "To Do") {
            return {
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                    { offset: 0, color: '#013e8f' },
                    { offset: 1, color: '#012a58' }
                ])
            }
        } else if (type == "In Progress") {
            return {
                color: new echarts.graphic.LinearGradient(1, 0, 0, 0, [
                    { offset: 0, color: '#007ed7' },
                    { offset: 1, color: '#00b5b7' }
                ])
            }
        } else if (type == "Delayed") {
            return {
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                    { offset: 0, color: '#005fda' },
                    { offset: 1, color: '#0076dc' }
                ])
            }
        } else if (type == "Missed") {
            return {
                color: new echarts.graphic.LinearGradient(0, 0, 1, 0, [
                    { offset: 0, color: '#007ed7' },
                    { offset: 1, color: '#00b5b7' }
                ])
            }
        } else if (type == "Complete") {
            return {
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                    { offset: 0, color: '#007ed7' },
                    { offset: 1, color: '#00b5b7' }
                ])
            }
        }
    }
    let option = {
        tooltip: {
            trigger: 'item',
            confine: true,
            formatter: (params) => {
                const { name, value, percent } = params;
                return `
                  <div >
                    <div><strong>Click to see more ${name} details</strong></div>
                   
                `;
            },
        },

        series: [
            {
                name: 'Action wise implementation percentage',
                type: 'pie',
                radius: ['15%', '50%'],
                avoidLabelOverlap: true,
                itemStyle: {
                    borderRadius: 4,
                    borderColor: '#fff',
                    borderWidth: 1
                },
                label: {
                    margin: '5%',
                    position: "outside",
                    formatter: function (params) {
                        const { name, value } = params;
                        const formattedValue = parseFloat(value).toFixed(2);
                        return `${name}\n${formattedValue}%`;
                    },
                    // minMargin: 1,
                    edgeDistance: "25%",
                    lineHeight: 15,

                    distanceToLabelLine: 0
                },
                // label: {
                //     show: true,
                //     position: 'outside',
                //     formatter: function (params) {
                //         console.log("params]]]]]]]]]]]]]", params)
                //         // if (params.data.value === 0) {
                //         //     return '';
                //         // }
                //         return params.name + " - " + params.data.value?.toFixed(2) + '%';
                //     },
                //     fontSize: 10,
                // },
                emphasis: {
                    label: {
                        show: true,
                        fontSize: 12,
                        fontWeight: 'bold'
                    }
                },
                labelLine: {
                    show: true,
                    // smooth: true,
                    minTurnAngle: 45,
                    maxSurfaceAngle: 45

                },
                color: [
                    '#AED8E6',
                    '#0492C2',
                    '#1520A6',
                    '#2f69b3',
                    '#4169E1',
                    '#d3d3d3'
                ],
                data: report && report ?
                    report.map((element) => ({ ...element, percentage: +`${element.percentage?.toFixed(2)}` }))
                        .map((item) => { return { value: item.percentage, name: item.status, itemStyle: getStyle(item.status) } })
                        .filter((e) => e.value !== 0)

                    : []

            }
        ]
    };
    // Check if all counts are zero
    const allCountsZero = report && report.every((item) => item.count === 0);

    // Add light gray data point if all counts are zero
    if (allCountsZero) {
        option.series[0].data.push({ value: 1, name: 'No Data', itemStyle: { color: '#d3d3d3' } });
    }

    return <ReactECharts style={{ height: "35vh" }} option={option}
        onEvents={{ click: onClickDonut }}
    />;
};

export default PresidentDonut;