import { Button, Form, Input, Space, message, Typography, Select, Card, Row, Col, Checkbox, Modal } from 'antd';
import { BankOutlined, UserOutlined, UserSwitchOutlined, IdcardOutlined, MailOutlined, SettingOutlined } from '@ant-design/icons';
import './signup.styles.css';
import { useEffect, useState } from 'react'; import Lottie from "lottie-react";
import doneAnimation from "./done.json";
import { signup, getAgencies } from "../../api";
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

const { Title, Text } = Typography;


function Page() {
  const [form] = Form.useForm();
  const [requiredMark, setRequiredMarkType] = useState('optional');
  const onRequiredTypeChange = ({ requiredMarkValue }) => {
    setRequiredMarkType(requiredMarkValue);
  };
  const [messageApi, contextHolder] = message.useMessage();

  const success = () => {
    // successDialog()
    messageApi.open({
      type: 'success',
      content: 'User Registration Request Successfull.',
      style: {
        marginTop: '30vh',
        height: '100vh',
        fontSize: '20px'
      },
      duration: 3
    });

    setTimeout(() => {
      form.resetFields();
      window.location.href = "/login"
    }, 1000);

  };

  const [showModal, setShowModal] = useState(false);
  const [agencyName, setAgencyName] = useState(null);
  const [errorMessage, setErrorMessage] = useState(false);


  const error = () => {
    messageApi.open({
      type: 'error',
      content: 'Sign up unsuccessful',
      style: {
        marginTop: '30vh',
        height: '100vh',
        fontSize: '20px'
      },
      duration: 3
    });
  };

  const [pending, setPending] = useState(false);
  const [agencyType, setAgencyType] = useState(4);
  const [submitted, setSubmitted] = useState(false);
  const [agencies, setAgencies] = useState([]);
  const [allAgencies, setAllAgencies] = useState([]);
  const [checked, onChangeCheckBox] = useState(false);
  const [mobile, setMobile] = useState("");
  const [officialMobile, setOfficialMobile] = useState("");

  const onChange = (e) => {
    console.log('checked = ', e.target.checked);
    onChangeCheckBox(e.target.checked);
  };

  const onFinish = async (val) => {
    setPending(true);
    if (!isNaN(val.agency_name)) {
      val.agency_id = val.agency_name;
      val.agency_name = (allAgencies.filter((item) => item.value === val.agency_name))[0].label;
    } else {
      val.agency_id = 9999;
    }
    val.agency_type = agencyType;
    if (!val.user_level) {
      val.user_level = agencyType;
    }
    console.log(val);
    const out = await signup(val);
    if (out) {

      setTimeout(() => {
        setPending(false);
        success();
        setSubmitted(true);
      }, 1000);
    } else {
      setPending(false);
      error();
    }
  }

  const refreshAgencies = (type) => {
    const agencies = allAgencies.filter((ag) => ag.agency_type === type);
    console.log(agencies)
    setAgencies(agencies);
  }

  const updateAgencyType = (input) => {
    setAgencyType(input);
    form.setFieldValue('agency_name', null);
    form.setFieldValue('agency_id', null);
    setAgencyName(null);
    refreshAgencies(input);
  }

  useEffect(() => { getAgencies().then((out) => { setAllAgencies(out.data.out); }) }, []);
  // eslint-disable-next-line
  useEffect(() => { refreshAgencies(1); }, [allAgencies]);



  return (
    <div className="container" style={{ background: `url('/ntfc_background.jpg')`, display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundSize: '100% auto' }}>
      {contextHolder}
      <Card className='no-padding' style={{ backgroundColor: "#fafaff", borderColor: "#fafaff", width: '50vw', padding: 0, borderRadius: 30 }}>
        <Row gutter={16}>
          <Col span={12}>
            <div style={{ padding: 25 }}>
              <img src="/ntfc.png" width={125} alt="logo" />
              <Typography.Title level={3} style={{ color: "#2B52B4", margin: 0 }}>Implementation Progress</Typography.Title>
              <Typography.Title level={3} style={{ color: "#2B52B4", margin: 0 }}>Monitoring Tool</Typography.Title>
              <p style={{ textAlign: 'justify' }}>
                The National Committee on Trade Facilitation (NCTF) in Sri Lanka was established on 3 June 2014 and
                was formalized by the Ministers of Cabinet of the Government of Sri Lanka on 26 April 2016, as
                required by Article 23.2 of the WTO Trade Facilitation Agreement (TFA).
              </p>
              {/* <Button style={{ borderRadius: 30, color: "#2B52B4", border: '2px solid #2B52B4' }} type='default' href='/signup' size='large'>Register Now</Button> */}
            </div>
          </Col>

        </Row>
      </Card>
      <Card className='no-padding'
        style={{
          width: '37vw',
          margin: '4px 0px 12px -350px',
          borderRadius: '30px',
          boxShadow: 'rgba(0, 0, 0, 0.3) -5px 5px 4px'
        }}>
        <Space direction='vertical' className='signup-width' style={{ paddingTop: "10px", width: "100%" }}>
          <Title level={2} style={{ textAlign: "center" }}>Register</Title>
          {!submitted ? <div style={{ textAlign: "center", margin: "-20px 0px 10px 0px" }}><Text type="secondary">Create Your NTFC Account</Text></div> : null}
          <Form
            form={form}
            layout="vertical"
            initialValues={{
              requiredMarkValue: requiredMark,
            }}
            onValuesChange={onRequiredTypeChange}
            requiredMark={requiredMark}
            onFinish={onFinish}
          >
            <Space>
              <Form.Item name="title" required
                rules={[
                  {
                    required: true,
                    message: 'Title is required',
                  }
                ]}>
                <div className="select-custom">
                  <Select
                    placeholder="Title"
                    size='large'
                    style={{ width: '6vw' }}
                    onChange={(value) => form.setFieldValue('title', value)}
                    options={
                      [
                        { value: 'Mr.', label: 'Mr.' },
                        { value: 'Mrs.', label: 'Mrs.' },
                        { value: 'Miss', label: 'Miss' },
                        { value: 'Dr.', label: 'Dr.' }
                      ]
                    }

                  />
                </div>
              </Form.Item>
              <Form.Item name="first_name"
                required rules={[
                  {
                    required: true,
                    message: <p style={{ marginLeft: "12px" }}>First name is required</p>,
                  },
                  {
                    pattern: /^[a-zA-Z]+$/,
                    message: <p style={{ marginLeft: "12px" }}>Please enter a valid first name with alphabetical characters</p>,
                  }
                ]}>
                <Input type='text' placeholder="First Name" style={{ borderRadius: "30px", width: "26vw" }} size="large" suffix={<UserOutlined style={{ color: "#D7D3D5" }} maxLength={10} />} />
              </Form.Item>
            </Space>
            <Space>
              <Form.Item name="last_name" required
                rules={[
                  {
                    required: true,
                    message: <p style={{ marginLeft: "12px" }}>Last Name is required</p>,
                  },
                  {
                    pattern: /^[a-zA-Z]+$/,
                    message: <p style={{ marginLeft: "12px" }}>Please enter a valid last name with alphabetical characters</p>,
                  }
                ]}>
                <Input type='text' placeholder="Last Name" style={{ borderRadius: "30px", width: "32.5vw" }} size="large" suffix={<UserSwitchOutlined style={{ color: "#D7D3D5" }} maxLength={100} />} />
              </Form.Item>
            </Space>
            <Space>
              <Form.Item name='agency_type' required>
                <div className="select-custom">
                  <Select
                    placeholder="Select Agency"
                    size='large'
                    style={{ width: '32.5vw' }}
                    onChange={(value) => { updateAgencyType(value); form.setFieldValue('agency_type', value); }}
                    // defaultValue={4}
                    options={
                      [
                        { value: 1, label: 'Government Sector' },
                        { value: 2, label: 'Private Sector' },
                        { value: 3, label: 'Donor' },
                        { value: 4, label: 'Other' }
                      ]
                    }
                  />
                </div>
              </Form.Item>
            </Space>
            <Space>
              {agencyType !== 4 ? <Form.Item name="agency_name" required
                rules={[{
                  required: true,
                  message: <p style={{ marginLeft: "12px" }}>Agency Name is required</p>,
                },
                { min: 5, message: 'At least 5 characters required' },]}>
                <div className="select-custom">
                  <Select
                    size='large'
                    placeholder="Agency Name"
                    onChange={(value) => { form.setFieldValue('agency_name', value); form.setFieldValue('agency_id', value); setAgencyName(value) }}
                    style={{ width: '32.5vw' }}
                    options={agencies}
                    value={agencyName}
                  />
                </div>
              </Form.Item> : null}
              {agencyType === 4 ? <Form.Item name="agency_name" required
                rules={[{
                  required: true,
                  message: <p style={{ marginLeft: "12px" }}>Agency Name is required</p>,
                },
                { min: 5, message: 'At least 5 characters required' },]}>
                <Input type='text' placeholder="Agency Name" style={{ borderRadius: "30px", width: "32.5vw" }} size="large" suffix={<BankOutlined style={{ color: "#D7D3D5" }} />} />
              </Form.Item> : null}
            </Space>
            <Space>
              <Form.Item name="designation" required
                rules={[{
                  required: true,
                  message: <p style={{ marginLeft: "12px" }}>Designation is required</p>,
                }]}>
                <Input type='text' placeholder="Designation" style={{ borderRadius: "30px", width: "32.5vw" }} size="large" suffix={<IdcardOutlined style={{ color: "#D7D3D5" }} />} />
              </Form.Item>
            </Space>
            <Space>
              {agencyType === 1 ? <Form.Item name="user_level" required
                rules={[{
                  required: true,
                  message: <p style={{ marginLeft: "12px" }}>User Level is required</p>,
                }]}>
                <div className="select-custom">
                  <Select
                    placeholder="User Level"
                    onChange={(value) => { form.setFieldValue('user_level', value); }}
                    size="large"
                    style={{ width: '32.5vw' }}
                    options={
                      [
                        { value: '5', label: 'Head of Agency' },
                        { value: '4', label: 'Technical User' },

                      ]
                    }
                  />
                </div>
              </Form.Item> : null}
            </Space>
            <Space>
              <Form.Item name="email" required
                rules={[{
                  type: 'email',
                  message: <p style={{ marginLeft: "12px" }}>Invalid Email</p>,
                },
                {
                  required: true,
                  message: <p style={{ marginLeft: "12px" }}>Email is required</p>,
                },]}>
                <Input type='email' placeholder="Email" style={{ borderRadius: "30px", width: "32.5vw" }} size="large" suffix={<MailOutlined style={{ color: "#D7D3D5" }} />} />
              </Form.Item>
            </Space>
            <Space>
              <Form.Item name="mobile" required
                rules={[
                  {
                    required: true,
                    message: <p style={{ marginLeft: "12px" }}>Mobile Number is required</p>,
                  },
                  {
                    pattern: /^(?!0)\d+$/,
                    message: <p style={{ marginLeft: "12px" }}>Invalid Country Code</p>,
                  }
                ]}
              >
                <PhoneInput
                  country={'lk'}
                  value={mobile}
                  onChange={setMobile}
                  placeholder="Mobile Number"
                  inputStyle={{ width: "32.5vw", borderRadius: "30px" }}
                  className="select-custom-pn"
                  inputProps={{
                    style: { width: '32.5vw', borderRadius: '30px' },
                  }}
                  disableInitialCountryGuess={false}
                />
              </Form.Item>
              <br />
            </Space>
            <Space>
              <Form.Item name="phone"
                rules={[
                  {
                    pattern: /^(?!0)\d+$/,
                    message: <p style={{ marginLeft: "12px" }}>Invalid Country Code</p>,
                  }
                ]}
              >
                <PhoneInput
                  country={'lk'}
                  value={officialMobile}
                  onChange={setOfficialMobile}
                  placeholder="Official Mobile Number"
                  inputStyle={{ width: "32.5vw", borderRadius: "30px" }}
                  className="select-custom-pn"
                  inputProps={{
                    placeholder: 'Official Mobile Number',
                    style: { width: '32.5vw', borderRadius: '30px' },
                  }}
                  disableInitialCountryGuess={false}
                />
              </Form.Item>
            </Space>
            <Form.Item>
              <Checkbox onChange={onChange}>I have read the privacy policy and I have agreed to the terms and conditions.</Checkbox>
            </Form.Item>
            <Form.Item>
              <Space>
                <Button disabled={checked ? false : true} type='primary' htmlType="submit" loading={pending} shape="round" size="large"><b>Create Account</b></Button>
                {!pending ? <a href='/login' style={{ marginLeft: "110px" }}><b>Already have an account?</b></a> : null}
              </Space>
            </Form.Item>
          </Form>
        </Space>

      </Card>




    </div>
  );
}

export default Page;
