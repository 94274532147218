import {
  Form,
  Divider,
  Button,
  Card,
  Col,
  FloatButton,
  Image,
  Row,
  Select,
  Spin,
  Tooltip,
  Typography,
  Space,
  Modal,
  Tree,
  Collapse,
  Tag,
  Tabs,
  Badge,
  Radio,
  Segmented,
} from "antd";
import React, { useState, useEffect } from "react";
import "./newdashboard.styles.css";
import UserDetails from "../../../Components/DashBoard/UserDetails";
import {
  DownOutlined,
  ToolOutlined,
  ControlOutlined,
  DeploymentUnitOutlined,
  BankOutlined,
} from "@ant-design/icons";
import {
  getUserById,
  GetAgencyWiseAllArticles,
  getCountryWiseDashboard,
  getAgencyWiseDashboard,
  getAgencies,
  GetArticleCompletionGraph,
  GetDonorGraphData,
  GetCompletionGraphData,
} from "../../../api";
import MainCards from "./Components/MainCards";
import DonorGraphNew from "./Components/DonorGraphNew";
import HBar from "../Agency/HBar";
import PageHeader from "../../Inquiries/PageHeader";
import ProgressChart from "../../../Components/DashBoard/ProgressChart";
import CompletionDonut from "../Agency/CompletionDonut";
import DonorGraph from "../Agency/DonorGraph";
import MeasureCompletionBar from "../Agency/MeasureCompletionBar";
import Donut from "../Country/Donut";
import StatDashBoarCard from "../../Donors/components/StatDashBoarCard";
import dayjs from "dayjs";
import DonutForPopup from "./Components/DonutForPopup";
import { NewColorTheme } from "../../../appConst";
import ArticleCompletionProgressCharts from "../NewDashbaord4/Components/ActionCard/ArticleCompletionProgressCharts";
import { ToastContainer, toast, Zoom, Slide } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SummaryDonut from "./Components/SummaryDonut";
import MeasureWiseDonut from "./Components/MeasureWiseDonut";

const { Panel } = Collapse;


// import Donut from '../Agency/Donut';

const myTheme = {
  dashBordBack: "#005476",
};
// Configuration floating buttons
const configurations = [
  {
    ToolTipColor: "cyan",
    ToolTipTitle: "Setup Action Plan",
    icon: <ToolOutlined />,
    url: `/actionplan`,
  },
  {
    ToolTipColor: "cyan",
    ToolTipTitle: "Setup Actions",
    icon: <ControlOutlined />,
    url: `/add-actions`,
  },
  {
    ToolTipColor: "cyan",
    ToolTipTitle: "Setup Measures",
    icon: <DeploymentUnitOutlined />,
    url: `/add-measures`,
  },

  {
    ToolTipColor: "cyan",
    ToolTipTitle: "Setup Articles",
    icon: <ControlOutlined />,
    url: `/add-articles`,
  },
  {
    ToolTipColor: "cyan",
    ToolTipTitle: "Setup Agencies",
    icon: <BankOutlined />,
    url: `/add-agencies`,
  },
];
let userDesignation = "";
let userDashboardName = "";

export const NewDashboard2 = () => {
  const [form] = Form.useForm();
  const [userLevel, setUserLevel] = useState(null);
  const [userData, setUserData] = useState(null);
  const [reports, setReports] = useState(null);
  const [Donutloaded, setDonut] = useState(true);
  const [agencies, setAgencies] = useState();
  const [selectedAgency, setSelectedAgency] = useState(null);
  const [articleCompletion, setArticleCompletion] = useState([]);
  const [summaryType, setSummaryType] = useState('Measure Wise');

  useEffect(() => {
    getUserData();
    loadData();
  }, []);

  const loadData = async () => {
    let agency = await window.localStorage.getItem("ntfc_agency");
    let level = await window.localStorage.getItem("ntfc_level");
    getAgencies().then((out) => {
      out.data.out.unshift({
        label: "All Country Wise",
        value: 0,
        agency_type: 1,
      });

      let filteredAgency = out.data.out.filter((e) => e.value == agency)[0];
      console.log("filteredAgency ---------->>>", out.data.out);
      if (Number(level) == 5 || Number(level) == 4) {
        let filterer = out.data.out.filter((e) => e.value == agency);
        filterer.unshift({
          label: "All Country Wise",
          value: 0,
          agency_type: 1,
        });

        setAgencies(filterer);
        form.setFieldValue("agency", filteredAgency);
        setSelectedAgencyDonut(filteredAgency.value);
        setSelectedAgency(filteredAgency.value);

        getGraphData(filteredAgency.value);
      } else {
        setAgencies(out.data.out);

        form.setFieldValue("agency", out.data.out[0]);

        setSelectedAgencyDonut(out.data.out[0].value);

        setSelectedAgency(0);

        getGraphData(0);
      }
    });
  };

  const getGraphData = async (agency) => {
    // Articles
    GetAgencyWiseAllArticles(agency).then((out) => {
      setArticleCompletion(out.data.out);

    });
  };

  useEffect(() => {
    if (selectedAgency !== null) {
      getGraphData(selectedAgency);
    }
  }, [selectedAgency]);



  const setSelectedAgencyDonut = async (val) => {
    setDonut(false);

    if (val.value === 0) {
      getCountryWiseDashboard({}).then((out) => {
        setReports(out.data.data);
        setDonut(true);
      });
    } else {
      getAgencyWiseDashboard(val, {}).then((out) => {
        setReports(out.data.data);
        setDonut(true);
      });
    }
  };


  const getUserData = async function () {
    setUserLevel(await window.localStorage.getItem("ntfc_level"));

    const user = await getUserById(
      await window.localStorage.getItem("user_id")
    );

    if (user.status === 200) {
      setUserData(user.data.user);

      if (user.data.user.user_level === 7) {
        userDesignation = "Super Admin";
        userDashboardName = "Dashboard - NTFC Secretariat ";
        // myTheme.dashBordBack = "#005476"
      } else if (user.data.user.user_level === 6) {
        userDesignation = "Secretariat";
        userDashboardName = "Dashboard - NTFC Secretariat";
        // myTheme.dashBordBack = "#005476"
      } else if (user.data.user.user_level === 5) {
        userDesignation = "Head of the Department";
        userDashboardName =
          "Dashboard - " +
          (user.data.user.agency_name ? user.data.user.agency_name : "N/A");
      } else if (user.data.user.user_level === 4) {
        userDesignation = "Technical User";
        userDashboardName =
          "Dashboard - " +
          (user.data.user.agency_name ? user.data.user.agency_name : "N/A");
      } else if (user.data.user.user_level === 3) {
        // userDesignation = "Private"
        userDesignation = "Donor";
        userDashboardName =
          "Dashboard - " +
          (user.data.user.agency_name ? user.data.user.agency_name : "N/A");
      } else if (user.data.user.user_level === 2) {
        userDesignation = "Private Sector User";
        userDashboardName =
          "Dashboard -  " +
          (user.data.user.agency_name ? user.data.user.agency_name : "N/A");
      } else if (user.data.user.user_level === 1) {
        userDesignation = "Director General";
        userDashboardName =
          "Dashboard - " +
          (user.data.user.agency_name ? user.data.user.agency_name : "N/A");
      } else if (user.data.user.user_level === -1) {
        userDesignation = "Other";
        userDashboardName =
          "Dashboard - " +
          (user.data.user.agency_name ? user.data.user.agency_name : "N/A");
      }
    }
  };

  const [user, setUser] = useState({});
  const [loaded, setLoad] = useState(false);


  const getUserDetails = async function () {
    const user = await getUserData();
    console.log(user);
    setUser(user);
    setLoad(true);
  };
  useEffect(() => {
    getUserDetails();
  }, []);


  return (
    <div>
      {/* <div className="bgSidePanel" style={{ background: 'rgba(0, 84, 118, 0.3)' }}></div> */}
      <div
        className="bgSidePanel"
        style={{ overflow: "hidden", background: NewColorTheme.primaryColor }}
      ></div>
      <div className="sidepanel-left" style={{ background: "white" }}></div>
      <div>
        {/* <PageHeader dashboard={true} title={userDashboardName} /> */}
        <PageHeader title="Summary" user={user} />
        {/* <MainCards userLevel={userLevel} report={reports} /> */}

        <div
          style={{
            padding: "0 20px 0px 40px",
          }}
        >
          <Row gutter={[16, 16]}>
            {window.localStorage.getItem("ntfc_level") != -1 && (
              <Col xs={24}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    width: "100%",
                  }}
                >
                  <Select
                    onChange={(e) => {
                      console.log("seleceetedd", e);
                      setSelectedAgency(e);
                      setSelectedAgencyDonut(e);
                    }}
                    style={{ width: 500 }}
                    placeholder="Select Agency"
                    options={agencies?.filter(
                      (agency) => agency.agency_type === 1
                    )}
                    value={selectedAgency}
                  />
                </div>
              </Col>
            )}

            <Col xs={24}>
              <Row gutter={[16, 16]} align={"stretch"}>
                <Col lg={11} xs={24}>
                  <StatDashBoarCard
                    title={"Completion percentage"}
                    titleBackColor={"#EA6A21"}
                    content={
                      <>
                        <div style={{ width: '50%', margin: 'auto' }}>
                          <Segmented block options={['Measure Wise', 'Action Wise']} onChange={(val) => { setSummaryType(val); }} />
                        </div>
                        {summaryType == 'Measure Wise' ?
                          <MeasureWiseDonut val={selectedAgency} /> : <SummaryDonut val={selectedAgency} />}
                      </>
                    }
                  />
                </Col>
                <Col lg={13} xs={24}>
                  <StatDashBoarCard
                    title={"TFA Article Completion status"}
                    titleBackColor={"#009EF7"}
                    content={
                      <div>
                        <ArticleCompletionProgressCharts val={selectedAgency} />
                      </div>

                    }
                  />
                </Col>
                {selectedAgency == 0 && (
                  <Col xs={24}>
                    <StatDashBoarCard
                      title={""}
                      titleBackColor={"#00A711"}
                      content={
                        <HBar
                          report={
                            reports && reports.length > 0
                              ? reports.filter(
                                (e) =>
                                  e.report_name == "Measure Status Summary"
                              )[0]
                              : null
                          }
                        />
                      }
                    />
                  </Col>
                )}
              </Row>
            </Col>
            <Col xs={24}>
              <StatDashBoarCard
                title={"Article wise donor assistance distribution for each measure"}
                titleBackColor={"#F1416C"}
                content={<DonorGraphNew val={selectedAgency} />}
              />
            </Col>
            <Col lg={14} xs={24}>
              <StatDashBoarCard
                title={"Measure wise action percentage for each status"}
                titleBackColor={"#FF9900"}
                content={<MeasureCompletionBar val={selectedAgency} />}
              />
            </Col>
            <Col lg={10} xs={24}>
              <StatDashBoarCard
                title={"Action Plan - Upcoming Definitive Dates"}
                titleBackColor={"#8f1fe5"}
                content={<MainCards userLevel={userLevel} report={reports} val={selectedAgency} />}
              />
            </Col>
          </Row>


        </div>


      </div>


    </div >
  );
};
