import React, { useEffect, useState } from 'react'
import { CalenderColors } from '../../../../../appConst'
import { Button, Card, Col, Row, Skeleton, Tag, Typography } from 'antd'
import { BuildOutlined, RocketOutlined, BlockOutlined, AppstoreOutlined } from '@ant-design/icons';
import { BiCameraMovie } from "react-icons/bi";
import dayjs from 'dayjs';
import { getSchedules } from '../../../../../api';

function UpcomingEvents() {

    const [upcomingEvents, setUpcomingEvents] = useState()
    const [todoList, setTodoList] = useState()


    useEffect(() => {
        getEvents()
    }, [])


    const getEvents = async function (actionTasks) {
        const currentDate = dayjs(); // Get the current date
        const dateAfter30Days = currentDate.add(15, 'day').format('DD/MM/YYYY');
        let dateRange = {
            "from_date": currentDate.format('DD/MM/YYYY'),
            "to_date": dateAfter30Days
        }

        const out = await getSchedules(dateRange)


        const newArr = out.data.data.flatMap((element) => {
            const startDate = dayjs(element.from_date).format("YYYY-MM-DD")
            const endDate = dayjs(element.to_date).format("YYYY-MM-DD")
            const startTime = dayjs((element.from_time == 0 ? "0000" : element.from_time.toString().padStart(4, '0').toString()), 'HHmm').format('HH:mm')
            const endTime = dayjs((element.to_time == 0 ? "0000" : element.to_time.toString().padStart(4, '0').toString()), 'HHmm').format('HH:mm')
            const startDateISO = new Date(`${startDate}T${startTime}:00`).toISOString();
            const endDateISO = new Date(`${endDate}T${endTime}:00`).toISOString();


            const eventObj = {
                extendedProps: {
                    id: element.id,
                    type: getType(element.type),
                    participants: element.participants ? element.participants : [],
                    description: element.description ? element.description : "",
                    url: element.url,
                    from_time: dayjs((element.from_time == 0 ? "0000" : element.from_time.toString().padStart(4, '0').toString()), 'HHmm').format('HH:mm'),
                    to_time: dayjs((element.to_time == 0 ? "0000" : element.to_time.toString().padStart(4, '0').toString()), 'HHmm').format('HH:mm'),
                    to_date: dayjs(element.to_date).format("YYYY-MM-DD"),
                    from_date: dayjs(element.from_date).format("YYYY-MM-DD"),
                    comments: element.comments ? element.comments : [],
                    created_by: element.created_by ? element.created_by : "",
                    backgroundColor: getColor(element.type),
                    borderColor: "white",
                },
                end: endDateISO,
                title: element.title,
                start: startDateISO,
                backgroundColor: getColor(element.type),
                borderColor: "white",
                allDay: false

            }

            return eventObj;
        })
        let netSortedArr = newArr.sort((a, b) => {
            const dateA = new Date(a.start);
            const dateB = new Date(b.start);
            return dateA - dateB;
        })

        setTodoList(netSortedArr)



    }


    const getType = (type) => {


        if (type == 0) {
            return "Meeting"
        } else if (type == 1) {
            return "Event"
        } else if (type == 2) {
            return "To Do"
        }

    }
    const getColor = (type) => {
        if (type === 0) {
            return CalenderColors.MEETING

        } else if (type === 1) {
            return CalenderColors.EVENT

        } else if (type === 2) {

            return CalenderColors.TODO
        }

    }

    const getGradient = (type) => {
        if (type == "Meeting") {
            return `linear-gradient( to right, ${CalenderColors.MEETING}  100%, #ABDCFF 100%)`
        } else if (type == "Event") {
            return `linear-gradient( to right, ${CalenderColors.EVENT}  100%, #F6CEEC 100%)`
        } else if (type == "To Do") {
            return `linear-gradient( 135deg, ${CalenderColors.TODO} 100%, #FEB692 100%)`
        } else if (type == "measure") {
            return `linear-gradient( 135deg, ${CalenderColors.MEASURE}  100%, #81FBB8 100%)`
        } else if (type == "action") {
            return `linear-gradient( 135deg, ${CalenderColors.ACTIONS}  100%, #FDEB71 100%)`
        }
    }
    return (
        <>
            {todoList ?
                <Row gutter={[16, 16]}>

                    {/* To Do List */}
                    <Col xs={24}>

                        <div className={'custom-scroll-div '} style={{
                            width: "100%",
                            height: (window.localStorage.getItem('ntfc_level') == 5 || window.localStorage.getItem('ntfc_level') == 4) ? "40vh" : "50vh",
                            overflow: "auto",
                            padding: 7
                        }} >
                            {todoList?.map((element, index) => {

                                return <Card
                                    key={index}
                                    style={{
                                        height: "max-content",
                                        width: "100%",
                                        display: "flex",
                                        // backgroundImage: getGradient(element.extendedProps.type),
                                        // background: getColor(element.extendedProps.type),
                                        // padding: "10px",
                                        margin: "5px 0",
                                        padding: "10px"


                                    }}
                                >
                                    <Row gutter={16}>
                                        <Col xs={3}
                                            style={{
                                                display: "flex",
                                                width: "100%",
                                                // height: "100%",
                                                alignItems: "center",
                                                justifyContent: "center"
                                            }}
                                        >
                                            {
                                                element.extendedProps.type == "action" ?

                                                    <Button type="primary" shape="circle" icon={<RocketOutlined style={{ fontSize: 20, color: "white" }} />}>
                                                    </Button>
                                                    :
                                                    element.extendedProps.type == "measure" ?
                                                        <Button type="primary" shape="circle" icon={<BlockOutlined style={{ fontSize: 20, color: "white" }} />}>
                                                        </Button>


                                                        :
                                                        element.extendedProps.type == "Meeting" ?
                                                            <Button size='large' type="primary" shape="circle" icon={<BiCameraMovie style={{ fontSize: 22, color: "white" }} />}>
                                                            </Button> :
                                                            element.extendedProps.type == "Event" ?
                                                                <Button size='large' type="primary" shape="circle" icon={<AppstoreOutlined style={{ fontSize: 20, color: "white" }} />}>
                                                                </Button>
                                                                :
                                                                <Button size='large' type="primary" shape="circle" icon={<BuildOutlined style={{ fontSize: 22, color: "white" }} />}>
                                                                </Button>

                                            }

                                        </Col>
                                        <Col xs={15}>

                                            <div
                                                style={{
                                                    display: "flex",
                                                    // flexDirection: "column",
                                                    gap: 1

                                                    // width: "100%"
                                                }}
                                            >
                                                {/* <Tag color='yellow' style={{ width: "max-content" }}>{element.extendedProps.type}</Tag> */}
                                                <div>
                                                    <Typography
                                                        style={{
                                                            fontFamily: "Figtree",
                                                            fontSize: 14,
                                                            fontWeight: 600,
                                                            color: "black"
                                                        }}>{element.title}</Typography>
                                                    <Typography
                                                        style={{
                                                            fontFamily: "Figtree",
                                                            fontSize: 12,
                                                            fontWeight: 500,
                                                            color: "#808080"
                                                        }}
                                                    >{element.extendedProps.description}</Typography>
                                                </div>


                                            </div>

                                        </Col>
                                        <Col xs={6}>
                                            <div style={{
                                                display: "flex",
                                                flexDirection: "column",
                                                width: "100%",
                                                justifyContent: "flex-end",
                                                alignItems: 'end'
                                            }}>

                                                <Typography
                                                    style={{
                                                        fontFamily: "Figtree",
                                                        fontSize: 12,
                                                        fontWeight: 500,
                                                        color: "black"
                                                    }}
                                                >{(element.start ? dayjs(element.start).format("DD/MM/YYYY") : "-")}</Typography>
                                                <Typography
                                                    style={{
                                                        fontFamily: "Figtree",
                                                        fontSize: 12,
                                                        fontWeight: 500,
                                                        color: "black"
                                                    }}
                                                >{(element.extendedProps.from_time ? element.extendedProps.from_time + " - " + element.extendedProps.to_time : "-")}</Typography>

                                            </div>

                                        </Col>

                                    </Row>

                                </Card>
                            })}

                        </div>

                    </Col>

                </Row> :

                <Skeleton
                    active
                    avatar
                    paragraph={{
                        rows: 4,
                    }}
                />}


        </>
    )
}

export default UpcomingEvents