import React from 'react';
import ReactECharts from 'echarts-for-react';
import * as echarts from 'echarts/core';
import "./actionCard.css"

const ActionDonut = ({ data }) => {


    let option = {
        tooltip: {
            trigger: 'item'
        },

        series: [
            {
                name: 'Access From',
                type: 'pie',
                radius: ['25%', '95%'],
                center: ['50%', '50%'],
                roseType: 'area',
                avoidLabelOverlap: true,
                itemStyle: {
                    borderRadius: 0,
                    borderColor: '#fff',
                    borderWidth: 0
                },
                label: {
                    show: true,
                    position: 'outside',
                    labelLine: {
                        lineStyle: {
                            color: 'rgba(255, 255, 255, 0.3)'
                        },
                        smooth: 0.2,
                        length: 10,
                        length2: 20
                    },
                },

                emphasis: {
                    label: {
                        show: true,
                        fontSize: 13,
                        fontWeight: 'bold'
                    }
                },
                color: [
                    new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                        { offset: 0, color: '#05f2c3' },
                        { offset: 0.7, color: '#0395C9' },
                        { offset: 1, color: '#027DCE' }
                    ]),
                    new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                        { offset: 0, color: '#2378f7' },
                        { offset: 0.7, color: '#2378f7' },
                        { offset: 1, color: '#83bff6' }
                    ]),
                    new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                        { offset: 0, color: '#05f2c3' },
                        { offset: 0.7, color: '#0395C9' },
                        { offset: 1, color: '#027DCE' }
                    ]),
                    new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                        { offset: 0, color: '#003E8F' },
                        { offset: 0.7, color: '#01397E' },
                        { offset: 1, color: '#012F60' }
                    ]),
                    new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                        { offset: 0, color: '#2375f7' },
                        { offset: 0.7, color: '#1328f7' },
                        { offset: 1, color: '#83b1f6' }
                    ])],

                data: [
                    {
                        value: data.todo, name: ' To Do', itemStyle: {
                            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                                { offset: 0, color: '#013e8f' },
                                { offset: 1, color: '#012a58' }
                            ])
                        }
                    },
                    {
                        value: data.inprogress, name: 'Inprogress', itemStyle: {
                            color: new echarts.graphic.LinearGradient(1, 0, 0, 0, [
                                { offset: 0, color: '#0D6F8F' },
                                { offset: 1, color: '#0D6F8F ' }
                            ])
                        }
                    },
                    {
                        value: data.delayed, name: 'Delayed', itemStyle: {
                            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                                { offset: 0, color: '#005fda' },
                                { offset: 1, color: '#0076dc' }
                            ])
                        }
                    },
                    {
                        value: data.missed, name: 'Missed', itemStyle: {
                            color: new echarts.graphic.LinearGradient(0, 0, 1, 0, [
                                { offset: 0, color: '#04E2B7 ' },
                                { offset: 1, color: '#04E2B7 ' }
                            ])
                        }
                    },
                    {
                        value: data.complete, name: 'Completed', itemStyle: {
                            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                                { offset: 0, color: '#007ed7' },
                                { offset: 1, color: '#00b5b7' }
                            ])
                        }
                    }
                ]
            }
        ]
    };

    return <div style={{ position: 'relative' }}>
        <img src='/target.png' alt='target' className='graph-mid' />
        <ReactECharts style={{ height: "22vh" }} option={option} />
    </div>;
};

export default ActionDonut;