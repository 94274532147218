import { Button, Col, ConfigProvider, Form, Modal, Row, Segmented, Space, Typography } from 'antd';
import { useState } from 'react';
import { CloseOutlined, PlusOutlined } from "@ant-design/icons";
import PersonalChat from './PersonalChat';
import GroupChat from './GroupChat';
import { NewColorTheme } from '../../appConst';
const CreateConversation = ({ refreshChats }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [chatType, setChatType] = useState('Personal');
    const showModal = () => {
        setIsModalOpen(true);
    };
    const handleOk = () => {
        setChatType('Personal')
        setIsModalOpen(false);
    };
    const handleCancel = () => {
        setChatType('Personal')
        setIsModalOpen(false);
        refreshChats();

    };

    return (
        <>
            <div style={{ width: '100%' }}>
                <ConfigProvider
                    theme={{
                        token: {
                            colorPrimary: NewColorTheme.primaryColor,
                            width: "100%"
                        },
                    }}
                >
                    <Button type='primary' style={{ width: '100%' }} size="middle" onClick={showModal}>Create New Conversation</Button>
                </ConfigProvider>
            </div>

            <Modal
                closable={false}

                className="custom-modal"
                // width={700}
                footer={false}
                onCancel={handleCancel}
                title={
                    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                        <div><Typography className='custom-modal-title' level={2}>Create New Conversation</Typography></div>
                        <div>
                            <ConfigProvider
                                theme={{
                                    token: {
                                        colorPrimary: NewColorTheme.primaryColor,
                                    },
                                }}
                            >
                                <Button onClick={handleCancel} icon={<CloseOutlined style={{ color: 'white' }} />} type="primary" shape="circle">
                                </Button>
                            </ConfigProvider>
                        </div>
                    </div>
                } open={isModalOpen} onOk={handleOk} centered>

                <div className='custom-modal-body'>
                    <Space className='custom-scroll-div' direction='vertical' style={{ width: '100%', display: "flex", overflow: 'auto', overflowX: "hidden", padding: "0px 10px" }}>

                        <Row gutter={[16, 16]}>
                            <Col xs={24} >
                                <div style={{ display: "flex", width: "100%", justifyContent: "center", alignItems: "center", flexDirection: 'column' }}>
                                    <ConfigProvider
                                        theme={{
                                            token: {
                                                colorPrimary: NewColorTheme.primaryColor,
                                                colorPrimaryActive: NewColorTheme.primaryColor,
                                            },
                                        }}
                                    >
                                        <Segmented className='cutome-segment' options={['Personal', 'Group']} onChange={(val) => setChatType(val)} />
                                        <div style={{ width: '100%', height: "max-content" }}>
                                            {chatType === 'Personal' ? <PersonalChat closeModal={handleCancel} modal={isModalOpen} /> : null}
                                            {chatType === 'Group' ? <GroupChat closeModal={handleCancel} modal={isModalOpen} /> : null}
                                        </div>
                                    </ConfigProvider>
                                </div>
                            </Col>
                        </Row>

                    </Space>
                </div>
            </Modal>
        </>
    );
};
export default CreateConversation;