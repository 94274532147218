import React, { useState } from 'react'

import DonarUser from '../Donors/components/DonarUser'
import '../Donors/components/dashboarddonors.css'
import { Button, Card, Col, Divider, Row } from 'antd'

import { CameraOutlined } from '@ant-design/icons';
import DashboardCards from '../Donors/components/DashboardCards';
import DonorDashboardCard from '../Donors/components/DonorDashboardCard';
import CustomTable from '../../Components/Table';
import RowData from './RowData';
import DonorInterestRow from './DonorInterestRow';
import DonorTable from './DonorTable';



const DonorInterestProjects = () => {



    return (
        <>
            <div className="bgSidePanel"></div>
            <div className="sidepanel-left"></div>

            <div className="top-dashboard">
                <DonarUser
                    donorUser='Mr. Kalum Sanjaya'
                />
                <div className="divide-line">
                    <Divider orientation="left">
                        <p className='divider-text'>My Interests</p>
                    </Divider>
                </div>
                <div>
                    <Row gutter={16}>
                        <Col span={6}>
                            <DonorDashboardCard
                                cardClass="primaryCard"
                                CardText="Interested Measures"
                                CardVal="5"
                                textClass="primaryFont"
                                valClass="primaryVal"


                            />

                        </Col>
                        <Col span={8}>
                            <DonorDashboardCard
                                style={{ width: "100%" }}
                                cardClass="SecondaryCard2"
                                CardText="Interested Projects"
                                CardVal="5"
                                textClass="secondaryFont2"
                                valClass="secondaryVal2"


                            />

                        </Col>




                    </Row>
                </div>

                <div style={{ width: "65%", margin: "50px 0px" }}>

                    <Card id="donorinterest-card" className='donorinterest-card'>

                        <DonorTable />

                    </Card>
                </div>

            </div>



        </>
    )
}

export default DonorInterestProjects
