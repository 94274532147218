import { Card, Col, Row, Typography } from 'antd'
import React from 'react'
import './donorUserCard.css'
import { useState } from 'react';
import { useEffect } from 'react';
import { GetMyDonorInterests, getDonorCardData, getMyAgencyContributionCreations } from '../../../../../api';
import CountUp from 'react-countup';
import { PlusSquareOutlined, EyeOutlined } from "@ant-design/icons";

function DonorUserActionCard({ user_level, agency }) {

    const [cardData, setCardData] = useState(null)
    const [shouldAnimate, setShouldAnimate] = useState(false);
    const [contributionCount, setContributionCount] = useState(0);
    const [interestCount, setInterestData] = useState(0);


    useEffect(() => {
        setShouldAnimate(true);
        loadData()

    }, [agency])

    const loadData = async () => {
        let agency
        let userLevel = await window.localStorage.getItem('ntfc_level')
        if (userLevel == 6 || userLevel == 7) {
            agency = 0
        } else {
            agency = window.localStorage.getItem('ntfc_agency')
        }

        getDonorCardData(agency).then((out) => {
            console.log("Donor -data", out)
            setCardData(out.data.out)

        });

        getMyAgencyContributionCreations().then((out) => {
            setContributionCount(out.data.out.length)
        })

        GetMyDonorInterests().then((out) => {
            setInterestData(out.data.out.length);
        });

    }

    return (
        <Card style={{ cursor: "pointer" }} className='donor-card'>
            <div className='donor-user-inside'>
                <div className='donor-user-bottom-div-label' onClick={() => {
                    window.location.href = "/donorsdashboard"
                }}>

                    <img src='/donornew.png' alt='donor' className='donor-logo-png-2' />
                    <div style={{ display: "flex", justifyContent: "end", gap: 5, right: "10px", position:"absolute", top:0, marginTop:"10px" }}>
                                <EyeOutlined style={{ fontSize: 25, color: "white" }} />
                            </div>
                </div>
                <div className="Large-tile-items">
                    <Row gutter={[8, 8]}>
                        <Col xs={12}>

                            <Card className="new-db-card2" >
                                <div className='new-donor-linktile'>
                                    <div >
                                        <Typography className='new-db-card-count2'>
                                            {shouldAnimate && <CountUp end={cardData && cardData.requests ? cardData.requests : 0} duration={2} />}

                                        </Typography>
                                    </div>
                                    <div >
                                        <Typography className='new-db-card-title'>
                                            request
                                        </Typography>
                                    </div>

                                </div>


                            </Card>

                        </Col>
                        <Col xs={12}>

                            <Card className="new-db-card2" >
                                <div className='new-donor-linktile'>
                                    <div >
                                        <Typography className='new-db-card-count2'>
                                            {shouldAnimate && <CountUp end={interestCount && interestCount ? interestCount : 0} duration={2} />}

                                        </Typography>
                                    </div>
                                    <div >
                                        <Typography className='new-db-card-title'>
                                            Interests
                                        </Typography>
                                    </div>

                                </div>


                            </Card>

                        </Col>
                        <Col xs={12}>

                            <Card className="new-db-card2" style={{ backgroundColor: " #35B771" }} onClick={() => {
                                window.location.href = "/donorprofile"
                            }}>
                                <div className='new-donor-linktile'>

                                    <div >
                                        <Typography className='new-db-card-profile-title'>
                                            All Donors
                                        </Typography>
                                    </div>

                                </div>


                            </Card>

                        </Col>
                        <Col xs={12}>

                            <Card className="new-db-card2" >
                                <div className='new-donor-linktile'>
                                    <div >
                                        <Typography className='new-db-card-count2'>
                                            {shouldAnimate && <CountUp end={contributionCount && contributionCount ? contributionCount : 0} duration={2} />}

                                        </Typography>
                                    </div>
                                    <div >
                                        <Typography className='new-db-card-title'>
                                            contribution
                                        </Typography>
                                    </div>

                                </div>


                            </Card>

                        </Col>

                    </Row>
                </div>








            </div>


        </Card>
    )
}

export default DonorUserActionCard