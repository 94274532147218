import { Avatar, Badge, Button, Col, Drawer, Typography, Popover, Empty, Row, Space, Tag, Tooltip } from "antd";
import {
    BellOutlined,
    DeploymentUnitOutlined,
    CalendarOutlined
} from "@ant-design/icons";
import { useEffect, useState } from "react";
import { getNotifications, readNotifications } from "../../api";
import { NewColorTheme } from "../../appConst";
import './notification.css'

const Notifications = () => {
    const [unread, setUnread] = useState(0);
    const [messages, setMessages] = useState([]);
    const [open, setOpen] = useState(false);

    const fetchNotifications = () => {
        getNotifications().then((res) => {
            if (res && res.data && res.data.out) {
                setMessages(res.data.out.sort((a, b) => a.is_read - b.is_read));
                setUnread((res.data.out.filter((item) => item.is_read == 0)).length);
            }
        })
    }

    useEffect(() => {
        fetchNotifications();
        setInterval(fetchNotifications, 60000);
    }, []);

    const onClose = () => {
        setOpen(false)

    }

    return <>
        <Badge count={unread}>
            <Tooltip title="Notifications">
                {/* <Popover placement="bottom" title={"Notifications"} content={(
                    messages.map((msg) => {
                        console.log(msg.params);
                        const obj = msg.params;
                        return (<div style={{ display: 'flex' }} onClick={() => {
                            readNotifications({
                                id: msg.id
                            }).then(() => {
                                window.location.href = `/tasksactionplan?param1=${obj.article_id}&param2=${obj.measure_id}`;
                            })
                        }}>
                            <Avatar onClick={() => setOpen(true)} icon={<BellOutlined />} style={!obj.is_read ? {} : { backgroundColor: 'red' }} />
                            <p style={{ margin: 0, paddingLeft: 10, alignItems: 'center' }}>{msg.message}</p>
                        </div>);
                    })
                )} trigger="click"> */}
                <Button
                    className="enlarged-button"
                    onClick={() => {
                        setOpen(true)
                    }}
                    size="small"
                    // shape="circle"
                    icon={
                        <BellOutlined style={{ color: "#282D93" }} />
                    }
                />
                {/* </Popover> */}
            </Tooltip>
        </Badge>
        <Drawer

            title={<span style={{ color: NewColorTheme.primaryColor }}>Notifications</span>}
            placement={"right"}
            width={400}
            onClose={onClose}
            open={open}
            extra={
                <Space>

                    <BellOutlined style={{ color: "#282D93" }} />

                </Space>
            }
        >
            {
                messages?.length > 0 ?
                    messages.map((msg, index) => {
                        console.log("msg=====>>", msg)
                        return (
                            <div style={{
                                display: 'flex',
                                background: msg.is_read != 0 ? "#e4eef5" : "#c7e8ff",
                                height: "max-content",
                                padding: 10,
                                margin: "5px 0px",
                                borderRadius: 10,
                                cursor: "pointer",
                                flexDirection: "column"
                            }}
                                className="enlarged-button2"
                                key={index}
                                onClick={() => {
                                    readNotifications({
                                        id: msg.id
                                    }).then(() => {
                                        if (msg.type == 1) {
                                            window.location.href = `/tasksactionplan?param1=${msg.article_id}&param2=${msg.measure_id}`;

                                        } else if (msg.type == 3) {
                                            window.location.href = `/newcalender`;

                                        }
                                    })
                                }}>


                                <Row gutter={[16, 2]}>

                                    <Col xs={2}>
                                        <Avatar onClick={() => setOpen(true)} icon={msg.type == 1 ? <DeploymentUnitOutlined /> : msg.type == 3 ? <CalendarOutlined /> : <BellOutlined />} style={msg.is_read == 0 ? { backgroundColor: '#55acee', color: "white", } : { backgroundColor: "#86b0cd" }} />
                                    </Col>
                                    <Col xs={22}>
                                        <p style={{ color: NewColorTheme.primaryColor, textAlign: 'justify', paddingLeft: 15 }}>{msg.message}</p>
                                    </Col>
                                    {msg.is_read == 0 &&
                                        <div style={{ width: "100%", justifyContent: "flex-end", display: "flex" }}>
                                            <Tag color="#55acee">
                                                New
                                            </Tag>
                                        </div>
                                    }

                                </Row>


                            </div>);
                    })
                    : <Empty
                        image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
                        imageStyle={{
                            height: 120,
                        }}
                        description={
                            <span>
                                <Typography style={{ fontFamily: "figtree", fontSize: 18, color: "" }}>There are no notifications to display</Typography>
                            </span>
                        }
                    >
                        {/* <Button type="primary">Create Now Conversation</Button> */}
                    </Empty>
            }



        </Drawer>
    </>
}

export default Notifications;