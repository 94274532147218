import { Button, Space, Typography } from 'antd';
import 'react-edit-text/dist/index.css';
import './tasks.styles.css';
import { useEffect, useState } from 'react';
import SideCards from './SideCards';
import MeasureGroup from './MeasureGroup';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { getActionParticipants, getAgency, getAgencyActions } from '../../api';

function Page() {
  const [records, setRecords] = useState([]);

  const [selectedMeasure, setSelectedMeasure] = useState(null);

  const [agencyName, setAgencyName] = useState("");

  const processRecords = async (items) => {
    const recordArr = [];
    for (let i = 0; i < items.length; i++) {
      const article_idx = recordArr.findIndex((item) => item.article_id === items[i].article_id);
      const itemObj = {};
      if (article_idx < 0) {
        // Add new article
        itemObj.article_id = items[i].article_id;
        itemObj.article_no = items[i].article_no;
        itemObj.article = items[i].article;
        itemObj.article_summary = items[i].article_summary;
        itemObj.measures = [
          {
            agency_id: items[i].agency_id,
            measure_id: items[i].measure_id,
            article_no: items[i].article_no,
            article_id: items[i].article_id,
            measure_no: items[i].measure_no,
            measure_name: items[i].measure_name,
            measure_summary: items[i].measure_summary,
            measure_def_date: items[i].measure_def_date,
            category: items[i].category,
            measure_completion: 0,
            actions: [
              {
                agency_id: items[i].agency_id,
                measure_id: items[i].measure_id,
                article_no: items[i].article_no,
                article_id: items[i].article_id,
                measure_no: items[i].measure_no,
                action_id: items[i].action_id,
                action_no: items[i].action_no,
                action: items[i].action,
                action_summary: items[i].action_summary,
                action_def_date: items[i].action_def_date,
                action_completion: items[i].completion,
                action_status: items[i].status
              }
            ]
          }
        ];
        recordArr.push(itemObj);
      } else {
        // override existing article
        const measure_idx = recordArr[article_idx].measures.findIndex((item) => item.measure_id === items[i].measure_id);
        if (measure_idx < 0) {
          recordArr[article_idx].measures.push({
            agency_id: items[i].agency_id,
            measure_id: items[i].measure_id,
            article_no: items[i].article_no,
            article_id: items[i].article_id,
            measure_no: items[i].measure_no,
            measure_name: items[i].measure_name,
            measure_summary: items[i].measure_summary,
            measure_def_date: items[i].measure_def_date,
            category: items[i].category,
            measure_completion: 0,
            actions: [
              {
                agency_id: items[i].agency_id,
                measure_id: items[i].measure_id,
                article_no: items[i].article_no,
                article_id: items[i].article_id,
                measure_no: items[i].measure_no,
                action_id: items[i].action_id,
                action_no: items[i].action_no,
                action: items[i].action,
                action_summary: items[i].action_summary,
                action_def_date: items[i].action_def_date,
                action_completion: items[i].completion,
                action_status: items[i].status
              }
            ]
          });
        } else {
          recordArr[article_idx].measures[measure_idx].actions.push({
            agency_id: items[i].agency_id,
            measure_id: items[i].measure_id,
            article_no: items[i].article_no,
            article_id: items[i].article_id,
            measure_no: items[i].measure_no,
            action_id: items[i].action_id,
            action_no: items[i].action_no,
            action: items[i].action,
            action_summary: items[i].action_summary,
            action_def_date: items[i].action_def_date,
            action_completion: items[i].completion,
            action_status: items[i].status
          })
        }
      }
    }
    for (let i = 0; i < recordArr.length; i++) {
      const measures = recordArr[i].measures;
      for (let j = 0; j < measures.length; j++) {
        recordArr[i].measures[j].measure_completion = ((measures[j].actions.filter((p) => p.action_status === 2)).length / measures[j].actions.length) * 100
      }
    }
    console.log("Restructured Record Arr", recordArr);

    setRecords([...recordArr])
    // getCurrentParticipants(recordArr)
  }
  // Getting each actions current Participants
  const getCurrentParticipants = async (recordArr) => {
    console.log("call");
    let newRecords = []

    for (let record = 0; record < recordArr.length; record++) {
      console.log("record");

      for (let measure = 0; measure < records[record].measures.length; measure++) {
        console.log("measure");
        for (let act = 0; act < records[record].measures[measure].actions.length; act++) {
          console.log("act");
          await getActionParticipants(act.agency_id, act.action_id).then(out => {
            console.log(out.data.out);
            // record[record].measures[measure].actions[act].currentParticipants = out.data.out

          })
        }
      }
      newRecords.push(record)
    }
  }

  useEffect(() => {
    loadData()

  }, []);

  const loadData = async () => {
    getAgencyActions(await window.localStorage.getItem('ntfc_agency'), {}).then((out) => {
      console.log("ntfc_agency", out.data.out);
      processRecords(out.data.out);
    })
    getAgency(await window.localStorage.getItem('ntfc_agency'), {}).then((out) => {
      if (out && out.data && out.data.out && out.data.out.length > 0) {
        setAgencyName(`- ${out.data.out[0].agency_name}`);
      }
    })
  }

  return (
    <div style={{ paddingLeft: 60, paddingTop: 20, paddingRight: 60, paddingBottom: 20 }}>
      <div style={{ display: "flex", justifyContent: "flex-start" }}>
        <Button
          onClick={() => { window.location.href = "/newdashboard" }}
          // disabled={disable ? true : false}
          shape="circle"
          icon={<ArrowLeftOutlined />}
          style={{ width: 50, height: 50, backgroundColor: "white", color: "black", borderRadius: 50 }}
        />
      </div>
      <Space style={{ height: '8vh', width: '100%', borderBottom: '1px solid #e5e5e5' }}>
        <Typography.Title style={{ marginTop: 20 }} level={2}>Actions Plan {agencyName}</Typography.Title>
      </Space>
      <div style={{ display: 'flex', backgroundColor: "#abcdfd8a", margin: "10px 0", borderRadius: "12px" }}>
        <div className='measure-content'>
          {records.map((mes, index) => <SideCards key={index} obj={mes} point={index} color="#a3a3a3" select={setSelectedMeasure}
            selected={selectedMeasure} />)}
        </div>
        <div className='task-content'>
          {/* {records.map((rec) => <Group color={rec.color} title={rec.measure} rec={rec} />)} */}
          <MeasureGroup measure={selectedMeasure} />
        </div>
      </div>
    </div>
  );
}

export default Page;
