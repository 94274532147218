import { Card, Col, Row, Skeleton, Typography } from 'antd'
import React, { useEffect, useState } from 'react'
import { ToolOutlined, ControlOutlined, DeploymentUnitOutlined, BankOutlined, PlusSquareOutlined, EyeOutlined } from "@ant-design/icons";
import { getActionConfigurationCardData } from '../../../../../api';
import '../../dashboard4.css'

// Configuration floating buttons
const configurations = [
    {
        id: "agencies",
        ToolTipColor: "cyan",
        ToolTipTitle: "Agencies",
        icon: <BankOutlined />,
        url: `/add-agencies`,
        className: "config-item-title",
        backgroundColor: "#013578"
    },
    {
        id: "articles",
        ToolTipColor: "cyan",
        ToolTipTitle: "Articles",
        icon: <ControlOutlined />,
        url: `/add-articles`,
        className: "config-item-title",
        backgroundColor: "#005ED8"
    },
    {
        id: "measures",
        ToolTipColor: "cyan",
        ToolTipTitle: "Measures",
        icon: <DeploymentUnitOutlined />,
        url: `/add-measures`,
        className: "config-item-title",
        backgroundColor: "#00A5C2"
    },
    {
        id: "actions",
        ToolTipColor: "cyan",
        ToolTipTitle: "Action ",
        icon: <ControlOutlined />,
        url: `/add-actions`,
        className: "config-item-title",
        backgroundColor: "#0D6F8F"
    },

    {
        id: "actionplan",
        ToolTipColor: "cyan",
        ToolTipTitle: localStorage.getItem('ntfc_level') == 6 ? "View Action Plan" : "Setup Action Plan",
        icon: <ToolOutlined />,
        url: `/actionplan`,
        backgroundColor: "#04E3B7"

    },






]

function NewConfiguration() {
    const [cardData, setCardData] = useState(null)
    const [shouldAnimate, setShouldAnimate] = useState(false);
    const [configButtons, setConfigButtons] = useState()

    useEffect(() => {
        setShouldAnimate(true);
        loadData()

    }, [])

    const loadData = async () => {



        let agency = await window.localStorage.getItem('ntfc_agency')

        getActionConfigurationCardData(0).then((out) => {

            let [agencies, articles, measures, action, actionplan,] = configurations
            action.count = out.data.out.actions
            measures.count = out.data.out.measures
            articles.count = out.data.out.articles
            agencies.count = out.data.out.agencies
            setConfigButtons(configurations)


        });

    }
    return (

        <div style={{
            display: "flex",
            width: "100%",
            padding: '0px 20px'
        }} >
            {configButtons ?
                <Row gutter={[5, 5]} align={"middle"}>
                    {configButtons?.map((e, i) => {
                        return <div

                            onClick={() => {
                                window.location.href = e.url

                            }} style={{
                                cursor: "pointer",
                                backgroundColor: e.backgroundColor,
                                // height: "37px"



                            }}
                            className='new-configuration-five enlarged-button2'
                        >
                            <Col xs={i == configButtons.length - 1 ? 24 : 10}>
                                <Typography style={{
                                    textAlign: i == configButtons.length - 1 ? "center" : "start"
                                }} className={"config-item-title"}>
                                    {e.ToolTipTitle}
                                </Typography>
                            </Col>
                            <Col xs={4}>
                                {i < configButtons.length - 1 &&
                                    <Typography className={"config-item-title"}>
                                        {e.count}
                                    </Typography>}
                            </Col>
                            <Col xs={10} style={{ textAlign: 'end', paddingRight: 15 }}>

                                {i < configButtons.length - 1 &&
                                    window.localStorage.getItem('ntfc_level') == 6 ?
                                    <EyeOutlined style={{ color: "white" }} /> : <PlusSquareOutlined style={{ color: "white" }} />
                                }
                            </Col>




                        </div>
                    })}


                </Row>

                :
                <Skeleton
                    active
                    avatar
                    paragraph={{
                        rows: 4,
                    }}
                />

            }






        </div>


    )
}

export default NewConfiguration