import {
  Button,
  Col,
  ConfigProvider,
  Row,
  Space,
  Table,
  Tag,
  Typography,
  List,
  Avatar,
  Modal,
  Input,
  Switch,
  Tooltip,
} from "antd";
import VirtualList from "rc-virtual-list";
import { useEffect, useState } from "react";
import TabLayout from "./TabLayout";
import { ToastContainer, toast, Zoom } from "react-toastify";
import {
  getAllDonorInterests,
  DownloadFiles,
  AddDonorInterestinterests,
  removeDonorIntereststInterest,
  getMyDonorInterestsInterest,
  unAssignInterestsAgency,
  AssignInterestsAgency,
} from "../../api/index.js";
import moment from "moment";
import {
  PlusOutlined,
  InboxOutlined,
  EyeOutlined,
  CloseOutlined,
  CloudUploadOutlined,
  CalendarOutlined,
  FlagOutlined,
  ContainerOutlined,
  ReconciliationOutlined,
  CalendarFilled,
  FileDoneOutlined,
  BorderlessTableOutlined,
  BoxPlotOutlined,
} from "@ant-design/icons";
import dayjs from "dayjs";
import { BiCloudDownload } from "react-icons/bi";
import { MainColors, NewColorTheme } from "../../appConst";
const AllDonorInterests = ({ showMessage, setRefreshCounts, data, setData }) => {
  const [viewDialog, setViewDialog] = useState(false);
  const [currentRecord, setCurrentRecord] = useState();
  const [this_data, setThis_data] = useState({});
  const [isChair, setChair] = useState(false)
  const handleCancelView = () => {
    setViewDialog(false);
  };

  useEffect(() => {
    if (window.localStorage.getItem('ntfc_level') == 6) {
      setChair(true)
    } else {
      setChair(false)
    }
    reloadData();
  }, []);

  const reloadData = () => {
    getAllDonorInterests().then((res) => {
      var sortedArray = res.data.out.sort(
        (a, b) => new Date(b.created) - new Date(a.created)
      );

      if (
        localStorage.getItem("ntfc_level") == 7 ||
        localStorage.getItem("ntfc_level") == 6
      ) {
        const result = sortedArray.map((item) => {
          if (item.interests.length > 0 && item.assignees.length > 0) {
            return { ...item, checked: true, admin: true, assigned: true };
          } else if (item.interests.length > 0 && item.assignees.length == 0) {
            return { ...item, checked: true, admin: true, assigned: false };
          } else {
            return { ...item, checked: false, admin: true, assigned: false };
          }
        });

        for (let obj of result) {
          let assigneeUserIds = obj.assignees.map((item) => item.user_id);

          for (let interestObj of obj.interests) {
            if (assigneeUserIds.includes(interestObj.user_id)) {
              interestObj.checked = true;
            }
          }
        }

        setData(result);

        console.log("Mapped 2222", result);
      } else {
        getDonorInterestInterest(sortedArray);
      }
    });
  };

  async function getDonorInterestInterest(incomingData) {
    const out = await getMyDonorInterestsInterest();

    const newData = incomingData.map((obj1) => {
      let isChecked = false;
      for (const obj2 of out.data.out) {
        console.log("Mapped", obj1.id, obj2.request_id);
        if (obj1.id === obj2.request_id) {
          isChecked = true;
          break; // Skip to the next obj1 when a match is found
        } else {
          isChecked = false;
        }
      }

      return { ...obj1, checked: isChecked };
    });

    console.log("Mapped", newData);
    setData(newData);
    // setLoad(true)
  }

  const handleModalOk = async (record) => {
    setData((prevData) =>
      prevData.map((item) =>
        item.id === record.id
          ? {
            ...item,
            modalVisible: false,
          }
          : item
      )
    );
    let body = {
      id: record.id,
    };

    if (record.checked) {
      let out = await AddDonorInterestinterests(body);

      if (out.status === 200) {
        toast.success(out.data.message, {
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progressClassName: "toast-progress-bar",
        });
      }
    } else {
      let out = await removeDonorIntereststInterest(body);
      if (out.status === 200) {
        toast.success(out.data.message, {
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progressClassName: "toast-progress-bar",
        });
      }
    }
  };

  const handleModalCancel = (record) => {
    setData((prevData) =>
      prevData.map((item) =>
        item.id === record.id
          ? {
            ...item,

            modalVisible: false,
          }
          : item
      )
    );
    // Revert the switch state back to its original value
    setData((prevData) =>
      prevData.map((item) =>
        item.id === record.id
          ? {
            ...item,
            checked: !item.checked,
          }
          : item
      )
    );
  };

  const handleSwitchChange = (checked, record) => {
    console.log("cehcekdd----------------", checked);
    setData((prevData) =>
      prevData.map((item) =>
        item.id === record.id
          ? {
            ...item,
            checked,
            modalVisible: true,
          }
          : item
      )
    );
  };

  const getStatus = (status) => {
    if (status == 0) {
      return (
        <Tag
          color={"#0492c2"}
          key={status}
          style={{ width: 120, display: "flex", justifyContent: "center" }}
        >
          {"PENDING"}
        </Tag>
      );
    } else if (status == 1) {
      return (
        <Tag
          color={"#007613"}
          key={status}
          style={{ width: 120, display: "flex", justifyContent: "center" }}
        >
          {"PUBLISHED"}
        </Tag>
      );
    } else if (status == 2) {
      return (
        <Tag
          color={"rgb(155, 2, 89)"}
          key={status}
          style={{ width: 120, display: "flex", justifyContent: "center" }}
        >
          {"ASSIGNED"}
        </Tag>
      );
    } else if (status == 999) {
      return (
        <Tag
          color={"#4169E1"}
          key={status}
          style={{ width: 120, display: "flex", justifyContent: "center" }}
        >
          {"REJECTED"}
        </Tag>
      );
    }
  };

  const columns = [
    {
      title: "Created Date",
      dataIndex: "created",
      key: "created",
      render: (_, { created }) => (
        <p>{created ? dayjs(created).format("DD/MM/YYYY") : "-"}</p>
      ),
    },
    {
      title: "Donor",
      dataIndex: "agency_name",
      key: "agency_name",
      ellipsis: true,
    },
    {
      title: "Subject",
      dataIndex: "subject",
      key: "subject",
    },
    // {
    //     title: 'Measure Number',
    //     dataIndex: 'measure_id',
    //     key: 'measure_id',
    // },
    {
      title: "Measure Name",
      dataIndex: "measure_name",
      key: "measure_name",
      ellipsis: true,
      width: 400,
      render: (_, { measure_no, article_no, measure_name, measure_id }) => (
        <p>{measure_id ? (article_no + "." + measure_no + ". " + measure_name) : "-"}</p>
      ),
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      width: 250,
    },

    {
      title: "Support Type",
      dataIndex: "support_type",
      key: "support_type",
      render: (_, { support_type }) => (
        <span
          key={support_type}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "flex-start",
          }}
        >
          {support_type == 0 ? <Tag style={{ display: "flex", justifyContent: "center" }} color={NewColorTheme.primaryColor}>TS/CB</Tag> :
            <Tag style={{ display: "flex", justifyContent: "center" }} color="#08B3D7">OTHER</Tag>}

        </span>
      ),
    },
    {
      title: "Interest Status",
      dataIndex: "description",
      key: "description",
      render: (_, { checked, admin, assigned }) => (
        <div style={{ display: "flex", justifyContent: 'center' }}>
          <Tag
            color={checked ? "#007613" : "#0492c2"}
            key={checked}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "flex-start",
              width: "max-content",
            }}
          >
            {checked && assigned
              ? "Agency Assigned"
              : checked && !assigned
                ? "Agency Interested"
                : "Pending"}
          </Tag>
        </div>
      ),
    },
    {
      title: "Action",
      dataIndex: "publish_reason",
      key: "publish_reason",
      render: (_, record) => (
        <Space size="middle" style={{ display: "flex", justifyContent: 'center' }} >
          <Tooltip title="View">
            <Button
              type="secondary"
              size="small"
              onClick={() => {
                console.log("record", record);
                setCurrentRecord(record);
                setViewDialog(true);
              }}
              shape="circle"
              icon={<EyeOutlined style={{ color: "darkgreen" }} />}
            />
          </Tooltip>
          {/* <Tooltip title="Approve">
                        <Button type="primary" shape="circle" icon={<CheckOutlined />} size={'medium'} style={{ background: record.status === 0 ? '#70D983' : record.status === 2 ? '#70D983' : '#c4c4c4' }}
                            disabled={record.status == 0 ? false : record.status == 2 ? false : true}
                            onClick={() => setApproveDialogOpen(true)} />
                    </Tooltip>
                    <Tooltip title="Reject">
                        <Button type="primary" shape="circle" icon={<CloseOutlined />} size={'medium'} style={{ background: record.status === 0 ? '#FF8282' : record.status === 2 ? '#FF8282' : '#c4c4c4' }}
                            disabled={record.status == 0 ? false : record.status == 2 ? false : true}
                            onClick={() => setRejectDialogOpen(true)} />
                    </Tooltip> */}
        </Space>
      ),
    },
    // {
    //     title: '',
    //     dataIndex: 'action',
    //     key: 'action',
    //     render: (_, record, index) => (

    //         record ?
    //             <div>
    //                 {console.log("recoed", record)}
    //                 <ConfigProvider
    //                     theme={{
    //                         token: {
    //                             colorPrimary: '#5cb2b2'
    //                         },
    //                     }}
    //                 >
    //                     <Switch
    //                         type="primary"
    //                         checked={record.checked}
    //                     onChange={(checked) => handleSwitchChange(checked, record)}
    //                     />
    //                     <Modal
    //                         centered
    //                         title="Confirm"
    //                         open={record.modalVisible}
    //                         onOk={() => handleModalOk(record)}
    //                         onCancel={() => handleModalCancel(record)}

    //                     >
    //                         <p>{record.checked ? "Are you sure to assist this project?" : "Are you sure to remove this project?"}</p>
    //                     </Modal>
    //                 </ConfigProvider>
    //             </div>

    //             : null
    //     ),

    // },
  ];

  const interestSubmit = async () => {
    let body = {
      id: currentRecord.id,
    };

    if (!currentRecord?.checked) {
      let out = await AddDonorInterestinterests(body);

      if (out.status === 200) {
        toast.success(out.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    } else {
      let out2 = await removeDonorIntereststInterest(body);
      if (out2.status === 200) {
        toast.success(out2.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    }

    setViewDialog(false);
    reloadData();
  };
  const FetchFile = async (file) => {
    let out = await DownloadFiles(file.token);

    let blob = new Blob([out.data], { type: file.type });
    let url = window.URL.createObjectURL(blob);

    let link = document.createElement("a");
    link.href = url;

    link.download = file.url.split("-::-")[1];

    document.body.appendChild(link);
    link.click();
  };

  const assignAgency = async (item) => {
    console.log("asdsadsadsadsad", item);
    let data = {
      id: item.request_id,
      agency_id: item.agency_id,
      user_id: item.user_id,
    };
    console.log("data", data);

    let out;
    if (item.checked) {
      out = await unAssignInterestsAgency(data);
    } else {
      out = await AssignInterestsAgency(data);
    }

    console.log("out", out);
    if (out.status === 200) {
      toast.success(out.data.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      reloadData();
      setCurrentRecord({});
    } else {
      toast.error(out.data.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }

    setViewDialog(false);
  };

  return (
    <div className="custom-scroll-div tab-layout-div">
      <TabLayout
        title="View Donors Interest"
        // formSide={
        //   <div>
        //     {currentData.id &&

        //       <div className='list-container' style={{
        //         display: 'flex',
        //         overflowX: 'hidden'
        //       }}>

        //         <Row gutter={[16, 16]}>
        //           <Col span={24}>
        //             <Row style={{ width: '100%' }}>
        //               <Col style={{ width: '30%' }}>
        //                 <Typography.Title level={5}>
        //                   Subject
        //                 </Typography.Title>
        //               </Col>
        //               <Col style={{ paddingTop: '2px' }}>
        //                 <Typography.Paragraph style={{ fontWeight: '500' }}>
        //                   {currentData.subject}
        //                 </Typography.Paragraph>
        //               </Col>
        //             </Row>
        //           </Col>

        //           <Col span={24}>
        //             <Row style={{ width: '100%' }}>
        //               <Col style={{ width: '30%' }}>
        //                 <Typography.Title level={5}>
        //                   Measure
        //                 </Typography.Title>
        //               </Col>
        //               <Col style={{ paddingTop: '2px' }}>
        //                 <Typography.Paragraph style={{ fontWeight: '500' }}>
        //                   {currentData.measure_id && `${currentData.measure_id} - ${currentData.measure_name}`}
        //                 </Typography.Paragraph>
        //               </Col>
        //             </Row>
        //           </Col>

        //           <Col span={24}>
        //             <Row style={{ width: '100%' }}>
        //               <Col style={{ width: '30%' }}>
        //                 <Typography.Title level={5}>
        //                   Support Type
        //                 </Typography.Title>
        //               </Col>
        //               <Col style={{ paddingTop: '2px' }}>
        //                 <Typography.Paragraph style={{ fontWeight: '500' }}>
        //                   {currentData?.support_type == 0 ? "Technical Support" : currentData?.support_type == 1 ? "Capacity Building" : "Other"}
        //                 </Typography.Paragraph>
        //               </Col>
        //             </Row>
        //           </Col>

        //           <Col span={24}>
        //             <Row style={{ width: '100%' }}>
        //               <Col style={{ width: '30%' }}>
        //                 <Typography.Title level={5}>
        //                   Description
        //                 </Typography.Title>
        //               </Col>
        //               <Col style={{ paddingTop: '2px' }}>
        //                 <Typography.Paragraph style={{ fontWeight: '500' }}>
        //                   {currentData.description}
        //                 </Typography.Paragraph>
        //               </Col>
        //             </Row>
        //           </Col>

        //           <Col span={24}>
        //             <Row style={{ width: '100%' }}>
        //               <Col style={{ width: '30%' }}>
        //                 <Typography.Title level={5}>
        //                   Created Date
        //                 </Typography.Title>
        //               </Col>
        //               <Col style={{ paddingTop: '2px' }}>
        //                 <Typography.Paragraph style={{ fontWeight: '500' }}>
        //                   {moment(currentData.created).format("MM/DD/YYYY")}
        //                 </Typography.Paragraph>
        //               </Col>
        //             </Row>
        //           </Col>

        //           <Col span={24}>
        //             <Row style={{ width: '100%' }}>
        //               <Col style={{ width: '30%' }}>
        //                 <Typography.Title level={5}>
        //                   Attachments
        //                 </Typography.Title>
        //               </Col>
        //             </Row>
        //             <Row gstyle={{ width: '100%' }}>
        //               <Col style={{ width: '100%' }}>
        //                 {currentData && currentData.attachments && currentData.attachments.map((file) => {
        //                   return <div onClick={() => fetchFile(file)}>
        //                     {file.type.includes("image") ?
        //                       <img src="/image.png" alt="image" style={{ height: 50, width: 50 }} />
        //                       : <img src="/file.png" alt="file" style={{ height: 50, width: 50 }} />}
        //                     {(file.url.toString().split("-::-"))[1]}
        //                   </div>
        //                 })}
        //               </Col>
        //             </Row>
        //           </Col>

        //           {/* <Col span={24}>
        //                             <Row style={{ width: '100%' }}>
        //                                 <Col style={{ width: '50%' }}>
        //                                     <Typography.Title level={5}>
        //                                         Interested Agencies
        //                                     </Typography.Title>
        //                                 </Col>

        //                                 <Col span={24}>
        //                                     {currentData?.interests?.length > 0 ?
        //                                         <List>
        //                                             <VirtualList
        //                                                 data={currentData.interests}
        //                                                 height={150}
        //                                                 itemHeight={47}
        //                                                 itemKey="id"
        //                                             // onScroll={onScroll}
        //                                             >
        //                                                 {(item, key) => (
        //                                                     <List.Item key={key} style={{ cursor: "pointer" }}>
        //                                                         <List.Item.Meta
        //                                                             avatar={<Avatar src={<img src={'https://gw.alipayobjects.com/zos/rmsportal/KDpgvguMpGfqaHPjicRK.svg'} alt="avatar" />} />}
        //                                                             // title={<p>{item.title}</p>}
        //                                                             title={item.agency_name}
        //                                                             // description={"By " + item.first_name + " " + item.last_name}

        //                                                             description={new Date(item.created).toISOString().slice(8, 10) + "/" + new Date(item.created).toISOString().slice(5, 7)}
        //                                                         />
        //                                                     </List.Item>
        //                                                 )}
        //                                             </VirtualList>
        //                                         </List>
        //                                         : <Typography.Paragraph>
        //                                             There is no Interested Donors for this Project.
        //                                         </Typography.Paragraph>
        //                                     }
        //                                 </Col>

        //                             </Row>
        //                         </Col> */}

        //           <Col span={24}>
        //             {currentData.status == 0 &&
        //               <div style={{ display: "flex", justifyContent: "flex-end" }}>
        //                 <Space >
        //                   {/* <Button danger type="primary" htmlType="button" onClick={publishDonorRequest}> Reject</Button> */}
        //                   {/* <Button loading={btnLoad} onClick={() => setRejectDialogOpen(true)}> Reject</Button>
        //                                         <Button loading={btnLoad} type="primary" onClick={() => setApproveDialogOpen(true)}> Approve</Button> */}
        //                 </Space>
        //               </div>
        //             }
        //           </Col>

        //         </Row>

        //       </div>

        //     }
        //   </div>

        // }
        table={
          <Table
            className="custom-table"
            rowClassName={(record, index) => index % 2 === 0 ? 'white-row' : 'gray-row'}
            size="small"
            columns={columns}
            dataSource={data}
            scroll={{
              y: 400,
            }}
            pagination={{
              pageSize: 5,
              total: data.length,
              showSizeChanger: false,
            }}
            onRow={(record, rowIndex) => {
              return {
                onClick: () => {
                  setCurrentRecord(record);
                  setViewDialog(true);
                },
              };
            }}
          />
        }
      />
      <ToastContainer />
      <Modal
        centered
        className="custom-modal"
        title={
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div>
              {" "}
              <Typography className="custom-modal-title" level={2}>
                Project Details
              </Typography>
            </div>
          </div>
        }
        open={viewDialog}
        width={750}
        onCancel={handleCancelView}
        footer={
          localStorage.getItem("ntfc_level") == 6 ||
            localStorage.getItem("ntfc_level") == 7
            ? false
            : [
              <div style={{ display: "flex", width: "100%" }}>
                <Typography.Title level={4}>
                  {" "}
                  Mark My Interest
                </Typography.Title>
              </div>,
              <Button size="large" onClick={handleCancelView}>
                {" "}
                Cancel
              </Button>,
              <Button
                style={{ backgroundColor: NewColorTheme.primaryColor, color: "white" }}
                size="large"
                type="primary"
                onClick={interestSubmit}
                disabled={window.localStorage.getItem('view_only') == 1}
              >
                {" "}
                {currentRecord?.checked
                  ? "Remove Interest"
                  : "Mark my Interest"}
              </Button>,
            ]
        }
      >
        <div
          className="custom-modal-body custom-scroll-div"
          style={{ height: 500, overflow: "auto" }}
        >
          <Row>
            <Col xs={24}>
              <Row gutter={[8, 8]} align={"top"}>
                <Col xs={24}>
                  <Row gutter={[8, 8]}>
                    <Col xs={2}>
                      <ReconciliationOutlined style={{ fontSize: 22 }} />
                    </Col>
                    <Col xs={22}>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "flex-start",
                        }}
                      >
                        <Typography.Title level={5}>
                          {" "}
                          Subject
                        </Typography.Title>
                        <Typography> {currentRecord?.subject}</Typography>
                      </div>
                    </Col>
                  </Row>
                </Col>
                <Col xs={12}>
                  <Row gutter={[8, 8]} align={"top"}>
                    <Col xs={4}>
                      <CalendarFilled style={{ fontSize: 22 }} />
                    </Col>
                    <Col xs={20}>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "flex-start",
                        }}
                      >
                        <Typography.Title level={5}>
                          {" "}
                          Created Date
                        </Typography.Title>

                        <Typography>
                          {" "}
                          {dayjs(currentRecord?.created).format("DD/MM/YYYY")}
                        </Typography>
                      </div>
                    </Col>
                  </Row>
                </Col>
                {currentRecord?.measure_id &&
                  <Col xs={24}>
                    <Row gutter={[8, 8]} align={"top"}>
                      <Col xs={2}>
                        <BoxPlotOutlined style={{ fontSize: 22 }} />
                      </Col>
                      <Col xs={22}>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "flex-start",
                          }}
                        >
                          <Typography.Title level={5}> Measure</Typography.Title>

                          <Typography>
                            {" "}
                            {currentRecord?.article_no +
                              "." +
                              currentRecord?.measure_no +
                              ". " +
                              currentRecord?.measure_name}
                          </Typography>
                        </div>
                      </Col>
                    </Row>
                  </Col>
                }

                <Col xs={24}>
                  <Row gutter={[8, 8]} align={"top"}>
                    <Col xs={2}>
                      <BorderlessTableOutlined style={{ fontSize: 22 }} />
                    </Col>
                    <Col xs={22}>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "flex-start",
                        }}
                      >
                        <Typography.Title level={5}>
                          {" "}
                          Support Type
                        </Typography.Title>

                        <Typography>
                          {" "}
                          {currentRecord?.support_type == 0
                            ? "Technical Support or Capacity Building"
                            : "Other"}
                        </Typography>
                      </div>
                    </Col>
                  </Row>
                </Col>

                <Col xs={12}>
                  <Row gutter={[8, 8]} align={"top"}>
                    <Col xs={4}>
                      <CalendarOutlined style={{ fontSize: 22 }} />
                    </Col>
                    <Col xs={20}>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "flex-start",
                        }}
                      >
                        <Typography.Title level={5}>
                          {" "}
                          Tentative Start Date
                        </Typography.Title>

                        <Typography>
                          {" "}
                          {currentRecord?.implementation_from ? dayjs(currentRecord?.implementation_from).format(
                            "DD/MM/YYYY"
                          ) : "-"}
                        </Typography>
                      </div>
                    </Col>
                  </Row>
                </Col>

                <Col xs={12}>
                  <Row gutter={[8, 8]} align={"top"}>
                    <Col xs={4}>
                      <CalendarOutlined style={{ fontSize: 22 }} />
                    </Col>
                    <Col xs={20}>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "flex-start",
                        }}
                      >
                        <Typography.Title level={5}>
                          {" "}
                          Tentative End Date
                        </Typography.Title>

                        <Typography>
                          {" "}
                          {currentRecord?.implementation_to ? dayjs(currentRecord?.implementation_to).format(
                            "DD/MM/YYYY"
                          ) : "-"}
                        </Typography>
                      </div>
                    </Col>
                  </Row>
                </Col>
                {/* <Col xs={8}>
                                    <Row gutter={[8, 8]} align={"top"}>
                                        <Col xs={6}>
                                            <CalendarOutlined style={{ fontSize: 22 }} />
                                        </Col>
                                        <Col xs={18}>

                                            <div style={{ display: 'flex', flexDirection: "column", alignItems: 'flex-start' }}>
                                                <Typography.Title level={5}> Published Date</Typography.Title>

                                                <Typography> {
                                                    currentRecord?.published_date ?
                                                        dayjs(currentRecord?.published_date).format("DD/MM/YYYY")
                                                        : "-"
                                                }</Typography>

                                            </div>



                                        </Col>

                                    </Row>


                                </Col> */}
                <Col xs={24}>
                  <Row gutter={[8, 8]} align={"top"}>
                    <Col xs={2}>
                      <ContainerOutlined style={{ fontSize: 22 }} />
                    </Col>
                    <Col xs={22}>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "flex-start",
                        }}
                      >
                        <Typography.Title level={5}>
                          {" "}
                          Description
                        </Typography.Title>

                        <Typography>
                          {" "}
                          {currentRecord?.description
                            ? currentRecord?.description
                            : "-"}
                        </Typography>
                      </div>
                    </Col>
                  </Row>
                </Col>
                {currentRecord?.attachments?.length > 0 && (
                  <Col xs={24}>
                    <Row gutter={[8, 8]} align={"top"}>
                      <Col xs={2}>
                        <CloudUploadOutlined style={{ fontSize: 22 }} />
                      </Col>
                      <Col xs={22}>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "flex-start",
                          }}
                        >
                          <Typography.Title level={5}>
                            {" "}
                            Attachment
                          </Typography.Title>

                          {currentRecord?.attachments?.length > 0 &&
                            currentRecord?.attachments.map((element, key) => {
                              return (
                                <div
                                  style={{
                                    alignItems: "center",
                                    display: "flex",
                                    justifyContent: "space-between",
                                    width: "100%",
                                    cursor: "pointer",
                                    background: MainColors.LIGHTBACKCOLOR,
                                    borderRadius: 10,
                                    padding: 10,

                                    ":hover": {
                                      background: MainColors.DARKBACKCOLOR,
                                      color: "white",
                                      // Add any additional styles you want for the hover effect
                                    },
                                  }}
                                  key={key}
                                  onClick={() => FetchFile(element)}
                                >
                                  <div>
                                    <img
                                      src="/file.png"
                                      alt="file"
                                      style={{ height: 50, width: 50 }}
                                    />
                                    {element?.url &&
                                      (element?.url
                                        ?.toString()
                                        .split("-::-"))[1]}
                                  </div>

                                  <BiCloudDownload
                                    style={{ fontSize: 32, color: NewColorTheme.primaryColor }}
                                  />
                                </div>
                              );
                            })}
                        </div>
                      </Col>
                    </Row>
                  </Col>
                )}

                {(localStorage.getItem("ntfc_level") == 6 ||
                  localStorage.getItem("ntfc_level") == 7) && (
                    <Col span={24}>
                      <Row gutter={[8, 8]} align={"top"}>
                        <Col xs={2}>
                          <FlagOutlined style={{ fontSize: 22 }} />
                        </Col>
                        <Col xs={22}>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "flex-start",
                              width: "100%",
                            }}
                          >
                            <Typography.Title level={5}>
                              {" "}
                              Interested Agencies
                            </Typography.Title>
                            {currentRecord?.interests?.length > 0 ? (
                              <List style={{ width: "100%" }}>
                                <VirtualList
                                  data={currentRecord.interests}
                                  height={150}
                                  itemHeight={47}
                                  itemKey="id"
                                // onScroll={onScroll}
                                >
                                  {(item, key) => (
                                    <List.Item
                                      key={key}
                                      style={{ cursor: "pointer" }}
                                    >
                                      {console.log("item ----->>", item)}
                                      <List.Item.Meta
                                        avatar={
                                          <Avatar
                                            src={
                                              <img
                                                src={
                                                  "https://gw.alipayobjects.com/zos/rmsportal/KDpgvguMpGfqaHPjicRK.svg"
                                                }
                                                alt="avatar"
                                              />
                                            }
                                          />
                                        }
                                        // title={<p>{item.title}</p>}
                                        title={item.agency_name}
                                        // description={"By " + item.first_name + " " + item.last_name}

                                        description={
                                          new Date(item.created)
                                            .toISOString()
                                            .slice(8, 10) +
                                          "/" +
                                          new Date(item.created)
                                            .toISOString()
                                            .slice(5, 7)
                                        }
                                      />
                                      <div>{!isChair &&


                                        <Space>
                                          <Button
                                            type="primary"
                                            onClick={() => {
                                              setThis_data(item);
                                              assignAgency(item);
                                            }}
                                            disabled={window.localStorage.getItem('view_only') == 1}
                                          >
                                            {" "}
                                            {item.checked ? "Unassign" : "Assign"}
                                          </Button>
                                        </Space>
                                      }
                                      </div>
                                    </List.Item>
                                  )}
                                </VirtualList>
                              </List>
                            ) : (
                              <Typography.Paragraph>
                                {" "}
                                There is no Interested Donors for this Projec
                              </Typography.Paragraph>
                            )}
                          </div>
                        </Col>
                      </Row>
                    </Col>
                  )}
                <Col xs={24}>
                  <Row gutter={[8, 8]} align={"top"}>
                    <Col xs={2}>
                      <FileDoneOutlined style={{ fontSize: 22 }} />
                    </Col>
                    <Col xs={22}>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "flex-start",
                        }}
                      >
                        <Typography.Title level={5}> Remarks</Typography.Title>

                        <Typography>
                          {" "}
                          {currentRecord?.reject_reason
                            ? currentRecord?.reject_reason
                            : currentRecord?.publish_reason
                              ? currentRecord?.publish_reason
                              : "-"}
                        </Typography>
                        {currentRecord?.published_attachments?.length > 0 &&
                          currentRecord?.published_attachments.map((element, key) => {
                            return (
                              <div
                                style={{
                                  alignItems: "center",
                                  display: "flex",
                                  justifyContent: "space-between",
                                  width: "100%",
                                  cursor: "pointer",
                                  background: MainColors.LIGHTBACKCOLOR,
                                  borderRadius: 10,
                                  padding: 10,

                                  ":hover": {
                                    background: MainColors.DARKBACKCOLOR,
                                    color: "white",
                                    // Add any additional styles you want for the hover effect
                                  },
                                }}
                                key={key}
                                onClick={() => FetchFile(element)}
                              >
                                <div>
                                  <img
                                    src="/file.png"
                                    alt="file"
                                    style={{ height: 50, width: 50 }}
                                  />
                                  {element?.url &&
                                    (element?.url
                                      ?.toString()
                                      .split("-::-"))[1]}
                                </div>
                                <BiCloudDownload
                                  style={{ fontSize: 32, color: NewColorTheme.primaryColor }}
                                />
                              </div>
                            );
                          })}
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
        {/* <p>{record.checked ? "Are you sure to assist this project?" : "Are you sure to remove this project?"}</p> */}
      </Modal>
    </div>
  );
};

export default AllDonorInterests;
