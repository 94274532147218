import { Typography } from 'antd';
import { useState, useEffect } from 'react';
import '../../dash3.css'
function CircularProgress({ progress, size, backgroundColor, progressColor, label }) {
    const [strokeWidth, setStrokeWidth] = useState(1);
    const [progressOffset, setProgressOffset] = useState(0);
    const circumference = 2 * Math.PI * (size / 2);
    const [height, setHeight] = useState('auto');
    const [width, setWidth] = useState('auto');

    useEffect(() => {
        // const newStrokeWidth = progress === 0 ? 1 : 5;
        // setStrokeWidth(newStrokeWidth);

        // const startingPointArcLength = (1 / 20) * circumference / 100;
        // const newProgressOffset = circumference - startingPointArcLength - (progress / 100) * circumference;
        // setProgressOffset(newProgressOffset);
        // const startingPointArcLength = (1 / 20) * circumference / 100;
        // const newProgressOffset = circumference - ((progress / 100) * circumference) - startingPointArcLength;
        // setProgressOffset(newProgressOffset);

        const newStrokeWidth = progress === 0 ? 1 : 5;
        setStrokeWidth(newStrokeWidth);

        const newProgressOffset = circumference - (progress / 100) * circumference;
        setProgressOffset(newProgressOffset);
        // const newProgressOffset = circumference - startingPointArcLength - ((progress / 100) * circumference) - startingPointArcLength;
        // setProgressOffset(newProgressOffset);


        // set height and width based on screen size
        const screenWidth = window.innerWidth;
        if (screenWidth >= 1400) {
            setHeight('125px');
            setWidth('125px');
        } else if (screenWidth <= 1400 && screenWidth >= 990) {
            setHeight('100px');
            setWidth('100px');
        } else {
            setHeight('80px');
            setWidth('80px');
        }


    }, [progress, circumference]);

    return (
        <div style={{ position: 'relative' }}>
            <div style={{ position: 'absolute', top: -30, left: '50%', transform: 'translateX(-50%)', marginBottom: '10px' }}>
                <Typography style={{ color: 'white', fontWeight: 700 }}>{label}</Typography>
            </div>
            <svg viewBox={`0 0 ${size} ${size}`} width={width} height={height}>
                <circle
                    // className='progress-circle'
                    cx={size / 2}
                    cy={size / 2}
                    r={size / 2 - strokeWidth / 2}
                    fill="none"
                    stroke={backgroundColor}
                    strokeWidth={strokeWidth}
                />
                <circle
                    className="progress-circle"
                    cx={size / 2}
                    cy={size / 2}
                    r={size / 2 - strokeWidth / 2}
                    fill="none"
                    stroke={progressColor}
                    strokeWidth="0"
                />
                <circle
                    className="progress-circle"
                    cx={size / 2}
                    cy={size / 2}
                    r={size / 2 - strokeWidth / 2}
                    fill="none"
                    stroke={progressColor}
                    strokeWidth={strokeWidth}
                    strokeDasharray={circumference}
                    strokeDashoffset={progressOffset}
                    transform={`rotate(-90 ${size / 2} ${size / 2})`}
                    style={{ transition: 'stroke-dashoffset 0.5s ease-in-out' }}
                />
                {progress < 100 && (
                    <circle
                        // className='progress-circle'
                        cx={size / 2}
                        cy={size / 2}
                        r={size / 2 - strokeWidth / 8}
                        fill="none"
                        stroke={progressColor}
                        strokeWidth="0.5"
                        strokeDasharray={`${circumference} ${circumference}`}
                        strokeDashoffset="0"
                        transform={`rotate(-90 ${size / 2} ${size / 2})`}
                    />
                )}
                <text
                    fill={progressColor}
                    x={size / 2}
                    y={size / 2}
                    textAnchor="middle"
                    dominantBaseline="middle"
                    fontSize={size / 5}
                >
                    <animate attributeName="start" from="0" to={progress} dur="0.5s" fill="freeze" />
                    {progress}%
                </text>
            </svg>
        </div>
    );
}

export default CircularProgress;
