import { Avatar, Card, Divider, Typography } from 'antd'
import React from 'react'
import { UserOutlined, CaretDownOutlined } from '@ant-design/icons';
import UserDetails from './UserDetails';
import { useState } from 'react';
import { useEffect } from 'react';

const PageHeader = (props) => {

    const [ntfcName, setNtfc] = useState()
    const [ntfcLevel, setNtfcLevel] = useState()

    useEffect(() => {
        loadData()
    }, [])

    const loadData = async () => {
        setNtfc(await window.localStorage.getItem('ntfc_name'))
        setNtfcLevel(await window.localStorage.getItem('ntfc_level'))
    }


    return (
        <div style={{ display: 'flex', width: '100%', height: '15vh' }}>
            <div style={{ width: '70%', display: 'flex', alignItems: 'center', paddingLeft: '5%' }}>
                <Typography.Title level={4} style={{ margin: 0 }}>Published Inquiries</Typography.Title>
                <hr style={{ background: '#005476', height: 1, width: '71%', border: 'none', marginLeft: 10, marginRight: 0 }} />
            </div>
            <div style={{ width: '30%', display: 'flex', alignItems: 'center', justifyContent: 'end' }} className="profile-user-donor">
                <div style={{ display: "flex", justifyContent: "space-between", paddingRight: '20%' }}>
                    <UserDetails username={ntfcName}
                        designation={ntfcLevel} />
                </div>
            </div>
        </div>
    )
}

export default PageHeader