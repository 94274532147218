import { Button, Card, Typography } from 'antd'
import React, { useEffect, useState } from 'react'
import './dbcard.css';
import CountUp from 'react-countup';
import { PlusOutlined } from '@ant-design/icons';


function DbCard({ count, title, onClick, selected, addClick }) {

    const [shouldAnimate, setShouldAnimate] = useState(false);

    useEffect(() => {
        setShouldAnimate(true);


    }, [count])
    return (
        <Card onClick={onClick} className={"new-db-card"} >
            <div className={selected ? "new-db-card-container-selected" : 'new-db-card-container'}>
                <div className='db-count-div-upper'>
                    <Typography className={selected ? 'new-db-card-count-selected' : 'new-db-card-count'}>
                        {shouldAnimate && <CountUp end={count && count ? count : 0} duration={2} />}
                    </Typography>
                </div>
                <div className='db-count-div'>
                    <Typography className={selected ? 'new-db-card-title-selected' : 'new-db-card-title'}>
                        {title}
                    </Typography>
                </div>
                {/* {selected &&
                    <div >
                        <Button onClick={addClick} shape="circle" icon={<PlusOutlined />} />
                    </div>} */}

            </div>


        </Card>
    )
}

export default DbCard