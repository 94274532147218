import { Col, Input } from "antd";

const FullSearch = ({ full = [], setFiltered = () => { }, params = [] }) => {
    const search = (e) => {
        const pre = [];
        const post = [];
        for (let i = 0; i < full.length; i++) {
            let starts = false;
            let contains = false;
            for (let j = 0; j < params.length; j++) {
                if (full[i][params[j]] && full[i][params[j]].toString().toLowerCase().startsWith(e.target.value.toString().toLowerCase())) {
                    starts = true;
                    break;
                } else if (full[i][params[j]] && full[i][params[j]].toString().toLowerCase().includes(e.target.value.toString().toLowerCase())) {
                    contains = true;
                    break;
                }
            }
            if (starts) {
                pre.push(full[i]);
            }
            if (contains) {
                post.push(full[i]);
            }
        }
        setFiltered(pre.concat(post));
        // if (is_valid_input(e.target.value)) {
        //     setFiltered(full.filter((ele) => {
        //         return ele.action_formatted ? ele.action_formatted.startsWith(e.target.value) : ele.measure_formatted ? ele.measure_formatted.startsWith(e.target.value) : ele.article_no ? ele.article_no == e.target.value : ele.action_no == e.target.value;
        //     }))
        // } else {

        // }
    }

    function is_valid_input(input_value) {
        // The regular expression pattern to match the input value.
        const regex = /^[1-9]\.?[0-9]?\.?[0-9]?$/;

        // Check if the input value matches the regular expression pattern.
        if (input_value.match(regex)) {
            return true;
        } else {
            return false;
        }
    }

    return <Col lg={12}>
        <Input.Search placeholder="Type to search" style={{ marginBottom: 10 }} onChange={search} allowClear />
    </Col>
}

export default FullSearch;