import { Avatar, Button, Col, Divider, Row, Space, Typography, Input, Popover, Tooltip, DatePicker } from "antd";
import { UserOutlined, QuestionOutlined, LoadingOutlined, UserAddOutlined, CommentOutlined, PlusOutlined } from '@ant-design/icons';
import StatusCompo from "./StatusCompo";
import SubActionActivities from "./SubActionActivities";
import { useEffect, useState } from "react";
import { addParticipants, changeSubactionStatus, getActionParticipants, getAgencySubActions, getAllUsers, removeParticipants, searchParticipants } from "../../api";
import Search from "antd/es/input/Search";
import moment from "moment/moment";
const { Text } = Typography;

const SubActionItem = ({ sub, pending, action, getSubActions }) => {

    const [loaded, setLoad] = useState(true)
    const [currentActionParticipants, setCurrentActionParticipants] = useState([]);
    const [userSearching, setSearch] = useState(false)
    const [searhcUsers, setSearchUsers] = useState([]);
    const [currentPartiesLoading, setCurrentPartLoad] = useState(false)
    const [users, setUsers] = useState([]);

    const [status, setStatus] = useState(sub.status);


    const changeStatus = (stat) => {
        setStatus(stat)
        getSubActions()
        changeSubactionStatus({
            id: sub.id,
            status: stat
        }).then(() => {
            //show message
        })

    }

    useEffect(() => {

        // getUserDetails()

    }, [sub])

    async function getUserDetails(agency_id, id) {


        let currentUsers = []
        let allusers = []

        setCurrentPartLoad(true)

        // get all Participants
        await getAllUsers(action.agency_id).then(out => {
            allusers = out.data.out
            setUsers(out.data.out)
        });

        // get current Participants
        await getActionParticipants(agency_id, id).then(out => {

            setCurrentActionParticipants(out.data.out)
            currentUsers = out.data.out
            setLoad(true)

        })



        // set function for set user details for the current paricipants -- first_name, last_name...
        await getCurrentUsersName(currentUsers, allusers)

    }

    const getCurrentUsersName = async (currentUsers, allusers) => {

        if (allusers && currentUsers) {
            currentUsers.forEach(obj2 => {
                const obj1 = allusers.find(o => o.uuid === obj2.user_id);
                if (obj1) {
                    Object.assign(obj2, obj1);
                }
            });
            setCurrentActionParticipants(currentUsers)

        }

        setCurrentPartLoad(false)

        setLoad(true)
        onSearch("")


    }

    const onSearch = async (value) => {

        setSearch(true)

        let out = await searchParticipants(action.agency_id, value)
        if (out.status === 200) {
            setSearchUsers(out.data.out)
            setSearch(false)
        }
        console.log(out)
    };
    const participants = (
        <div style={{ width: '30vw' }}>
            <Text strong>Participants</Text>
            <Divider style={{ margin: 5 }} />


            <Search style={{ width: "100%", margin: "10px 0" }} placeholder="input search text" onSearch={onSearch} enterButton />

            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                {!userSearching ?
                    searhcUsers.map((c, k) => (
                        <Tooltip title={"Add " + c.title + " " + c.first_name + " " + c.last_name} color={"#108ee9"} key={k}>
                            <div onClick={() => addNewParticipant(c)} style={{ padding: "5px 10px", borderRadius: 25, border: '1px solid #e9e7e7', background: '#fafafa', width: 'fit-content', margin: "2px 3px", cursor: "pointer" }}>
                                <Space key={k}>
                                    {/* <Avatar size='small'>{c}</Avatar> */}
                                    <Text>{c.title + " " + c.first_name + " " + c.last_name}</Text>
                                </Space>
                            </div>
                        </Tooltip>
                    )) : <LoadingOutlined style={{ fontSize: "16px", color: "blueviolet" }} />
                }
            </div>


            {searhcUsers.length > 0 ? <Divider style={{ margin: 5 }} /> : null}

            <div style={{ margin: "15px 0", padding: "0 10px" }}>
                <Text style={{ fontSize: 16 }} strong>Current Participants</Text>
            </div>


            {!currentPartiesLoading ?
                <Row>
                    {
                        currentActionParticipants.map((c, k) => (
                            <Tooltip title={"Remove " + c.title + " " + c.first_name + " " + c.last_name} color={"#f50"} key={k}>
                                <div onClick={() => removeParticipant(c)} style={{ padding: "5px 10px", borderRadius: 25, border: '1px solid #e9e7e7', background: '#fafafa', width: 'fit-content', margin: "2px 3px", cursor: "pointer" }}>
                                    <Text>{c.title + " " + c.first_name + " " + c.last_name}</Text>
                                </div>
                            </Tooltip>
                        ))
                    }
                </Row> :
                <div>
                    <LoadingOutlined style={{ fontSize: "16px" }} />
                </div>
            }

        </div>

    );




    const addNewParticipant = async (user) => {

        let data = {
            "agency_id": action.agency_id,
            "action_id": action.action_id,
            "participant": user.uuid,
            "subaction": sub.id
        }

        let out = await addParticipants(data)
        if (out.status === 200) {
            getUserDetails()
        }


    }

    const removeParticipant = async (user) => {

        let data = {
            "agency_id": user.agency_id,
            "action_id": action.action_id,
            "participant": user.user_id,
            "subaction": sub.id
        }

        let out = await removeParticipants(data)
        if (out.status === 200) {
            getUserDetails()
        }

    }

    function getRandomColor() {
        const letters = "0123456789ABCDEF";
        let color = "#";
        for (let i = 0; i < 6; i++) {
            color += letters[Math.floor(Math.random() * 16)];
        }
        return color;
    }



    return <>
        <Row gutter={16} style={{ marginTop: 10, marginBottom: 10 }}>
            <Col span={7}>
                <Typography.Text>{sub.subaction ?? null}</Typography.Text>
            </Col>
            <Col span={3} style={{ textAlign: 'center' }}>
                <StatusCompo status={status} setStatus={changeStatus} />
            </Col>
            <Col span={5} style={{ textAlign: 'center' }}>
                {loaded ? !currentActionParticipants ? <UserAddOutlined /> :
                    <Popover content={participants} trigger="click" placement="left" onOpenChange={async (visible) => {
                        if (visible) {
                            await getUserDetails()
                        } else {
                            setSearchUsers([])
                        }
                    }}>
                        <Tooltip onOpenChange={async (visible) => {
                            if (visible) {
                                await getUserDetails()
                            } else {
                                setSearchUsers([])
                            }


                        }} title={
                            <div style={{ padding: "5px" }}>
                                <Avatar.Group>
                                    {currentActionParticipants.length > 0 ? currentActionParticipants.map(element => {
                                        return <Avatar size="large" style={{ backgroundColor: getRandomColor() }}>{element.first_name}</Avatar>
                                    })
                                        :
                                        <Typography.Text style={{ color: "ActiveCaption" }}>{"No Participants added. click to Add Participants."} </Typography.Text>}

                                </Avatar.Group>
                            </div>} placement="top" color={"#fff"}>
                            <div style={{ padding: "5px" }}>
                                <Avatar.Group>
                                    {currentActionParticipants.length > 0 ? currentActionParticipants.map(element => {
                                        return <Avatar size="large" style={{ backgroundColor: getRandomColor() }}>{element.first_name}</Avatar>
                                    })
                                        :
                                        <Avatar
                                            style={{
                                                backgroundColor: '#00a0bc',
                                            }}
                                            icon={<PlusOutlined />}
                                        />
                                    }
                                </Avatar.Group>
                            </div>
                        </Tooltip>
                    </Popover> : <LoadingOutlined style={{ fontSize: "16px" }} />}
            </Col>
            <Col span={3} style={{ textAlign: 'center' }}>
                <DatePicker onChange={() => { }} style={{ border: 'none', background: 'none', width: "120%" }} format="DD/MM/YYYY" />
            </Col>
            <Col span={3} style={{ textAlign: 'center' }}>
                <Typography.Text level={6}>{sub.def_date ? new Date(sub.def_date).toLocaleDateString() : "-"}</Typography.Text>

            </Col>
            <Col span={3} style={{ textAlign: 'center' }}>
                <SubActionActivities action={action} sub={sub} create={false} commentSec={true} mainAction={false} status={status} actionType={"SUBACTION"} />
            </Col>
        </Row>
        <Divider />
    </>
}

export default SubActionItem;