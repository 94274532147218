import { Button, Result } from "antd";

function Page() {
  return (
    <div
      className="App"
      style={{
        width: "100%",
        height: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Result
        status="404"
        title="404"
        subTitle="Sorry, the page you visited does not exist."
        extra={
          <Button
            onClick={() => (window.location.href = "/newdashboard")}
            type="primary"
          >
            Back to Dashboard
          </Button>
        }
      />
    </div>
  );
}

export default Page;
