import { Avatar, Button, Card, Col, Row, Space, Typography, Popover } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import { useState } from 'react';
const { Text } = Typography;

const Tasks = () => {
    const [open, setOpen] = useState(false);
    const handleOpenChange = (newOpen) => {
        setOpen(newOpen);
    };
    return (
        <div>
            <Space direction='vertical' style={{ width: '100%' }}>
                <Card size='small' style={{ borderLeft: '8px solid #ff8282', background: '#f9f9f9' }}>
                    <Row gutter={16}>
                        <Col className="gutter-row" span={15} style={{ display: 'flex', alignItems: 'center' }}>
                            <Text>Finalize Budget Report</Text>
                        </Col>
                        <Col className="gutter-row" span={3}>
                            <Avatar icon={<UserOutlined />} />
                        </Col>
                        <Col className="gutter-row" span={6}>
                            <Popover
                                content={<div>
                                    <Space direction='vertical'>
                                        <Text>Due: 2023-03-05</Text>
                                        <Text>Change Status</Text>
                                        <Space>
                                            <Button style={{ background: '#ff8282', border: 'none', color: 'white' }} block>
                                                Stuck
                                            </Button>
                                            <Button style={{ background: '#08de78', border: 'none', color: 'white' }} block>
                                                Ongoing
                                            </Button>
                                            <Button style={{ background: '#ffc61d', border: 'none', color: 'white' }} block>
                                                Pending
                                            </Button>
                                        </Space>
                                    </Space>
                                </div>}
                                title="Finalize Budget Report"
                                trigger="click"
                                open={open}
                                onOpenChange={handleOpenChange}
                                placement="bottom"
                            >
                                <Button style={{ background: '#ff8282', border: 'none', color: 'white' }} block>
                                    Stuck
                                </Button>
                            </Popover>
                        </Col>
                    </Row>
                </Card>
                <Card size='small' style={{ borderLeft: '8px solid #08de78', background: '#f9f9f9' }}>
                    <Row gutter={16}>
                        <Col className="gutter-row" span={15} style={{ display: 'flex', alignItems: 'center' }}>
                            <Text>Finalize Budget Report 2021</Text>
                        </Col>
                        <Col className="gutter-row" span={3}>
                            <Avatar icon={<UserOutlined />} />
                        </Col>
                        <Col className="gutter-row" span={6}>
                            <Button style={{ background: '#08de78', border: 'none', color: 'white' }} block>
                                Ongoing
                            </Button>
                        </Col>
                    </Row>
                </Card>
                <Card size='small' style={{ borderLeft: '8px solid #ffc61d', background: '#f9f9f9' }}>
                    <Row gutter={16}>
                        <Col className="gutter-row" span={15} style={{ display: 'flex', alignItems: 'center' }}>
                            <Text>Finalize Budget Report 2020</Text>
                        </Col>
                        <Col className="gutter-row" span={3}>
                            <Avatar icon={<UserOutlined />} />
                        </Col>
                        <Col className="gutter-row" span={6}>
                            <Button style={{ background: '#ffc61d', border: 'none', color: 'white' }} block>
                                Pending
                            </Button>
                        </Col>
                    </Row>
                </Card>
            </Space>
        </div>
    )
};

export default Tasks;