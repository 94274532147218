import { Col, DatePicker, Modal, Row, Typography } from "antd";
import { useEffect, useState } from "react";
import dayjs from 'dayjs';
import ActionConfirmCompo from "./ActionConfirmCompo";

import { changeAgencyActionDeliveryDate } from "../../../api";
import { ToastContainer, toast, Zoom } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { NewColorTheme } from "../../../appConst";

const ActionComponent = ({ action, article, measure, refreshPlan, agency, isChair }) => {


    const [delDate, setDelDate] = useState(action.delivery_date);
    const onChange = (_obj, dateString) => {
        if (!modalOpen) {
            setDelDate(dateString);
        }
        action.delivery_date = dateString;
        setModalOpen(true);
    };

    const [modalOpen, setModalOpen] = useState(false);

    const changeDeliveryDate = () => {
        changeAgencyActionDeliveryDate({
            agency_id: agency,
            action_id: action.id,
            delivery_date: delDate
        }).then((out) => {
            toast.success(`The action has been successfully updated`, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
                // progressClassName: 'toast-progress-bar',
            });
            refreshPlan();
        })
    }

    useEffect(() => {
        if (!modalOpen) {
            console.log("SETTING", action.delivery_date ?? action.def_date);
            setDelDate(action.delivery_date ?? action.def_date)
        }
    }, [action]);

    return <>
        <Row gutter={16}>
            <div className="line" style={{ border: action.available ? "0.5px solid #282D93" : "" }}></div>
            <Col span={12} >
                <div className="tblRaw3" style={{ border: action.available ? `2px solid ${NewColorTheme.primaryColor}` : "" }}>
                    <Typography.Text>{article.article_no}.{measure.measure_no}.{action.action_no} - {action.action}</Typography.Text>
                </div>
            </Col>
            <Col span={4} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', }}   >
                <div className="bgPrimaryRounded">
                    <Typography.Text>{`${(new Date(action.def_date).getDate()).toString().padStart(2, '0')}/${(new Date(action.def_date).getMonth() + 1).toString().padStart(2, '0')}/${new Date(action.def_date).getFullYear()}`}</Typography.Text>
                </div>
            </Col>
            <Col span={4} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <div className="bgWhiteRounded">
                    <DatePicker style={{ width: '100%' }} disabledDate={(current) => current && current > dayjs(measure.measure_extended_def_date ? measure.measure_extended_def_date : action.def_date).startOf('day')}
                        value={dayjs(delDate).isValid() ? dayjs(delDate) : dayjs(new Date().toISOString())} format={'DD/MM/YYYY'} onChange={onChange} allowClear={false} disabled={!action.available || window.localStorage.getItem('view_only') == 1 || isChair} />
                </div>
            </Col>
            <Col span={4} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <ActionConfirmCompo isChair={isChair} action={action} available={action.available} refreshPlan={refreshPlan} agency={agency} />
            </Col>
        </Row>
        <Modal
            centered
            title="Confirm"
            open={modalOpen}
            onOk={() => { setModalOpen(!modalOpen); changeDeliveryDate(); }}
            onCancel={() => { setModalOpen(false); }}
            okButtonProps={{ style: { backgroundColor: "#005476", color: "white" } }}
        >
            <p>{`Are you sure you want to change the delivery date to ${delDate}?`}</p>
        </Modal>

    </>
}

export default ActionComponent;