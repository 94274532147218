import React, { useEffect, useState } from 'react'
import '../../dashboard4.css'
import CountUp from 'react-countup';
import { Typography } from 'antd';
import { getUserManagementCardData } from '../../../../../api';


function NewUserRequests() {

    useEffect(() => {
        setShouldAnimate(true);
        loadData()

    }, [])


    const [cardData, setCardData] = useState(null)
    const [shouldAnimate, setShouldAnimate] = useState(false);

    const loadData = async () => {

        let agency
        let userLevel = await window.localStorage.getItem('ntfc_level')
        if (userLevel == 6 || userLevel == 7) {
            agency = 0
        } else {
            agency = window.localStorage.getItem('ntfc_agency')
        }

        getUserManagementCardData(agency).then((out) => {

            setCardData(out.data.out)

        });

    }
    return (
        <div
            onClick={() => {
                window.location.href = "/userrequestshod"
            }}
            style={{
                cursor: "pointer",
                background: "#7233BD"
            }}
            className='count-title-card'
        >
            <div style={{
                flex: 1,
                textAlign: "center"
            }}>

                <Typography style={{
                    fontFamily: "Figtree",
                    fontSize: 60,
                    fontWeight: 800,
                    color: "white",
                    textTransform: "uppercase"
                }}>
                    {shouldAnimate && <CountUp end={cardData && cardData.approval ? cardData.approval : 0} duration={2} />}


                </Typography>
            </div>
            <div style={{
                flex: 2
            }}>
                <Typography style={{
                    fontFamily: "Figtree",
                    fontSize: 21,
                    fontWeight: 700,
                    color: "white",
                    textAlign: "center",
                    lineHeight: 1.2,
                    textTransform: "uppercase"
                }}>
                    User Requests
                </Typography>
            </div>






        </div>
    )
}

export default NewUserRequests