import { Card, Col, Row, Skeleton, Typography } from 'antd'
import React from 'react'
import './donorCard.css'
import { useState } from 'react';
import { useEffect } from 'react';

import CountUp from 'react-countup';
import { getDonorCardData, getDonorCounts } from '../../../../../api';

function DonorCard({ techUser }) {

    const [cardData, setCardData] = useState(null)
    const [cardData2, setCardData2] = useState(null)
    const [shouldAnimate, setShouldAnimate] = useState(false);

    useEffect(() => {
        setShouldAnimate(true);
        loadData()

    }, [])

    const loadData = async () => {

        let agency
        let userLevel = await window.localStorage.getItem('ntfc_level')
        if (userLevel == 6 || userLevel == 7) {
            agency = 0
        } else {
            agency = window.localStorage.getItem('ntfc_agency')
        }
        getDonorCardData(agency).then((out) => {

            setCardData(out.data.out)

        });

        getDonorCounts().then((out) => {

            setCardData2(out.data.out)

        });
    }

    return (
        <div >
            {cardData ?
                <Row gutter={[16, 16]} style={{ width: "100%" }}>
                    <Col xs={24} lg={techUser ? 24 : 8}>
                        <div style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: 'center',
                            justifyContent: "center",
                            height: "100%",
                            marginTop: -20

                        }} onClick={(e) => {
                            e.stopPropagation()
                            window.location.href = "/donorprofile"
                        }}>
                            <span className='new-card-donor-new'>
                                {shouldAnimate && <CountUp end={cardData && cardData.profiles ? cardData.profiles : 0} duration={2} />}

                            </span>
                            <span className="new-card-donor-title1"  >Donor</span>
                            <span className="new-card-donor-title2">Profiles</span>
                        </div>
                    </Col>
                    <Col xs={24} lg={techUser ? 24 : 16}>
                        <div>
                            <div style={{
                                display: 'flex',
                                justifyContent: 'center'
                            }}>
                                <Row gutter={[8, 8]}>


                                    {localStorage.ntfc_level > 10 ?
                                        <Col xs={5}>
                                            <div style={{ display: 'flex', justifyContent: "end", alignItems: "center", height: "100%" }}>
                                                <span className='new-card-donor-req-count' >
                                                    {shouldAnimate && <CountUp end={cardData && cardData.requests ? cardData.requests : 0} duration={2} />}
                                                </span>
                                            </div>
                                        </Col>

                                        :
                                        <Col xs={10}>
                                            <div style={
                                                techUser ? { display: 'flex', justifyContent: "center", alignItems: "center", height: "100%" }
                                                    :
                                                    { display: 'flex', justifyContent: "end", alignItems: "center", height: "100%" }}>
                                                <span className='new-card-donor-req-count' >
                                                    {shouldAnimate && <CountUp end={cardData2 && cardData2.my_donor_requests ? cardData2.my_donor_requests : 0} duration={2} />}
                                                </span>
                                            </div>
                                        </Col>

                                    }
                                    <Col xs={14} >
                                        <div style={techUser ? { display: 'flex', justifyContent: "center", alignItems: "center", height: "100%" } : { display: 'flex', justifyContent: "end", alignItems: "center", height: "100%" }}>

                                            <span className='new-card-donor-req-content' >Requests </span>
                                        </div>
                                    </Col>
                                    <Col xs={10}>
                                        <div style={
                                            techUser ? { display: 'flex', justifyContent: "center", alignItems: "center", height: "100%" }
                                                :
                                                { display: 'flex', justifyContent: "end", alignItems: "center", height: "100%" }}>
                                            <span className='new-card-donor-req-count' >
                                                {shouldAnimate && <CountUp end={cardData && cardData.interests ? cardData.interests : 0} duration={2} />}


                                            </span>
                                        </div>
                                    </Col>
                                    <Col xs={14} >
                                        <div style={techUser ? { display: 'flex', justifyContent: "center", alignItems: "center", height: "100%" } : { display: 'flex', justifyContent: "end", alignItems: "center", height: "100%", marginTop: -14 }}>
                                            <span className='new-card-donor-req-content' >Interests</span>
                                        </div>
                                    </Col>

                                    <Col xs={24} style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        width: "100%"
                                    }}>
                                        <img src='/donornew.png' alt='donor' style={techUser ? { width: 110, marginTop: -35 } : { width: 110, marginTop: -35, marginRight: -50 }} />

                                    </Col>

                                </Row>
                            </div>

                        </div>
                    </Col>

                </Row>

                :
                <Skeleton
                    active
                    avatar
                    paragraph={{
                        rows: 4,
                    }}
                />


            }


        </div>







    )
}

export default DonorCard