import { Card, Col, Row, Typography } from 'antd'
import React from 'react'
import './donorCard.css'
import { useState } from 'react';
import { useEffect } from 'react';
import { getAllInquiryCounts, getDonorCardData, getDonorCounts } from '../../../../../api';
import { EyeOutlined } from "@ant-design/icons";
import CountUp from 'react-countup';

function DonorCard({ user_level }) {

    const [cardData, setCardData] = useState(null)
    const [cardData2, setCardData2] = useState(null)
    const [shouldAnimate, setShouldAnimate] = useState(false);

    useEffect(() => {
        setShouldAnimate(true);
        loadData()

    }, [])

    const loadData = async () => {

        let agency
        let userLevel = await window.localStorage.getItem('ntfc_level')
        if (userLevel == 6 || userLevel == 7) {
            agency = 0
        } else {
            agency = window.localStorage.getItem('ntfc_agency')
        }
        getDonorCardData(agency).then((out) => {

            setCardData(out.data.out)

        });

        getDonorCounts().then((out) => {

            setCardData2(out.data.out)

        });
    }

    return (
        <Card style={{ cursor: "pointer" }} className='donor-card' onClick={() => {
            window.location.href = "/donormanagement"
            // window.location.href = "/donordashboardv3"
        }}>
            <div className='donor-inside'>
                <div className='tfa-plan-div'>

                    <Typography className='tfa-title'>
                        Donor Management
                    </Typography>
                    <div style={{ display: "flex", justifyContent: "end", gap: 5, right: 0 }}>
                        <EyeOutlined style={{ fontSize: 25, color: "white" }} />
                    </div>

                </div>
                <div className='donor-upper-div'>
                    <div className='donor-left' onClick={(e) => {
                        e.stopPropagation()
                        window.location.href = "/donorprofile"
                    }}>
                        <span className='card-donor-new' >
                            {shouldAnimate && <CountUp end={cardData && cardData.profiles ? cardData.profiles : 0} duration={2} />}

                        </span>
                        <span className='card-donor-content' >Donor</span>
                        <span className='card-donor-content-2' >Profiles</span>

                    </div>
                    <div className='donor-left'>
                        <div className='donor-right-small-div'>
                            <Row gutter={16}>


                                {localStorage.ntfc_level > 5 ?
                                    <Col xs={5}>
                                        <div style={{ display: 'flex', justifyContent: "start", alignItems: "center", height: "100%" }}>
                                            <span className='card-donor-req-count' >
                                                {shouldAnimate && <CountUp end={cardData && cardData.requests ? cardData.requests : 0} duration={2} />}
                                            </span>
                                        </div>
                                    </Col>

                                    :
                                    <Col xs={5}>
                                        <div style={{ display: 'flex', justifyContent: "start", alignItems: "center", height: "100%" }}>
                                            <span className='card-donor-req-count' >
                                                {shouldAnimate && <CountUp end={cardData2 && cardData2.my_donor_requests ? cardData2.my_donor_requests : 0} duration={2} />}
                                            </span>
                                        </div>
                                    </Col>

                                }
                                <Col xs={15} offset={4}>
                                    <div style={{ display: 'flex', justifyContent: "start", alignItems: "center", height: "100%" }}>

                                        <span className='card-donor-req-content' >Requests </span>
                                    </div>
                                </Col>

                            </Row>
                        </div>
                        <div className='donor-right-small-div'>
                            <Row>

                                <Col xs={5}>
                                    <div style={{ display: 'flex', justifyContent: "start", alignItems: "center", height: "100%" }}>
                                        <span className='card-donor-req-count' >
                                            {shouldAnimate && <CountUp end={cardData && cardData.interests ? cardData.interests : 0} duration={2} />}


                                        </span>
                                    </div>
                                </Col>
                                <Col xs={15} offset={4}>
                                    <div style={{ display: 'flex', justifyContent: "start", alignItems: "center", height: "100%" }}>
                                        <span className='card-donor-req-content' >Interests</span>
                                    </div>
                                </Col>


                            </Row>
                        </div>

                    </div>
                </div>
                <div className='donor-bottom-div'>
                    {/* <div style={{ display: "flex", justifyContent: "end", gap: 5, position: "absolute", left: "0", marginLeft: "3%" }}>
                        <EyeOutlined style={{ fontSize: 25, color: "white" }} />
                    </div> */}
                    {/* <Typography className="donor-bottom-title" style={{ marginLeft: "6%" }}>Create New</Typography> */}
                    <img src='/donornew.png' alt='donor' className='donor-logo-png' />
                </div>
            </div>

        </Card>
    )
}

export default DonorCard