import React, { useState } from 'react'

import DonarUser from '../Donors/components/DonarUser'
import '../Donors/components/dashboarddonors.css'
import { Button, Col, Divider, Row } from 'antd'

import { CameraOutlined } from '@ant-design/icons';
import DashboardCards from '../Donors/components/DashboardCards';
import DonorDashboardCard from '../Donors/components/DonorDashboardCard';
import CustomTable from '../../Components/Table';

const DonorContribution = () => {

    const [data, setData] = useState([]);
    const [filtered_data, setFilteredData] = useState([]);


    const filterData = (val) => {
        const itms = data.filter((rec) => {
            return rec.article_id === Number(val) || !val
        });
        console.log(itms); setFilteredData(itms);
    }


    const columns = [
        {
            title: '#',
            dataIndex: 'action_no',
            key: 'action_no',
            render: (_, record) => (

                record ?
                    <div>{record.article_no + "." + record.measure_no + "." + record.action_no}</div>
                    : null
            ),

        },

        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',

        },
        {
            title: 'Summary',
            dataIndex: 'summary',
            key: 'summary',

        },
        {
            title: 'Weightage',
            dataIndex: 'weightage',
            key: 'weightage',

        },

        {
            title: 'Remarks',
            dataIndex: 'remarks',
            key: 'remarks',

        },

    ];


    return (
        <>
            <div className="bgSidePanel"></div>
            <div className="sidepanel-left"></div>

            <div className="top-dashboard">
                <DonarUser
                    donorUser='Mr. Kalum Sanjaya'
                />
                <div className="divide-line">
                    <Divider orientation="left">
                        <p className='divider-text'>My Contribution</p>
                    </Divider>
                </div>
                <div>
                    <Row gutter={16}>
                        <Col span={6}>
                            <DonorDashboardCard
                                cardClass="primaryCard"
                                CardText="Technical Assistances"
                                CardVal="5"
                                textClass="primaryFont"
                                valClass="primaryVal"


                            />

                        </Col>
                        <Col span={8}>
                            <DonorDashboardCard
                                style={{ width: "100%" }}
                                cardClass="SecondaryCard2"
                                CardText="Capacity Building Requests"
                                CardVal="5"
                                textClass="secondaryFont2"
                                valClass="secondaryVal2"


                            />

                        </Col>




                    </Row>
                </div>
                <div style={{ width: "70%", margin: "50px 0px" }}>

                    <CustomTable columns={columns} data={filtered_data}
                        pagination={{
                            pageSize: 10,
                            total: filtered_data.length,
                        }} />
                </div>


            </div>



        </>
    )
}

export default DonorContribution
