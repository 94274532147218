import React from 'react';
import { Row, Col, Avatar, Typography } from 'antd';
const avatarColor = "#249191"
const CardTitle = ({ icon, title, content }) => {
    return (

        <Row gutter={[16, 16]}>
            <Col span={6}>
                <div style={{ display: "flex", justifyContent: "start", alignItems: "start", height: "100%" }}>
                    <Typography className='inner-tile-title'>{title}</Typography>
                </div>

            </Col>
            <Col span={18}>
                <div style={{ display: "flex", justifyContent: "start", alignItems: "start", height: "100%" }}>
                    <Typography className='inner-tile-content' >{content} </Typography>
                </div>


            </Col>

        </Row>

    );
};

export default CardTitle;
