import React, { useMemo } from 'react'
import FullCalendar from '@fullcalendar/react' // must go before plugins
import dayGridPlugin from '@fullcalendar/daygrid' // a plugin!
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from "@fullcalendar/interaction" // needed for dayClick
import { Form, Avatar, Button, ConfigProvider, Modal, Segmented, Select, Space, TimePicker, Typography, Input, Alert, Row, Col, Popover, DatePicker, Descriptions, Divider, Tag, Tooltip, Badge, Card, Spin, Skeleton } from 'antd'
import { useState } from 'react'
import { ContainerOutlined, CrownOutlined, UserOutlined, BuildOutlined, RocketOutlined, BlockOutlined, EnvironmentOutlined, CrownFilled, EyeOutlined, DeleteOutlined, SettingOutlined, CloudUploadOutlined, InboxOutlined, ProfileOutlined, UsergroupAddOutlined, ClockCircleOutlined, CalendarOutlined, CloseOutlined, VideoCameraOutlined, AppstoreOutlined, FieldTimeOutlined, SaveOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import './NewCalender.css'
import { useEffect } from 'react'
import { GetAgencyWiseAllArticles, UpdateSchedules, addSchedulesComments, addSchedulesParticipants, createSchedules, deleteEventFiles, deleteSchedules, deleteSchedulesParticipants, downloadSheduleFiles, getAgencies, getMeasures, getSchedules, getUserById, getUsersByAgencies } from '../../../api'
import { BiCameraMovie } from "react-icons/bi";
import Dragger from 'antd/es/upload/Dragger'
import { useRef } from 'react';
import NewNavBar from '../Newdashoard3/Components/NewNavBar'
import { ToastContainer, toast, Zoom, Slide } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { CalenderColors, MainColors, NewColorTheme } from '../../../appConst'
import SideBar from './Components/SideBar'
import PageHeader from '../../Inquiries/PageHeader'
import { getUserData } from '../../Donors/getCounts'
import { useAsyncError } from 'react-router-dom'



const { TextArea } = Input;
const { Text } = Typography;

function NewCalender() {

    const [form] = Form.useForm();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [participants, setParticipants] = useState([]);
    const [selectedDate, setSelectedDate] = useState(null)
    const [selectedDate2, setSelectedDate2] = useState(null)
    const [selectedOption, setSelectedOption] = useState("Meeting")
    const [agencies, setAgencies] = useState([]);
    const [currentUsers, setUsers] = useState([]);
    const [allUsers, setAllUsers] = useState([])
    const [selectedAgencies, setSelectedAgencies] = useState([])
    const [allEvents, setAllEvents] = useState([])
    const [showArrow, setShowArrow] = useState(true);
    const [arrowAtCenter, setArrowAtCenter] = useState(false);
    const [fileList, setFileList] = useState();
    const [from_time, setFromTime] = useState(null)
    const [to_time, setToTime] = useState(null)
    const [currentActionTasks, setActionTasks] = useState([])
    const [currentMeetings, setCurrentMeetings] = useState([])
    const [editMode, setEditMode] = useState(false)
    const [currentSelectedEventID, setCurrentSelectedEventID] = useState()
    const [eventCurrentParticipants, setEventCurrentParticipnts] = useState()
    const [disabaleEvent, setDisableEvent] = useState(false)
    const [dialog, confirmationDialog] = useState(false)
    const [comment, setComment] = useState("")
    const [measures, setMeasures] = useState([])
    const [commnetsFiles, setEventCurrentComments] = useState([])
    const [createdUser, setCreatedUser] = useState(false)
    const [postEvent, setPostEvent] = useState(false)
    const [todoList, setTodoList] = useState()
    const [sDate, setStDate] = useState()
    const [lDate, setLdDate] = useState()
    const [isPickerOpen, setIsPickerOpen] = useState(false);
    const [cuurentURL, setCurrentEventUrl] = useState()
    const [loading, setLoading] = useState(true)
    const uploadRef = useRef();
    const [filteredOptions, setFilteredOptions] = useState([]);
    const [currentSelected, setSelectedMeeting] = useState();
    const [commentError, setCommentError] = useState(false);
    const [fileError, setfileError] = useState(false);
    const [btnLoading, setbtnLoading] = useState(false)
    const [eventCreate, setEventCreate] = useState(false)

    // Function to handle the search event
    const handleSearch = (value) => {
        console.log("valye ==========================", value)
        // Filter the options based on the user input
        const filtered = agencies.filter(option => option.label.toLowerCase().includes(value.toLowerCase()));
        console.log(filtered)
        setFilteredOptions(filtered);
    };
    const handlePickerOpenChange = (open) => {
        setIsPickerOpen(open);
    };

    const handlePickerBlur = () => {
        setIsPickerOpen(false);
    };

    const mergedArrow = useMemo(() => {
        if (arrowAtCenter) return { pointAtCenter: true };
        return showArrow;
    }, [showArrow, arrowAtCenter]);

    useEffect(() => {

        LoadData()


    }, [])




    const LoadData = async function (stdate, edDate) {

        setLoading(true)

        console.log("/////------->>", stdate, edDate)
        const currentDate = dayjs();
        let firstDateOfMonth
        let lastDateOfMonth
        if (stdate) {
            firstDateOfMonth = stdate
            lastDateOfMonth = edDate
        } else {
            firstDateOfMonth = currentDate.startOf('month').format('DD/MM/YYYY');
            lastDateOfMonth = currentDate.endOf('month').format('DD/MM/YYYY');
            setStDate(firstDateOfMonth)
            setLdDate(lastDateOfMonth)
        }
        // Get the current date


        getAgencies().then((out) => {
            out.data.out.unshift({ label: "All Agencies", value: 0 })
            const newOption = { label: "NTFC Secretrait User", value: 9999 };
            const updatedOptions = [newOption].concat(out.data.out);
            // options.unshift({ label: "All Agencies", value: 0 });
            setAgencies(updatedOptions);

            setFilteredOptions(updatedOptions)
        });

        if (await window.localStorage.getItem('ntfc_level') == 7 || await window.localStorage.getItem('ntfc_level') == 6) {
            getMeasures().then((m) => {

                let newMeasures = m.data.out.flatMap((element) => {

                    const measureObj = {
                        extendedProps: {

                            type: "measure",
                            measure: element.label,
                            article: element.article,
                            measure_def_date: element.extended_def_date ? dayjs(element.extended_def_date).format("DD/MM/YYYY") : dayjs(element.def_date).format("DD/MM/YYYY")
                        },

                        title: "Due Measure - " + element.label,
                        start: element.extended_def_date ? dayjs(element.extended_def_date).format("YYYY-MM-DD") : dayjs(element.def_date).format("YYYY-MM-DD"),
                        backgroundColor: CalenderColors.MEASURE,
                        borderColor: "white",
                    };

                    return [measureObj]
                })
                const filteredData = newMeasures.filter(item => {
                    const measureDate = item.extendedProps.measure_def_date;

                    return measureDate >= firstDateOfMonth && measureDate <= lastDateOfMonth
                });
                setMeasures(filteredData);
                getEvents(newMeasures, firstDateOfMonth, lastDateOfMonth)

            });
        } else {
            AgencyActions(localStorage.ntfc_agency, firstDateOfMonth, lastDateOfMonth)
        }
        return;
    }


    const AgencyActions = async (agency, stdate, edDate) => {


        // Get the current date

        const out = await GetAgencyWiseAllArticles(agency)

        if (out.status === 200) {

            // Restructure the array that can match to the events for the calender
            const newArray = out.data.out.flatMap(item => {
                const { article_no, article, measures } = item;
                return measures.flatMap(({ measure_id, measure_name, article_no, measure_no, measure_summary, measure_def_date, actions }) => {
                    const measureObj = {
                        extendedProps: {
                            id: measure_id,
                            type: "measure",
                            measure: measure_no + ". " + measure_name,
                            article: article_no + ". " + article,
                            measure_def_date: dayjs(measure_def_date).format("DD/MM/YYYY")
                        },

                        title: "Due Measure - " + article_no + "." + measure_no + " " + measure_summary,
                        start: new Date(dayjs(measure_def_date).format("YYYY-MM-DD")).toISOString(),
                        backgroundColor: CalenderColors.MEASURE,
                        borderColor: "white",
                        allDay: true
                    };
                    const actionObjs = actions.map(({ action_id, action_status, action, participants, action_no, action_def_date, action_summary }) => (
                        {
                            extendedProps: {
                                id: action_id,
                                type: "action",
                                action: action_no + ". " + action,
                                participants: participants,
                                status: action_status,
                                article: article_no + ". " + article,
                                measure: measure_no + ". " + measure_name,
                                measure_def_date: dayjs(measure_def_date).format("DD/MM/YYYY"),
                                action_def_date: dayjs(action_def_date).format("DD/MM/YYYY")
                            },

                            title: "Due Action - " + article_no + "." + measure_no + "." + action_no + " " + action_summary,
                            start: new Date(dayjs(action_def_date).format("YYYY-MM-DD")).toISOString(),
                            backgroundColor: CalenderColors.ACTIONS,
                            borderColor: "white",
                            allDay: true
                        }
                    ));
                    return [measureObj, ...actionObjs];
                });
            });

            const filteredData = newArray.filter(item => {
                const measureDate = item.extendedProps.measure_def_date
                return measureDate >= stdate && measureDate <= edDate;
            });


            setActionTasks(filteredData)
            getEvents(newArray, stdate, edDate)

        } else {
            console.log("data loading falid")
        }

    }
    const getEvents = async function (actionTasks, stdate, edDate) {
        console.log("stdate", stdate, edDate)
        const currentDate = dayjs();
        let dateRange = {
            "from_date": stdate,
            "to_date": edDate
        }

        const out = await getSchedules(dateRange)


        const newArr = out.data.data.flatMap((element) => {
            const startDate = dayjs(element.from_date).format("YYYY-MM-DD")
            const endDate = dayjs(element.to_date).format("YYYY-MM-DD")
            const startTime = dayjs((element.from_time == 0 ? "0000" : element.from_time.toString().padStart(4, '0').toString()), 'HHmm').format('HH:mm')
            const endTime = dayjs((element.to_time == 0 ? "0000" : element.to_time.toString().padStart(4, '0').toString()), 'HHmm').format('HH:mm')
            const startDateISO = new Date(`${startDate}T${startTime}:00`).toISOString();
            const endDateISO = new Date(`${endDate}T${endTime}:00`).toISOString();


            const eventObj = {
                extendedProps: {
                    id: element.id,
                    type: getType(element.type),
                    participants: element.participants ? element.participants : [],
                    description: element.description,
                    url: element.url,
                    from_time: dayjs((element.from_time == 0 ? "0000" : element.from_time.toString().padStart(4, '0').toString()), 'HHmm').format('HH:mm'),
                    to_time: dayjs((element.to_time == 0 ? "0000" : element.to_time.toString().padStart(4, '0').toString()), 'HHmm').format('HH:mm'),
                    to_date: dayjs(element.to_date).format("YYYY-MM-DD"),
                    from_date: dayjs(element.from_date).format("YYYY-MM-DD"),
                    comments: element.comments ? element.comments : [],
                    created_by: element.created_by ? element.created_by : "",
                    backgroundColor: getColor(element.type),
                    borderColor: "white",
                },
                end: endDateISO,
                title: element.title,
                start: startDateISO,
                backgroundColor: getColor(element.type),
                borderColor: "white",
                allDay: false

            }

            return eventObj;
        })



        setCurrentMeetings(newArr)

        let AllEvents = [...actionTasks, ...newArr]
        console.log("All events", actionTasks)
        const currentDate2 = new Date();
        const thirtyDaysLater = new Date();
        thirtyDaysLater.setDate(currentDate2.getDate() + 30);

        const filteredArray = AllEvents.filter(item => {
            const startDate = new Date(item.start);
            return startDate > currentDate && startDate <= thirtyDaysLater;
        });
        let netSortedArr = filteredArray.sort((a, b) => {
            const dateA = new Date(a.start);
            const dateB = new Date(b.start);
            return dateA - dateB;
        })
        console.log('netSortedArr', netSortedArr)
        setTodoList(AllEvents)
        setAllEvents(AllEvents)
        setLoading(false)



    }

    const getType = (type) => {


        if (type == 0) {
            return "Meeting"
        } else if (type == 1) {
            return "Event"
        } else if (type == 2) {
            return "To Do"
        }

    }
    const getColor = (type) => {
        if (type === 0) {
            return CalenderColors.MEETING

        } else if (type === 1) {
            return CalenderColors.EVENT

        } else if (type === 2) {

            return CalenderColors.TODO
        }

    }
    useEffect(() => {

    }, [agencies])


    const selectAgency = async (val) => {
        if (val.length == 0) {
            console.log("callinggg")
            setAgencies(filteredOptions.forEach((obj) => { obj.disabled = false }));
            setUsers([])
            setParticipants([])

            setSelectedAgencies([])
            form.setFieldValue('participants', [])
        } else if (val[0] == 0) {
            setSelectedAgencies(val)
            let newArr = filteredOptions.filter((e, i) => i != 0).forEach((obj) => { obj.disabled = true })
            setAgencies(newArr);
            getParticipants(val)
        } else {
            setSelectedAgencies(val)
            let newArr = filteredOptions.filter((e, i) => i == 0).forEach((obj) => { obj.disabled = true })

            setAgencies(newArr);
            getParticipants(val)
        }
    }

    const getParticipants = async (agencies) => {
        let body = {
            agencies: agencies
        }
        await getUsersByAgencies(body).then(out => {
            let refactorArr = out.data.out.map((obj, index) => ({ ...obj, label: obj.designation + " - " + obj.label }))
            refactorArr.unshift({ label: "All Users", value: 0 })
            setUsers(refactorArr);
            // setUsers(out.data.out)
            setAllUsers(refactorArr)

        });
    }




    const handleDateClick = (arg) => {
        // setSelectedOption(clickInfo.event.extendedProps.type)
        setCommentError(false);
        setfileError(false);
        setSelectedDate(dayjs(arg.startStr));
        form.setFieldValue('from_date', arg.startStr)

        setIsModalOpen(true);
    }

    const handleCancel = () => {

        setParticipants([])
        setAgencies(filteredOptions.forEach((obj) => { obj.disabled = false })); // selectedAgencies([])
        setUsers([])
        setEventCurrentParticipnts([])

        setSelectedDate(dayjs())
        // setSelectedDate2(undefined)
        setIsModalOpen(false);
        setEditMode(false)
        setDisableEvent(false)
        setSelectedOption("Meeting")
        setFileList([])
        setCreatedUser(false)
        setPostEvent(false)
        setEventCurrentComments([])
        setEventCreate(false)
        form.resetFields()
    };

    const clearForm = () => {

        setParticipants([])

        // selectedAgencies([])
        setUsers([])
        setEventCurrentParticipnts([])
        setSelectedDate(dayjs())
        setSelectedDate2(undefined)
        form.resetFields()
        setIsModalOpen(false);
        setEditMode(false)
        setDisableEvent(false)
        setSelectedOption("Meeting")
        setFileList([])
        setCreatedUser(false)
        confirmationDialog(false)
        setPostEvent(false)
        setEventCurrentComments([])
        setEventCreate(false)
    }
    const handleOk = () => {
        setIsModalOpen(false);
        setEventCreate(false)
    };
    const onChangeFromTime = (time, timeString) => {

        setFromTime(timeString)
    };
    const onChangeToTime = (time, timeString) => {

        setToTime(timeString)
    };
    const selectRef = useRef(null);
    const onChangeSel = (value, opts) => {

        const isFirstOptionSelected = value.includes(0);

        if (isFirstOptionSelected) {
            // Focus on the Select to hide the dropdown
            currentUsers.filter((el, i) => i != 0).forEach((user) => {
                user.disabled = true
            });
        } else {
            // If the first option is deselected, enable all options
            currentUsers.forEach((user) => {
                user.disabled = false
            });
        }

        setUsers(currentUsers)

        setParticipants(opts);

    };
    const onSearchSel = (value) => {

    };

    const onChangeCheckBox = (e) => {
        console.log(`checked = ${e.target.checked}`);
    };
    const setSegmentBody = (e) => {
        setSelectedOption(e)
    };

    const onDateChange = (date, dateString) => {

        if (dateString) {
            setSelectedDate(dayjs(date));
        } else {
            form.resetFields(['from_date'])
        }


    };
    const onDateChange2 = (date, dateString) => {
        if (dateString) {
            setSelectedDate2(dateString);
        } else {
            form.resetFields(['to_date'])
        }

    };



    const onFinish = (values) => {
        setLoading(true)

        let type = ""
        if (selectedOption == "Meeting") {
            type = 0
        }
        if ((selectedOption == "Event")) {
            type = 1
        }
        if ((selectedOption == "To Do")) {
            type = 2
        }
        const formData = new FormData();

        formData.append('title', values.title);
        formData.append('url', values.url ? values.url : "");
        formData.append('description', values.description ? values.description : "");
        formData.append('from_time', dayjs(from_time, 'HH:mm').format("HHmm"));
        formData.append('to_time', dayjs(to_time, 'HH:mm').format("HHmm"));
        formData.append('from_date', dayjs(values.from_date).format("DD/MM/YYYY"));

        if (values.to_date) {
            formData.append('to_date', dayjs(values.to_date).format("DD/MM/YYYY"));
        } else {
            formData.append('to_date', dayjs(values.from_date).format("DD/MM/YYYY"));
        }


        formData.append('type', type);



        if (editMode) {
            // formData.append('id', currentSelectedEventID);
            setEventCreate(true)
            let updatedValues = {
                ...values,
                from_time: dayjs(from_time, 'HH:mm').format("HHmm"),
                to_time: dayjs(to_time, 'HH:mm').format("HHmm"),
                from_date: dayjs(values.from_date).format("DD/MM/YYYY"),
                to_date: values.to_date ? dayjs(values.to_date).format("DD/MM/YYYY") : dayjs(values.from_date).format("DD/MM/YYYY"),
                type: type,
                id: currentSelectedEventID
            }
            UpdateSchedules(updatedValues).then((out) => {

                if (out.data.status === 200) {
                    form.resetFields();

                    toast.success(`Event details successfully updated`);
                    setEventCreate(false)
                    LoadData(sDate, lDate)
                    clearForm()
                    setIsModalOpen(false);
                    setEditMode(false)

                } else {
                    toast.error(out.data.message);
                    setEventCreate(false)
                }
            }).catch((err) => {
                setEventCreate(false)
                toast.error("Error");
            })
        } else {
            // let updatedValues = {
            //     ...values,
            //     from_time: dayjs(from_time, 'HH:mm').format("HHmm"),
            //     to_time: dayjs(to_time, 'HH:mm').format("HHmm"),
            //     from_date: dayjs(values.from_date).format("DD/MM/YYYY"),
            //     to_date: dayjs(values.to_date).format("DD/MM/YYYY"),
            //     type: type,
            //     participants: participants.map(obj => obj.value)
            // }
            let participant = participants.map(obj => obj.value)
            if (fileList) {
                formData.append('event_file', fileList[0]);
            }
            if (participants) {
                if (participant.includes(0)) {
                    formData.append('participants', currentUsers.map((e) => e.value));
                } else {
                    formData.append('participants', participant);
                }

            }
            setEventCreate(true)

            createSchedules(formData).then((out) => {

                if (out.data.status === 200) {
                    form.resetFields();
                    setEventCreate(false)
                    toast.success("Event Created Successfully");
                    LoadData(sDate, lDate)
                    // clearForm()
                    setIsModalOpen(false);
                    setEditMode(false)

                } else {
                    setEventCreate(false)
                    toast.error(out.data.message);

                }
            }).catch((err) => {
                console.log(err);
                setEventCreate(false)
                toast.error("Error");
            })
        }


        // console.log("values", values)

        // console.log("values", updatedValues)
        // const newEvent = {

        //     title: values.title,
        //     start: dayjs(values.date).format("DD/MM/YYYY"),
        //     allDay: true,
        //     backgroundColor: getEventColor(),
        //     borderColor: getEventColor(),
        //     extendedProps: {
        //         type: selectedOption,
        //         eventData: updatedValues
        //     },


        // }
        // setAllEvents([...allEvents, newEvent])
        // console.log("events", allEvents)


    };

    const getEventColor = () => {
        if (selectedOption === "Meeting") {
            return "#4B5CBA"
        } else if (selectedOption === "Event") {
            return "#0B8043"
        } else {
            return "#9E69AF"
        }
    }
    const onFinishFailed = (errorInfo) => {

    };

    const handleEventClick = async (clickInfo) => {
        setCommentError(false)
        setfileError(false)
        form.resetFields();
        console.log("clickInfo", clickInfo)

        if (clickInfo.event.extendedProps.type != "action" && clickInfo.event.extendedProps.type != "measure") {
            setSelectedMeeting(clickInfo.event)
            form.setFieldValue('title', clickInfo.event.title)
            form.setFieldValue('description', clickInfo.event.extendedProps.description)
            form.setFieldValue('url', clickInfo.event.extendedProps.url)
            form.setFieldValue('from_date', dayjs(clickInfo.event.extendedProps.from_date))

            if (!dayjs(clickInfo.event.extendedProps.to_date).isSame(dayjs(clickInfo.event.extendedProps.from_date), 'day')) {
                form.setFieldValue('to_date', dayjs(clickInfo.event.extendedProps.to_date))
            }

            form.setFieldValue('from_time', dayjs(clickInfo.event.extendedProps.from_time, 'HH:mm'))
            form.setFieldValue('to_time', dayjs(clickInfo.event.extendedProps.to_time, 'HH:mm'))
            setCurrentEventUrl(clickInfo.event.extendedProps.url)
            let newArr = []

            if (clickInfo.event.extendedProps.participants) {
                // find the organizer of the event and make that user as the first element of the array


                const organizerObject = clickInfo.event.extendedProps.participants.find(obj => obj.user_id === clickInfo.event.extendedProps.created_by);
                if (organizerObject) {
                    organizerObject.organizer = true;
                }

                // Move the organizer object to the first index of the array
                if (organizerObject) {
                    const index = clickInfo.event.extendedProps.participants.indexOf(organizerObject);
                    if (index > -1) {
                        clickInfo.event.extendedProps.participants.splice(index, 1);
                        clickInfo.event.extendedProps.participants.unshift(organizerObject);
                    }

                    newArr = clickInfo.event.extendedProps.participants
                    console.log("Created By ARR", newArr)
                } else {
                    newArr = clickInfo.event.extendedProps.participants
                }
                const user = await getUserById(newArr[0].user_id)
                const userOrganization = user ? user.data.user.agency_name : ""

                newArr[0]["agency"] = userOrganization


                setEventCurrentParticipnts(newArr)
                setEventCurrentComments(clickInfo.event.extendedProps.comments)
                setSelectedOption(clickInfo.event.extendedProps.type)

                if (clickInfo.event.extendedProps.from_date) {
                    setSelectedDate(clickInfo.event.extendedProps.from_date)
                }

                // setSelectedDate2(clickInfo.event.extendedProps.to_date ? clickInfo.event.extendedProps.to_date : undefined)


                setFileList([])
                setFromTime(clickInfo.event.extendedProps.from_time)
                setToTime(clickInfo.event.extendedProps.to_time)

                setCurrentSelectedEventID(clickInfo.event.extendedProps.id)
                setIsModalOpen(true)
                if (window.localStorage.getItem('user_id') == clickInfo.event.extendedProps.created_by) {
                    setEditMode(true)
                    setCreatedUser(true)
                } else {
                    setEditMode(false)
                    setDisableEvent(true)

                }

            }

            if (dayjs(clickInfo.event.extendedProps.from_date).isBefore(dayjs(), 'day')) {
                setDisableEvent(true)
                setPostEvent(true)

            }

        }
    }
    function generateRandomHexCode() {
        console.log("coclossasasadsadsads")
        const characters = '0123456789ABCDEF';
        let hexCode = '#';

        do {
            hexCode = '#';
            for (let i = 0; i < 6; i++) {
                hexCode += characters[Math.floor(Math.random() * 16)];
            }
        } while (hexCode === '#FFFFFF');


        return hexCode;
    }


    const deleteEvent = async () => {
        deleteSchedules({ id: currentSelectedEventID }).then(async (out) => {

            if (out.data.status === 200) {
                form.resetFields();

                toast.success("Event deleted Successfully");
                await LoadData(sDate, lDate);
                setIsModalOpen(false);
            } else {
                toast.error(out.data.message);

            }
        }).catch((err) => {

            toast.error("Error");
        })
        clearForm()
    }

    const addParticipnats = async () => {
        let participant = participants.map(obj => obj.value)

        console.log("Attendieees============>>", participant)
        let data = {
            id: currentSelectedEventID,
            user_id: participant.join(", ")
        }
        addSchedulesParticipants(data).then((out) => {

            if (out.data.status === 200) {
                form.resetFields();
                setIsModalOpen(false);
                toast.success("New Attendees Added Successfully");
                LoadData(sDate, lDate)
                // clearForm()


            } else {
                toast.error("Agency and Attendees are Required");

            }
        }).catch((err) => {

            toast.error("Error");
        })
    }
    const addNewAttachments = async () => {
        setbtnLoading(true)
        const formData = new FormData();
        formData.append('type', disabaleEvent ? 1 : 0);
        formData.append('comment', comment);
        formData.append('id', currentSelectedEventID);
        formData.append('event_file', fileList[0]);



        if (comment !== "" && fileList.length == 0) {
            toast.error("Attachment is required");
            setfileError(true)
            setbtnLoading(false)

        } else if (comment == "" && fileList.length > 0) {
            toast.error("Description is required");
            setCommentError(true)
            setbtnLoading(false)

        } else if (comment !== "" && fileList.length > 0) {
            addSchedulesComments(formData).then((out) => {

                if (out.data.status === 200) {
                    form.resetFields();
                    setFileList([])
                    toast.success("New attachment added successfully");
                    LoadData(sDate, lDate)
                    setIsModalOpen(false);
                    // clearForm()
                    setComment("")

                } else {
                    toast.error("Description is Required");
                    setCommentError(true)

                }
                setbtnLoading(false)
            }).catch((err) => {

                toast.error("Error");
                setbtnLoading(false)
            })
        } else {
            toast.error("Description and the attachment is required");
            setfileError(true)
            setCommentError(true)
            setbtnLoading(false)
        }

    }

    const removeParticipant = async (user) => {

        let formdata = {
            id: user.event_id,
            user_id: user.user_id
        }

        deleteSchedulesParticipants(formdata).then((out) => {

            if (out.data.status === 200) {
                form.resetFields();

                toast.success("Attendee removed Successfully");
                LoadData(sDate, lDate);
                const curParticipants = eventCurrentParticipants;
                setEventCurrentParticipnts(curParticipants.filter((itm) => itm.user_id != user.user_id))
            } else {
                toast.error(out.data.message);

            }
        }).catch((err) => {

            toast.error("Error");
        })
    }

    const FetchFile = async (file) => {
        console.log("file", file)
        let out = await downloadSheduleFiles(file.token)

        let blob = new Blob([out.data], { type: file.type });
        let url = window.URL.createObjectURL(blob);

        let link = document.createElement('a');
        link.href = url;

        link.download = (file.file_name.split("-::-"))[1];

        document.body.appendChild(link);
        link.click();
    }

    const deleteFile = async (file) => {
        let body = {
            id: file.id
        }

        deleteEventFiles(body).then((out) => {
            if (out.data.status === 200) {
                form.resetFields();
                toast.success("File removed Successfully");
                LoadData(sDate, lDate)
                // clearForm()
                const curFiles = commnetsFiles;
                setEventCurrentComments(curFiles.filter((itm) => itm.id != file.id))
            } else {
                toast.error(out.data.message);
            }
        }).catch((err) => {
            toast.error("Error");
        })
        // setIsModalOpen(false)
    }
    const eventContent = (info) => {

        return (
            <Popover placement="top"
                content={<div>
                    {
                        info.event.extendedProps.type == "action" || info.event.extendedProps.type == "measure" ?
                            <div>
                                <Descriptions size="small" title={info.event.extendedProps.type == "action" ? "Action Details" : "Measure Details"} bordered>
                                    <Descriptions.Item span={24} label="Article">{info.event.extendedProps.article ? info.event.extendedProps.article : ""}</Descriptions.Item>
                                    <Descriptions.Item span={3} label="Measure">{info.event.extendedProps.measure ? info.event.extendedProps.measure : ""}</Descriptions.Item>
                                    <Descriptions.Item span={21} label="Definitive Date">{info.event.extendedProps.measure_def_date ? info.event.extendedProps.measure_def_date : ""}</Descriptions.Item>
                                    {info.event.extendedProps.type == "action" && <Descriptions.Item span={3} label="Action">{info.event.extendedProps.action ? info.event.extendedProps.action : ""}</Descriptions.Item>}
                                    {info.event.extendedProps.type == "action" && <Descriptions.Item span={21} label="Action Delivery Date">{info.event.extendedProps.action_def_date ? info.event.extendedProps.action_def_date : ""}</Descriptions.Item>}
                                </Descriptions>
                            </div> : <div>
                                <Descriptions size="small" title={info.event.title} bordered>
                                    <Descriptions.Item span={24} label="Time">{info.event.extendedProps.from_time ? info.event.extendedProps.from_time + " - " + info.event.extendedProps.to_time : ""}</Descriptions.Item>


                                    <Descriptions.Item span={24} label="Location / URL">{info.event.extendedProps.url ? info.event.extendedProps.url : "-"}</Descriptions.Item>

                                </Descriptions>
                            </div>
                    }
                </div>}
                arrow={mergedArrow}>


                <div style={{
                    padding: '4px',
                    gap: '5px',
                    alignItems: 'center',
                    display: 'flex',
                    // margin: "8px 0px",
                    background: info.event.extendedProps.backgroundColor,
                    borderRadius: 5,
                    width: "100%",
                    height: "100%"
                }}>
                    {
                        info.event.extendedProps.type == "action" ?
                            <RocketOutlined style={{ fontSize: 18, color: "white" }} /> :
                            info.event.extendedProps.type == "measure" ?
                                <BlockOutlined style={{ fontSize: 18, color: "white" }} /> :
                                info.event.extendedProps.type == "Meeting" ?
                                    <BiCameraMovie style={{ fontSize: 18, color: "white" }} /> :
                                    info.event.extendedProps.type == "Event" ?
                                        <AppstoreOutlined style={{ fontSize: 18, color: "white" }} /> :
                                        <BuildOutlined style={{ fontSize: 18, color: "white" }} />
                    }


                    <Typography style={{ fontSize: 12, fontWeight: 500, color: "white" }}>    {info.event.title.length > 20 ? info.event.title.slice(0, 20) + "..." : info.event.title}</Typography>

                </div>
            </Popover>
        );
    };

    const getGradient = (type) => {
        if (type == "Meeting") {
            return `linear-gradient( to right, ${CalenderColors.MEETING}  100%, #ABDCFF 100%)`
        } else if (type == "Event") {
            return `linear-gradient( to right, ${CalenderColors.EVENT}  100%, #F6CEEC 100%)`
        } else if (type == "To Do") {
            return `linear-gradient( 135deg, ${CalenderColors.TODO} 100%, #FEB692 100%)`
        } else if (type == "measure") {
            return `linear-gradient( 135deg, ${CalenderColors.MEASURE}  100%, #81FBB8 100%)`
        } else if (type == "action") {
            return `linear-gradient( 135deg, ${CalenderColors.ACTIONS}  100%, #FDEB71 100%)`
        }
    }



    const props = {
        name: 'file',
        multiple: false,
        // Action: ,

        onChange(info) {

            info.fileList = []


            const { status } = info.file;
            if (status !== 'uploading') {

            }
            if (status === 'done') {
                // setFileList(file)
                // toast.success(`${info.file.name} file uploaded successfully.`);
                handleFileUpload(info.file);
            } else if (status === 'error') {

                // toast.error(`${info.file.name} file upload failed.`);
            }
        },
        onDrop(e) {
            console.log('Dropped files', e);
        },
    };

    async function handleFileUpload(file) {

        setFileList([file])


    }

    useEffect(() => {
        getUserDetails()



    }, []);
    const [user, setUser] = useState({})
    const getUserDetails = async function () {

        const user = await getUserData()
        console.log(user);
        setUser(user)

    }
    const validateURL = (_, value) => {
        if (value.startsWith('http' || 'ftp')) {
            if (!value || /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/.test(value)) {
                return Promise.resolve();
            }
            return Promise.reject(new Error('Please enter a valid URL'));
        } else {
            return Promise.resolve();
        }

    };
    const handleEventPositioned = (info) => {
        const { event, el } = info;
        const eventElements = el.querySelectorAll('.fc-event');
        const eventRects = Array.from(eventElements).map((el) => el.getBoundingClientRect());

        // Check for overlaps and adjust the event positions if necessary
        for (let i = 0; i < eventRects.length; i++) {
            for (let j = i + 1; j < eventRects.length; j++) {
                if (isOverlap(eventRects[i], eventRects[j])) {
                    // Adjust the positions of overlapping events
                    const topOffset = calculateTopOffset(eventRects[i], eventRects[j]);
                    eventElements[j].style.top = `${topOffset}px`;
                }
            }
        }
    };

    const isOverlap = (rect1, rect2) => {
        // Implement your logic to check for overlap between two event rectangles
        // Example logic: return true if there is vertical overlap
        return rect1.top < rect2.bottom && rect1.bottom > rect2.top;
    };

    const calculateTopOffset = (rect1, rect2) => {
        // Implement your logic to calculate the necessary top offset
        // Example logic: move the second event below the first event by a fixed amount
        return rect1.bottom - rect2.top + 10;
    };

    return (
        <div style={{ backgroundColor: "#F5F8FF", }} className='custom-scroll-div'>
            <div className="bgSidePanel" style={{ overflow: "hidden", background: NewColorTheme.primaryColor }}></div>
            <div className="sidepanel-left" style={{ background: "white" }}></div>
            <PageHeader title="My Calendar" user={user} calender={true} />


            <div style={{ display: "flex", flexDirection: "column", gap: 20, padding: 50 }}>
                <Row gutter={16}>
                    <Col xs={24} md={6} style={{ height: '100%', }}>
                        {!loading ?
                            <SideBar todoList={todoList} />
                            : <Card style={{ padding: 10 }}>
                                <Skeleton
                                    active
                                    avatar
                                    paragraph={{
                                        rows: 4,
                                    }}
                                />
                            </Card>
                        }



                    </Col>
                    <Col xs={24} md={18}>
                        <Card style={{ padding: 10 }}>


                            <div style={{
                                display: "flex",
                                flexDirection: "column",
                                gap: 10
                            }}>


                                <Row gutter={12}>
                                    <Col span={13}>
                                        <div>

                                            <Space>

                                                <Badge

                                                    size='default'
                                                    text="Meeting"
                                                    color={CalenderColors.MEETING}
                                                />
                                                <Badge
                                                    text="Event"
                                                    color={CalenderColors.EVENT}
                                                />
                                                <Badge
                                                    text="  To Do"
                                                    color={CalenderColors.TODO}
                                                />
                                                <Badge
                                                    text="Measure"
                                                    color={CalenderColors.MEASURE}
                                                />
                                                <Badge
                                                    text="Action"
                                                    color={CalenderColors.ACTIONS}
                                                />

                                            </Space>
                                            {/* <Space size={[0, 8]} wrap>
                                                <Tag style={{

                                                    fontSize: '14px',
                                                    height: '40px',
                                                    lineHeight: '40px',
                                                    display: 'flex',
                                                    textAlign: 'center',
                                                    width: '109px',
                                                    justifyContent: 'start',
                                                    alignItems: 'center',
                                                    gap: '5px'
                                                }} icon={<BiCameraMovie />} color={CalenderColors.MEETING}>
                                                    Meeting
                                                </Tag>
                                                <Tag style={{

                                                    fontSize: '14px',
                                                    height: '40px',
                                                    lineHeight: '40px',
                                                    display: 'flex',
                                                    textAlign: 'center',
                                                    width: '109px',
                                                    justifyContent: 'start',
                                                    alignItems: 'center',
                                                    gap: '5px'
                                                }} icon={<AppstoreOutlined />} color={CalenderColors.EVENT}>
                                                    Event
                                                </Tag>
                                                <Tag style={{

                                                    fontSize: '14px',
                                                    height: '40px',
                                                    lineHeight: '40px',
                                                    display: 'flex',
                                                    textAlign: 'center',
                                                    width: '109px',
                                                    justifyContent: 'start',
                                                    alignItems: 'center',
                                                    gap: '5px'
                                                }} icon={<BuildOutlined />} color={CalenderColors.TODO}>
                                                    To Do
                                                </Tag>
                                                <Tag style={{

                                                    fontSize: '14px',
                                                    height: '40px',
                                                    lineHeight: '40px',
                                                    display: 'flex',
                                                    textAlign: 'center',
                                                    width: '109px',
                                                    justifyContent: 'start',
                                                    alignItems: 'center',
                                                    gap: '5px'
                                                }} icon={<BlockOutlined />} color={CalenderColors.MEASURE}>
                                                    Measure
                                                </Tag>
                                                <Tag style={{

                                                    fontSize: '14px',
                                                    height: '40px',
                                                    lineHeight: '40px',
                                                    display: 'flex',
                                                    textAlign: 'center',
                                                    width: '109px',
                                                    justifyContent: 'start',
                                                    alignItems: 'center',
                                                    gap: '5px'
                                                }} icon={<RocketOutlined />} color={CalenderColors.ACTIONS}>
                                                    Action
                                                </Tag>

                                            </Space> */}
                                        </div>
                                    </Col>
                                    <Col span={10} offset={1}>

                                        <div style={{ display: "flex", justifyContent: "flex-end", width: "100%", }}>

                                            <Space>



                                                <Button type='primary' size="middle" htmlType="button" icon={<SaveOutlined />}
                                                    onClick={() => {
                                                        setCommentError(false)
                                                        setfileError(false)
                                                        setFileList([])
                                                        // setSelectedDate(dayjs())
                                                        setSelectedDate2(dayjs())
                                                        form.setFieldValue('from_date', dayjs())
                                                        form.setFieldValue('to_date', undefined)
                                                        form.setFieldValue('title', "")
                                                        form.setFieldValue('description', "")
                                                        form.setFieldValue('from_time', undefined)
                                                        form.setFieldValue('to_time', undefined)
                                                        form.setFieldValue('url', "")

                                                        setIsModalOpen(true)
                                                    }}
                                                // style={{ background: 'green' }}

                                                >
                                                    Add New Event
                                                </Button>
                                                <Button type='primary' size="middle" htmlType="button" icon={<EyeOutlined />}
                                                    onClick={() => {

                                                        window.location.href = "/allevents"
                                                    }}
                                                >
                                                    View All Events
                                                </Button>
                                            </Space>

                                        </div>
                                    </Col>



                                </Row>

                                <FullCalendar
                                    headerToolbar={{
                                        left: 'prev,next today',
                                        center: 'title',
                                        right: 'dayGridMonth,timeGridWeek,timeGridDay'
                                    }}
                                    slotEventOverlap={false}
                                    showNonCurrentDates={false}
                                    eventOverlap={false}
                                    eventPositioned={handleEventPositioned}
                                    plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                                    initialView="dayGridMonth"
                                    editable={false}
                                    selectable={true}
                                    select={handleDateClick}
                                    weekends={true}
                                    // eventsSet={handleEvents}
                                    events={allEvents}
                                    eventClick={handleEventClick}
                                    eventContent={eventContent}
                                    dayMaxEventRows={true}
                                    views={{
                                        timeGrid: { dayMaxEventRows: 4 },
                                        dayGrid: { dayMaxEventRows: 4 }
                                    }}
                                    style={{
                                        padding: 10,
                                        backgroundColor: "white",
                                        borderRadius: 8
                                    }}
                                    // aspectRatio={10}
                                    // Event triggered when the calendar's date range is changed (including navigation)
                                    datesSet={function (dateInfo) {
                                        var firstDate = dateInfo.startStr; // First date of the displayed month
                                        var lastDate = dateInfo.endStr; // Last date of the displayed month
                                        setStDate(firstDate)
                                        setLdDate(lastDate)
                                        LoadData(dayjs(firstDate).format("DD/MM/YYYY"), dayjs(lastDate).format("DD/MM/YYYY"))
                                        console.log("First date of the month: " + firstDate);
                                        console.log("Last date of the month: " + lastDate);
                                    }}

                                />


                            </div>
                            <Modal
                                closable={true}
                                maskClosable={false}
                                className="custom-modal"
                                width={700}
                                footer={false}
                                onCancel={handleCancel}
                                title={
                                    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                        <div>     <Typography className='custom-modal-title' level={2}>{editMode ? `Update ${selectedOption}` : (!createdUser && disabaleEvent) ? `View ${selectedOption}` : "Add New Event/ Meeting or Task"}</Typography></div>

                                    </div>


                                } open={isModalOpen} onOk={handleOk} centered>

                                <div className='custom-modal-body'>

                                    <Space className='custom-scroll-div' direction='vertical' style={{ width: '100%', height: "70vh", display: "flex", overflow: 'auto', overflowX: "hidden", padding: "0px 10px" }}>
                                        <Form
                                            form={form}
                                            layout="vertical"
                                            onFinish={onFinish}
                                            onFinishFailed={onFinishFailed}
                                            scrollToFirstError
                                        >

                                            <Row gutter={[16, 16]}>
                                                {!editMode &&
                                                    <Col xs={24} >
                                                        <div style={{ display: "flex", width: "100%", justifyContent: "center" }}>
                                                            <ConfigProvider
                                                                theme={{
                                                                    token: {
                                                                        colorPrimary: MainColors.PRIMARY,
                                                                        // colorPrimaryActive: '#265182',
                                                                    },
                                                                }}
                                                            >
                                                                <Segmented
                                                                    size="large"
                                                                    disabled={disabaleEvent ? true : false}
                                                                    className='cutome-segment'
                                                                    onChange={setSegmentBody}
                                                                    value={selectedOption}
                                                                    options={[
                                                                        {
                                                                            label: 'Meeting',
                                                                            value: 'Meeting',
                                                                            icon: <VideoCameraOutlined />,
                                                                        },
                                                                        {
                                                                            label: 'Event',
                                                                            value: 'Event',
                                                                            icon: <AppstoreOutlined />,
                                                                        },
                                                                        {
                                                                            label: 'To Do',
                                                                            value: 'To Do',
                                                                            icon: <FieldTimeOutlined />,
                                                                        },
                                                                    ]}
                                                                />
                                                            </ConfigProvider>
                                                        </div>



                                                    </Col>
                                                }

                                                <Col xs={24}>
                                                    <Row gutter={[8, 8]}>
                                                        <Col xs={2}>
                                                            <AppstoreOutlined style={{ fontSize: 22 }} />
                                                        </Col>
                                                        <Col xs={22}>

                                                            {disabaleEvent ?
                                                                <div style={{ display: 'flex', flexDirection: "column", alignItems: 'flex-start' }}>
                                                                    <Typography.Title level={5}> Title</Typography.Title>
                                                                    <Typography> {currentSelected?.title}</Typography>
                                                                </div>
                                                                :
                                                                <div style={{ display: 'flex', flexDirection: "column", alignItems: 'flex-start' }}>

                                                                    <Form.Item
                                                                        style={{ width: "100%" }}
                                                                        label="Title" name="title" required
                                                                        rules={[{
                                                                            required: true,
                                                                            message: <p style={{ marginLeft: "12px" }}>Title is required</p>,
                                                                        }]}
                                                                    >
                                                                        <Input disabled={disabaleEvent ? true : false} placeholder={selectedOption == "Meeting" ? "Subject" : selectedOption == "Event" ? "Event Title" : "Task Title"} style={{ width: "100%", margin: 0 }} />
                                                                    </Form.Item>
                                                                </div>
                                                            }

                                                        </Col>

                                                    </Row>


                                                </Col>
                                                <Col xs={24}>
                                                    <Row gutter={[8, 8]} align={"top"}>
                                                        <Col xs={2}>
                                                            <CalendarOutlined style={{ fontSize: 22 }} />
                                                        </Col>
                                                        <Col xs={22}>
                                                            <Row gutter={16}>
                                                                <Col xs={12}>
                                                                    {disabaleEvent ?

                                                                        <div style={{ display: 'flex', flexDirection: "column", alignItems: 'flex-start' }}>
                                                                            <Typography.Title level={5}> Schedule Date</Typography.Title>
                                                                            {currentSelected?.extendedProps.from_date !== currentSelected?.extendedProps.to_date ?
                                                                                <Typography> {dayjs(currentSelected?.extendedProps.from_date).format('DD/MM/YYYY') + " - " + dayjs(currentSelected?.extendedProps.to_date).format("DD/MM/YYYY")}</Typography>

                                                                                : <Typography> {dayjs(currentSelected?.extendedProps.from_date).format('DD/MM/YYYY')}</Typography>
                                                                            }
                                                                        </div>
                                                                        :
                                                                        <div style={{ display: 'flex', flexDirection: "column", alignItems: 'flex-start' }}>

                                                                            <Form.Item
                                                                                style={{ width: "100%" }}
                                                                                label="Schedule From"
                                                                                name="from_date" required rules={[{
                                                                                    required: true,
                                                                                    message: <p style={{ marginLeft: "12px" }}>Date is required</p>,
                                                                                }]} valuePropName='date'>
                                                                                <DatePicker
                                                                                    disabled={disabaleEvent ? true : false}
                                                                                    placeholder='Start Date'
                                                                                    onChange={(e) => {
                                                                                        onDateChange(e, 'Start Date')
                                                                                        if (editMode) {
                                                                                            form.setFieldValue('to_date', undefined)
                                                                                        }
                                                                                    }}
                                                                                    value={dayjs(selectedDate).isValid() ? dayjs(selectedDate) : null}
                                                                                    format={'DD/MM/YYYY'}
                                                                                    style={{ width: "100%", borderRadius: "5px" }} />
                                                                            </Form.Item>

                                                                        </div>
                                                                    }

                                                                </Col>

                                                                {console.log("selected date", selectedDate, selectedDate2)}
                                                                {!disabaleEvent && (dayjs(selectedDate) != dayjs(selectedDate2)) &&
                                                                    <Col xs={12}>
                                                                        <div style={{ display: 'flex', flexDirection: "column", alignItems: 'flex-start' }}>

                                                                            <Form.Item
                                                                                style={{ width: "100%" }}
                                                                                label="Schedule To"
                                                                                name="to_date"
                                                                            //  valuePropName='date'
                                                                            >
                                                                                <DatePicker
                                                                                    disabled={disabaleEvent ? true : false}
                                                                                    placeholder='End Date'
                                                                                    onChange={onDateChange2}
                                                                                    value={dayjs(selectedDate2).isValid() ? dayjs(selectedDate2) : null}
                                                                                    format={'DD/MM/YYYY'}
                                                                                    disabledDate={(current) => {
                                                                                        // Disable selecting dates that are before today
                                                                                        if (!editMode) {
                                                                                            return current && (selectedDate ? current < selectedDate : current < dayjs().startOf('day'));

                                                                                        } else {
                                                                                            return current && current < form.getFieldValue('from_date');
                                                                                        }
                                                                                    }}


                                                                                    style={{ width: "100%", borderRadius: "5px" }} />
                                                                            </Form.Item>

                                                                        </div>
                                                                    </Col>
                                                                }


                                                            </Row>


                                                        </Col>

                                                    </Row>


                                                </Col>
                                                <Col xs={24}>
                                                    <Row gutter={[8, 8]} align={"top"}>
                                                        <Col xs={2}>
                                                            <ClockCircleOutlined style={{ fontSize: 22 }} />
                                                        </Col>
                                                        <Col xs={22}>
                                                            {disabaleEvent ?
                                                                <div style={{ display: 'flex', flexDirection: "column", alignItems: 'flex-start' }}>
                                                                    <Typography.Title level={5}> Schedule Time</Typography.Title>
                                                                    <Typography> {currentSelected?.extendedProps.from_time + " - " + currentSelected?.extendedProps.to_time}</Typography>
                                                                </div>
                                                                :
                                                                <Row gutter={16} >
                                                                    <Col xs={12} >
                                                                        <Form.Item
                                                                            style={{ width: "100%" }}
                                                                            label="Start Time"
                                                                            name="from_time" required rules={[{
                                                                                required: true,
                                                                                message: <p style={{ marginLeft: "12px" }}>From time is required</p>,
                                                                            }]}>
                                                                            <TimePicker
                                                                                minuteStep={15} hourStep={1}
                                                                                disabled={disabaleEvent ? true : false}
                                                                                style={{ width: "100%" }}
                                                                                placeholder='Start Time'
                                                                                onChange={onChangeFromTime}
                                                                                value={from_time}
                                                                                defaultOpenValue={dayjs('00:00', 'HH:mm')}
                                                                                format={'HH:mm'}
                                                                                changeOnBlur={true}
                                                                                showNow={false}
                                                                                popupClassName={'modern-popup'}
                                                                            />

                                                                        </Form.Item>
                                                                    </Col>
                                                                    <Col xs={12}>
                                                                        <Form.Item
                                                                            style={{ width: "100%" }}
                                                                            label="End Time"
                                                                            name="to_time" required rules={[{
                                                                                required: true,
                                                                                message: <p style={{ marginLeft: "12px" }}>To time is required</p>,
                                                                            }]}>
                                                                            <TimePicker
                                                                                minuteStep={15} hourStep={1}
                                                                                disabled={disabaleEvent ? true : false}
                                                                                style={{ width: "100%" }}
                                                                                placeholder='End Time'
                                                                                onChange={onChangeToTime}
                                                                                value={to_time}
                                                                                defaultOpenValue={dayjs('00:00', 'HH:mm')}
                                                                                format={'HH:mm'}
                                                                                disabledHours={() => {
                                                                                    const fromHour = from_time ? parseInt(from_time.split(":")[0], 10) : null;
                                                                                    return Array.from(Array(fromHour).keys());
                                                                                }}
                                                                                disabledMinutes={(selectedHour) => {
                                                                                    if (from_time) {
                                                                                        const fromHour = parseInt(from_time.split(":")[0], 10);
                                                                                        const fromMinute = parseInt(from_time.split(":")[1], 10);
                                                                                        if (selectedHour === fromHour) {
                                                                                            return Array.from(Array(fromMinute).keys());
                                                                                        }
                                                                                    }
                                                                                    return [];
                                                                                }}
                                                                                changeOnBlur={true}
                                                                                showNow={false}
                                                                                popupClassName={'modern-popup'}
                                                                            />
                                                                        </Form.Item>
                                                                    </Col>

                                                                </Row>




                                                            }

                                                        </Col>

                                                    </Row>


                                                </Col>
                                                {disabaleEvent ?
                                                    <Col xs={24}>
                                                        <Row gutter={[8, 8]}>
                                                            <Col xs={2}>
                                                                <EnvironmentOutlined style={{ fontSize: 22 }} />
                                                            </Col>
                                                            <Col xs={22}>
                                                                <div style={{ display: 'flex', flexDirection: "column", alignItems: 'flex-start' }}>
                                                                    <Typography.Title level={5}> Location/ URL</Typography.Title>
                                                                    {cuurentURL.startsWith('http') ?
                                                                        <a rel="noopener noreferrer" target="_blank" href={cuurentURL}>{cuurentURL}</a>
                                                                        : <Typography>{cuurentURL}</Typography>
                                                                    }
                                                                </div>
                                                            </Col>

                                                        </Row>


                                                    </Col>
                                                    :
                                                    <Col xs={24}>
                                                        <Row gutter={[8, 8]}>
                                                            <Col xs={2}>
                                                                <EnvironmentOutlined style={{ fontSize: 22 }} />
                                                            </Col>
                                                            <Col xs={22}>
                                                                <div style={{ display: 'flex', flexDirection: "column", alignItems: 'flex-start' }}>

                                                                    <Form.Item
                                                                        style={{ width: "100%" }}
                                                                        label="Location/ URL" name="url"
                                                                        rules={[
                                                                            {
                                                                                validator: validateURL
                                                                            }
                                                                        ]}
                                                                    >
                                                                        <Input disabled={disabaleEvent ? true : false} placeholder={"Enter Location URL"} style={{ width: "100%", margin: 0 }}

                                                                        />
                                                                    </Form.Item>
                                                                </div>
                                                            </Col>

                                                        </Row>


                                                    </Col>
                                                }

                                                <Col xs={24}>
                                                    <Row gutter={[8, 8]} align={"top"}>
                                                        <Col xs={2}>
                                                            <ProfileOutlined style={{ fontSize: 22 }} />
                                                        </Col>
                                                        <Col xs={22}>

                                                            {disabaleEvent ?
                                                                <div style={{ display: 'flex', flexDirection: "column", alignItems: 'flex-start' }}>
                                                                    <Typography.Title level={5}> Description</Typography.Title>
                                                                    <Typography> {currentSelected?.extendedProps.description}</Typography>
                                                                </div>

                                                                :
                                                                <div style={{ display: 'flex', flexDirection: "column", alignItems: 'flex-start' }}>
                                                                    {/* <Text type='secondary'>Description</Text> */}
                                                                    <Form.Item
                                                                        style={{ width: "100%" }}
                                                                        label="Description "
                                                                        name="description">
                                                                        <TextArea disabled={disabaleEvent ? true : false} rows={4} />
                                                                    </Form.Item>
                                                                </div>
                                                            }


                                                        </Col>

                                                    </Row>


                                                </Col>
                                                {eventCurrentParticipants?.length > 0 &&
                                                    <Col xs={24}>
                                                        <Row gutter={[8, 8]} align={"top"}>
                                                            <Col xs={2}>
                                                                <CrownOutlined style={{ fontSize: 22 }} />
                                                            </Col>
                                                            <Col xs={22}>
                                                                <div style={{ display: "flex", flexDirection: "column" }}>
                                                                    <Text type='secondary' style={{ color: 'black', fontWeight: 700 }}>Organizer</Text>
                                                                    <div style={{
                                                                        display: "flex",
                                                                        marginTop: 10,
                                                                        gap: 10,
                                                                        alignItems: "center"
                                                                    }}>

                                                                        <Avatar
                                                                            style={{
                                                                                backgroundColor: generateRandomHexCode()
                                                                            }}
                                                                        >
                                                                            {console.log("Parti", eventCurrentParticipants[0])}
                                                                            {eventCurrentParticipants[0].participant
                                                                                .charAt(eventCurrentParticipants[0].participant
                                                                                    .indexOf('.') + 1).toUpperCase()}
                                                                        </Avatar>
                                                                        <div style={{ display: 'flex', flexDirection: "column", }}>
                                                                            <Typography>{(eventCurrentParticipants[0].designation ? eventCurrentParticipants[0].designation + " - " : "") + eventCurrentParticipants[0].participant}</Typography>
                                                                            {eventCurrentParticipants[0].agency && <Typography style={{ fontSize: 13 }}>{eventCurrentParticipants[0].agency}</Typography>}


                                                                        </div>





                                                                    </div>
                                                                </div>

                                                            </Col>

                                                        </Row>

                                                    </Col>
                                                }

                                                {(eventCurrentParticipants?.length > 0) ?
                                                    <Col xs={24}>
                                                        <Row gutter={[8, 8]} align={"top"}>
                                                            <Col xs={2}>
                                                                <UserOutlined style={{ fontSize: 22 }} />
                                                            </Col>
                                                            <Col xs={22}>
                                                                <div style={{ display: "flex", flexDirection: "column" }}>
                                                                    <Text type='secondary' style={{ color: 'black', fontWeight: 700 }}>Invited Participants</Text>
                                                                    <Avatar.Group
                                                                        style={{ marginTop: 5 }}
                                                                        maxCount={10}
                                                                        maxPopoverTrigger="click"
                                                                        size="large"
                                                                        maxStyle={{ color: '#f56a00', backgroundColor: '#fde3cf', cursor: 'pointer' }}
                                                                    >

                                                                        {eventCurrentParticipants.slice(1).map((element, key) => {
                                                                            return <Popover key={key} title={(element.designation ? element.designation + " - " : "") + element.participant} placement="top" content={
                                                                                !disabaleEvent &&
                                                                                <div>
                                                                                    {element.organizer ? <Tag>organizer</Tag> : null}
                                                                                    <Button danger onClick={() => removeParticipant(element)}>Remove Attendee</Button>
                                                                                </div>
                                                                            }>
                                                                                <Badge

                                                                                    offset={[5, 5]}
                                                                                    count={element.organizer ?
                                                                                        <CrownFilled
                                                                                            style={{
                                                                                                color: '#FFD700',
                                                                                            }}
                                                                                        /> : 0
                                                                                    }
                                                                                >
                                                                                    <Avatar style={{ backgroundColor: generateRandomHexCode() }}>{element.participant.charAt(element.participant.indexOf('.') + 1).toUpperCase()}</Avatar>
                                                                                </Badge>
                                                                            </Popover>


                                                                        })}
                                                                    </Avatar.Group>
                                                                </div>


                                                            </Col>

                                                        </Row>

                                                    </Col>
                                                    : editMode && <Col xs={24}>
                                                        <Row gutter={[8, 8]} align={"top"}>
                                                            <Col xs={2}>
                                                                <UserOutlined style={{ fontSize: 22 }} />
                                                            </Col>
                                                            <Col xs={22}>
                                                                <Alert message="No Current Attendencies" type="info" showIcon />

                                                            </Col>

                                                        </Row>

                                                    </Col>
                                                }
                                                {
                                                    (commnetsFiles?.length > 0) ?

                                                        <Col xs={24}>

                                                            <Row gutter={[8, 8]} align={"top"}>
                                                                <Col xs={2}>
                                                                    <CloudUploadOutlined style={{ fontSize: 22 }} />
                                                                </Col>
                                                                <Col xs={22}>
                                                                    <Text type='secondary' style={{ color: 'black', fontWeight: 700 }}>Attachments</Text>

                                                                    {commnetsFiles?.filter(e => e.type == 0).map((element, key) => {

                                                                        if (element.file_name) {
                                                                            return <div className="inquiry-btn" style={{ background: "white", borderRadius: 10, padding: "5px 20px 5px 0px", width: "100%", display: "flex", justifyContent: "space-between", alignItems: "center" }} onClick={() => FetchFile(element)}>


                                                                                <div style={{ display: "flex", flexDirection: "column" }}>
                                                                                    <span> <i>Description :</i>{element.comment ? element.comment : ""}</span>
                                                                                    <img src="/file.png" alt="file" style={{ height: 50, width: 50 }} />
                                                                                    {element?.file_name && (element?.file_name.toString().split("-::-"))[1]}
                                                                                </div>
                                                                                {createdUser && !disabaleEvent && <Tooltip title="Delete File">
                                                                                    <Button shape="circle" onClick={(e) => {
                                                                                        e.stopPropagation()
                                                                                        deleteFile(element)
                                                                                    }

                                                                                    } icon={<DeleteOutlined />} />
                                                                                </Tooltip>}

                                                                            </div>
                                                                        } else {
                                                                            <span> <i>Description :</i>{element.comment ? element.comment : ""}</span>
                                                                        }


                                                                    })}


                                                                </Col>

                                                            </Row>

                                                        </Col>
                                                        : editMode && <Col xs={24}>
                                                            <Row gutter={[8, 8]} align={"top"}>
                                                                <Col xs={2}>
                                                                    <CloudUploadOutlined style={{ fontSize: 22 }} />
                                                                </Col>
                                                                <Col xs={22}>
                                                                    <Alert message="No Attachments for this event" type="info" showIcon />

                                                                </Col>

                                                            </Row>

                                                        </Col>
                                                }

                                                {!disabaleEvent &&
                                                    <Col xs={24}>
                                                        <Row gutter={[16, 16]} align={"top"}>
                                                            <Col xs={2}>

                                                                <UsergroupAddOutlined style={{ fontSize: 22 }} />
                                                            </Col>
                                                            <Col xs={22}>
                                                                <div style={{ display: 'flex', flexDirection: "column", alignItems: 'flex-start', gap: 8 }}>
                                                                    <Text type='secondary'>Invitees</Text>
                                                                    <Row gutter={[8, 8]} style={{ width: "100%" }}>
                                                                        <Col span={24}>
                                                                            <Form.Item
                                                                                label="Select Agency"
                                                                                name="agency"

                                                                                required rules={[{
                                                                                    required: editMode ? false : true,
                                                                                    message: <p style={{ marginLeft: "12px" }}>Agency is required</p>,
                                                                                }]}
                                                                            >
                                                                                <Select
                                                                                    disabled={disabaleEvent ? true : false}
                                                                                    value={selectedAgencies}
                                                                                    // maxTagCount='responsive'
                                                                                    mode='multiple'
                                                                                    placeholder="Select An Agency"
                                                                                    allowClear
                                                                                    style={{ width: "100%" }}
                                                                                    options={filteredOptions}
                                                                                    onChange={selectAgency}
                                                                                    onSearch={onSearchSel}
                                                                                    filterOption={(input, option) =>
                                                                                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                                                                    }
                                                                                />
                                                                            </Form.Item>
                                                                        </Col>
                                                                        <Col span={24}>
                                                                            <Form.Item
                                                                                label="Select Attendees"
                                                                                name="participants"

                                                                                required rules={[{
                                                                                    required: editMode ? false : true,
                                                                                    message: <p style={{ marginLeft: "12px" }}>participants are required</p>,
                                                                                }]}
                                                                            >
                                                                                <Select
                                                                                    ref={selectRef}
                                                                                    disabled={disabaleEvent ? true : false}
                                                                                    showSearch
                                                                                    mode='multiple'
                                                                                    placeholder="Select a person"
                                                                                    optionFilterProp="children"
                                                                                    onChange={onChangeSel}
                                                                                    onSearch={onSearchSel}
                                                                                    style={{ width: "100%" }}
                                                                                    value={participants}
                                                                                    filterOption={(input, option) =>
                                                                                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                                                                    }
                                                                                    options={currentUsers}
                                                                                />
                                                                            </Form.Item>
                                                                        </Col>






                                                                    </Row>



                                                                </div>

                                                            </Col>

                                                        </Row>


                                                    </Col>}

                                                {editMode && !disabaleEvent && <Col xs={24}>
                                                    <div style={{ display: "flex", justifyContent: "flex-end", width: "100%" }}>

                                                        <Button type='primary' htmlType="button" onClick={addParticipnats} icon={<SaveOutlined />} style={{ background: 'green' }}>
                                                            Add New Attendees
                                                        </Button>




                                                    </div>
                                                </Col>}
                                                {(editMode || disabaleEvent) &&
                                                    <Col xs={24}>
                                                        <Divider orientation="left" orientationMargin="0">
                                                            <Typography style={{ fontSize: 18 }}>   {postEvent ? "Post Event Details" : "Pre Event Details"}</Typography>

                                                        </Divider>

                                                    </Col>
                                                }

                                                {(commnetsFiles?.filter(e => e.type == 1).length > 0) ?
                                                    <Col xs={24}>
                                                        <Row gutter={[8, 8]} align={"top"}>
                                                            <Col xs={2}>
                                                                <CloudUploadOutlined style={{ fontSize: 22 }} />
                                                            </Col>
                                                            <Col xs={22}>
                                                                <Text style={{
                                                                    color: 'rgba(0, 0, 0, 0.88)',
                                                                    fontSize: '14px',
                                                                    fontWeight: 700
                                                                }} type='secondary'>Uploaded Attachments</Text>

                                                                {commnetsFiles?.filter(e => e.type == 1).map((element, key) => {
                                                                    return <>
                                                                        {element.file_name ?
                                                                            <Descriptions key={key} size="small" bordered style={{ marginTop: "10px" }}>
                                                                                <Descriptions.Item span={24} label="Description">{element.comment ? element.comment : ""}</Descriptions.Item>
                                                                                <Descriptions.Item span={24} label="Attachment">
                                                                                    <div className="inquiry-btn" onClick={() => FetchFile(element)}>
                                                                                        <img src="/file.png" alt="file" style={{ height: 50, width: 50 }} />
                                                                                        {element?.file_name && (element?.file_name.toString().split("-::-"))[1]}
                                                                                    </div>
                                                                                </Descriptions.Item>
                                                                            </Descriptions>

                                                                            :
                                                                            <Descriptions key={key} size="small" bordered style={{ marginTop: "10px" }}>
                                                                                <Descriptions.Item span={24} label="Description">{element.comment ? element.comment : ""}</Descriptions.Item>

                                                                            </Descriptions>

                                                                        }



                                                                    </>




                                                                })}


                                                            </Col>

                                                        </Row>

                                                    </Col> : disabaleEvent && <Col xs={24}>
                                                        <Row gutter={[8, 8]} align={"top"}>
                                                            <Col xs={2}>
                                                                <CloudUploadOutlined style={{ fontSize: 22 }} />
                                                            </Col>
                                                            <Col xs={22}>
                                                                <Alert message="No Attachments for this event" type="info" showIcon />

                                                            </Col>

                                                        </Row>

                                                    </Col>


                                                }


                                                <Col xs={24}>
                                                    {(disabaleEvent || editMode) &&
                                                        <Row gutter={[8, 8]} align={"top"}>
                                                            <Col xs={2}>
                                                                <ContainerOutlined style={{ fontSize: 22 }} />
                                                            </Col>
                                                            <Col xs={22}>
                                                                <div style={{ display: 'flex', flexDirection: "column", alignItems: 'flex-start', width: "100%" }}>
                                                                    <Text type='secondary'>Description</Text>
                                                                    <Form.Item name="comment"
                                                                        style={{ width: "100%" }}
                                                                        rules={[{
                                                                            required: disabaleEvent ? true : false,
                                                                            message: <p style={{ marginLeft: "12px" }}>Description is required</p>,
                                                                        },



                                                                        ]}
                                                                    >

                                                                        <TextArea rows={3} style={{ margin: "10px 0", width: "100%" }} placeholder='Description' value={comment}
                                                                            onChange={(e) => {
                                                                                setCommentError(false)
                                                                                setComment(e.target.value)
                                                                            }}
                                                                        />
                                                                    </Form.Item>
                                                                    {commentError && <span style={{
                                                                        color: 'red',
                                                                        fontSize: '10px',
                                                                        margin: '-17px 0px 10px'
                                                                    }}>Description is required</span>}



                                                                </div>

                                                            </Col>

                                                        </Row>
                                                    }
                                                    <Row gutter={[8, 8]} align={"top"}>
                                                        <Col xs={2}>
                                                            <CloudUploadOutlined style={{ fontSize: 22 }} />
                                                        </Col>
                                                        <Col xs={22}>
                                                            <Alert style={{ marginBottom: "20px" }} type='info' message=
                                                                {"Please ensure that only one attachment is uploaded at a time, and the maximum file size should be 5MB"}
                                                            ></Alert>
                                                            <div style={{ display: 'flex', flexDirection: "column", alignItems: 'flex-start', width: "100%" }}>

                                                                <Text type='secondary'>Attachments</Text>
                                                                <Dragger
                                                                    fileList={fileList}
                                                                    className='custom-dragger'
                                                                    style={{ width: "100%", marginTop: "16px !important" }}
                                                                    maxCount={1}
                                                                    id='file dragger'
                                                                    // fileList={fileList}
                                                                    {...props}
                                                                    showUploadList={{ showProgress: true }}

                                                                    beforeUpload={file => {
                                                                        setfileError(false)

                                                                        const MAX_SIZE = 10 * 1024 * 1024; // 5 MB
                                                                        if (file.size > MAX_SIZE) {

                                                                            toast.error('File size exceeds limit! The maximum file upload size is 10MB.');
                                                                            return false;
                                                                        } else {

                                                                            handleFileUpload(file);
                                                                        }


                                                                        return false; // to prevent automatic upload
                                                                    }}
                                                                    ref={uploadRef}
                                                                    onRemove={(log) => {
                                                                        console.log("log", log)
                                                                        setFileList([])
                                                                    }}

                                                                >
                                                                    <p className="ant-upload-drag-icon">
                                                                        <InboxOutlined tyle={{ color: "#33363F" }} />
                                                                    </p>
                                                                    <p className="ant-upload-text">Click or drag a file to this area to upload</p>

                                                                </Dragger>
                                                                {fileError && <span style={{ color: "red", fontSize: 10 }}>File attachment is required</span>
                                                                }
                                                            </div>

                                                        </Col>

                                                    </Row>


                                                </Col>
                                                {(editMode || disabaleEvent) && <Col xs={24}>
                                                    <div style={{ display: "flex", justifyContent: "flex-end", width: "100%" }}>

                                                        <Button loading={btnLoading} type='primary' htmlType="button" onClick={addNewAttachments} icon={<SaveOutlined />} style={{ background: 'green' }}>
                                                            Save
                                                        </Button>




                                                    </div>
                                                </Col>}
                                                {editMode && !disabaleEvent &&
                                                    <Col xs={24}>
                                                        <Row gutter={[8, 8]} align={"top"}>
                                                            <Col xs={2}>
                                                                <SettingOutlined style={{ fontSize: 22 }} />
                                                            </Col>
                                                            <Col xs={22}>
                                                                <div style={{ display: 'flex', flexDirection: "column", alignItems: 'flex-start', width: "100%" }}>
                                                                    <Text type='secondary' style={{ color: 'black', fontWeight: 700 }}>Event Actions</Text>

                                                                </div>

                                                            </Col>

                                                        </Row>
                                                    </Col>
                                                }





                                                {!editMode && !disabaleEvent && <Col xs={24}>
                                                    <div style={{ display: "flex", justifyContent: "flex-end", width: "100%" }}>
                                                        <Button style={{ marginRight: 10 }} type='primary' danger icon={<CloseOutlined />} onClick={() => {
                                                            clearForm()
                                                        }}>
                                                            Cancel
                                                        </Button>
                                                        <Button loading={eventCreate} type='primary' htmlType="submit" icon={<SaveOutlined />} style={{ background: 'green' }}>
                                                            Save
                                                        </Button>


                                                    </div>
                                                </Col>}

                                                {editMode && !disabaleEvent && <Col xs={24}>
                                                    <div style={{ display: "flex", justifyContent: "flex-end", width: "100%" }}>
                                                        <Button style={{ marginRight: 10 }} icon={<CloseOutlined />} onClick={() => {
                                                            handleCancel()
                                                        }}>
                                                            Cancel
                                                        </Button>
                                                        <Button loading={eventCreate} type='primary' htmlType="submit" icon={<SaveOutlined />} style={{ background: 'green' }}>
                                                            Update Event
                                                        </Button>

                                                        <Button style={{ marginLeft: 10 }} type='primary' danger htmlType="button" onClick={() => confirmationDialog(true)} icon={<DeleteOutlined />} >
                                                            Delete Event
                                                        </Button>


                                                    </div>
                                                </Col>}


                                            </Row>

                                        </Form>





                                    </Space>

                                </div>



                            </Modal>
                            <Modal
                                title="Delete Event"
                                open={dialog}
                                onOk={deleteEvent}
                                onCancel={() => { confirmationDialog(false) }}
                                okText="Yes"
                                cancelText="No"
                            >
                                "Are you sure, you want to delete this event?"
                            </Modal>
                        </Card >
                    </Col >


                </Row >
            </div >
            <ToastContainer
                transition={Slide}
                position="top-right"
                autoClose={5000}
                hideProgressBar
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored"

            />
        </div >
    )
}

export default NewCalender