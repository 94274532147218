import { Card, Col, Row, Typography } from "antd";
import { useEffect, useState } from "react";


import DbCard from "./Components/DBCard/DbCard";
import { getDonorCounts } from "../../api";
import { getUserData } from "../Donors/getCounts";

const DonorDBTabs = ({ tab, setTab, refreshTimestamp, addCick }) => {

    const [counts, setCounts] = useState(null);
    const [user, setUser] = useState(null);

    useEffect(() => {
        getDonorCounts().then(res => {
            setCounts(res?.data.out)
        });

        fetchUser();
    }, []);

    const fetchUser = async () => {
        const userInfo = await getUserData();
        setUser(userInfo);
    }

    useEffect(() => {
        getDonorCounts().then(res => {
            setCounts(res?.data.out)
        });
    }, [refreshTimestamp])

    const TabTile2 = ({ title, tail, pos }) =>
        <Col span={6}>
            <Card style={{
                height: '15vh', border: pos === tab ? '2px solid #005476' : 'none',
                display: 'flex', alignItems: 'center', cursor: 'pointer'
            }} onClick={() => setTab(pos)}>
                <Row gutter={16}>
                    <Col span={14} style={{ textAlign: 'center' }}>
                        <Typography.Title style={{ margin: 0, color: '#005476', fontSize: 25 }} level={2}>{title}</Typography.Title>
                        {/* <Typography.Title style={{ margin: 0, color: '#005476' }} level={2}>Inquiries</Typography.Title> */}
                    </Col>
                    <Col span={10} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', color: '#005476' }}>
                        {tail}
                    </Col>
                </Row>
            </Card>
        </Col>





    return <div style={{ paddingLeft: '5%', paddingRight: '5%' }}>
        <Row gutter={16}>
            <Col xs={24} md={12} lg={8}>
                <DbCard
                    addClick={() => {
                        console.log("-------")
                        addCick(0)
                    }}
                    selected={tab === 0 ? true : false}
                    onClick={() => {
                        setTab(0)
                    }}
                    title={"Requests"}
                    count={counts ? counts.donor_requests : 0}
                />

            </Col>
            <Col xs={24} md={12} lg={8}>
                <DbCard
                    selected={tab === 1 ? true : false}
                    onClick={() => {
                        setTab(1)
                    }}
                    title={"Donor Interests"}
                    count={counts ? counts.donor_interests : 0}
                />

            </Col>
            <Col xs={24} md={12} lg={8}>
                <DbCard
                    selected={tab === 2 ? true : false}
                    onClick={() => {
                        setTab(2)
                    }}
                    title={"Projects"}
                    count={counts ? counts.donor_request_interests : 0}
                />

            </Col>


        </Row>
    </div>

}

export default DonorDBTabs;