import { Divider, Space, Table, Tag, Typography, Button, Tooltip, Input, Drawer, Spin, message, ConfigProvider, Avatar, Popover, Modal, Form, Descriptions, Alert } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { useState, useEffect, Fragment } from 'react';
import { ContainerOutlined, UserOutlined, BuildOutlined, RocketOutlined, BlockOutlined, EnvironmentOutlined, EyeOutlined, PlusOutlined, DeleteOutlined, CloudUploadOutlined, InboxOutlined, ProfileOutlined, UsergroupAddOutlined, ClockCircleOutlined, CalendarOutlined, CloseOutlined, VideoCameraOutlined, AppstoreOutlined, FieldTimeOutlined, SaveOutlined } from '@ant-design/icons';
import { useRef } from 'react';
import { Col, Row, Select } from 'antd/es';
import FormLayouts from '../../DonorsNew/Components/FormLayouts';
import { addSchedulesComments, downloadSheduleFiles, getSchedules } from '../../../api';
import dayjs from 'dayjs';
import Dragger from 'antd/es/upload/Dragger';
import { ToastContainer, toast, Zoom, Slide } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';



const { TextArea } = Input;
const { Text } = Typography;


const AllEvents = () => {


    const [messageApi, contextHolder] = message.useMessage();
    const [open, setOpen] = useState(false);
    const [data, setData] = useState([]);
    const [filteredData, setFIlteredData] = useState([]);
    const [singleUserData, setSingleUserData] = useState([]);
    const [rawData, setRawData] = useState([]);
    const [prevData, setPrevData] = useState([]);
    const [loaded, setLoaded] = useState(false);
    const [userLevel, setUserLevel] = useState(null);
    const [search, setSearch] = useState("");
    const [agencies, setAgencies] = useState([]);//agencies from db
    const [agency, setAgency] = useState(null);
    const [modal, showModal] = useState(false)
    const [fileList, setFileList] = useState([]);
    const [comment, setComment] = useState("")
    const uploadRef = useRef();
    const [currentRecord, setCurrentRecord] = useState(null)
    const [showError, setError] = useState(false)


    const columns = [
        // {
        //     title: 'ID',
        //     dataIndex: 'order',
        //     key: 'order'
        // },
        {
            title: 'Type',
            dataIndex: 'type',
            key: 'name',
            render: (_, { type }) => (
                <Tag color={getColor(type)} style={{ width: 120, display: 'flex', justifyContent: 'center' }} >
                    {getType(type).toUpperCase()}
                </Tag>
            ),
        },
        {
            title: 'Title',
            dataIndex: 'title',
            key: 'title',
        },
        {
            title: 'Date',
            dataIndex: 'from_date',
            key: 'from_date',
            width: 100,
            render: (_, { from_date }) => (
                <Typography style={{ whiteSpace: "nowrap" }}>{dayjs(from_date).format("DD/MM/YYYY")}</Typography>
            ),
        },
        {
            title: 'Time',
            key: 'time',
            width: 105,
            dataIndex: 'from_time',
            render: (_, { from_time, to_time }) => (
                <Typography style={{ whiteSpace: "nowrap" }}>{dayjs((from_time == 0 ? "0000" : from_time.toString().padStart(4, '0').toString()), 'HHmm').format('HH:mm') + " - " + dayjs((to_time == 0 ? "0000" : to_time.toString().padStart(4, '0').toString()), 'HHmm').format('HH:mm')}</Typography>
            ),
        },
        {
            title: 'Location/ URL',
            dataIndex: 'url',
            key: 'url',
            width: 200,
            ellipsis: true,
            render: (_, { url }) => {
                if (isWebLink(url)) {
                    return <a rel="noopener noreferrer" target="_blank" href={url}>{url}</a>
                } else {
                    return <Typography>{url}</Typography>
                }

            },
        },
        {
            title: 'Participants',
            dataIndex: 'participants',
            key: 'participants',
            render: (_, { participants }) => (
                <Avatar.Group
                    maxCount={5}
                    maxPopoverTrigger="click"
                    size="large"
                    maxStyle={{ color: '#f56a00', backgroundColor: '#fde3cf', cursor: 'pointer' }}
                >

                    {participants.map((element, key) => {
                        return <Popover key={key} title={(element.designation ? element.designation + " - " : "") + element.participant} placement="top"
                        >
                            <Avatar style={{ backgroundColor: generateRandomHexCode() }}>{element.participant.charAt(element.participant.indexOf('.') + 1).toUpperCase()}</Avatar>
                        </Popover>


                    })}
                </Avatar.Group>
            ),
        },
        {
            title: 'Attachments',
            key: 'comments',
            render: (_, { comments }) => (
                <div>

                    {
                        comments.length > 0 && comments.map((element, key) => {
                            if (key < 2) {
                                // Display the first two elements
                                if (element.file_name) {
                                    return (
                                        <div>
                                            <div style={{ cursor: "pointer" }} key={key} onClick={() => FetchFile(element)}>
                                                <img src="/file.png" alt="file" style={{ height: 50, width: 50 }} />
                                                {(element?.file_name && (element?.file_name?.toString().split("-::-"))[1])}
                                            </div>
                                        </div>
                                    );
                                } else {
                                    return (
                                        <span> <i>Description :</i>{" " + element.comment ? element.comment : ""}</span>
                                    );
                                }
                            } else if (key === 2) {
                                // When the third element is encountered, display a span for the rest count
                                const remainingItems = comments.length - 2;
                                return (
                                    <span style={{ background: '#cbe1fb', color: "black", padding: "2px 5px", borderRadius: 6 }}>{remainingItems} {remainingItems === 1 ? "more item" : "more items"}</span>
                                );
                            } else {
                                // For elements after the third one, do not display anything
                                return null;
                            }
                        })
                    }


                </div>
            ),
        },
        {
            title: 'Actions',
            key: 'action',
            render: (_, record) => (

                <div div >
                    <Tooltip title="View">
                        <Button type="secondary" size='small' onClick={() => {
                            console.log("record", record)
                            setCurrentRecord(record)
                            setComment("")
                            setFileList([])
                            showModal(true)

                        }} shape="circle" icon={<EyeOutlined style={{ color: 'darkgreen' }} />} />
                    </Tooltip>
                </div>
            ),

        },
    ];
    function isWebLink(string) {
        // Regular expression pattern to match web links
        var pattern = /^https?:\/\/(?:[-\w.]|(?:%[\da-fA-F]{2}))+/;

        // Check if the string matches the pattern
        return pattern.test(string);
    }


    const handleCancel = () => {
        showModal(false)
        setFileList([])
    };

    const FetchFile = async (file) => {
        console.log('file------->>', file)
        let out = await downloadSheduleFiles(file.token)

        let blob = new Blob([out.data], { type: file.file_type });
        let url = window.URL.createObjectURL(blob);

        let link = document.createElement('a');
        link.href = url;

        link.download = (file.file_name.split("-::-"))[1];

        document.body.appendChild(link);
        link.click();
    }

    const props = {
        name: 'file',
        multiple: false,
        // Action: ,

        onChange(info) {

            info.fileList = []


            const { status } = info.file;
            if (status !== 'uploading') {
                console.log(info.file, info.fileList);
            }
            if (status === 'done') {
                // setFileList(file)
                // toast.success(`${info.file.name} file uploaded successfully.`);
                handleFileUpload(info.file);
            } else if (status === 'error') {

                // toast.error(`${info.file.name} file upload failed.`);
            }
        },

        onDrop(e) {
            console.log('Dropped files', e);
        },
    };

    async function handleFileUpload(file) {
        setFileList([file])

    }

    function generateRandomHexCode() {
        const characters = '0123456789ABCDEF';
        let hexCode = '#';

        do {
            hexCode = '#';
            for (let i = 0; i < 6; i++) {
                hexCode += characters[Math.floor(Math.random() * 16)];
            }
        } while (hexCode === '#FFFFFF');


        return hexCode;
    }

    const addNewAttachments = async () => {
        const formData = new FormData();
        formData.append('type', currentRecord?.type);

        formData.append('id', currentRecord?.id);
        if (fileList.length > 0) {
            formData.append('event_file', fileList[0]);
        }

        if (comment) {
            formData.append('comment', comment);
        }


        if (comment == "") {
            setError(true)
            toast.error("Description is required");

        } else if (fileList.length == 0) {

            toast.error("Attachment is required");

        } else {
            addSchedulesComments(formData).then((out) => {

                if (out.data.status === 200) {


                    toast.success("New attachment added successfully");

                    // clearForm()
                    getEvents()

                } else {
                    toast.error(out.data.message);
                    getEvents()
                }
            }).catch((err) => {
                console.log(err);
                toast.error("Error");
            })
            setFileList([])

            showModal(false)
        }




    }

    useEffect(() => {
        getEvents()
    }, [])

    const getType = (type) => {


        if (type == 0) {
            return "Meeting"
        } else if (type == 1) {
            return "Event"
        } else if (type == 2) {
            return "To Do"
        }

    }

    const getColor = (type) => {
        if (type === 0) {
            return "#0D4C92"

        } else if (type === 1) {
            return "#FF577F"

        } else if (type === 2) {

            return "#c31fb9"
        }

    }

    const onSelectChange = async (val) => {

        let allData = data
        if (val == 0 || val == 1 || val == 2) {
            let datas = allData.filter(element => element.type == val)
            console.log(datas)
            setFIlteredData(datas)
        } else {
            setFIlteredData(allData)
        }

    }

    const getEvents = async function () {

        console.log("eventss calling------------")
        const currentDate = dayjs(); // Get the current date

        const dateBefore15Days = currentDate.subtract(15, 'day').format('DD/MM/YYYY');
        const dateAfter15Days = currentDate.add(15, 'day').format('DD/MM/YYYY');
        let dateRange = {
            "from_date": dateBefore15Days,
            "to_date": dateAfter15Days
        }



        const out = await getSchedules(dateRange)

        let sorted = out.data.data.sort(function (a, b) {
            return new Date(a.from_date) - new Date(b.from_date);
        })
        setData(sorted)
        setFIlteredData(sorted)
        setLoaded(true)
    }

    useEffect(() => {
        if (search == " ") {
            setFIlteredData(data)
        } else {
            const filtered = data.filter((element) => element.title.toString().includes(search));
            setFIlteredData([...filtered]);
        }
    }, [search])


    const filterSchedules = () => {
        if (search == " ") {
            setFIlteredData(data)
        } else {
            const filtered = data.filter((element) => element.title.toString().includes(search));
            setFIlteredData([...filtered]);
        }

    }
    return <>

        <Fragment>
            <FormLayouts
                AllEvents={true}
                title="All Events"

                table={

                    <Row gutter={[12, 24]} style={{ marginBottom: '-15px', width: "100%", paddingTop: 15, }}>

                        <Col xs={24} sm={24} md={4} lg={4} xl={4}>
                            <Select
                                placeholder="Type"
                                onChange={onSelectChange}
                                style={{ width: '100%' }}
                                options={[
                                    {
                                        value: 0,
                                        label: 'Meeting',
                                    },
                                    {
                                        value: 1,
                                        label: 'Event',
                                    },
                                    {
                                        value: 2,
                                        label: 'Task',
                                    },
                                ]}
                                allowClear
                            />
                        </Col>
                        {/* <Col xs={24} sm={24} md={7} lg={7} xl={7}>
                            <Select
                                placeholder="Agency Name"
                                onChange={setAgency}
                                style={{ width: '100%' }}
                                options={agencies}
                                // value={agencyName}
                                allowClear
                            />
                        </Col> */}
                        {/* <Col xs={24} sm={24} md={15} lg={15} xl={15}>
                    <Search placeholder="Search Name/ Designation/ Email Address/ Mobile Number/ Phone Number" onSearch={(value) => searchTable(value)} style={{ width: "100%", margin: 0 }} enterButton />
                </Col> */}
                        <Col xs={24} sm={24} md={18} lg={18} xl={18}>
                            <Input placeholder="Search by event name" style={{ width: "100%", margin: 0 }} onChange={(e) => setSearch(e.target.value)} />
                        </Col>
                        <Col xs={24} sm={24} md={2} lg={2} xl={2}>
                            <Button type="primary" onClick={() => filterSchedules()} icon={<SearchOutlined />} >Search</Button>
                        </Col>

                        <Col xs={24}>
                            {loaded ?
                                <div>
                                    <Table columns={columns} dataSource={filteredData}
                                        pagination={{
                                            pageSize: 5,

                                        }}

                                    />
                                </div>
                                :
                                <Space>
                                    <Spin tip="Loading" size="large">
                                        <div className="content" />
                                    </Spin>
                                </Space>
                            }

                        </Col>
                    </Row>

                }
            />



        </Fragment>
        <Modal
            closable={true}
            centered
            className="custom-modal"
            width={700}
            footer={false}
            onCancel={handleCancel}
            title={
                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                    <div>     <Typography className='custom-modal-title' level={2}>{"Add New Attachments"}</Typography></div>
                    <div>
                        <ConfigProvider
                            theme={{
                                token: {
                                    colorPrimary: '#265252',
                                },
                            }}
                        >

                        </ConfigProvider>
                    </div>

                </div>


            }
            open={modal}
        >
            <div className='custom-modal-body custom-scroll-div' style={{ height: "70vh", overflow: "auto", marginRight: 10 }}>
                <Row>
                    <Col xs={24}>
                        <Row gutter={[8, 8]} align={"top"}>
                            <Col xs={24}>
                                <Row gutter={[8, 8]}>
                                    <Col xs={2}>
                                        <AppstoreOutlined style={{ fontSize: 22 }} />
                                    </Col>
                                    <Col xs={22}>
                                        <div style={{ display: 'flex', flexDirection: "column", alignItems: 'flex-start' }}>
                                            <Typography.Title level={5}> Title</Typography.Title>
                                            <Typography> {currentRecord?.title}</Typography>
                                        </div>
                                    </Col>

                                </Row>


                            </Col>
                            <Col xs={12}>
                                <Row gutter={[8, 8]} align={"top"}>
                                    <Col xs={4}>
                                        <CalendarOutlined style={{ fontSize: 22 }} />
                                    </Col>
                                    <Col xs={20}>

                                        <div style={{ display: 'flex', flexDirection: "column", alignItems: 'flex-start' }}>
                                            <Typography.Title level={5}> Schedule Date</Typography.Title>
                                            {(currentRecord?.from_date == currentRecord?.to_date) ?
                                                <Typography> {dayjs(currentRecord?.from_date).format("DD/MM/YYYY")}</Typography>
                                                :
                                                <Typography> {dayjs(currentRecord?.from_date).format("DD/MM/YYYY") + " - " + dayjs(currentRecord?.to_date).format("DD/MM/YYYY")}</Typography>

                                            }


                                        </div>



                                    </Col>

                                </Row>


                            </Col>
                            <Col xs={12}>
                                <Row gutter={[8, 8]} align={"top"}>
                                    <Col xs={4}>
                                        <ClockCircleOutlined style={{ fontSize: 22 }} />
                                    </Col>
                                    <Col xs={20}>

                                        <div style={{ display: 'flex', flexDirection: "column", alignItems: 'flex-start' }}>
                                            <Typography.Title level={5}> Schedule Time</Typography.Title>
                                            <Typography>
                                                {
                                                    currentRecord !== null && (
                                                        dayjs(
                                                            (currentRecord.from_time !== 0 ? currentRecord.from_time.toString().padStart(4, '0').toString() : "0000"),
                                                            'HHmm'
                                                        ).format('HH:mm') + " - " + dayjs(
                                                            (currentRecord.to_time !== 0 ? currentRecord.to_time.toString().padStart(4, '0').toString() : "0000"),
                                                            'HHmm'
                                                        ).format('HH:mm')
                                                    )
                                                }


                                            </Typography>

                                        </div>



                                    </Col>

                                </Row>


                            </Col>
                            {currentRecord?.url &&
                                <Col xs={24}>
                                    <Row gutter={[8, 8]} align={"top"}>
                                        <Col xs={2}>
                                            <EnvironmentOutlined style={{ fontSize: 22 }} />
                                        </Col>
                                        <Col xs={22}>

                                            <div style={{ display: 'flex', flexDirection: "column", alignItems: 'flex-start' }}>
                                                <Typography.Title level={5}> Location/ URL</Typography.Title>
                                                {isWebLink(currentRecord?.url) ?
                                                    <a rel="noopener noreferrer" target="_blank" href={currentRecord?.url}>{currentRecord?.url}</a> :
                                                    <Typography> {currentRecord?.url}</Typography>

                                                }


                                            </div>



                                        </Col>

                                    </Row>


                                </Col>
                            }

                            <Col xs={24}>
                                <Row gutter={[8, 8]} align={"top"}>
                                    <Col xs={2}>
                                        <ProfileOutlined style={{ fontSize: 22 }} />
                                    </Col>
                                    <Col xs={22}>

                                        <div style={{ display: 'flex', flexDirection: "column", alignItems: 'flex-start' }}>
                                            <Typography.Title level={5}> Description</Typography.Title>
                                            <Typography style={{ display: "flex", textAlign: "justify" }}> {currentRecord?.description}</Typography>

                                        </div>



                                    </Col>

                                </Row>


                            </Col>
                            <Col xs={24}>
                                <Row gutter={[8, 8]} align={"top"}>
                                    <Col xs={2}>
                                        <UserOutlined style={{ fontSize: 22 }} />
                                    </Col>
                                    <Col xs={22}>
                                        <Avatar.Group
                                            maxCount={10}
                                            maxPopoverTrigger="click"
                                            size="large"
                                            maxStyle={{ color: '#f56a00', backgroundColor: '#fde3cf', cursor: 'pointer' }}
                                        >

                                            {currentRecord !== null && currentRecord?.participants.map((element, key) => {
                                                return <Popover key={key} title={(element.designation ? element.designation + " - " : "") + element.participant} placement="top" >
                                                    <Avatar style={{ backgroundColor: generateRandomHexCode() }}>{element.participant.charAt(element.participant.indexOf('.') + 1).toUpperCase()}</Avatar>
                                                </Popover>


                                            })}
                                        </Avatar.Group>

                                    </Col>

                                </Row>

                            </Col>
                            {currentRecord?.comments.filter(e => e.type == 0).length > 0 &&
                                <Col xs={24}>
                                    <Row gutter={[8, 8]} align={"top"}>
                                        <Col xs={2}>
                                            <CloudUploadOutlined style={{ fontSize: 22 }} />
                                        </Col>
                                        <Col xs={22}>


                                            {currentRecord?.comments.filter(e => e.type == 0).map((element, key) => {
                                                return <div key={key} style={{ display: "flex", flexDirection: "column" }}>
                                                    {element?.file_name ?
                                                        <>
                                                            {element.comment ? <span> <i>Description :</i>{" " + element.comment ? element.comment : ""}</span> : null}
                                                            <div className="inquiry-btn" onClick={() => FetchFile(element)}>
                                                                <img src="/file.png" alt="file" style={{ height: 50, width: 50 }} />
                                                                {element?.file_name && (element?.file_name.toString().split("-::-"))[1]}
                                                            </div>


                                                        </>

                                                        : <> {element.comment ? <span> <i>Description : </i>{" " + element.comment ? element.comment : ""}</span> : null}

                                                        </>
                                                    }

                                                </div>



                                            })}


                                        </Col>

                                    </Row>

                                </Col>
                            }

                            <Col xs={24}>
                                <Divider orientation="left" orientationMargin="0">
                                    <Typography style={{ fontSize: 18 }}> Additional Event Details</Typography>

                                </Divider>
                            </Col>
                            {currentRecord?.comments.filter(e => e.type == 1).length > 0 &&
                                <Col xs={24}>
                                    <Row gutter={[8, 8]} align={"top"}>
                                        <Col xs={2}>
                                            <CloudUploadOutlined style={{ fontSize: 22 }} />
                                        </Col>
                                        <Col xs={22}>
                                            <Text style={{
                                                color: 'rgba(0, 0, 0, 0.88)',
                                                fontSize: '14px',
                                                fontWeight: 700
                                            }} type='secondary'>Uploaded Attachments</Text>

                                            {currentRecord?.comments.filter(e => e.type == 1).map((element, key) => {
                                                return <Descriptions key={key} size="small" bordered style={{ marginTop: "10px" }}>
                                                    <Descriptions.Item span={24} label="Description">{element.comment ? element.comment : ""}</Descriptions.Item>
                                                    <Descriptions.Item span={24} label="Attachment">
                                                        <div className="inquiry-btn" onClick={() => FetchFile(element)}>
                                                            <img src="/file.png" alt="file" style={{ height: 50, width: 50 }} />
                                                            {element?.file_name && (element?.file_name.toString().split("-::-"))[1]}
                                                        </div>
                                                    </Descriptions.Item>
                                                </Descriptions>




                                            })}


                                        </Col>

                                    </Row>

                                </Col>
                            }


                            <Col xs={24}>
                                <Row gutter={[8, 8]} align={"top"}>
                                    <Col xs={2}>
                                        <ContainerOutlined style={{ fontSize: 22 }} />
                                    </Col>
                                    <Col xs={22}>
                                        <div style={{ display: 'flex', flexDirection: "column", alignItems: 'flex-start', width: "100%" }}>
                                            <Text type='secondary'>Description</Text>
                                            <TextArea rows={3} style={{ margin: "10px 0" }} placeholder='Description' value={comment} onChange={(e) => {
                                                setError(false)
                                                setComment(e.target.value)
                                            }} />
                                            {showError && <span style={{ color: "red" }}>This field is required</span>}

                                        </div>

                                    </Col>

                                </Row>
                            </Col>

                            <Col xs={24}>
                                <Row gutter={[8, 8]} align={"top"}>
                                    <Col xs={2}>
                                        <CloudUploadOutlined style={{ fontSize: 22 }} />
                                    </Col>
                                    <Col xs={22}>
                                        <div style={{ display: 'flex', flexDirection: "column", alignItems: 'flex-start', width: "100%" }}>
                                            <Text type='secondary'>Attachments</Text>
                                            <Alert style={{ margin: "20px 0px" }} type='info' message=
                                                {"Please ensure that only one attachment is uploaded at a time, and the maximum file size should be 5MB"}
                                            ></Alert>

                                            <Dragger
                                                fileList={fileList}
                                                className='custom-dragger'
                                                style={{ width: "100%", marginTop: "16px !important" }}
                                                maxCount={1}
                                                id='file dragger'
                                                // fileList={fileList}
                                                {...props}
                                                showUploadList={{ showProgress: true }}

                                                beforeUpload={file => {

                                                    const MAX_SIZE = 10 * 1024 * 1024; // 5 MB
                                                    if (file.size > MAX_SIZE) {

                                                        toast.error('File size exceeds limit! The maximum file upload size is 10MB.');
                                                        return false;
                                                    } else {

                                                        handleFileUpload(file);
                                                    }


                                                    return false; // to prevent automatic upload
                                                }}
                                                ref={uploadRef}

                                            >
                                                <p className="ant-upload-drag-icon">
                                                    <InboxOutlined tyle={{ color: "#33363F" }} />
                                                </p>
                                                <p className="ant-upload-text">Click or drag a file to this area to upload</p>

                                            </Dragger>
                                        </div>
                                    </Col>

                                </Row>
                            </Col>



                        </Row>


                    </Col>
                    <Col xs={24}>
                        <div style={{ display: "flex", justifyContent: "flex-end", width: "100%", marginTop: 10 }}>

                            <Button type='primary' htmlType="button" onClick={addNewAttachments} icon={<SaveOutlined />} style={{ background: 'green' }}>
                                Add New Attachments
                            </Button>




                        </div>
                    </Col>



                </Row>
            </div>
        </Modal>
        <ToastContainer
            transition={Slide}
            position="top-right"
            autoClose={5000}
            hideProgressBar
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="colored"

        />

    </>
}

export default AllEvents;