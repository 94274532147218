import { Card, Row, Space, Typography } from 'antd'
import React, { useEffect, useState } from 'react'

import CircularProgress from '../CircularProgress/CircularProgress';
import './tfaCard.css'
import { getTfaCardData } from '../../../../../api';
import CountUp from 'react-countup';
import { EyeOutlined } from "@ant-design/icons";
import CircularProgress2 from '../CircularProgress/CircularProgress2';



function TfaCard() {

    const [cardData, setCardData] = useState(null)
    const [shouldAnimate, setShouldAnimate] = useState(false);
    const [userLevel, setUserLevel] = useState(0);

    useEffect(() => {
        setShouldAnimate(true);
        loadData()

    }, [])

    const loadData = async () => {

        let userLevel = await window.localStorage.getItem('ntfc_level')

        setUserLevel(userLevel)
        console.log("user level", userLevel)
        let agency
        if (userLevel == 3) {
            agency = 0
        } else {
            agency = await window.localStorage.getItem('ntfc_agency')
        }


        getTfaCardData(0).then((out) => {
            console.log("tfa", out)
            setCardData(out.data.out)

        });

    }


    return (
        <Card style={{ cursor: "pointer" }} className='tfa-card' onClick={() => {
            window.location.href = "/summary"
        }}>
            <div className='tfa-inner-card'>
                <div className='tfa-plan-div'>

                    <Typography className='tfa-title'>
                        {userLevel == 3 || userLevel == 7 || userLevel == 6 ? "Country Wise TFA Implementation Summary" : "TFA Implementation Summary"}
                    </Typography>
                    <div style={{ display: "flex", justifyContent: "end", gap: 5, right: 0 }}>
                        <EyeOutlined style={{ fontSize: 25, color: "white" }} />
                    </div>

                </div>

                {/* <div >
                    <CircularProgress2 label={"A"} progress={cardData ? parseInt(cardData ? cardData.A : 0) : 0} />
                    <CircularProgress2 label={"B"} progress={cardData ? parseInt(cardData ? cardData.B : 0) : 0} />
                    <CircularProgress2 label={"C"} progress={cardData ? parseInt(cardData ? cardData.C : 0) : 0} />

                </div> */}

                {/* <CircularProgress2 label={"A"} progress={cardData ? parseInt(cardData ? cardData.A : 0) : 0} /> */}




                {console.log("acasdadasd --------------------->>", cardData ? cardData.A : 0)}
                <div className='tfa-count-div'>
                    <Space size={"large"}>

                        <CircularProgress
                            progress={cardData ? parseInt(cardData ? cardData.A : 0) : 0}

                            size={50}
                            backgroundColor={"transparent"}
                            progressColor={"white"}
                            label={"A"}

                        />
                        <CircularProgress
                            progress={cardData ? parseInt(cardData.B) : 0}

                            size={50}
                            backgroundColor={"transparent"}
                            progressColor={"white"}
                            label={"B"}

                        />
                        <CircularProgress
                            progress={cardData ? parseInt(cardData.C) : 0}

                            size={50}
                            backgroundColor={"transparent"}
                            progressColor={"white"}
                            label={"C"}

                        />
                    </Space>


                </div>

            </div>



        </Card>
    )
}

export default TfaCard