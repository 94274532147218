import { Switch, message } from 'antd'
import React from 'react'
import { addDonorRequestInterest, removeDonorRequestInterest } from '../../api';
import { useEffect } from 'react';

function DonorInterestRow({ measure, updateMeasure }) {

    console.log(measure, "Measures---------------------")

    const [messageApi, contextHolder] = message.useMessage();

    useEffect(() => {
        message.config({
            maxCount: 1,
            duration: 2,
        });
    }, []);

    const onChange = async (checked) => {

        let body = {
            id: measure.id
        }
        let out
        if (checked) {

            out = await addDonorRequestInterest(body)
        } else {
            out = await removeDonorRequestInterest(body)
        }

        if (out.status == 200) {
            message.success(out.data.message);

        } else {

        }
        // message.error(out.data.message);
        updateMeasure(measure.id, checked);
        console.log("Out", out)

    };
    return (
        <div className='donor-measure-row-container'>
            <div className="donor-measure-row">
                <div className='row-no'>
                    <span>{measure.measure_no}</span>
                </div>
                <div className='row-content'>
                    <span>{measure.measure_name}</span>
                </div>
            </div>
            <div className="line-measure-div">
                <hr className="donor-measure-line" />
            </div>
            <div className="row-switch">
                <Switch checked={measure.checked} onChange={onChange} />
            </div>
        </div>
    )
}

export default DonorInterestRow