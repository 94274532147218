import React, { useEffect, useState } from 'react'
import Slider from "react-slick";
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { getAgencyWiseAllSummary } from '../../api';
import PresidentDonut from './PresidentDonut';
import { Badge, Col, Collapse, Empty, Modal, Popover, Row, Space, Spin, Tabs, Tag, Typography } from 'antd';
import './president.css'
const { Panel } = Collapse;

function DonutCarosel() {

    const [report, setReports] = useState([]);
    const [loaded, setLoad] = useState(false)
    const [viewDialog, setViewDialog] = useState(false);
    const [currentAgency, setCurrentAgency] = useState();
    const [tab, setTab] = useState();
    const [donutPreviewData, setPreviewData] = useState([]);
    const [donutLoaded, setDonutLoaded] = useState(false)
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        loadData();
    }, []);

    const loadData = async () => {
        try {
            const out = await getAgencyWiseAllSummary();
            console.log("out ----->>", out);
            const reps = out.data.data;
            // if (reps.length % 5 > 0) {
            //     for (let i = 0; i < reps.length % 5; i++) {
            //         reps.push(null);
            //     }
            // }

            if (reps && reps.length > 0) {
                reps.filter(item => {
                    const counts = ['todo', 'inProgress', 'completed', 'missed', 'delayed'];
                    return counts.every(countKey => item.data?.every(obj => obj[countKey] === 0));
                });


                setReports(reps);
                setLoad(true);
            }
        } catch (error) {
            console.error("Error loading data:", error);
        }
    };

    const extractNames = arr => arr.flatMap(item => item.measure ? item.measure : extractNames(item.data));

    const settings = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 3,
        arrows: true
    };

    const openDonutModal = (agencyData) => {
        console.log("callingg---", tab)
        setTab(0);
        setDonutLoaded(false);
        setCurrentAgency(agencyData);
        loadTabData(0, agencyData);
        setViewDialog(true);
    };

    const handleCancel = () => {
        setViewDialog(false);
    };

    const onChange = (key) => {
        setTab(key);
    };

    useEffect(() => {

        loadTabData(tab, currentAgency);



    }, [tab]);

    const loadTabData = (key, agencyData) => {
        setLoading(true)
        setTab(key);
        let status = key === 0 ? "To Do" :
            key === 1 ? "In Progress" :
                key === 2 ? "Completed" :
                    key === 3 ? "Delayed" :
                        "Missed";

        let filteredData = agencyData?.data.filter(el => el.status === status)[0].data;
        const articles = [];
        const articleMap = new Map();

        filteredData?.forEach(obj => {
            const { article_id, measure, action_id, article, action, action_no, measure_no, article_no } = obj;

            if (!articleMap.has(article_id)) {
                articleMap.set(article_id, { article_id, measures: [], article, article_no: article_no });
                articles.push(articleMap.get(article_id));
            }

            const Article = articleMap.get(article_id);
            const { measures } = Article;

            let measureObj = measures.find(m => m.measure === measure);
            if (!measureObj) {
                measureObj = { measure, actions: [] };
                measures.push(measureObj);
            }

            const { actions } = measureObj;

            if (!actions.some(a => a.action_id === action_id)) {
                actions.push({ action_id, action, action_no, measure_no, article_no });
            }
        });
        articles.sort((a, b) => a.article_no - b.article_no)
        articles.map(e => e.actionCount = 0)
        articles.forEach((item) => {
            let actionCount = 0;

            item.measures.forEach((measure) => {
                actionCount += measure.actions.length;
            });


            articles[0].actionCount += actionCount;
        });
        console.log("articless", articles)
        setPreviewData(articles);
        setLoading(false)
        setTimeout(() => {
            setDonutLoaded(true);
        }, 2000);

    };

    return (
        <div>
            {loaded ?
                <Slider {...settings}>
                    {report?.map((element, index) => {
                        return element !== null ?
                            <div
                                key={index}
                                onClick={() => openDonutModal(element)}
                                style={{ cursor: 'pointer' }}
                            >
                                <PresidentDonut
                                    report={element.data}
                                    lastItem={report?.length - 1 === index}
                                />

                                <Typography className='president-new-inner-card-title' onClick={(e) => { e.stopPropagation() }} style={{ textAlign: "center", cursor: "default" }}>
                                    {element.agency_name}
                                </Typography>

                            </div> : <div key={index}></div>;
                    })}
                </Slider> : null
            }

            <Modal
                closable={true}
                centered
                className="custom-modal"
                width={'50%'}
                footer={false}
                onCancel={handleCancel}
                title={
                    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                        <div>
                            <Typography className="custom-modal-title" level={2}>
                                {currentAgency ? currentAgency.agency_name + " Overview" : ""}
                            </Typography>
                        </div>
                    </div>
                }
                open={viewDialog}
            >
                <div className="custom-modal-body">
                    <Tabs
                        activeKey={tab}
                        items={currentAgency ?
                            currentAgency.data.map((object, key) => ({
                                label: object.status,
                                key: key,
                            })) : []
                        }
                        onChange={onChange}
                    />

                    <div
                        className="custom-scroll-div"
                        style={{
                            height: 400,
                            overflow: "auto",
                            minWidth: 300,
                        }}
                    >
                        <>

                            {!loading ?

                                (donutPreviewData.length > 0 ?
                                    <>
                                        <Space>
                                            <Tag style={{ width: "70px", margin: "5px 0px " }} color="#1520A6">Articles : {donutPreviewData.length}</Tag>
                                            <Tag style={{ width: "80px", margin: "5px 0px " }} color="#1520A6">Actions : {donutPreviewData[0].actionCount}</Tag>

                                        </Space>
                                        <Collapse size="small" defaultActiveKey={["0"]} >
                                            {
                                                donutPreviewData?.map((element, index) => {
                                                    return (
                                                        <Panel
                                                            header={element.article}
                                                            key={index}
                                                        >
                                                            <Tag style={{ width: "62px", margin: "5px 0px " }} color="#1520A6">Measures</Tag>

                                                            <Collapse size="small" defaultActiveKey={["0"]} >
                                                                {element.measures?.map((measure, key) => {
                                                                    return (
                                                                        <Panel
                                                                            header={measure.measure}
                                                                            key={key}
                                                                        >
                                                                            <Tag style={{ width: "62px", margin: "5px 0px " }} color="#1520A6">Actions</Tag>
                                                                            <div style={{ display: "flex", flexDirection: "column" }}>
                                                                                <Row gutter={[16, 4]}>
                                                                                    {measure.actions.map((action, actkey) => {
                                                                                        return <Col xs={24}>
                                                                                            <Row gutter={[1, 2]}>

                                                                                                <Col xs={1}>
                                                                                                    <Badge color="blue" status="default" />
                                                                                                </Col>
                                                                                                <Col xs={23}>

                                                                                                    <span key={actkey} className='popup-action-title'>

                                                                                                        {action.article_no + "." + action.measure_no + "." + action.action_no + ". " + action.action}
                                                                                                    </span>
                                                                                                </Col>
                                                                                            </Row>
                                                                                        </Col>
                                                                                    })}
                                                                                </Row>
                                                                            </div>
                                                                        </Panel>
                                                                    );
                                                                })}
                                                            </Collapse>
                                                        </Panel>
                                                    );

                                                })

                                            }
                                        </Collapse>
                                    </> :
                                    <Empty />) : <span> loading</span>
                            }

                        </>
                    </div>
                </div>
            </Modal>
        </div>
    );
}

export default DonutCarosel;
