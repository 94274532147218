import React from 'react'
import './president.css'
import { Badge, Button, Card, Carousel, Col, Collapse, Modal, Radio, Row, Segmented, Select, Space, Tabs, Tag, Tooltip, Typography } from 'antd'
import NewUserData from '../Dashboard/NewDashoard5/Components/NewUserData'
import StatDashBoarCard from '../Donors/components/StatDashBoarCard'
import Donut from '../Dashboard/Country/Donut'
import { useEffect } from 'react'
import { GetAgencyWiseAllArticles, getAgencies, getAgencyWiseDashboard, getCountryWiseDashboard } from '../../api'
import { useState } from 'react'
import ProgressChart from '../../Components/DashBoard/ProgressChart'
import ArticleCompletionProgressCharts from '../Dashboard/NewDashbaord4/Components/ActionCard/ArticleCompletionProgressCharts'
import DonutCarosel from './DonutCarosel'
import MainCards from '../Dashboard/NewDashbaord4/Components/ActionCard/UpcominDefinitiveDates'
import DonorGraph from '../Dashboard/Agency/DonorGraph'
import { BankOutlined, CalendarOutlined, DeploymentUnitOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import DonutForPopup from '../Dashboard/NewDashBord2/Components/DonutForPopup'
import MeasureWiseDonut from '../Dashboard/NewDashBord2/Components/MeasureWiseDonut'
import SummaryDonut from '../Dashboard/NewDashBord2/Components/SummaryDonut'
import DonorGraphNew from '../Dashboard/NewDashBord2/Components/DonorGraphNew'
const { Panel } = Collapse;


function PresidentView() {
    const [value, setValue] = useState(4);
    const [reports, setReports] = useState(null);
    const [articleCompletion, setArticleCompletion] = useState([]);
    const [selectedAgency, setSelectedAgency] = useState(null);
    const [agencies, setAgencies] = useState();
    const [donutPreviewData, setPreviewData] = useState([]);
    const [donutData, setOpenModalData] = useState([]);
    const [viewDialog, setViewDialog] = useState(false);
    const [tab, setTab] = useState();
    const [activeKey, setActiveKey] = useState(1);
    const [allAgencies, setAllAgencies] = useState([]);
    const [summaryType, setSummaryType] = useState('Measure Wise');



    useEffect(() => {
        loadData()
    }, [])

    const loadData = async () => {


        if (localStorage.getItem('ntfc_agency') == 1) {

            setSelectedAgencyDonut(localStorage.getItem('ntfc_agency'))
        } else {
            setSelectedAgencyDonut(0)
        }


    }

    const setSelectedAgencyDonut = async (val) => {



        if (val.value === 0) {
            getCountryWiseDashboard({}).then((out) => {

                setReports(out.data.data);

            })
        } else {
            getAgencyWiseDashboard(0, {}).then((out) => {

                setReports(out.data.data);



            })
        }


    }
    const onChange = (currentSlide) => {
        console.log(currentSlide);
    };
    const handleCancel = () => {
        setViewDialog(false);
    };
    const opeDonutModal = (params) => {

        setActiveKey(1)
        console.log("Params------------>>", params)
        setOpenModalData(params)
        let filteredReport = reports
            .filter((element) => element.report_name === "Agency wise Summary")[0]
            .data.find((obj) => obj.status === params.name);

        const uniqueAgencies = filteredReport ? filteredReport.data.reduce((unique, obj) => {
            if (!unique.some(item => item.key === obj.agency_id)) {
                unique.push({ label: obj.agency_name, key: obj.agency_id, agency_name: obj.agency_name });
            }
            return unique;
        }, []) : [];

        const transformedArray = uniqueAgencies.map(obj => {
            const words = obj.label.split(' ');
            const initials = words.map(word => {
                const firstLetter = word.charAt(0).toUpperCase();
                return /[,&]|and/i.test(word) ? '' : firstLetter;
            });
            const agencyName = initials.join('');
            return { ...obj, label: agencyName };
        });


        setAllAgencies(transformedArray)

        const result = [];
        const agencyMap = new Map();


        filteredReport.data.forEach(obj => {
            const { agency_name, agency_id, article_id, measure_id, action_id, article, measure, action, action_no, article_no, measure_no } = obj;

            if (!agencyMap.has(agency_id)) {
                agencyMap.set(agency_id, { agency_id, articles: [], name: agency_name });
                result.push(agencyMap.get(agency_id));
            }
            const agency = agencyMap.get(agency_id);

            const { articles } = agency;

            let articleObj = articles.find(a => a.article_id === article_id);
            if (!articleObj) {
                articleObj = { article_id, measures: [], article };
                articles.push(articleObj);
            }
            const { measures } = articleObj;

            let measureObj = measures.find(m => m.measure_id === measure_id);
            if (!measureObj) {

                measureObj = { measure_id, actions: [], measure: measure };
                measures.push(measureObj);
            }

            const { actions } = measureObj;

            if (!actions.some(a => a.action_id === action_id)) {
                actions.push({ action_id, action, action_no, measure_no, article_no });

            }

        });

        console.log("=======================================================", result)


        result.forEach((item) => {
            let actionCount = 0;
            item.articles.forEach((article) => {
                article.measures.forEach((measure) => {
                    actionCount += measure.actions.length;
                });
            });

            item.actionCount = actionCount;
        });

        // Calculate the total actionCount
        let totalActionCount = 0;

        result.forEach((item) => {
            totalActionCount += item.actionCount;
        });

        // Add the actionPercentage key-value pair to each object
        result.forEach((item) => {
            const actionPercentage = ((item.actionCount / totalActionCount) * 100)?.toFixed(2);
            item.value = parseFloat(actionPercentage);
        });

        console.log("=======================================================||", result)
        if (transformedArray && transformedArray.length > 0) {
            setPreviewData(result);
            setViewDialog(true);
        }
        setTab(transformedArray && transformedArray.length > 0 ? transformedArray[0].key : 0)
    };
    const onChangeTab = (key) => {
        console.log(key)
        setTab(key)
    };
    return (
        <div className='president-main-div custom-scroll-div2'>
            <div className='president-first-div main-pading'>
                <div className='president-nav-bar'>
                    <div className='presideent-view-logo-title'>
                        <img src='/logo.png' alt='ntfc logo' className='dashboard-five-logo' />
                        <Typography
                            className='new-dash-five-title'
                        >
                            <span>National Trade Facilitation Committee</span>
                        </Typography>
                    </div>

                    <div style={{ display: "flex", gap: 30, alignItems: "center" }}>
                        <div>
                            <Space>
                                {window.localStorage.getItem("ntfc_level") == 1 &&
                                    <Tooltip title="Action Plan">
                                        <Button
                                            className="enlarged-button"
                                            onClick={() => window.location.href = '/tasksactionplan'} icon={<DeploymentUnitOutlined />}

                                            size={"small"} />

                                    </Tooltip>
                                }

                                <Tooltip title="Calendar">
                                    <Button
                                        className="enlarged-button"
                                        size="small"
                                        onClick={() => window.location.href = '/newcalender'} icon={<CalendarOutlined />}
                                    // size={"large"} 
                                    />

                                </Tooltip>
                            </Space>
                        </div>

                        <NewUserData />
                    </div>
                </div>
                <div className='top-graph-view '>
                    <Row gutter={[16, 16]} style={{ height: "100%" }}>
                        {/* <Col xs={24}>
                            <div style={{ display: 'flex', justifyContent: 'flex-end', width: "100%" }}>

                                <Select onChange={(e) => {
                                    console.log("seleceetedd", e)
                                    setSelectedAgency(e)
                                    setSelectedAgencyDonut(e)
                                }
                                }
                                    style={{ width: 500 }}
                                    placeholder="Select Agency"
                                    options={agencies?.filter((agency) => agency.agency_type === 1)}
                                    value={selectedAgency}
                                />

                            </div>

                        </Col> */}
                        <Col xs={24} lg={10}>
                            <StatDashBoarCard
                                title={"Completion percentage"}
                                titleBackColor={"#EA6A21"}
                                content={
                                    <>
                                        <div style={{ width: '50%', margin: 'auto' }}>
                                            <Segmented block options={['Measure Wise', 'Action Wise']} onChange={(val) => { setSummaryType(val); }} />
                                        </div>
                                        {summaryType == 'Measure Wise' ?
                                            <MeasureWiseDonut val={0} /> : <SummaryDonut val={0} />}
                                    </>
                                }
                            />
                        </Col>
                        <Col xs={24} lg={14}>
                            <Card className='dash5-card'>
                                <div className='new-action-card-new-president' >
                                    <div
                                        className='action-card-five-new'
                                    >
                                        <Typography className='ac-new-inner-card-title'>Article Completion Percentage</Typography>

                                    </div>


                                    <ArticleCompletionProgressCharts />



                                </div>
                            </Card>
                        </Col>
                        <Col xs={24} lg={24}>
                            <Card className='dash5-card'

                                style={{
                                    height: "100%",
                                    cursor: window.localStorage.getItem("ntfc_level") == 0 ? 'default' : 'pointer'
                                }}

                            >
                                <div className='new-action-card-new-president'>
                                    <div
                                        className='action-card-five-new'
                                    >
                                        <Typography className='ac-new-inner-card-title'>Action Plan - Upcoming Definitive Dates</Typography>

                                    </div>
                                    <div>
                                        <MainCards president={true} />
                                    </div>

                                </div>
                            </Card>
                        </Col>

                        <Col xs={24}>
                            <Card className='dash5-card' style={{ height: "100%" }}>
                                <div className='new-action-card-new-president2'>
                                    <div
                                        className='action-card-five-new'
                                    >
                                        <Typography className='ac-new-inner-card-title'>Agency wise implementation summary</Typography>

                                    </div>

                                    <DonutCarosel />
                                </div>
                            </Card>

                        </Col>

                        <Col xs={24}>
                            <Card className='dash5-card' style={{ height: "100%" }}>
                                <StatDashBoarCard
                                    title={"Article wise donor assistance distribution for each measure"}
                                    titleBackColor={"#F1416C"}
                                    content={<DonorGraphNew val={selectedAgency} />}
                                />
                            </Card>

                        </Col>

                    </Row>

                </div>


            </div>
            <Modal
                closable={true}
                centered
                className="custom-modal"
                width={'80%'}
                footer={false}
                onCancel={handleCancel}
                title={
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                        }}
                    >
                        <div>
                            {" "}
                            <Typography className="custom-modal-title" level={2}>
                                {donutData ? donutData.name + " Actions Overview" : ""}
                            </Typography>
                        </div>
                    </div>
                }
                open={viewDialog}
            >
                <div className="custom-modal-body">
                    <Row gutter={[16, 16]}>

                        <Col xs={24} md={18}>
                            <Tabs activeKey={tab} items={allAgencies} onChange={onChangeTab} />
                            <div style={{ padding: "0px ", margin: "10px 0px", display: "flex", gap: 10, alignContent: "center" }}>
                                <BankOutlined style={{ fontSize: 25 }} /><Typography style={{ fontWeight: 600, fontSize: 20 }}>
                                    {(allAgencies?.filter(el => el.key == tab)) && (allAgencies?.filter(el => el.key == tab)).length > 0 ? allAgencies?.filter(el => el.key == tab)[0].agency_name : ''}
                                </Typography>
                            </div>
                            <div
                                className="custom-scroll-div"
                                style={{
                                    height: 400,
                                    overflow: "auto",
                                    paddingRight: 16,
                                    minWidth: 300,
                                }}
                            >
                                <Space>
                                    <Tag style={{ width: "62px", margin: "5px 0px " }} color="#1520A6">Articles</Tag>
                                    <Tag style={{ width: "85px", margin: "5px 0px " }} color="#108ee9">{"Actions :" + (donutPreviewData?.filter(el => el.agency_id == tab)) && (donutPreviewData?.filter(el => el.agency_id == tab)).length > 0 ? donutPreviewData?.filter(el => el.agency_id == tab)[0].actionCount : 0}</Tag>
                                    <Tag style={{ width: "125px", margin: "5px 0px " }} color="#2db7f5">{"Contribution : " + ((donutPreviewData?.filter(el => el.agency_id == tab)) && (donutPreviewData?.filter(el => el.agency_id == tab)).length > 0 ? donutPreviewData?.filter(el => el.agency_id == tab)[0].value : 0) + "%"}</Tag>

                                </Space>
                                <Collapse size="small" defaultActiveKey={["0"]} >
                                    {donutPreviewData?.filter(el => el.agency_id == tab) && (donutPreviewData?.filter(el => el.agency_id == tab)).length > 0 ? donutPreviewData?.filter(el => el.agency_id == tab)[0].articles.map((element, index) => {
                                        return (
                                            <Panel
                                                header={

                                                    element.article

                                                }
                                                key={index}
                                            >

                                                <Tag style={{ width: "62px", margin: "5px 0px " }} color="#1520A6">Measures</Tag>



                                                <Collapse size="small" defaultActiveKey={["0"]} >
                                                    {
                                                        element.measures?.map(
                                                            (measure, key) => {
                                                                return <Panel
                                                                    header={

                                                                        measure.measure

                                                                    }
                                                                    key={key}
                                                                >
                                                                    <Tag style={{ width: "62px", margin: "5px 0px " }} color="#1520A6">Actions</Tag>
                                                                    <div style={{ display: "flex", flexDirection: "column" }}>
                                                                        <Row gutter={[16, 16]}>


                                                                            {measure.actions.map((action, actkey) => {

                                                                                return <Col xs={24}>
                                                                                    <Row gutter={[1, 2]}>

                                                                                        <Col xs={1}>
                                                                                            <Badge color="blue" status="default" />
                                                                                        </Col>
                                                                                        <Col xs={23}>

                                                                                            <span key={actkey} className='popup-action-title'>

                                                                                                {action.article_no + "." + action.measure_no + "." + action.action_no + ". " + action.action}
                                                                                            </span>
                                                                                        </Col>
                                                                                    </Row>
                                                                                </Col>
                                                                            })}
                                                                        </Row>
                                                                    </div>


                                                                </Panel>
                                                            }
                                                        )
                                                    }
                                                </Collapse>
                                                {/* <div style={{ display: "flex", flexDirection: "column", width: "100%", }}>
                     

                      <Tag style={{ width: "62px" }} color="#1520A6">Article</Tag>
                      <p style={{ marginTop: 5, fontWeight: 700, fontSize: 13 }}>{element.article}</p>

                      <Tag style={{ marginTop: 8, width: "62px" }} color="#2f69b3">Measure</Tag>
                      <p style={{ marginTop: 5, fontWeight: 500, fontSize: 13 }}>{element.measure}</p>

                      <Tag style={{ marginTop: 8, width: "62px" }} color="#0492C2">Action</Tag>

                      <p >{element.article_no +
                        "." +
                        element.measure_no +
                        "." + " " +
                        element.action_no + " " + element.action}</p>
                      <p style={{ marginTop: 5 }}>Delivery  Date : {dayjs(element.delivery_date).format("DD/MM/YYYY")}</p>

                    </div> */}

                                            </Panel>
                                        );
                                    }) : null}
                                </Collapse>
                                {/* {donutPreviewData?.data.map((element, index) => (
                                            <div>
                                                <div className={index == 0 ? "lines" : "sub_lines"}></div>
                                                <div className="measure_div">
                                                    <div className="measure_no">
                                                        {"Action" + " " + element.article_no + "." + element.measure_no + "." + element.action_no}
                                                    </div>
                                                    <div className="measure_name">
                                                        {element.action}
                                                    </div>
                                                    {/* <div className="status_c">
                                                        Completed
                                                    </div> 
                                                </div>
                                                <div className="measure_sub_div">
                                                    <div className="date_lbl">
                                                        Definitive Date
                                                    </div>
                                                    <div className="date">
                                                        2023/02/02
                                                    </div>
                                                </div>
                                                <div className="measure_sub_div">
                                                    <div className="date_lbl">
                                                        Completed Date
                                                    </div>
                                                    <div className="date">
                                                        2023/02/02
                                                    </div>
                                                </div>
                                            </div>
                                        ))} */}
                            </div>
                        </Col>
                        <Col xs={24} md={6}>
                            <DonutForPopup data={donutPreviewData?.filter(el => el.agency_id == tab)[0]} tab={tab} />
                        </Col>

                    </Row>

                </div >
            </Modal >



        </div >
    )
}

export default PresidentView