import "./donors.styles.css";
import DonorTabs from "./Components/DonorTabs";
import { useState, useEffect } from "react";
import { Divider, Drawer, Tabs, Typography, message, Input, AutoComplete, Space, Select, Segmented } from "antd";
import AllDonorRequests from "./AllDonorRequests";
import MyDonorRequests from "./MyDonorRequests";
import AllDonorInterests from "./AllDonorInterests";
import DonerInterestsForProject from "./DonerInterestsForProjects";
import AgencyInterestsForProject from "./AgencyInterestsForProjects";
import NewNavBar from "../Dashboard/Newdashoard3/Components/NewNavBar";
import { getUserData } from "../Donors/getCounts";
import PageHeader from "../Inquiries/PageHeader";
import DonorRequestsView from "./DonorViews/DonorRequestsView";
import DonorContributionView from "./DonorViews/DonorContributionView";
import DonorInterestsView from "./DonorViews/DonorInterestsView";
import DonorAssignedProjects from "./DonorViews/DonorAssignedProjects";
import AgencyAssignedInterest from "./AgencyAssignedInterest";
import { useParams } from 'react-router-dom';

import {
  MergeCellsOutlined,
  NumberOutlined,
  SendOutlined,
  MacCommandOutlined,
  OneToOneOutlined,
  HomeOutlined,
} from "@ant-design/icons";
import { getAgencies, getMeasures } from "../../api";
import { NewColorTheme, status } from "../../appConst";
import InterestedDonorRequests from "./InterestedDonorRequests";
import FullSearch from "../../Components/FullSearch";
import DonorAssignedInterestProjects from "./DonorViews/DonorAssignedInterestProjects";
import ConfirmedDonorRequests from "./ConfirmedDonorRequests";

const DonorManagement = () => {
  const { id } = useParams();
  const [messageApi, contextHolder] = message.useMessage();
  const [tab, setTab] = useState(id ? id : 0);
  const [refreshTimestamp, setRefreshTimestamp] = useState("");
  const [loaded, setLoad] = useState(false);
  const [user, setUser] = useState({});
  const [data, setData] = useState([]);
  const [initData, setInitData] = useState([]);
  const [agencies, setAgencies] = useState([]);
  const [options, setOptions] = useState([]);
  const [options2, setOptions2] = useState([]);
  const [measures, setMeasures] = useState([]);
  const [userLevel, setUserLevel] = useState(null);
  const [interestType, setInterestType] = useState('Donor Interests');
  const [interestType2, setInterestType2] = useState('Interests submitted by donor');
  const [interestType3, setInterestType3] = useState('Confirmed interest projects');
  const [interestType4, setInterestType4] = useState('Confirmed interest projects');
  const [donorCounts, setDonorCounts] = useState();
  useEffect(() => {
    console.log("Refesheeeeee", refreshTimestamp)
  }, [refreshTimestamp])

  useEffect(() => {
    getUserDetails()

    if (window.localStorage.getItem("ntfc_level") == 3) {
      setTab(id)

    }
    setLoad(true)

    getAgencies().then((out) => {
      const agencyNames = out.data.out.map((agency) => agency.label);
      setAgencies(agencyNames)
      setOptions(agencyNames)
    })

    getMeasures().then((out2) => {
      setMeasures(
        out2.data.out.map((m) => {
          return m.label

        })
      );
      setOptions2(out2.data.out.map((m) => {
        return m.label

      }))
    });

  }, []);

  useEffect(() => { setData([]); setInitData([]); }, [tab]);

  const getUserDetails = async function () {

    const user = await getUserData()

    setUser(user)


  }
  const success = (msg) => {
    messageApi.open({
      type: "success",
      content: msg,
      style: {
        marginTop: "30vh",
        height: "100vh",
        fontSize: "20px",
      },
      duration: 3,
    });
  };

  const error = (msg) => {
    messageApi.open({
      type: "error",
      content: msg,
      style: {
        marginTop: "30vh",
        height: "100vh",
        fontSize: "20px",
      },
      duration: 3,
    });
  };

  const onSearch = (value) => {
    if (value) {
      const tempData = initData.filter((item) => item.agency_name && item.agency_name.toLowerCase().includes(value.toLowerCase()));
      setData(tempData);
      const agenciesFiltered = agencies.filter((ag) => ag.includes(value))
      setOptions(agenciesFiltered)
    } else {
      setData(initData);
      setAgencies(agencies)
    }

  }

  const onSearch2 = (value) => {
    if (value) {
      const tempData = initData.filter((item) => item.measure_name && item.measure_name.toLowerCase().includes(value.toLowerCase()));
      setData(tempData);
      const agenciesFiltered = measures.filter((ag) => ag.includes(value))
      setOptions2(agenciesFiltered)
    } else {
      setData(initData);
      setAgencies(agencies)
    }

  }
  const setStatus = (value) => {

    if (value != undefined) {
      const tempData = initData.filter((item) => item.status == value);
      setData(tempData);
    } else {
      setData(initData);
    }


  }

  useEffect(() => {
    setInterestType('Interests submitted by donors');
    setInterestType2('Interests submitted by donors')
    setInterestType3('Confirmed interest projects');
    setInterestType4('Confirmed interest projects');
  }, [tab])


  const showMessage = (issuccess, msg) => {
    if (issuccess) {
      success(msg);
    } else {
      error(msg);
    }
  };

  return (
    <div style={{ overflow: "hidden" }}>
      {contextHolder}
      <div
        className="bgSidePanel"
        style={{ overflow: "hidden", background: NewColorTheme.primaryColor }}
      ></div>
      <div className="sidepanel-left" style={{ background: "white" }}></div>
      <div>
        <>
          <PageHeader title="Donor Management" user={user} />
        </>
        {/* <PageHeader title={"Donor Management "} /> */}
        {loaded ? (
          <>
            <div className="mob-hide">
              <DonorTabs
                tab={tab}
                setTab={setTab}
                refreshTimestamp={refreshTimestamp}
                donorCounts={donorCounts}
                setDonorCounts={setDonorCounts}
              />
            </div>
            {tab == 0 ?
              <div className="mob-hide" style={{ paddingLeft: "37px", paddingTop: 15, width: '100%', justifyContent: 'start', display: "flex", gap: 10 }}>
                <FullSearch full={initData} setFiltered={setData} params={["agency_name", "measure_name", "article_no", "measure_no", "subject"]} />
                {
                  window.localStorage.getItem('ntfc_level') != 3 && (

                    <Select

                      placeholder="Status"
                      onChange={setStatus}
                      style={{ width: 120 }}
                      options={status}
                      allowClear
                    />
                  )
                }
              </div> : null}
            {/* {tab == 0 ?
              <div className="mob-hide" style={{ paddingLeft: "37px", paddingTop: 15, width: '100%', justifyContent: 'start' }}>
                <Space>
                  <AutoComplete style={{ display: "flex", justifyContent: "flex-end" }} dataSource={options2} onSelect={(val) => onSearch2(val)}>
                    <Input.Search style={{ width: 340 }} placeholder="Search By Measure" allowClear onSearch={onSearch2} onChange={(e) => onSearch2(e.target.value)} />
                  </AutoComplete>
                  < AutoComplete style={{ display: "flex", justifyContent: "flex-end" }} dataSource={options} onSelect={(val) => onSearch(val)}>
                    <Input.Search style={{ width: 340 }} placeholder="Search By Agency" allowClear onSearch={onSearch} onChange={(e) => onSearch(e.target.value)} />
                  </AutoComplete>
               




                </Space>
              </div> : null} */}
            {window.localStorage.getItem('ntfc_level') == 3 ? (
              <>
                {tab == 0 ? (
                  <DonorRequestsView
                    showMessage={showMessage}
                    setTab={setTab}
                    setRefreshTimestamp={setRefreshTimestamp}
                    data={data}
                    setData={setData}
                    setInitData={setInitData}
                  />
                ) : null}
                {tab == 1 ? (
                  <DonorContributionView
                    showMessage={showMessage}
                    setTab={setTab}
                    setRefreshTimestamp={setRefreshTimestamp}
                    data={data}
                    setData={setData}
                  />
                ) : null}
                {tab == 2 ? (
                  <div>
                    <Segmented options={['Interests submitted by donors', 'Requests chosen by donor']} onChange={(val) => { setData([]); setInterestType2(val) }} style={{ marginLeft: 35, marginTop: 10 }} />
                    {interestType2 == 'Interests submitted by donors' ? <DonorInterestsView
                      showMessage={showMessage}
                      setTab={setTab}
                      setRefreshTimestamp={setRefreshTimestamp}
                      data={data}
                      setData={setData}
                    /> : <InterestedDonorRequests
                      showMessage={showMessage}
                      setTab={setTab}
                      setRefreshTimestamp={setRefreshTimestamp}
                      data={data}
                      setData={setData}
                      setInitData={setInitData}
                    />}
                  </div>
                ) : null}
                {tab == 3 ? (
                  <div>
                    <Segmented
                      options={['Confirmed interest projects', 'Confirmed request projects']}
                      onChange={(val) => { setData([]); setInterestType3(val) }} style={{ marginLeft: 35, marginTop: 10 }}

                    />
                    {interestType3 == 'Confirmed interest projects' ? <DonorAssignedInterestProjects
                      showMessage={showMessage}
                      setTab={setTab}
                      setRefreshTimestamp={setRefreshTimestamp}
                      data={data}
                      setData={setData}
                    /> : <DonorAssignedProjects
                      showMessage={showMessage}
                      setTab={setTab}
                      setRefreshTimestamp={setRefreshTimestamp}
                      data={data}
                      setData={setData}
                    />}
                  </div>
                ) : null}
              </>
            ) : (
              <>
                {tab === 0 ? (
                  <AllDonorRequests
                    showMessage={showMessage}
                    setTab={setTab}
                    setRefreshTimestamp={setRefreshTimestamp}
                    data={data}
                    setData={setData}
                    setInitData={setInitData}
                  />
                ) : null}
                {tab === 1 ? (
                  <MyDonorRequests
                    showMessage={showMessage}
                    setTab={setTab}
                    setRefreshTimestamp={setRefreshTimestamp}
                    data={data}
                    setData={setData}
                  />
                ) : null}
                {tab === 2 ? (
                  <div>
                    <Segmented options={['Interests submitted by donors', 'Requests chosen by donor']} onChange={(val) => { setInterestType(val) }} style={{ marginLeft: 35, marginTop: 10 }} />
                    {interestType == 'Interests submitted by donors' ? <AllDonorInterests
                      showMessage={showMessage}
                      setTab={setTab}
                      setRefreshTimestamp={setRefreshTimestamp}
                      data={data}
                      setData={setData}
                    /> : <InterestedDonorRequests
                      showMessage={showMessage}
                      setTab={setTab}
                      setRefreshTimestamp={setRefreshTimestamp}
                      data={data}
                      setData={setData}
                      setInitData={setInitData}
                    />}
                  </div>
                ) : null}
                {tab === 3 ? (
                  (window.localStorage.getItem('ntfc_level') == 4 || window.localStorage.getItem('ntfc_level') == 5) ? (
                    <div>
                      <Segmented options={['Confirmed interest projects', 'Confirmed request projects']} onChange={(val) => { setData([]); setInterestType4(val) }} style={{ marginLeft: 35, marginTop: 10 }} />
                      {interestType4 == 'Confirmed interest projects' ?
                        < DonerInterestsForProject
                          showMessage={showMessage}
                          setTab={setTab}
                          setRefreshTimestamp={setRefreshTimestamp}
                          data={data}
                          setData={setData}
                        /> : <ConfirmedDonorRequests
                          showMessage={showMessage}
                          setTab={setTab}
                          setRefreshTimestamp={setRefreshTimestamp}
                          data={data}
                          setData={setData}
                        />
                      }
                    </div>
                  ) : (
                    <div>
                      <Segmented options={['Confirmed interest projects', 'Confirmed request projects']} onChange={(val) => { setData([]); setInterestType4(val) }} style={{ marginLeft: 35, marginTop: 10 }} />
                      {interestType4 == 'Confirmed interest projects' ?
                        <DonerInterestsForProject
                          showMessage={showMessage}
                          setTab={setTab}
                          setRefreshTimestamp={setRefreshTimestamp}
                          data={data}
                          setData={setData}
                        /> : <ConfirmedDonorRequests
                          showMessage={showMessage}
                          setTab={setTab}
                          setRefreshTimestamp={setRefreshTimestamp}
                          data={data}
                          setData={setData}
                        />
                      }
                    </div>
                  )
                ) : null}
                {tab === 4 ? (
                  <AgencyInterestsForProject
                    showMessage={showMessage}
                    setTab={setTab}
                    setRefreshTimestamp={setRefreshTimestamp}
                    data={data}
                    setData={setData}
                  />
                ) : null}
              </>
            )}
          </>
        ) : null}
      </div>
    </div >
  );
};

export default DonorManagement;
