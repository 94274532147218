import { useEffect, useState } from 'react';
import { Button, Form, Input, Space, Typography, Select, Radio, message, Row, Col, Modal } from 'antd';
import { BankOutlined, InfoCircleOutlined, MailOutlined, UserAddOutlined } from '@ant-design/icons';
import { CreateNewUser, getAgencies, updateUserByID } from "../../api";
import { ToastContainer, toast, Zoom } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import PhoneInput from 'react-phone-input-2';
import { forwardRef } from 'react';
import { useRef } from 'react';
const { Text } = Typography;

const UserEdit = ({ reset }) => {

    useEffect(() => {
        console.log("reser--------------->>")
        form.resetFields()


    }, [reset])

    const [form] = Form.useForm();
    const [requiredMark, setRequiredMarkType] = useState('optional');
    const [agencies, setAgencies] = useState([]);
    const [agenciesMain, setAgenciesMain] = useState([]);
    const [selectedAgency, setSelectedAgency] = useState(null);
    const [uuid, setUuid] = useState(null);
    const [mobile, setMobile] = useState("");
    const [officialMobile, setOfficialMobile] = useState("");
    const [agencyType, setAgencyType] = useState(4);
    const [agencyName, setAgencyName] = useState(null);
    const [isChair, setChair] = useState(false)


    useEffect(() => {
        getAgencies().then((out) => {
            // setAgencies(out.data.out)
            setAgenciesMain(out.data.out)
        });
        if (localStorage.getItem('ntfc_level') == 6) {
            setChair(true)
        }
        console.log("A")
    }, []);

    // useEffect(() => {
    //     form.setFieldValue('first_name', props.singleUserData.first_name)
    //     form.setFieldValue('last_name', props.singleUserData.last_name)
    //     form.setFieldValue('title', props.singleUserData.title)
    //     form.setFieldValue('agency_type', props.singleUserData.agency_type)
    //     form.setFieldValue('agency_name', props.singleUserData.agency_name)
    //     form.setFieldValue('designation', props.singleUserData.designation)
    //     form.setFieldValue('user_level', props.singleUserData.user_level == 4 ? "Technical User" : props.singleUserData.user_level == 5 ? "HOD" : "")
    //     form.setFieldValue('email', props.singleUserData.email)
    //     form.setFieldValue('mobile', props.singleUserData.mobile)
    //     form.setFieldValue('phone', props.singleUserData.phone)
    //     setUuid(props.singleUserData.uuid)
    // }, [props.singleUserData]);

    const success = () => {
        messageApi.open({
            type: 'success',
            content: 'User Addition/ Update successful',
            style: {
                marginTop: '30vh',
                height: '100vh',
                fontSize: '20px'
            },
            duration: 3
        });
    };

    const error = () => {
        messageApi.open({
            type: 'error',
            content: 'User Addition/ Update unsuccessful',
            style: {
                marginTop: '30vh',
                height: '100vh',
                fontSize: '20px'
            },
            duration: 3
        });
    };

    const onFinish = async (val) => {
        let newFormData = {};
        newFormData['title'] = val.title
        newFormData['first_name'] = val.first_name
        newFormData['last_name'] = val.last_name
        newFormData['agency_type'] = val.agency_type
        if (agencyType == -1) {
            newFormData['agency_name'] = val.agency_name
        } else if (agencyType == 0) {
            newFormData['agency_name'] = "Presidential"
            newFormData['agency_id'] = 9999;
        } else if (agencyType == 6) {
            newFormData['agency_name'] = "ADMIN"
            newFormData['agency_id'] = 9999;
        } else if (agencyType == 99) {
            newFormData['agency_name'] = "CO-CHAIR"
            newFormData['agency_id'] = 9999;
        } else if (agencyType == 98) {
            newFormData['agency_name'] = "PR to WTO"
            newFormData['agency_id'] = 9999;
            newFormData['role_id'] = 98;
        } else {
            newFormData['agency_name'] = (agencies.filter((item) => item.value === val.agency_name))[0].label;
            newFormData['agency_id'] = val.agency_name;
        }

        if (!val.user_level) {
            newFormData['user_level'] = agencyType;
        } else {
            newFormData['user_level'] = val.user_level
        }

        if (agencyType == 99) {
            newFormData['view_only'] = 1;
            newFormData['user_level'] = 6;
        }
        if (agencyType == 98) {
            newFormData['role_id'] = 98;
            newFormData['user_level'] = 6;
        }

        newFormData['designation'] = val.designation

        newFormData['mobile'] = val.mobile
        newFormData['phone'] = val.phone
        newFormData['email'] = val.email


        const out = await CreateNewUser(newFormData);
        console.log("Out", out)
        if (out) {
            form.resetFields();
            if (out.data.status === 200) {
                toast.success(out.data.message,);
            } else {
                toast.error(out.data.message);
            }


        } else {
            toast.error("Error on adding measure");
        }
    }

    const updateAgencyType = (input) => {

        setAgencyType(input);
        form.setFieldValue('agency_name', null);
        form.setFieldValue('agency_id', null);
        setAgencyName(null);
        refreshAgencies(input);
    }
    const refreshAgencies = (type) => {
        const filteredAgencies = agenciesMain.filter((ag) => ag.agency_type === type);

        setAgencies(filteredAgencies);
    }
    const [messageApi, contextHolder] = message.useMessage();



    const [open, setOpen] = useState(false);
    // Function to reset the form fields
    const [formKey, setFormKey] = useState(0);

    const userEditCallback = () => {
        form.resetFields()
    }

    const showDrawer = (record) => {
        form.resetFields()
        console.log(record)
        setOpen(true);
    };

    const onClose = () => {
        setOpen(false);


        userEditCallback();
    };
    return <>
        {contextHolder}
        {!isChair && <Button type="primary" icon={<UserAddOutlined />}
            onClick={() => showDrawer()}
            disabled={window.localStorage.getItem('view_only') == 1}>
            Add New User
        </Button>
        }

        <Modal
            closable={true}

            className="custom-modal"
            width={650}
            footer={false}
            onCancel={onClose}
            title={
                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                    <div>     <Typography className='custom-modal-title' level={2}>Create user</Typography></div>


                </div>


            } open={open} centered>

            <div className='custom-modal-body custom-scroll-div' style={{ height: "75vh", overflow: "auto" }} >
                <Form

                    form={form}
                    layout="vertical"
                    onFinish={onFinish}
                >



                    <Space style={{ width: "100%" }}>
                        <Row gutter={[16, 16]}>
                            <Space>



                                <Form.Item label="Title" name="title" required
                                    rules={[{
                                        required: true,
                                        message: "Title is Required",

                                    },]}
                                >
                                    <Select
                                        allowClear={false}
                                        placeholder="Select Title"
                                        options={[{ value: 'Mr.', label: 'Mr.' }, { value: 'Mrs.', label: 'Mrs.' }, { value: 'Miss', label: 'Miss' }, { value: 'Dr.', label: 'Dr.' }]}

                                    />
                                </Form.Item>


                                <Form.Item label="First Name" name="first_name" required
                                    rules={[{
                                        required: true,
                                        message: "First name required",

                                    },
                                    {
                                        pattern: /^(?!\s)[a-zA-Z\s]+$/,

                                        message: "Please enter a valid name",
                                    },]}
                                >
                                    <Input style={{ width: "12.3vw" }} type='text' placeholder="First Name" />
                                </Form.Item>
                                <Form.Item
                                    rules={[{
                                        required: true,
                                        message: "Last name required",

                                    },
                                    {
                                        pattern: /^(?!\s)[a-zA-Z\s]+$/,

                                        message: "Please enter a valid name",
                                    },]}
                                    label="Last Name" name="last_name" required >
                                    <Input style={{ width: "12.3vw" }} type='text' placeholder="Last Name" />
                                </Form.Item>
                            </Space>

                            <Space direction='vertical' style={{ width: "100%" }}>
                                <Form.Item
                                    rules={[{
                                        required: true,
                                        message: "Agency is required",

                                    },]}
                                    name='agency_type' label="Select Agency" required>

                                    <Select
                                        placeholder="Select Agency"
                                        style={{ width: "100%" }}

                                        onChange={(value) => { updateAgencyType(value); form.setFieldValue('agency_type', value); }}
                                        // defaultValue={4}
                                        options={
                                            [
                                                { value: 6, label: 'NTFC Secretariat' },
                                                { value: 0, label: 'Presidential Secretariat' },
                                                { value: 1, label: 'NTFC Member Agency (Government Sector)' },
                                                { value: 2, label: 'NTFC Member Agency (Private Sector) ' },
                                                { value: 3, label: 'Donor Agency' },
                                                { value: -1, label: 'Non-NTFC Agency (Government and Private Sector)' },
                                                { value: 99, label: 'Co-chair/Chair Person' },
                                                { value: 98, label: 'PR to WTO' }
                                            ]

                                        }
                                    />

                                </Form.Item>

                                {(agencyType != -1 && agencyType != 0 && agencyType != 6 && agencyType != 99 && agencyType != 98) ? <Form.Item name="agency_name" required label="Agency Name"
                                    rules={[{
                                        required: true,
                                        message: "Agency Name is required",

                                    },
                                        // { min: 5, message: 'At least 5 characters required' },


                                    ]}>

                                    <Select

                                        placeholder="Agency Name"
                                        onChange={(value) => { form.setFieldValue('agency_name', value); form.setFieldValue('agency_id', value); setAgencyName(value) }}
                                        style={{ width: '100%', textAlign: 'left' }}
                                        options={agencies}
                                        value={agencyName}
                                    />

                                </Form.Item> : null}
                                {agencyType === -1 ? <Form.Item name="agency_name" required label="Agency Name"
                                    rules={[{
                                        required: true,
                                        message: "Agency Name is required",

                                    },
                                    {
                                        pattern: /^(?!\s)[a-zA-Z\s]+$/,

                                        message: "Please enter a valid name",
                                    },
                                    { min: 5, message: 'At least 5 characters required' },
                                    ]}>
                                    <Input type='text' placeholder="Agency Name" style={{ width: "20.3vw" }} suffix={<BankOutlined style={{ color: "#D7D3D5" }} />} />
                                </Form.Item> : null
                                }


                                <Form.Item label="Designation" name="designation" required
                                    rules={[{
                                        required: true,
                                        message: "Designation is required",

                                    },
                                    {
                                        pattern: /^(?!\s)[a-zA-Z\s]+$/,

                                        message: "Please enter a valid name",
                                    },]}
                                >
                                    <Input type='text' placeholder="Designation" />
                                </Form.Item>

                                {agencyType == 1 &&

                                    <Form.Item label="User Level" name="user_level" required
                                        rules={[{
                                            required: true,
                                            message: "User level is required",

                                        },]}
                                    >
                                        <Select
                                            placeholder={"User Level"}
                                            allowClear={false}

                                            options={
                                                [
                                                    { value: 1, label: 'Director General' },
                                                    { value: 5, label: 'Head of Agency' },
                                                    { value: 4, label: 'Technical User' },

                                                ]
                                            } />
                                    </Form.Item>

                                }


                                <Form.Item label="Email" name="email" required
                                    rules={[{
                                        type: 'email',
                                        message: <p style={{ marginLeft: "12px" }}>Invalid Email</p>,
                                    },
                                    {
                                        required: true,
                                        message: <p style={{ marginLeft: "12px" }}>Email is required</p>,
                                    },]}>
                                    <Input type='email' placeholder="Email" style={{ width: "100%" }} suffix={<MailOutlined style={{ color: "#D7D3D5" }} />} />
                                </Form.Item>



                                <Form.Item name="mobile" required label="Mobile Number"
                                    rules={[
                                        {
                                            required: true,
                                            message: "Mobile Number is required",
                                        },
                                        {
                                            pattern: /^\d+$/,
                                            message: "Invalid Country Code",
                                        },
                                        {
                                            max: 15,
                                            message: "Maximum 15 digits allowed",
                                        },
                                        ({ getFieldValue }) => ({
                                            validator(_, value) {
                                                console.log("value", value);
                                                if (value.startsWith("94")) {
                                                    if (value.length === 11) {
                                                        if (value[2] != "7") {
                                                            return Promise.reject(
                                                                new Error(
                                                                    "Invalid Mobile Number. Must be +947XXXXXXX format."
                                                                )
                                                            );
                                                        } else {
                                                            return Promise.resolve();
                                                        }

                                                    } else {
                                                        return Promise.reject(
                                                            new Error(
                                                                "Invalid Mobile Number. Must be +94XXXXXXXX format."
                                                            )
                                                        );
                                                    }


                                                }
                                                if (value.startsWith("07")) {
                                                    if (value.length === 10) {
                                                        return Promise.resolve();


                                                    } else {
                                                        return Promise.reject(
                                                            new Error(
                                                                "Invalid Mobile Number. Must be 07XXXXXXX format."
                                                            )
                                                        );
                                                    }

                                                }
                                                return Promise.resolve();
                                            },
                                        }),
                                    ]}
                                >
                                    <PhoneInput
                                        country={'lk'}
                                        value={mobile}
                                        onChange={setMobile}
                                        placeholder="Mobile Number"
                                        inputProps={{
                                            placeholder: 'Mobile Number',

                                        }}
                                        inputStyle={{ width: "100%" }}
                                        // className="select-custom-pn"
                                        disableCountryCode={true}
                                        disableInitialCountryGuess={false}
                                    />
                                </Form.Item>



                                <Form.Item name="phone" label="Phone Number"
                                    rules={[
                                        {
                                            pattern: /^\d+$/,
                                            message: "Invalid Country Code",
                                        },
                                        {
                                            max: 15,
                                            message: "Maximum 15 digits allowed",
                                        },
                                        ({ getFieldValue }) => ({
                                            validator(_, value) {
                                                console.log("value", value);
                                                if (value.startsWith("94")) {
                                                    if (value.length === 11) {


                                                    } else {
                                                        return Promise.reject(
                                                            new Error(
                                                                "Invalid landline Number. Must be +94XXXXXXXXX format."
                                                            )
                                                        );
                                                    }


                                                }
                                                if (value.startsWith("07")) {
                                                    if (value.length === 10) {
                                                        return Promise.resolve();


                                                    } else {
                                                        return Promise.reject(
                                                            new Error(
                                                                "Invalid Mobile Number. Must be 07XXXXXXX format."
                                                            )
                                                        );
                                                    }

                                                }
                                                return Promise.resolve();
                                            },
                                        }),
                                    ]}
                                >
                                    <PhoneInput
                                        country={'lk'}
                                        value={officialMobile}
                                        onChange={setOfficialMobile}
                                        placeholder="Phone Number"
                                        inputStyle={{ width: "100%" }}
                                        // className="select-custom-pn"
                                        inputProps={{
                                            placeholder: 'Official Mobile Number',

                                        }}
                                        disableCountryCode={true}
                                        disableInitialCountryGuess={false}
                                    />
                                </Form.Item>
                                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                                    <Button type='primary' htmlType='submit'>Create User</Button>

                                </div>
                            </Space>







                        </Row>



                    </Space>


                </Form>

            </div>



        </Modal>
        {/* <Text type="secondary">Add New User</Text> */}

        <ToastContainer
            transition={Zoom}
            position="top-right"
            autoClose={5000}
            hideProgressBar
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="colored"
        // style={{ top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}
        />
    </>
}

export default UserEdit;