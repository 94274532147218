import React, { useState, useEffect } from 'react';
import { FaTimes } from 'react-icons/fa';
import './Modal.css';
import { PlusOutlined, UserOutlined, InboxOutlined, CheckCircleOutlined, CommentOutlined, DeleteOutlined, UserDeleteOutlined, InteractionOutlined, LoadingOutlined, UserAddOutlined, SubnodeOutlined, CloudUploadOutlined, CalendarOutlined, ExclamationCircleOutlined, MinusOutlined, MessageOutlined } from '@ant-design/icons';
import TitleBox from '../TitleBox/TitleBox';
import { Avatar, Button, Col, ConfigProvider, Divider, Form, Image, Input, List, Modal, Row, Progress, Skeleton, Space, Spin, Tooltip, Typography, Alert, Tag, DatePicker, Popover } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import MemberCard from '../MemberCard/MemberCard';
import { message, Upload } from 'antd';
import DocumentLoader from '../DocumentLoader/DocumentLoader';
import { AddActionParticipant, AddAgencyActionComment, AddAgencySubActionComment, AddSubActionFiles, AddSubActionParticipant, DeleteActionFile, DeleteSubActionFile, DownloadFiles, GetActionParticipants, GetAgencyUploads, GetSubActionFiles, GetSubActionParticipant, RemoveActionParticipant, RemoveSubActionParticipant, UpdateSubAction, UplaodActionAttachments, addAgencySubAction, addParticipants, changeAgencyActionStatus, changeSubactionStatus, getActionParticipants, getAgencyActivities, getAllUsers, getSubActionsById, removeParticipants, searchParticipants } from '../../api';
import CommentBox from '../CommentBox/CommentBox';
import VirtualList from 'rc-virtual-list';
import StatusCompo from '../../pages/Tasks/StatusCompo';
import { BiUpload } from "react-icons/bi";
import Title from '../TitleBox/Title';
import PDF from './pdf.png'
import EXLS from './excel.png'
import CSV from './csv.png'
import PNG from './PNG.png'
import JPG from './JPG.png'
import PPT from './ppt.png'
import TXT from './txt.png'
import OTHER from './other.png'
import DOCX from './docx.png'

import { ToastContainer, toast, Zoom } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Search from 'antd/es/transfer/search';
import RowData from '../../pages/DonorsNew/RowData';
import { useRef } from 'react';
import dayjs from 'dayjs';
import { NewColorTheme } from '../../appConst';



const { Dragger } = Upload;
const { Text } = Typography;


const CustomeModal = ({
    changeView,
    modalWidth,
    viewMode,
    article,
    measures,
    action,
    subAction,
    icon: IconComponent = PlusOutlined,
    onrRefesh,
    actionType,
    editMode,
    ntfc,
    currentStatus
}) => {

    console.log("Action", action)
    console.log("measures", measures)

    const [showModal, setShowModal] = useState(true);
    const [div1Closed, setDiv1Closed] = useState(false);
    const [div2Closed, setDiv2Closed] = useState(false);
    const [div3Closed, setDiv3Closed] = useState(false);
    const [animationClass, setAnimationClass] = useState('');
    const [comment, setComment] = useState("")
    const [commentLoading, setLoad] = useState(false);
    const [subActionBtn, setLoading] = useState(false);
    const [messageApi, contextHolder] = message.useMessage();
    const [form] = Form.useForm();
    const [form2] = Form.useForm();
    const [form3] = Form.useForm();
    const [requiredMark, setRequiredMarkType] = useState('optional');
    const [subBtnLoading, setSubBtnLoading] = useState(false)
    const [addParticipantsDialog, SetAddParticipants] = useState(false)
    const [refeshActions, setRefreshActions] = useState(false)
    const [seeMore, setSeeMore] = useState(false);
    const [commentAdding, setCommentLoad] = useState(false);
    const [fileTitle, setTitle] = useState("");
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [allActivities, setActivities] = useState([]);
    const [loadingMembers, setLoadMembers] = useState(false)
    const [users, setUsers] = useState([]);
    const [currentActionParticipants, setCurrentActionParticipants] = useState([]);
    const [otherActionParticipants, setOtherActionParticipants] = useState([]);
    const [RemainingParticipants, setRemainingActionParticipants] = useState([]);
    const [uplaodedFiles, setUploadedFiles] = useState([])
    const [fileTitleDisable, setFileTitleDisable] = useState(false)

    const [uplaodedFilesDialog, setUploadDialog] = useState(false)
    const [uploadProgress, setUploadProgress] = useState(0);
    const [open, setOpen] = useState(false);
    const [fileLoading, setFileLoading] = useState(false);
    const [userSearching, setSearch] = useState(false)
    const [searhcUsers, setSearchUsers] = useState([]);
    const [EditMode, setEditMode] = useState(editMode);
    const [Action, setAction] = useState(action)
    const [SubAction, setSubAction] = useState(subAction)
    const fileTitleRef = useRef(null);
    const [curentActionMembers, setCurrentActionMembers] = useState([])
    const [subDivDate, setSubDivDate] = useState(null)
    const [title, setSubTitle] = useState("")
    const [subDescription, setSubDescription] = useState("")
    const [comment2, setComment2] = useState("")
    const [newSubAction, setNewSubAction] = useState(false)
    const [ViewMode, setViewMode] = useState(viewMode)

    const [FileTitleError, setFileTitleError] = useState(false)
    const [fileDeleteConfirmation, setFileDelete] = useState(false)
    const [curentFile, setCurrentFile] = useState()




    const uploadRef = useRef();
    console.log("SubAction", SubAction)
    console.log("EditMode", EditMode)




    useEffect(() => {

        if (EditMode) {
            form.setFieldValue('title', SubAction.subaction)
            form.setFieldValue('description', SubAction.description)
            form.setFieldValue('due_date', dayjs(SubAction.due_date))
            console.log("SubAction------------->", dayjs(new Date(SubAction.due_date)))
            setSubDivDate(dayjs(new Date(SubAction.due_date)))
            setSubDescription(SubAction.description)
            setSubTitle(SubAction.subaction)

        } else {
            form.resetFields();
        }

        if (viewMode == "Add New Sub Action") {
            form.setFieldValue('due_date', dayjs(action.action_def_date))
            setSubDivDate(dayjs(new Date(action.action_def_date)))
        }
        // eslint-disable-next-line
    }, [EditMode]);


    useEffect(() => {
        setViewMode(viewMode)
        if (ViewMode === "Add New Sub Action") {
            setCurrentActionParticipants([])
            setUploadedFiles([])
            setActivities([])
        } else {
            LoadMembers();
            getActivityLog();
            LoadFiles();
        }
    }, []);
    useEffect(() => {
        message.config({
            maxCount: 1,
            duration: 2,
        });
    }, []);


    function getStatus(st) {
        if (st === 0) {
            return "To Do"
        } else if (st === 1) {
            return "In Progress"
        } else if (st === 2) {
            return "Completed"
        } else if (st === 3) {
            return "Delayed"
        } else if (st === 4) {
            return "Missed"
        }
    }

    function getColor(st) {
        if (st === 0) {
            return "magenta"
        } else if (st === 1) {
            return "orange"
        } else if (st === 2) {
            return "green"
        } else if (st === 3) {
            return "yellow"
        } else if (st === 4) {
            return "red"
        }
    }


    const onRequiredTypeChange = ({ requiredMarkValue }) => {
        setRequiredMarkType(requiredMarkValue);
    };

    const onDateChange2 = (date, dateString) => {
        setSubDivDate(dateString);
    };

    async function getActivityLog() {
        setLoad(true)
        let out = await getAgencyActivities(Action.agency_id, Action.action_id)
        if (out.status === 200) {
            setActivities(out.data.out)
            setLoad(false)
        }
    }
    const LoadMembers = async (value) => {

        setLoadMembers(true)

        let allusers = []
        let currentUsers = []

        // get all Participants
        await getAllUsers(Action.agency_id).then(out => {
            allusers = out.data.out

            setUsers(out.data.out)
        });

        if (!EditMode) {
            await GetActionParticipants(Action.agency_id, Action.action_id).then(out => {
                currentUsers = out.data.out
                setCurrentActionMembers(out.data.out)

            })
        } else {
            await GetSubActionParticipant(SubAction.agency_id, SubAction.action_id, SubAction.id).then(out => {
                currentUsers = out.data.out
                setCurrentActionMembers(out.data.out)

            })
        }

        setTimeout(MatchUsers(allusers, currentUsers), 2000);
        // Memebers that are in Agency but not a member for this Action
        const filteredArr = allusers.filter(obj1 => {
            const matchFound = currentUsers.some(obj2 => obj2.user_id === obj1.uuid);
            return !matchFound;
        });

        // // // set user details for the current paricipants -- first_name, last_name...
        // await getCurrentUsersName(filteredArr, allusers, "other")

        // await getCurrentUsersName(currentUsers, allusers, "current")
        // console.log("CU---", currentUsers)
        // console.log("AU---", allusers)

    };
    const MatchUsers = (allusers, currentUsers) => {
        console.log("all use", allusers);
        console.log("action", currentUsers);

        allusers.forEach(item1 => {
            const match = currentUsers.find(item2 => item1.uuid === item2.uuid);
            if (match) {
                item1.matched = true;
            }
        });


        setUsers(allusers)
        setSearchUsers(allusers)
        setLoadMembers(false)
    }
    const getCurrentUsersName = async (OtherMembers, allusers, type) => {



        if (type === "current") {
            OtherMembers.forEach(obj2 => {
                const obj1 = allusers.find(o => o.uuid === obj2.user_id);
                console.log("obj1---", obj1)
                if (obj1) {
                    Object.assign(obj2, obj1);
                }
                console.log("OtherMembers---", OtherMembers)
            });
            setCurrentActionParticipants(OtherMembers)

        } else {
            OtherMembers.forEach(obj2 => {
                const obj1 = allusers.find(o => o.uuid === obj2.user_id);
                if (obj1) {
                    Object.assign(obj2, obj1);
                }
            });

            setOtherActionParticipants(OtherMembers)

        }

        setLoadMembers(false)

    }

    async function LoadFiles() {

        console.log("Action", Action);


        let out = await GetAgencyUploads(Action.agency_id, Action.action_id)

        if (out.data.status === 200) {

            if (EditMode) {
                out = await GetSubActionFiles(Action.agency_id, Action.action_id, SubAction.id)
                setUploadedFiles(out.data.out.filter(element => element.action_id === Action.action_id))
            } else {
                out = await GetAgencyUploads(Action.agency_id, Action.action_id)
                setUploadedFiles(out.data.out.filter(element => element.action_id === Action.action_id))
            }

        } else {
            message.error(out.data.message);
        }


    }


    // MOdal Funtions --------------------

    const toggleModal = () => {
        // setShowModal(!showModal);
        if (showModal) {

        }
        setDiv1Closed(false);
        setDiv2Closed(false);
        setDiv3Closed(false);
        setAnimationClass('');
    };

    const handleClose = (event, divNum) => {
        event.stopPropagation();
        event.preventDefault();
        if (divNum === 1) {
            setDiv1Closed(true);
        } else if (divNum === 2) {
            setDiv2Closed(true);
        } else {
            setDiv3Closed(true);
        }
    };

    useEffect(() => {
        if (div1Closed && div2Closed && div3Closed) {
            setShowModal(false);

        }
    }, [div1Closed, div2Closed, div3Closed]);

    const modalClose = () => {
        if (!div1Closed || !div2Closed || !div3Closed) {
            return;
        }
        setShowModal(false);
        // onrRefesh(true)
    };

    // End of Modal Funations-------------------

    // FIle Uplaod
    const [fileList, setFileList] = useState([]);

    const props = {
        name: 'file',
        multiple: false,
        // Action: ,

        onChange(info) {

            info.fileList = []

            console.log('FIle --------dsdad', info)
            const { status } = info.file;
            if (status !== 'uploading') {
                console.log(info.file, info.fileList);
            }
            if (status === 'done') {
                toast.dismiss();
                toast.success(`${info.file.name} file uploaded successfully.`, {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
                handleFileUpload(info.file);
            } else if (status === 'error') {
                toast.dismiss();
                toast.error(`${info.file.name} file upload failed.`, {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
            }
        },
        onDrop(e) {
            console.log('Dropped files', e);
        },
    };

    async function handleFileUpload(file) {
        toast.dismiss();
        setFileLoading(true)

        const formData = new FormData();
        formData.append('action_file', file);
        formData.append('title', fileTitle);
        formData.append('agency', Action.agency_id);
        formData.append('action', Action.action_id);

        let out
        if (EditMode) {
            formData.append('subaction', SubAction.id);
            out = await AddSubActionFiles(formData, config)
        } else {
            out = await UplaodActionAttachments(formData, config)
        }

        setTitle('') // clear fileTitle state
        fileTitleRef.current.value = '';


        if (out.data.status === 200) {
            setFileLoading(false)
            toast.dismiss();
            toast.success(out.data.message, {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: false,
                pauseOnHover: false,

                theme: "colored",
            });

            setUploadDialog(false)
            setFileList([])
            setTitle("")
            LoadFiles()
        } else {
            toast.dismiss();
            toast.error(out.data.message, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });

        }

        setFileList([])
        setTitle("")
        setFileTitleDisable(false)
        getActivityLog()
        setUploadProgress(0);
    }
    const config = {
        onUploadProgress: (progressEvent) => {
            const progress = Math.round(
                (progressEvent.loaded / progressEvent.total) * 100
            );
            setUploadProgress(progress);
        },
    };

    // Sub Agency Adding
    const onSubmit = async (val) => {

        console.log("date", val)

        setSubBtnLoading(true)


        if (EditMode) {
            const out = await UpdateSubAction({
                id: SubAction.id,
                subaction: val.title,
                description: val.description,
                due_date: subDivDate,
                action_id: SubAction.action_id
            })
            setSubBtnLoading(false)
            if (out.data.status === 200) {
                toast.dismiss();
                toast.success("Sub action has been updated successfully", {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });

                setRefreshActions(true)
                // form.resetFields();
                // onrRefesh(true)

            } else {
                toast.dismiss();
                toast.error(out.data.message, {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });

            }
        } else {
            const out = await addAgencySubAction({
                agency_id: Action.agency_id,
                action_id: Action.action_id,
                sub_action: val.title,
                description: val.description,
                due_date: subDivDate
            })
            setSubBtnLoading(false)
            if (out.data.status === 200) {
                toast.dismiss();
                toast.success("The sub action has been successfully added", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
                setRefreshActions(true)
                form.resetFields();
                changeView("Action View")
                setViewMode("")
                getSingleSubAction(out.data.id)
                // onrRefesh(true)

            } else {
                toast.dismiss();
                toast.error(out.data.message, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
            }
        }



        // console.log(title, description, Action);

        setShowModal(false);
        // onrRefesh(true)
        setSubBtnLoading(false)
    }

    const getSingleSubAction = async (id) => {

        let data = {
            "agency_id": subAction.agency_id,
            "article_id": subAction.article_id
        }

        let out = await getSubActionsById(action.action_id, id)
        let incomeData = out.data.out[0]
        setEditMode(true)

        form.setFieldValue('title', incomeData.subaction)
        form.setFieldValue('description', incomeData.description)
        setSubDivDate(dayjs(new Date(incomeData.due_date)).format('DD/MM/YYYY'))
        setSubDescription(incomeData.description)

        setSubAction(incomeData)
    }

    const DownloadFiles2 = async (file) => {
        console.log(";;;;;", file)
        let out = await DownloadFiles(file.token)

        let blob = new Blob([out.data], { type: file.type });
        let url = window.URL.createObjectURL(blob);

        let link = document.createElement('a');
        link.href = url;

        link.download = file.title ? file.title : (file.url.split("-::-"))[1];

        document.body.appendChild(link);
        link.click();
    }



    function getImageUrl(file) {

        let url
        switch (file.url.split('.').pop()) {
            case 'pdf':
                url = PDF;
                break;
            case 'jpg':
            case 'jpeg':
                url = JPG;
                break;
            case 'png':
                url = PNG;
                break;
            case 'xlsx':
                url = EXLS;
                break;
            case 'csv':
                url = CSV;
                break;
            case 'doc':
            case 'docx':
                url = DOCX;
                break;
            case 'pptx':
                url = PPT;
                break;
            case 'txt':
                url = TXT;
                break;
            default:
                url = OTHER;
                break;
        }

        return url
    }


    const addNewParticipant = async (user) => {

        let data = {
            "agency_id": Action.agency_id,
            "action_id": Action.action_id,
            "participant": user.uuid,

        }
        let out
        if (!EditMode) {
            out = await AddActionParticipant(data)
        } else {
            data.subaction_id = SubAction.id
            out = await AddSubActionParticipant(data)
        }

        if (out) {
            if (out.status === 200) {
                toast.dismiss();
                toast.success("Participant has been successfully added", {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
                LoadMembers()
            } else {
                toast.error(out.data.message, {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
            }
        } else {
            toast.dismiss();
            toast.error("Internal Server Error", {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
        }
        SetAddParticipants(false)
        LoadMembers()
        getActivityLog()


    }

    const removeParticipant = async (user) => {

        let data = {
            "agency_id": user.agency_id,
            "action_id": Action.action_id,
            "participant": user.uuid,

        }
        console.log("Action--------------->", SubAction)
        let out
        if (!EditMode) {
            out = await RemoveActionParticipant(data)
        } else {
            data.subaction_id = SubAction.id
            out = await RemoveSubActionParticipant(data)
        }

        if (out.status === 200) {
            toast.dismiss();
            toast.success(out.data.message, {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });

        } else {
            toast.dismiss();
            toast.error(out.data.message, {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
        }
        SetAddParticipants(false)
        LoadMembers()
        getActivityLog()
    }

    const removeFiles = async (item) => {
        console.log("Item", item)

        let data = {
            id: item.id
            // token: item.token,
            // agency: item.agency_id,
            // action: item.action_id
        }
        let out
        if (!EditMode) {
            out = await DeleteActionFile(data)
            LoadFiles()
            getActivityLog()
        } else {
            data.subaction = item.SubAction
            out = await DeleteSubActionFile(data)
            LoadFiles()
            getActivityLog()
        }

        if (out.status === 200) {
            toast.dismiss();
            toast.success(out.data.message, {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
            setFileDelete(false)

        } else {
            toast.dismiss();
            toast.error(out.data.message, {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
            setFileDelete(false)
        }


    }

    const onSearch = async (value) => {

        console.log("val", value)
        getUsers(value)



        // console.log(out)
    };

    const getUsers = async (value) => {
        setSearch(false)
        let out = await searchParticipants(Action.agency_id, value)
        if (out.status === 200) {
            setSearchUsers(out.data.out)

        }
        setSearch(true)
    }
    const participants = (
        <div style={{ width: '30vw' }}>


            <Text strong>Participants</Text>
            <Divider style={{ margin: 5 }} />

            <Search style={{ width: "100%", margin: "10px 0" }} placeholder="input search text" onSearch={onSearch} enterButton />

            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                {!userSearching ?
                    searhcUsers.map((c, k) => (
                        <Tooltip title={"Add " + c.title + " " + c.first_name + " " + c.last_name} color={"#108ee9"} key={k}>
                            <div onClick={() => addNewParticipant(c)} style={{ padding: "5px 10px", borderRadius: 25, border: '1px solid #e9e7e7', background: '#fafafa', width: 'fit-content', margin: "2px 3px", cursor: "pointer" }}>
                                <Space key={k}>
                                    {/* <Avatar size='small'>{c}</Avatar> */}
                                    <Text>{c.title + " " + c.first_name + " " + c.last_name + " | " + c.designation}</Text>
                                </Space>
                            </div>
                        </Tooltip>
                    )) : <LoadingOutlined style={{ fontSize: "16px", color: "blueviolet" }} />
                }
            </div>

        </div>

    );



    const handleClick = (event) => {
        event.stopPropagation();
    };

    const AddNewComment = async (value) => {
        console.log("value", value.comment)
        let ntfc_agency = await window.localStorage.getItem('ntfc_agency')
        setCommentLoad(true)

        let sendData = {
            // agency_id: parseInt(ntfc_agency),
            agency_id: Action.agency_id,
            action_id: Action.action_id,
            comment: value.comment

        }



        if (value.comment.trim() == "") {
            toast.dismiss();
            toast.error("Please enter valid comment", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
                collapse: true
            });
        } else {

            let out
            if (!EditMode) {
                out = await AddAgencyActionComment(sendData)
            } else {
                sendData.subaction_id = SubAction.id
                out = await AddAgencySubActionComment(sendData)
            }
            if (out.data.status === 200) {
                console.log("action coment sucesss")
                toast.dismiss();
                toast.success(out.data.message, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                    collapse: true
                });


            } else {
                toast.dismiss();
                console.log("action coment error")
                toast.error(out.data.message, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });

            }
        }



        getActivityLog()

        setComment(null)
        form2.resetFields()
        setCommentLoad(false)

    }

    const handleCancel = () => {
        setIsModalVisible(false);
        setFileList([]);
    };

    const handleViewUploads = () => {
        setIsModalVisible(true);
    };


    const changeStatus = (stat, Action) => {

        console.log("out",);
        let obj = {}
        obj.agency_id = Action.agency_id
        obj.new_status = stat
        obj.article_id = Action.article_id
        obj.measure_id = Action.measure_id
        obj.action_id = Action.action_id

        if (EditMode) {
            let sendData = {
                id: SubAction.id,
                status: stat
            }

            changeSubactionStatus(sendData).then((out) => {
                if (out.data.status) {
                    toast.dismiss();
                    toast.success(out.data.message, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    });
                } else {
                    toast.dismiss();
                    toast.error(out.data.message, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    });
                }
            })

        } else {
            changeAgencyActionStatus(obj).then((out) => {
                if (out.data.status === 200) {

                    toast.success(out.data.message, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    });
                } else {

                    toast.error(out.data.message, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    });
                }
            })
        }
    }
    function formatISODate(dateString) {
        const date = new Date(dateString);
        const options = { day: 'numeric', month: 'numeric', year: 'numeric' };
        const formattedDate = new Intl.DateTimeFormat('en-GB', options).format(date);
        const hours = date.getHours();
        const minutes = date.getMinutes().toString().padStart(2, '0');
        const ampm = hours >= 12 ? 'PM' : 'AM';
        const formattedTime = `${hours % 12 || 12}:${minutes} ${ampm}`;
        return `${formattedDate} ${formattedTime}`;
    }


    return (
        <div style={{ overflow: 'hidden' }}>
            <div className="modal-content" >
                <div className="modal-row" style={{ marginTop: -30 }}>
                    <div id="div1" className="childDiv div1 custom-scroll-div" style={{ backgroundColor: 'white' }}>
                        {/* <FaTimes className="close-icon" onClick={(e) => handleClose(e, 1)} /> */}
                        <TitleBox
                            titleBoxColor={NewColorTheme.primaryColor}
                            title={ViewMode === "Action View" ? "Action Details" : "Sub Action Details"}
                            body={
                                <div style={{ width: "100%" }}>
                                    <Space style={{ width: "100%" }} size={12} direction='vertical'>

                                        <Typography className="article-title">{article.article_no + ". " + article.article}</Typography>
                                        <Typography style={{ paddingLeft: 15 }} className="measure-title">{article.article_no + ". " + measures.measure_no + ". " + measures.measure_name}</Typography>
                                        <Typography style={{ paddingLeft: 30 }} className="action-title2">{article.article_no + ". " + measures.measure_no + ". " + Action.action_no + ". " + Action.action}</Typography>
                                        {/* {EditMode &&
                                            <StatusCompo status={EditMode ? SubAction.status : SubAction.action_status} Action={SubAction} setStatus={changeStatus} />

                                        } */}

                                        {ViewMode === "Action View" ?

                                            <div>
                                                {
                                                    Action.subactions.map((element, key) => {
                                                        return <div>
                                                            <Typography.Text style={{ fontWeight: 700, paddingLeft: 45 }} key={key}>{article.article_no + ". " + measures.measure_no + ". " + Action.action_no + ". " + (key + 1) + " " + element.subaction}</Typography.Text>
                                                        </div>


                                                    })
                                                }
                                            </div>

                                            :
                                            <div>
                                                {currentStatus !== 2 ?
                                                    <Form

                                                        layout="vertical"
                                                        style={{
                                                            maxWidth: 700,
                                                        }}
                                                        labelCol={{
                                                            span: 0,
                                                            style: { color: 'black', textAlign: 'left', fontWeight: 700, },
                                                        }}
                                                        wrapperCol={{
                                                            span: 24,
                                                        }}
                                                        form={form}
                                                        onValuesChange={onRequiredTypeChange}

                                                        onFinish={onSubmit}
                                                    >

                                                        <Row gutter={[8, 8]}>



                                                            <Col xs={24}>
                                                                <Form.Item style={{ paddingLeft: 45 }} label=
                                                                    {EditMode ? <span>{(article.article_no + ". " + measures.measure_no + ". " + Action.action_no + ". " + SubAction.id)}</span> : <span>Sub Action Title</span>}



                                                                    name="title"
                                                                    required rules={[
                                                                        {
                                                                            required: true,
                                                                            message: <p style={{ marginLeft: "12px", marginTop: -25 }}>Sub action title is required</p>
                                                                        },
                                                                        {
                                                                            pattern: /^(?!\s).*$/,
                                                                            message: <p style={{ marginLeft: "12px", marginTop: -35 }}>Sub action title is invalid</p>,
                                                                        },


                                                                    ]}
                                                                >

                                                                    <Input value={title}
                                                                        // className='form-item'
                                                                        placeholder="Add sub action title *"
                                                                        maxLength={40}
                                                                        showCount
                                                                    // onChange={(e) => {

                                                                    //     setSubTitle(e.target.value); form.setFieldValue('title', e.target.value)
                                                                    //     form.validateFields(["title"]);
                                                                    // }}

                                                                    />
                                                                    {/* <div style={{ width: "100%", justifyContent: "flex-end", display: "flex" }}>
                                                                        <p >{title?.length}/40</p>
                                                                    </div> */}

                                                                    {/* <span className='status'>
To Do
</span> */}
                                                                </Form.Item>
                                                            </Col>
                                                            <Col xs={24}>
                                                                <Form.Item style={{ paddingLeft: 45 }} name="description" label="Description">
                                                                    <TextArea
                                                                        // className='form-item' 
                                                                        rows={4}
                                                                        placeholder="Add sub action description"
                                                                        maxLength={200}
                                                                        // value={subDescription}
                                                                        showCount
                                                                    // onChange={(e) => { setSubDescription(e.target.value); form.setFieldValue('description', e.target.value) }}
                                                                    />
                                                                    {/* <div style={{ width: "100%", justifyContent: "flex-end", display: "flex" }}>
                                                                        <p >{subDescription?.length}/200</p>
                                                                    </div> */}

                                                                </Form.Item>
                                                            </Col>
                                                            <Col xs={24}>
                                                                <Form.Item style={{ paddingLeft: 45 }} name="due_date"
                                                                    label={<span>Due Date</span>}

                                                                    required={true}

                                                                    rules={[
                                                                        {
                                                                            required: true,
                                                                            message: <p style={{ marginLeft: "12px" }}>Date is required</p>
                                                                        }]}
                                                                >
                                                                    <DatePicker onChange={onDateChange2} format="DD/MM/YYYY"
                                                                        value={subDivDate ? dayjs(subDivDate) : undefined}
                                                                        defaultValue={subDivDate ? dayjs(subDivDate) : undefined}

                                                                        disabledDate={(current) =>
                                                                            current &&
                                                                            (current < dayjs().startOf('day') ||
                                                                                current > dayjs(action ? action.action_def_date : measures.measure_def_date).startOf('day'))
                                                                        }   // disabledDate={(current) => current && current < dayjs(defDate).startOf('day')}

                                                                        style={{ width: "100%", borderRadius: "5px" }} />
                                                                </Form.Item>
                                                            </Col>

                                                        </Row>





                                                        <div style={{ display: "flex", width: "100%", justifyContent: "flex-end" }}>
                                                            <ConfigProvider
                                                                theme={{
                                                                    token: {
                                                                        colorPrimary: NewColorTheme.primaryColor,
                                                                    },
                                                                }}
                                                            >
                                                                <Button
                                                                    loading={subBtnLoading}
                                                                    disabled={form.isFieldTouched("title") ? false : true}
                                                                    htmlType="submit"
                                                                    type="primary" >
                                                                    {EditMode ? "Update sub action" : "Add sub action"}</Button>
                                                            </ConfigProvider>
                                                        </div>



                                                    </Form>

                                                    :
                                                    <div>
                                                        <Typography style={{ paddingLeft: 30 }} className="action-title2">{(article.article_no + ". " + measures.measure_no + ". " + Action.action_no + ". " + SubAction.id + '. ' + SubAction.subaction)}</Typography>

                                                    </div>
                                                }
                                            </div>


                                        }



                                    </Space>
                                </div>
                            }

                        />

                        { }

                        <TitleBox
                            titleBoxColor={NewColorTheme.primaryColor}
                            title={"Members"}
                            body={
                                <div style={{ width: "100%" }}>
                                    <Row gutter={[16, 16]}>
                                        {(!ntfc && currentStatus !== 2) && <Col span={8}>
                                            <div className="upload-button">
                                                <UserAddOutlined width={64} style={{ fontSize: 25, color: NewColorTheme.primaryColor }} />
                                                {/* <Typography.Text className="upload-text">Add new Member</Typography.Text> */}
                                                <ConfigProvider
                                                    theme={{
                                                        token: {
                                                            colorPrimary: NewColorTheme.primaryColor,
                                                        },
                                                    }}
                                                >
                                                    <Button disabled={ViewMode === "Add New Sub Action" ? true : false} type='primary' onClick={() => {
                                                        SetAddParticipants(true)
                                                        LoadMembers()
                                                        form3.resetFields()
                                                        // getUsers("")
                                                    }}>Add Member</Button>
                                                </ConfigProvider>

                                            </div>
                                        </Col>}

                                        {curentActionMembers.length > 0 ?
                                            <Col span={16}>
                                                {!loadingMembers ?
                                                    <div style={{ width: "100%", height: "20vh", overflow: "auto", padding: " 0px 20px 0px 20px" }} className='custom-scroll-div'>



                                                        <List
                                                            dataSource={curentActionMembers}
                                                            renderItem={(item) => (
                                                                <List.Item key={item.email}>
                                                                    {console.log("Item---", item)}
                                                                    <List.Item.Meta
                                                                        avatar={<Avatar
                                                                            style={{
                                                                                backgroundColor: NewColorTheme.primaryColor,
                                                                            }}
                                                                            icon={<UserOutlined />}
                                                                        />}
                                                                        title={item.title + " " + item.first_name + " " + item.last_name}
                                                                        description={item.designation}
                                                                    />
                                                                    {/* <a href={}>download</a> */}
                                                                    {(!ntfc && currentStatus !== 2) && <Button size="small" shape="square" onClick={() => removeParticipant(item)} icon={<MinusOutlined />} />}
                                                                </List.Item>
                                                            )}
                                                        >

                                                        </List>
                                                    </div>

                                                    : <LoadingOutlined style={{ marginLeft: 20 }} />}
                                            </Col> :
                                            <Col span={24}>
                                                <ConfigProvider
                                                    theme={{
                                                        alert: {
                                                            colorInfo: NewColorTheme.primaryColor,
                                                            backgroundColor: NewColorTheme.primaryColor
                                                        }
                                                    }}

                                                >
                                                    <Alert
                                                        message={<Typography.Title level={4} style={{ color: NewColorTheme.primaryColor }}>Note</Typography.Title>}
                                                        description="There are no members added."
                                                        type="info"
                                                        showIcon
                                                        icon={<ExclamationCircleOutlined style={{ color: NewColorTheme.primaryColor }} />}
                                                        style={{ color: NewColorTheme.primaryColor, backgroundColor: "#E5EBEB", border: '1px solid #282D93' }}
                                                    />
                                                </ConfigProvider>


                                            </Col>
                                        }

                                    </Row>



                                    {/* <Tooltip title="Add New Member">
                                        <div>
                                            <MemberCard onClick={() => {
                                                console.log("clicked")
                                                SetAddParticipants(true)
                                                LoadMembers()
                                            }} name="" role={""} />
                                        </div>
                                    </Tooltip> */}







                                </div>
                            }

                        />

                        <TitleBox
                            titleBoxColor={NewColorTheme.primaryColor}
                            title={"Attachments"}
                            body={
                                <div style={{ width: "100%" }}>
                                    <Row gutter={[16, 16]}>
                                        {(!ntfc && currentStatus !== 2) &&
                                            <Col xs={24}>
                                                <Alert style={{ marginBottom: "20px" }} type='info' message=
                                                    {"Please ensure that only one attachment is uploaded at a time, and the maximum file size should be 5MB"}
                                                >

                                                </Alert>
                                            </Col>
                                        }

                                        <Col span={8}>
                                            <Row gutter={[16, 16]}>
                                                {(!ntfc) &&
                                                    <Col span={24}>
                                                        <div className="upload-button">
                                                            <BiUpload width={64} style={{ fontSize: 25, color: NewColorTheme.primaryColor }} />
                                                            {/* <Typography.Text disabled={fileTitleDisable} className="upload-text">Upload Attachments</Typography.Text> */}
                                                            <ConfigProvider
                                                                theme={{
                                                                    token: {
                                                                        colorPrimary: NewColorTheme.primaryColor,
                                                                    },
                                                                }}
                                                            >
                                                                <Button disabled={ViewMode === "Add New Sub Action" ? true : false} type='primary' onClick={() => {
                                                                    setFileTitleError(false)
                                                                    setUploadDialog(true)
                                                                }}>Upload Files</Button>
                                                            </ConfigProvider>

                                                        </div>

                                                    </Col>
                                                }
                                                {uplaodedFiles.length > 0 &&
                                                    <Col span={24}>
                                                        <Typography.Text style={{ fontSize: 15 }}>No of files attached : {uplaodedFiles.length}</Typography.Text>
                                                    </Col>
                                                }

                                            </Row>



                                        </Col>

                                        {uplaodedFiles.length > 0 ? <Col span={16}>

                                            <div className='custom-scroll-div' style={{ height: "40vh", overflow: 'auto', padding: " 0px 10px 0px 20px" }}>
                                                <List dataSource={uplaodedFiles.sort((a, b) => b.id - a.id)}
                                                    renderItem={(item, key) => (
                                                        <List.Item key={key} onClick={() => DownloadFiles2(item)} style={{ cursor: "pointer", paddingRight: 20 }}>
                                                            <List.Item.Meta
                                                                avatar={<Image src={getImageUrl(item)} />}
                                                                title={item.title}
                                                                description={<div>{"By " + item.first_name + " " + item.last_name}
                                                                    <div>{formatISODate(item.created)}</div>
                                                                </div>}
                                                            />

                                                            {(!ntfc && currentStatus !== 2) &&
                                                                <Button shape="square" onClick={(e) => {
                                                                    e.stopPropagation();
                                                                    setCurrentFile(item)
                                                                    setFileDelete(true)


                                                                }} icon={<DeleteOutlined />} />}

                                                        </List.Item>
                                                    )}
                                                >

                                                </List>
                                            </div>
                                            {/* <List
                    itemLayout="horizontal"
                    dataSource={uplaodedFiles}
                    renderItem={(item, index) => (
                        <List.Item>
                            <List.Item.Meta
                                avatar={<Image src={item.url} />}
                                title={<p>{item.title}</p>}
                                description={"By " + item.first_name + " " + item.last_name}
                            />
                        </List.Item>
                    )}
                /> */}

                                            {/* {uplaodedFiles.map((file, key) => {
                return <DocumentLoader key={key} name="Agreement" member={"By Conry Henry"} size="14.48MB" />
            })} */}



                                        </Col> : <Col span={24}>
                                            <ConfigProvider
                                                theme={{
                                                    alert: {
                                                        colorInfo: NewColorTheme.primaryColor,
                                                        backgroundColor: '#F3ECF3'
                                                    }
                                                }}

                                            >
                                                <Alert
                                                    message={<Typography.Title level={4} style={{ color: NewColorTheme.primaryColor }}>Note</Typography.Title>}
                                                    description="There are no files added."
                                                    type="info"
                                                    showIcon
                                                    icon={<ExclamationCircleOutlined style={{ color: NewColorTheme.primaryColor }} />}
                                                    style={{ color: NewColorTheme.primaryColor, backgroundColor: "#E5EBEB", border: '1px solid #282D93' }}
                                                />
                                            </ConfigProvider>


                                        </Col>}



                                    </Row>


                                </div>
                            }

                        />
                    </div>

                    {ViewMode !== "Add New Sub Action" &&
                        <div className="childDiv div2" style={{ backgroundColor: 'white' }}>
                            {/* <FaTimes className="close-icon" onClick={(e) => handleClose(e, 2)} /> */}
                            <TitleBox
                                comment={true}
                                titleBoxColor={NewColorTheme.primaryColor}
                                title={"Comments"}
                                body={
                                    <div style={{ display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
                                        <div style={{ marginLeft: 0, height: "380px", }} className='custom-scroll-div'>
                                            {/* {!commentLoading ? */}
                                            <List

                                                dataSource={(EditMode ? allActivities.filter(element => (element.activity_type === 1 || element.activity_type === 2) && element.opt1 === SubAction.id) : allActivities.filter(element => element.activity_type === 1 || element.activity_type === 2)).reverse()}
                                                renderItem={(item, key) => (

                                                    <List.Item key={key} style={{
                                                        backgroundColor: " #FFFF",


                                                        padding: 10, margin: 5, borderRadius: "0 10px 10px 10px"
                                                    }}>

                                                        <>
                                                            <List.Item.Meta
                                                                avatar={<Avatar
                                                                    style={{
                                                                        backgroundColor: NewColorTheme.primaryColor,
                                                                    }}
                                                                    icon={<UserOutlined />}
                                                                />}
                                                                title={<Typography strong={true} style={{ fontWeight: 700 }}>{item.title + " " + item.first_name + " " + item.last_name} </Typography>}
                                                                description={
                                                                    <p style={{ width: "100%" }}>
                                                                        <span>{item.desc1.length > 50 ? item.desc1.slice(0, 50) + "... " : item.desc1}</span>
                                                                        {item.desc1.length > 50 ? <Popover content={<div style={{ width: 200 }}>{item.desc1}</div>} title={"By" + " " + item.title + " " + item.first_name + " " + item.last_name}>
                                                                            <a>see more</a>
                                                                        </Popover> : ""}

                                                                        <div>on {formatISODate(item.created)}</div>
                                                                    </p>

                                                                }
                                                            />



                                                        </>




                                                    </List.Item>
                                                )}
                                            >

                                            </List>
                                            {/* :  */}
                                            {/* <LoadingOutlined style={{ fontSize: "16px", color: "blueviolet" }} /> */}
                                            {/* } */}


                                            {/* <CommentBox name="Beyond Nexy" comment={"Newk Comment"} /> */}

                                        </div>
                                        {localStorage.getItem('view_only') != 1 &&
                                            <div style={{
                                                position: 'absolute',
                                                bottom: '20px',
                                                width: '97%',
                                                padding: '10px',
                                                left: '3px',

                                            }}>
                                                <Form form={form2} onFinish={AddNewComment}>
                                                    <Form.Item
                                                        // label="Comment"
                                                        required
                                                        name="comment"
                                                        rules={[

                                                            { required: true, message: 'Please enter a comment' },
                                                            {
                                                                pattern: /^(?!\s+$).*/,
                                                                message:
                                                                    "Please enter a valid comment",
                                                            },]}
                                                    >
                                                        <Input.TextArea rows={3} placeholder='Enter Comment' maxLength={150} showCount
                                                            onChange={(e) => { form2.setFieldValue('comment', e.target.value); form2.validateFields(); }}
                                                        />
                                                        {/* <p >{comment?.length}/150</p> */}

                                                    </Form.Item>
                                                    <ConfigProvider
                                                        theme={{
                                                            token: {
                                                                colorPrimary: NewColorTheme.primaryColor,
                                                            },
                                                        }}
                                                    >
                                                        <Button loading={commentAdding} style={{
                                                            position: 'absolute',
                                                            left: '10px',
                                                            bottom: '-32px'
                                                        }} type="primary"
                                                            htmlType='submit'
                                                        >Save</Button>
                                                    </ConfigProvider>
                                                </Form>
                                            </div>
                                        }

                                    </div>
                                }

                            />
                        </div>
                    }


                    {ViewMode !== "Add New Sub Action" && <div className="childDiv div3" style={{ backgroundColor: 'white', paddingRight: 0 }}>
                        {/* <FaTimes className="close-icon" onClick={(e) => handleClose(e, 3)} /> */}
                        <TitleBox
                            history={true}
                            titleBoxColor={NewColorTheme.primaryColor}
                            title={"History"}
                            body={
                                <div style={{ width: "100%" }}>
                                    <div style={{ marginLeft: -14, height: '580px' }}>
                                        {/* {!commentLoading ?  */}
                                        <List

                                            dataSource={EditMode ? allActivities.filter(element => element.opt1 === SubAction.id) : allActivities}
                                            renderItem=
                                            {(item, key) => (

                                                <List.Item key={key} style={{
                                                    backgroundColor: " #FFFF",


                                                    padding: 10, margin: 5, borderRadius: "0 10px 10px 10px"
                                                }}>

                                                    {item.activity_type === 1 || item.activity_type === 2 ?
                                                        <>
                                                            <List.Item.Meta
                                                                avatar={<Avatar
                                                                    style={{
                                                                        backgroundColor: NewColorTheme.primaryColor,
                                                                    }}
                                                                    icon={<MessageOutlined />}
                                                                />}
                                                                title={<Typography strong={true} style={{ fontWeight: 700 }}>New Comment</Typography>}
                                                                description={
                                                                    <p>
                                                                        <p> {"Added by " + " " + item.title + " " + item.first_name + " " + item.last_name}</p>
                                                                        <p style={{ marginTop: -15 }}> on  {formatISODate(item.created)}</p>



                                                                    </p>
                                                                }
                                                            />

                                                        </>
                                                        :
                                                        item.activity_type === 3 ?
                                                            <>
                                                                <List.Item.Meta
                                                                    avatar={<Avatar
                                                                        style={{
                                                                            backgroundColor: NewColorTheme.primaryColor,
                                                                        }}
                                                                        icon={<InteractionOutlined />}
                                                                    />}
                                                                    title={
                                                                        <div>
                                                                            <Typography strong={true} style={{ fontWeight: 700 }}>Action Status Change </Typography>

                                                                        </div>
                                                                    }
                                                                    description={
                                                                        <p>

                                                                            <p> <><Tag color={getColor(item.opt1)}>{getStatus(item.opt1)}</Tag>

                                                                                <span>{"->"}</span>
                                                                                <Tag color={getColor(item.opt2)}>{getStatus(item.opt2)}</Tag>

                                                                            </>
                                                                            </p>
                                                                            <p style={{ marginTop: -8 }}> on  {formatISODate(item.created)} <br />  {"By " + " " + item.title + " " + item.first_name + " " + item.last_name}</p>
                                                                            <p></p>



                                                                        </p>
                                                                    }


                                                                />


                                                            </>


                                                            : item.activity_type === 4 ?
                                                                <>
                                                                    <List.Item.Meta
                                                                        avatar={<Avatar
                                                                            style={{
                                                                                backgroundColor: NewColorTheme.primaryColor,
                                                                            }}
                                                                            icon={<InteractionOutlined />}
                                                                        />}
                                                                        title={
                                                                            <div>
                                                                                <Typography strong={true} style={{ fontWeight: 700 }}>Sub Action Status Change </Typography>

                                                                            </div>
                                                                        }
                                                                        description={
                                                                            <p>
                                                                                <p> {"By " + " " + item.title + " " + item.first_name + " " + item.last_name}</p>
                                                                                <p> <><Tag color={getColor(item.opt2)}>{getStatus(item.opt2)}</Tag>

                                                                                    <span>{"->"}</span>
                                                                                    <Tag color={getColor(item.opt3)}>{getStatus(item.opt3)}</Tag>

                                                                                </>
                                                                                </p>
                                                                                <p style={{ marginTop: -8 }}> on  {formatISODate(item.created)}</p>



                                                                            </p>
                                                                        }


                                                                    />


                                                                </>


                                                                : item.activity_type === 8 ?
                                                                    <>
                                                                        <List.Item.Meta
                                                                            avatar={<Avatar
                                                                                style={{
                                                                                    backgroundColor: NewColorTheme.primaryColor,
                                                                                }}
                                                                                icon={<CloudUploadOutlined />}
                                                                            />}
                                                                            title={<Typography strong={true} style={{ fontWeight: 700 }}>New File Attachment </Typography>}
                                                                            description={
                                                                                <div>
                                                                                    Document uploaded to {item.desc1.toLowerCase()}
                                                                                    <p>{"File Name : " + item.desc3}</p>
                                                                                    <div>on {formatISODate(item.created)}</div>
                                                                                    <div>By {item.title + " " + item.first_name + " " + item.last_name}</div>

                                                                                </div>


                                                                            }
                                                                        />



                                                                    </> : item.activity_type === 9 ?
                                                                        <>
                                                                            <List.Item.Meta
                                                                                avatar={<Avatar
                                                                                    style={{
                                                                                        backgroundColor: NewColorTheme.primaryColor,
                                                                                    }}
                                                                                    icon={<UserAddOutlined />}
                                                                                />}
                                                                                title={<Typography strong={true} style={{ fontWeight: 700 }}>New User Assignment </Typography>}
                                                                                description={
                                                                                    <div>
                                                                                        {item.participant + " was assigned to this action"}
                                                                                        <div>on {formatISODate(item.created)}</div>
                                                                                        <div>By {item.title + " " + item.first_name + " " + item.last_name}</div>

                                                                                    </div>


                                                                                }
                                                                            />


                                                                        </> : item.activity_type === 10 ?
                                                                            <>
                                                                                <List.Item.Meta
                                                                                    avatar={<Avatar
                                                                                        style={{
                                                                                            backgroundColor: NewColorTheme.primaryColor,
                                                                                        }}
                                                                                        icon={<UserDeleteOutlined />}
                                                                                    />}
                                                                                    title={<Typography strong={true} style={{ fontWeight: 700 }}>User unassigned</Typography>}
                                                                                    description={
                                                                                        <div>
                                                                                            {item.participant + " was unassigned from the action "}
                                                                                            <div>on {formatISODate(item.created)}</div>
                                                                                            <div>By {item.title + " " + item.first_name + " " + item.last_name} </div>
                                                                                        </div>


                                                                                    }
                                                                                />


                                                                            </> : item.activity_type === 5 ?
                                                                                <>
                                                                                    <List.Item.Meta
                                                                                        avatar={<Avatar
                                                                                            style={{
                                                                                                backgroundColor: NewColorTheme.primaryColor,
                                                                                            }}
                                                                                            icon={<InteractionOutlined />}
                                                                                        />}
                                                                                        title={<Typography strong={true} style={{ fontWeight: 700 }}>Action Status Change Approval</Typography>}
                                                                                        description={
                                                                                            <div>
                                                                                                {"Approval for"}
                                                                                                <p> <><Tag color={getColor(item.opt1)}>{getStatus(item.opt1)}</Tag>

                                                                                                    <span>{"->"}</span>
                                                                                                    <Tag color={getColor(item.opt2)}>{getStatus(item.opt2)}</Tag>

                                                                                                </>
                                                                                                </p>
                                                                                                <div>on {formatISODate(item.created)}</div>
                                                                                                <div>By {item.title + " " + item.first_name + " " + item.last_name} </div>

                                                                                            </div>


                                                                                        }
                                                                                    />


                                                                                </> : item.activity_type === 6 ?
                                                                                    <>
                                                                                        <List.Item.Meta
                                                                                            avatar={<Avatar
                                                                                                style={{
                                                                                                    backgroundColor: NewColorTheme.primaryColor,
                                                                                                }}
                                                                                                icon={<InteractionOutlined />}
                                                                                            />}
                                                                                            title={<Typography strong={true} style={{ fontWeight: 700 }}>Action Status Change Rejection</Typography>}
                                                                                            description={
                                                                                                <div>
                                                                                                    {/* {"Status change rejection for" + getStatus(item.opt1) + " -> " + getStatus(item.opt2)} */}
                                                                                                    <p> <><Tag color={getColor(item.opt1)}>{getStatus(item.opt1)}</Tag>

                                                                                                        <span>{"->"}</span>
                                                                                                        <Tag color={getColor(item.opt2)}>{getStatus(item.opt2)}</Tag>

                                                                                                    </>
                                                                                                    </p>
                                                                                                    <p>Reason - {item.desc1}</p>
                                                                                                    <div>on {formatISODate(item.created)}</div>
                                                                                                    <div>By{item?.rejected_by} </div>

                                                                                                </div>


                                                                                            }
                                                                                        />

                                                                                    </> : item.activity_type === 7 ?
                                                                                        <>
                                                                                            <List.Item.Meta
                                                                                                avatar={<Avatar
                                                                                                    style={{
                                                                                                        backgroundColor: NewColorTheme.primaryColor,
                                                                                                    }}
                                                                                                    icon={<CalendarOutlined />}
                                                                                                />}
                                                                                                title={<Typography strong={true} style={{ fontWeight: 700 }}>Extended of Measure </Typography>}
                                                                                                description={
                                                                                                    <div>
                                                                                                        {"Measure Extended from" + item.desc1 + " -> " + item.desc2}

                                                                                                        <div>on {formatISODate(item.created)}</div>
                                                                                                        <div>by {item.title + " " + item.first_name + " " + item.last_name}</div>
                                                                                                    </div>


                                                                                                }
                                                                                            />



                                                                                        </> : item.activity_type === 11 ?
                                                                                            <>
                                                                                                <List.Item.Meta
                                                                                                    avatar={<Avatar
                                                                                                        style={{
                                                                                                            backgroundColor: NewColorTheme.primaryColor,
                                                                                                        }}
                                                                                                        icon={<CheckCircleOutlined />}
                                                                                                    />}
                                                                                                    title={<Typography strong={true} style={{ fontWeight: 700 }} >Completion of Action</Typography>}
                                                                                                    description={
                                                                                                        <div>
                                                                                                            <p> <><Tag color={getColor(item.opt1)}>{getStatus(item.opt1)}</Tag>

                                                                                                                <span>{"->"}</span>
                                                                                                                <Tag color={getColor(item.opt2)}>{getStatus(item.opt2)}</Tag>

                                                                                                            </>
                                                                                                            </p>

                                                                                                            <div>on {formatISODate(item.created)}</div>
                                                                                                            <div>By {item.title + " " + item.first_name + " " + item.last_name} </div>

                                                                                                        </div>


                                                                                                    }
                                                                                                />



                                                                                            </> : item.activity_type === 12 ?
                                                                                                <>
                                                                                                    <List.Item.Meta
                                                                                                        avatar={<Avatar
                                                                                                            style={{
                                                                                                                backgroundColor: NewColorTheme.primaryColor,
                                                                                                            }}
                                                                                                            icon={<CalendarOutlined />}
                                                                                                        />}
                                                                                                        title={<Typography strong={true} style={{ fontWeight: 700 }}> Completion of Measure </Typography>}
                                                                                                        description={
                                                                                                            <div>
                                                                                                                {"Measure Completion " + item.opt1}

                                                                                                                <div>on {formatISODate(item.created)}</div>
                                                                                                                <div>By {item.title + " " + item.first_name + " " + item.last_name}</div>

                                                                                                            </div>


                                                                                                        }
                                                                                                    />


                                                                                                </> : item.activity_type === 13 ?
                                                                                                    <>
                                                                                                        <List.Item.Meta
                                                                                                            avatar={<Avatar
                                                                                                                style={{
                                                                                                                    backgroundColor: NewColorTheme.primaryColor,
                                                                                                                }}
                                                                                                                icon={<SubnodeOutlined />}
                                                                                                            />}
                                                                                                            title={<Typography strong={true} style={{ fontWeight: 700 }}>Sub Action Creation </Typography>}
                                                                                                            description={
                                                                                                                <div>
                                                                                                                    {`New sub action created`}

                                                                                                                    <div>on {formatISODate(item.created)}</div>
                                                                                                                    <div>By {item.title + " " + item.first_name + " " + item.last_name}</div>

                                                                                                                </div>


                                                                                                            }
                                                                                                        />


                                                                                                    </> : item.activity_type === 15 ?
                                                                                                        <>
                                                                                                            <List.Item.Meta
                                                                                                                avatar={<Avatar
                                                                                                                    style={{
                                                                                                                        backgroundColor: NewColorTheme.primaryColor,
                                                                                                                    }}
                                                                                                                    icon={<CalendarOutlined />}
                                                                                                                />}
                                                                                                                title={<Typography strong={true} style={{ fontWeight: 700 }}>{`${item.desc1 == "SUBACTION" ? "Sub Action" : "Action"} File Deletion`} </Typography>}
                                                                                                                description={
                                                                                                                    <div>
                                                                                                                        {`File Name : ${item.desc2}`}

                                                                                                                        <div>on {formatISODate(item.created)}</div>
                                                                                                                        <div>Deleted By {item.title + " " + item.first_name + " " + item.last_name}</div>

                                                                                                                    </div>


                                                                                                                }
                                                                                                            />


                                                                                                        </> : item.activity_type === 14 ?
                                                                                                            <>
                                                                                                                <List.Item.Meta
                                                                                                                    avatar={<Avatar
                                                                                                                        style={{
                                                                                                                            backgroundColor: NewColorTheme.primaryColor,
                                                                                                                        }}
                                                                                                                        icon={<CalendarOutlined />}
                                                                                                                    />}
                                                                                                                    title={<Typography strong={true} style={{ fontWeight: 700 }}>Action Assign </Typography>}
                                                                                                                    description={
                                                                                                                        <div>
                                                                                                                            {`Action is assigned`}

                                                                                                                            <div>on {formatISODate(item.created)}</div>
                                                                                                                            <div>By {item.title + " " + item.first_name + " " + item.last_name + " " + " was "}</div>

                                                                                                                        </div>


                                                                                                                    }
                                                                                                                />


                                                                                                            </> : null
                                                    }


                                                </List.Item>
                                            )}
                                        >
                                            {/* <VirtualList
                                                // data={mainAction ?
                                                //     allActivities.filter(element =>
                                                //         (element.activity_type === 1 || element.activity_type === 3) && element.action_id === Action.action_id)
                                                //     :
                                                //     allActivities.filter(element => (element.activity_type === 2 || element.activity_type === 4) && element.action_id === sub.id)
                                                // }
                                                data={EditMode ? allActivities.filter(element => element.opt1 === SubAction.id) : allActivities}
                                                height={550}
                                                itemHeight={45}
                                                itemKey="email"



                                            >

                                                {(item, key) => (

                                                    <List.Item key={key} style={{
                                                        backgroundColor: " #FFFF",


                                                        padding: 10, margin: 5, borderRadius: "0 10px 10px 10px"
                                                    }}>

                                                        {item.activity_type === 1 || item.activity_type === 2 ?
                                                            <>
                                                                <List.Item.Meta
                                                                    avatar={<Avatar
                                                                        style={{
                                                                            backgroundColor: NewColorTheme.primaryColor,
                                                                        }}
                                                                        icon={<MessageOutlined />}
                                                                    />}
                                                                    title={<Typography strong={true} style={{ fontWeight: 700 }}>New Comment</Typography>}
                                                                    description={
                                                                        <p>
                                                                            <p> {"Added by " + " " + item.title + " " + item.first_name + " " + item.last_name}</p>
                                                                            <p style={{ marginTop: -15 }}> on  {formatISODate(item.created)}</p>



                                                                        </p>
                                                                    }
                                                                />

                                                            </>
                                                            :
                                                            item.activity_type === 3 || item.activity_type === 4 ?
                                                                <>
                                                                    <List.Item.Meta
                                                                        avatar={<Avatar
                                                                            style={{
                                                                                backgroundColor: NewColorTheme.primaryColor,
                                                                            }}
                                                                            icon={<InteractionOutlined />}
                                                                        />}
                                                                        title={
                                                                            <div>
                                                                                <Typography strong={true} style={{ fontWeight: 700 }}>Status Change </Typography>

                                                                            </div>
                                                                        }
                                                                        description={
                                                                            <p>
                                                                                <p> {"By " + " " + item.title + " " + item.first_name + " " + item.last_name}</p>
                                                                                <p> <><Tag>{getStatus(item.opt2)}</Tag>

                                                                                    <span>{"->"}</span>
                                                                                    <Tag>{getStatus(item.opt3)}</Tag>

                                                                                </>
                                                                                </p>
                                                                                <p style={{ marginTop: -8 }}> on  {formatISODate(item.created)}</p>



                                                                            </p>
                                                                        }


                                                                    />


                                                                </>


                                                                : item.activity_type === 8 ?
                                                                    <>
                                                                        <List.Item.Meta
                                                                            avatar={<Avatar
                                                                                style={{
                                                                                    backgroundColor: NewColorTheme.primaryColor,
                                                                                }}
                                                                                icon={<CloudUploadOutlined />}
                                                                            />}
                                                                            title={<Typography strong={true} style={{ fontWeight: 700 }}>New File Attachment </Typography>}
                                                                            description={
                                                                                <div>
                                                                                    Document uploaded to {item.desc1.toLowerCase()}
                                                                                    <div>on {formatISODate(item.created)}</div>
                                                                                    <div>By {item.title + " " + item.first_name + " " + item.last_name}</div>

                                                                                </div>


                                                                            }
                                                                        />



                                                                    </> : item.activity_type === 9 ?
                                                                        <>
                                                                            <List.Item.Meta
                                                                                avatar={<Avatar
                                                                                    style={{
                                                                                        backgroundColor: NewColorTheme.primaryColor,
                                                                                    }}
                                                                                    icon={<UserAddOutlined />}
                                                                                />}
                                                                                title={<Typography strong={true} style={{ fontWeight: 700 }}>New user assign </Typography>}
                                                                                description={
                                                                                    <div>
                                                                                        {item.participant + " is assigned to action"}
                                                                                        <div>on {formatISODate(item.created)}</div>
                                                                                        <div>By {item.title + " " + item.first_name + " " + item.last_name}</div>

                                                                                    </div>


                                                                                }
                                                                            />


                                                                        </> : item.activity_type === 10 ?
                                                                            <>
                                                                                <List.Item.Meta
                                                                                    avatar={<Avatar
                                                                                        style={{
                                                                                            backgroundColor: NewColorTheme.primaryColor,
                                                                                        }}
                                                                                        icon={<UserDeleteOutlined />}
                                                                                    />}
                                                                                    title={<Typography strong={true} style={{ fontWeight: 700 }}>User unassigned</Typography>}
                                                                                    description={
                                                                                        <div>
                                                                                            {item.participant + " is unassigned from the action "}
                                                                                            <div>on {formatISODate(item.created)}</div>
                                                                                            <div>By {item.title + " " + item.first_name + " " + item.last_name} </div>
                                                                                        </div>


                                                                                    }
                                                                                />


                                                                            </> : item.activity_type === 5 ?
                                                                                <>
                                                                                    <List.Item.Meta
                                                                                        avatar={<Avatar
                                                                                            style={{
                                                                                                backgroundColor: NewColorTheme.primaryColor,
                                                                                            }}
                                                                                            icon={<InteractionOutlined />}
                                                                                        />}
                                                                                        title={<Typography strong={true} style={{ fontWeight: 700 }}>Status Change Approval</Typography>}
                                                                                        description={
                                                                                            <div>
                                                                                                {"Status change aprroval for" + item.opt1 + " -> " + item.op2}
                                                                                                <div>on {formatISODate(item.created)}</div>
                                                                                                <div>By {item.title + " " + item.first_name + " " + item.last_name} </div>

                                                                                            </div>


                                                                                        }
                                                                                    />


                                                                                </> : item.activity_type === 6 ?
                                                                                    <>
                                                                                        <List.Item.Meta
                                                                                            avatar={<Avatar
                                                                                                style={{
                                                                                                    backgroundColor: NewColorTheme.primaryColor,
                                                                                                }}
                                                                                                icon={<InteractionOutlined />}
                                                                                            />}
                                                                                            title={<Typography strong={true} style={{ fontWeight: 700 }}>Status Change Rejection</Typography>}
                                                                                            description={
                                                                                                <div>
                                                                                                    {"Status change rejection for" + item.opt1 + " -> " + item.opt2}

                                                                                                    <div>on {formatISODate(item.created)}</div>
                                                                                                    <div>By{item.title + " " + item.first_name + " " + item.last_name} </div>

                                                                                                </div>


                                                                                            }
                                                                                        />

                                                                                    </> : item.activity_type === 7 ?
                                                                                        <>
                                                                                            <List.Item.Meta
                                                                                                avatar={<Avatar
                                                                                                    style={{
                                                                                                        backgroundColor: NewColorTheme.primaryColor,
                                                                                                    }}
                                                                                                    icon={<CalendarOutlined />}
                                                                                                />}
                                                                                                title={<Typography strong={true} style={{ fontWeight: 700 }}>Extended of Measure </Typography>}
                                                                                                description={
                                                                                                    <div>
                                                                                                        {"Measure Extended from" + item.desc1 + " -> " + item.desc2}

                                                                                                        <div>on {formatISODate(item.created)}</div>
                                                                                                        <div>by {item.title + " " + item.first_name + " " + item.last_name}</div>
                                                                                                    </div>


                                                                                                }
                                                                                            />



                                                                                        </> : item.activity_type === 11 ?
                                                                                            <>
                                                                                                <List.Item.Meta
                                                                                                    avatar={<Avatar
                                                                                                        style={{
                                                                                                            backgroundColor: NewColorTheme.primaryColor,
                                                                                                        }}
                                                                                                        icon={<CheckCircleOutlined />}
                                                                                                    />}
                                                                                                    title={<Typography strong={true} style={{ fontWeight: 700 }} Completion of Action></Typography>}
                                                                                                    description={
                                                                                                        <div>
                                                                                                            {"Action Completion " + item.opt1 + " -> " + item.opt2}

                                                                                                            <div>on {formatISODate(item.created)}</div>
                                                                                                            <div>By {item.title + " " + item.first_name + " " + item.last_name} </div>

                                                                                                        </div>


                                                                                                    }
                                                                                                />



                                                                                            </> : item.activity_type === 12 ?
                                                                                                <>
                                                                                                    <List.Item.Meta
                                                                                                        avatar={<Avatar
                                                                                                            style={{
                                                                                                                backgroundColor: NewColorTheme.primaryColor,
                                                                                                            }}
                                                                                                            icon={<CalendarOutlined />}
                                                                                                        />}
                                                                                                        title={<Typography strong={true} style={{ fontWeight: 700 }}> Completion of Measure </Typography>}
                                                                                                        description={
                                                                                                            <div>
                                                                                                                {"Measure Completion " + item.opt1}

                                                                                                                <div>on {formatISODate(item.created)}</div>
                                                                                                                <div>By {item.title + " " + item.first_name + " " + item.last_name}</div>

                                                                                                            </div>


                                                                                                        }
                                                                                                    />


                                                                                                </> : item.activity_type === 13 ?
                                                                                                    <>
                                                                                                        <List.Item.Meta
                                                                                                            avatar={<Avatar
                                                                                                                style={{
                                                                                                                    backgroundColor: NewColorTheme.primaryColor,
                                                                                                                }}
                                                                                                                icon={<SubnodeOutlined />}
                                                                                                            />}
                                                                                                            title={<Typography strong={true} style={{ fontWeight: 700 }}>Sub Action Creation </Typography>}
                                                                                                            description={
                                                                                                                <div>
                                                                                                                    {`New sub action created`}

                                                                                                                    <div>on {formatISODate(item.created)}</div>
                                                                                                                    <div>By {item.title + " " + item.first_name + " " + item.last_name}</div>

                                                                                                                </div>


                                                                                                            }
                                                                                                        />


                                                                                                    </> : item.activity_type === 14 ?
                                                                                                        <>
                                                                                                            <List.Item.Meta
                                                                                                                avatar={<Avatar
                                                                                                                    style={{
                                                                                                                        backgroundColor: NewColorTheme.primaryColor,
                                                                                                                    }}
                                                                                                                    icon={<CalendarOutlined />}
                                                                                                                />}
                                                                                                                title={<Typography strong={true} style={{ fontWeight: 700 }}>Sub Action Creation </Typography>}
                                                                                                                description={
                                                                                                                    <div>
                                                                                                                        {`New action created`}

                                                                                                                        <div>on {formatISODate(item.created)}</div>
                                                                                                                        <div>By {item.title + " " + item.first_name + " " + item.last_name}</div>

                                                                                                                    </div>


                                                                                                                }
                                                                                                            />


                                                                                                        </> : null
                                                        }


                                                    </List.Item>
                                                )}
                                            </VirtualList> */}
                                        </List>
                                        {/* :
                                            <LoadingOutlined style={{ fontSize: "16px", color: "purple" }} />\ */}




                                    </div>
                                </div>
                            }

                        />
                    </div>
                    }



                </div>

            </div>


            <Modal
                // title="Vertically centered modal dialog"
                centered
                open={addParticipantsDialog}
                onOk={() => SetAddParticipants(false)}
                onCancel={() => SetAddParticipants(false)}
                footer={false}
                width={600}
                style={{ height: "70vh" }}
                className="custom-modal"
                title={
                    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                        <div>     <Typography className='custom-modal-title' level={2}>Add Members</Typography></div>


                    </div>


                }

            >

                <div style={{ width: "100%", padding: 20 }}>


                    <Form form={form3} onFinish={(values) => onSearch(values.search)}>
                        <Form.Item name="search">
                            <Search placeholder="input search text" onSearch={onSearch} enterButton />
                        </Form.Item>
                    </Form>


                    <div className='custom-scroll-div' style={{ height: "50vh", overflow: "auto", paddingRight: 10 }} >

                        <List
                            dataSource={searhcUsers.filter((element) => element.matched != true)}
                            renderItem={(item, k) => (
                                <Tooltip title={"Add " + item.title + " " + item.first_name + " " + item.last_name} color={"#108ee9"} key={k}>
                                    <List.Item key={item.email} >

                                        <List.Item.Meta
                                            avatar={<Avatar
                                                style={{
                                                    backgroundColor: NewColorTheme.primaryColor,
                                                }}
                                                icon={<UserOutlined />}
                                            />}
                                            title={item.title + " " + item.first_name + " " + item.last_name}
                                            description={item.designation}
                                        />
                                        <Button shape="square" onClick={() => {

                                            if (item.matched) {
                                                removeParticipant(item)
                                            } else {
                                                addNewParticipant(item)
                                            }


                                        }} icon={item.matched ? <MinusOutlined /> : <PlusOutlined />}

                                        />

                                    </List.Item>
                                </Tooltip>
                            )}
                        >

                        </List>

                    </div>
                    {/* {!loadingMembers ?
                                <Row>
                                    {
                                        otherActionParticipants.map((c, k) => (
                                            <Tooltip title={"Add " + c.title + " " + c.first_name + " " + c.last_name} color={"#108ee9"} key={k}>
                                                <div onClick={() => addNewParticipant(c)} style={{ padding: "5px 10px", borderRadius: 25, border: '1px solid #e9e7e7', background: '#fafafa', width: 'fit-content', margin: "2px 3px", cursor: "pointer" }}>
                                                    <Space key={k}>
                                                        {/* <Avatar size='small'>{c}</Avatar>
                                                        <Text>{c.title + " " + c.first_name + " " + c.last_name + " | " + c.designation}</Text>
                                                    </Space>
                                                </div>
                                            </Tooltip>
                                        ))
                                    }
                                </Row> :
                                <div>
                                    <LoadingOutlined />
                                </div>
                            } */}


                </div>

            </Modal>
            <Modal
                open={isModalVisible}
                onCancel={handleCancel}
                onOk={handleCancel}
            >
                {fileList.map((file, index) => (
                    <p key={index}>{file.name}</p>
                ))}
            </Modal>
            <Modal
                // title="Vertically centered modal dialog"
                // closable
                centered
                open={uplaodedFilesDialog}
                onOk={() => setUploadDialog(false)}
                onCancel={() => { setUploadDialog(false); setTitle("") }}
                footer={false}
                width={800}

                maskClosable={fileLoading ? false : true}
                className="custom-modal"
                title={
                    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                        <div>     <Typography className='custom-modal-title' level={2}>Add Attachments</Typography></div>


                    </div>


                }
            >
                <div style={{ padding: "20px" }}>
                    <Row gutter={[16, 16]} >

                        <Col span={24} style={{ marginTop: -15 }}>

                            <Typography>Please enter a title before uploading the file.</Typography>


                            <Input disabled={fileTitleDisable} ref={fileTitleRef} value={fileTitle}
                                onChange={(e) => {

                                    const inputValue = e.target.value;


                                    if (inputValue.length === 0 || inputValue.startsWith(" ")) {
                                        setFileTitleError(true)
                                        // Display an error message or handle the error condition
                                        console.log('Error: File title cannot be empty.');
                                    } else {
                                        setFileTitleError(false)

                                    }
                                    setTitle(inputValue);
                                }
                                } placeholder='Enter a file title' />
                            {FileTitleError && <span style={{ fontSize: 12, color: "red" }}>Invalid File Title</span>
                            }


                        </Col>

                        <Col span={24} style={{ marginTop: 0 }} >
                            {/* <Dragger {...props}

                            >
                                <p className="ant-upload-drag-icon">
                                    <InboxOutlined />
                                </p>
                                <p className="ant-upload-text">Click or drag a file to this area to upload</p>
                                <p className="ant-upload-hint">
                                    Support for a single or bulk upload. Strictly prohibited from uploading company data or other
                                    banned files.
                                </p>
                            </Dragger> */}
                            <Alert style={{ marginBottom: "20px" }} type='info' message=
                                {"Please ensure that only one attachment is uploaded at a time, and the maximum file size should be 5MB"}
                            >

                            </Alert>
                            <Dragger
                                maxCount={1}
                                id='file dragger'
                                fileList={fileList}
                                {...props}
                                showUploadList={{ showProgress: true }}
                                disabled={fileTitle ? false : true}
                                beforeUpload={file => {

                                    const MAX_SIZE = 5 * 1024 * 1024; // 5 MB
                                    if (file.size > MAX_SIZE) {

                                        toast.error('File size exceeds limit! The maximum file upload size is 5MB.', {
                                            position: "top-right",
                                            autoClose: 5000,
                                            hideProgressBar: false,
                                            closeOnClick: true,
                                            pauseOnHover: true,
                                            draggable: true,
                                            progress: undefined,
                                            theme: "colored",
                                        });
                                        return false;
                                    } else {
                                        setFileTitleDisable(true)
                                        handleFileUpload(file);
                                    }


                                    return false; // to prevent automatic upload
                                }}
                                ref={uploadRef}

                            >
                                <p className="ant-upload-drag-icon">
                                    <InboxOutlined tyle={{ color: "#33363F" }} />
                                </p>
                                <p className="ant-upload-text">Click or drag a file to this area to upload</p>

                            </Dragger>
                            {fileLoading && (
                                <Typography.Text style={{ color: "green", fontWeight: 700 }}>Please Wait..Your File is uploding</Typography.Text>
                            )}

                        </Col>
                    </Row>
                </div>
            </Modal>

            <Modal centered title="Confirmation" open={fileDeleteConfirmation} onOk={() => removeFiles(curentFile)} onCancel={() => { setCurrentFile(null); setFileDelete(false) }}>
                <p>Are you sure you want to delete this file?</p>

            </Modal>




        </div>
    );
};

export default CustomeModal;
