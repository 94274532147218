import React, { useMemo } from "react";
import "./dash5.css";
import {
  Button,
  Card,
  Col,
  Divider,
  Drawer,
  Row,
  Space,
  Tooltip,
  Typography,
} from "antd";
import ActionStatusChangeRequests from "./Components/ActionStatusChangeRequests";
import UserRequests5 from "./Components/UserRequests5";
import DonorManagement from "./Components/DonorManagement";
import InquiryManagement5 from "./Components/InquiryManagement5";
import NewUserData from "./Components/NewUserData";
import ActionCardContent from "../NewDashbaord4/Components/ActionCard/ActionCardContent";
import UpcomingEvents5 from "./Components/UpcomingEvents5";
import DBMainCard from "../NewDashbaord4/Components/DBMainCard";
import NewConfiguration from "../NewDashbaord4/Components/Configuration/NewConfiguration";
import UpcomingTasks from "./Components/UpcomingTasks";
import { BarChartOutlined } from "@ant-design/icons";
import Chat from "../../../Components/Chat";
import ArticleCount from "./Components/ArticleCount";
import AgencyCount from "./Components/AgencyCount";
import DonorContributionCount from "./Components/DonorContributionCount";
import DonorPublishedInquiries from "./Components/DonorPublishedInquiries";
import DonorRequestsCount from "./Components/DonorRequestsCount";
import DonorInterestsCount from "./Components/DonorInterestsCount";
import DonorProfileCount from "./Components/DonorProfileCount";
import DonorProfileDetailsForDB from "./Components/DonorProfileDetailsForDB";
import MyDonorContributions from "./Components/MyDonorContributions";
import PageHeader from "../../Inquiries/PageHeader";
import { useState } from "react";
import { useEffect } from "react";
import {
  MergeCellsOutlined,
  NumberOutlined,
  SendOutlined,
  MacCommandOutlined,
  OneToOneOutlined,
  HomeOutlined,
  UserOutlined,
  WalletOutlined,
  BorderlessTableOutlined,
  LogoutOutlined,
  DeploymentUnitOutlined,
  CalendarOutlined,
  MenuOutlined
} from "@ant-design/icons";
import { getUserData } from "../../Donors/getCounts";
import { NewColorTheme } from "../../../appConst";
import Notifications from "../../../Components/Notifications";
import MainCards from "../NewDashbaord4/Components/ActionCard/UpcominDefinitiveDates";

function Newdashboard5() {
  const [open, setOpenDrawer] = useState(false);
  const [user, setUser] = useState({});
  const [drawerList, setList] = useState();
  const openDrawer = () => {
    console.log("drawer callling");
    setOpenDrawer(true);
  };

  const onClose = () => {
    setOpenDrawer(false);
  };

  const DrawerTile = ({ title, icon, url, Key }) => {
    console.log("-------", url);
    return (
      <div
        key={Key}
        className="my-drawer-content-list-item"
        onClick={() => {
          if (url == "/login") {
            window.localStorage.removeItem("ntfc_token");
          }
          window.location.href = url;
        }}
      >
        <div style={{ display: "flex", gap: 10 }}>
          {icon}
          <Typography className="tile-title">{title}</Typography>
        </div>
        <Divider style={{ margin: 0 }} />
      </div>
    );
  };

  useMemo(() => {
    if (window.localStorage.getItem('ntfc_level') == 0) {
      window.location.href = "/presidentdashbaord";
    }
  }, []);

  useEffect(() => {
    let DefaultdrawerList = [];
    DefaultdrawerList = [
      {
        title: "Inquiry Management",
        url: "/inquiries",
        icon: <BorderlessTableOutlined className="drawer-item-icon" />,
        priority: 990,
      },

      {
        title: "Logout",
        url: "/login",
        icon: <LogoutOutlined className="drawer-item-icon" />,
        priority: 999,
      },
    ];
    console.log(
      "Upcoming def date-------------------->>>>",
      window.localStorage.getItem("ntfc_level")
    );
    if (window.localStorage.getItem("ntfc_level") != 3) {
      if (
        window.localStorage.getItem("ntfc_level") != 4 &&
        window.localStorage.getItem("ntfc_level") != -1
      ) {
        DefaultdrawerList.push(
          {
            title: "Status Change Requests",
            icon: <MergeCellsOutlined className="drawer-item-icon" />,
            priority: 1,
            url: "/requests",
          },
          {
            title: "User Requests",
            url: "/userrequestshod",
            icon: <UserOutlined className="drawer-item-icon" />,
            priority: 2,
          }
        );
      }

      if (window.localStorage.getItem("ntfc_level") != -1) {
        DefaultdrawerList.push({
          title: "Donor Management",
          icon: <WalletOutlined className="drawer-item-icon" />,
          priority: 3,
          url: "/donormanagement",
        });
      }
    } else if (window.localStorage.getItem("ntfc_level") == 3) {
      DefaultdrawerList.push(
        {
          title: "Donor Requests",
          icon: <SendOutlined className="drawer-item-icon" />,
          priority: 4,
          url: "/donormanagement",
        },
        {
          title: "Donor Interests",
          url: "/donormanagement",
          icon: <SendOutlined className="drawer-item-icon" />,
          priority: 5,
        },
        {
          title: "Donor Contribution",
          url: "/donormanagement",
          icon: <SendOutlined className="drawer-item-icon" />,
          priority: 6,
        },

        {
          title: "Donor Profile",
          url: "/donormanagement",
          icon: <SendOutlined className="drawer-item-icon" />,
          priority: 7,
        }
      );
    }
    let sortedArray = DefaultdrawerList.sort((a, b) => a.priority - b.priority);
    setList(sortedArray);
  }, []);

  return (
    <div className="dash5-main-div" style={{ backgroundColor: NewColorTheme.primaryColor }}>
      <Row gutter={[8, 4]}>
        <Col xs={24} className="mob-hide">
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              width: "100%",
              alignItems: "center",
            }}
            className="db-nav-bar"
          >
            <Space size={"large"} style={{ width: "100%" }}>
              <img
                src="/logo.png"
                alt="ntfc logo"
                className="dashboard-five-logo"
              />
              <Typography className="new-dash-five-title">
                <span>National Trade</span>
                <span>Facilitation Committee</span>
              </Typography>
            </Space>
            <div
              style={{
                display: "flex",
                height: "100%",
                alignItems: "center",
                paddingRight: 30,
                gap: 10,
              }}
            >
              {((window.localStorage.getItem("ntfc_level") != 3) && (window.localStorage.getItem("ntfc_level") != 2) && (window.localStorage.getItem("ntfc_level") != -1)) &&
                <Tooltip title="Action Plan">
                  <Button
                    className="enlarged-button"
                    onClick={() => window.location.href = "/tasksactionplan"}
                    size="small"
                    // shape="circle"
                    icon={
                      <DeploymentUnitOutlined style={{ color: NewColorTheme.primaryColor }} />
                    }
                  />
                </Tooltip>
              }

              <Tooltip title="Summary">
                <Button
                  className="enlarged-button"
                  onClick={() => {
                    window.location.href = "/summary";
                  }}
                  size="small"
                  // shape="circle"
                  icon={
                    <BarChartOutlined style={{ color: NewColorTheme.primaryColor }} />
                  }
                />
              </Tooltip>

              {(window.localStorage.getItem("ntfc_level") != -1) && (

                <Chat />

              )}
              {((window.localStorage.getItem("ntfc_level") != -1)) &&
                <Tooltip title="Event Calendar">
                  <Button
                    className="enlarged-button"
                    onClick={() => {
                      window.location.href = "/newcalender";
                    }}
                    size="small"
                    // shape="circle"
                    icon={

                      <CalendarOutlined style={{ color: NewColorTheme.primaryColor }} />
                    }
                  />
                </Tooltip>
              }

              {((window.localStorage.getItem("ntfc_level") != 2) && (window.localStorage.getItem("ntfc_level") != -1)) &&
                <Notifications />
              }

            </div>
            <NewUserData />
          </div>
        </Col>
        <Col xs={24}>
          <div className="mobile-view-header">
            <div style={{ display: "flex", alignContent: 'center' }}>
              <Typography className="mobile-title">
                National Trade Facilitation Committee
              </Typography>
            </div>
            <div style={{ display: "flex", alignItems: "center", height: "100%" }}>
              <MenuOutlined
                style={{
                  fontSize: "25px",
                  color: "white",
                }}
                onClick={openDrawer}
              />
            </div>
          </div>
        </Col>
        <Col xs={24} >
          <Row gutter={[24, 8]}>
            <Col xs={24} md={6}  >
              <Row gutter={[16, 8]} >
                {window.localStorage.getItem("ntfc_level") != 4 &&
                  window.localStorage.getItem("ntfc_level") != -1 &&
                  window.localStorage.getItem("ntfc_level") != 3 &&
                  window.localStorage.getItem("ntfc_level") != 2 && (
                    <Col xs={24} lg={24} className="mob-hide" >
                      <ActionStatusChangeRequests />
                    </Col>
                  )}
                {window.localStorage.getItem("ntfc_level") != 4 &&
                  window.localStorage.getItem("ntfc_level") != -1 &&
                  window.localStorage.getItem("ntfc_level") != 3 &&
                  window.localStorage.getItem("ntfc_level") != 2 && (
                    <Col xs={24} lg={24} className="mob-hide "  >
                      <UserRequests5 />
                    </Col>
                  )}

                {window.localStorage.getItem("ntfc_level") != -1 &&
                  window.localStorage.getItem("ntfc_level") != 3 &&
                  window.localStorage.getItem("ntfc_level") != 2 && (
                    <Col xs={24} className="mob-hide"  >
                      <DonorManagement />
                    </Col>
                  )}

                {window.localStorage.getItem("ntfc_level") == 3 && <>

                  <Col xs={24} className="mob-hide">
                    <DonorRequestsCount />
                  </Col>
                  <Col xs={24} className=" mob-hide" >
                    <DonorInterestsCount />
                  </Col>
                  <Col xs={24} className=" mob-hide">
                    <DonorContributionCount />
                  </Col>
                  <Col xs={24} className=" mob-hide">
                    <DonorProfileCount />
                  </Col>
                </>}
                <Col xs={24} className="mob-hide">
                  <InquiryManagement5 />
                </Col>



              </Row>
            </Col>
            <Col xs={24} md={18}>
              <Row gutter={[4, 4]}>
                <div className="right-main-div">
                  {window.localStorage.getItem("ntfc_level") == 3 ? (
                    <Row gutter={[16, 16]} style={{ minWidth: "100%" }}>
                      <Col xs={24}>
                        <DonorProfileDetailsForDB />
                      </Col>

                      <Col xs={24}>
                        <MyDonorContributions />
                      </Col>
                    </Row>
                  ) : (
                    <Row gutter={[8, 8]}>
                      <Col xs={24} lg={24}>
                        <ActionCardContent />
                      </Col>

                      <Col lg={(window.localStorage.getItem('ntfc_level') == -1 || window.localStorage.getItem('ntfc_level') == 2) ? 24 : 15} xs={24}>

                        <Card className='dash5-card' style={{ border: `2px solid ${NewColorTheme.primmaryLightColor}` }}>
                          <div className='new-action-card-bottom-new'>
                            <div
                              className='action-card-five-new'
                            >
                              <Typography className='ac-new-inner-card-title' style={{ color: NewColorTheme.primaryDarkColor }}>Action Plan - Upcoming Definitive Dates</Typography>
                            </div>
                            <div>
                              <MainCards />
                            </div>

                          </div>
                        </Card>

                      </Col>


                      <Col xs={24} lg={9}>
                        <Row >
                          {/* <Col xs={24}>
                          <Card
                            className="dash5-card"
                            style={{ border: `2px solid ${NewColorTheme.primmaryLightColor}` }}
                            onClick={() => {
                              window.location.href = "/newCalender";
                            }}
                          >
                            <div
                              className={
                                window.localStorage.getItem("ntfc_level") ==
                                  5 ||
                                  window.localStorage.getItem("ntfc_level") == 4
                                  ? "upcoming-meeting-div-five-hod"
                                  : "upcoming-meeting-div-five"
                              }
                            >
                              <div className="upcoming-meeting-div-title">
                                <Typography className="ac-new-inner-card-title"
                                  style={{ color: NewColorTheme.primaryDarkColor }}
                                >
                                  Upcoming Meetings/ Events
                                </Typography>
                                <UpcomingEvents5 />
                              </div>
                            </div>
                          </Card>
                        </Col> */}
                          {(window.localStorage.getItem("ntfc_level") == 5 ||
                            window.localStorage.getItem("ntfc_level") == 4) && (
                              <Col xs={24}>
                                <Card className="dash5-card" style={{ border: `2px solid ${NewColorTheme.primmaryLightColor}` }}>
                                  <div className="upcoming-meeting-div-five-tasks">
                                    <div className="upcoming-meeting-div-title">
                                      <Typography className="ac-new-inner-card-title"
                                        style={{ color: NewColorTheme.primaryDarkColor }}
                                      >
                                        Upcoming Tasks
                                      </Typography>
                                      <UpcomingTasks />
                                    </div>
                                  </div>
                                </Card>
                              </Col>
                            )}

                          {(window.localStorage.getItem("ntfc_level") == 6 ||
                            window.localStorage.getItem("ntfc_level") == 7) && (
                              <Col
                                xs={24}
                                className="configuration-card-five mob-hide"
                              >
                                <DBMainCard
                                  style={{
                                    padding: "30px 20px",
                                    "@media (min-width: 1200px) and (max-width: 1399px) ":
                                    {
                                      padding: 5,
                                    },
                                  }}
                                  title={localStorage.getItem('ntfc_level') == 6 ? "Master Data" : "Configurations"}
                                  backgroundColor="#1677ff"
                                  titleColor="white"
                                  cardBackground="#f5f9ff"
                                  content={<NewConfiguration />}
                                />
                              </Col>
                            )}
                        </Row>
                      </Col>
                    </Row>
                  )}
                </div>

              </Row>
            </Col>

          </Row>

        </Col>

      </Row>
      <Drawer
        title="Dashbooard"
        placement={"left"}
        closable={false}
        onClose={onClose}
        open={open}
        key={"drawer"}
        className="my-drawer"
      >
        <div className="my-drawer-content">
          {drawerList &&
            drawerList
              ?.sort((a, b) => a.priority - b.priority)
              .map((element, index) => {
                return (
                  <DrawerTile
                    Key={index}
                    title={element.title}
                    icon={element.icon}
                    url={element.url}
                  />
                );
              })}
        </div>
      </Drawer>
    </div>
  );
}

export default Newdashboard5;
