import { Card, Col, Row, Typography } from 'antd'
import React, { useState } from 'react'
import { EyeOutlined, PlusSquareOutlined } from "@ant-design/icons";
import './measureImplementationSummary.css'
import { useEffect } from 'react';
import { getActionCardData } from '../../../../../api';
import CountUp from 'react-countup';

function MeasureImplementationSummaryCard({ user_level, agency }) {


    useEffect(() => {
        setShouldAnimate(true);
        if (agency) {
            loadData()
        }
    }, [agency])


    const [cardData, setCardData] = useState(null)
    const [shouldAnimate, setShouldAnimate] = useState(false);


    useEffect(() => {
        setShouldAnimate(true);
        // if (agency) {
        loadData()
        // }
    }, [])

    const loadData = async () => {

        getActionCardData(0).then((out) => {

            setCardData(out.data.out)
            console.log("THIS DATA", out.data.out)
        });

    }


    return (
        <Card style={{ cursor: "pointer" }} className='action-card' onClick={() => {
            window.location.href = "/requests"
        }}>
            <Row >

                <Col xs={24} style={{ display: "flex", justifyContent: "start" }}>
                    <div className='measure-right-side-div'>
                        <div className='measure-title-div-new'>
                            <Typography className='meaure-imp-title' style={{ width: "90%", textAlign: "center" }}>Country Wise Measure Implementation Summary</Typography>
                            <div style={{ display: "flex", justifyContent: "end", gap: 5, right: "10px", position: "absolute" }}>
                                <EyeOutlined style={{ fontSize: 20, color: "white" }} />
                            </div>
                        </div>
                        <div style={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                            <Row gutter={[8, 8]} justify={"space-around"} style={{ padding: 10 }}>
                                <Col span={12}>
                                    <div style={{ border: `0.5px solid #AED8E6` }}
                                        className='measure-tile-new'
                                    >
                                        <span style={{ color: `#AED8E6` }} className='card-count-new' > {shouldAnimate && <CountUp end={cardData ? cardData.todo : 0} duration={2} />}</span>
                                        <span style={{ color: `#AED8E6` }} className='card-count-content' >{"To-Do"}</span>
                                    </div>

                                </Col>
                                <Col span={12}>

                                    <div style={{ border: `0.5px solid #0492C2` }}
                                        className='measure-tile-new'
                                    >
                                        <span style={{ color: `#0492C2` }} className='card-count-new' > {shouldAnimate && <CountUp end={cardData ? cardData.inprogress : 0} duration={2} />}</span>
                                        <span style={{ color: `#0492C2` }} className='card-count-content' >{"In Progress"}</span>
                                    </div>
                                </Col>
                                <Col span={12}>

                                    <div style={{ border: `0.5px solid #2f69b3` }}
                                        className='measure-tile-new'
                                    >
                                        <span style={{ color: `#2f69b3` }} className='card-count-new' > {shouldAnimate && <CountUp end={cardData ? cardData.delayed : 0} duration={2} />}</span>
                                        <span style={{ color: `#2f69b3` }} className='card-count-content' >{"Delayed"}</span>
                                    </div>
                                </Col>
                                <Col span={12}>

                                    <div style={{ border: `0.5px solid #4169E1` }}
                                        className='measure-tile-new'
                                    >
                                        <span style={{ color: `#4169E1` }} className='card-count-new' > {shouldAnimate && <CountUp end={cardData ? cardData.missed : 0} duration={2} />}</span>
                                        <span style={{ color: `#4169E1` }} className='card-count-content' >{"Missed"}</span>
                                    </div>
                                </Col>
                                <Col span={24}>

                                    <div style={{ border: `0.5px solid #1520A6` }}
                                        className='measure-tile-new'
                                    >
                                        <span style={{ color: `#1520A6` }} className='card-count-new' > {shouldAnimate && <CountUp end={cardData ? cardData.complete : 0} duration={2} />}</span>
                                        <span style={{ color: `#1520A6` }} className='card-count-content' >{"Completed"}</span>
                                    </div>
                                </Col>

                            </Row>
                        </div>


                    </div>


                </Col>

            </Row>

        </Card>
    )
}

export default MeasureImplementationSummaryCard