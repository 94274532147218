import { Typography } from 'antd'
import React from 'react'
import './TitleBox.styles.css'
import { NewColorTheme } from '../../appConst'

function TitleBox({ comment, history, title, body, titleBoxColor = NewColorTheme.primaryColor }) {


  return (
    <div style={{ padding: 10, }}>
      <div className="title-container"  >
        <div className="title-div" style={{ backgroundColor: titleBoxColor }}>
          <Typography className="section-title"> {title.toUpperCase()}</Typography>
        </div>
        <div className="line-div">
          <p className='title-line' style={{ borderBottom: `2px solid ${titleBoxColor}` }} ></p>
        </div>


      </div>
      <div className={history ? "body-container-history" : comment ? "body-container-comment" : "body-container"}>
        {body}

      </div>


    </div>
  )
}

export default TitleBox