import { Card, Typography } from 'antd'
import React, { useEffect, useState } from 'react'
import './InquiryCard.css'
import CountUp from 'react-countup';
import { getInquiryCardData } from '../../../../../api';
import { EyeOutlined } from "@ant-design/icons";


function InquiryCard({ user_level }) {

    const [cardData, setCardData] = useState(null)
    const [shouldAnimate, setShouldAnimate] = useState(false);

    useEffect(() => {
        setShouldAnimate(true);
        loadData()

    }, [])

    const loadData = async () => {

        let agency
        let userLevel = await window.localStorage.getItem('ntfc_level')
        if (userLevel == 6 || userLevel == 7) {
            agency = 0
        } else {
            agency = window.localStorage.getItem('ntfc_agency')
        }

        getInquiryCardData(agency).then((out) => {

            setCardData(out.data.out)

        });

    }



    return (
        <Card style={{ cursor: "pointer" }} className={user_level && user_level == 3 ? 'inquiry-card2' : 'inquiry-card'} onClick={() => {
            window.location.href = "/inquiries"
        }} >
            <div style={{ height: 322 }}>
                <div className='tfa-plan-div'>

                    <Typography className='tfa-title'>
                        Inquiry Management
                    </Typography>
                    <div style={{ display: "flex", justifyContent: "end", gap: 5, right: 0 }}>
                        <EyeOutlined style={{ fontSize: 25, color: "white" }} />
                    </div>

                </div>
                <div className={user_level && user_level == 3 ? "inner_inq2" : "inner_inq"}>


                    <div className='inquiry-divs'>
                        <Typography className='inquiry-count'>
                            {shouldAnimate && <CountUp end={cardData && cardData.answered ? cardData.answered : 0} duration={2} />}

                        </Typography>

                        <div
                            className='inquiry-tile-new'
                        >
                            <span className='inquiry-count-new' style={{ fontSize: 33 }}>Answered</span>
                            <span className='inquiry-count-content' >Inquiries</span>
                        </div>

                    </div>
                    {/* <div style={{ display: "flex", justifyContent: "end", gap: 5, position: "absolute", right: "0", marginRight: "3%", top: "5px" }}>
                        <EyeOutlined style={{ fontSize: 25, color: "white" }} />
                    </div> */}
                    <div className='inquiry-divs'>
                        <Typography className='inquiry-count'>
                            {shouldAnimate && <CountUp end={cardData && cardData.pending ? cardData.pending : 0} duration={2} />}
                        </Typography>

                        <div
                            className='inquiry-tile-new'
                        >
                            <span className='inquiry-count-new' >Pending</span>
                            <span className='inquiry-count-content' >Answers</span>
                        </div>

                    </div>
                </div>


                {/* <div className={user_level && user_level == 3 ? 'inquiry-bottom-div2' : 'inquiry-bottom-div'}>


                    {/* <Typography className="inqu-botton-title">New Inquiry</Typography>
                    <img src="/inq.png" alt={"inquries"} className='inq-logo'></img>



                </div> */}
            </div>





        </Card>
    )
}

export default InquiryCard