import React from 'react';
import ReactECharts from 'echarts-for-react';
import { useEffect } from 'react';
var chartDom = document.getElementById('main');



const ArticlePopOverGraph = ({ data }) => {



    console.log("data -----?/////", data)
    let option = {
        // title: {
        //     text: data.article_summary,
        //     // subtext: 'Living Expenses in Shenzhen'
        // },
        xAxis: {
            type: 'category',
            data: data.measures.map(measure => `${data.article_no}.${measure.measure_no}`).sort((a, b) => parseFloat(a) - parseFloat(b)),
            axisTick: {
                show: false // Remove x-axis ticks
            }
        },
        yAxis: {
            type: 'value',
            min: 0,           // Set the minimum value of the y-axis to 0
            max: 100,         // Set the maximum value of the y-axis to 100
            interval: 10
        },
        series: [
            {
                data: data.measures.map(measure => measure.measure_completion),
                type: 'bar',
                showBackground: true,
                backgroundStyle: {
                    color: 'rgba(180, 180, 180, 0.2)'
                }
            }
        ]
    };


    return <ReactECharts style={{ width: 350, height: "50vh" }} option={option} />

};

export default ArticlePopOverGraph;