import React, { useEffect, useRef, useState } from 'react';
import ReactECharts from 'echarts-for-react';
import { GetCompletionGraphData } from '../../../api';
import { Tooltip } from 'antd';
const MeasureCompletionBar = ({ val }) => {

    const [measures, setMeasures] = useState([]);
    const [measureNos, setMeasureNos] = useState([]);
    const [completion, setCompletion] = useState([]);
    const [inprogress, setInprogress] = useState([]);
    const [other, setOther] = useState([]);
    const [tooltipPosition, setTooltipPosition] = useState({ x: 0, y: 0 });

    useEffect(() => {

        // if (window.localStorage.getItem("ntfc_level") == 7 || window.localStorage.getItem("ntfc_level") == 6 || window.localStorage.getItem("ntfc_level") == 3 || window.localStorage.getItem("ntfc_level") == 2 || window.localStorage.getItem("ntfc_level") == -1) {

        //     GetCompletionGraphData(0).then((out) => {
        //         if (out.data && out.data.out) {
        //             console.log("COMPLETION OUT ------->>>>", out.data.out)
        //             setMeasures(out.data.out.measure_arr);
        //             setMeasureNos(out.data.out.measure_no_arr);
        //             setCompletion(out.data.out.completed_arr);
        //             setInprogress(out.data.out.inprogress_arr);
        //             setOther(out.data.out.other_arr);
        //         }
        //     })
        // } else {
        //     GetCompletionGraphData(window.localStorage.getItem('ntfc_agency')).then((out) => {
        //         if (out.data && out.data.out) {
        //             console.log("COMPLETION OUT ------->>>>", out.data.out)
        //             setMeasures(out.data.out.measure_arr);
        //             setMeasureNos(out.data.out.measure_no_arr);
        //             setCompletion(out.data.out.completed_arr);
        //             setInprogress(out.data.out.inprogress_arr);
        //             setOther(out.data.out.other_arr);
        //         }
        //     })
        // }
        GetCompletionGraphData(val).then((out) => {
            if (out.data && out.data.out) {
                console.log("COMPLETION OUT ------->>>>", out.data.out)
                setMeasures(out.data.out.measure_arr);
                setMeasureNos(out.data.out.measure_no_arr);
                setCompletion(out.data.out.completed_arr);
                setInprogress(out.data.out.inprogress_arr);
                setOther(out.data.out.other_arr);
            }
        })

    }, [val]);
    const chartContainerRef = useRef(null); // Ref to chart container element
    let options = {
        tooltip: {
            trigger: 'item',
            axisPointer: {
                type: 'cross'
            },
            confine: true,
            formatter: (params) => {
                return `${params.seriesName}<br/><b>${params.data}%</b>`;
            }
        },
        legend: {
            data: ["Completed", "Inprogres", "Missed/ Delayed/ To Do"],
            top: 15,
            left: 'center',
        },
        grid: {
            left: '3%',
            right: '4%',
            bottom: '3%',
            containLabel: true
        },
        xAxis: [
            {
                type: 'value',
                // Set the minimum value of x-axis to the minimum data value
                // max: 'dataMax',
            }

        ],
        yAxis: [
            {
                type: 'category',
                axisTick: {
                    show: false
                },
                triggerEvent: true,
                axisLabel: {
                    show: true,
                    width: 100,
                    overflow: 'truncate'
                },

                axisPointer: {
                    type: 'shadow', // Use 'shadow' to show a tooltip box
                    // Triggered when the y-axis label is hovered
                },
                data: measures,
            }
        ],
        series: [
            {
                name: 'Completed',
                type: 'bar',
                itemStyle: {
                    color: "#1520A6",
                    borderRadius: [0, 10, 10, 0],
                    height: 30  // sets border radius for top-left and top-right corners
                },
                label: {
                    show: true,
                    position: 'inside'
                },
                emphasis: {
                    focus: 'series'
                },
                data: completion
            },
            {
                name: 'Inprogres',
                type: 'bar',
                itemStyle: {
                    color: '#0492C2',
                    borderRadius: [0, 10, 10, 0],
                    height: 30 // sets border radius for top-left and top-right corners
                },
                stack: 'Total',
                label: {
                    show: true,
                    position: 'inside'
                },
                emphasis: {
                    focus: 'series'
                },
                data: inprogress
            },
            {
                name: 'Missed/ Delayed/ To Do',
                type: 'bar',
                itemStyle: {
                    color: '#AED8E6 ',
                    borderRadius: [10, 0, 0, 10],
                    padding: [0, 100, 0, 40],
                    height: 30 // sets border radius for top-left and top-right corners
                },
                stack: 'Total',
                label: {
                    show: true,
                    position: 'inside'
                },
                emphasis: {
                    focus: 'series'
                },
                data: other
            }
        ]
    };

    return <div ref={chartContainerRef} >
        <ReactECharts style={{ height: "215vh" }} option={options} />
    </div>
};

export default MeasureCompletionBar;