import { Row, Tag, Collapse, Col, Badge } from "antd";
import { useEffect, useState } from "react";
import { getMeasureAgencies } from "../../../../api";
const { Panel } = Collapse;

const MeasureWiseDonutModal = ({ arrays = [[], [], [], [], []], type = "" }) => {
    const [measures, setMeasures] = useState([]);

    useEffect(() => {
        let ms = [];
        console.log("type", type);
        if (type == "In Progress") {
            ms = arrays[1];
        } else if (type == "Missed") {
            ms = arrays[4];
        } else if (type == "To Do") {
            ms = arrays[0];
        } else if (type == "Completed") {
            ms = arrays[2];
        } else if (type == "Delayed") {
            ms = arrays[3];
        }
        console.log("GETTING MS", { measures: ms });
        if (ms.length > 0) {
            getMeasureAgencies({ measures: ms }).then((out) => {
                console.log("RECEIVED MS", out);
                setMeasures(out.data.out);
            })
        }
    }, [type]);

    return <div className="custom-modal-body custom-scroll-div" style={{ height: "85vh", overflow: "auto" }}>
        <Row gutter={[16, 16]}>
            <Tag style={{ width: "62px", margin: "5px 0px " }} color="#1520A6">Measures</Tag>
            <Collapse size="small" defaultActiveKey={["0"]} >
                {
                    measures?.map(
                        (measure, key) => {
                            return <Panel
                                header={
                                    measure.article_no + "." + measure.measure_no + "." + measure.measure_name
                                }
                                key={key}
                            >
                                <Tag style={{ width: "62px", margin: "5px 0px " }} color="#1520A6">Agencies</Tag>
                                <div style={{ display: "flex", flexDirection: "column" }}>
                                    <Row gutter={[16, 16]}>
                                        {measure.agencies.map((agency, agkey) => {
                                            return <Col xs={24}>
                                                <Row gutter={[1, 1]}>
                                                    <Col xs={1}>
                                                        <Badge color="blue" status="default" />
                                                    </Col>
                                                    <Col xs={23}>
                                                        <span className='popup-action-title' key={agkey} >
                                                            {agency.agency_name}
                                                        </span>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        })}
                                    </Row>
                                </div>
                            </Panel>
                        }
                    )
                }
            </Collapse>
        </Row>
    </div >
}

export default MeasureWiseDonutModal;