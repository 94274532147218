import { Avatar, Button, ConfigProvider, Divider, Image, Popover, Typography } from 'antd';
import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import { UserOutlined, ArrowLeftOutlined } from "@ant-design/icons";
import { getUserById } from '../../api';


let userDesignation = ""

function UserDetails({ username, designation, dashboardName, title, dashborad }) {

    const [dateTime, setDateTime] = useState(new Date());
    const [userData, setUserData] = useState({})

    useEffect(() => {
        userDesignation = ""
        getUserData()
    }, [])

    async function getUserData() {
        const user = await getUserById(await window.localStorage.getItem('user_id'))

        setUserData(user.data.user)
        if (user.data.user.user_level === 7) {
            userDesignation = "Super Admin"



        } else if (user.data.user.user_level === 6) {
            userDesignation = "Secretariat"


        } else if (user.data.user.user_level === 5) {
            userDesignation = "Head of the Department"


        } else if (user.data.user.user_level === 4) {
            userDesignation = "Technical User"


        } else if (user.data.user.user_level === 3) {
            // userDesignation = "Private"
            userDesignation = "Donor"


        } else if (user.data.user.user_level === 2) {
            userDesignation = "Private Sector User"


        } else if (user.data.user.user_level === 1) {
            userDesignation = "User"

        }
    }
    useEffect(() => {
        const intervalId = setInterval(() => {
            setDateTime(new Date());
        }, 1000);
        return () => clearInterval(intervalId);
    }, [])


    const dateOptions = {
        day: '2-digit', month: '2-digit', year: 'numeric'
    };

    const timeOptions = {
        hour: "numeric",
        minute: "numeric",
        // second: "numeric",
    };

    const date = dateTime.toLocaleDateString(undefined, dateOptions);
    const time = dateTime.toLocaleTimeString(undefined, timeOptions);

    return (
        <>
            <div style={{ display: "flex", justifyContent: 'space-between' }}>
                <div>
                    <img src='/ntfcLogo.png' alt="ntfc logo"></img>

                </div>

                <div className="user-details" >

                    <div className="name-designation">

                        <Typography className="user-name">
                            {(userData ? userData?.first_name : "") + " " + (userData ? userData?.last_name : "")}
                        </Typography>
                        <br />
                        <Typography className="user-designation">
                            {userDesignation}
                        </Typography>


                    </div>
                    <Popover
                        content=
                        {
                            <Button

                                onClick={async () => {
                                    await window.localStorage.removeItem('ntfc_token');
                                    window.location.href = "/login";
                                }}>
                                Logout
                            </Button>
                        }
                        trigger="click">

                        <Avatar className="avatar" shape="square" size={50} icon={<UserOutlined />} />

                    </Popover>

                    <div className="date-time">
                        <Typography className="time">  {time}</Typography>
                        <Typography className="date2">  {date}</Typography>

                    </div>





                </div>


            </div>
            {!dashborad &&
                <div style={{ display: "flex", alignContent: "center" }}>
                    <div style={{ width: "95%" }}>
                        <Divider orientation="left" orientationMargin={0}>
                            <p className='divider-text' style={{ fontSize: 30 }}>{title}</p>

                        </Divider>
                    </div>

                    <div style={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        alignItems: 'center',
                        width: '5%',

                    }}>

                        <ConfigProvider
                            theme={{
                                token: {
                                    colorPrimary: '#006666',
                                },
                            }}
                        >
                            <Button
                                onClick={() => { window.location.href = "/newdashboard" }}
                                // disabled={disable ? true : false}
                                shape="circle"
                                icon={<ArrowLeftOutlined style={{ color: "#006666" }} />}
                                style={{ boxShadow: "rgba(0, 0, 0, 0.25) 0px 25px 50px -12px", width: 50, height: 50, backgroundColor: "#ffff", color: "black", borderRadius: 50, zIndex: 999 }}
                            />
                        </ConfigProvider>

                    </div>

                </div>
            }
        </>


    )
}

export default UserDetails