import { Button, Card, Result } from 'antd'
import React from 'react'

function EmailAlredayVerified() {
    return (
        <div className="container2" >
            <div className="container2"
                style={{
                    position: 'relative',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center', height: "100vh",
                    width: "100%"
                }}>
                <video
                    src={'/back.webm.mp4'}
                    controls={false}
                    autoPlay
                    loop
                    muted
                    style={{ width: "100%", height: "100%", objectFit: 'cover', }}

                />

            </div>

            <div className="overlay"></div>
            <Card className='no-padding2'
                style={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    backgroundColor: 'white',
                    width: '30vw',
                    border: '0px',
                    height: 'max-content',
                    padding: '0px',
                    borderRadius: '10px'
                }}
            >
                <Result

                    title="Your email is already verified!"
                    // subTitle="Your account is in the approvel stage. Once your account is approved by the NTFC Secretariat we will inform you via email. Please kindly check your email on next few days for the account confirmation notification."
                    extra={[
                        <Button size="large" type="primary" key="console" onClick={() => window.location.href = "/login"}>
                            Back to login
                        </Button>
                    ]}
                />
            </Card>
        </div>
    )
}

export default EmailAlredayVerified