import { Calendar, Badge, Avatar, Popover, Space, Modal, Segmented, Input, TimePicker, Typography, Select, Alert } from 'antd';
import { AppstoreOutlined, VideoCameraOutlined, FieldTimeOutlined, AntDesignOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { useState } from 'react';
dayjs.extend(customParseFormat);
const onChange = (time, timeString) => {
    console.log(time, timeString);
};
const { TextArea } = Input;
const { Text } = Typography;
const EventCalendarOld = () => {
    const onPanelChange = (value, mode) => {
        console.log(value.format('YYYY-MM-DD'), mode);
    };
    const [sel, setSel] = useState(new Date());
    const [modal1Open, setModal1Open] = useState(false);

    const [participants, setParticipants] = useState([]);

    const onChangeSel = (value, opts) => {
        console.log(`selected ${value}`, opts);
        const members = participants;
        members.push(opts.label);
        setParticipants([...members]);
    };
    const onSearchSel = (value) => {
        console.log('search:', value);
    };

    const content = (
        <Space direction='vertical'>
            <Badge status="success" text="Meeting with HR" />
            <Badge status="error" text="Task1 deadline" />
            <Badge status="default" text="Event in the top floor" />
        </Space>
    );

    const dateCellRender = (value) => {
        return (
            [5, 10, 15, 20].includes(new Date(value).getDate()) ? <Badge count={5} dot>
                <Popover content={content} title={`Events : ${new Date(value).toDateString()}`}>
                    <Avatar shape="square" size="large" style={
                        `${new Date(sel).getMonth()}-${new Date(sel).getDate()}` === `${new Date(value).getMonth()}-${new Date(value).getDate()}` ?
                            { background: '#1677ff' } : (new Date(sel).getMonth() !== new Date(value).getMonth() ? { background: '#f6f6f6', color: '#cecece' } : (new Date().toDateString() !== new Date(value).toDateString() ? { background: '#f6f6f6', color: 'black' } : { background: '#dbeefd', color: 'black' }))}>
                        {new Date(value).getDate()}
                    </Avatar>
                </Popover>
            </Badge> : <Avatar shape="square" size="large" style={
                `${new Date(sel).getMonth()}-${new Date(sel).getDate()}` === `${new Date(value).getMonth()}-${new Date(value).getDate()}` ?
                    { background: '#1677ff' } : (new Date(sel).getMonth() !== new Date(value).getMonth() ? { background: '#f6f6f6', color: '#cecece' } : (new Date().toDateString() !== new Date(value).toDateString() ? { background: '#f6f6f6', color: 'black' } : { background: '#dbeefd', color: 'black' }))}>
                {new Date(value).getDate()}
            </Avatar>
        );
    };
    return (
        <div>
            <Calendar fullscreen={false} dateFullCellRender={dateCellRender} onPanelChange={onPanelChange} onSelect={(dt) => {
                setSel(dt);
                setModal1Open(true);
            }} />
            <Modal
                title="Create New Calendar Event"
                centered
                open={modal1Open}
                onOk={() => setModal1Open(false)}
                onCancel={() => setModal1Open(false)}
            >
                <Space direction='vertical' style={{ width: '100%' }}>
                    <Segmented
                        options={[
                            {
                                label: 'Meeting',
                                value: 'Meeting',
                                icon: <VideoCameraOutlined />,
                            },
                            {
                                label: 'Event',
                                value: 'Event',
                                icon: <AppstoreOutlined />,
                            },
                            {
                                label: 'Task',
                                value: 'Task',
                                icon: <FieldTimeOutlined />,
                            },
                        ]}
                    />
                    <Text type='secondary'>Time (HH:mm)</Text>
                    <TimePicker onChange={onChange} defaultOpenValue={dayjs('00:00', 'HH:mm')} format={'HH:mm'} />
                    <Text type='secondary'>Participants</Text>
                    <Select
                        showSearch
                        placeholder="Select a person"
                        optionFilterProp="children"
                        onChange={onChangeSel}
                        onSearch={onSearchSel}
                        style={{ width: 300 }}
                        value={null}
                        filterOption={(input, option) =>
                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                        }
                        options={[
                            {
                                value: 'jack',
                                label: <Space><Avatar src="https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png" /><Text>Jack</Text></Space>,
                            },
                            {
                                value: 'lucy',
                                label: <Space><Avatar style={{ backgroundColor: '#f56a00' }}>K</Avatar><Text>Lucy</Text></Space>,
                            },
                            {
                                value: 'tom',
                                label: <Space><Avatar style={{ backgroundColor: '#1890ff' }} icon={<AntDesignOutlined />} /><Text>Tom</Text></Space>,
                            },
                        ]}
                    />
                    <Space block wrap>
                        {participants.map((person) => <Alert
                            message={person}
                            type="warning"
                            style={{ background: '#f5f5f5', borderRadius: 25, padding: 5, border: 'none' }}
                            closable
                        />)}
                    </Space>
                    <Text type='secondary'>Description</Text>
                    <TextArea rows={4} />
                </Space>
            </Modal>
        </div>
    );
};
export default EventCalendarOld;