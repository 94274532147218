import { useState } from 'react';
import AllInquiries from './AllInquiries';
import New from './New';
import PendingAnswers from './PendingAnswers';
import Answered from './Answered';

const TabsMonitor = () => {
  const [activeTab, setActiveTab] = useState('tab1');

  const handleTabChange = (key) => {
    setActiveTab(key);
  };

  return (
    <>
      <div>
      <div className="cardContainer">
        <div className="cardTab"
        onClick={() => handleTabChange('tab1')}
        >
            <p >All Inquiries</p>
        </div>
        <div className="cardTab"
         onClick={() => handleTabChange('tab2')}
        >
            <p >New</p>
        </div>
        <div className="cardTab"
         onClick={() => handleTabChange('tab3')}
        >
            <p >Pending Answers</p>
        </div>
        <div className="cardTab"
        onClick={() => handleTabChange('tab4')}
        >
            <p >Answered</p>
        </div>
      </div>
        
        
        
        
      </div>
      <div style={{ display: activeTab === 'tab1' ? 'block' : 'none' }}>
        <AllInquiries />
      </div>
      <div style={{ display: activeTab === 'tab2' ? 'block' : 'none' }}>
        <New />
      </div>
      <div style={{ display: activeTab === 'tab3' ? 'block' : 'none' }}>
        <PendingAnswers />
      </div>
      <div style={{ display: activeTab === 'tab4' ? 'block' : 'none' }}>
        <Answered />
      </div>
    </>
  );
};

export default TabsMonitor;