import React from 'react';
import ReactECharts from 'echarts-for-react';

const Lines = () => {
  const options = {
    xAxis: {
      type: 'category',
      data: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
    },
    yAxis: {
      type: 'value'
    },
    tooltip: {
      trigger: 'axis'
    },
    textStyle: {
      fontFamily: 'Figtree'
    },
    series: [
      {
        data: [10, 15, 25, 5, 35, 40, 30, 45, 20, 10, 90, 100],
        type: 'line'
      },
      {
        data: [45, 20, 10, 30, 90, 75, 55, 50, 10, 80, 5, 50],
        type: 'line'
      }
    ]
  };

  return <ReactECharts option={options} />;
};

export default Lines;