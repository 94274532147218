import { Avatar, Space, Typography, Popover, Divider, Row, Col, Tooltip, Button, Drawer, List, Input, Tag } from 'antd';
import { SearchOutlined, MessageOutlined, AlertOutlined, SendOutlined } from '@ant-design/icons';
import { useState, useEffect } from 'react';
const { Text, Title } = Typography;

const Header = ({ children }) => {
    const [open, setOpen] = useState(false);
    const onClose = () => {
        setOpen(false);
    };
    const [openSearch, setOpenSearch] = useState(false);
    const onCloseSearch = () => {
        setOpenSearch(false);
    };
    const [level, setLevel] = useState("");

    useEffect(() => {

        getLevel()

    }, []);

    const getLevel = async () => {


        const ntfc_level = await window.localStorage.getItem('ntfc_level');
        let level = ""
        if (ntfc_level == 1) {
            level = "Other"
        } else if (ntfc_level == 2) {
            level = "Donor"
        } else if (ntfc_level == 3) {
            level = "Private"
        } else if (ntfc_level == 4) {
            level = "Gov Technical"
        } else if (ntfc_level == 5) {
            level = "Gov HOD"
        } else if (ntfc_level == 6) {
            level = "Secretariat"
        } else if (ntfc_level == 7) {
            level = "Super Admin"
        }

        setLevel(level)
    }

    const ProfileModal = () => {
        return <div style={{ width: 400 }}>
            <Divider style={{ margin: '5px 0 10px 0' }} />
            <Button danger onClick={async () => {
                await window.localStorage.removeItem('ntfc_token');
                window.location.href = "/login";
            }}>Logout</Button>
            <Space direction='vertical' style={{ width: '100%' }}>
                <Row>
                    <Col span={12}>
                        <Space direction='vertical' style={{ width: '100%' }}>
                            <Text strong>Option Set 1</Text>
                            <Text type='secondary'>Option 1</Text>
                            <Text type='secondary'>Option 2</Text>
                            <Text type='secondary'>Option 3</Text>
                            <Text type='secondary'>Option 4</Text>
                        </Space>
                    </Col>
                    <Col span={12}>
                        <Space direction='vertical' style={{ width: '100%' }}>
                            <Text strong>Option Set 2</Text>
                            <Text type='secondary'>Option 1</Text>
                            <Text type='secondary'>Option 2</Text>
                            <Text type='secondary'>Option 3</Text>
                            <Text type='secondary'>Option 4</Text>
                        </Space>
                    </Col>
                </Row>
            </Space>
        </div>
    }

    const NotificationModal = () => {
        return <div style={{ width: 400 }}>
            <Divider style={{ margin: '5px 0 10px 0' }} />
            <Space direction='vertical' style={{ width: '100%' }}>
                <Row>
                    <Col span={12}>
                        <Space direction='vertical' style={{ width: '100%' }}>
                            <Text strong>Option Set 1</Text>
                            <Text type='secondary'>Option 1</Text>
                            <Text type='secondary'>Option 2</Text>
                            <Text type='secondary'>Option 3</Text>
                            <Text type='secondary'>Option 4</Text>
                        </Space>
                    </Col>
                    <Col span={12}>
                        <Space direction='vertical' style={{ width: '100%' }}>
                            <Text strong>Option Set 2</Text>
                            <Text type='secondary'>Option 1</Text>
                            <Text type='secondary'>Option 2</Text>
                            <Text type='secondary'>Option 3</Text>
                            <Text type='secondary'>Option 4</Text>
                        </Space>
                    </Col>
                </Row>
            </Space>
        </div>
    }

    return (
        <>
            <Space align="center" size="large" direction='vertical'>
                <Tooltip title="Search">
                    <Button onClick={() => setOpenSearch(true)} icon={<SearchOutlined style={{ fontSize: 18, color: '#969696' }} />} style={{ background: 'transparent', border: 'none' }} />
                </Tooltip>
                <Tooltip title="Inbox">
                    <Button onClick={() => setOpen(true)} icon={<MessageOutlined style={{ fontSize: 18, color: '#969696' }} />} style={{ background: 'transparent', border: 'none' }} />
                </Tooltip>
                <Popover
                    placement="bottomRight"
                    title="Notifications"
                    content={<NotificationModal />}
                >
                    <Button icon={<AlertOutlined style={{ fontSize: 18, color: '#969696' }} />} style={{ background: 'transparent', border: 'none' }} />
                </Popover>
                <Popover
                    placement="right"
                    title={
                        <div>
                            <div style={{ marginRight: "10px", display: "inline-block" }}>
                                Sample Profile Name
                            </div>
                            <div style={{ display: "inline-block" }}>
                                <Tag color="#2db7f5">{level}</Tag>
                            </div>
                        </div>
                    }
                    content={<ProfileModal />}
                >
                    <Avatar size="large" src="hydra.JPG" />
                </Popover>
            </Space>
            <Drawer
                closable={false}
                placement={"right"}
                width={"70vw"}
                onClose={onClose}
                open={open}
                style={{ borderRadius: '20px 0 0 20px' }}
            >
                <div style={{ width: '20vw', height: '5vh' }}>
                    <Title level={3}>Messages</Title>
                </div>
                <div style={{ display: 'flex' }}>
                    <div style={{ width: '30vw', borderRight: '1px solid #e0e0e0', height: '85vh' }}>
                        <List
                            dataSource={[
                                {
                                    id: 1,
                                    name: 'Lily',
                                    description: 'Last message',
                                    selected: false
                                },
                                {
                                    id: 2,
                                    name: 'Sammy',
                                    description: 'Last message',
                                    selected: true
                                },
                            ]}
                            renderItem={(item) => (
                                <List.Item
                                    key={item.id}
                                    style={item.selected ? { background: '#1677FF' } : {}}
                                >
                                    <List.Item.Meta
                                        avatar={
                                            <Avatar src="https://gw.alipayobjects.com/zos/rmsportal/BiazfanxmamNRoxxVxka.png" />
                                        }
                                        title={<Text strong style={item.selected ? { color: 'white' } : {}}>{item.name}</Text>}
                                        description={<Text style={item.selected ? { color: '#d9d9d9' } : {}}>{item.name}</Text>}
                                    />
                                </List.Item>
                            )}
                        />
                    </div>
                    <div style={{ width: '80vw', height: '85vh', overflowY: 'auto' }}>
                        <div style={{ width: '100%', height: '75vh', background: '#fbfbfb', padding: 10 }}>
                            <Space direction='vertical' style={{ width: '100%' }}>
                                <div style={{ padding: 10, background: 'white', display: 'flex', borderRadius: 8, boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px', width: 'fit-content', float: 'left' }}>Message One</div>
                                <div style={{ padding: 10, background: '#1677FF', color: 'white', display: 'flex', borderRadius: 8, boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px', width: 'fit-content', float: 'right' }}>Message Two</div>
                            </Space>
                        </div>
                        <div style={{ width: '100%' }}>
                            <Input.Group compact style={{ display: 'flex', justifyContent: 'center', background: 'white', paddingTop: 5 }}>
                                <Input
                                    style={{ width: '80%' }}
                                    placeholder="Enter your message here..."
                                />
                                <Tooltip title="Send Message">
                                    <Button type='primary' icon={<SendOutlined />} />
                                </Tooltip>
                            </Input.Group>
                        </div>
                    </div>
                </div>
            </Drawer>
            <Drawer
                closable={false}
                placement={"right"}
                width={"70vw"}
                onClose={onCloseSearch}
                open={openSearch}
                style={{ borderRadius: '20px 0 0 20px' }}
            >
                <div style={{ width: '20vw', height: '5vh' }}>
                    <Title level={3}>Search</Title>
                </div>
                <div style={{ display: 'flex' }}>
                </div>
            </Drawer>
        </>
    )
};

export default Header;