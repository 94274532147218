import { Avatar, Button, Col, Divider, Row, Space, Typography, Input, Popover, Tooltip, DatePicker, Collapse } from "antd";
import { UserOutlined, QuestionOutlined, UserAddOutlined, CommentOutlined, CaretRightOutlined, PlusCircleTwoTone, LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import StatusCompo from "./StatusCompo";
import SubActionActivities from "./SubActionActivities";
import { useEffect, useState } from "react";
import { addParticipants, changeAgencyActionStatus, getActionParticipants, getAgencySubActions, getAllUsers, removeParticipants, searchParticipants } from "../../api";
import SubActionItem from "./SubActionItem";
import Modal from "../../Components/Modal/CustomeModal";


const { Search } = Input;
const { Panel } = Collapse;

const { Text } = Typography;

const ActionItem = ({ action, pending }) => {


    const [subActions, setSubActions] = useState([]);
    const [currentActionParticipants, setCurrentActionParticipants] = useState([]);
    const [finalData, setFinalData] = useState([])
    const [users, setUsers] = useState([]);
    const [searhcUsers, setSearchUsers] = useState([]);
    const [currentPartiesLoading, setCurrentPartLoad] = useState(false)
    const [loaded, setLoad] = useState(true)
    const [userSearching, setSearch] = useState(false)

    useEffect(() => {

        getAgencySubActions({
            agency: action.agency_id,
            action: action.action_id
        }).then((out) => {
            setSubActions(out.data.out)
        })

    }, [action])

    async function getSubActions() {
        getAgencySubActions({
            agency: action.agency_id,
            action: action.action_id
        }).then((out) => {
            setSubActions(out.data.out)
        })
    }

    async function getUserDetails() {

        let currentUsers = []
        let allusers = []
        setCurrentPartLoad(true)

        // get current Participants
        await getActionParticipants(action.agency_id, action.action_id).then(out => {

            setCurrentActionParticipants(out.data.out)
            currentUsers = out.data.out

        })

        // get all Participants
        await getAllUsers(action.agency_id).then(out => {
            allusers = out.data.out
            setUsers(out.data.out)
        });

        // set function for set user details for the current paricipants -- first_name, last_name...
        await getCurrentUsersName(currentUsers, allusers)

    }

    const getCurrentUsersName = async (currentUsers, allusers) => {

        if (allusers && currentUsers) {
            currentUsers.forEach(obj2 => {
                const obj1 = allusers.find(o => o.uuid === obj2.user_id);
                if (obj1) {
                    Object.assign(obj2, obj1);
                }
            });
            setCurrentActionParticipants(currentUsers)

        }
        setLoad(true)
        setCurrentPartLoad(false)
        onSearch("")

    }

    const changeStatus = (stat, obj) => {
        obj.agency_id = action.agency_id;
        obj.new_status = stat;
        changeAgencyActionStatus(obj).then((out) => {
            window.location.reload();
        })
    }

    const getBackground = (status) => {
        let out = null;
        switch (status) {
            case 0:
                out = { background: '#ffff', borderRadius: '10px', boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px', margin: '0 !important' }
                break;
            case 1:
                out = { background: '#ffff', borderRadius: '10px', boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px', margin: '0 !important' }
                break;
            case 2:
                out = { background: '#ffff', borderRadius: '10px', boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px', margin: '0 !important' }
                break;
            case 3:
                out = { background: '#ffff', borderRadius: '10px', boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px', margin: '0 !important' }
                break;
            case 4:
                out = { background: '#ffff', borderRadius: '10px', boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px', margin: '0 !important' }
                break;
            default:
                out = { background: '#ffff', borderRadius: '10px', boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px', margin: '0 !important' };
                break;
        }
        return out;
    }

    const getLabel = (status) => {
        let out = null;
        switch (status) {
            case 0:
                out = <div style={{ textAlign: "center", background: '#8E8E8E', color: 'white', padding: 5, borderRadius: '5px ' }}>ToDo</div>
                break;
            case 1:
                out = <div style={{ textAlign: "center", background: '#FDAA3D', color: 'white', padding: 5, borderRadius: '5px' }}>InProgress</div>
                break;
            case 2:
                out = <div style={{ textAlign: "center", background: '#00C874', color: 'white', padding: "5px", borderRadius: '5px' }}>Completed</div>
                break;
            case 3:
                out = <div style={{ textAlign: "center", background: '#E2445C', color: 'white', padding: 5, borderRadius: '5px' }}>Delayed</div>
                break;
            case 4:
                out = <div style={{ textAlign: "center", background: '#ff8282', color: 'white', padding: 5, borderRadius: '5px' }}>Missed</div>
                break;
            default:
                out = <div style={{ textAlign: "center", background: '#FDAA3D', color: 'white', padding: 5, borderRadius: '5px' }}>Pending</div>;
                break;
        }
        return out;
    }
    const onSearch = async (value) => {

        setSearch(true)

        let out = await searchParticipants(action.agency_id, value)
        if (out.status === 200) {
            setSearchUsers(out.data.out)
            setSearch(false)
        }
        console.log(out)
    };

    const addNewParticipant = async (user) => {

        let data = {
            "agency_id": action.agency_id,
            "action_id": action.action_id,
            "participant": user.uuid,
            "subaction": null
        }

        let out = await addParticipants(data)
        if (out.status === 200) {
            getUserDetails()
        }

    }

    const removeParticipant = async (user) => {

        let data = {
            "agency_id": user.agency_id,
            "action_id": action.action_id,
            "participant": user.user_id,
            "subaction": null
        }

        let out = await removeParticipants(data)
        if (out.status === 200) {
            getUserDetails()
        }

    }

    function getRandomColor() {
        const letters = "0123456789ABCDEF";
        let color = "#";
        for (let i = 0; i < 6; i++) {
            color += letters[Math.floor(Math.random() * 16)];
        }
        return color;
    }


    const participants = (
        <div style={{ width: '30vw' }}>


            <Text strong>Participants</Text>
            <Divider style={{ margin: 5 }} />

            <Search style={{ width: "100%", margin: "10px 0" }} placeholder="input search text" onSearch={onSearch} enterButton />

            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                {!userSearching ?
                    searhcUsers.map((c, k) => (
                        <Tooltip title={"Add " + c.title + " " + c.first_name + " " + c.last_name} color={"#108ee9"} key={k}>
                            <div onClick={() => addNewParticipant(c)} style={{ padding: "5px 10px", borderRadius: 25, border: '1px solid #e9e7e7', background: '#fafafa', width: 'fit-content', margin: "2px 3px", cursor: "pointer" }}>
                                <Space key={k}>
                                    {/* <Avatar size='small'>{c}</Avatar> */}
                                    <Text>{c.title + " " + c.first_name + " " + c.last_name + " | " + c.designation}</Text>
                                </Space>
                            </div>
                        </Tooltip>
                    )) : <LoadingOutlined style={{ fontSize: "16px", color: "blueviolet" }} />
                }
            </div>


            {searhcUsers.length > 0 ? <Divider style={{ margin: 5 }} /> : null}

            <div style={{ margin: "15px 0", padding: "0 10px" }}>
                <Text style={{ fontSize: 16 }} strong>Current Participants</Text>
            </div>


            {!currentPartiesLoading ?
                <Row>
                    {
                        currentActionParticipants.map((c, k) => (
                            <Tooltip title={"Remove " + c.title + " " + c.first_name + " " + c.last_name} color={"#f50"} key={k}>
                                <div onClick={() => removeParticipant(c)} style={{ padding: "5px 10px", borderRadius: 25, border: '1px solid #e9e7e7', background: '#fafafa', width: 'fit-content', margin: "2px 3px", cursor: "pointer" }}>
                                    <Text>{c.title + " " + c.first_name + " " + c.last_name + " | " + c.designation}</Text>
                                </div>
                            </Tooltip>
                        ))
                    }
                </Row> :
                <div>
                    <LoadingOutlined style={{ fontSize: "16px" }} />
                </div>
            }

        </div>

    );

    return <div style={getBackground(action.action_status)}>
        <Row gutter={16} style={{
            marginLeft: '0 !important',
            marginRight: '0 !important',
            background: 'rgb(240, 246, 255)',
            padding: '10px',
            borderRadius: '10px 10px 0px 0px',

        }} >
            <Col span={7}>
                <Row gutter={16} >
                    <Col span={10}>
                        {getLabel(action.action_status)}
                    </Col>
                    <Col span={8} className="colStyle">
                        <Typography.Text style={{ fontSize: 12, backgroundColor: "#ffff", padding: "5px 10px", borderRadius: "10px" }}>{subActions.length} Lines</Typography.Text>
                    </Col>
                    <Col span={6} className="colStyle">
                        <Typography.Title style={{ fontSize: 12, borderRadius: "10px 10px 00px 10px" }} level={5}>50%</Typography.Title>
                    </Col>
                </Row>

            </Col>

            <Col span={3} className="colStyle">
                <Typography.Text style={{ fontSize: 12 }}>STATUS</Typography.Text>
            </Col>
            <Col span={5} className="colStyle">
                <Typography.Text style={{ fontSize: 12 }}>ASSIGNEE</Typography.Text>
            </Col>
            <Col span={3} className="colStyle">
                <Typography.Text style={{ fontSize: 12 }}>START DATE</Typography.Text>
            </Col>
            <Col span={3} className="colStyle">
                <Typography.Text style={{ fontSize: 12 }}>DEFINITIVE DATE</Typography.Text>
            </Col>
            <Col span={3} className="colStyle">
                <Typography.Text style={{ fontSize: 12 }}>COMMENTS</Typography.Text>
            </Col>
            {/* <Col span={1} className="colStyle">
                <SubActionActivities action={action} create={true} commentSec={false} mainAction={true} />
            </Col> */}
        </Row>

        <Row gutter={16} style={{ marginTop: 15, }}>
            <Col span={7} style={{ paddingLeft: 18 }}>
                <Typography.Text style={{ fontWeight: 800, fontSize: 13 }} level={6}>
                    {action ? action.action.substring(0, 45).replace(/([A-Za-z])(\s+)([A-Za-z])/g, "$1 $3") + "..." : null}
                </Typography.Text>

            </Col>
            <Col span={3} style={{ textAlign: 'center', paddingLeft: 18 }}>
                <StatusCompo status={action.action_status} action={action} setStatus={changeStatus} />
            </Col>
            <Col span={5} style={{ textAlign: 'center' }}>
                {loaded ? !currentActionParticipants ? <UserAddOutlined /> :
                    <Popover content={participants} trigger="click" placement="left" onOpenChange={async (visible) => {
                        if (visible) {
                            await getUserDetails()
                        } else {
                            setSearchUsers([])
                        }


                    }}>
                        <Tooltip onOpenChange={async (visible) => {
                            if (visible) {
                                await getUserDetails()
                            } else {
                                setSearchUsers([])
                            }


                        }} title={
                            <div style={{ padding: "5px" }}>
                                <Avatar.Group>
                                    {currentActionParticipants.length > 0 ? currentActionParticipants.map(element => {
                                        return <Avatar size="large" style={{ backgroundColor: getRandomColor() }}>{element.first_name}</Avatar>
                                    })
                                        :
                                        <Typography.Text style={{ color: "ActiveCaption" }}>{"No Participants added. click to Add Participants."} </Typography.Text>}

                                </Avatar.Group>
                            </div>} placement="top" color={"#fff"}>
                            <div style={{ padding: "5px" }}>
                                <Avatar.Group>
                                    {currentActionParticipants.length > 0 ? currentActionParticipants.map(element => {
                                        return <Avatar size="large" style={{ backgroundColor: getRandomColor() }}>{element.first_name}</Avatar>
                                    })
                                        :
                                        <Avatar
                                            style={{
                                                backgroundColor: '#87d068',
                                            }}
                                            icon={<PlusOutlined />}
                                        />
                                    }

                                </Avatar.Group>
                            </div>
                        </Tooltip>
                    </Popover> : <LoadingOutlined style={{ fontSize: "16px" }} />}

            </Col>
            <Col span={3} style={{ textAlign: 'center' }}>
                <DatePicker onChange={() => { }} style={{ border: 'none', background: 'none', width: "120%" }} format="DD/MM/YYYY" />
            </Col>
            <Col span={3} style={{ textAlign: 'center' }}>
                <Typography.Text level={6}>{new Date(action.action_def_date).toLocaleDateString()}</Typography.Text>
            </Col>
            <Col span={3} style={{ textAlign: 'center' }}>
                <Modal />
                {/* <SubActionActivities action={action} create={true} commentSec={false} mainAction={true} getSubActions={() => getSubActions()} /> */}
            </Col>
        </Row>
        {/* <Divider style={{ margin: 0, marginTop: 10 }} /> */}
        <Collapse

            defaultActiveKey={['1']}
            expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
            style={{ margin: "10px 0" }}

        >
            <Panel header="Sub Actions" key="1" >
                {subActions.map((sub, k) => {
                    return <SubActionItem key={k} sub={sub} action={action} getSubActions={() => getSubActions()} />
                })}
            </Panel>

        </Collapse>
        <Divider />

    </div>
}

export default ActionItem;