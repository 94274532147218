import React, { useEffect, useState } from "react";
import TabLayout from "../TabLayout";
import {
  Button,
  Card,
  Col,
  ConfigProvider,
  DatePicker,
  Form,
  Input,
  Modal,
  Popover,
  Row,
  Select,
  Space,
  Table,
  Tag,
  Tooltip,
  Typography,
} from "antd";

import { ToastContainer, toast, Zoom } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  DownloadFiles,
  GetMyDonorInterests,
  addDonorInterests,
  editInterest,
  getAgencies,
  getAgencyMeasures,
  getMeasures,
} from "../../../api";
import Dragger from "antd/es/upload/Dragger";
import TextArea from "antd/es/input/TextArea";
import dayjs from "dayjs";
import {
  PlusOutlined,
  InboxOutlined,
  EyeOutlined,
  CloseOutlined,
  CloudUploadOutlined,
  CalendarOutlined,
  ContainerOutlined,
  ReconciliationOutlined,
  CalendarFilled,
  FileDoneOutlined,
  BorderlessTableOutlined,
  BoxPlotOutlined,
  EditOutlined,
  HomeOutlined
} from "@ant-design/icons";
import { MainColors, NewColorTheme } from "../../../appConst";
import { BiCloudDownload } from "react-icons/bi";

function DonorInterestsView({ data, setData }) {
  const [agencies, setAgencies] = useState([]);
  const [measures, setMeasures] = useState([]);
  const [form] = Form.useForm();
  const [submitBtn, SetSubmitBtnLoad] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [support_type, setAssistance] = useState(0);
  const [toDate, setToDate] = useState(null);
  const [fromDate, setFromDate] = useState(null);
  const [uploadLoad, setUploadLoad] = useState(true);
  const [measure, setMeasure] = useState(null);
  const [selected, setSelected] = useState(null);
  const [currentAgency, setAgency] = useState()
  const [currentRecord, setCurrentRecord] = useState(null);
  const [isInterestModalOpen, setIsInterestModalOpen] = useState(false);
  const handleCancel = () => {
    setIsInterestModalOpen(false);
  };

  const MyInterest = [
    {
      title: "Creation Date",
      dataIndex: "created",
      key: "created",
      render: (_, { created }) => (
        <p>{created ? dayjs(created).format("DD/MM/YYYY") : "-"}</p>
      ),
    },

    {
      title: "Measure",
      dataIndex: "measure_name",
      key: "measure_name",
      width: 280,
      render: (_, { measure_no, article_no, measure_name, measure_id }) => (
        <p>{measure_id ? (article_no + "." + measure_no + ". " + measure_name) : "-"}</p>
      ),
    },

    {
      title: "Subject",
      dataIndex: "subject",
      key: "subject",
    },

    {
      title: "Support Type",
      dataIndex: "support_type",
      key: "support_type",
      render: (_, record) =>
        record ? (
          <div>
            {record.support_type == 0 ? <Tag style={{ display: "flex", justifyContent: "center" }} color={NewColorTheme.primaryColor}>TS/CB</Tag> :
              <Tag style={{ display: "flex", justifyContent: "center" }} color="#08B3D7">OTHER</Tag>}

          </div>

        ) : null,
    },

    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      width: 110,
      ellipsis: true,
      render: (_, { description }) => (
        <Space>
          {description.length > 30 ?
            <span>
              {`${description.substring(0, 5)}...`}
              <Popover arrow content={<div style={{ width: 400 }} >{description}</div>} >
                <a>see more</a>
              </Popover>
            </span>
            : description}
        </Space>

      ),
    },
    // {
    //     title: 'Attachments',
    //     dataIndex: 'attachments',
    //     key: 'attachments',

    // },
    {
      title: "Created Date",
      dataIndex: "created",
      key: "created",
      render: (_, record) =>
        record ? <div>{dayjs(record.created).format("DD/MM/YYYY")}</div> : null,
    },
    {
      title: "Status",
      dataIndex: "published_by",
      key: "published_by",
      render: (_, { status }) => (
        <Tag
          color={status == 0 ? "#008282" : status == 1 ? "#9B0259" : "#9B0259"}
          key={status}
          style={{
            width: "max-content",
            display: "flex",
            justifyContent: "center",
          }}
        >
          {status == 0 ? "ACTIVE" : "INACIVE"}
        </Tag>
      ),
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) =>
        record ? (
          <div>
            <Tooltip title="View">
              <Button
                type="secondary"
                size="small"
                onClick={() => {
                  form.resetFields();

                  setAgency()
                  // setSummary("")
                  // setDescription("")
                  setToDate(null)
                  setFromDate(null)
                  setCurrentRecord(record);
                  setIsInterestModalOpen(true);
                  console.log("Record", record);
                }}
                shape="circle"
                icon={<EyeOutlined style={{ color: "darkgreen" }} />}
              />
            </Tooltip>
            <Tooltip title="Edit Interest">
              <Button
                type="secondary"
                size="small"
                onClick={(e) => {
                  e.stopPropagation();
                  form.resetFields();
                  setSelected(record)
                  console.log("EDit", record);
                }}
                shape="circle"
                icon={<EditOutlined style={{ color: "darkgreen" }} />}
              />
            </Tooltip>
          </div>
        ) : null,
    },
  ];

  useEffect(() => {
    if (selected) {
      console.log("selected", selected)

      // let def_date = `${new Date().getFullYear()}-${new Date().getMonth() + 1}-${new Date().getDate()}`;
      // if (selected.def_date) {
      //     def_date = moment(selected.def_date).format("DD/MM/YYYY");
      // }
      form.setFieldValue('subject', selected.subject)

      form.setFieldValue('description', selected.description)

      form.setFieldValue('measure', selected.measure_id)
      form.setFieldValue('agency', selected.agency_id)
      form.setFieldValue('support_type', selected.support_type)
      form.setFieldValue('duration', selected.summary)
      setFromDate(selected.implementation_from)
      setToDate(selected.implementation_to)

    } else {
      form.resetFields();
      // setAction("")
      // setRemarks("")

    }
    // eslint-disable-next-line
  }, [selected]);


  useEffect(() => {
    loadInterestTableData();
  }, []);

  function loadInterestTableData() {
    getAgencies().then((res) => {
      const agencyList = [
        {
          value: -1,
          label: "National Wise",
          agency_type: 1,
        },
        ...res.data.out,
        {
          value: -2,
          label: "Other",
          agency_type: 1,
        },
      ];
      setAgencies(agencyList);
    });

    getMeasures().then((out2) => {
      setMeasures(out2.data.out);
    });

    GetMyDonorInterests().then((out) => {
      var sortedArray = out.data.out.sort(
        (a, b) => new Date(b.created) - new Date(a.created)
      );
      setData(sortedArray);
    });
  }

  const onSubmit = async (val) => {
    SetSubmitBtnLoad(true);
    console.log("measureeee", val);
    const formData = new FormData();
    formData.append("interest_file", fileList);
    formData.append("subject", val.subject);
    formData.append("description", val.description);
    formData.append("measure", val.measure);
    formData.append("agency_id", window.localStorage.getItem('ntfc_level') != 3 ? val.agency : null);
    formData.append("support_type", support_type);
    formData.append("implementation_from", fromDate);
    formData.append("implementation_to", toDate);
    setUploadLoad(false);
    let out
    if (selected) {
      formData.append("id", selected.id);
      out = await editInterest(formData);
    } else {
      out = await addDonorInterests(formData);
    }

    if (out.status === 200) {
      toast.success(out.data.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });

      setSelected(false)
    } else {
      toast.error("File Upload Error", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
    loadInterestTableData();
    form.resetFields();
    setFileList([]);
    SetSubmitBtnLoad(false);
    setUploadLoad(true);
  };

  const handleAgency = (value) => {
    console.log(value)
    // setAgencyID(value)
    if (value == -1) {
      getMeasures().then((out2) => {
        setMeasures(out2.data.out);
      });
    } else {
      getAgencyMeasures(value).then((out) => {
        setMeasures(
          out.data.out.map((m) => {
            return {
              value: m.measure_id,
              label: m.article_no + ". " + m.measure_no + ". " + m.summary,
            };
          })
        );
      });
    }
  };

  const onFromDateChange = (date, dateString) => {
    setFromDate(dayjs(date).format());
  };

  const onToDateChange = (date, dateString) => {
    setToDate(dayjs(date).format());
  };
  const props = {
    name: "file",
    multiple: false,
    action: "https://www.mocky.io/v2/5cc8019d300000980a055e76",

    onChange(info) {
      console.log("FIle", info);
      const { status } = info.file;
      if (status !== "uploading") {
        console.log(info.file, info.fileList);
      }
      if (status === "done") {
        // message.success(`${info.file.name} file uploaded successfully.`);
        handleFileUpload(info.file);
      } else if (status === "error") {
        // message.error(`${info.file.name} file upload failed.`);
      }
    },
    onDrop(e) {
      console.log("Dropped files", e.dataTransfer.files);
    },
  };
  async function handleFileUpload(file) {
    setFileList(file);
  }
  const FetchFile = async (file) => {
    let out = await DownloadFiles(file.token);

    let blob = new Blob([out.data], { type: file.type });
    let url = window.URL.createObjectURL(blob);

    let link = document.createElement("a");
    link.href = url;

    link.download = file.url.split("-::-")[1];

    document.body.appendChild(link);
    link.click();
  };
  return (
    <div className="custom-scroll-div tab-layout-div">
      <TabLayout
        title={selected ? "Edit Donor Interest" : "Create Donor Interest"}
        formSide={
          <div>
            <Form form={form} layout="vertical" onFinish={onSubmit} scrollToFirstError>
              {window.localStorage.getItem('ntfc_level') != 3 ?
                <Form.Item
                  label="Agency Name"
                  wrapperCol={{ span: 24 }}
                  rules={[
                    {
                      required: true,
                      message: "Agency is required",
                    },
                  ]}
                  required
                  name="agency"
                >
                  <Select
                    name="agency"
                    placeholder="Select Agency"
                    // size="large"
                    style={{ width: "100%" }}
                    options={agencies.filter((a) => a.agency_type === 1)}
                    onChange={(value) => {
                      form.resetFields(["measure"]);
                      handleAgency(value);
                      setAgency(value)
                    }}
                  // value={currentAgency}
                  // options={[{ value: 999, label: "Island wise" }, ...agencies]}
                  />
                </Form.Item>
                : null}
              {currentAgency != -2 &&
                <Form.Item
                  label="Measure"
                  wrapperCol={{ span: 24 }}
                  rules={[
                    {
                      required: currentAgency != -2 ? true : false,
                      message: "Measure is required",
                    },
                  ]}
                  required
                  name="measure"
                >
                  <Select
                    placeholder="Select Measure"
                    options={measures}
                    onChange={(val) => {
                      console.log("valuee-------->>>", val);
                      setMeasure(val);
                    }}
                  />
                </Form.Item>

              }

              <Form.Item
                required
                rules={[
                  {
                    required: true,
                    message: "Subject is required",
                  },
                  {
                    pattern:
                      /^(?=.*[a-zA-Z])[a-zA-Z0-9!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/? ]+$/, //if field has only spaces, numbers or special characters
                    message: (
                      <p style={{ marginLeft: "12px" }}>
                        Please enter a valid Subject with alphabetical
                        characters
                      </p>
                    ),
                  },
                ]}
                label="Subject"
                wrapperCol={{ span: 24 }}
                name="subject"
              >
                <Input placeholder="Subject" />
              </Form.Item>
              <Form.Item
                label="Support Type"
                wrapperCol={{ span: 24 }}
                rules={[
                  {
                    required: true,
                    message: "Support type is required",
                  },
                ]}
                required
                name="support_type"
              >
                <Select
                  placeholder="Support Type"
                  onChange={(val) => setAssistance(val)}
                  // defaultValue={0}
                  options={[
                    {
                      value: 0,
                      label: "Technical Support and Capacity Building",
                    },

                    { value: 1, label: "Other" },
                  ]}
                />
              </Form.Item>
              <Form.Item
                label="Tentative Start Date"
                name="start_date"
                // required
                valuePropName="date"
              // rules={[{
              //     required: true,
              //     message: <p style={{ marginLeft: "12px" }}>Start Date is required</p>,
              // }]}
              >
                <DatePicker
                  style={{ width: "100%" }}
                  // size="large"
                  onChange={onFromDateChange}
                  format="DD/MM/YYYY"
                  value={fromDate ? dayjs(fromDate) : null}
                  allowClear={false}
                // value={dayjs(fromDate).isValid() ? dayjs(fromDate) : null}
                />
              </Form.Item>
              <Form.Item
                label="Tentative End Date"
                name="end_date"
                // required
                valuePropName="date"
              // rules={[{
              //     required: true,
              //     message: <p style={{ marginLeft: "12px" }}>End Date is required</p>,
              // }]}
              >
                <DatePicker
                  style={{ width: "100%" }}
                  // size="large"
                  onChange={onToDateChange}
                  format="DD/MM/YYYY"
                  disabledDate={(current) =>
                    current && current.isBefore(form.getFieldValue("start_date"))
                  }
                  value={toDate ? dayjs(toDate) : null}
                  allowClear={false}
                />
              </Form.Item>
              {uploadLoad ? (
                <div style={{ margin: "30px 0px" }}>
                  <p>
                    Please ensure that only one attachment is uploaded at a
                    time, and the file size should be limited to a maximum of
                    5MB.
                  </p>
                  <Dragger
                    {...props}
                    showUploadList={{ showProgress: true }}
                    onRemove={() => {
                      setFileList([])
                    }}
                    multiple={false}
                    beforeUpload={(file) => {
                      const MAX_SIZE = 5 * 1024 * 1024; // 5 MB
                      if (file.size > MAX_SIZE) {
                        toast.error(
                          "File size exceeds limit! The maximum file upload size is 5MB.",
                          {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "colored",
                          }
                        );
                        return false;
                      } else {
                        handleFileUpload(file);
                      }
                      return false; // to prevent automatic upload
                    }}
                    maxCount={1}
                  >
                    <p className="ant-upload-drag-icon">
                      <InboxOutlined tyle={{ color: "#33363F" }} />
                    </p>
                    <p className="ant-upload-text">
                      Click or drag a file to this area to upload
                    </p>
                  </Dragger>
                </div>
              ) : (
                ""
              )}
              <Form.Item
                required
                rules={[
                  {
                    required: true,
                    message: "Description is required",
                  },
                  {
                    pattern:
                      /^(?=.*[a-zA-Z])[a-zA-Z0-9!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/? ]+$/, //if field has only spaces, numbers or special characters
                    message: (
                      <p style={{ marginLeft: "12px" }}>
                        Please enter a valid project description with
                        alphabetical characters
                      </p>
                    ),
                  },
                ]}
                label="Project Description"
                wrapperCol={{ span: 24 }}
                name="description"
              >
                <TextArea placeholder="Description" rows={4} />
              </Form.Item>
              <Form.Item wrapperCol={{ span: 24 }}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    width: "100%",
                  }}
                >
                  {selected ? <ConfigProvider
                    theme={{
                      token: {
                        colorPrimary: "#272c95"
                      },
                    }}
                  >
                    <Button
                      htmlType="button"
                      size="large"
                      type="primary"
                      style={{ marginRight: 5 }}
                      onClick={() => {
                        setSelected(null);
                        setFromDate(null);
                        setToDate(null);
                      }}
                      disabled={window.localStorage.getItem('view_only') == 1}
                    >
                      Cancel
                    </Button>
                  </ConfigProvider> : null}
                  <ConfigProvider
                    theme={{
                      token: {
                        colorPrimary: "#272c95",
                      },
                    }}
                  >
                    <Button
                      loading={submitBtn}
                      htmlType="submit"
                      size="large"
                      type="primary"
                      disabled={window.localStorage.getItem('view_only') == 1}
                    >
                      {selected ? "Save" : "Create"}
                    </Button>
                  </ConfigProvider>
                </div>
              </Form.Item>
            </Form>
          </div>
        }
        table={
          <Table
            className="custom-table"
            rowClassName={(record, index) => index % 2 === 0 ? 'white-row' : 'gray-row'}

            scroll={{
              y: 400,
            }}
            size="small"
            columns={MyInterest}
            dataSource={data}
            pagination={{
              pageSize: 4,
              total: data?.length,
            }}
          // onRow={(record, rowIndex) => {
          //   return {
          //     onClick: () => {
          //       form.resetFields();


          //       console.log("record--->>", record);
          //       setCurrentRecord(record);
          //       setIsInterestModalOpen(true);
          //     },
          //   };
          // }}
          />
        }
      />
      <Modal
        closable={true}
        className="custom-modal"
        width={700}
        footer={false}
        onCancel={handleCancel}
        title={
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div>
              {" "}
              <Typography className="custom-modal-title" level={2}>
                Donor Interest Details
              </Typography>
            </div>
          </div>
        }
        open={isInterestModalOpen}
        onOk={handleCancel}
        centered
      >
        <div
          className="custom-modal-body custom-scroll-div"
          style={{ height: 500, overflow: "auto" }}
        >
          <Row>
            <Col xs={24}>
              <Row gutter={[8, 8]} align={"top"}>
                <Col xs={24}>
                  <Row gutter={[8, 8]}>
                    <Col xs={2}>
                      <ReconciliationOutlined style={{ fontSize: 22 }} />
                    </Col>
                    <Col xs={22}>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "flex-start",
                        }}
                      >
                        <Typography.Title level={5}>
                          {" "}
                          Subject
                        </Typography.Title>
                        <Typography> {currentRecord?.subject}</Typography>
                      </div>
                    </Col>
                  </Row>
                </Col>
                <Col xs={12}>
                  <Row gutter={[8, 8]} align={"top"}>
                    <Col xs={4}>
                      <CalendarFilled style={{ fontSize: 22 }} />
                    </Col>
                    <Col xs={20}>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "flex-start",
                        }}
                      >
                        <Typography.Title level={5}>
                          {" "}
                          Created Date
                        </Typography.Title>

                        <Typography>
                          {" "}
                          {dayjs(currentRecord?.created).format("DD/MM/YYYY")}
                        </Typography>
                      </div>
                    </Col>
                  </Row>
                </Col>
                <Col xs={24}>
                  <Row gutter={[8, 8]} align={"top"}>
                    <Col xs={2}>
                      <HomeOutlined style={{ fontSize: 22 }} />
                    </Col>
                    <Col xs={22}>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "flex-start",
                        }}
                      >
                        <Typography.Title level={5}> Agency</Typography.Title>

                        <Typography>
                          {" "}
                          {currentRecord?.agency_id ? agencies.find((element) => element.value == currentRecord?.agency_id).label : "-"}
                        </Typography>
                      </div>
                    </Col>
                  </Row>
                </Col>
                <Col xs={24}>
                  <Row gutter={[8, 8]} align={"top"}>
                    <Col xs={2}>
                      <BoxPlotOutlined style={{ fontSize: 22 }} />
                    </Col>
                    <Col xs={22}>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "flex-start",
                        }}
                      >
                        <Typography.Title level={5}> Measure</Typography.Title>

                        <Typography>
                          {" "}
                          {currentRecord?.measure_id ? currentRecord?.article_no +
                            "." +
                            currentRecord?.measure_no +
                            ". " +
                            currentRecord?.measure_name : "-"}
                        </Typography>
                      </div>
                    </Col>
                  </Row>
                </Col>
                <Col xs={24}>
                  <Row gutter={[8, 8]} align={"top"}>
                    <Col xs={2}>
                      <BorderlessTableOutlined style={{ fontSize: 22 }} />
                    </Col>
                    <Col xs={22}>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "flex-start",
                        }}
                      >
                        <Typography.Title level={5}>
                          {" "}
                          Support Type
                        </Typography.Title>

                        <Typography>
                          {" "}
                          {currentRecord?.support_type == 0
                            ? "Technical Support or Capacity Building"
                            : "Other"}
                        </Typography>
                      </div>
                    </Col>
                  </Row>
                </Col>
                {currentRecord?.implementation_from && (
                  <Col xs={12}>
                    <Row gutter={[8, 8]} align={"top"}>
                      <Col xs={4}>
                        <CalendarOutlined style={{ fontSize: 22 }} />
                      </Col>
                      <Col xs={20}>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "flex-start",
                          }}
                        >
                          <Typography.Title level={5}>
                            {" "}
                            Tentative Start Date
                          </Typography.Title>

                          <Typography>
                            {" "}
                            {dayjs(currentRecord?.implementation_from).format(
                              "DD/MM/YYYY"
                            )}
                          </Typography>
                        </div>
                      </Col>
                    </Row>
                  </Col>
                )}
                {currentRecord?.implementation_to && (
                  <Col xs={12}>
                    <Row gutter={[8, 8]} align={"top"}>
                      <Col xs={4}>
                        <CalendarOutlined style={{ fontSize: 22 }} />
                      </Col>
                      <Col xs={20}>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "flex-start",
                          }}
                        >
                          <Typography.Title level={5}>
                            {" "}
                            Tentative End Date
                          </Typography.Title>

                          <Typography>
                            {" "}
                            {dayjs(currentRecord?.implementation_to).format(
                              "DD/MM/YYYY"
                            )}
                          </Typography>
                        </div>
                      </Col>
                    </Row>
                  </Col>
                )}

                {/* <Col xs={8}>
                                    <Row gutter={[8, 8]} align={"top"}>
                                        <Col xs={6}>
                                            <CalendarOutlined style={{ fontSize: 22 }} />
                                        </Col>
                                        <Col xs={18}>

                                            <div style={{ display: 'flex', flexDirection: "column", alignItems: 'flex-start' }}>
                                                <Typography.Title level={5}> Published Date</Typography.Title>

                                                <Typography> {
                                                    currentRecord?.published_date ?
                                                        dayjs(currentRecord?.published_date).format("DD/MM/YYYY")
                                                        : "-"
                                                }</Typography>

                                            </div>



                                        </Col>

                                    </Row>


                                </Col> */}
                <Col xs={24}>
                  <Row gutter={[8, 8]} align={"top"}>
                    <Col xs={2}>
                      <ContainerOutlined style={{ fontSize: 22 }} />
                    </Col>
                    <Col xs={22}>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "flex-start",
                        }}
                      >
                        <Typography.Title level={5}>
                          {" "}
                          Description
                        </Typography.Title>

                        <Typography>
                          {" "}
                          {currentRecord?.description
                            ? currentRecord?.description
                            : "-"}
                        </Typography>
                      </div>
                    </Col>
                  </Row>
                </Col>
                {currentRecord?.attachments?.length > 0 && (
                  <Col xs={24}>
                    <Row gutter={[8, 8]} align={"top"}>
                      <Col xs={2}>
                        <CloudUploadOutlined style={{ fontSize: 22 }} />
                      </Col>
                      <Col xs={22}>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "flex-start",
                          }}
                        >
                          <Typography.Title level={5}>
                            {" "}
                            Attachment
                          </Typography.Title>

                          {currentRecord?.attachments?.length > 0 &&
                            currentRecord?.attachments.map((element, key) => {
                              return (
                                <div
                                  style={{
                                    alignItems: "center",
                                    display: "flex",
                                    justifyContent: "space-between",
                                    width: "100%",
                                    cursor: "pointer",
                                    background: MainColors.LIGHTBACKCOLOR,
                                    borderRadius: 10,
                                    padding: 10,

                                    ":hover": {
                                      background: MainColors.DARKBACKCOLOR,
                                      color: "white",
                                      // Add any additional styles you want for the hover effect
                                    },
                                  }}
                                  key={key}
                                  onClick={() => FetchFile(element)}
                                >
                                  <div>
                                    <img
                                      src="/file.png"
                                      alt="file"
                                      style={{ height: 50, width: 50 }}
                                    />
                                    {element?.url &&
                                      (element?.url
                                        ?.toString()
                                        .split("-::-"))[1]}
                                  </div>
                                  <BiCloudDownload
                                    style={{ fontSize: 32, color: NewColorTheme.primaryColor }}
                                  />
                                </div>
                              );
                            })}
                        </div>
                      </Col>
                    </Row>
                  </Col>
                )}
                <Col xs={24}>
                  <Row gutter={[8, 8]} align={"top"}>
                    <Col xs={2}>
                      <FileDoneOutlined style={{ fontSize: 22 }} />
                    </Col>
                    <Col xs={22}>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "flex-start",
                        }}
                      >
                        <Typography.Title level={5}> Remarks</Typography.Title>

                        <Typography>
                          {" "}
                          {currentRecord?.reject_reason
                            ? currentRecord?.reject_reason
                            : currentRecord?.publish_reason
                              ? currentRecord?.publish_reason
                              : "-"}
                        </Typography>
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </Modal>

      <ToastContainer />
    </div>
  );
}

export default DonorInterestsView;
