import { Avatar, Button, Card, Col, Row, Space, Typography, Popover, Collapse } from 'antd';
import { UserOutlined, DownCircleOutlined } from '@ant-design/icons';
import { useState } from 'react';
const { Text, Title } = Typography;
const { Panel } = Collapse;

const Tasks = () => {
    const [open, setOpen] = useState(false);
    const handleOpenChange = (newOpen) => {
        setOpen(newOpen);
    };
    const onChange = (key) => {
        console.log(key);
    };

    const Group = ({ color, title, pop }) => (<Collapse onChange={onChange} ghost defaultActiveKey={['1']}
        style={{ borderLeft: `8px solid ${color}`, display: 'flex', alignItems: 'center' }}
        expandIcon={({ isActive }) => <DownCircleOutlined style={{ fontSize: 20, color: color }} rotate={isActive ? 0 : -90} />}>
        <Panel header={<Space><Title level={5} style={{ color: color, margin: 0 }}>{title}</Title><Text style={{ fontSize: 10 }} type='secondary'>03 Items</Text></Space>} key="1" style={{ width: '100%' }}>
            <Space direction='vertical' style={{ width: '100%' }}>
                <Card size='small' style={{ borderLeft: '8px solid #ff8282', background: '#f9f9f9' }}>
                    <Row gutter={16}>
                        <Col className="gutter-row" span={15} style={{ display: 'flex', alignItems: 'center' }}>
                            <Text>Finalize Budget Report</Text>
                        </Col>
                        <Col className="gutter-row" span={3}>
                            <Avatar icon={<UserOutlined />} />
                        </Col>
                        <Col className="gutter-row" span={6}>
                            {!pop ? <Button style={{ background: '#ff8282', border: 'none', color: 'white' }} block>
                                Stuck
                            </Button> : <Popover
                                content={<div>
                                    <Space direction='vertical'>
                                        <Text>Due: 2023-03-05</Text>
                                        <Text>Change Status</Text>
                                        <Space>
                                            <Button style={{ background: '#ff8282', border: 'none', color: 'white' }} block>
                                                Stuck
                                            </Button>
                                            <Button style={{ background: '#08de78', border: 'none', color: 'white' }} block>
                                                Ongoing
                                            </Button>
                                            <Button style={{ background: '#ffc61d', border: 'none', color: 'white' }} block>
                                                Pending
                                            </Button>
                                        </Space>
                                    </Space>
                                </div>}
                                title="Finalize Budget Report"
                                trigger="click"
                                open={open}
                                onOpenChange={handleOpenChange}
                                placement="bottom"
                            >
                                <Button style={{ background: '#ff8282', border: 'none', color: 'white' }} block>
                                    Stuck
                                </Button>
                            </Popover>}
                        </Col>
                    </Row>
                </Card>
                <Card size='small' style={{ borderLeft: '8px solid #9ECF8D', background: '#f9f9f9' }}>
                    <Row gutter={16}>
                        <Col className="gutter-row" span={15} style={{ display: 'flex', alignItems: 'center' }}>
                            <Text>Finalize Budget Report 2021</Text>
                        </Col>
                        <Col className="gutter-row" span={3}>
                            <Avatar icon={<UserOutlined />} />
                        </Col>
                        <Col className="gutter-row" span={6}>
                            <Button style={{ background: '#9ECF8D', border: 'none', color: 'white' }} block>
                                Ongoing
                            </Button>
                        </Col>
                    </Row>
                </Card>
                <Card size='small' style={{ borderLeft: '8px solid #ffc61d', background: '#f9f9f9' }}>
                    <Row gutter={16}>
                        <Col className="gutter-row" span={15} style={{ display: 'flex', alignItems: 'center' }}>
                            <Text>Finalize Budget Report 2020</Text>
                        </Col>
                        <Col className="gutter-row" span={3}>
                            <Avatar icon={<UserOutlined />} />
                        </Col>
                        <Col className="gutter-row" span={6}>
                            <Button style={{ background: '#ffc61d', border: 'none', color: 'white' }} block>
                                Pending
                            </Button>
                        </Col>
                    </Row>
                </Card>
            </Space>
        </Panel>
    </Collapse>);

    return (
        <Space direction='vertical' style={{ width: '100%' }}>
            <Group color={'#82b1ff'} title={"Lorem Ipsum is simply dummy text of the printing"} pop />
            <Group color={'#ffc282'} title={"Measure2"} />
            <Group color={'#66d6a2'} title={"Measure3"} />
            <Group color={'#b275e5'} title={"Measure4"} />
        </Space>
    )
};

export default Tasks;