import { Table, Button, Typography, Form, Descriptions, Divider, Alert, Tag, Space, Select, Drawer, Input, Upload, Radio } from "antd";
import { LinkOutlined, PlusOutlined, InboxOutlined } from '@ant-design/icons';
import { useEffect, useState } from "react";
import 'react-quill/dist/quill.snow.css';
import { addDonorInterests, getDonorInterests } from "../../api/index.js";
import PageLayouts from "../../Components/PageLayouts/index.jsx";
import CustomTable from "../../Components/Table/index.js";
import ReactQuill from 'react-quill';

const DonorRequests = () => {
    const [selected, setSelected] = useState(null);
    const [open, setOpen] = useState(false);
    const [filtered_data, setFilteredData] = useState([]);
    const [interests, setInterests] = useState([]);

    const fetchInterests = () => {
        getDonorInterests().then((out) => setInterests(out.data.out))
    }
    const [form] = Form.useForm();
    const showDrawer = () => {
        setOpen(true);
    };
    const onClose = () => {
        setOpen(false);
        fetchInterests();
    };
    const [requiredMark, setRequiredMarkType] = useState('optional');
    const onRequiredTypeChange = ({ requiredMarkValue }) => {
        setRequiredMarkType(requiredMarkValue);
    };
    const [description, setDescription] = useState('');
    const normFile = (e) => {
        console.log('Upload event:', e);
        if (Array.isArray(e)) {
            return e;
        }
        return e?.fileList;
    };

    const submit = (val) => {
        console.log(val);
        addDonorInterests(val).then(() => (console.log('')))
    }
    useEffect(() => { fetchInterests(); }, []);
    const filterData = (val) => {
        // const itms = data.filter((rec) => {
        //     return rec.article_id === Number(val) || !val
        // });
        // console.log(itms); setFilteredData(itms);
    }

    const columns = [
        // {
        //     title: 'ID',
        //     dataIndex: 'id',
        //     key: 'id'
        // },
        {
            title: 'Subject',
            dataIndex: 'subject',
            key: 'subject',
        },
        {
            title: 'Measure',
            dataIndex: 'measure',
            key: 'measure',
        },
        {
            title: 'Summary',
            dataIndex: 'summary',
            key: 'summary',
        },
        {
            title: 'Description',
            dataIndex: 'description',
            key: 'description',
        },
        {
            title: 'Attachment',
            dataIndex: 'attachment',
            key: 'attachment',
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
        },



    ];
    const [value, setValue] = useState(1);
    const onChange = (e) => {
        console.log('radio checked', e.target.value);
        setValue(e.target.value);
    };

    return <>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <PageLayouts
                formTitle="All Donor Requests"
                tableTitle="All Requests"
                imageSrc="/images/marketing-company.png"
                formSide={
                    <div></div>
                }
                tableSide={
                    <CustomTable
                        columns={columns}
                        data={interests}
                        pagination={{
                            pageSize: 10,
                            total: filtered_data.length,
                        }}
                    />
                }
            />



        </div>
    </>

}

export default DonorRequests;