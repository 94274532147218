import { Segmented, Select, Space, Typography } from 'antd';
import { useState } from 'react';


export const progressStatus = {
    CRITICAL: "critical2",
    NORMAL: "normal2",
    INTERMEDIATE: "intermediate2"
}

const NewProgressChart = ({ startData, endDate, leftText, rightText, progress }) => {

    const classSwitch = () => {
        if (progress > 60) {
            return progressStatus.NORMAL;
        }
        if (progress >= 30) {
            return progressStatus.INTERMEDIATE;
        }
        return progressStatus.CRITICAL;

    }

    return (
        <div className='progress-content-wrapper2'>
            <div className='start-text2'>{startData}</div>
            <div className='progress-chrat-wrapper2' >
                <div className='left-text-content2'>{leftText} <span className={`progress-val ${progress < 30 ? "critical-color" : ""}`}>{`${progress}%`}</span></div>
                <div className={`progress2 ${classSwitch()}`} style={{ width: `${progress}%` }}></div>
                <div className='right-text-content2'>{rightText + " days"}</div>
            </div>
            <div className='end-text'>{endDate}</div>
        </div>

    );
}

export default NewProgressChart;
