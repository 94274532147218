import { Card, Col, Row, Typography } from 'antd'
import React, { useEffect } from 'react'
import './dashboardStat.css'
import BarChart from './components/Articles/BarChart';
import MeasureChart from './components/Measures/MeasureChart';
import { useState } from 'react';
import { getDashBoardStats } from '../../../../../api';
import NewCalender from '../../../CalanderComponent/NewCalender';
import CalenderCard from './components/Calender/CalenderCard';

function DashBoardStats({ user_level }) {

    const [cardData, setCardData] = useState(null)
    const [shouldAnimate, setShouldAnimate] = useState(false);


    useEffect(() => {
        setShouldAnimate(true);
        loadData()

    }, [])

    const loadData = async () => {
        let agency
        let userLevel = await window.localStorage.getItem('ntfc_level')
        if (userLevel == 6 || userLevel == 7 || userLevel == 3) {
            agency = 0
        } else {
            agency = window.localStorage.getItem('ntfc_agency')
        }

        getDashBoardStats(agency).then((out) => {
            console.log("Stats", out)
            setCardData(out.data.out)


        });

    }

    return (
        <div className='db-stat-card'>

            <Row gutter={[8, 8]} justify={"space-around"}>
                <Col xs={12} lg={12}>
                    <Card className='StatCard-db'>
                        <BarChart count={cardData ? cardData.articles : 0} data={cardData ? cardData.graph.map(Number) : []} />

                    </Card>

                </Col>
                <Col xs={12} lg={12}>
                    <Card className='StatCard-db'>
                        <MeasureChart count={cardData ? cardData.measures : 0} data={cardData ? cardData.grid : []} />
                    </Card>

                </Col>
                {/* <Col xs={12} lg={8}>
                    <Card className='StatCard-db' style={{ cursor: "pointer" }} onClick={() => {
                        window.location.href = '/newcalender'
                    }}>
                        {/* <CalenderCard />
                    </Card>

                </Col> */}

            </Row>




        </div>
    )
}

export default DashBoardStats