import { Alert, Card, Col, Row, Space, Tooltip, Typography } from 'antd';
import { UserOutlined, ArrowLeftOutlined } from '@ant-design/icons';
import { Button, Form, Input, message } from 'antd';
import { useState } from 'react';
import './forgot2.styles.css';
import { requestPasscode, updatePassword } from '../../api';
import { useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
const { Title, Text } = Typography;
const passwordRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?])(?=.*[a-zA-Z]).{8,}$/gm;

function NewIndex() {
  const [sent, setSent] = useState(true);
  const [form] = Form.useForm();

  const [requiredMark, setRequiredMarkType] = useState('optional');
  const onRequiredTypeChange = ({ requiredMarkValue }) => {
    setRequiredMarkType(requiredMarkValue);
  };

  const [email, setEmail] = useState("");

  const [messageApi, contextHolder] = message.useMessage();
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const email = urlParams.get('email');

    if (email) {
      // Perform the necessary operations with the email
      // For example, update state or make an API call
      console.log('Email:', email);
      setEmail(email)
      form.setFieldValue('email', email)
    }
  }, []);

  const onFinish = (values) => {
    values.email = email;

    if (!passwordRegex.test(values.password) && !passwordRegex.test(values.password_conf)) {
      messageApi.open({
        type: 'error',
        content: "Incorrect password format. Please read the given guidlines and enter again",
        style: {
          marginTop: '30vh',
          height: '100vh',
          fontSize: '20px'
        },
        duration: 3
      });
      return;
    }
    console.log("setup password", values)
    updatePassword(values).then((out) => {
      console.log("Out-->>", out)
      if (out.data.status == 200) {
        toast.success(out.data ? out.data.message : "", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        setTimeout(() => {
          window.location.href = '/login'
        }, 2000);
      } else {

        toast.error(out.data ? out.data.message : '', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    })
  };

  return (
    <div className="container2" >
      <div className="container2"
        style={{
          position: 'relative',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center', height: "100vh",
          width: "100%"
        }}>
        <video
          src={'/back.webm'}
          controls={false}
          autoPlay
          loop
          muted
          style={{ width: "100%", height: "100%", objectFit: 'cover', }}

        />

      </div>
      {contextHolder}
      <div className="overlay"></div>
      <Card className='no-padding2'
        style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          backgroundColor: 'rgb(5 21 56 / 61%)',
          width: '40vw',
          border: '0px',
          height: 'max-content',
          padding: '0px',
          borderRadius: '10px'
        }}
      >
        <Row gutter={16}>
          {/* <Col span={12}>
            <div style={{ padding: 25 }}>
              <img src="/ntfc.png" width={125} alt="logo" />
              <Typography.Title level={3} style={{ color: "#2B52B4", margin: 0 }}>Implementation Progress</Typography.Title>
              <Typography.Title level={3} style={{ color: "#2B52B4", margin: 0 }}>Monitoring Tool</Typography.Title>
              <p style={{ textAlign: 'justify' }}>
                The National Committee on Trade Facilitation (NCTF) in Sri Lanka was established on 3 June 2014 and
                was formalized by the Ministers of Cabinet of the Government of Sri Lanka on 26 April 2016, as
                required by Article 23.2 of the WTO Trade Facilitation Agreement (TFA).
              </p>
              <Button style={{ borderRadius: 30, color: "#2B52B4", border: '2px solid #2B52B4', backgroundColor: "white", width: "50%" }} type='default' href='/signup' size='large'><b>Register Now</b></Button>
            </div>
          </Col> */}
          <Col span={24}>

            <div
              style={{
                textAlign: 'center',
                padding: '25px 100px',
                height: 'max-content',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                gap: 15,
                background: "white",
                borderRadius: "10px",


              }}>
              <Form
                form={form}
                layout="vertical"
                onFinish={onFinish}
                style={{ display: 'flex' }}
              >

                <div className='forgot-form2'>
                  {sent ? <Space direction='vertical' className='forgot-width2'>
                    <Title level={2}>Setup Password</Title>

                    <Alert message="Please provide the code that was sent to your registered email address." type="info" showIcon />

                    <Form.Item required name='passcode' rules={[{
                      required: true,
                      message: <p>Code is Required</p>,
                    }]}>
                      <Input type='number' placeholder="Code" size="large" style={{ borderRadius: "10px" }} maxLength={4} />
                    </Form.Item>
                    <Tooltip placement="right" title="
                              Passwords must contain at least 8 characters, 1 number, 1 uppercase letter, 1 lowercase letter and 1 special character (@, #, $, %, etc.)
                              ">
                      <Form.Item required name="password" rules={[{
                        required: true,
                        message: <p>Password is Required</p>,
                      }]}>
                        <Input.Password type='password' placeholder="New Password" size="large" style={{ borderRadius: "10px" }} />
                      </Form.Item>
                    </Tooltip>
                    <Typography style={{ fontSize: 10 }}> Passwords must contain at least 8 characters, 1 number, 1 uppercase letter, 1 lowercase letter and 1 special character (@, #, $, %, etc.)
                    </Typography>
                    <Tooltip placement="right" title="
                              Passwords must contain at least 8 characters, 1 number, 1 uppercase letter, 1 lowercase letter and 1 special character (@, #, $, %, etc.)
                              ">
                      <Form.Item
                        dependencies={['password']}
                        hasFeedback
                        required name="password_conf" rules={[{
                          required: true,
                          message: <p>Password Confirmation is Required</p>,

                        },
                        ({ getFieldValue }) => ({
                          validator(_, value) {
                            if (!value || getFieldValue('password') === value) {
                              return Promise.resolve();
                            }
                            return Promise.reject(new Error('The two passwords that you entered do not match!'));
                          },
                        }),

                        ]}>

                        <Input.Password type='password' placeholder="Confirm Password" size="large" style={{ borderRadius: "10px" }} />
                      </Form.Item>
                    </Tooltip>
                    <Form.Item>

                      {/* <Button shape='circle' size="large" style={{ marginRight: "20px", backgroundColor: "#FAFAFF" }} onClick={() => setSent(false)}>
                        <ArrowLeftOutlined />
                      </Button> */}
                      <Button type='primary' style={{ width: "100%" }} htmlType='submit' size="large"><b>Setup Password</b></Button>

                    </Form.Item>
                  </Space> :

                    <Space direction='vertical' className='forgot-width2'>
                      <Space direction="vertical">
                        <Title style={{ color: "#0a225a", fontSize: 30 }}>Forgot Password</Title>

                      </Space>
                      <div style={{ margin: "15px 0" }}>
                        <img className='forgot-img2' src='/forgot-password.png' alt='forgot' />
                      </div>

                      <Form.Item required name="email"
                        rules={[{
                          type: 'email',
                          message: <p style={{ marginLeft: "12px" }}>Invalid Email</p>,
                        }, {
                          required: true,
                          message: <p>Email is Required</p>,
                        }]}
                      >
                        <Input type='email' placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)} style={{ width: '100%', borderRadius: 10 }} size="large" suffix={<UserOutlined style={{ color: "#D7D3D5" }} />} />
                      </Form.Item>
                      <Form.Item>

                        {/* <a href='/login'>
                          <Button shape='circle' size="large" style={{ marginRight: "20px", backgroundColor: "#FAFAFF" }}>
                            <ArrowLeftOutlined />
                          </Button></a> */}
                        <Button type='primary' style={{ width: '100%', borderRadius: 10 }} htmlType="submit" size="large" onClick={() => {
                          if (email) {
                            requestPasscode({
                              email: email
                            }).then((out) => {
                              console.log(out);
                              messageApi.open({
                                type: out.status == 200 ? 'success' : 'error',
                                content: out.message,
                                style: {
                                  marginTop: '30vh',
                                  height: '100vh',
                                  fontSize: '20px'
                                },
                                duration: 3
                              });
                            })
                            setSent(true);
                          }

                        }}><b>Setup  Password</b></Button>


                      </Form.Item>
                      <Space>
                        {<a href='/login' style={{ textAlign: "center" }}><b>Back to Login</b></a>}
                      </Space>
                    </Space>}
                </div>
              </Form>
            </div>

          </Col>
        </Row>
      </Card>
      <ToastContainer />
    </div>




    // <div className="container">
    //   {contextHolder}
    //   <Form
    //     form={form}
    //     layout="vertical"
    //     onFinish={onFinish}
    //     style={{ display: 'flex' }}
    //   >
    //     <div className='side'>
    //       <img className='forgot-img2' src='/forgot.png' alt='forgot' />
    //     </div>
    //     <div className='side'>
    //       <div className='forgot-form'>
    //         {sent ? <Space direction='vertical' className='forgot-width2'>
    //           <Title level={4}>Change Password</Title>
    //           <Form.Item label="Code" required name='passcode'>
    //             <Input type='number' placeholder="Code" />
    //           </Form.Item>

    //           <Space style={{ width: "30vw", fontSize: ".5em", textAlign: "justify" }}>
    //             <Text type="danger">Password must be at least <b>8 characters long</b> and contain at least one <b>UPPERCASE letter</b>, one <b>lowercase letter</b>, one <b>number</b>, and one <b>special character</b></Text>
    //           </Space>
    //           <Form.Item label="New Password" required name="password">
    //             <Input type='password' placeholder="New Password" />
    //           </Form.Item>
    //           <Form.Item label="Confirm Password" required name="password_conf">
    //             <Input type='password' placeholder="Confirm Password" />
    //           </Form.Item>
    //           <Form.Item>
    //             <Space>
    //               <a href='/signup'><Button shape='round'>Cancel</Button></a>
    //               <Button type='primary' shape='round' htmlType='submit'>Change Password</Button>
    //             </Space>
    //           </Form.Item>
    //         </Space> : <Space direction='vertical' className='forgot-width2'>
    //           <Title level={4}>Change Password</Title>
    //           <Text type="secondary">Please enter your email address to send the password recovery code</Text>
    //           <Form.Item label="Email" required name="email">
    //             <Input type='email' placeholder="input email" value={email} onChange={(e) => setEmail(e.target.value)} />
    //           </Form.Item>
    //           <Form.Item>
    //             <Space>
    //               <a href='/signup'><Button shape='round'>Cancel</Button></a>
    //               <Button type='primary' shape='round' onClick={() => {
    //                 requestPasscode({
    //                   email: email
    //                 }).then((out) => {
    //                   console.log(out);
    //                   messageApi.open({
    //                     type: 'success',
    //                     content: 'Passcode sent',
    //                   });
    //                 })
    //                 setSent(true);
    //               }}>Send Code</Button>
    //             </Space>
    //           </Form.Item>
    //         </Space>}
    //       </div>
    //     </div>
    //   </Form>
    // </div >
  );
}

export default NewIndex;
