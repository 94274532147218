import React from "react";
import {
  Card,
  Col,
  Divider,
  Row,
  Button,
  ConfigProvider,
  Typography,
} from "antd";
import { NewColorTheme } from "../../appConst";

export default function TabLayout({ title, formSide, table, addButton }) {
  return (
    <div
      style={{
        position: "absolute",
        height: "75vh",
        width: "100%",
        padding: "20px 26px 0px 36px",
        overflowX: "hidden",
        display: "flex",
        overflowY: "hidden",
      }}
    >
      <Row gutter={[16, 16]}>
        {formSide && (
          <Col xs={24} lg={6}>
            <Card
              style={{
                background: "#f5f9ff",
                height: "100%",
                overflow: "auto",
                overflowX: "hidden",
                display: "flex",
              }}
            >
              <Col span={24}>
                <div style={{ paddingTop: "10px", paddingLeft: "10px" }}>
                  <Typography.Title style={{ color: NewColorTheme.primaryColor }} level={3}>
                    {title}
                  </Typography.Title>
                </div>
                <div
                  className="custom-scroll-div"
                  style={{
                    overflow: "auto",
                    height: "50vh",
                    padding: "10px",
                  }}
                >
                  {formSide}
                </div>
              </Col>
            </Card>
          </Col>
        )}
        <Col xs={24} lg={formSide ? 18 : 24}>
          <Card className="full-card">{table}</Card>
        </Col>
      </Row>
    </div>
  );
}
