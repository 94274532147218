import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { GetDonorGraphData } from '../../../../api';
import { Empty, Popover, Segmented, Tooltip } from 'antd';
import './MainCard.css'
const DonorGraphNew = ({ report }) => {

    const [donorGraphData, setDonorGraphData] = useState([]);
    const [donorGraphDataInt, setDonorGraphDataInt] = useState([]);
    const [maxReq, setMaxReq] = useState(0);
    const [maxInt, setMaxInt] = useState(0);
    const [selected, setSelected] = useState('Table view');
    const [tblView, setTblView] = useState([]);
    useEffect(() => {
        GetDonorGraphData(0).then((out) => {
            if (out.data && out.data.out) {
                setDonorGraphData(out.data.out.request_arr)
                let rmax = 0;
                out.data.out.request_arr.forEach((r) => {
                    let maxCount = 0;
                    r.measures.forEach((m) => { maxCount += m.req_len })
                    if (maxCount > rmax) {
                        rmax = maxCount;
                    }
                })
                setMaxReq(rmax);
                setDonorGraphDataInt(out.data.out.interest_arr)
                let imax = 0;
                out.data.out.interest_arr.forEach((r) => {
                    let maxCount = 0;
                    r.measures.forEach((m) => { maxCount += m.int_len })
                    if (maxCount > imax) {
                        imax = maxCount;
                    }
                })
                setMaxInt(imax);
                const measure_numbers = [...new Set(out.data.out.tableView.map(item => item.measure_no))];
                let article = null;
                let article_index = null;
                const articles = [];
                const article_data = [];
                out.data.out.tableView.forEach((x) => {
                    if (x.article_no !== article) {
                        article_index = articles.length;
                        articles.push(x.article_no);
                        article = x.article_no;
                        article_data.push(Array(measure_numbers.length).fill([0, 0]));
                    }
                    const m_idx = measure_numbers.findIndex((val) => val === x.measure_no);
                    article_data[article_index][m_idx] = [x.requests, x.interests];
                });
                console.log("ARTICLE TBL VIEW", article_data);
                const tbl_view = [];
                const header_row = [""];
                const name_row = ["Measure No"];
                const data_rows = [];
                articles.forEach((a) => { header_row.push(`Article ${a}`); name_row.push("R"); name_row.push("I"); });
                measure_numbers.forEach((m, ind) => {
                    const data_row = [m];
                    article_data.forEach((a) => {
                        data_row.push(a[ind][0]);
                        data_row.push(a[ind][1]);
                    })
                    data_rows.push(data_row);
                });
                tbl_view.push(header_row);
                tbl_view.push(name_row);
                tbl_view.push(data_rows);
                setTblView(tbl_view);
            }
        })
    }, []);

    const content = (measure, article) => {
        return <div>
            <h4 style={{ background: '#0492C2', textAlign: 'center', color: 'white' }}>{article}.{measure.measure_no} {measure.measure_name}</h4>
            <ul>
                {measure.requests.map((req) => {
                    return <li>{req.subject}</li>
                })}
            </ul>
        </div>
    };

    const contentInterest = (measure, article) => {
        return <div>
            <h4 style={{ background: '#0492C2', textAlign: 'center', color: 'white' }}>{article}.{measure.measure_no} {measure.measure_name}</h4>
            <ul>
                {measure.interests.map((req) => {
                    return <li>{req.subject}</li>
                })}
            </ul>
        </div>
    };

    const getStyle = ({ height = 1, index = 0 }) => {
        return { display: 'flex', width: '100%', height: height * (500 / maxReq), background: index % 2 == 0 ? '#0047AB' : '#6495ED', justifyContent: 'center', alignItems: 'center', padding: 10, color: 'white', fontSize: 16 };
    }

    const getStyleInt = ({ height = 1, index = 0 }) => {
        return { display: 'flex', width: '100%', height: height * (500 / maxInt), background: index % 2 == 0 ? '#0047AB' : '#6495ED', justifyContent: 'center', alignItems: 'center', padding: 10, color: 'white', fontSize: 16 };
    }

    return <div style={{ padding: 25 }}>
        <Segmented block options={['Table view', 'Requests count graph', 'Interest count graph']} style={{ width: 500, margin: 'auto' }} onChange={(val) => setSelected(val)} />
        <div style={{ display: 'flex', flexDirection: 'column' }}>
            {selected === 'Table view' ?
                ((tblView && tblView.length) > 0 ?
                    <>
                        <table className='bordered' style={{ marginTop: 10 }}>
                            <tr>{tblView[0].map((h, idx) => <th className='bordered' colSpan={2}>{h}</th>)}</tr>
                            <tr>{tblView[1].map((h, idx) => idx === 0 ? <th className='bordered' colSpan={2}>{h}</th> : <th className='bordered'>{h}</th>)}</tr>
                            {tblView.length > 2 ? tblView[2].map((itm, index) => <tr>{itm.map((h, idx) => idx === 0 ? <td colSpan={2} style={{ textAlign: 'center' }} className='bordered'>{h}</td> : <td style={{ textAlign: 'center', background: idx % 2 == 0 ? 'rgb(16, 142, 233)' : 'rgb(153, 216, 245)', color: idx % 2 == 0 ? 'white' : '#454545' }} className='bordered'>{h}</td>)}</tr>) : null}
                        </table>
                        <div style={{ display: 'flex', justifyContent: 'center' }}><b>R&nbsp;</b> - Requests&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<b>I&nbsp;</b> - Interest</div>
                    </>
                    : null)
                : (selected === 'Requests count graph' ?
                    <>
                        {donorGraphData && !donorGraphData.every(element => element.measures.every(item => item.requests.length === 0)) ?
                            <>
                                <div style={{ color: '#b1b1b1' }}>No of Interests/ No of Requests</div>

                                <div style={{ display: 'flex', height: 600 }}>
                                    <div style={{ height: 'calc(100% - 98px)', width: 30, marginRight: 10, borderRight: '1px solid #b1b1b1', textAlign: 'right', paddingRight: 5 }}>
                                        {Array.from({ length: maxReq }, (_, i) => i + 1).map((item) => {
                                            return <div style={{ height: `${(1 / maxReq) * 100}%` }}>
                                                {maxReq == 1 ? (maxReq - item + 1) : (maxReq - item + 1) % 2 == 0 ? (maxReq - item + 1) : ""}
                                            </div>
                                        })}
                                    </div>
                                    <div style={{ width: "100%", display: "flex", flexDirection: "column" }}>

                                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'flex-end', height: 600 }}>
                                            {donorGraphData.map((item) => {
                                                return <div style={{ display: 'flex', flexDirection: 'column', padding: '0 5px', width: `calc(100%/${donorGraphData.length})` }}>
                                                    {item.measures.map((me, ind) => {
                                                        return me.req_len > 0 ? <Popover content={content(me, item.article_no)} placement='right'>
                                                            <div style={getStyle({ height: me.req_len, index: ind })}>{item.article_no}.{me.measure_no}</div>
                                                        </Popover> : null
                                                    })}
                                                    <div style={{ borderTop: '1px solid #b1b1b1', width: '100%', height: 70, overflow: 'hidden' }}>
                                                        <Tooltip title={`${item.article_no}. ${item.article}`} placement='bottom'>
                                                            <div style={{ width: '100%', overflow: 'hidden', height: 20, display: 'flex', justifyContent: 'center', fontWeight: '600', whiteSpace: "nowrap" }}>Article {item.article_no}</div>
                                                            {/* <div style={{ width: '100%', overflow: 'hidden', height: 50, textOverflow: 'ellipsis', display: 'flex', justifyContent: 'center' }}><span style={{ fontSize: 10, textAlign: 'center' }}>{item.article.substring(0, 25)}</span></div> */}
                                                        </Tooltip>
                                                    </div>
                                                </div>
                                            })}
                                        </div>
                                        <div style={{ width: "100%", display: "flex", justifyContent: "center", color: '#b1b1b1', fontSize: 18 }}> Article</div>

                                    </div>
                                </div>
                            </>
                            :
                            <Empty description={"No Data Available"} />
                        }

                    </>

                    :

                    <>
                        {donorGraphDataInt && !donorGraphDataInt.every(element => element.measures.every(item => item.interests.length === 0)) ?
                            <>
                                <div style={{ color: '#b1b1b1' }}>No of Interests/ No of Requests</div>

                                <div style={{ display: 'flex', height: 600 }}>
                                    <div style={{ height: 'calc(100% - 98px)', width: 30, marginRight: 10, borderRight: '1px solid #b1b1b1', textAlign: 'right', paddingRight: 5 }}>
                                        {Array.from({ length: maxInt }, (_, i) => i + 1).map((item) => {
                                            return <div style={{ height: `${(1 / maxInt) * 100}%` }}>
                                                {maxInt == 1 ? (maxInt - item + 1) : (maxInt - item + 1) % 2 == 0 ? (maxInt - item + 1) : ""}

                                            </div>
                                        })}
                                    </div>
                                    <div style={{ width: "100%", display: "flex", flexDirection: "column" }}>

                                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'flex-end', height: 600 }}>
                                            {donorGraphDataInt.map((item) => {
                                                return <div style={{ display: 'flex', flexDirection: 'column', padding: '0 5px', width: `calc(100%/${donorGraphDataInt.length})` }}>
                                                    {item.measures.map((me, ind) => {
                                                        return me.int_len > 0 ? <Popover content={contentInterest(me, item.article_no)} placement='right'>
                                                            <div style={getStyleInt({ height: me.int_len, index: ind })}>{item.article_no}.{me.measure_no}</div>
                                                        </Popover> : null
                                                    })}
                                                    <div style={{ borderTop: '1px solid #b1b1b1', width: '100%', height: 70, overflow: 'hidden' }}>
                                                        <Tooltip title={`${item.article_no}. ${item.article}`} placement='right'>
                                                            <div style={{ width: '100%', overflow: 'hidden', display: 'flex', justifyContent: 'center', fontWeight: '600', whiteSpace: "nowrap" }}>Article {item.article_no}</div>
                                                            {/* <div style={{ width: '100%', overflow: 'hidden', display: 'flex', justifyContent: 'center' }}><span style={{ fontSize: 10, textAlign: 'center' }}>{item.article}</span></div> */}
                                                        </Tooltip>
                                                    </div>
                                                </div>
                                            })}
                                        </div>
                                        <div style={{ width: "100%", display: "flex", justifyContent: "center", color: '#b1b1b1', fontSize: 18 }}> Article</div>
                                    </div>
                                </div>
                            </>
                            :
                            <Empty description={"No Data Available"} />}


                    </>

                )
            }
        </div>
    </div>;
};

export default DonorGraphNew;