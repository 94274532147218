import { Card, Col, Row, Tag, Typography } from 'antd'
import React, { useState } from 'react'
import { EyeOutlined, PlusSquareOutlined } from "@ant-design/icons";
import './techUserActionCard.css'
import { useEffect } from 'react';
import { getActionCardData, getActionForUser } from '../../../../../api';
import CountUp from 'react-countup';
import dayjs from 'dayjs';

function TechUserActionCard({ user_level, agency }) {


    useEffect(() => {
        setShouldAnimate(true);
        if (agency) {
            loadData()
        }

    }, [agency])


    const [cardData, setCardData] = useState(null)
    const [shouldAnimate, setShouldAnimate] = useState(false);
    const [userActions, setActions] = useState()


    useEffect(() => {
        setShouldAnimate(true);

        if (agency) {
            console.log("AGE", agency)
        } else {
            console.log("UNDEFINED AGENCY________________________________")
        }

        if (agency) {
            loadData()
        }

    }, [])

    const loadData = async () => {
        getActionCardData(agency).then((out) => {

            setCardData(out.data.out)

        });
        getActionForUser({}).then((out) => {
            setActions(out.data.out)
            console.log("Action data", out)

        });

    }


    return (
        <Card style={{ cursor: "pointer" }} className='action-card' onClick={() => {
            window.location.href = "/requests"
        }}>
            <Row >
                <Col xs={24} lg={13}>
                    <div className='tech-action-left-side-div'>

                        <div style={{ cursor: "pointer" }} className='action-plan-div' onClick={(e) => {
                            e.stopPropagation()
                            window.location.href = "/tasksactionplan"

                        }}>

                            <Typography className='action-plan-navigate-text'>
                                Action Plan
                                Management
                            </Typography>


                            <div style={{ display: "flex", justifyContent: "end", gap: 5 }}>
                                {user_level == 5 &&

                                    <PlusSquareOutlined style={{ fontSize: 25, color: "white" }} />
                                }

                                <EyeOutlined style={{ fontSize: 25, color: "white" }} />
                            </div>



                        </div>
                        <div className='tech-action-count-div'>
                            {userActions?.map((element, index) => {
                                return <div key={index} className='techAction-status'>
                                    <Typography>{element.article_no + "." + element.measure_no + "." + element.action_no}</Typography>
                                    <Tag color={element.status === 0 ? "gray"
                                        : element.status === 1 ? "orange"
                                            : element.status === 2 ? "green"
                                                : element.status === 3 ? "brown"
                                                    : "red"} >
                                        {element.status === 0 ? "To Do"
                                            : element.status === 1 ? "Inprogres"
                                                : element.status === 2 ? "Completed"
                                                    : element.status === 3 ? "Delayed"
                                                        : "Missed"

                                        }</Tag>
                                    <Typography>{dayjs(element.delivery_date).format("DD/MM/YYYY")}</Typography>

                                </div>
                            })}



                        </div>

                    </div>

                </Col>
                <Col xs={24} lg={11} style={{ display: "flex", justifyContent: "center" }}>
                    <div className='action-right-side-div'>
                        <Row gutter={[8, 8]} justify={"space-around"}>
                            <Col span={12}>
                                <div style={{ border: `0.5px solid #AED8E6` }}
                                    className='tech-action-tile-new'
                                >
                                    <span style={{ color: `#AED8E6` }} className='card-count-new' > {shouldAnimate && <CountUp end={cardData ? cardData.todo : 0} duration={2} />}</span>
                                    <span style={{ color: `#AED8E6` }} className='card-count-content' >{"To-Do"}</span>
                                </div>

                            </Col>
                            <Col span={12}>

                                <div style={{ border: `0.5px solid #0492C2` }}
                                    className='tech-action-tile-new'
                                >
                                    <span style={{ color: `#0492C2` }} className='card-count-new' > {shouldAnimate && <CountUp end={cardData ? cardData.inprogress : 0} duration={2} />}</span>
                                    <span style={{ color: `#0492C2` }} className='card-count-content' >{"In Progress"}</span>
                                </div>
                            </Col>
                            <Col span={12}>

                                <div style={{ border: `0.5px solid #2f69b3` }}
                                    className='tech-action-tile-new'
                                >
                                    <span style={{ color: `#2f69b3` }} className='card-count-new' > {shouldAnimate && <CountUp end={cardData ? cardData.delayed : 0} duration={2} />}</span>
                                    <span style={{ color: `#2f69b3` }} className='card-count-content' >{"Delayed"}</span>
                                </div>
                            </Col>
                            <Col span={12}>

                                <div style={{ border: `0.5px solid #4169E1` }}
                                    className='tech-action-tile-new'
                                >
                                    <span style={{ color: `#4169E1` }} className='card-count-new' > {shouldAnimate && <CountUp end={cardData ? cardData.missed : 0} duration={2} />}</span>
                                    <span style={{ color: `#4169E1` }} className='card-count-content' >{"To-Do"}</span>
                                </div>
                            </Col>
                            <Col span={24}>

                                <div style={{ border: `0.5px solid #1520A6` }}
                                    className='tech-action-tile-new'
                                >
                                    <span style={{ color: `#1520A6` }} className='card-count-new' > {shouldAnimate && <CountUp end={cardData ? cardData.complete : 0} duration={2} />}</span>
                                    <span style={{ color: `#1520A6` }} className='card-count-content' >{"Completed"}</span>
                                </div>
                            </Col>

                        </Row>

                    </div>


                </Col>

            </Row>

        </Card>
    )
}

export default TechUserActionCard