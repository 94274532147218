import { Button, Card, Col, Row, Space, Typography, Input } from "antd";
import { useEffect, useState } from "react";
import { getPublishedInquiries } from "../../../api";
import { DownloadFiles } from "../../../api";

const FaqPanel = () => {

    const [inquiries, setInquiries] = useState([]);
    const [inquiriesFiltered, setInquiriesFiltered] = useState([]);
    const [inquiry, setInquiry] = useState(null);

    useEffect(() => {
        getPublishedInquiries().then((res) => {
            if (res && res.data && res.data.out) {
                setInquiries(res.data.out);
                setInquiriesFiltered(res.data.out);
            }
        })
    }, []);

    const formatDate = (str) => {
        const dmy = ((str.split('T'))[0]).split('-')
        return `${dmy[2]}/${dmy[1]}/${dmy[0]}`
    }

    const filterInquiries = (str) => {
        const filtered = inquiries.filter((inq) => inq.topic.toString().includes(str));
        setInquiriesFiltered([...filtered]);
    }

    const selectTopic = (item) => {
        if (inquiry && inquiry.id === item.id) {
            setInquiry(null);
        } else {
            setInquiry(item);
        }
    }

    const FetchFile = async (file) => {
        let out = await DownloadFiles(file.token)

        let blob = new Blob([out.data], { type: file.type });
        let url = window.URL.createObjectURL(blob);

        let link = document.createElement('a');
        link.href = url;

        link.download = (file.url.split("-::-"))[1];

        document.body.appendChild(link);
        link.click();
    }

    return <div style={{ position: 'absolute', top: '15vh', height: '85vh', width: '100%', padding: '2% 5%' }}>
        <Row gutter={16} style={{ height: '100%' }}>
            <Col span={8} style={{ height: '100%' }}>
                <Card style={{ height: 'calc(85vh - 2%)', maxHeight: '100%', overflow: 'auto' }} >
                    <div style={{ display: 'flex', alignItems: 'center', marginBottom: 20 }}>
                        <div style={{ padding: '5px 10px', color: 'white', background: '#88a8b8', marginRight: 5, width: '30%', textAlign: 'center' }} >Inquiry</div>
                        <Input.Search placeholder="Search" onSearch={filterInquiries} style={{ width: '100%' }} />
                    </div>
                    {inquiriesFiltered.map((inq) => <div className={inquiry && inquiry.id === inq.id ? "faq-search-item-selected" : "faq-search-item"} onClick={() => selectTopic(inq)}>
                        <Typography.Title style={{ color: '#005476', margin: 0 }} level={5}>{inq.topic}</Typography.Title>
                    </div>)}
                </Card>
            </Col>
            <Col span={16} style={{ overflow: 'auto', height: '100%' }}>
                <Card className="full-card" style={{ height: 'calc(85vh - 2%)', overflow: 'auto', maxHeight: '100%' }} >
                    {inquiry ? <div style={{ padding: 20 }}>
                        <div style={{ display: 'flex', alignItems: 'center', marginBottom: 20 }}>
                            <div style={{ padding: '5px 10px', color: 'white', background: '#88a8b8', marginRight: 5, width: '25%' }} >TITLE</div>
                            <hr style={{ background: '#005476', height: 1, width: '75%', border: 'none' }} />
                        </div>
                        <Typography.Title style={{ color: '#005476', marginBottom: 15 }} level={5}>{inquiry.topic}</Typography.Title>
                        <div style={{ display: 'flex', alignItems: 'center', marginBottom: 10 }}>
                            <div style={{ padding: '5px 10px', color: 'white', background: '#88a8b8', marginRight: 5, width: '25%' }} >DESCRIPTION</div>
                            <div style={{ padding: '5px 10px', color: '#005476', marginRight: 5, width: '25%', border: '1px solid #005476' }} >
                                Date: {formatDate(inquiry.created_at)}
                            </div>
                            <div style={{ padding: '5px 10px', color: '#005476', marginRight: 5, width: '35%', border: '1px solid #005476', whiteSpace: 'nowrap', overflow: 'hidden' }} >
                                Created By: {inquiry.name}
                            </div>
                            <hr style={{ background: '#005476', height: 1, width: '15%', border: 'none' }} />
                        </div>
                        <Typography.Text style={{ color: '#005476' }}>{inquiry.description}</Typography.Text>
                        <div style={{ display: 'flex', alignItems: 'center', marginBottom: 10, marginTop: 10 }}>
                            <div style={{ padding: '5px 10px', color: 'white', background: '#88a8b8', marginRight: 5, width: '25%' }} >ATTACHMENTS</div>
                            <hr style={{ background: '#005476', height: 1, width: '75%', border: 'none' }} />
                        </div>
                        {inquiry.files.map((file) => {
                            return <div onClick={() => FetchFile(file)}>
                                {file.type.includes("image") ?
                                    <img src="/image.png" alt="image" style={{ height: 50, width: 50 }} />
                                    : <img src="/file.png" alt="file" style={{ height: 50, width: 50 }} />}
                                {(file.url.toString().split("-::-"))[1]}
                            </div>
                        })}
                        <hr />
                        {inquiry.responses.map((res) => <>
                            <div style={{ display: 'flex', alignItems: 'center', marginBottom: 10 }}>
                                <div style={{ padding: '5px 10px', color: 'white', background: '#88a8b8', marginRight: 5, width: '25%' }} >ANSWER</div>
                                <div style={{ padding: '5px 10px', color: '#005476', marginRight: 5, width: '25%', border: '1px solid #005476' }} >
                                    Date: {formatDate(res.created)}
                                </div>
                                <div style={{ padding: '5px 10px', color: '#005476', marginRight: 5, width: '35%', border: '1px solid #005476', whiteSpace: 'nowrap', overflow: 'hidden' }} >
                                    Answered By: {res.username}
                                </div>
                                <hr style={{ background: '#005476', height: 1, width: '15%', border: 'none' }} />
                            </div>
                            <Typography.Text style={{ color: '#005476' }}>{res.content}</Typography.Text>
                            <div style={{ display: 'flex', alignItems: 'center', marginBottom: 10, marginTop: 10 }}>
                                <div style={{ padding: '5px 10px', color: 'white', background: '#88a8b8', marginRight: 5, width: '25%' }} >ATTACHMENTS</div>
                                <hr style={{ background: '#005476', height: 1, width: '75%', border: 'none' }} />
                            </div>
                        </>)}
                    </div> : null}
                </Card>
            </Col>
        </Row>
    </div>
}

export default FaqPanel;