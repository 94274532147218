import { Badge, Col, Empty, Radio, Row, Skeleton } from 'antd'
import React, { useEffect, useState } from 'react'
import ProgressChart from '../../../../../Components/DashBoard/ProgressChart'
import { GetAgencyWiseAllArticles } from '../../../../../api';

function ArticleCompletionProgressCharts({ presidentView = false, height, val = 0 }) {

    const [articleCompletion, setArticleCompletion] = useState();
    const [filteredData, setFilteredData] = useState()
    const [value, setValue] = useState(4);
    const [loading, setLoading] = useState(true)
    useEffect(() => {

        setLoading(true)
        if (val == "dashboard") {

            // if (window.localStorage.getItem('ntfc_level') == 6 || window.localStorage.getItem('ntfc_level') == 7) {
            getGraphData(0);
            // } else {
            //     getGraphData(window.localStorage.getItem('ntfc_agency'));
            // }

        } else {
            loadData()
        }



    }, [val]);




    const loadData = async () => {
        getGraphData(val);
    }

    const getGraphData = async (agency) => {

        // Articles
        await GetAgencyWiseAllArticles(agency).then((out) => {
            setLoading(true)
            setArticleCompletion(out.data.out.sort((a, b) => a.article_no - b.article_no))
            setFilteredData(out.data.out)

        })
        setLoading(false)
    }

    const getLength = (length) => {
        if (length <= 4) {
            return 12
        } else if (length <= 6) {
            return 12
        } else {
            return 6
        }
    }

    useEffect(() => {
        console.log("value ---", value)
        let filtered = [];
        if (articleCompletion && articleCompletion.length > 0) {
            if (value == 1) {
                filtered = articleCompletion.filter(element => element.article_completion < 30);

            } else if (value == 2) {
                filtered = articleCompletion.filter(element => element.article_completion >= 30 && element.article_completion < 60);

            } else if (value == 3) {
                filtered = articleCompletion.filter(element => element.article_completion >= 60);
            } else {
                filtered = articleCompletion
            }
        }


        setFilteredData(filtered)
    }, [value])


    useEffect(() => {
        setLoading(true)
    }, [])

    return (
        <>
            {!loading ?

                (articleCompletion?.length > 0 ?
                    <div>
                        <div style={{ marginTop: 15, display: "flex", justifyContent: "center", width: "100%" }}>

                            <Col xs={24} style={{ textAlign: "center" }}>
                                <Radio.Group onChange={(e) => {
                                    e.stopPropagation()
                                    setValue(e.target.value)
                                }

                                } value={value}

                                >
                                    <Radio value={1}><Badge key={"#aed8e6"} color={"#aed8e6"} />{" ( < 30% )"}</Radio>
                                    <Radio value={2}><Badge key={"#0492c2"} color={"#0492c2"} />{" ( 30% - 60% )"}</Radio>
                                    <Radio value={3}><Badge key={"#1520a6"} color={"#1520a6"} />{" ( > 60% )"}</Radio>
                                    <Radio value={4}>All</Radio>


                                </Radio.Group>
                            </Col>

                        </div>
                        <div
                            className='custom-scroll-div'
                            style={{
                                marginTop: 5,
                                paddingRight: "20px",
                                overflowX: "hidden",
                                overflow: "auto",
                                height: height ? height : "55vh"
                            }}>
                            <Row gutter={[0, 0]} >

                                {filteredData?.length > 0 ? filteredData?.map((item, index) => (
                                    <Col span={24} className="bar-chart-col" key={index} >
                                        <ProgressChart
                                            presidentView={presidentView}
                                            article={item}
                                            articleNo={item.article_no}
                                            title={item.article}
                                            rightText={Math.floor(item.article_completion) + "%"}
                                            progress={item.article_completion}
                                        />
                                    </Col>
                                )) :
                                    <div style={{
                                        width: "100%",
                                        height: "100%",
                                        textAlign: "center"
                                    }}>
                                        <Empty
                                            image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
                                            imageStyle={{
                                                height: 60,
                                            }}
                                            description={
                                                <span>
                                                    No Articles Available
                                                </span>
                                            }
                                        >

                                        </Empty>
                                    </div>

                                }

                            </Row>
                        </div>
                    </div>

                    :
                    <Empty
                        image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
                        imageStyle={{
                            height: 60,
                        }}
                        description={
                            <span>
                                No Articles Available
                            </span>
                        }
                    >

                    </Empty>)
                :
                <div style={{ minWidth: "30em" }}>
                    <Skeleton
                        active
                        avatar
                        paragraph={{
                            rows: 2,
                        }}
                    />
                </div>


            }
        </>
    )
}

export default ArticleCompletionProgressCharts