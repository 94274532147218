import { List, Typography, Input, Space, Button, message, Tooltip, Form, Select, Row, Col, DatePicker, ConfigProvider, Table, Modal, Divider, Checkbox, Tag, Card, FloatButton } from 'antd';
import { SettingOutlined, ToolOutlined, ControlOutlined, DeploymentUnitOutlined, LoadingOutlined, BarsOutlined, BankOutlined, CalendarOutlined, PieChartOutlined, HeatMapOutlined, ContainerOutlined, BlockOutlined, PlusOutlined, CloseOutlined, EditOutlined, EyeOutlined } from '@ant-design/icons';
import { Fragment, useEffect, useState } from 'react';
import { addMeasure, getMeasuresList, updateMeasure, getArticles, getNextMeasureNo } from '../../api';
import { MEASURE_CATEGORIES } from '../../constants';
import moment from 'moment';
import PageLayouts from '../../Components/PageLayouts';
import CustomTable from '../../Components/Table';
import dayjs from 'dayjs';
import { ToastContainer, toast, Zoom } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import FormLayouts from '../DonorsNew/Components/FormLayouts';
import CardTitle from '../../Components/ViewDialogCardTitle/CardTitle';
import FullSearch from '../../Components/FullSearch';
import { NewColorTheme } from '../../appConst';


const AddMeasures = ({ article }) => {
    const [items, setItems] = useState([]);

    const [data, setData] = useState([]);
    const [filtered_data, setFilteredData] = useState([]);
    const [full, setFull] = useState([]);
    const [articles, setArticles] = useState([]);
    const [defDate, setDefDate] = useState(undefined);
    const [exDefDate, setExDefDate] = useState(undefined);
    const [selectedArticle, setSelectedArticle] = useState(null);

    const [selected, setSelected] = useState(null);
    const [selectedDate, setSelectedDate] = useState();
    const [viewOnly, setViewOnly] = useState(false);
    const [summary, setSummary] = useState("");
    const [description, setDescription] = useState("");
    const [reason, setReason] = useState("");
    const [currentRecord, setCurrentRecord] = useState({})
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [optional, ShowOptional] = useState(false);
    const [measureName, setMeasureName] = useState(null)
    const [showError, setError] = useState(false)
    const [form] = Form.useForm();
    const [btnDisable, setDisable] = useState(true)
    const [isChair, setChair] = useState(false)
    const [loadingMeasureNumber, setLoadingMeasureNumber] = useState(false)




    const isFieldsTouched = () => {
        return form.isFieldsTouched();
    };

    const showModal = () => {
        setIsModalOpen(true);
    };
    const handleOk = () => {
        setIsModalOpen(false);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };






    const columns = [
        {
            title: 'Measure No',
            dataIndex: 'measure_no',
            key: 'measure_no',
            width: 100,
            render: (_, record) => (

                record ?
                    <div style={{ textAlign: 'center' }}>

                        <div>
                            {record.article_no + "." + record.measure_no}
                        </div>
                    </div>
                    : null
            ),

        },

        {
            title: 'Measure',
            dataIndex: 'measure_name',
            key: 'measure_name',
            width: isChair ? 800 : 400,
            render: (_, { measure_name }) => (
                <div style={{ textAlign: "justify" }}>
                    {measure_name}
                </div>
            ),

        },
        // {
        //     title: 'Summary',
        //     dataIndex: 'summary',
        //     key: 'summary',

        // },
        {
            title: () => <div style={{ textAlign: 'center' }}>Category</div>,

            dataIndex: 'category',
            key: 'category',
            align: "center",
            render: (_, { category }) => (
                <div style={{ textAlign: 'center' }}>
                    {category === "A" ? <Tag color={"#99d8f5"}>A</Tag> : category === "B" ? <Tag color="#2db7f5">B</Tag> : <Tag color="#108ee9">C</Tag>}
                </div>
            ),
        },
        {
            title: 'Definitive Date',
            dataIndex: 'def_date',
            key: 'def_date',
            width: 120,
            render: (_, { def_date, extended_def_date }) => (
                <div style={{ textAlign: 'center', display: "flex", gap: 5 }}>


                    <div>
                        {extended_def_date ? dayjs(extended_def_date).format('DD/MM/YYYY') : dayjs(def_date).format('DD/MM/YYYY')}
                    </div>
                    <div style={{ textAlign: 'center', width: "100%" }}>
                        {extended_def_date && <Tag color="volcano">
                            E
                        </Tag>}
                    </div>
                </div>


            ),

        },

        {
            title: 'Action',
            key: 'action',
            width: 120,
            render: (_, record, i) => (

                record && !record.moreData ?
                    <div>
                        {!isChair &&
                            <Tooltip title="Update Measure">
                                <Button disabled={record.selectedItem} type="secondary" size='small' onClick={() => {

                                    const updatedData = filtered_data.map((item, index) => ({
                                        ...item,
                                        selectedItem: index === i ? true : false,
                                    }));
                                    setFilteredData(updatedData);
                                    setLoadingMeasureNumber(false)
                                    if (selected != record) {
                                        form.resetFields();
                                        setSummary("")
                                        setDescription("")
                                        setMeasureName("")
                                    }


                                    setDisable(true)
                                    setViewOnly(false);

                                    setError(false)


                                    setDefDate(dayjs(record.def_date))
                                    form.setFieldValue("def_date", record.def_date)
                                    if (record.extended_def_date) {
                                        setExDefDate(record.extended_def_date)
                                        form.setFieldValue("extended_def_date", record.extended_def_date)
                                        ShowOptional(true)
                                    } else {
                                        ShowOptional(false)
                                    }
                                    form.setFieldsValue('summary', record.summary)
                                    form.setFieldsValue('measure_name', record.measure_name)
                                    setSummary(record.summary)
                                    setMeasureName(record.measure_name)
                                    // record.def_date = def_date
                                    console.log("measure", record)

                                    setSelected(record)

                                }} shape="circle" icon={<EditOutlined style={{ color: '#946e6e' }} />} />
                            </Tooltip>
                        }

                        <Tooltip title="View Measure">
                            <Button type="secondary" size='small' onClick={() => {
                                setFilteredData(filtered_data.map((ele) => ({ ...ele, selectedItem: false })))

                                setSelected(null);
                                setViewOnly(false);
                                setSummary("")
                                setSummary("")
                                setDescription("")
                                setDefDate(undefined)
                                setExDefDate(undefined)
                                form.resetFields()
                                form.resetFields();
                                setDefDate(undefined)
                                setSummary("")
                                setMeasureName("")
                                setDescription("")
                                setCurrentRecord(record)
                                showModal(true)
                                ShowOptional(false)
                                console.log("Record", record)

                            }} shape="circle" icon={<EyeOutlined style={{ color: 'darkgreen' }} />} />
                        </Tooltip>
                    </div>
                    : null
            ),
        },
    ];

    const refreshMeasures = () => {
        setDisable(true)
        getMeasuresList().then((out) => {
            const data = out.data.out.map((item) => {
                return {
                    ...item,
                    date_formatted: item.extended_def_date ? dayjs(item.extended_def_date).format('DD/MM/YYYY') : dayjs(item.def_date).format('DD/MM/YYYY'),
                    measure_formatted: item.article_no + "." + item.measure_no,
                    selectedItem: false
                }
            })
            setItems(data);
        });
    }



    const handleArticleOnchange = async (val) => {

        setLoadingMeasureNumber(true)
        form.resetFields(['measure_no']);
        setSelectedArticle(val);
        let measureNo
        await getNextMeasureNo(val).then((out) => {
            measureNo = parseInt(out.data.out[0].id) ?? 1


        });


        form.setFieldValue('measure_no', measureNo);
        setLoadingMeasureNumber(false)
    }




    const filterData = (val) => {
        console.log(data);
        const itms = data.filter((rec) => {
            console.log(rec.article_id, val);
            return rec.article_id === Number(val) || !val
        });
        console.log(itms); setFilteredData(itms);
    }

    const onFinish = (values) => {

        if (defDate) {
            setError(false)
            console.log('values', values)
            values.def_date = defDate;
            if (optional) {
                values.extended_def_date = exDefDate
            }
            values.exDefDate = exDefDate

            if (!selected) {
                let val = data.filter((element) => element.article_id === values.article_id).some((obj) => obj.measure_no === values.measure_no)



                if (val === true) {
                    toast.error("Measure no alredy exists!", {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    });
                } else {
                    addMeasure(values).then((out) => {
                        console.log("out", out)
                        form.resetFields(); refreshMeasures();
                        setSummary("")
                        setMeasureName("")
                        setDescription("")
                        setDefDate(undefined)
                        setExDefDate(undefined)
                        setDisable(true)
                        if (out.data.status === 200) {
                            toast.success("The measure has been successfully added",
                                {
                                    position: "top-right",
                                    autoClose: 5000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    progress: undefined,
                                    theme: "colored",
                                }
                            );
                            ShowOptional(false)
                        } else {
                            toast.error(out.data.message,
                                {
                                    position: "top-right",
                                    autoClose: 5000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    progress: undefined,
                                    theme: "colored",
                                }
                            );
                        }


                    })
                }

            } else {
                console.log('valuesss', values)
                values.id = selected.id;

                if (isFieldsTouched()) {
                    updateMeasure(values).then((out) => {
                        form.resetFields(); refreshMeasures();
                        setSummary("")
                        setMeasureName("")
                        setDescription("")
                        setSelected(null)
                        setDefDate(undefined)
                        setExDefDate(undefined)
                        ShowOptional(false)
                        setDisable(true)
                        if (out.data.status === 200) {
                            toast.success("The measure has been successfully updated", {
                                position: "top-right",
                                autoClose: 5000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                                theme: "colored",
                            }
                            );
                        } else {
                            toast.error(out.data.message,
                                {
                                    position: "top-right",
                                    autoClose: 5000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    progress: undefined,
                                    theme: "colored",
                                }
                            );
                        }


                    })

                } else {
                    toast.warning("There are no changes to update the measure", {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    })
                    form.resetFields(); refreshMeasures();
                    setSummary("")
                    setMeasureName("")
                    setDescription("")
                    setSelected(null)
                    setDefDate(undefined)
                    setExDefDate(undefined)
                    ShowOptional(false)
                }

            }
        } else {
            setError(true)
        }

    };
    const onFinishFailed = (errorInfo) => {
        console.log("error info", errorInfo)
        if (!defDate) {
            setError(true)
        }



    };

    const onDateChange = (date, dateString) => {
        if (dateString) {
            setError(false)
            console.log(dateString);
            setDefDate(dateString);
        } else {
            setError(true)

        }

    };
    const onDateChange2 = (date, dateString) => {
        console.log(dateString);
        setExDefDate(dateString);
    };

    useEffect(() => {
        if (window.localStorage.getItem('ntfc_level') == 6) {
            setChair(true)
        } else {
            setChair(false)
        }
        getArticles().then((art) => {
            setArticles(art.data.out.sort((a, b) => (a.article_no - b.article_no)));
        })
        refreshMeasures()
    }, []);

    useEffect(() => {
        setData(items);
        setFilteredData(items);
        setFull(items);
    }, [items]);

    useEffect(() => {
        if (selected && selected != {}) {
            let def_date = `${new Date().getFullYear()}-${new Date().getMonth() + 1}-${new Date().getDate()}`;
            if (selected.def_date) {
                def_date = `${new Date(selected.def_date).getFullYear()}-${new Date(selected.def_date).getMonth() + 1}-${new Date(selected.def_date).getDate()}`;
            }

            let ex_def_date = `${new Date().getFullYear()}-${new Date().getMonth() + 1}-${new Date().getDate()}`;
            if (selected.extended_def_date) {
                ex_def_date = `${new Date(selected.def_date).getFullYear()}-${new Date(selected.extended_def_date).getMonth() + 1}-${new Date(selected.extended_def_date).getDate()}`;
            }

            console.log("selected", selected)
            form.setFieldValue('article_id', selected.article_id)
            form.setFieldValue('measure_no', selected.measure_no)
            form.setFieldValue('measure_name', selected.measure_name)
            form.setFieldValue('def_date', def_date)
            form.setFieldValue('summary', selected.summary)
            form.setFieldValue('extended_def_date', ex_def_date)
            form.setFieldValue('reason', selected.reason)
            form.setFieldValue('category', selected.category)
            form.setFieldValue('description', selected.description)
            setDescription(selected.description)
            setReason(selected.reason)
        } else {
            console.log("call---->>", selected)
            form.resetFields();
            setError(false)
        }
        // eslint-disable-next-line
    }, [selected]);

    useEffect(() => {
        if (selectedArticle) {
            filterData(selectedArticle);
        }
        // eslint-disable-next-line
    }, [selectedArticle]);




    return <Fragment>

        <FormLayouts
            formTitle={"Add Measure"}
            title="Measures"
            formSide={
                !isChair ?
                    <Form
                        form={form}
                        layout="vertical"
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                        scrollToFirstError
                        onValuesChange={(changedValues, allValues) => {
                            if (changedValues) {
                                setDisable(false)
                            } else {
                                setDisable(true)
                            }

                        }}
                    >

                        <Form.Item
                            label="Article"

                            name="article_id" required rules={[{
                                required: true,
                                message: <p style={{ marginLeft: "12px" }}>Article Required</p>,
                            }]} >
                            <Select
                                placeholder="Article"
                                options={articles}
                                onChange={(val) => handleArticleOnchange(val)}
                                disabled={viewOnly ? true : false}
                                style={{ backgroundColor: viewOnly ? "#dbdbdb" : "", borderRadius: "5px" }}
                            />
                        </Form.Item>

                        <Form.Item
                            label="Measure No"
                            name="measure_no" required rules={[{
                                required: true,
                                message: <p style={{ marginLeft: "12px" }}>Measure No is required</p>,
                            }
                                , {
                                pattern: /^[1-9]\d{0,2}$/,
                                message: 'Measure No must be a positive number up to three digits',
                            },
                            ]}>
                            <Input type='number' placeholder="Measure No"
                                disabled={viewOnly ? true : false}
                                style={{ backgroundColor: viewOnly ? "#dbdbdb" : "", borderRadius: "5px" }} />
                        </Form.Item>
                        {loadingMeasureNumber && <span style={{ color: NewColorTheme.primaryColor }}>Generating measure number <LoadingOutlined /></span>}

                        <Form.Item
                            label="Measure Summary"
                            name="summary" required rules={[{
                                required: true,
                                message: <p style={{ marginLeft: "12px" }}>Measure summary is required</p>,
                            },
                            {
                                pattern: /^\S.*$/,
                                message: (
                                    <p style={{ marginLeft: "12px" }}>
                                        Invalid measure summary
                                    </p>
                                ),
                            },

                            ]}



                        >
                            <Input maxLength={100} rows={3} placeholder='Measure  Summary'
                                showCount
                                // value={summary}
                                disabled={viewOnly ? true : false}
                                style={{ backgroundColor: viewOnly ? "#dbdbdb" : "", borderRadius: "5px" }}
                            // onChange={(e) => { setSummary(e.target.value); form.setFieldValue('summary', e.target.value) }}
                            />
                            {/* <div>
                            <p style={{ float: "right", color: "black" }}>{summary?.length}/40</p>
                        </div> */}
                        </Form.Item>

                        <Form.Item
                            label="Measure"
                            name="measure_name" required rules={[{
                                required: true,
                                message: <p style={{ marginLeft: "12px" }}>Measure is required</p>,
                            },
                            {
                                pattern: /^\S.*$/,
                                message: (
                                    <p style={{ marginLeft: "12px" }}>
                                        Invalid measure name
                                    </p>
                                ),
                            },]}>
                            <Input.TextArea type='text' placeholder="Measure"
                                maxLength={100}
                                showCount
                                // value={measureName}
                                disabled={viewOnly ? true : false}
                                style={{ backgroundColor: viewOnly ? "#dbdbdb" : "", borderRadius: "5px" }}
                            // onChange={(e) => { setMeasureName(e.target.value); form.setFieldValue('measure_name', e.target.value) }}
                            />
                            {/* <div>
                            <p style={{ float: "right", color: "black" }}>{measureName?.length}/100</p>
                        </div> */}
                        </Form.Item>

                        <Form.Item
                            label="Category"
                            name="category" required rules={[{
                                required: true,
                                message: <p style={{ marginLeft: "12px" }}>Category is required</p>,
                            }]}>
                            <Select
                                placeholder="Select Category"
                                options={MEASURE_CATEGORIES}
                                disabled={viewOnly ? true : false}
                                style={{ backgroundColor: viewOnly ? "#dbdbdb" : "", borderRadius: "5px" }} />
                        </Form.Item>

                        <Form.Item
                            trigger="none"
                            label="Definitive Date"
                            name="def_date" required rules={[

                                //     {
                                //     required: true,
                                //     message: <p style={{ marginLeft: "12px" }}>Definitive Date is required</p>,
                                // }

                            ]} valuePropName='date'>
                            <DatePicker onChange={onDateChange} defaultValue={defDate ? dayjs(defDate) : undefined} format={'DD/MM/YYYY'}

                                disabled={viewOnly ? true : false}
                                style={{ width: "100%", backgroundColor: viewOnly ? "#dbdbdb" : "", borderRadius: "5px" }} />
                        </Form.Item>
                        {showError && <span style={{ color: "red" }}>Definitive Date is required</span>}

                        <Form.Item
                            label="Description"
                            name="description">
                            <Input.TextArea rows={4} placeholder="Description" maxLength={500} showCount
                                disabled={viewOnly ? true : false}
                                style={{ backgroundColor: viewOnly ? "#dbdbdb" : "", borderRadius: "5px" }}

                            />
                            {/* <div>
                            <p style={{ float: "right", color: "white" }}>{description?.length}/200</p>
                        </div> */}
                        </Form.Item>

                        <Checkbox checked={optional} onChange={(e) => {
                            e.target.checked ? ShowOptional(true) : ShowOptional(false)
                        }}>Add an extended definitive date</Checkbox>


                        {optional && <>
                            <Form.Item
                                rules={[{
                                    required: optional ? true : false,
                                    message: <p style={{ marginLeft: "12px" }}>Extended date is required</p>,
                                }]}
                                label="Extended Definitive Date"
                                name="extended_def_date" valuePropName='exdate'>
                                <DatePicker onChange={onDateChange2} format="DD/MM/YYYY" defaultValue={exDefDate ? dayjs(exDefDate) : undefined}
                                    disabledDate={(current) => current && current < dayjs(defDate).startOf('day')}
                                    disabled={viewOnly ? true : false}
                                    style={{ width: "100%", backgroundColor: viewOnly ? "#dbdbdb" : "", borderRadius: "5px" }} />
                            </Form.Item>

                            <Form.Item
                                label="Remarks"
                                name="reason">
                                <Input.TextArea rows={3} type='text' placeholder="Remarks" value={reason}
                                    disabled={viewOnly ? true : false}
                                    style={{ backgroundColor: viewOnly ? "#dbdbdb" : "", borderRadius: "5px" }}

                                    onChange={(e) => { setReason(e.target.value); form.setFieldValue('reason', e.target.value) }}
                                />
                            </Form.Item>
                        </>

                        }

                        {!viewOnly ?

                            !selected ?
                                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                                    <Form.Item>
                                        <Button size='large' type='primary' htmlType="submit" icon={<PlusOutlined />}
                                            disabled={window.localStorage.getItem('view_only') == 1}>
                                            Add Measure
                                        </Button>
                                    </Form.Item>
                                </div> :
                                <div style={{ display: "flex", justifyContent: "flex-end" }}>

                                    <Button
                                        htmlType="button"
                                        size='large' style={{ marginRight: 10 }} type='primary' danger icon={<CloseOutlined />} onClick={() => {

                                            form.resetFields()
                                            setSelected();
                                            setViewOnly(false);
                                            // setDisable(true)
                                            setError(false)
                                            // setSummary("")
                                            setDefDate(undefined)
                                            setExDefDate(undefined)
                                            setMeasureName('')
                                            // setDescription("")
                                            ShowOptional(false)
                                            setFilteredData(filtered_data.map((ele) => ({ ...ele, selectedItem: false })))
                                        }}>
                                        Cancel
                                    </Button>

                                    <Form.Item>
                                        <Button
                                            disabled={window.localStorage.getItem('view_only') == 1 || btnDisable}
                                            size='large' type='primary' htmlType="submit" icon={<EditOutlined />} >
                                            Update
                                        </Button>
                                    </Form.Item>

                                </div>


                            :
                            <div style={{ display: "flex", justifyContent: "flex-end" }}>
                                <Button htmlType='button' size='large' style={{ marginRight: 10 }} type='primary' danger icon={<CloseOutlined />}
                                    onClick={() => {
                                        form.resetFields()
                                        setSelected(false);
                                        setViewOnly(false);
                                        // setDisable(true)
                                        setError(false)
                                        // setSummary("")
                                        setDefDate(undefined)
                                        setExDefDate(undefined)
                                        setMeasureName('')
                                        // setDescription("")
                                        ShowOptional(false)

                                    }}>
                                    Cancel
                                </Button>
                            </div>
                        }

                    </Form> : null

            }
            table={

                <div>
                    <FullSearch full={full} setFiltered={setFilteredData} params={["measure_name", "measure_formatted", "date_formatted"]} />
                    <Table
                        scroll={{
                            y: 400,
                        }}
                        className='custom-table-header custom-table2'
                        columns={columns} dataSource={filtered_data}
                        pagination={{
                            pageSize: 4,
                            total: filtered_data.length,
                            showSizeChanger: false,

                        }} />
                </div>

            }
        />

        <Modal
            closable={true}

            className="custom-modal"
            width={700}
            footer={false}
            onCancel={handleCancel}
            title={
                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                    <div>     <Typography className='custom-modal-title' level={2}>Measure Details</Typography></div>


                </div>


            } open={isModalOpen} onOk={handleOk} centered>

            <div className='custom-modal-body'>
                <Row gutter={[16, 16]}>
                    <Col span={24}>
                        <Card style={{ background: "#a8cccc4a", borderRadius: 5, padding: 12 }}>

                            <div style={{ display: "flex", gap: 10 }}>
                                <div className='no-div' style={{
                                    // marginTop: '-31px',
                                    // position: 'absolute',
                                    // marginLeft: '10px'
                                    display: 'flex',
                                    alignItems: "center",

                                }}>
                                    <Typography className='inner-tile-title'>{"Article" + " " + articles.filter(element => element.value === currentRecord.article_id)[0]?.article_no}</Typography>

                                </div>
                                <Typography className='inner-tile-content' >{articles.filter(element => element.value === currentRecord.article_id)[0]?.article} </Typography>


                            </div>





                        </Card>
                    </Col>
                    <Col span={24}>

                        <Row gutter={16}>

                            <Col span={21} offset={3}>
                                <Card style={{ background: "#a8cccc4a", borderRadius: 5, padding: 12 }}>
                                    <Row gutter={[16, 16]}>
                                        {/* <div style={{
                                            marginTop: '-31px',
                                            position: 'absolute',
                                            marginLeft: '10px'
                                        }}>
                                            <Typography className='inner-tile-title'>{"Measure"}</Typography>
                                        </div> */}
                                        <Col span={24}>
                                            <Row gutter={16}>
                                                <Col span={6}>
                                                    <div style={{ display: "flex", justifyContent: "start", alignItems: "start", height: "100%" }}>
                                                        <span className='inner-tile-title' style={{ background: "#2652523b", padding: "5px 10px", borderRadius: 8, marginLeft: "-2%" }}>
                                                            {"Measure" + " " + currentRecord.article_no + "." + currentRecord.measure_no}

                                                        </span>
                                                    </div>

                                                </Col>
                                                <Col span={18}>
                                                    <div style={{ display: "flex", justifyContent: "start", alignItems: "start", height: "100%" }}>
                                                        <Typography className='inner-tile-content' > {currentRecord.measure_name} </Typography>
                                                    </div>


                                                </Col>

                                            </Row>




                                        </Col>
                                        {/* <Col span={24}>
                                            <CardTitle
                                                icon={<ContainerOutlined />}
                                                title={"Measure"}
                                                content={currentRecord.measure_name}

                                            />

                                        </Col> */}
                                        {/* <Col span={24} style={{
                                            paddingLeft: '8px',
                                            paddingRight: '8px',
                                            alignItems: 'center',
                                            display: 'flex'
                                        }}>
                                            <Typography className='inner-tile-content' >{currentRecord.measure_name} </Typography>

                                        </Col> */}

                                        <Col span={24}>
                                            <CardTitle
                                                icon={<ContainerOutlined />}
                                                title={"Summary"}
                                                content={currentRecord.summary}

                                            />

                                        </Col>
                                        <Col span={24}>
                                            <CardTitle
                                                icon={<PieChartOutlined />}
                                                title={"Category"}
                                                content={currentRecord.category}

                                            />

                                        </Col>
                                        <Col span={24}>
                                            <CardTitle
                                                icon={<CalendarOutlined />}
                                                title={"Definitive Date"}
                                                content={moment(currentRecord.def_date).format('DD/MM/YYYY')}

                                            />

                                        </Col>
                                        {currentRecord.description &&
                                            <Col span={24}>
                                                <CardTitle
                                                    icon={<BarsOutlined />}
                                                    title={"Description"}
                                                    content={currentRecord.description}

                                                />

                                            </Col>
                                        }

                                        {currentRecord.extended_def_date &&
                                            <Col span={24}>
                                                <CardTitle
                                                    icon={<CalendarOutlined />}
                                                    title={"Extended Definitive Date"}
                                                    content={moment(currentRecord.extended_def_date).format('DD/MM/YYYY')}

                                                />

                                            </Col>
                                        }
                                        {currentRecord.reason &&
                                            <Col span={24}>
                                                <CardTitle
                                                    icon={<BarsOutlined />}
                                                    title={"Extended Reason"}
                                                    content={currentRecord.reason}

                                                />

                                            </Col>
                                        }
                                    </Row>
                                </Card>

                            </Col>

                        </Row>

                    </Col>


                </Row>


            </div>



        </Modal>
        {/* <FloatButton.Group

            trigger="click"
            type="primary"
            style={{
                right: 20,
                fontSize: 0, lineHeight: 0
            }}
            icon={<SettingOutlined />}


        >
            <Tooltip color="cyan" placement="left" title="Setup Action Plan">
                <FloatButton onClick={() => {
                    window.location.href = `/actionplan`
                }} icon={<ToolOutlined />} />
            </Tooltip>


            <Tooltip color="cyan" placement="left" title="Setup Actions">
                <FloatButton onClick={() => {
                    window.location.href = `/add-actions`
                }} icon={<ControlOutlined />} />
            </Tooltip>
            <Tooltip color="cyan" placement="left" title="Setup Measures">
                <FloatButton onClick={() => {
                    window.location.href = `/add-measures`
                }} icon={<DeploymentUnitOutlined />} />
            </Tooltip>
            <Tooltip color="cyan" placement="left" title="Setup Articles">
                <FloatButton onClick={() => {
                    window.location.href = `/add-articles`
                }} icon={<BookOutlined />} />
            </Tooltip>
            <Tooltip color="cyan" placement="left" title="Setup Agencies">
                <FloatButton onClick={() => window.location.href = `/add-agencies`} icon={<BankOutlined />} />
            </Tooltip>

        </FloatButton.Group> */}





        <ToastContainer
        />

    </Fragment>
}

export default AddMeasures;