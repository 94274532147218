import { Table, Button, Typography, Image, Form, Descriptions, Divider, Alert, Tag, Space, Select, Drawer, Input, Upload, Radio, ConfigProvider, Row, Col, List, Avatar } from "antd";
import { EyeOutlined } from '@ant-design/icons';
import { useEffect, useState } from "react";
import 'react-quill/dist/quill.snow.css';
import { DownloadFiles, GetDonorRequestInterests, GetPendingAssignedRequests, PublishedPendingAssignedRequests, addDonorInterests, getAgencies, getAgencyList, getDonorInterests, getMeasures, getPublishedDonorRequests } from "../../api/index.js";
import PageLayouts from "../../Components/PageLayouts/index.jsx";
import CustomTable from "../../Components/Table/index.js";
import ReactQuill from 'react-quill';
import VirtualList from 'rc-virtual-list';

import { ToastContainer, toast, Zoom } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import FormLayouts from "./Components/FormLayouts.jsx";
import { getUserData } from "../Donors/getCounts.js";
import CSV from '../../Components/Modal/csv.png'
import EXLS from '../../Components/Modal/excel.png'
import PDF from '../../Components/Modal/pdf.png'
import { NewColorTheme } from "../../appConst.js";

const AllInterests = () => {
    const [selected, setSelected] = useState(null);
    const [open, setOpen] = useState(false);
    const [filtered_data, setFilteredData] = useState([]);
    const [interests, setInterests] = useState([]);
    const [purpose, setPurpose] = useState("All Countries");
    const [agency, setAgency] = useState(null);// selected Agency
    const [agencies, setAgencies] = useState(null);//agencies list from DB
    const [measure, setMeasure] = useState(null);//selected Measure
    const [measures, setMeasures] = useState(null);//Measures list from DB
    const [user, setUser] = useState({})
    const [data, setdata] = useState([])
    const [currentData, setCurrentData] = useState({})



    const DownloadFiles2 = async (file) => {


        let out = await DownloadFiles(file.token)
        console.log("FIle", out)

        // Set the Content-Type header for known file types
        let contentType;
        switch (file.url.split('.').pop()) {
            case 'pdf':
                contentType = 'application/pdf';
                break;
            case 'jpg':
            case 'jpeg':
                contentType = 'image/jpeg';
                break;
            case 'png':
                contentType = 'image/png';
                break;
            case 'xlsx':
                contentType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
                break;
            case 'doc':
            case 'docx':
                contentType = 'application/msword';
                break;
            default:
                contentType = 'text/plain';
                break;
        }

        // Create a URL object from the file data with the Content-Type header
        let blob = new Blob([out], { type: contentType });
        let url = window.URL.createObjectURL(blob);

        // Create a link element
        let link = document.createElement('a');
        link.href = url;

        // Set the download attribute and file name
        link.download = file.title;

        // Append the link element to the document
        document.body.appendChild(link);

        // Trigger a click event on the link element
        link.click();
    }

    function getImageUrl(file) {

        let url
        switch (file.url.split('.').pop()) {
            case 'pdf':
                url = PDF;
                break;
            case 'jpg':
            case 'jpeg':
                url = 'image/jpeg';
                break;
            case 'png':
                url = 'image/png';
                break;
            case 'xlsx':
                url = EXLS;
                break;
            case 'csv':
                url = CSV;
                break;
            case 'doc':
            case 'docx':
                url = 'application/msword';
                break;
            default:
                url = 'text/plain';
                break;
        }

        return url
    }
    const columns = [
        // {
        //     title: 'ID',
        //     dataIndex: 'id',
        //     key: 'id'
        // },
        {
            title: 'Subject',
            dataIndex: 'subject',
            key: 'subject',
        },
        {
            title: 'Measure',
            dataIndex: 'measure_name',
            key: 'measure_name',
        },
        {
            title: 'Summary',
            dataIndex: 'summary',
            key: 'summary',
        },
        {
            title: 'Description',
            dataIndex: 'description',
            key: 'description',
        },
        {
            title: 'Attachment',
            dataIndex: 'attachment',
            key: 'attachment',
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: (_, { status }) => (
                <Tag color={status == 0 ? "#008282" : status == 1 ? "#9B0259" : NewColorTheme.primaryColor} key={status} style={{ width: 120, display: 'flex', justifyContent: 'center' }} >
                    {
                        status == 0 ? "UNPUBLISHED"
                            : status == 1 ? "PUBLISHED" : "ASSIGNED"
                    }
                </Tag>
            ),
        },



    ];


    useEffect(() => {
        getalldonorinterests()
        getUserDetails()

    }, []);

    const getalldonorinterests = async () => {
        let out = await GetPendingAssignedRequests()
        console.log("use-----", out)

        if (out.data.out) {

            setdata(out.data.out)
            setCurrentData(out.data.out[0])


        }
    }

    const assignInterest = async () => {

        let data = {
            id: currentData.id
        }
        let out = await PublishedPendingAssignedRequests(data)
        if (out.status === 200) {
            toast.success(out.data.message, {

                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progressClassName: 'toast-progress-bar',
            });
            getalldonorinterests()

        }
    }

    const getUserDetails = async function () {

        const user = await getUserData()

        setUser(user)
    }

    return <>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <FormLayouts
                title="Donor All Interest"
                formSide={
                    <ConfigProvider
                        theme={{
                            token: {
                                colorPrimary: '#006666',
                            },
                        }}
                    >
                        <Row gutter={[16, 16]}>
                            <Col span={24}>
                                <Typography.Title level={5}>
                                    Subject

                                </Typography.Title>
                                <p style={{ backgroundColor: "white", borderRadius: 10, padding: 10 }}>
                                    {currentData ? currentData.subject ? currentData.subject : "" : ""}
                                </p>

                            </Col>
                            <Col span={24}>
                                <Typography.Title level={5}>
                                    Measure Name

                                </Typography.Title>
                                <p style={{ backgroundColor: "white", borderRadius: 10, padding: 10 }}>
                                    {currentData ? currentData.measure_id ? currentData.measure_id : "" : ""}
                                </p>

                            </Col>

                            <Col span={24}>
                                <Typography.Title level={5}>
                                    Description

                                </Typography.Title>
                                <p style={{ backgroundColor: "white", borderRadius: 10, padding: 10 }}>
                                    {currentData ? currentData.description ? currentData.description : "" : ""}
                                </p>

                            </Col>
                            {/* {currentData?.attachments.length > 0 ?

                                <Col span={24}>
                                    <Typography.Title level={5}>
                                        Attachments

                                    </Typography.Title>
                                    <List>
                                        <VirtualList
                                            data={currentData.attachments}
                                            height={150}
                                            itemHeight={47}
                                            itemKey="id"
                                        // onScroll={onScroll}
                                        >
                                            {(item, key) => (
                                                <List.Item key={key} onClick={() => DownloadFiles2(item)} style={{ cursor: "pointer" }}>
                                                    <List.Item.Meta
                                                        avatar={<Image src={getImageUrl(item)} />}
                                                        title={<p>{item.title}</p>}
                                                        description={"By " + item.first_name + " " + item.last_name}
                                                    />
                                                    <div>{new Date(item.created).toISOString().slice(8, 10) + "/" + new Date(item.created).toISOString().slice(5, 7)}</div>
                                                </List.Item>
                                            )}
                                        </VirtualList>
                                    </List>

                                </Col>

                                :  */}
                            <Col span={24}>
                                <Typography.Text>No Attachments Found</Typography.Text>
                            </Col>


                            {/* } */}

                            <Col span={24}>
                                <div style={{ display: "flex", justifyContent: "end", width: "100%" }}>
                                    <Space>
                                        <Button type="primary" shape="circle" icon={<EyeOutlined />} />
                                        <Button type="primary"
                                            onClick={() => assignInterest()}
                                        >Accept
                                        </Button>
                                    </Space>
                                </div>
                            </Col>

                        </Row>


                    </ConfigProvider>
                }
                table={<Table
                    columns={columns}
                    dataSource={data}
                    onRow={(record, rowIndex) => {

                        return {
                            onClick: () => setCurrentData(record)
                        };
                    }}
                    pagination={{
                        pageSize: 10,
                        total: filtered_data.length,
                    }}
                />}
            />

            <ToastContainer
                transition={Zoom}
                position="top-center"
                autoClose={5000}
                hideProgressBar
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored"
                style={{ top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}
            />


        </div>
    </>

}

export default AllInterests;