import {
  Button,
  Col,
  ConfigProvider,
  Row,
  Space,
  Table,
  Tag,
  Typography,
  List,
  Avatar,
  Modal,
  Input,
  Form,
  Select,
  DatePicker,
  Tooltip,
} from "antd";

import { useEffect, useState } from "react";
import TabLayout from "./TabLayout";
import { ToastContainer, toast, Zoom } from "react-toastify";
import {
  getAllDonorRequests,
  DownloadFiles,
  publishDonorRequest,
  rejectDonorRequest,
  addDonorRequest,
  getAgencies,
  getMyDonorRequests,
  getAgencyMeasures,
  getMeasures,
} from "../../api/index.js";
import {
  CloseOutlined,
  PlusOutlined,
  InboxOutlined,
  EyeOutlined,
  ContainerOutlined,
  CalendarOutlined,
  CloudUploadOutlined,
  CheckOutlined,
  ReconciliationOutlined,
  CalendarFilled,
  FileDoneOutlined,
  BorderlessTableOutlined,
  BoxPlotOutlined,
} from "@ant-design/icons";
import VirtualList from "rc-virtual-list";
import moment from "moment";
import Dragger from "antd/es/upload/Dragger.js";
import { getUserData } from "../Donors/getCounts";
import dayjs from "dayjs";
import { MainColors, NewColorTheme } from "../../appConst";
import { BiCloudDownload } from "react-icons/bi";

const AllDonorRequests = ({ showMessage, setRefreshCounts, setOpen, data, setData, setInitData }) => {
  console.log("Opeennnnnnnnnnn", setOpen);

  const [currentData, setCurrentData] = useState({});
  const [btnLoad, setBtnLoad] = useState(false);
  const [btnLoad2, setBtnLoad2] = useState(false);
  const [rejectDialogOpen, setRejectDialogOpen] = useState(false);
  const [rejectReason, setRejectReason] = useState("");
  const [approveDialogOpen, setApproveDialogOpen] = useState(false);
  const [approveReason, setApproveReason] = useState("");
  const [userData, setUserData] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [addModalView, setAddModelView] = useState(false);
  const [form] = Form.useForm();
  const [subBtnLoad, setLoad] = useState(false);
  const [toDate, setToDate] = useState();
  const [fromDate, setFromDate] = useState();
  const [fileList, setFileList] = useState([]);
  const [measures, setMeasures] = useState(null); //Measures list from DB
  const [assistance, setAssistance] = useState(0);
  const [purpose, setPurpose] = useState(0);
  const [user, setUser] = useState({});
  const [agency, setAgency] = useState(null);
  const [description, setDescription] = useState("");
  const [agencies, setAgencies] = useState(null); //agencies list from DB
  const [interests, setInterests] = useState([]);

  const [ntfcLevel, setNtfcLevel] = useState();
  const [myAgency, setMyAgency] = useState("");
  const [measure, setMeasure] = useState(null);
  const [viewDialog, setViewDialog] = useState(false);
  const [currentRecord, setCurrentRecord] = useState(null);
  const [showError, setError] = useState(false);
  const [errMsg, setErrorMassage] = useState();
  const [isChair, setChair] = useState(false)

  useEffect(() => {

    if (window.localStorage.getItem('ntfc_level') == 6) {
      setChair(true)
    } else {
      setChair(false)
    }
    reloadData();
    // getUserData();
  }, []);

  useEffect(() => {
    setIsModalOpen(setOpen);
    // getUserData();
  }, [setOpen]);

  useEffect(() => {
    setLocalStorageData();
  });

  const setLocalStorageData = async () => {
    setNtfcLevel(await window.localStorage.getItem("ntfc_level"));
  };

  const reloadData = () => {
    getAllDonorRequests().then((res) => {
      var sortedArray = res.data.out.sort(
        (a, b) => new Date(b.created) - new Date(a.created)
      );
      sortedArray.forEach(item => {
        item.measure_name = `${item.article_no}.${item.measure_no} - ${item.measure_name}`;
      });

      setData(sortedArray);
      setInitData(sortedArray);
    });
  };

  const getStatus = (status) => {
    if (status == 0) {
      return (
        <Tag
          color={"#0492c2"}
          key={status}
          style={{ width: 90, display: "flex", justifyContent: "center" }}
        >
          {"PENDING"}
        </Tag>
      );
    } else if (status == 1) {
      return (
        <Tag
          color={"#007613"}
          key={status}
          style={{ width: 90, display: "flex", justifyContent: "center" }}
        >
          {"PUBLISHED"}
        </Tag>
      );
    } else if (status == 2) {
      return (
        <Tag
          color={NewColorTheme.primaryColor}
          key={status}
          style={{ width: 90, display: "flex", justifyContent: "center" }}
        >
          {"ASSIGNED"}
        </Tag>
      );
    } else if (status == 999) {
      return (
        <Tag
          color={"#FF0000"}
          key={status}
          style={{ width: 90, display: "flex", justifyContent: "center" }}
        >
          {"REJECTED"}
        </Tag>
      );
    }
  };

  // const getUserData = async () => {
  //     const user = await getUserData();
  //     setUserData(user);
  // }
  const onFromDateChange = (date, dateString) => {
    setFromDate(dateString);
  };
  const onToDateChange = (date, dateString) => {
    setToDate(dateString);
  };

  const handleCancel = () => {
    setViewDialog(false);
    setError(false);
  };

  const columns = [
    {
      title: "Creation Date",
      dataIndex: "created",
      key: "created",
      width: 90,
      render: (_, { created }) => (
        <p style={{ whiteSpace: "nowrap" }}>{created ? moment(created).format("DD/MM/YYYY") : "-"}</p>
      ),
    },
    {
      title: "Agency Name",
      dataIndex: "agency_name",
      key: "agency_name",
      ellipsis: true,
      width: 170,
      render: (_, { scope, agency_name, agency }) => (
        <p>{agency == -1 ? "National Wise" : agency_name ? agency_name : "-"}</p>
      ),
    },
    {
      title: "Subject",
      dataIndex: "subject",
      key: "subject",
      width: 150,
      ellipsis: true,
    },
    {
      title: "Measure",
      dataIndex: "measure_name",
      key: "measure_name",
      width: 320,
      ellipsis: true,
      // render: (_, { measure_no, article_no, measure_name }) => (
      //   <p>{article_no + "." + measure_no + " - " + measure_name}</p>
      // ),
    },
    {
      title: "Support Type",
      dataIndex: "assistance",
      key: "assistance",
      width: 90,
      render: (_, { assistance }) => (
        <p
          key={assistance}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "flex-start",
          }}
        >
          {assistance == 0 ? <Tag style={{ display: "flex", justifyContent: "center" }} color={NewColorTheme.primaryColor}>TS/CB</Tag> :
            <Tag style={{ display: "flex", justifyContent: "center" }} color="#08B3D7">OTHER</Tag>}
        </p>
      ),
    },
    {
      title: "Tentative Start Date",
      dataIndex: "implementation_from",
      key: "implementation_from",
      width: 90,

      render: (_, { implementation_from }) => (
        <p style={{ whiteSpace: "nowrap" }}>
          {implementation_from
            ? moment(implementation_from).format("DD/MM/YYYY")
            : "-"}
        </p>
      ),
    },
    {
      title: "Tentative End Date",
      dataIndex: "implementation_to",
      key: "implementation_to",
      width: 90,
      render: (_, { implementation_to }) => (
        <p style={{ whiteSpace: "nowrap" }}>
          {implementation_to
            ? moment(implementation_to).format("DD/MM/YYYY")
            : "-"}
        </p>
      ),
    },
    {
      title: "Published Date",
      dataIndex: "published_date",
      key: "published_date",
      width: 90,
      render: (_, { created_agency_id, published_date, created }) => (
        <p style={{ whiteSpace: "nowrap" }}>
          {created_agency_id == 0
            ? moment(created).format("DD/MM/YYYY")
            : published_date
              ? moment(published_date).format("DD/MM/YYYY")
              : "-"}
        </p>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: 100,
      render: (_, { status }) => getStatus(status),
    },
    {
      title: "Action",
      dataIndex: "publish_reason",
      key: "publish_reason",
      width: 80,
      render: (_, record) => (
        <Space size="middle" style={{ display: 'flex', justifyContent: "center" }}>
          <Tooltip title="View">
            <Button
              type="secondary"
              size="small"
              onClick={() => {
                console.log("record", record);
                setCurrentRecord(record);
                setViewDialog(true);
              }}
              shape="circle"
              icon={<EyeOutlined style={{ color: "darkgreen" }} />}
            />
          </Tooltip>
          {/* <Tooltip title="Approve">
                        <Button type="primary" shape="circle" icon={<CheckOutlined />} size={'medium'} style={{ background: record.status === 0 ? '#70D983' : record.status === 2 ? '#70D983' : '#c4c4c4' }}
                            disabled={record.status == 0 ? false : record.status == 2 ? false : true}
                            onClick={() => setApproveDialogOpen(true)} />
                    </Tooltip>
                    <Tooltip title="Reject">
                        <Button type="primary" shape="circle" icon={<CloseOutlined />} size={'medium'} style={{ background: record.status === 0 ? '#FF8282' : record.status === 2 ? '#FF8282' : '#c4c4c4' }}
                            disabled={record.status == 0 ? false : record.status == 2 ? false : true}
                            onClick={() => setRejectDialogOpen(true)} />
                    </Tooltip> */}
        </Space>
      ),
    },
  ];

  const fetchFile = async (file) => {
    let out = await DownloadFiles(file.token);

    let blob = new Blob([out.data], { type: file.type });
    let url = window.URL.createObjectURL(blob);

    let link = document.createElement("a");
    link.href = url;

    link.download = file.url.split("-::-")[1];

    document.body.appendChild(link);
    link.click();
  };
  const props = {
    name: "file",
    multiple: false,
    // action: ,

    onChange(info) {
      console.log("FIle", info);
      const { status } = info.file;
      if (status !== "uploading") {
        console.log(info.file, info.fileList);
      }
      if (status === "done") {
        toast.success(`${info.file.name} file uploaded successfully.`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        handleFileUpload(info.file);
      } else if (status === "error") {
        toast.error(`${info.file.name} file upload failed.`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    },
    onDrop(e) {
      console.log("Dropped files", e.dataTransfer.files);
    },
  };

  async function handleFileUpload(file) {
    let arr = [];
    arr.push(file);
    setFileList(arr);
  }
  const approve = async (value) => {
    setApproveDialogOpen(false);
    setBtnLoad(true);
    console.log("Reason------>>", value)
    const formData = new FormData();
    formData.append("request_file", fileList[0]);
    formData.append("id", currentRecord.id);
    formData.append("reason", form.getFieldValue("reason"));
    // let body = {
    //     id: currentRecord.id,
    //     reason: approveReason
    // }
    // console.log(body)

    let out = await publishDonorRequest(formData);
    console.log("Approval------->>", out)
    if (out.status == 200) {
      toast.success("Donor request has been published successfully", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      setCurrentRecord({});
    } else {
      toast.error("Error on Publishing", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
    setBtnLoad(false);
    setFileList([]);
    reloadData();
    setViewDialog(false);
    setApproveReason("");
  };

  const reject = async (value) => {
    setBtnLoad2(true);

    const formData = new FormData();
    formData.append("request_file", fileList[0]);
    formData.append("id", currentRecord.id);
    formData.append("reason", value.reason);
    // let body = {
    //     id: currentRecord.id,
    //     reason: rejectReason
    // }


    setError(false);

    let out = await rejectDonorRequest(formData);

    if (out.status == 200) {
      toast.success("Donor request has been rejected successfully", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      setCurrentRecord({});
      setRejectDialogOpen(false);
    }

    reloadData();
    setViewDialog(false);
    setRejectReason("");


    setBtnLoad2(false);
  };

  const onFinishFailed = (errorInfo) => {
    toast.error("Request creation unsuccessful", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });
  };

  useEffect(() => {
    getUserDetails();
    refreshRequests();
  }, []);

  const getUserDetails = async function () {
    const user = await getUserData();
    setUser(user);
    console.log("user", user);

    if (window.localStorage.getItem('ntfc_level') == 5) {
      setAgency(user.agency_id);
    }
  };

  const refreshRequests = () => {
    getAgencies().then((res) => {
      const agencyList = [
        {
          value: -1,
          label: "National Wise",
        },
        ...res.data.out,
        {
          value: -2,
          label: "Other",
        },
      ];
      res.data.out.forEach((item, idx) => {
        if (item.value == window.localStorage.getItem("ntfc_agency")) {
          setMyAgency(item.label);
        }
      });
      console.log(agencyList);
      setAgencies(agencyList);
    });

    console.log("---------------", agency);
    if (agency && agency.value > -1) {
      loadMeasures(agency.value);
    }

    getMyDonorRequests().then((out3) => {
      setInterests(out3.data.out);
    });
  };

  const loadMeasures = (agencyId) => {
    console.log(agencyId);

    if (agencyId > -1) {
      getAgencyMeasures(agencyId).then((measureRes) => {
        setMeasures(
          measureRes.data.out.map((m) => {
            return { value: m.measure_id, label: m.measure_name };
          })
        );
      });
    } else {
      getMeasures().then((measureRes) => {
        setMeasures(measureRes.data.out);
      });
    }
  };

  const onFinish = (values) => {
    setLoad(true);

    const formData = new FormData();
    formData.append("request_file", fileList);
    formData.append("subject", values.subject);
    formData.append("description", values.description);
    formData.append("measure", values.measure);
    formData.append("assistance", values.assistance);

    formData.append("implementation_from", fromDate);
    formData.append("implementation_to", toDate);

    values["assistance"] = assistance;
    console.log("Agnecy ------------->", agency);

    if (purpose === 2) {
      values["agency"] = agency;
      formData.set("agency", agency);
    } else {
    }

    if (agency == -1) {
      values["agency"] = 999;
      formData.set("agency", 999);
    } else {
      values["agency"] = agency;
      formData.set("agency", agency);
    }

    if (window.localStorage.getItem('ntfc_level') !== 6 && window.localStorage.getItem('ntfc_level') !== 7) {
      values["agency"] = user.agency_id;
      values["scope"] = 2;
      formData.set("agency", user.agency_id);
      formData.set("scope", 2);
      console.log("user---------");
    } else {
      values["scope"] = purpose;
      formData.set("scope", purpose);
    }

    if (agency == "-2") {
      values["scope"] = 2;
      values["agency_name"] = values.agencyName;
      formData.set("scope", 2);
      formData.set("agency_name", values.agencyName);
    }
    if (agency == -1) {
      values["agency"] = 999;
    }

    form.setFieldValue("description", description);

    addDonorRequest(formData)
      .then((out) => {
        if (out.data.status === 200) {
          form.resetFields();
          refreshRequests();
          setDescription("");
          toast.success("Donor Request Created Successfully", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          setLoad(false);
          setFileList([]);
        } else {
          toast.error(out.data.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          setLoad(false);
          setFileList([]);
        }
        reloadData();
      })
      .catch((err) => {
        console.log(err);
        setDescription("");
        toast.error("Error on donor request", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        setLoad(false);
        setFileList([]);
      });
  };

  return (
    <div className="custom-scroll-div tab-layout-div">
      <TabLayout
        title="Request Details"
        // formSide={
        //     <div>
        //         {currentData.id &&
        //             <div className='list-container'>

        //                 <Row gutter={[16, 16]}>
        //                     <Col span={24}>
        //                         <Row style={{ width: '100%' }}>
        //                             <Col style={{ width: '30%' }}>
        //                                 <Typography.Title level={5}>
        //                                     Subject
        //                                 </Typography.Title>
        //                             </Col>
        //                             <Col style={{ paddingTop: '2px' }}>
        //                                 <Typography.Paragraph style={{ fontWeight: '500' }}>
        //                                     {currentData.subject}
        //                                 </Typography.Paragraph>
        //                             </Col>
        //                         </Row>
        //                     </Col>

        //                     <Col span={24}>
        //                         <Row style={{ width: '100%' }}>
        //                             <Col style={{ width: '30%' }}>
        //                                 <Typography.Title level={5}>
        //                                     Measure
        //                                 </Typography.Title>
        //                             </Col>
        //                             <Col style={{ paddingTop: '2px' }}>
        //                                 <Typography.Paragraph style={{ fontWeight: '500' }}>
        //                                     {currentData.measure && `${currentData.measure} - ${currentData.measure_name}`}
        //                                 </Typography.Paragraph>
        //                             </Col>
        //                         </Row>
        //                     </Col>

        //                     <Col span={24}>
        //                         <Row style={{ width: '100%' }}>
        //                             <Col style={{ width: '30%' }}>
        //                                 <Typography.Title level={5}>
        //                                     Support Type
        //                                 </Typography.Title>
        //                             </Col>
        //                             <Col style={{ paddingTop: '2px' }}>
        //                                 <Typography.Paragraph style={{ fontWeight: '500' }}>
        //                                     {console.log("CURRRREEENTTTT", currentData.assistance)}
        //                                     {currentData?.assistance == 0 ? "Technical Support" : currentData?.assistance == 1 ? "Capacity Building" : "Other"}
        //                                 </Typography.Paragraph>
        //                             </Col>
        //                         </Row>
        //                     </Col>

        //                     <Col span={24}>
        //                         <Row style={{ width: '100%' }}>
        //                             <Col style={{ width: '30%' }}>
        //                                 <Typography.Title level={5}>
        //                                     Description
        //                                 </Typography.Title>
        //                             </Col>
        //                             <Col style={{ paddingTop: '2px' }}>
        //                                 <Typography.Paragraph style={{ fontWeight: '500' }}>
        //                                     {currentData.description}
        //                                 </Typography.Paragraph>
        //                             </Col>
        //                         </Row>
        //                     </Col>

        //                     <Col span={24}>
        //                         <Row style={{ width: '100%' }}>
        //                             <Col style={{ width: '30%' }}>
        //                                 <Typography.Title level={5}>
        //                                     Agency
        //                                 </Typography.Title>
        //                             </Col>
        //                             <Col style={{ paddingTop: '2px' }}>
        //                                 <Typography.Paragraph style={{ fontWeight: '500' }}>
        //                                     {currentData.scope == 0 ? "National Wise" : currentData.agency_name ? currentData.agency_name : "-"}
        //                                 </Typography.Paragraph>
        //                             </Col>
        //                         </Row>
        //                     </Col>

        //                     <Col span={24}>
        //                         <Row style={{ width: '100%' }}>
        //                             <Col style={{ width: '30%' }}>
        //                                 <Typography.Title level={5}>
        //                                     Tentative Start Date
        //                                 </Typography.Title>
        //                             </Col>
        //                             <Col style={{ paddingTop: '2px' }}>
        //                                 <Typography.Paragraph style={{ fontWeight: '500' }}>
        //                                     {moment(currentData.implementation_from).format("DD/MM/YYYY")}
        //                                 </Typography.Paragraph>
        //                             </Col>
        //                         </Row>
        //                     </Col>

        //                     <Col span={24}>
        //                         <Row style={{ width: '100%' }}>
        //                             <Col style={{ width: '30%' }}>
        //                                 <Typography.Title level={5}>
        //                                     Tentative End Date
        //                                 </Typography.Title>
        //                             </Col>
        //                             <Col style={{ paddingTop: '2px' }}>
        //                                 <Typography.Paragraph style={{ fontWeight: '500' }}>
        //                                     {moment(currentData.implementation_to).format("DD/MM/YYYY")}
        //                                 </Typography.Paragraph>
        //                             </Col>
        //                         </Row>
        //                     </Col>

        //                     <Col span={24}>
        //                         <Row style={{ width: '100%' }}>
        //                             <Col style={{ width: '100%' }}>
        //                                 <Typography.Title level={5}>
        //                                     Attachments
        //                                 </Typography.Title>
        //                             </Col>
        //                         </Row>
        //                         <Row gstyle={{ width: '100%' }}>
        //                             <Col style={{ width: '100%' }}>
        //                                 {currentData && currentData.attachments && currentData.attachments.map((file) => {
        //                                     return <div onClick={() => fetchFile(file)}>
        //                                         {file.type.includes("image") ?
        //                                             <img src="/image.png" alt="image" style={{ height: 50, width: 50 }} />
        //                                             : <img src="/file.png" alt="file" style={{ height: 50, width: 50 }} />}
        //                                         {(file.url.toString().split("-::-"))[1]}
        //                                     </div>
        //                                 })}
        //                             </Col>
        //                         </Row>
        //                     </Col>

        //                     <Col span={24}>
        //                         <Row style={{ width: '100%' }}>
        //                             <Col style={{ width: '50%' }}>
        //                                 <Typography.Title level={5}>
        //                                     Interested Donors
        //                                 </Typography.Title>
        //                             </Col>

        //                             <Col span={24}>
        //                                 {currentData?.interests?.length > 0 ?
        //                                     <List>
        //                                         <VirtualList
        //                                             data={currentData.interests}
        //                                             height={150}
        //                                             itemHeight={47}
        //                                             itemKey="id"
        //                                         // onScroll={onScroll}
        //                                         >
        //                                             {(item, key) => (
        //                                                 <List.Item key={key} style={{ cursor: "pointer" }}>
        //                                                     <List.Item.Meta
        //                                                         avatar={<Avatar src={<img src={'https://gw.alipayobjects.com/zos/rmsportal/KDpgvguMpGfqaHPjicRK.svg'} alt="avatar" />} />}
        //                                                         // title={<p>{item.title}</p>}
        //                                                         title={item.agency_name}
        //                                                         // description={"By " + item.first_name + " " + item.last_name}

        //                                                         description={new Date(item.created).toISOString().slice(8, 10) + "/" + new Date(item.created).toISOString().slice(5, 7)}
        //                                                     />
        //                                                 </List.Item>
        //                                             )}
        //                                         </VirtualList>
        //                                     </List>
        //                                     : <Typography.Paragraph>
        //                                         There is no Interested Donors for this Project.
        //                                     </Typography.Paragraph>
        //                                 }
        //                             </Col>

        //                         </Row>
        //                     </Col>

        //                     <Col span={24}>
        //                         {currentData.status == 0 && userData && userData.user_level != '5' &&
        //                             <div style={{ display: "flex", justifyContent: "flex-end" }}>
        //                                 <Space >
        //                                     {/* <Button danger type="primary" htmlType="button" onClick={publishDonorRequest}> Reject</Button> */}
        //                                     <Button loading={btnLoad} onClick={() => setRejectDialogOpen(true)}> Reject</Button>
        //                                     <Button loading={btnLoad} type="primary" onClick={() => setApproveDialogOpen(true)}> Approve</Button>
        //                                 </Space>
        //                             </div>
        //                         }
        //                     </Col>

        //                 </Row>

        //             </div>

        //         }
        //     </div>

        // }
        // addButton={
        //     <Button
        //         onClick={() => {
        //             setAddModelView(true)
        //         }}
        //         type="primary" icon={<PlusOutlined />} size={"medium"}>
        //         Add New Request
        //     </Button>}

        table={
          <Table
            className="custom-table"
            rowClassName={(record, index) => index % 2 === 0 ? 'white-row' : 'gray-row'}
            // className="custom-table-header"
            scroll={{
              y: 400,
            }}
            size="small"
            columns={columns}
            dataSource={data}
            pagination={{
              pageSize: 5,
              showSizeChanger: false,
              // total: data.length,
            }}


            onRow={(record, rowIndex) => {
              return {
                onClick: () => {
                  console.log("record--->>", record);
                  setCurrentRecord(record);
                  // setViewDialog(true);
                },
              };
            }}
          />
        }
      />

      <Modal
        className="custom-modal"
        title={
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div>
              {" "}
              <Typography className="custom-modal-title" level={2}>
                Donor Request Rejection
              </Typography>
            </div>
          </div>
        }
        centered
        footer={false}
        open={rejectDialogOpen}
        onOk={() => reject()}
        onCancel={() => {
          setFileList([]);
          form.resetFields()
          setRejectDialogOpen(false);
          setRejectReason("");
          setError(false);
        }}

      >
        <div className="custom-modal-body">
          <Form

            form={form}
            layout="vertical"
            onFinish={reject}
          >
            <Space direction="vertical">

              <Form.Item label="Reject Reason" name="reason" required
                rules={[{
                  required: true,
                  message: "Reason is required",

                },
                  // {
                  //   pattern: /^(?!\s)[a-zA-Z\s]+$/,

                  //   message: "Please enter a valid reason",
                  // },
                ]}
              >
                <Input.TextArea

                  rows={5}
                  placeholder="Enter Remark"
                  showCount
                  maxLength={100}

                />
              </Form.Item>


              <p>
                Please ensure that only one attachment is uploaded at a time, and
                the file size should be limited to a maximum of 5MB.
              </p>
              <Dragger
                {...props}
                fileList={fileList}
                showUploadList={{ showProgress: true }}
                maxCount={1}
                onRemove={() => {
                  setFileList([]);
                }}
                beforeUpload={(file) => {
                  const MAX_SIZE = 5 * 1024 * 1024; // 5 MB
                  if (file.size > MAX_SIZE) {
                    toast.error(
                      "File size exceeds limit! The maximum file upload size is 5MB.",
                      {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                      }
                    );
                    return false;
                  } else {
                    handleFileUpload(file);
                  }
                  return false; // to prevent automatic upload
                  // to prevent automatic upload
                }}
              >
                <p className="ant-upload-drag-icon">
                  <InboxOutlined tyle={{ color: "#33363F" }} />
                </p>
                <p className="ant-upload-text">
                  Click or drag a file to this area to upload
                </p>
              </Dragger>

              <div>
                <Button htmlType="submit" type="primary"
                  disabled={window.localStorage.getItem('view_only') == 1}>
                  Ok
                </Button>
              </div>
            </Space>
          </Form>
        </div>
      </Modal>

      <Modal
        className="custom-modal"
        centered
        closable
        open={approveDialogOpen}
        // onOk={() => approve()}
        footer={false}
        // onCancel={() => {
        //   setFileList([]);
        //   setApproveDialogOpen(false);
        //   setApproveReason("");
        // }}
        // okButtonProps={{
        //   style: { backgroundColor: NewColorTheme.primaryColor, color: "white" },
        // }}
        title={
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div>
              {" "}
              <Typography className="custom-modal-title" level={2}>
                Donor Request Approval
              </Typography>
            </div>
          </div>
        }
      >
        <div className="custom-modal-body">
          <Form

            form={form}
            layout="vertical"
            onFinish={approve}
          >
            <Form.Item label="Approval Remarks" name="reason" required
              rules={[{
                required: true,
                message: "Remarks is required",

              },
                // {
                //   pattern: /^(?!\s)[a-zA-Z\s]+$/,

                //   message: "Please enter a valid reason",
                // },
              ]}
            >
              <Input.TextArea
                rows={5}
                placeholder="Enter Remark"
                // value={approveReason}
                maxLength={100}
              />
            </Form.Item>
            <p>
              Please ensure that only one attachment is uploaded at a time, and
              the file size should be limited to a maximum of 5MB.
            </p>
            <Dragger
              {...props}
              showUploadList={{ showProgress: true }}
              maxCount={1}
              onRemove={() => {
                setFileList([]);
              }}
              fileList={fileList}
              beforeUpload={(file) => {
                const MAX_SIZE = 5 * 1024 * 1024; // 5 MB
                if (file.size > MAX_SIZE) {
                  toast.error(
                    "File size exceeds limit! The maximum file upload size is 5MB.",
                    {
                      position: "top-right",
                      autoClose: 5000,
                      hideProgressBar: false,
                      closeOnClick: true,
                      pauseOnHover: true,
                      draggable: true,
                      progress: undefined,
                      theme: "colored",
                    }
                  );
                  return false;
                } else {
                  handleFileUpload(file);
                }
                return false; // to prevent automatic upload
                // to prevent automatic upload
              }}
            >
              <p className="ant-upload-drag-icon">
                <InboxOutlined tyle={{ color: "#33363F" }} />
              </p>
              <p className="ant-upload-text">
                Click or drag a file to this area to upload
              </p>
            </Dragger>

            <Space style={{ display: "flex", justifyContent: 'flex-end', marginTop: 10 }}>
              <Button
                size="middle"
                loading={btnLoad2}
                onClick={() => {
                  form.resetFields()
                  setFileList([]);
                  setApproveDialogOpen(false);
                  setApproveReason("");
                }}

              >
                {" "}
                Cancel
              </Button>,
              <Button
                htmlType="submit"
                style={{ backgroundColor: NewColorTheme.primaryColor, color: "white" }}
                size="middle"
                loading={btnLoad}
                type="primary"

                disabled={window.localStorage.getItem('view_only') == 1}
              >
                {" "}
                Ok
              </Button>
            </Space>


          </Form>
        </div>
      </Modal>
      <Modal
        closable={true}
        centered
        className="custom-modal"
        width={700}
        onCancel={handleCancel}
        title={
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div>
              {" "}
              <Typography className="custom-modal-title" level={2}>
                {"Request Details"}
              </Typography>
            </div>
          </div>
        }
        open={viewDialog}
        footer={
          !isChair && currentRecord?.status == 0 && (window.localStorage.getItem('ntfc_level') == 6 || window.localStorage.getItem('ntfc_level') == 7)
            ? [
              <Button
                size="large"
                loading={btnLoad2}
                onClick={() => {
                  form.resetFields()
                  setRejectDialogOpen(true)
                }}
                disabled={window.localStorage.getItem('view_only') == 1}
              >
                {" "}
                Reject
              </Button>,
              <Button
                htmlType="submit"
                style={{ backgroundColor: NewColorTheme.primaryColor, color: "white" }}
                size="large"
                loading={btnLoad}
                type="primary"
                onClick={() => {
                  form.resetFields()
                  setApproveDialogOpen(true)
                }}
                disabled={window.localStorage.getItem('view_only') == 1}
              >
                {" "}
                Approve
              </Button>,
            ]
            : []
        }
      >
        <div
          className="custom-modal-body custom-scroll-div"
          style={{ height: "60vh", overflow: "auto" }}
        >
          <Row>
            <Col xs={24}>
              <Row gutter={[8, 8]} align={"top"}>
                <Col xs={24}>
                  <Row gutter={[8, 8]}>
                    <Col xs={2}>
                      <ReconciliationOutlined style={{ fontSize: 22 }} />
                    </Col>
                    <Col xs={22}>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "flex-start",
                        }}
                      >
                        <Typography.Title level={5}>
                          {" "}
                          Subject
                        </Typography.Title>
                        <Typography> {currentRecord?.subject}</Typography>
                      </div>
                    </Col>
                  </Row>
                </Col>
                <Col xs={12}>
                  <Row gutter={[8, 8]} align={"top"}>
                    <Col xs={4}>
                      <CalendarFilled style={{ fontSize: 22 }} />
                    </Col>
                    <Col xs={20}>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "flex-start",
                        }}
                      >
                        <Typography.Title level={5}>
                          {" "}
                          Created Date
                        </Typography.Title>

                        <Typography>
                          {" "}
                          {dayjs(currentRecord?.created).format("DD/MM/YYYY")}
                        </Typography>
                      </div>
                    </Col>
                  </Row>
                </Col>
                <Col xs={24}>
                  <Row gutter={[8, 8]} align={"top"}>
                    <Col xs={2}>
                      <BoxPlotOutlined style={{ fontSize: 22 }} />
                    </Col>
                    <Col xs={22}>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "flex-start",
                        }}
                      >
                        <Typography.Title level={5}> Measure</Typography.Title>

                        <Typography>
                          {" "}
                          {currentRecord?.measure_name ? currentRecord?.measure_name : "-"}
                        </Typography>
                      </div>
                    </Col>
                  </Row>
                </Col>
                <Col xs={24}>
                  <Row gutter={[8, 8]} align={"top"}>
                    <Col xs={2}>
                      <BorderlessTableOutlined style={{ fontSize: 22 }} />
                    </Col>
                    <Col xs={22}>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "flex-start",
                        }}
                      >
                        <Typography.Title level={5}>
                          {" "}
                          Support Type
                        </Typography.Title>

                        <Typography>
                          {" "}
                          {currentRecord?.assistance == 0
                            ? "Technical Support or Capacity Building"
                            : "Other"}
                        </Typography>
                      </div>
                    </Col>
                  </Row>
                </Col>
                {currentRecord?.implementation_from && (
                  <Col xs={12}>
                    <Row gutter={[8, 8]} align={"top"}>
                      <Col xs={4}>
                        <CalendarOutlined style={{ fontSize: 22 }} />
                      </Col>
                      <Col xs={20}>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "flex-start",
                          }}
                        >
                          <Typography.Title level={5}>
                            {" "}
                            Tentative Start Date
                          </Typography.Title>

                          <Typography>
                            {" "}
                            {dayjs(currentRecord?.implementation_from).format(
                              "DD/MM/YYYY"
                            )}
                          </Typography>
                        </div>
                      </Col>
                    </Row>
                  </Col>
                )}

                {currentRecord?.implementation_to && (
                  <Col xs={12}>
                    <Row gutter={[8, 8]} align={"top"}>
                      <Col xs={4}>
                        <CalendarOutlined style={{ fontSize: 22 }} />
                      </Col>
                      <Col xs={20}>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "flex-start",
                          }}
                        >
                          <Typography.Title level={5}>
                            {" "}
                            Tentative End Date
                          </Typography.Title>

                          <Typography>
                            {" "}
                            {dayjs(currentRecord?.implementation_to).format(
                              "DD/MM/YYYY"
                            )}
                          </Typography>
                        </div>
                      </Col>
                    </Row>
                  </Col>
                )}

                {currentRecord?.published_date && (
                  <Col xs={12}>
                    <Row gutter={[8, 8]} align={"top"}>
                      <Col xs={4}>
                        <CalendarOutlined style={{ fontSize: 22 }} />
                      </Col>
                      <Col xs={20}>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "flex-start",
                          }}
                        >
                          <Typography.Title level={5}>
                            {" "}
                            Published Date
                          </Typography.Title>

                          <Typography>
                            {" "}
                            {currentRecord?.published_date
                              ? dayjs(currentRecord?.published_date).format(
                                "DD/MM/YYYY"
                              )
                              : "-"}
                          </Typography>
                        </div>
                      </Col>
                    </Row>
                  </Col>
                )}
                {currentRecord?.attachments?.length > 0 && (
                  <Col xs={24}>
                    <Row gutter={[8, 8]} align={"top"}>
                      <Col xs={2}>
                        <CloudUploadOutlined style={{ fontSize: 22 }} />
                      </Col>
                      <Col xs={22}>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "flex-start",
                          }}
                        >
                          <Typography.Title level={5}>
                            {" "}
                            Attachment
                          </Typography.Title>

                          {currentRecord?.attachments?.length > 0 &&
                            currentRecord?.attachments?.map((element, key) => {
                              return (
                                <div
                                  style={{
                                    alignItems: "center",
                                    display: "flex",
                                    justifyContent: "space-between",
                                    width: "100%",
                                    cursor: "pointer",
                                    background: MainColors.LIGHTBACKCOLOR,
                                    borderRadius: 10,
                                    padding: 10,

                                    ":hover": {
                                      background: MainColors.DARKBACKCOLOR,
                                      color: "white",
                                      // Add any additional styles you want for the hover effect
                                    },
                                  }}
                                  key={key}
                                  onClick={() => fetchFile(element)}
                                >
                                  <div>
                                    <img
                                      src="/file.png"
                                      alt="file"
                                      style={{ height: 50, width: 50 }}
                                    />
                                    {element?.url &&
                                      (element?.url
                                        ?.toString()
                                        .split("-::-"))[1]}
                                  </div>
                                  <BiCloudDownload
                                    style={{ fontSize: 32, color: NewColorTheme.primaryColor }}
                                  />
                                </div>
                              );
                            })}
                        </div>
                      </Col>
                    </Row>
                  </Col>
                )}
                <Col xs={24}>
                  <Row gutter={[8, 8]} align={"top"}>
                    <Col xs={2}>
                      <ContainerOutlined style={{ fontSize: 22 }} />
                    </Col>
                    <Col xs={22}>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "flex-start",
                        }}
                      >
                        <Typography.Title level={5}>
                          {" "}
                          Description
                        </Typography.Title>

                        <Typography>
                          {" "}
                          {currentRecord?.description
                            ? currentRecord?.description
                            : "-"}
                        </Typography>
                      </div>
                    </Col>
                  </Row>
                </Col>
                {(currentRecord?.reject_reason || currentRecord?.publish_reason) &&
                  <Col xs={24}>
                    <Row gutter={[8, 8]} align={"top"}>
                      <Col xs={2}>
                        <FileDoneOutlined style={{ fontSize: 22 }} />
                      </Col>
                      <Col xs={22}>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "flex-start",
                          }}
                        >
                          <Typography.Title level={5}> Remarks</Typography.Title>

                          <Typography>
                            {" "}
                            {currentRecord?.reject_reason
                              ? currentRecord?.reject_reason
                              : currentRecord?.publish_reason
                                ? currentRecord?.publish_reason
                                : "-"}
                          </Typography>
                        </div>
                      </Col>
                    </Row>
                  </Col>

                }

                {currentRecord?.published_attachments?.length > 0 && (
                  <Col xs={24}>
                    <Row gutter={[8, 8]} align={"top"}>
                      <Col xs={2}>
                        <CloudUploadOutlined style={{ fontSize: 22 }} />
                      </Col>
                      <Col xs={22}>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "flex-start",
                          }}
                        >
                          <Typography.Title level={5}>
                            Remarks Related Attachment
                          </Typography.Title>

                          {currentRecord?.published_attachments?.length > 0 &&
                            currentRecord?.published_attachments?.map(
                              (element, key) => {
                                return (
                                  <div
                                    style={{
                                      alignItems: "center",
                                      display: "flex",
                                      justifyContent: "space-between",
                                      width: "100%",
                                      cursor: "pointer",
                                      background: MainColors.LIGHTBACKCOLOR,
                                      borderRadius: 10,
                                      padding: 10,

                                      ":hover": {
                                        background: MainColors.DARKBACKCOLOR,
                                        color: "white",
                                        // Add any additional styles you want for the hover effect
                                      },
                                    }}
                                    key={key}
                                    onClick={() => fetchFile(element)}
                                  >
                                    <div>
                                      <img
                                        src="/file.png"
                                        alt="file"
                                        style={{ height: 50, width: 50 }}
                                      />
                                      {element?.url &&
                                        (element?.url
                                          ?.toString()
                                          .split("-::-"))[1]}
                                    </div>

                                    <BiCloudDownload
                                      style={{ fontSize: 32, color: NewColorTheme.primaryColor }}
                                    />
                                  </div>
                                );
                              }
                            )}
                        </div>
                      </Col>
                    </Row>
                  </Col>
                )}

                {currentRecord?.status != 0 && (
                  <Col xs={24}>
                    <Row gutter={[8, 8]} align={"top"}>
                      <Col xs={2}>
                        <FileDoneOutlined style={{ fontSize: 22 }} />
                      </Col>
                      <Col xs={22}>
                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "flex-start",
                          }}
                        >
                          <Typography.Title level={5}>
                            {" "}
                            Interested Donors
                          </Typography.Title>

                          {currentRecord?.interests?.length > 0 ? (
                            <List style={{ width: "100%" }}>
                              <VirtualList
                                style={{ width: "100%" }}
                                data={currentRecord.interests}
                                height={150}
                                itemHeight={47}
                                itemKey="id"
                              // onScroll={onScroll}
                              >
                                {(item, key) => (
                                  <List.Item
                                    key={key}
                                    style={{ cursor: "pointer" }}
                                  >
                                    <List.Item.Meta
                                      avatar={
                                        <Avatar
                                          src={
                                            <img
                                              src={
                                                "https://gw.alipayobjects.com/zos/rmsportal/KDpgvguMpGfqaHPjicRK.svg"
                                              }
                                              alt="avatar"
                                            />
                                          }
                                        />
                                      }
                                      // title={<p>{item.title}</p>}
                                      title={item.agency_name}
                                      // description={"By " + item.first_name + " " + item.last_name}

                                      description={
                                        new Date(item.created)
                                          .toISOString()
                                          .slice(8, 10) +
                                        "/" +
                                        new Date(item.created)
                                          .toISOString()
                                          .slice(5, 7)
                                      }
                                    />
                                  </List.Item>
                                )}
                              </VirtualList>
                            </List>
                          ) : (
                            <Typography.Paragraph>
                              There is no Interested Donors for this Project.
                            </Typography.Paragraph>
                          )}
                        </div>
                      </Col>
                    </Row>
                  </Col>
                )}
              </Row>
            </Col>
            {/* <Col span={24}>
              {console.log("ntfc level ------------>>>>>", window.localStorage.getItem('ntfc_level'))}
              {currentData.status == 0 && window.localStorage.getItem('ntfc_level') == 6 && window.localStorage.getItem('ntfc_level') == 7 && (
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                  <Space>
                    {/* <Button danger type="primary" htmlType="button" onClick={publishDonorRequest}> Reject</Button> 
                    <Button
                      loading={btnLoad}
                      onClick={() => setRejectDialogOpen(true)}
                    >
                      {" "}
                      Reject
                    </Button>
                    <Button
                      loading={btnLoad}
                      type="primary"
                      onClick={() => setApproveDialogOpen(true)}
                    >
                      {" "}
                      Approve
                    </Button>
                  </Space>
                </div>
              )}
            </Col> */}
          </Row>
        </div>
      </Modal>

      <ToastContainer />
    </div>
  );
};

export default AllDonorRequests;
