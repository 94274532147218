import { Card, Typography } from 'antd'
import React from 'react'
import { useState } from 'react';
import { useEffect } from 'react';
import CountUp from 'react-countup';
import '../NewCalender.css'

function CounterCard({ onClick, title, count, backgroundColor }) {

    console.log(onClick, title, count, backgroundColor)

    const [shouldAnimate, setShouldAnimate] = useState(false);
    useEffect(() => {
        setShouldAnimate(true);
    }, [])
    return (
        <Card
            className='myCard'
            onClick={onClick}
            style={{
                height: 150,
                width: "100%",
                backgroundColor: backgroundColor,
                // backgroundImage: `linear-gradient(135deg, #8BC6EC 0%, #9599E2 100%)`,
                cursor: "pointer",
                padding: "0px !important"
            }}>
            <div style={{ display: "flex", flexDirection: "column", textAlign: "center", padding: 10, }}>

                <Typography
                    className='counter-count'
                    style={{
                        fontFamily: "Figtree",
                        fontSize: 64,
                        fontWeight: 700,
                        color: "white"

                    }}>
                    {shouldAnimate && <CountUp end={count ? count : 0} duration={2} />}



                </Typography>
                <Typography style={{
                    fontFamily: 'Figtree',
                    fontSize: '15px',
                    fontWeight: '600',
                    marginTop: '-9px',
                    color: "white"
                }}>
                    {title}
                </Typography>

            </div>
        </Card>
    )
}

export default CounterCard