import AgencySummery from "../Agency-Summery/AgencySummery";

const Summary = ({ report }) => {
    return <div className="flex-container">
        {report && report.data ? 
            report.data.map((item)=><AgencySummery lbl={item.status} value={item.count} />)
        : null}
    </div>
}

export default Summary;