import { Col, Row } from 'antd'
import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import CountUp from 'react-countup';


function ActionCounts({ cardData }) {


    useEffect(() => {
        setShouldAnimate(true);


    }, [])

    const [shouldAnimate, setShouldAnimate] = useState(false);
    return (
        <Row gutter={[4, 4]} justify={"space-around"}>
            <Col xs={24} md={12}>
                <div style={{
                    border: `0.5px solid #AED8E6`,
                    background: "linear-gradient(90deg, #013E8F 0%, #012A58 100%)"
                }}
                    className='new-action-tile-new'
                >
                    <span style={{ color: `white` }} className='new-card-count-content' >{"To-Do"}</span>

                    <span style={{ color: `white` }} className='new-card-count-new' > {shouldAnimate && <CountUp end={cardData ? cardData.todo : 0} duration={2} />}</span>
                </div>

            </Col>
            <Col xs={24} md={12}>

                <div style={{
                    // border: `0.5px solid #0492C2`,
                    background: "linear-gradient(90deg, #0D6F8F 32.29%, #0D6F8F 100%)"

                }}
                    className='new-action-tile-new'
                >
                    <span style={{ color: `white` }} className='new-card-count-content' >{"In Progress"}</span>

                    <span style={{ color: `white` }} className='new-card-count-new' > {shouldAnimate && <CountUp end={cardData ? cardData.inprogress : 0} duration={2} />}</span>
                </div>
            </Col>
            <Col xs={24} md={12}>

                <div style={{
                    // border: `0.5px solid #2f69b3`,
                    background: "linear-gradient(90deg, #005FDA 0%, #0076DC 100%)"
                }}
                    className='new-action-tile-new'
                >
                    <span style={{ color: `white` }} className='new-card-count-content' >{"Delayed"}</span>

                    <span style={{ color: `white` }} className='new-card-count-new' > {shouldAnimate && <CountUp end={cardData ? cardData.delayed : 0} duration={2} />}</span>
                </div>
            </Col>
            <Col xs={24} md={12}>

                <div style={{
                    // border: `0.5px solid #4169E1`,
                    background: "linear-gradient(270deg, #04E2B7 0%, #04E2B7 100%)"

                }}
                    className='new-action-tile-new'
                >
                    <span style={{ color: `white` }} className='new-card-count-content' >{"Missed"}</span>

                    <span style={{ color: `white` }} className='new-card-count-new' > {shouldAnimate && <CountUp end={cardData ? cardData.missed : 0} duration={2} />}</span>
                </div>
            </Col>
            <Col xs={24} >

                <div style={{
                    background: "linear-gradient(270deg, #007ED7 0%, #00D1A8 100%)"

                }}
                    className='new-action-tile-new'
                >
                    <span style={{ color: `white` }} className='new-card-count-content' >{"Completed"}</span>

                    <span style={{ color: `white` }} className='new-card-count-new' > {shouldAnimate && <CountUp end={cardData ? cardData.complete : 0} duration={2} />}</span>
                </div>
            </Col>

        </Row>
    )
}

export default ActionCounts