import { Checkbox, Col, Form, Row, Select, Space, Typography, message, DatePicker, Button, Radio, Skeleton, Switch, Affix, ConfigProvider, Modal, Divider } from "antd";
import { useEffect, useState } from "react";
import { getActionsList, getAgencies, getAgencyActions, updateAgencyActions } from "../../api";
import './actionplan/ActionPlan.css'
import { ToastContainer, toast, Zoom } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { getUserData } from "../Donors/getCounts";
import DonarUser from "../Donors/components/DonarUser";
import FormLayouts from "../DonorsNew/Components/FormLayouts";
import ActionComponent from "./actionplan/ActionComponent";
import { Fragment } from "react";
import { NewColorTheme } from "../../appConst";
import PageHeader from "../Inquiries/PageHeader";

const ActionPlan = () => {
    const [agencies, setAgencies] = useState([]);
    const [actions, setActions] = useState([]);
    const [fullArray, setFullArray] = useState([]);
    const [selectedAgency, setSelectedAgency] = useState();
    const [pendingSubmissions, setPendingSubmissions] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [showDropdown, setShowDropDown] = useState(false);
    const [isChair, setChair] = useState(false)

    const showModal = () => {
        setIsModalOpen(true);
    };
    const handleOk = () => {
        setIsModalOpen(false);
        setShowDropDown(true)
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const restructure = (actionArr) => {
        const tempArray = [];
        for (let i = 0; i < actionArr.length; i++) {
            const articleAvail = tempArray.findIndex((itm) => itm.article_id === actionArr[i].article_id);


            if (articleAvail < 0) {
                const item = {
                    article_id: actionArr[i].article_id,
                    article_no: actionArr[i].article_no,
                    article: actionArr[i].article,
                    measures: [{
                        measure_id: actionArr[i].measure_id,
                        measure_no: actionArr[i].measure_no,
                        measure_name: actionArr[i].measure_name,
                        measure_extended_def_date: actionArr[i].measure_extended_def_date,
                        actions: [
                            {
                                id: actionArr[i].id,
                                article_id: actionArr[i].article_id,
                                measure_id: actionArr[i].measure_id,
                                action_no: actionArr[i].action_no,
                                action: actionArr[i].action,
                                def_date: actionArr[i].def_date,
                                description: actionArr[i].description,
                                summary: actionArr[i].summary,
                                created: actionArr[i].created,
                                updated: actionArr[i].updated,
                                available: actionArr[i].available,
                                available_def: actionArr[i].available_def,
                                delivery_date: actionArr[i].delivery_date,
                            }
                        ]
                    }]
                };
                tempArray.push(item);
            } else {
                const articleItem = tempArray[articleAvail];
                const measureAvail = articleItem.measures.findIndex((itm) => itm.measure_id === actionArr[i].measure_id);
                if (measureAvail < 0) {
                    const mItem = {
                        measure_id: actionArr[i].measure_id,
                        measure_no: actionArr[i].measure_no,
                        measure_name: actionArr[i].measure_name,
                        measure_extended_def_date: actionArr[i].measure_extended_def_date,
                        actions: [
                            {
                                id: actionArr[i].id,
                                article_id: actionArr[i].article_id,
                                measure_id: actionArr[i].measure_id,
                                action_no: actionArr[i].action_no,
                                action: actionArr[i].action,
                                def_date: actionArr[i].def_date,
                                description: actionArr[i].description,
                                summary: actionArr[i].summary,
                                created: actionArr[i].created,
                                updated: actionArr[i].updated,
                                available: actionArr[i].available,
                                available_def: actionArr[i].available_def,
                                delivery_date: actionArr[i].delivery_date,
                            }
                        ]
                    };
                    articleItem.measures.push(mItem);
                } else {
                    const measureItem = articleItem.measures[measureAvail];
                    const actionAvail = measureItem.actions.findIndex((itm) => itm.id === actionArr[i].id);
                    if (actionAvail < 0) {
                        measureItem.actions.push({
                            id: actionArr[i].id,
                            article_id: actionArr[i].article_id,
                            measure_id: actionArr[i].measure_id,
                            action_no: actionArr[i].action_no,
                            action: actionArr[i].action,
                            def_date: actionArr[i].def_date,
                            description: actionArr[i].description,
                            summary: actionArr[i].summary,
                            created: actionArr[i].created,
                            updated: actionArr[i].updated,
                            available: actionArr[i].available,
                            available_def: actionArr[i].available_def,
                            delivery_date: actionArr[i].delivery_date,
                            measure_extended_def_date: actionArr[i].measure_extended_def_date,
                        });
                    }
                    articleItem.measures[measureAvail] = measureItem;
                }
                tempArray[articleAvail] = articleItem;
            }
        }
        setFullArray(tempArray);
    }

    useEffect(() => {
        getAgencies().then((out) => {
            setAgencies(out.data.out)
            showModal()
        });
        getActionsList().then((out) => {
            const pre = out.data.out;
            for (let i = 0; i < pre.length; i++) {
                pre[i].available = false;
                pre[i].available_def = false;
                pre[i].delivery_date = pre[i].def_date;
            }
            setActions(pre);
        })

    }, []);

    const refreshPlan = () => {
        console.log("referss")
        resetPlan();
        console.log("referss")
        getAgencyActions(selectedAgency).then((agc) => {
            const pre = agc.data.out.sort((a, b) => a.article_no - b.article_no);
            const post = actions.sort((a, b) => a.article_no - b.article_no);
            console.log("pre", pre)
            for (let i = 0; i < post.length; i++) {
                const filtered = pre.filter((p) => p.action_id === post[i].id);
                if (filtered.length > 0) {
                    post[i].available = true;
                    post[i].available_def = true;
                    post[i].delivery_date = filtered[0].delivery_date ?? post[i].def_date;
                    post[i].measure_extended_def_date = filtered[0].measure_extended_def_date && filtered[0].measure_extended_def_date;
                }
            }

            console.log("after", post)
            setActions([...post]);
            restructure(post);
        });
    }

    const resetPlan = () => {
        console.log("Reset")
        const post = actions;
        for (let i = 0; i < post.length; i++) {
            post[i].available = false;
            post[i].available_def = false;
            post[i].delivery_date = post[i].def_date;
        }
        setActions([...post]);
    }

    useEffect(() => {
        if (selectedAgency) {
            refreshPlan();
        }
    }, [selectedAgency]);

    const [user, setUser] = useState({})
    useEffect(() => {
        if (window.localStorage.getItem('ntfc_level') == 6) {
            setChair(true)
        } else {
            setChair(false)
        }
        getUserDetails()
    }, []);
    const getUserDetails = async function () {
        const user = await getUserData()
        setUser(user)
    }

    return <div style={{ overflow: "hidden" }}>
        <div
            className="bgSidePanelaction"
            style={{ background: NewColorTheme.primaryColor }}
        ></div>
        <div className="sidepanel-left" style={{ background: "white" }}></div>
        <PageHeader title={"Action Plan Setup"} user={user} />

        <div className="form-layout-div-config">
            <Row gutter={16} >
                <Col span={24}>
                    <div className="boxAction">
                        <Row gutter={16} className="boxHeader">
                            <Col span={12}>
                                <Row gutter={16}>
                                    <Col span={6}>
                                        <p>
                                            <strong >All Measure View </strong>
                                        </p>
                                    </Col>
                                    <Col span={18}>
                                        {showDropdown && <Form.Item name="agency" required>
                                            <Select
                                                defaultValue={agencies.filter((agency) => agency.value === selectedAgency)[0]}
                                                placeholder="Select An Agency"
                                                allowClear
                                                options={agencies.filter((agency) => agency.agency_type === 1)}
                                                onChange={(val) => {
                                                    setPendingSubmissions([]);
                                                    setSelectedAgency(val)

                                                }}
                                            />

                                        </Form.Item>
                                        }


                                    </Col>
                                </Row>
                            </Col>

                            <Col span={4}>
                                <div className="text">
                                    <p>Definitive Date</p>
                                </div>

                            </Col>
                            <Col span={4}>
                                <div className="text">
                                    <p>Delivery Date</p>
                                </div>
                            </Col>
                            <Col span={4}>
                                <div className="text">
                                    <p>Assign</p>
                                </div>
                            </Col>

                        </Row>
                        <div className="boxBody ">

                            <div style={{ paddingTop: 15 }}>
                                {selectedAgency ? (<Row gutter={16}>

                                    <Col span={24}>
                                        <Space direction="vertical" style={{ width: '100%' }}>
                                            {fullArray.map((article) => {
                                                return <Space direction="vertical" style={{ width: '100%' }}>
                                                    <div className="tblRaw1" >

                                                        <Typography.Text>{article.article_no}.  {article.article}</Typography.Text>
                                                    </div>

                                                    <div style={{ paddingLeft: 30 }}>
                                                        {article.measures.map((measure) => {
                                                            return <Space direction="vertical" style={{ width: '100%' }}>
                                                                <div className="tblRaw2" >

                                                                    <Typography.Text>{article.article_no}.{measure.measure_no}.  {measure.measure_name}</Typography.Text>
                                                                </div>
                                                                <div style={{ paddingLeft: 30 }}>
                                                                    {measure.actions.map((action) => {
                                                                        return <ActionComponent action={action} article={article} measure={measure} refreshPlan={refreshPlan} agency={selectedAgency} isChair={isChair} />
                                                                    })}
                                                                </div>
                                                            </Space>;
                                                        })}
                                                    </div>
                                                </Space>;
                                            })}
                                        </Space>
                                    </Col>

                                </Row>) : (
                                    <>

                                        <Skeleton
                                            style={{ marginTop: 20, textAlign: 'right' }}
                                            active
                                            title={false}
                                            round={false}
                                            paragraph={
                                                {
                                                    rows: 4,
                                                    // width:[800,750,700]
                                                }
                                            }
                                        />

                                    </>
                                )
                                }
                            </div>
                        </div>
                    </div>
                </Col>
            </Row>

        </div>

        <Modal maskClosable={false} closable={false} centered footer={false} title="Please select an agency" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
            <div style={{ width: "100%", marginTop: 15 }}>
                <Row>
                    <Col span={24}>
                        <Select
                            style={{ width: "100%" }}
                            allowClear
                            options={agencies.filter((element) => element.agency_type === 1)}
                            onChange={(val) => {
                                setPendingSubmissions([])
                                setSelectedAgency(val)
                                handleOk()
                            }}
                        />

                        <div style={{ width: "100%", display: "flex", justifyContent: "flex-end", marginTop: 10 }}>
                            <Button type='primary' onClick={() => window.location.href = '/newdashboard'}> Back to Dashboard </Button>

                        </div>
                    </Col>
                </Row>
            </div>
        </Modal>
        <ToastContainer />
    </div>

}

export default ActionPlan;