import { Space, Typography, Collapse, Progress, Tooltip } from 'antd';
import { DoubleRightOutlined } from '@ant-design/icons';
const { Text } = Typography;
const { Panel } = Collapse;

const SideCards = ({ color, obj, rec, text, point, select, selected }) => {
    return <Collapse ghost defaultActiveKey={['1']}
        style={{ display: 'flex', alignItems: 'center' }}
        className='hidden-class'
        expandIcon={({ isActive }) => <DoubleRightOutlined style={{ fontSize: 12, color: "white" }} rotate={isActive ? 90 : 0} />}>
        <Panel header={<Space>
            <Tooltip
                placement="right"
                title={"Article " + obj.article_no}
                key={point % 3 === 0 ? '#E2445C' : (point % 2 === 0 ? '#FDAA3D' : '#00C874')}
                color={point % 3 === 0 ? '#E2445C' : (point % 2 === 0 ? '#FDAA3D' : '#00C874')}
            >
                <Text style={{ width: '10vw', display: 'block', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', color: "white", fontWeight: 600 }}>{obj.article_no}. {obj.article}</Text>
            </Tooltip>
            <Text style={{ fontSize: 10, color: "white" }} type='secondary'>{obj.measures ? obj.measures.length : '00'} Items</Text>
        </Space>} key="1" style={{ width: '100%' }}>
            <Space direction='vertical' style={{ width: '100%' }}>
                {obj.measures.map((m) => {
                    console.log("MEASURE MAN", m.measure_completion);
                    return <div style={{ paddingLeft: 25, cursor: 'pointer' }}
                        className={selected && selected.article_id === m.article_id && selected.measure_id === m.measure_id ? "side-list-item-selected" : "side-list-item"}
                        onClick={() => select(m)}>
                        <Tooltip
                            placement="right"
                            title={"Measure " + obj.article_no + "." + m.measure_no}
                            key={point % 3 === 0 ? '#E2445C' : (point % 2 === 0 ? '#FDAA3D' : '#00C874')}
                            color={point % 3 === 0 ? '#E2445C' : (point % 2 === 0 ? '#FDAA3D' : '#00C874')}
                        >
                            <Text color='secondary' style={{ width: '12vw', display: 'block', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{obj.article_no}.{m.measure_no}.{m.measure_name}</Text>
                            <div>
                                <span className="dot" style={{ backgroundColor: '#00C874' }}></span>{text}
                                <Progress percent={m.measure_completion} style={{ width: '80%' }}
                                    strokeColor={{ from: '#00C874', to: '#FFF0FF' }} />
                            </div>
                        </Tooltip>
                    </div>
                })}
            </Space>
        </Panel>
    </Collapse>
};

export default SideCards;