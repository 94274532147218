import React, { useEffect, useMemo, useState } from 'react'
import NewNavBar from '../../Dashboard/Newdashoard3/Components/NewNavBar'
import './donorprof.css'
import { Avatar, Button, Card, Col, Divider, Image, List, Row, Tag, Typography } from 'antd'

import { ArrowRightOutlined, UserOutlined, CalendarOutlined } from '@ant-design/icons';
import SubTitleDiv from '../Components/SubTitleDiv/SubTitleDiv';
import CustomTag from '../../../Components/CustomTag/CustomTag';
import { GetAllDonorList, getAllContributions, getAllDonorInterests, getAllDonorRequests, getDonorContribution } from '../../../api';
import { Fragment } from 'react';
import FormLayouts from '../../DonorsNew/Components/FormLayouts';
import dayjs from "dayjs";
import { NewColorTheme } from '../../../appConst';
function DonorProfile() {

    const [donorRequests, setDonorRequests] = useState([]);
    const [donorInterests, setDonorInterests] = useState([]);
    const [contributions, setContributions] = useState([]);
    const [donorProfile, setDonorProfile] = useState([]);

    useMemo(() => {
        if (window.localStorage.getItem('ntfc_level') == 0) {
            window.location.href = "/presidentdashbaord";
        }
    }, []);

    useEffect(() => {
        GetAllDonorList().then((out) => {
            if (out && out.data && out.data.out) {
                setDonorRequests(out.data.out)
                setDonorProfile(out.data.out[0])
            }
        });

        getAllDonorInterests().then((out) => {
            if (out && out.data && out.data.out) {
                setDonorInterests(out.data.out.map((item) => {
                    const ag_name = item.agency_name ? item.agency_name.split(" ") : ["U"];
                    let initials = "";
                    for (let i = 0; i < ag_name.length; i++) {
                        initials += ag_name[i].charAt(0)
                    }
                    return {
                        title: item.subject,
                        content: item.description,
                        image: initials
                    };
                }))
            }
        });

        // getAllContributions().then((out) => {
        //     if (out && out.data && out.data.out) {
        //         setContributions(out.data.out.map((item) => {
        //             console.log("CONTRIB", item);
        //             const ag_name = item.agency_name ? item.agency_name.split(" ") : ["U"];
        //             let initials = "";
        //             for (let i = 0; i < ag_name.length; i++) {
        //                 initials += ag_name[i].charAt(0)
        //             }
        //             return {
        //                 title: item.project_name,
        //                 content: item.description,
        //                 image: initials
        //             };
        //         }))
        //     }
        // });
    }, []);

    const handleContributions = async (donor) => {
        const out2 = await getDonorContribution(donor.id);
        console.log("out", out2)
        setContributions(out2.data.out)

    }

    return (
        <Fragment>

            <FormLayouts
                title="All Donor Profiles"
                table={
                    <Row gutter={16}>
                        <Col xs={24} md={8}>
                            <Card className="donor-prof-v3-card">
                                <div className="donor-v3-prof-card-inner-div">
                                    <div className='new-doonor-prof-title-container'>
                                        <Typography className='prof-title-container-title'>
                                            All Donor Profiles
                                        </Typography>

                                    </div>
                                    <div className='donor-prof-list-div custom-scroll-div'>
                                        <List
                                            className='list-donor-prof custom-scroll-div'
                                            dataSource={donorRequests}
                                            renderItem=
                                            {(item, key) => (
                                                <div>
                                                    <List.Item key={key} style={{
                                                        paddingRight: 10, background: donorProfile && donorProfile.id == item.id ?
                                                            "#282d9321" : 'white',
                                                        cursor: "pointer"
                                                    }}

                                                        onClick={() => {

                                                            setDonorRequests(donorRequests)
                                                            console.log("kooodsfs", donorRequests[key])
                                                            setDonorProfile(donorRequests[key])
                                                            // getDonorRequestInterest(donors[key])
                                                            handleContributions(donorRequests[key])

                                                        }}
                                                    >
                                                        <List.Item.Meta
                                                            avatar={<Avatar style={{
                                                                backgroundColor: NewColorTheme.primaryColor,
                                                            }}
                                                                icon={<UserOutlined />} />}
                                                            title={<div className="donor-donortilename">{item.agency_name}</div>}
                                                        // description={item.description}
                                                        />
                                                        <div>

                                                            {/* <Avatar style={{ background: "rgba(94, 0, 102, 0.3)" }} size={50}>50</Avatar> */}

                                                        </div>
                                                    </List.Item>

                                                </div>
                                            )}

                                        >

                                        </List>
                                    </div>

                                </div>


                            </Card>

                        </Col>
                        <Col xs={24} md={16}>
                            <Card className="donor-prof-v3-card">
                                <div className="donor-v3-prof-card-content-inner-div">
                                    <div className='donor-prof-content-title-div'>
                                        <Typography className='donor-prof-content-title'>
                                            {donorProfile ? donorProfile.agency_name : ""}
                                        </Typography>

                                    </div>
                                    <div className='donor-prof-content-title-div'>
                                        <Row gutter={[16, 16]}>
                                            <Col xs={24} >
                                                <Typography className='donor-prof-content-desc'>
                                                    {donorProfile ? donorProfile.description : ""}         </Typography>
                                            </Col>
                                            {/* <Col xs={12} md={6}>
                                            <div style={{ display: "flex", justifyContent: "center" }}>
                                                <img src="/logo.png" width={80} />
                                            </div>

                                        </Col> */}

                                        </Row>


                                    </div>
                                    <SubTitleDiv title={"Contribution"}

                                        content={

                                            <div className='donor-contribution custom-scroll-div'>
                                                <List
                                                    className='list-donor-prof2 custom-scroll-div'
                                                    dataSource={contributions}

                                                    renderItem=
                                                    {(item, key) => (

                                                        <div>
                                                            {console.log("item------------------->>>", item)}
                                                            <List.Item key={key} style={{ paddingRight: 10 }}>
                                                                <List.Item.Meta
                                                                    avatar={<Avatar style={{ backgroundColor: NewColorTheme.primaryColor }}>{item.image}</Avatar>}
                                                                    title={<p>{item.project_name}</p>}
                                                                    description={<div style={{ display: "flex", flexDirection: 'column', width: "100%" }}>
                                                                        {item.start_date &&
                                                                            <span>
                                                                                <CalendarOutlined />     <i>Project Duration :</i> {dayjs(item.start_date).format("DD/MM/YYYY") + " - " + dayjs(item.end_date).format("DD/MM/YYYY")}
                                                                            </span>
                                                                        }


                                                                        <span>
                                                                            {item.description}
                                                                        </span>



                                                                    </div>}
                                                                />

                                                                <div>
                                                                    <Tag bordered={false} color={"default"} style={{ width: 100, textAlign: "center" }}>
                                                                        {item.assistance == 0 ? "TS/CB" : "OTHER"}
                                                                    </Tag>
                                                                </div>


                                                            </List.Item>

                                                        </div>
                                                    )}

                                                >

                                                </List>
                                            </div>
                                        } />
                                    {/* <SubTitleDiv title={"Interest Projects"}

                                    content={

                                        <div className='donor-contribution custom-scroll-div'>
                                            <List
                                                className='list-donor-prof2 custom-scroll-div'
                                                dataSource={donorInterests}

                                                renderItem=
                                                {(item, key) => (
                                                    <div>
                                                        <List.Item key={key} style={{ paddingRight: 10 }}>
                                                            <List.Item.Meta
                                                                avatar={<Avatar>{item.image}</Avatar>}
                                                                title={<p>{item.title}</p>}
                                                                description={<div>{item.content}</div>}
                                                            />

                                                            <div>
                                                                <Tag bordered={false} color="orange">
                                                                    Pending
                                                                </Tag>

                                                            </div>


                                                        </List.Item>

                                                    </div>
                                                )}

                                            >

                                            </List>
                                        </div>
                                    } />
 */}


                                </div>


                            </Card>
                        </Col>

                    </Row >
                }
            />

        </Fragment >
    )
}

export default DonorProfile