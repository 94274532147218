import React from 'react';
import ReactECharts from 'echarts-for-react';
import * as echarts from 'echarts/core';

const Donut = ({ report, onClickDonut, measurewise = false }) => {

  console.log("data------>>>", report)


  const getStyle = (type) => {
    if (type == "To Do") {
      return {
        color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
          { offset: 0, color: '#013e8f' },
          { offset: 1, color: '#012a58' }
        ])
      }
    } else if (type == "In Progress") {
      return {
        color: new echarts.graphic.LinearGradient(1, 0, 0, 0, [
          { offset: 0, color: '#0D6F8F' },
          { offset: 1, color: '#0D6F8F ' }
        ])
      }
    } else if (type == "Delayed") {
      return {
        color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
          { offset: 0, color: '#005fda' },
          { offset: 1, color: '#0076dc' }
        ])
      }
    } else if (type == "Missed") {
      return {
        color: new echarts.graphic.LinearGradient(0, 0, 1, 0, [
          { offset: 0, color: '#04E2B7 ' },
          { offset: 1, color: '#04E2B7 ' }
        ])
      }
    } else if (type == "Completed") {
      return {
        color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
          { offset: 0, color: '#007ed7' },
          { offset: 1, color: '#00b5b7' }
        ])
      }
    }
  }
  const aa = report?.data?.map((element) => ({
    ...element,
    percentage: `${element.percentage?.toFixed(2)}`

  })).map((item) => {
    return {
      value: item.percentage, name: item.status, itemStyle: getStyle(item.status)
    }
  }).filter((e) => e.value !== 0)

  console.log("aa", aa)
  let option = {
    tooltip: measurewise ? null : {
      trigger: 'item',
      confine: true,
      // position: ['21%', '3%'], // Set the position of the tooltip
      formatter: measurewise ? null : (params) => {
        const { name, value, percent } = params;
        return `
          <div >
            <div><strong>Click to see more ${name} details</strong></div>
           
        `;
      },
      backgroundColor: '#6190E6',
      extraCssText: 'box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);',
      textStyle: {
        color: '#fff',
      },
      // formatter: '{a} <br/>{b} : {c} ({d}%)'
    },
    legend: {
      bottom: '2%',
      left: 'center',
      data: ["To Do", "In Progress", "Delayed", "Missed", "Completed"]
    },
    // height: "50vh",
    series: [
      {
        name: 'Access From',
        type: 'pie',
        radius: ['12%', '70%'],
        center: ['50%', '50%'],
        roseType: 'area',
        avoidLabelOverlap: true,
        itemStyle: {
          borderRadius: 0,
          borderColor: '#fff',
          borderWidth: 0
        },
        label: {
          show: true,
          position: 'outside',
          formatter: function (params) {
            const { name, value } = params;
            const formattedValue = parseFloat(value).toFixed(2);
            return `${name}\n${formattedValue}%`;
          },
          labelLine: {
            lineStyle: {
              color: 'rgba(255, 255, 255, 0.3)'
            },
            smooth: 0.2,
            length: 10,
            length2: 20
          },
        },

        emphasis: {
          label: {
            show: true,
            fontSize: 14,
            fontWeight: 'bold'
          }
        },
        color: [
          new echarts.graphic.LinearGradient(0, 0, 0, 1, [
            { offset: 0, color: '#05f2c3' },
            { offset: 0.7, color: '#0395C9' },
            { offset: 1, color: '#027DCE' }
          ]),
          new echarts.graphic.LinearGradient(0, 0, 0, 1, [
            { offset: 0, color: '#2378f7' },
            { offset: 0.7, color: '#2378f7' },
            { offset: 1, color: '#83bff6' }
          ]),
          new echarts.graphic.LinearGradient(0, 0, 0, 1, [
            { offset: 0, color: '#05f2c3' },
            { offset: 0.7, color: '#0395C9' },
            { offset: 1, color: '#027DCE' }
          ]),
          new echarts.graphic.LinearGradient(0, 0, 0, 1, [
            { offset: 0, color: '#003E8F' },
            { offset: 0.7, color: '#01397E' },
            { offset: 1, color: '#012F60' }
          ]),
          new echarts.graphic.LinearGradient(0, 0, 0, 1, [
            { offset: 0, color: '#2375f7' },
            { offset: 0.7, color: '#1328f7' },
            { offset: 1, color: '#83b1f6' }
          ])],

        // data: report && report.data ? report.data.map((element) => ({
        //   ...element,
        //   percentage: `${element.percentage?.toFixed(2)}`



        // })).map((item) => {
        //   return {
        //     value: item.percentage, name: item.status, itemStyle: getStyle(item.status)
        //   }
        // }).filter((e) => e.value !== "0.00") : []
        data: [
          {
            value: report && report.data.find((el) => el.status == "To Do").percentage, name: 'To Do', itemStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                { offset: 0, color: '#013e8f' },
                { offset: 1, color: '#012a58' }
              ])
            }
          },
          {
            value: report && report.data.find((el) => el.status == "In Progress").percentage, name: 'In Progress', itemStyle: {
              color: new echarts.graphic.LinearGradient(1, 0, 0, 0, [
                { offset: 0, color: '#0D6F8F' },
                { offset: 1, color: '#0D6F8F ' }
              ])
            }
          },
          {
            value: report && report.data.find((el) => el.status == "Delayed").percentage, name: 'Delayed', itemStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                { offset: 0, color: '#005fda' },
                { offset: 1, color: '#0076dc' }
              ])
            }
          },
          {
            value: report && report.data.find((el) => el.status == "Missed").percentage, name: 'Missed', itemStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 1, 0, [
                { offset: 0, color: '#04E2B7 ' },
                { offset: 1, color: '#04E2B7 ' }
              ])
            }
          },
          {
            value: report && report.data.find((el) => el.status == "Completed").percentage, name: 'Completed', itemStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                { offset: 0, color: '#007ed7' },
                { offset: 1, color: '#00b5b7' }
              ])
            }
          }
        ]
      }

    ]
  };

  return <div style={{ position: 'relative' }}>
    <img src='/target.png' alt='target' className={localStorage.getItem('ntfc_level') == 0 ? "graph-mid3" : localStorage.getItem('ntfc_level') == 1 ? 'graph-mid3' : 'graph-mid2'} />
    <ReactECharts
      style={window.localStorage.getItem('ntfc_level') == 0 || window.localStorage.getItem('ntfc_level') == 1 ? { height: "63vh" } : { height: "66vh" }}
      option={option}



      onEvents={{ click: onClickDonut, }}
    />
  </div>
};

export default Donut;

// report && report.data.find((el) => el.status == "Delayed").percentage