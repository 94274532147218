import { Card, Col, Row, Typography, message } from 'antd';
import { Button, Form, Input } from 'antd';
import './login.styles.css';
import { login } from "../../api";
import { UserOutlined, LockOutlined } from '@ant-design/icons';


const { Title, Link } = Typography;


function Page() {




  const [form] = Form.useForm();
  const [messageApi, contextHolder] = message.useMessage();

  const success = () => {
    messageApi.open({
      type: 'success',
      content: 'Log in successful',
      style: {
        marginTop: '30vh',
        height: '100vh',
        fontSize: '20px'
      },
      duration: 3
    });
  };

  const error = () => {
    messageApi.open({
      type: 'error',
      content: 'Log in unsuccessful',
      style: {
        marginTop: '30vh',
        height: '100vh',
        fontSize: '20px'
      },
      duration: 3
    });
  };

  const onFinish = async (values) => {
    console.log('Success:', values);
    // if (values.username === "admin") {
    //   window.sessionStorage.setItem('admin', true);
    // } else {
    //   window.sessionStorage.setItem('admin', false);
    // }

    login(values).then((out) => {

      if (out.data.status === 200) {
        window.localStorage.setItem('ntfc_token', out.data.token);
        window.localStorage.setItem('ntfc_agency', out.data.agency);
        window.localStorage.setItem('ntfc_level', out.data.level);
        window.localStorage.setItem('ntfc_name', out.data.name);
        window.localStorage.setItem('user_id', out.data.id)
        window.localStorage.setItem('ntfc_session', 1);

        window.location.href = "/newDashboard";


      } else {
        messageApi.open({
          type: 'error',
          content: 'Login unsuccessful',
          style: {
            marginTop: '30vh',
            height: '100vh',
            fontSize: '20px'
          },
          duration: 3
        });
      }
    }).catch((err) => {
      console.log(err);
      messageApi.open({
        type: 'error',
        content: 'Login unsuccessful',
        style: {
          marginTop: '30vh',
          height: '100vh',
          fontSize: '20px'
        },
        duration: 3
      });
    })

    // let new_values = {};

    // new_values['email'] = values.username;
    // new_values['pwd'] = values.password;

    // window.location.href = "/dashboard";


    // const logged = await login(new_values);
    // if (logged) {
    //   setTimeout(() => {
    //     success();
    //     localStorage.setItem('ntfc_user', JSON.stringify(logged.data));
    //     window.location.href = "/dashboard";
    //   }, 1000);
    // } else {
    //   error();
    // }

  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <div className="container" style={{ background: `url('/ntfc_background.jpg')`, backgroundSize: '100% auto', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      {contextHolder}
      <Card className='no-padding' style={{ backgroundColor: "#fafaff", borderColor: "#fafaff", width: '70vw', padding: 0, borderRadius: 30, boxShadow: '-5px 5px 4px rgba(0, 0, 0, 0.3)' }}>
        <Row gutter={16}>
          <Col span={12}>
            <div style={{ padding: 25 }}>
              <img src="/ntfc.png" width={125} alt="logo" />
              <Typography.Title level={3} style={{ color: "#2B52B4", margin: 0 }}>Implementation Progress</Typography.Title>
              <Typography.Title level={3} style={{ color: "#2B52B4", margin: 0 }}>Monitoring Tool</Typography.Title>
              <p style={{ textAlign: 'justify' }}>
                The National Committee on Trade Facilitation (NCTF) in Sri Lanka was established on 3 June 2014 and
                was formalized by the Ministers of Cabinet of the Government of Sri Lanka on 26 April 2016, as
                required by Article 23.2 of the WTO Trade Facilitation Agreement (TFA).
              </p>
              <Button style={{ borderRadius: 30, color: "#2B52B4", border: '2px solid #2B52B4', backgroundColor: "white", width: "50%" }} type='default' href='/signup' size='large'><b>Register Now</b></Button>
            </div>
          </Col>
          <Col span={12}>
            <Card className='no-padding' style={{ padding: 20, borderRadius: 30, boxShadow: '-5px 0px 10px rgba(0, 0, 0, 0.10)', height: '100%', display: 'flex', alignItems: 'center' }}>
              <div style={{ textAlign: 'center' }}>
                <Title level={3}>Login</Title>
                <p style={{ margin: 0, color: '#ADA4A9' }}>Enter your credentials to access</p>
                <p style={{ margin: 0, color: '#ADA4A9', marginBottom: 15 }}>your account.</p>
                <Form
                  form={form}
                  layout="vertical"
                  onFinish={onFinish}
                  onFinishFailed={onFinishFailed}
                >
                  <Form.Item name="email" required
                    rules={[{
                      type: 'email',
                      message: <p style={{ marginLeft: "12px" }}>Invalid Email</p>,
                    }, {
                      required: true,
                      message: <p style={{ marginLeft: "12px" }}>Email is required</p>,
                    }]}
                  >
                    <Input type='text' placeholder="Email" size='large' style={{ borderRadius: 30 }} suffix={<UserOutlined style={{ color: "#D7D3D5" }} />} />
                  </Form.Item>
                  <Form.Item
                    required
                    name="pwd"
                    rules={[{
                      required: true,
                      message: <p style={{ marginLeft: "12px" }}>Password is required</p>,
                    }]}
                  >
                    <Input type='password' placeholder="Password" size='large' style={{ borderRadius: 30 }} suffix={<LockOutlined style={{ color: "#D7D3D5" }} />} />
                  </Form.Item>

                  <Form.Item>
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                      <Button type='primary' htmlType="submit" style={{ background: '#2B52B4', color: 'white', fontWeight: 'bold', borderRadius: 30, padding: '0 40px' }}
                        size="large">Login</Button>
                      <Link style={{ color: '#2B52B4' }} href='/update_password'><b>Forgot Password?</b></Link>
                    </div>
                  </Form.Item>
                </Form>
              </div>
            </Card>
          </Col>
        </Row>
      </Card>
    </div>
  );
}

export default Page;
