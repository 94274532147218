import { Divider, Space, Table, Tag, Typography, Button, Tooltip, Input, Drawer, Spin, message, ConfigProvider, Modal, Form, Switch } from 'antd';
import { FormOutlined, CrownOutlined, TeamOutlined, SearchOutlined, UserAddOutlined } from '@ant-design/icons';
import { useState, useEffect, useRef } from 'react';
import UserEdit from './UserEdit';
import { allRegistrations, deactivateUser, getAgencies, reactivateUser } from '../../api';
import { user_types, user_types2 } from '../../appConst.js';
import { Col, Row, Select } from 'antd/es';
import FormLayouts from '../DonorsNew/Components/FormLayouts';
import UserEditNew from './UserEditNew';
import { ToastContainer, toast, Slide } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const { Text } = Typography;
const { Search } = Input;

const Users = () => {
    const userEditRef = useRef(null);

    const [confirmation, showConfirmation] = useState(false)
    const [currentUser, setCurrentUser] = useState()
    const [loading, setLoading] = useState(false)


    const [form] = Form.useForm();
    const columns = [
        // {
        //     title: 'ID',
        //     dataIndex: 'order',
        //     key: 'order'
        // },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Agency',
            dataIndex: 'agency_name',
            key: 'agency_name',
        },
        {
            title: 'Designation',
            dataIndex: 'designation',
            key: 'designation',
        },
        {
            title: 'User Type',
            key: 'user_type',
            dataIndex: 'user_type',
            render: (_, { user_type }) => (
                <Tag color={user_type === "SUPER ADMIN" || user_type === "SECRETARIAT" ? "#008282" : "#9B0259"} key={user_type} style={{ width: 150, display: 'flex', justifyContent: 'center' }} icon={user_type === "SUPER ADMIN" || user_type === "SECRETARIAT" ? <CrownOutlined /> : <TeamOutlined />}>
                    {user_type?.toUpperCase()}
                </Tag>
            ),
        },
        {
            title: 'Email Address',
            dataIndex: 'email',
            key: 'email',
        },
        {
            title: 'Mobile Number',
            dataIndex: 'mobile',
            key: 'mobile',
        },
        {
            title: 'Phone Number',
            dataIndex: 'phone',
            key: 'phone',
        },
        {
            title: 'Action',
            key: 'action',
            width: 80,
            render: (_, record) => (
                <Space>
                    <UserEditNew loadUsers={getUsers} user={record} />
                    <Tooltip title={record.active == 1 ? "Deactivate User" : "Activate User"}>
                        <Switch
                            size='small'
                            checked={record.active == 1 ? true : false}
                            onChange={() => {
                                setCurrentUser(record)
                                showConfirmation(true)
                            }} />
                    </Tooltip>

                </Space>


            ),
        },
    ];
    const chairColumns = [
        // {
        //     title: 'ID',
        //     dataIndex: 'order',
        //     key: 'order'
        // },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Agency',
            dataIndex: 'agency_name',
            key: 'agency_name',
        },
        {
            title: 'Designation',
            dataIndex: 'designation',
            key: 'designation',
        },
        {
            title: 'User Type',
            key: 'user_type',
            dataIndex: 'user_type',
            render: (_, { user_type }) => (
                <Tag color={user_type === "SUPER ADMIN" || user_type === "SECRETARIAT" ? "#008282" : "#9B0259"} key={user_type} style={{ width: 150, display: 'flex', justifyContent: 'center' }} icon={user_type === "SUPER ADMIN" || user_type === "SECRETARIAT" ? <CrownOutlined /> : <TeamOutlined />}>
                    {user_type?.toUpperCase()}
                </Tag>
            ),
        },
        {
            title: 'Email Address',
            dataIndex: 'email',
            key: 'email',
        },
        {
            title: 'Mobile Number',
            dataIndex: 'mobile',
            key: 'mobile',
        },
        {
            title: 'Phone Number',
            dataIndex: 'phone',
            key: 'phone',
        },

    ];

    const [messageApi, contextHolder] = message.useMessage();
    const [open, setOpen] = useState(false);
    const [data, setData] = useState([]);
    const [singleUserData, setSingleUserData] = useState([]);
    const [rawData, setRawData] = useState([]);
    const [prevData, setPrevData] = useState([]);
    const [loaded, setLoaded] = useState(false);
    const [userLevel, setUserLevel] = useState(null);
    const [search, setSearch] = useState("");
    const [agencies, setAgencies] = useState([]);//agencies from db
    const [agency, setAgency] = useState(null);


    useEffect(() => {
        getUsers();
    }, []);

    const error = () => {
        messageApi.open({
            type: 'error',
            content: 'Data retrieve unsuccessful',
            style: {
                marginTop: '30vh',
                height: '100vh',
                fontSize: '20px'
            },
            duration: 3
        });
    };

    const getUsers = async () => {

        const users = await allRegistrations();
        if (rawData != users) {
            if (users) {
                setTimeout(() => {
                    const userMap = users.data.out;
                    setUserData(userMap.map((u) => { return { ...u, "full_name": `${u['first_name']} ${u['last_name']}`, phone: u.phone ? "+" + u.phone : "", mobile: u.mobile ? "+" + u.mobile : "" } }));
                }, 1000);
            } else {
                error();
            }
        }
    }

    const disableUserFunc = async () => {
        setLoading(true)
        await deactivateUser({ user_id: currentUser.uuid }).then((out) => {
            console.log("out", out)
            toast.success(out.data.message,
                {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                    transition: Slide
                })
            setLoading(false)
            showConfirmation(false)
            getUsers();
        });
    }

    const enableUserFunc = async () => {
        setLoading(true)
        await reactivateUser({ user_id: currentUser.uuid }).then((out) => {
            console.log("out", out)
            toast.success(out.data.message,
                {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                    transition: Slide
                })
            setLoading(false)
            showConfirmation(false)
            getUsers();
        });


    }

    const setUserData = (u_data) => {
        setRawData(u_data)
        u_data.forEach((element, idx) => {
            element['order'] = idx + 1;
            element['name'] = element.first_name + " " + element.last_name;
            user_types.forEach(element2 => {
                if (element2.level === element.user_level) {
                    element['user_type'] = element2.type
                }
            })
        });

        setData(u_data);
        setPrevData(u_data);
        setLoaded(true);
    }


    const userEditCallback = () => {
        // Function to be called inside UserEdit component
        console.log('Function inside UserEdit called');
        // Perform any necessary actions
    };
    useEffect(() => { getAgencies().then((out) => { setAgencies(out.data.out); }) }, []);

    const searchTable = () => {
        setLoaded(false);
        //search
        //userLevel
        if ((search == "" || search == null) && (userLevel == "" || userLevel == null) && (agency == "" || agency == null)) {
            setData(prevData);
            setLoaded(true);
            return;
        }

        let new_data = [];
        if (search != "" && search != null) {
            const params = ['email', 'first_name', 'last_name', 'designation', 'mobile', 'phone', 'full_name'];
            for (let i = 0; i < prevData.length; i++) {
                let contains = false;
                for (let j = 0; j < params.length; j++) {
                    if (prevData[i][params[j]] && prevData[i][params[j]].toString().toLowerCase().includes(search.toString().toLowerCase())) {
                        contains = true;
                        break;
                    }
                }
                if (contains) {
                    new_data.push(prevData[i]);
                }
            }
        }

        let prevData2 = [];
        let new_data2 = [];

        if (new_data.length > 0) {
            prevData2 = new_data;
        } else {
            prevData2 = prevData;
        }

        if (userLevel != "" && userLevel != null) {
            prevData2.forEach(item => {
                user_types2.forEach(item2 => {
                    if (item.user_level == item2.level && item2.type.toUpperCase().includes(userLevel.toUpperCase())) {
                        new_data2.push(item)
                    }
                })
            })
        } else {
            new_data2 = new_data
        }

        let prevData3 = [];
        let new_data3 = [];

        if (new_data2.length > 0) {
            prevData3 = new_data2;
        } else {
            prevData3 = prevData2;
        }

        if (agency != "" && agency != null) {
            prevData3.forEach(item => {
                if (item.agency_id != null && item.agency_id == agency) {
                    new_data3.push(item)
                }
            })
        } else {
            new_data3 = new_data2
        }

        setData(new_data3);
        setLoaded(true);
    }

    return <>

        <div style={{ width: "100%" }}>
            <FormLayouts
                backUrl="/userrequestshod"
                title="Users"
                // formSide={
                //     <ConfigProvider
                //         theme={{
                //             token: {
                //                 colorPrimary: '#006666',
                //             },
                //         }}
                //     >

                //         <UserEdit singleUserData={singleUserData} />
                //     </ConfigProvider>
                // }
                table={

                    <Row gutter={[12, 24]} style={{ marginBottom: '-15px', width: "100%", paddingTop: 15, }}>

                        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                            <div style={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
                                {(window.localStorage.getItem("ntfc_level") == 6 || window.localStorage.getItem("ntfc_level") == 7) &&

                                    <div style={{ width: "fit-content" }}>
                                        <UserEdit />
                                    </div>
                                }
                                <div style={{ width: "fit-content", padding: "10px 15px", backgroundColor: "#d2e9f4", borderRadius: 8 }}>
                                    <b>User count : {data.length}</b>
                                </div>


                            </div>
                        </Col>

                        <Col xs={24}>

                            <Form
                                form={form}
                                onFinish={searchTable}
                            >
                                <Row gutter={16}>
                                    <Col xs={24} sm={24} md={4} lg={4} xl={4}>
                                        <Form.Item
                                            name="userType"

                                        >

                                            <Select
                                                placeholder="User Type"
                                                onChange={setUserLevel}
                                                style={{ width: '100%' }}
                                                options={user_types2}
                                                allowClear
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={24} md={7} lg={7} xl={7}>
                                        <Form.Item
                                            name="agency_name"

                                        >
                                            <Select
                                                placeholder="Agency Name"
                                                onChange={setAgency}
                                                style={{ width: '100%' }}
                                                options={agencies}
                                                // value={agencyName}
                                                allowClear
                                            />
                                        </Form.Item>
                                    </Col>
                                    {/* <Col xs={24} sm={24} md={15} lg={15} xl={15}>
                    <Search placeholder="Search Name/ Designation/ Email Address/ Mobile Number/ Phone Number" onSearch={(value) => searchTable(value)} style={{ width: "100%", margin: 0 }} enterButton />
                </Col> */}
                                    <Col xs={24} sm={24} md={9} lg={9} xl={9}>
                                        <Form.Item
                                            name="search"

                                        >
                                            <Input allowClear placeholder="Search Name/ Designation/ Email Address/ Mobile Number/ Phone Number" style={{ width: "100%", margin: 0 }} onChange={(e) => setSearch(e.target.value)} />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={24} md={2} lg={2} xl={2}>
                                        <Button htmlType='submit' type="primary" icon={<SearchOutlined />} >Search</Button>
                                    </Col>
                                    <Col xs={24} sm={24} md={2} lg={2} xl={2}>
                                        <Button type="primary" icon={<SearchOutlined />} onClick={() => {
                                            form.resetFields()
                                            setData(prevData)
                                        }}>Reset</Button>
                                    </Col>
                                </Row>
                            </Form>


                        </Col>


                        <Col xs={24}>
                            {loaded ?
                                <div>
                                    <Table columns={(localStorage.getItem('ntfc_level') == 6 || localStorage.getItem('ntfc_level') == 5) ? chairColumns : columns} dataSource={data} scroll={{ y: 500, }} />
                                </div>
                                :
                                <Space>
                                    <Spin tip="Loading" size="large">
                                        <div className="content" />
                                    </Spin>
                                </Space>
                            }

                        </Col>
                    </Row>

                }
            />



        </div>

        <Modal
            maskClosable={false}
            centered
            title="Confirmation"
            open={confirmation}
            onOk={currentUser && currentUser.active == 1 ? disableUserFunc : enableUserFunc }
            confirmLoading={loading}
            onCancel={() => showConfirmation(false)}>
            Are you sure, you want to {currentUser && currentUser.active == 1 ? 'deactivate' : 'activate'} this user?
        </Modal>

    </>


}

export default Users;