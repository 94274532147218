import { Typography } from 'antd'
import React from 'react'


export const progressStatus = {
    CRITICAL: "critical",
    NORMAL: "normal",
    INTERMEDIATE: "intermediate"
}
function ArticleCustomPopOver({ article }) {
    console.log("Aeeeetivlee====>>", article)

    const classSwitch = (progress) => {
        if (progress > 60) {
            return progressStatus.NORMAL;
        }
        if (progress >= 30) {
            return progressStatus.INTERMEDIATE;
        }
        return progressStatus.CRITICAL;

    }
    return (
        <div style={{ width: "100%", marginTop: 25 }} >
            {
                article && article.measures.sort((a, b) => a.measure_no - b.measure_no).map(
                    (element, key) => {
                        return <div key={key} style={{ display: "flex", flexDirection: "column", backgroundColor: "white", padding: 10, borderRadius: 10, margin: "5px 0px" }}>
                            <div style={{ marginBottom: 5, display: "flex" }}>
                                {/* <Tag color="blue"> {"Article " + articleNo.toString().padStart(2, '0')}</Tag> */}
                                <span className='progress-title-five' style={{ borderRadius: 5, whiteSpace: "nowrap", background: "#d7e4f5", height: "max-content", padding: "2px 3px", marginRight: 5 }}>{"Measure " + element.article_no + "." + element.measure_no}</span>
                                <Typography className='progress-title-five' >{

                                    (element.measure_name.length > 80 ? element.measure_name.substring(0, 80).concat('..') : element.measure_name)

                                }</Typography>

                            </div>
                            <div className='progress-content-wrapper'>

                                <div className='progress-chrat-wrapper-five' >

                                    <div

                                        className={`progress ${classSwitch(element.measure_completion)}`}
                                        style={{
                                            width: `${element.measure_completion}%`,
                                            background: "linear-gradient(270deg, #00C4B0 0%, #013E8F 100%)"
                                        }}
                                    ></div>
                                    <div className='right-text-content'>{`${element.measure_completion}%`}</div>
                                </div>

                            </div>
                        </div>
                    }
                )
            }


        </div>
    )
}

export default ArticleCustomPopOver