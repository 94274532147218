import { Space, Typography, Card } from "antd";
import "./dashboard.styles.css";
import Lines from "./Lines";
import Donut from "./Donut";
import HBar from "./HBar";
import Dates from "./Dates";
import Counts from "./Counts";
import VBar from "./VChart";
import ProgressChart from "../Progress-chart/Progress-chart";
import AgencySummery from "../Agency-Summery/AgencySummery";
import { useEffect, useState } from "react";
import Summary from "./Summary";
import { getAgencyWiseDashboard } from "../../../api";
const { Text } = Typography;

function Page({ agency }) {
  const [reports, setReports] = useState([]);

  useEffect(()=>{
    getAgencyWiseDashboard(agency,{}).then((out)=>{
      setReports(out.data.data);
    })
  },[agency]);

  return (
    <div className="content">
      <Space style={{ marginTop: 10 }} align="start">
        <Space direction="vertical" size="large" style={{ width: "90vw" }}>
          <Card
            style={{
              boxShadow: "1px 0px 5px -2px rgba(33, 19, 13, 0.3)",
              textAlign: "center",
            }}
            title="Agency Wise Summary"
          >
            <Summary report={reports && reports.length > 0 ? reports[0] : null} />
          </Card>
        </Space>
      </Space>
      <Space style={{ marginTop: 25, alignItems: "start" }}>
        <Space direction="vertical" size="large" style={{ width: "40vw" }}>
          <Card
            style={{
              boxShadow: "1px 0px 5px -2px rgba(33, 19, 13, 0.3)",
              textAlign: "center",
            }}
            title="Action wise implementation percentage"
          >
            <Donut report={reports && reports.length > 0 ? reports[1] : null} />
          </Card>
        </Space>
        <Space direction="vertical" size="large" style={{ width: "50vw" }}>
          <Card
            style={{
              boxShadow: "1px 0px 5px -2px rgba(33, 19, 13, 0.3)",
              textAlign: "center",
            }}
            title="Agency Wise Measure Implementation Status"
          >
            {/* <Text strong level={5} type="secondary">Agency Wise Measure Implementation Status</Text> */}
            {/* <HBar /> */}
            <ProgressChart
              startData={"01-01-2023"}
              endDate={"01-05-2023"}
              leftText={"1.3. Enquery points: "}
              rightText={"2 Months left"}
              progress={29}
            />
            <ProgressChart
              startData={"01-01-2023"}
              endDate={"01-05-2023"}
              leftText={
                "5.1. Notification for enhanced controls or inspections: "
              }
              rightText={"2 Months left"}
              progress={65}
            />
            <ProgressChart
              startData={"01-01-2023"}
              endDate={"01-05-2023"}
              leftText={"1.1. Publications: "}
              rightText={"2 Months left"}
              progress={40}
            />
            <ProgressChart
              startData={"01-01-2023"}
              endDate={"01-05-2023"}
              leftText={"1.1. Publications: "}
              rightText={"2 Months left"}
              progress={0}
            />
            <ProgressChart
              startData={"01-01-2023"}
              endDate={"01-05-2023"}
              leftText={"1.3. Enquery points: "}
              rightText={"2 Months left"}
              progress={29}
            />
            <ProgressChart
              startData={"01-01-2023"}
              endDate={"01-05-2023"}
              leftText={
                "5.1. Notification for enhanced controls or inspections: "
              }
              rightText={"2 Months left"}
              progress={65}
            />
            <ProgressChart
              startData={"01-01-2023"}
              endDate={"01-05-2023"}
              leftText={"1.1. Publications: "}
              rightText={"2 Months left"}
              progress={40}
            />
            <ProgressChart
              startData={"01-01-2023"}
              endDate={"01-05-2023"}
              leftText={"1.3. Enquery points: "}
              rightText={"2 Months left"}
              progress={29}
            />
            <ProgressChart
              startData={"01-01-2023"}
              endDate={"01-05-2023"}
              leftText={
                "5.1. Notification for enhanced controls or inspections: "
              }
              rightText={"2 Months left"}
              progress={65}
            />
            <ProgressChart
              startData={"01-01-2023"}
              endDate={"01-05-2023"}
              leftText={"1.1. Publications: "}
              rightText={"2 Months left"}
              progress={40}
            />
          </Card>
        </Space>
      </Space>
      {/* <Space style={{ marginTop: 10 }} align="start">
        <Space direction="vertical" size="large" style={{ width: "50vw" }}>
          <Card
            style={{
              boxShadow: "1px 0px 5px -2px rgba(33, 19, 13, 0.3)",
              textAlign: "center",
            }}
            title="Expected vs Actual Measure Completion"
          >
            <Lines />
          </Card>
        </Space>
      </Space> */}
      {/* <Space style={{ marginTop: 10 }} align="start">
        <Space direction="vertical" size="large" style={{ width: "90vw" }}>
          <Card
            style={{ boxShadow: "1px 0px 5px -2px rgba(33, 19, 13, 0.3)" }}
            title="Agency Wise Measure"
          >
            <VBar />
          </Card>
        </Space>
      </Space> */}
      <Space style={{ marginTop: 10 }} align="start">
        <Space direction="vertical" size="large" style={{ width: "90vw" }}>
          <Card
            style={{
              boxShadow: "1px 0px 5px -2px rgba(33, 19, 13, 0.3)",
              textAlign: "center",
            }}
            title="Agency Wise Measure Implementation Status"
          >
            <HBar report={reports && reports.length > 0 ? reports[4] : null} />
          </Card>
        </Space>
      </Space>
    </div>
  );
}

export default Page;
