import { Button, Space, Typography } from "antd";

function Page() {
  return (
    <div className="App">
      <div style={{ background: `url('/home.svg')`, backgroundSize: 'cover', height: '100vh', width: '100vw' }}>
        <div style={{ display: 'flex', justifyContent: 'space-between', padding: '1vh 4vw' }}>
          <img style={{ height: '8vh' }} src="/ntfc.png" alt='logo' />
          <div style={{ display: 'flex', gap: 50, alignItems: 'center' }}>
            <Typography.Text>Agencies</Typography.Text>
            <Typography.Text>Section1</Typography.Text>
            <Typography.Text>Section2</Typography.Text>
            <Typography.Text>Section3</Typography.Text>
            <Typography.Text>Section4</Typography.Text>
            <Typography.Text>Section5</Typography.Text>
            <Typography.Text>Contact</Typography.Text>
            <Button type="primary" onClick={() => window.location.href = '/login'}>Login</Button>
          </div>
        </div>
        <div style={{ height: '80vh', marginLeft: '4vw', justifyContent: 'center', display: 'flex', flexDirection: 'column' }}>
          <Space direction="vertical">
            <div>
              <Typography.Title style={{ margin: 0 }}>National Trade Facilitation Committee</Typography.Title>
              <Typography.Title style={{ fontSize: 50, margin: 0 }}>MONITORING</Typography.Title>
              <Typography.Title style={{ fontSize: 50, margin: 0 }}>TOOL</Typography.Title>
            </div>
            <Button style={{ marginTop: 20, background: '#fac02e', minWidth: '10vw' }} onClick={() => window.location.href = '/signup'}>Signup</Button>
          </Space>
        </div>
        <div style={{ height: '10vh', marginLeft: '4vw' }}>
          <Typography.Text>Copyrights © National Trade Facilitation Committee</Typography.Text>
        </div>
      </div>
    </div>
  );
}

export default Page;
