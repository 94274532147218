import { Col, FloatButton, Row, } from 'antd'
import React, { useEffect, useState } from 'react'
import './dash3.css'

import NewNavBar from './Components/NewNavBar';
import ActionCard from './Components/ActionCard/ActionCard';
import DonorCard from './Components/DonorCard/DonorCard';
import TfaCard from './Components/TFACard/TfaCard';
import InquiryCard from './Components/InquiryCard/InquiryCard';
import UserManagementCard from './Components/UserManagementCard/UserManagementCard';
import ActionConfigurationCard from './Components/ActionConfigurationCard/ActionConfigurationCard';
import { Fragment } from 'react';
import DashBoardStats from './Components/DashBoardStats.jsx/DashboardStats';
import CreateNewUser from './CreateNewUserCard/CreateNewUser';
import TechUserActionCard from './Components/TechUserActionCard/TechUserActionCard';
import DonorUserActionCard from './Components/DonorUserActionCard/DonorUserActionCard';
import MeasureImplementationSummaryCard from './Components/MeasureImplementationSummaryCard/MeasureImplementationSummaryCard';
import { CalendarOutlined } from "@ant-design/icons";
import NewCalender from '../CalanderComponent/NewCalender';
import Chat from '../../../Components/Chat';
import CalenderCard from './Components/DashBoardStats.jsx/components/Calender/CalenderCard';



function Newdashboard3() {

    const [user_level, setUserLevel] = useState(null)
    const [currentAgency, setCurrentAgency] = useState()

    useEffect(() => {
        getUserLevel()

    }, [])

    const getUserLevel = async () => {
        const out = await window.localStorage.getItem('ntfc_level')

        setUserLevel(out)
    }
    //Get Agency selected from the Navbar select
    const handleSelectChange = (agency) => {
        console.log('Agency', agency)
        setCurrentAgency(agency)
    }


    return (
        <Fragment>
            <div className='db-main-div' style={{ position: "relative", zIndex: 5 }}>
                <NewNavBar onSelectChange={handleSelectChange} />
                <div className='dash-body-new' style={{ paddingTop: 90 }}>
                    <Row gutter={[16, 16]} justify={"start"}>
                        <Col xs={24} md={12} lg={9} style={{ height: 300 }}>
                            <Row gutter={[16, 16]} align={"middle"}>

                                {user_level == 4 ?

                                    <Col xs={24}>

                                        <TechUserActionCard user_level={user_level} agency={currentAgency} />
                                    </Col>
                                    : user_level == 3 ?
                                        <Col xs={24}>
                                            <DonorUserActionCard />
                                        </Col>
                                        : <>
                                            <Col xs={24}>
                                                <ActionCard user_level={user_level} agency={currentAgency} />
                                            </Col>
                                            {(user_level == 7 || user_level == 6) &&
                                                <Col xs={10}>
                                                    <ActionConfigurationCard user_level={user_level} agency={currentAgency} />
                                                </Col>
                                            }
                                            {/* {(user_level == 4 || user_level == 5) &&
                                    <Col xs={9}>
                                        <CreateNewUser />
                                    </Col>
                                } */}

                                            <Col xs={(user_level == 7 || user_level == 6) ? 14 : 24}>
                                                <UserManagementCard user_level={user_level} agency={currentAgency} />
                                            </Col>

                                        </>}


                            </Row>
                        </Col>
                        <Col xs={24} md={12} lg={6}>
                            <Row gutter={[16, 16]}>
                                {user_level == 3 ?
                                    <Col xs={24}>
                                        <MeasureImplementationSummaryCard user_level={user_level} agency={currentAgency} />
                                        {/* <MeasureImplemtationSummaryCard agency={currentAgency} /> */}
                                    </Col>
                                    :
                                    <Col xs={24}>
                                        <DonorCard user_level={user_level} agency={currentAgency} />
                                    </Col>
                                }

                                <Col xs={24}>
                                    <InquiryCard user_level={user_level} agency={currentAgency} />
                                </Col>
                            </Row>
                        </Col>
                        <Col xs={24} md={12} lg={9}>
                            <Row gutter={[16, 16]}>
                                <Col xs={24}>
                                    <TfaCard user_level={user_level} agency={currentAgency} />
                                </Col>
                                <Col xs={24}>
                                    <DashBoardStats user_level={user_level} agency={currentAgency} />
                                </Col>

                            </Row>

                        </Col>
                        {/* <Col xs={24} md={9}>
                            <Row gutter={[16, 16]}>
                                <Col xs={24}>
                                    <CalenderCard />
                                </Col>


                            </Row>

                        </Col> */}
                        {/* <Col span={24}>

                            <NewCalender />
                        </Col> */}
                    </Row>
                </div>
                <div style={{ position: "absolute", bottom: 0, left: 0, right: 0, zIndex: -1 }}>
                    <img src='/footerImag.png' alt="footer" style={{ width: "100%" }} />
                </div>
            </div>
            <Chat />
            <FloatButton
                className='custom-float'
                icon={<CalendarOutlined style={{
                    fontSize: '47px',
                    paddingLeft: '-5px',
                    marginLeft: '-12px',
                    marginBottom: 5
                }} />}
                shape="square"
                type="primary"
                description="Calender"
                style={{
                    right: '190px',
                    height: '100px',
                    width: '100px',
                    padding: '2px'
                }} onClick={() => window.location.href = '/newcalender'} />
        </Fragment>
    )
}

export default Newdashboard3