import {
  Button,
  Col,
  ConfigProvider,
  Row,
  Space,
  Table,
  Tag,
  Typography,
  Modal,
  Input,
  Form,
  Select,
  DatePicker,
  Card,
  Tooltip,
  List,
  Avatar,
} from "antd";
import {
  InboxOutlined,
  CloseOutlined,
  EyeOutlined,
  CalendarOutlined,
  FlagOutlined,
  ContainerOutlined,
  CloudUploadOutlined,
  ReconciliationOutlined,
  CalendarFilled,
  FileDoneOutlined,
  BorderlessTableOutlined,
  BoxPlotOutlined,
  BankOutlined,
  EditOutlined,
  DeleteOutlined
} from "@ant-design/icons";
import { useEffect, useState } from "react";
import TabLayout from "./TabLayout";
import { ToastContainer, toast, Zoom } from "react-toastify";
import {
  getMyDonorRequests,
  DownloadFiles,
  addDonorRequest,
  getAgencies,
  getMeasures,
  getAgencyMeasures,
  getMeasuresList,
  editMyDonorRequest,
  deleteMyDonorRequest,
} from "../../api/index.js";
import { getUserData } from "../Donors/getCounts.js";
import Dragger from "antd/es/upload/Dragger.js";
import moment from "moment";
import dayjs from "dayjs";
import VirtualList from "rc-virtual-list";
import { BiCloudDownload } from "react-icons/bi";
import { MainColors, NewColorTheme } from "../../appConst";

const MyDonorRequests = ({ showMessage, setRefreshTimestamp, data, setData }) => {
  const [currentData, setCurrentData] = useState({});
  const [btnLoad, setBtnLoad] = useState(false);
  const [interests, setInterests] = useState([]);
  const [agencies, setAgencies] = useState(null); //agencies list from DB
  const [measure, setMeasure] = useState(null); //selected Measure
  const [subBtnLoad, setLoad] = useState(false);

  const [ntfcLevel, setNtfcLevel] = useState();
  const [myAgency, setMyAgency] = useState("");

  const [toDate, setToDate] = useState();
  const [fromDate, setFromDate] = useState();
  const [fileList, setFileList] = useState([]);
  const [measures, setMeasures] = useState(null); //Measures list from DB
  const [assistance, setAssistance] = useState(0);
  const [purpose, setPurpose] = useState(0);
  const [user, setUser] = useState({});
  const [agency, setAgency] = useState(null);
  const [description, setDescription] = useState("");
  const [uploadLoad, setUploadLoad] = useState(true);
  const [viewDialog, setViewDialog] = useState(false);
  const [currentRecord, setCurrentRecord] = useState(null);
  const [selected, setSelected] = useState(null)
  const [confirmation, showConfirmation] = useState(false)
  const [selectedId, setSelectedID] = useState()
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [isChair, setChair] = useState(false)

  const handleCancel = () => {
    setViewDialog(false);
  };

  // selected Agency

  const [form] = Form.useForm();

  useEffect(() => {
    if (window.localStorage.getItem('ntfc_level') == 6) {
      setChair(true)
    } else {
      setChair(false)
    }
    reloadData();
  }, []);

  const reloadData = () => {
    getMyDonorRequests().then((res) => {
      let sorted = res.data.out.sort(
        (a, b) => new Date(b.created) - new Date(a.created)
      );
      setData(sorted);
      setRefreshTimestamp(Date.now());
    });
    setFromDate(null)
    setToDate(null)
  };

  const getStatus = (status) => {
    if (status == 0) {
      return (
        <Tag
          color={"#0492c2"}
          key={status}
          style={{ width: 80, display: "flex", justifyContent: "center" }}
        >
          {"PENDING"}
        </Tag>
      );
    } else if (status == 1) {
      return (
        <Tag
          color={"#007613"}
          key={status}
          style={{ width: 80, display: "flex", justifyContent: "center" }}
        >
          {"PUBLISHED"}
        </Tag>
      );
    } else if (status == 2) {
      return (
        <Tag
          color={NewColorTheme.primaryColor}
          key={status}
          style={{ width: 80, display: "flex", justifyContent: "center" }}
        >
          {"ASSIGNED"}
        </Tag>
      );
    } else if (status == 999) {
      return (
        <Tag
          color={"#4169E1"}
          key={status}
          style={{ width: 80, display: "flex", justifyContent: "center" }}
        >
          {"REJECTED"}
        </Tag>
      );
    }
  };

  const columns = [
    {
      title: "Creation Date",
      dataIndex: "created",
      key: "created",
      width: 90,
      render: (_, { created }) => (
        <p style={{ whiteSpace: "nowrap" }}>{created ? moment(created).format("DD/MM/YYYY") : "-"}</p>
      ),
    },
    {
      title: "Subject",
      dataIndex: "subject",
      key: "subject",
      width: 100,
      ellipsis: true
    },
    // {
    //     title: 'Agency Name',
    //     dataIndex: 'agency_name',
    //     key: 'agency_name',
    //     render: (_, { scope, agency_name }) => (
    //         <p>{scope == 0 ? "National Wise" : agency_name ? agency_name : myAgency ? myAgency : "-"}</p>
    //     ),
    // },
    {
      title: "Measure",
      dataIndex: "measure_name",
      key: "measure_name",
      width: 210,
      ellipsis: true,
      render: (_, { measure_no, article_no, measure_name }) => (
        <p>{article_no + "." + measure_no + ". " + measure_name}</p>
      ),
    },
    {
      title: "Support Type",
      dataIndex: "assistance",
      key: "assistance",
      width: 100,
      render: (_, { assistance }) => (
        <span
          // color={assistance == 0 ? "#f50" : "#2a8c00"}

          key={assistance}
          style={{
            // width: 150,
            display: "flex",
            justifyContent: "center",
          }}
        >
          {assistance == 0 ? <Tag style={{ display: "flex", justifyContent: "center" }} color={NewColorTheme.primaryColor}>TS/CB</Tag> :
            <Tag style={{ display: "flex", justifyContent: "center" }} color="#08B3D7">OTHER</Tag>}

        </span>
      ),
    },
    {
      title: "Tentative Start Date",
      dataIndex: "implementation_from",
      key: "implementation_from",
      width: 90,
      render: (_, { implementation_from }) => (
        <p style={{ whiteSpace: "nowrap" }}>
          {implementation_from
            ? moment(implementation_from).format("DD/MM/YYYY")
            : "-"}
        </p>
      ),
    },
    {
      title: "Tentative End Date",
      dataIndex: "implementation_to",
      key: "implementation_to",
      width: 100,
      render: (_, { implementation_to }) => (
        <p style={{ whiteSpace: "nowrap" }}>
          {implementation_to
            ? moment(implementation_to).format("DD/MM/YYYY")
            : "-"}
        </p>
      ),
    },
    // {
    //     title: 'Published Date',
    //     dataIndex: 'published_date',
    //     key: 'published_date',
    //     render: (_, { published_date }) => (
    //         <p>{published_date ? moment(published_date).format("DD/MM/YYYY") : "-"}</p>
    //     ),
    // },
    {
      title: "Published Date",
      dataIndex: "published_date",
      key: "published_date",
      width: 90,
      render: (_, { published_date, created }) => (
        <p style={{ whiteSpace: "nowrap" }}>
          {published_date ? dayjs(published_date).format("DD/MM/YYYY") : "-"}
        </p>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: 100,
      render: (_, { status }) => <div >{getStatus(status)}</div>,
    },
    // {
    //   title: "Remarks",
    //   dataIndex: "reject_reason",
    //   key: "reject_reason",
    //   render: (_, { reject_reason, publish_reason }) => (
    //     <p>
    //       {reject_reason
    //         ? reject_reason
    //         : publish_reason
    //           ? publish_reason
    //           : "-"}
    //     </p>
    //   ),
    // },
    {
      title: "Action",
      dataIndex: "publish_reason",
      key: "publish_reason",
      width: 70,
      render: (_, record) => (
        <div style={{ display: "flex", justifyContent: "center", width: "100%" }}>
          <Tooltip title="View Request">
            <Button
              type="secondary"
              size="small"
              onClick={() => {
                form.resetFields()
                console.log("record", record);
                setCurrentRecord(record);
                setViewDialog(true);
              }}
              shape="circle"
              icon={<EyeOutlined style={{ color: "darkgreen" }} />}
            />
          </Tooltip>
          {!isChair &&
            <Tooltip title="Update Request">
              <Button
                type="secondary"
                size="small"
                onClick={() => {
                  setViewDialog(false);
                  setSelected(record);
                }}
                shape="circle"
                icon={<EditOutlined style={{ color: "#946e6e" }} />}
              />
            </Tooltip>
          }

          {!isChair && record?.assignees?.length == 0 &&
            <Tooltip title="Delete Request">
              <Button
                type="secondary"
                size="small"
                onClick={() => {
                  form.resetFields()
                  showConfirmation(true);
                  setSelectedID(record.id);

                }}
                shape="circle"
                icon={<DeleteOutlined style={{ color: "#946e6e" }} />}
              />
            </Tooltip>

          }

        </div>
      ),
    },
  ];

  useEffect(() => {
    if (selected) {
      form.setFieldValue("measure", null);
      setAgency(selected.agency)
      form.setFieldValue("agency", selected.agency);
      form.setFieldValue("agencyName", selected.agency_title);
      form.setFieldValue("subject", selected.subject);
      let from_date = dayjs(selected.implementation_from)
      let to_date = dayjs(selected.implementation_to)
      setFromDate(from_date)
      setToDate(to_date)
      form.setFieldValue("to_date", to_date);
      form.setFieldValue("from_date", from_date);
      form.setFieldValue("description", selected.description);
      form.setFieldValue("assistance", selected.assistance);
    } else {
      form.resetFields();
    }
    // eslint-disable-next-line
  }, [selected]);

  const fetchMeasure = () => {
    getAgencyMeasures(agency).then((me) => {
      console.log("Agency Measure", me);
      const measure_list = me.data?.out?.map((m) => { return { label: `${m.article_no}.${m.measure_no} ${m.measure_name}`, value: m.measure_id } });
      setMeasures(measure_list);
      if (selected) {
        const sel_measure = measure_list.filter((m) => m.value == selected.measure);
        console.log("Selected Measure", selected, sel_measure);
        if (sel_measure.length > 0) {
          const m = sel_measure[0];
          setAgency(m.agency);
          form.setFieldValue("measure", m.value);
        }
      }
    })
  }

  useEffect(() => {
    fetchMeasure(agency);
  }, [agency]);

  const fetchFile = async (file) => {
    let out = await DownloadFiles(file.token);

    let blob = new Blob([out.data], { type: file.type });
    let url = window.URL.createObjectURL(blob);

    let link = document.createElement("a");
    link.href = url;

    link.download = file.url.split("-::-")[1];

    document.body.appendChild(link);
    link.click();
  };

  useEffect(() => {
    getUserDetails();
    refreshRequests();
  }, []);

  const getUserDetails = async function () {
    const user = await getUserData();
    setUser(user);
    console.log("user", user);

    if (window.localStorage.getItem('ntfc_level') == 5 || window.localStorage.getItem('ntfc_level') == 4) {
      setAgency(user.agency_id);
    }
  };

  const refreshRequests = () => {
    getAgencies().then((res) => {
      const agencyList = [
        {
          value: -1,
          label: "National Wise",
        },
        ...res.data.out,
        {
          value: -2,
          label: "Other",
        },
      ];
      res.data.out.forEach((item, idx) => {
        if (item.value == window.localStorage.getItem("ntfc_agency")) {
          setMyAgency(item.label);
        }
      });
      console.log(agencyList);
      setAgencies(agencyList);
    });

    getMyDonorRequests().then((out3) => {
      setInterests(out3.data.out);
    });
  };

  const onFromDateChange = (dateString) => {
    console.log(dateString)
    setFromDate(dateString);
    if (selected) {
      form.resetFields(['to_date'])
      setToDate(null)
    }
  };

  const onToDateChange = (dateString) => {
    setToDate(dateString);
  };

  useEffect(() => {
    setLocalStorageData();
  });

  const setLocalStorageData = async () => {
    setNtfcLevel(await window.localStorage.getItem("ntfc_level"));
  };

  const onFinish = (values) => {
    setLoad(true);
    console.log("Agency", agency, selected);
    const formData = new FormData();
    if (fileList?.length > 0) {
      formData.append("request_file", fileList[0]);
    }

    formData.append("subject", values.subject);
    formData.append("description", values.description);
    formData.append("measure", values.measure);
    formData.append("assistance", values.assistance);

    formData.append("implementation_from", dayjs(fromDate).format("DD/MM/YYYY"));
    formData.append("implementation_to", dayjs(toDate).format("DD/MM/YYYY"));

    values["assistance"] = assistance;

    if (purpose === 2) {
      values["agency"] = agency;
      formData.set("agency", agency);
    }

    if (window.localStorage.getItem('ntfc_level') !== 6 && window.localStorage.getItem('ntfc_level') !== 7) {
      values["agency"] = user.agency_id;
      values["scope"] = 2;
      formData.set("agency", user.agency_id);
      formData.set("scope", 2);
      console.log("user---------");
    } else {
      values["scope"] = purpose;
      formData.set("scope", purpose);
    }

    if (agency == "-2") {
      values["scope"] = 2;
      values["agency_name"] = values.agencyName;
      formData.set("scope", 2);
      formData.set("agency_name", values.agencyName);
    }
    if (window.localStorage.getItem('ntfc_level') == 6 || window.localStorage.getItem('ntfc_level') == 7) {
      formData.set("agency", agency);
    }

    setUploadLoad(false);

    form.setFieldValue("description", description);
    if (selected) {


      let body = {
        id: selected.id,
        subject: values.subject,
        description: values.description,
        measure: values.measure,
        assistance: values.assistance,
        implementation_from: dayjs(fromDate).format("DD/MM/YYYY"),
        implementation_to: dayjs(toDate).format("DD/MM/YYYY"),
        agency: agency
      }

      if (purpose === 2) {

        body.agency = agency

      }

      if (window.localStorage.getItem('ntfc_level') !== 6 && window.localStorage.getItem('ntfc_level') !== 7) {
        body.agency = user.agency_id
        body.scope = 2

      } else {

        body.scope = purpose

      }

      if (agency == "-2") {

        body.agency = user.agency_id
        body.scope = 2
        body.agency_name = values.agencyName

      }
      if (window.localStorage.getItem('ntfc_level') == 6 || window.localStorage.getItem('ntfc_level') == 7) {

        body.agency = agency ?? selected.agency;
      }

      editMyDonorRequest(body)
        .then((out) => {
          if (out.data.status === 200) {
            form.resetFields();
            refreshRequests();
            setDescription("");
            toast.success("Donor request has been updated Successfully", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            });
            setLoad(false);
            setFileList([]);
            setUploadLoad(true);
            setAgency(null)
            setSelected(null)
            setFromDate(null)
            setToDate(null)
          } else {
            toast.error(out.data.message, {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            });
            setLoad(false);
            setFileList([]);
            setUploadLoad(true);
          }
          reloadData()
        })
        .catch((err) => {
          console.log(err);
          setDescription("");
          toast.error("Error on donor request", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          setLoad(false);
          setFileList([]);
          setAgency(null)
        });
      setFileList([]);
    } else {
      addDonorRequest(formData)
        .then((out) => {
          if (out.data.status === 200) {
            form.resetFields();
            refreshRequests();
            setDescription("");
            toast.success("Donor Request Created Successfully", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: false,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            });
            setLoad(false);
            setFileList([]);
            setUploadLoad(true);
            setAgency(null)
            setFromDate(null)
            setToDate(null)
            form.resetFields()
            setMeasure(null);
          } else {
            toast.error(out.data.message, {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: false,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            });
            setLoad(false);
            setFileList([]);
            setUploadLoad(true);
          }
          reloadData()
        })
        .catch((err) => {
          console.log(err);
          setDescription("");
          toast.error("Error on donor request", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: false,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          setLoad(false);
          setFileList([]);
          setAgency(null)
        });
      setFileList([]);
    }


  };

  const deleteRequest = async () => {
    setConfirmLoading(true)
    let formData = {
      id: selectedId
    }
    deleteMyDonorRequest(formData)
      .then((out) => {
        console.log("delete", out)
        if (out.data.status === 200) {
          form.resetFields();
          refreshRequests();

          toast.success("Donor request has been deleted successfully", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: false,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          setLoad(false);
          setFileList([]);
          setUploadLoad(true);
          setAgency(null)
          setConfirmLoading(false)
          showConfirmation(false)

        } else {
          toast.error(out.data.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: false,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          setLoad(false);
          setFileList([]);
          setUploadLoad(true);
          setConfirmLoading(false)
          showConfirmation(false)
        }
        reloadData()
      })
  }

  const onFinishFailed = (errorInfo) => {
    // toast.error("Request creation unsuccessful", {
    //   position: "top-right",
    //   autoClose: 5000,
    //   hideProgressBar: false,
    //   closeOnClick: true,
    //   pauseOnHover: true,
    //   draggable: true,
    //   progress: undefined,
    //   theme: "colored",
    // });
  };

  const props = {
    name: "file",
    multiple: false,
    // action: ,

    onChange(info) {
      console.log("FIle", info);
      const { status } = info.file;
      if (status !== "uploading") {
        console.log(info.file, info.fileList);
      }
      if (status === "done") {
        toast.success(`${info.file.name} file uploaded successfully.`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        handleFileUpload(info.file);
      } else if (status === "error") {
        toast.error(`${info.file.name} file upload failed.`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    },
    onDrop(e) {
      console.log("Dropped files", e.dataTransfer.files);
    },
  };

  async function handleFileUpload(file) {
    setFileList([file]);
  }

  return (
    <div className="custom-scroll-div tab-layout-div">
      <TabLayout
        title="Create Donor Request"
        formSide={
          isChair ? false :
            <div>
              <Form
                form={form}
                layout="vertical"
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
              >
                {/* {(window.localStorage.getItem('ntfc_level') === 6 || window.localStorage.getItem('ntfc_level') === 7) &&
                                        <Form.Item
                                            // label="Purpose"
                                            required name="purpose"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: <p style={{ marginLeft: "12px" }}>Purpose is required</p>
                                                }]}

                                        >
                                            <Select
                                                onChange={(val) => setPurpose(val)}
                                                placeholder="Purpose"
                                                options={
                                                    [
                                                        { value: 1, label: 'National Wise' },
                                                        { value: 2, label: 'Agency Wise' },
                                                    ]
                                                }
                                            />
                                        </Form.Item>} */}

                {window.localStorage.getItem('ntfc_level') != 5 && window.localStorage.getItem('ntfc_level') != 4 && (
                  <Form.Item
                    required
                    rules={[
                      {
                        required: true,
                        message: (
                          <p style={{ marginLeft: "12px" }}>Agency is required</p>
                        ),
                      },
                    ]}
                    label="Agency"
                    name="agency"
                  >
                    <Select
                      placeholder="Agency"
                      onChange={(a) => {
                        setAgency(a);


                      }}
                      options={
                        agencies &&
                        agencies.filter(
                          (element) =>
                            element.agency_type === 1 || !element.agency_type
                        )
                      }
                    />
                  </Form.Item>
                )}

                {agency && agency == "-2" && (
                  <Form.Item
                    required
                    rules={[
                      {
                        required: true,
                        message: (
                          <p style={{ marginLeft: "12px" }}>
                            Agency Name is required
                          </p>
                        ),
                      },
                      { min: 5, message: 'At least 5 characters required' },
                    ]}
                    name="agencyName"
                  >
                    <Input type="text" placeholder="Agency Name" />
                  </Form.Item>
                )}

                <Form.Item
                  label="Subject"
                  required
                  rules={[
                    {
                      required: true,
                      message: (
                        <p style={{ marginLeft: "12px" }}>Subject is required</p>
                      ),
                    },
                    {
                      pattern:
                        /^(?=.*[a-zA-Z])[a-zA-Z0-9!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/? ]+$/, //if field has only spaces, numbers or special characters
                      message: (
                        <p style={{ marginLeft: "12px" }}>
                          Please enter a valid title with alphabetical characters
                        </p>
                      ),
                    },
                  ]}
                  name="subject"
                >
                  <Input type="text" placeholder="Subject" />
                </Form.Item>
                <Form.Item
                  label="Measure"
                  required
                  rules={[
                    {
                      required: true,
                      message: (
                        <p style={{ marginLeft: "12px" }}>Measure is required</p>
                      ),
                    },
                  ]}
                  name="measure"
                >
                  <Select
                    placeholder="Select Measure"
                    options={measures}
                    value={measure}
                    onChange={(val) => {
                      setMeasure(val);
                    }}
                  />
                </Form.Item>

                {/* <Col className="gutter-row" span={24}> */}
                <Form.Item
                  label="Tentative Start Date"
                  name="from_date"
                  required
                  valuePropName="date"
                  rules={[
                    {
                      required: true,
                      message: (
                        <p style={{ marginLeft: "12px" }}>
                          Tentative Start Date is required
                        </p>
                      ),
                    },
                  ]}
                >
                  <DatePicker
                    style={{ width: "100%" }}
                    onChange={onFromDateChange}
                    format="DD/MM/YYYY"
                    disabledDate={(current) =>
                      current && current.isBefore(dayjs(), 'day')
                    }
                    value={dayjs(fromDate).isValid() ? dayjs(fromDate) : null}


                  />
                </Form.Item>
                {/* </Col> */}

                {/* <Col className="gutter-row" span={24}> */}
                <Form.Item
                  label="Tentative End Date"
                  name="to_date"
                  required
                  valuePropName="date"
                  rules={[
                    {
                      required: true,
                      message: (
                        <p style={{ marginLeft: "12px" }}>
                          Tentative End Date is required
                        </p>
                      ),
                    },
                  ]}
                >
                  <DatePicker
                    style={{ width: "100%" }}
                    onChange={onToDateChange}
                    format="DD/MM/YYYY"
                    disabledDate={(current) =>
                      current && current.isBefore(form.getFieldValue("from_date"))
                    }
                    value={dayjs(toDate).isValid() ? dayjs(toDate) : null}
                  />
                </Form.Item>
                {/* </Col> */}

                {uploadLoad && !selected ? (
                  <div style={{ margin: "30px 0" }}>
                    <p>
                      Please ensure that only one attachment is uploaded at a
                      time, and the file size should be limited to a maximum of
                      5MB.
                    </p>
                    <Dragger
                      {...props}
                      showUploadList={{ showProgress: true }}
                      maxCount={1}
                      fileList={fileList}
                      onRemove={() => {
                        setFileList([]);
                      }}
                      beforeUpload={(file) => {
                        const MAX_SIZE = 5 * 1024 * 1024; // 5 MB
                        if (file.size > MAX_SIZE) {
                          toast.error(
                            "File size exceeds limit! The maximum file upload size is 5MB.",
                            {
                              position: "top-right",
                              autoClose: 5000,
                              hideProgressBar: false,
                              closeOnClick: true,
                              pauseOnHover: true,
                              draggable: true,
                              progress: undefined,
                              theme: "colored",
                            }
                          );
                          return false;
                        } else {
                          handleFileUpload(file);
                        }
                        return false; // to prevent automatic upload
                        // to prevent automatic upload
                      }}
                    >
                      <p className="ant-upload-drag-icon">
                        <InboxOutlined tyle={{ color: "#33363F" }} />
                      </p>
                      <p className="ant-upload-text">
                        Click or drag a file to this area to upload
                      </p>
                    </Dragger>
                  </div>
                ) : (
                  ""
                )}

                <Form.Item
                  label="Description"
                  required
                  rules={[
                    {
                      required: true,
                      message: (
                        <p style={{ marginLeft: "12px" }}>
                          Description is required
                        </p>
                      ),
                    },
                    {
                      pattern:
                        /^(?=.*[a-zA-Z])[a-zA-Z0-9!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/? ]+$/, //if field has only spaces, numbers or special characters
                      message: (
                        <p style={{ marginLeft: "12px" }}>
                          Please enter a valid project description with
                          alphabetical characters
                        </p>
                      ),
                    },
                  ]}
                  name="description"
                >
                  <Input.TextArea
                    placeholder="Description"
                    rows={5}
                    maxLength={200}
                    showCount
                  />
                </Form.Item>

                <Form.Item
                  label="Support Type"
                  required
                  rules={[
                    {
                      required: true,
                      message: (
                        <p style={{ marginLeft: "12px" }}>
                          Support Type is required
                        </p>
                      ),
                    },
                  ]}
                  name="assistance"
                >
                  <Select
                    placeholder="Support Type"
                    onChange={(val) => setAssistance(val)}
                    options={[
                      {
                        value: 0,
                        label: "Technical Support or Capacity Building",
                      },
                      { value: 1, label: "Other" },
                      // {value: 2, label: 'Other' },
                    ]}
                  />
                </Form.Item>
                <Form.Item>
                  <Space style={{ display: "flex", justifyContent: "flex-end" }}>
                    <Button size="middle" onClick={() => { form.resetFields(); setSelected(null); setFromDate(null); setToDate(null); setAgency(null); }}>Cancel</Button>

                    <Button
                      size="middle"
                      loading={subBtnLoad}
                      type="primary"
                      htmlType="submit"
                      disabled={window.localStorage.getItem('view_only') == 1}
                    >
                      {selected ? "Save" : "Submit"}
                    </Button>

                  </Space>
                </Form.Item>
              </Form>
            </div>
        }
        table={
          <Table
            scroll={{
              y: 400,
            }}
            size="small"
            className="custom-table"
            rowClassName={(record, index) => index % 2 === 0 ? 'white-row' : 'gray-row'}
            // className="custom-table-header"
            columns={columns}
            dataSource={data}
            pagination={{
              pageSize: 5,
              total: data?.length,
              showSizeChanger: false,
            }}
          // onRow={(record, rowIndex) => {
          //   return {
          //     onClick: () => {
          //       setCurrentRecord(record);
          //       setViewDialog(true);
          //     },
          //   };
          // }}
          />
        }
      />
      <Modal
        closable={true}
        centered
        className="custom-modal"
        width={700}
        // style={{ height: "70vh" }}
        footer={false}
        onCancel={handleCancel}
        title={
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div>
              {" "}
              <Typography className="custom-modal-title" level={2}>
                {"Request Details"}
              </Typography>
            </div>
          </div>
        }
        open={viewDialog}
      >
        <div className="custom-modal-body custom-scroll-div" style={{ height: "70vh", overflow: "auto", }}>
          <Row>
            <Col xs={24}>
              <Row gutter={[8, 8]} align={"top"}>
                <Col xs={24}>
                  <Row gutter={[8, 8]}>
                    <Col xs={2}>
                      <ReconciliationOutlined style={{ fontSize: 22 }} />
                    </Col>
                    <Col xs={22}>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "flex-start",
                        }}
                      >
                        <Typography.Title level={5}>
                          {" "}
                          Subject
                        </Typography.Title>
                        <Typography> {currentRecord?.subject}</Typography>
                      </div>
                    </Col>
                  </Row>
                </Col>
                <Col xs={24}>
                  <Row gutter={[8, 8]}>
                    <Col xs={2}>
                      <BankOutlined style={{ fontSize: 22 }} />
                    </Col>
                    <Col xs={22}>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "flex-start",
                        }}
                      >
                        <Typography.Title level={5}>
                          {" "}
                          Agency
                        </Typography.Title>
                        <Typography> {currentRecord?.agency_title}</Typography>
                      </div>
                    </Col>
                  </Row>
                </Col>
                <Col xs={12}>
                  <Row gutter={[8, 8]} align={"top"}>
                    <Col xs={4}>
                      <CalendarFilled style={{ fontSize: 22 }} />
                    </Col>
                    <Col xs={20}>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "flex-start",
                        }}
                      >
                        <Typography.Title level={5}>
                          {" "}
                          Created Date
                        </Typography.Title>

                        <Typography>
                          {" "}
                          {dayjs(currentRecord?.created).format("DD/MM/YYYY")}
                        </Typography>
                      </div>
                    </Col>
                  </Row>
                </Col>
                <Col xs={24}>
                  <Row gutter={[8, 8]} align={"top"}>
                    <Col xs={2}>
                      <BoxPlotOutlined style={{ fontSize: 22 }} />
                    </Col>
                    <Col xs={22}>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "flex-start",
                        }}
                      >
                        <Typography.Title level={5}> Measure</Typography.Title>

                        <Typography>
                          {" "}
                          {currentRecord?.article_no +
                            "." +
                            currentRecord?.measure_no +
                            ". " +
                            currentRecord?.measure_name}
                        </Typography>
                      </div>
                    </Col>
                  </Row>
                </Col>
                <Col xs={24}>
                  <Row gutter={[8, 8]} align={"top"}>
                    <Col xs={2}>
                      <BorderlessTableOutlined style={{ fontSize: 22 }} />
                    </Col>
                    <Col xs={22}>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "flex-start",
                        }}
                      >
                        <Typography.Title level={5}>
                          {" "}
                          Support Type
                        </Typography.Title>

                        <Typography>
                          {" "}
                          {currentRecord?.assistance == 0
                            ? "Technical Support or Capacity Building"
                            : "Other"}
                        </Typography>
                      </div>
                    </Col>
                  </Row>
                </Col>
                {currentRecord?.implementation_from && (
                  <Col xs={12}>
                    <Row gutter={[8, 8]} align={"top"}>
                      <Col xs={4}>
                        <CalendarOutlined style={{ fontSize: 22 }} />
                      </Col>
                      <Col xs={20}>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "flex-start",
                          }}
                        >
                          <Typography.Title level={5}>
                            {" "}
                            Tentative Start Date
                          </Typography.Title>

                          <Typography>
                            {" "}
                            {dayjs(currentRecord?.implementation_from).format(
                              "DD/MM/YYYY"
                            )}
                          </Typography>
                        </div>
                      </Col>
                    </Row>
                  </Col>
                )}

                {currentRecord?.implementation_to && (
                  <Col xs={12}>
                    <Row gutter={[8, 8]} align={"top"}>
                      <Col xs={4}>
                        <CalendarOutlined style={{ fontSize: 22 }} />
                      </Col>
                      <Col xs={20}>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "flex-start",
                          }}
                        >
                          <Typography.Title level={5}>
                            {" "}
                            Tentative End Date
                          </Typography.Title>

                          <Typography>
                            {" "}
                            {dayjs(currentRecord?.implementation_to).format(
                              "DD/MM/YYYY"
                            )}
                          </Typography>
                        </div>
                      </Col>
                    </Row>
                  </Col>
                )}

                {currentRecord?.published_date && (
                  <Col xs={12}>
                    <Row gutter={[8, 8]} align={"top"}>
                      <Col xs={4}>
                        <CalendarOutlined style={{ fontSize: 22 }} />
                      </Col>
                      <Col xs={20}>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "flex-start",
                          }}
                        >
                          <Typography.Title level={5}>
                            {" "}
                            Published Date
                          </Typography.Title>

                          <Typography>
                            {" "}
                            {currentRecord?.published_date
                              ? dayjs(currentRecord?.published_date).format(
                                "DD/MM/YYYY"
                              )
                              : "-"}
                          </Typography>
                        </div>
                      </Col>
                    </Row>
                  </Col>
                )}

                <Col xs={24}>
                  <Row gutter={[8, 8]} align={"top"}>
                    <Col xs={2}>
                      <ContainerOutlined style={{ fontSize: 22 }} />
                    </Col>
                    <Col xs={22}>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "flex-start",
                        }}
                      >
                        <Typography.Title level={5}>
                          {" "}
                          Description
                        </Typography.Title>

                        <Typography>
                          {" "}
                          {currentRecord?.description
                            ? currentRecord?.description
                            : "-"}
                        </Typography>
                      </div>
                    </Col>
                  </Row>
                </Col>
                {currentRecord?.attachments?.length > 0 && (
                  <Col xs={24}>
                    <Row gutter={[8, 8]} align={"top"}>
                      <Col xs={2}>
                        <CloudUploadOutlined style={{ fontSize: 22 }} />
                      </Col>
                      <Col xs={22}>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "flex-start",
                          }}
                        >
                          <Typography.Title level={5}>
                            {" "}
                            Attachment
                          </Typography.Title>

                          {currentRecord?.attachments?.length > 0 &&
                            currentRecord?.attachments.map((element, key) => {
                              return (
                                <div
                                  style={{
                                    alignItems: "center",
                                    display: "flex",
                                    justifyContent: "space-between",
                                    width: "100%",
                                    cursor: "pointer",
                                    background: MainColors.LIGHTBACKCOLOR,
                                    borderRadius: 10,
                                    padding: 10,

                                    ":hover": {
                                      background: MainColors.DARKBACKCOLOR,
                                      color: "white",
                                      // Add any additional styles you want for the hover effect
                                    },
                                  }}
                                  key={key}
                                  onClick={() => fetchFile(element)}
                                >
                                  <div>
                                    <img
                                      src="/file.png"
                                      alt="file"
                                      style={{ height: 50, width: 50 }}
                                    />
                                    {element?.url &&
                                      (element?.url
                                        ?.toString()
                                        .split("-::-"))[1]}
                                  </div>
                                  <BiCloudDownload
                                    style={{ fontSize: 32, color: NewColorTheme.primaryColor }}
                                  />
                                </div>
                              );
                            })}
                        </div>
                      </Col>
                    </Row>
                  </Col>
                )}
                {(currentRecord?.reject_reason || currentRecord?.publish_reason) &&
                  <Col xs={24}>
                    <Row gutter={[8, 8]} align={"top"}>
                      <Col xs={2}>
                        <FileDoneOutlined style={{ fontSize: 22 }} />
                      </Col>
                      <Col xs={22}>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "flex-start",
                          }}
                        >
                          <Typography.Title level={5}> Remarks</Typography.Title>

                          <Typography>
                            {" "}
                            {currentRecord?.reject_reason
                              ? currentRecord?.reject_reason
                              : currentRecord?.publish_reason
                                ? currentRecord?.publish_reason
                                : "-"}
                          </Typography>
                          {currentRecord?.published_attachments?.length > 0 &&
                            currentRecord?.published_attachments.map((element, key) => {
                              return (
                                <div
                                  style={{
                                    alignItems: "center",
                                    display: "flex",
                                    justifyContent: "space-between",
                                    width: "100%",
                                    cursor: "pointer",
                                    background: MainColors.LIGHTBACKCOLOR,
                                    borderRadius: 10,
                                    padding: 10,

                                    ":hover": {
                                      background: MainColors.DARKBACKCOLOR,
                                      color: "white",
                                      // Add any additional styles you want for the hover effect
                                    },
                                  }}
                                  key={key}
                                  onClick={() => fetchFile(element)}
                                >
                                  <div>
                                    <img
                                      src="/file.png"
                                      alt="file"
                                      style={{ height: 50, width: 50 }}
                                    />
                                    {element?.url &&
                                      (element?.url
                                        ?.toString()
                                        .split("-::-"))[1]}
                                  </div>
                                  <BiCloudDownload
                                    style={{ fontSize: 32, color: NewColorTheme.primaryColor }}
                                  />
                                </div>
                              );
                            })}
                        </div>
                      </Col>
                    </Row>
                  </Col>
                }

                {console.log("------------------", currentData)}
                {currentRecord?.assignees?.length > 0 && (
                  <Col span={24}>
                    <Row gutter={[8, 8]} align={"top"}>
                      <Col xs={2}>
                        <FlagOutlined style={{ fontSize: 22 }} />
                      </Col>
                      <Col xs={22}>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "flex-start",
                            width: "100%",
                          }}
                        >
                          <Typography.Title level={5}>
                            {" "}
                            Assigned Donors
                          </Typography.Title>

                          <List style={{ width: "100%" }}>
                            <VirtualList
                              data={currentRecord.assignees}
                              height={150}
                              itemHeight={47}
                              itemKey="id"
                            // onScroll={onScroll}
                            >
                              {(item, key) => (
                                <List.Item
                                  key={key}
                                  style={{ cursor: "pointer" }}
                                >
                                  {console.log("item ----->>", item)}
                                  <List.Item.Meta
                                    avatar={
                                      <Avatar
                                        src={
                                          <img
                                            src={
                                              "https://gw.alipayobjects.com/zos/rmsportal/KDpgvguMpGfqaHPjicRK.svg"
                                            }
                                            alt="avatar"
                                          />
                                        }
                                      />
                                    }
                                    // title={<p>{item.title}</p>}
                                    title={item.name}
                                    // description={"By " + item.first_name + " " + item.last_name}

                                    description={
                                      new Date(item.created)
                                        .toISOString()
                                        .slice(8, 10) +
                                      "/" +
                                      new Date(item.created)
                                        .toISOString()
                                        .slice(5, 7)
                                    }
                                  />
                                </List.Item>
                              )}
                            </VirtualList>
                          </List>
                        </div>
                      </Col>
                    </Row>
                  </Col>
                )}

                <Col xs={22}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start",
                      width: "100%",
                    }}
                  >
                    <Typography.Title level={5}>
                      {" "}
                      Interested Donors
                    </Typography.Title>
                    {currentRecord?.interests?.length > 0 ? (
                      <List style={{ width: "100%" }}>
                        <VirtualList
                          data={currentRecord.interests}
                          height={150}
                          itemHeight={47}
                          itemKey="id"
                        // onScroll={onScroll}
                        >
                          {(item, key) => (
                            <List.Item
                              key={key}
                              style={{ cursor: "pointer" }}
                            >
                              {console.log("item ----->>", item)}
                              <List.Item.Meta
                                avatar={
                                  <Avatar
                                    src={
                                      <img
                                        src={
                                          "https://gw.alipayobjects.com/zos/rmsportal/KDpgvguMpGfqaHPjicRK.svg"
                                        }
                                        alt="avatar"
                                      />
                                    }
                                  />
                                }
                                // title={<p>{item.title}</p>}
                                title={item.name}
                                // description={"By " + item.first_name + " " + item.last_name}

                                description={
                                  new Date(item.created)
                                    .toISOString()
                                    .slice(8, 10) +
                                  "/" +
                                  new Date(item.created)
                                    .toISOString()
                                    .slice(5, 7)
                                }
                              />
                              {/* <div>
                                <Space>
                                  <Button
                                    type="primary"
                                    onClick={() => {
                                    }}
                                    disabled={window.localStorage.getItem('view_only') == 1}
                                  >
                                    {" "}
                                    {item.checked ? "Unassign" : "Assign"}
                                  </Button>
                                </Space>
                              </div> */}
                            </List.Item>
                          )}
                        </VirtualList>
                      </List>
                    ) : (
                      <Typography.Paragraph>
                        {" "}
                        There is no Interested Donors for this Projec
                      </Typography.Paragraph>
                    )}
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </Modal>
      <Modal
        title="Confirmation "
        open={confirmation}
        onOk={deleteRequest}
        confirmLoading={confirmLoading}
        onCancel={() => showConfirmation(false)}
      >
        <p>{"Are you sure, you want to delete this request?"}</p>
      </Modal>
      <ToastContainer />
    </div>
  );
};

export default MyDonorRequests;
