import { Card, Col, Row, Tooltip, Typography } from "antd";
import { useEffect, useState } from "react";
import { getDonorCardData, getDonorCounts } from "../../../api";
import { getUserData } from "../../Donors/getCounts.js";
import { NewColorTheme } from "../../../appConst";
import './donortabs.css'

const DonorTabs = ({ tab, setTab, refreshTimestamp, donorCounts, setDonorCounts }) => {
  const [counts, setCounts] = useState(null);
  const [user, setUser] = useState(null);
  const [loaded, setLoad] = useState(false);
  useEffect(() => {
    console.log("refreshTimestamp", refreshTimestamp)
    fetchUser();
  }, []);



  useEffect(() => {

  }, [tab])


  const fetchUser = async () => {
    const userInfo = await getUserData();
    setUser(userInfo);

    if (userInfo.user_level == 3) {
      getDonorCardData(window.localStorage.getItem("ntfc_agency")).then(
        (out) => {
          setDonorCounts(out.data.out);
        }
      );
    } else {
      getDonorCounts().then((res) => {
        setCounts(res?.data.out);
      });
    }

    setLoad(true);
  };

  useEffect(() => {
    getDonorCounts().then((res) => {
      setCounts(res?.data.out);

      console.log("counts 1 ", res?.data.out)
    });


  }, [refreshTimestamp]);

  useEffect(() => {
    getDonorCardData(window.localStorage.getItem("ntfc_agency")).then(
      (out) => {
        setDonorCounts(out.data.out);
        console.log("counts 2", out?.data.out)
      }
    );

  }, [refreshTimestamp]);



  const TabTile = ({ title, tail, pos, tootile }) => (
    <Col md={6} xs={24}>
      <Tooltip title={tootile}>
        <Card
          style={{
            height: "10vh",
            border: pos == tab ? `2px solid ${NewColorTheme.primaryColor}` : "none",
            background: "#f5f9ff",
            display: "flex",
            alignItems: "center",
            cursor: "pointer",
          }}
          onClick={() => setTab(pos)}
        >
          <Row gutter={16}>
            <Col
              span={14}
              style={{
                display: "flex",
                textAlign: "center",
                alignItems: "center",
              }}
            >
              <Typography
                className="tab-title"
                style={{ margin: 0, color: NewColorTheme.primaryColor, }}
                level={2}
              >
                {title}
              </Typography>
              {/* <Typography.Title style={{ margin: 0, color: NewColorTheme.primaryColor }} level={2}>Inquiries</Typography.Title> */}
            </Col>
            <Col
              span={10}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                color: NewColorTheme.primaryColor,
              }}
            >
              {tail}
            </Col>
          </Row>
        </Card>
      </Tooltip>
    </Col>
  );

  return (
    <div style={{ paddingLeft: "40px", paddingRight: "25px", width: "100%" }}>
      {loaded && (
        <Row gutter={[16, 16]}>
          {window.localStorage.getItem('ntfc_level') == 3 ? (
            <>
              <TabTile
                title="Donor Requests"
                pos={0}
                tail={
                  <Typography
                    className="tab-count"
                    style={{ margin: 0, color: NewColorTheme.primaryColor }}
                    level={1}
                  >
                    {donorCounts ? donorCounts.requests : 0}
                  </Typography>
                }
              />
              <TabTile
                title="Contributions"
                pos={1}
                tail={
                  <Typography
                    className="tab-count"
                    style={{ margin: 0, color: NewColorTheme.primaryColor }}
                    level={1}
                  >
                    {donorCounts ? donorCounts.contributions : 0}
                  </Typography>
                }
              />
              <TabTile
                title="Donor Interests"
                pos={2}
                tail={
                  <Typography
                    className="tab-count"
                    style={{ margin: 0, color: NewColorTheme.primaryColor }}
                    level={1}
                  >
                    {donorCounts ? (donorCounts.interests_submitted + donorCounts.requests_chosen) : 0}
                  </Typography>
                }
              />
              <TabTile
                title="Confirmed"
                pos={3}
                tail={
                  <Typography
                    className="tab-count"
                    style={{ margin: 0, color: NewColorTheme.primaryColor }}
                    level={1}
                  >
                    {donorCounts ? (donorCounts.confirmed_interests + donorCounts.confirmed_requests) : 0}
                  </Typography>
                }
              />
            </>
          ) : (
            <>
              <TabTile
                tootile={
                  "All the donor requests created by agencies or NTFC secretariat"
                }
                title="All Donor Requests"
                pos={0}
                tail={
                  <Typography
                    className="tab-count"
                    style={{ margin: 0, color: NewColorTheme.primaryColor }}
                    level={1}
                  >
                    {counts ? counts.donor_requests : 0}
                  </Typography>
                }
              />
              <TabTile
                tootile={"Donor requests created by my agency"}
                title="My Donor Requests"
                pos={1}
                tail={
                  <Typography
                    className="tab-count"
                    style={{ margin: 0, color: NewColorTheme.primaryColor }}
                    level={1}
                  >
                    {counts ? counts.my_donor_requests : 0}
                  </Typography>
                }
              />
              <TabTile
                tootile={"All active donor interests created by the donors"}
                title="All Donor Interests"
                pos={2}
                tail={
                  <Typography
                    className="tab-count"
                    style={{ margin: 0, color: NewColorTheme.primaryColor }}
                    level={1}
                  >
                    {counts ? counts.donor_interests : 0}
                  </Typography>
                }
              />
              {window.localStorage.getItem('ntfc_level') != 5 && window.localStorage.getItem('ntfc_level') != 4 && (
                <>
                  <TabTile
                    tootile={"Donor requests projects which have received expression of interest from the donors"}
                    title="Confirmed Projects"
                    pos={3}
                    tail={
                      <Typography
                        className="tab-count"
                        style={{ margin: 0, color: NewColorTheme.primaryColor }}
                        level={1}
                      >
                        {counts ? (counts.confirmed_interests + counts.confirmed_requests) : 0}
                      </Typography>
                    }
                  />
                  {/* <TabTile title="Agency Interests for Projects" pos={4} tail={<Typography.Title style={{ fontSize: 50, margin: 0, color: NewColorTheme.primaryColor }} level={1}>{counts ? counts.donor_interest_interests : 0}</Typography.Title>} /> */}
                </>
              )}
              {user && (window.localStorage.getItem('ntfc_level') == 4 || window.localStorage.getItem('ntfc_level') == 5) && (
                <>
                  <TabTile
                    tootile={
                      "Donor requests which were shown interest by the donors and assigned by NTFC"
                    }
                    title="Assigned"
                    pos={3}
                    tail={
                      <Typography
                        className="tab-count"
                        style={{ margin: 0, color: NewColorTheme.primaryColor }}
                        level={1}
                      >
                        {counts ? counts.assigned : 0}
                      </Typography>
                    }
                  />
                  {/* <TabTile title="Agency Interests for Projects" pos={4} tail={<Typography.Title style={{ fontSize: 70, margin: 0, color: NewColorTheme.primaryColor }} level={1}>{counts ? counts.donor_interest_interests : 0}</Typography.Title>} /> */}
                </>
              )}
            </>
          )}
        </Row>
      )}
    </div>
  );
};

export default DonorTabs;
