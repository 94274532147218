import { Breadcrumb, Button, Col, Input, Modal, Row, Typography, Upload, Space, Avatar, List, Badge, Card, Divider, message, Popover, Tooltip, Drawer, ConfigProvider, Form } from 'antd';
import { CommentOutlined, PlusOutlined, DotChartOutlined, LoadingOutlined, InboxOutlined, EllipsisOutlined, AlignLeftOutlined, UserOutlined, UserAddOutlined, InteractionOutlined, CloudUploadOutlined, ReconciliationOutlined, HistoryOutlined } from '@ant-design/icons';
import React, { useEffect, useState } from "react";
import { EditText } from 'react-edit-text';
import StatusCompo from './StatusCompo';
import Participants from './Participants';
import { addAgencySubAction, AddAgencyActionComment, getAgencyActivities, searchParticipants, addParticipants, removeParticipants, getActionParticipants, getAllUsers, changeAgencyActionStatus } from '../../api';
import Dragger from 'antd/es/upload/Dragger';
import VirtualList from 'rc-virtual-list';
import Search from 'antd/es/input/Search';
import { BsDot } from "react-icons/bs";
import TextArea from 'antd/es/input/TextArea';
import TitleBox from '../../Components/TitleBox/TitleBox';
import Title from 'antd/es/skeleton/Title';
import DocumentLoader from '../../Components/DocumentLoader/DocumentLoader';

const { Text } = Typography;


const getBase64 = (file) =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
    });
const ContainerHeight = 600;



const SubActionActivities = ({ action, create, commentSec, sub, mainAction, getSubActions, status, actionType }) => {


    const [messageApi, contextHolder] = message.useMessage();
    const [commentLoading, setLoad] = useState(false);
    const [subActionBtn, setLoading] = useState(false);
    const [open, setOpen] = useState(false);


    const [title, setTitle] = useState("");
    const [description, setDescription] = useState("");
    const [comment, setComment] = useState("")
    const [allActivities, setActivities] = useState([]);
    const [currentPartiesLoading, setCurrentPartLoad] = useState(false)

    const [loaded, setLoadPart] = useState(true)
    const [userSearching, setSearch] = useState(false)
    const [currentActionParticipants, setCurrentActionParticipants] = useState([]);
    const [users, setUsers] = useState([]);
    const [searhcUsers, setSearchUsers] = useState([]);

    const [activityListLoaded, setLoaded] = useState(false);

    const [seeMore, setSeeMore] = useState(false);

    const success = (message) => {
        messageApi.open({
            type: 'success',
            content: message,
            style: {
                marginTop: '30vh',
                height: '100vh',
                fontSize: '20px'
            },
            duration: 3
        });
    };
    const error = (error) => {
        messageApi.open({
            type: 'error',
            content: error,
            style: {
                marginTop: '30vh',
                height: '100vh',
                fontSize: '20px'
            },
            duration: 3
        });
    };



    const saveSubaction = async () => {
        setLoading(true)
        console.log(title, description, action);
        const out = await addAgencySubAction({
            agency_id: action.agency_id,
            action_id: action.action_id,
            sub_action: title,
            description
        })

        if (out.data.status === 200) {
            success(out.data.message)
        } else {
            error(out.data.message)
        }
        setLoading(false)

        setDescription("")
        setTitle("")

        // setLoading(false)
    }

    const AddNewComment = async (id) => {



        setLoad(true)

        let sendData = {
            agency_id: action.agency_id,
            action_id: action.action_id,
            comment: comment

        }
        if (!mainAction) {
            sendData.subaction_id = id
        }
        const out = await AddAgencyActionComment(sendData)

        if (out.data.status === 200) {

            success(out.data.message)
            getActivityLog(action.agency_id, id)

        } else {

            error(out.data.message)

        }
        setLoad(false)

    }


    useEffect(() => {
        if (!mainAction) {
            getActivityLog(sub.agency_id, sub.id)

        } else {
            getActivityLog(action.agency_id, action.action_id)
        }
        // getUserDetails()
        console.log("sub", sub)

    }, [sub])

    async function getActivityLog(agc_id, act_id) {
        setLoaded(false)
        getAgencyActivities(agc_id, act_id).then((out) => {
            let data2 = out.data.out.filter(element => element.activity_type === 1 || element.activity_type === 2)
            setActivities(data2)

            setLoaded(true)
        })

    }
    async function getUserDetails() {
        let currentUsers = []
        let allusers = []

        // get all Participants
        await getAllUsers(1).then(out => {
            allusers = out.data.out

            setUsers(out.data.out)
        });

        if (!mainAction) {
            setCurrentPartLoad(true)
            // get current Participants
            await getActionParticipants(action.agency_id, action.action_id).then(out => {
                currentUsers = out.data.out
                setCurrentActionParticipants(out.data.out)

            })
            // set function for set user details for the current paricipants -- first_name, last_name...
            await getCurrentUsersName(currentUsers, allusers)
        }


        setLoadPart(true)



    }

    const getCurrentUsersName = async (currentUsers, allusers) => {

        if (allusers && currentUsers) {
            currentUsers.forEach(obj2 => {
                const obj1 = allusers.find(o => o.uuid === obj2.user_id);
                if (obj1) {
                    Object.assign(obj2, obj1);
                }
            });
            setCurrentActionParticipants(currentUsers)

        }

        setCurrentPartLoad(false)

    }

    const closeThis = () => {
        if (getSubActions) {
            getSubActions();
        }
        setOpen(false);
    }

    const onSearch = async (value) => {

        setSearch(true)

        let out = await searchParticipants(action.agency_id, value)
        if (out.status === 200) {
            setSearchUsers(out.data.out)
            setSearch(false)
        }

    };

    const addNewParticipant = async (user) => {

        let data = {
            "agency_id": action.agency_id,
            "action_id": action.action_id,
            "participant": user.uuid,
            "subaction": mainAction ? null : sub.id
        }

        let out = await addParticipants(data)
        if (out.status === 200) {
            getUserDetails()
        }


    }

    const removeParticipant = async (user) => {

        let data = {
            "agency_id": user.agency_id,
            "action_id": action.action_id,
            "participant": user.user_id,
            "subaction": mainAction ? null : sub.id
        }

        let out = await removeParticipants(data)
        if (out.status === 200) {
            getUserDetails()
        }

    }


    const participants = (
        <div style={{ width: '30vw' }}>
            <Text strong>Participants</Text>
            <Divider style={{ margin: 5 }} />


            <Search style={{ width: "100%", margin: "10px 0" }} placeholder="input search text" onSearch={onSearch} enterButton />

            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                {!userSearching ?
                    searhcUsers.map((c, k) => (
                        <Tooltip title={"Add " + c.title + " " + c.first_name + " " + c.last_name} color={"#108ee9"} key={k}>
                            <div onClick={() => addNewParticipant(c)} style={{ padding: "5px 10px", borderRadius: 25, border: '1px solid #e9e7e7', background: '#fafafa', width: 'fit-content', margin: "2px 3px", cursor: "pointer" }}>
                                <Space key={k}>
                                    {/* <Avatar size='small'>{c}</Avatar> */}
                                    <Text>{c.title + " " + c.first_name + " " + c.last_name + " | " + c.designation}</Text>
                                </Space>
                            </div>
                        </Tooltip>
                    )) : <LoadingOutlined style={{ fontSize: "16px", color: "blueviolet" }} />
                }
            </div>


            {searhcUsers.length > 0 ? <Divider style={{ margin: 5 }} /> : null}

            <div style={{ margin: "15px 0", padding: "0 10px" }}>
                <Text style={{ fontSize: 16 }} strong>Current Participants</Text>
            </div>


            {!currentPartiesLoading ?
                <Row>
                    {
                        currentActionParticipants.map((c, k) => (
                            <Tooltip title={"Remove " + c.title + " " + c.first_name + " " + c.last_name + " | " + c.designation} color={"#f50"} key={k}>
                                <div onClick={() => removeParticipant(c)} style={{ padding: "5px 10px", borderRadius: 25, border: '1px solid #e9e7e7', background: '#fafafa', width: 'fit-content', margin: "2px 3px", cursor: "pointer" }}>
                                    <Text>{c.title + " " + c.first_name + " " + c.last_name}</Text>
                                </div>
                            </Tooltip>
                        ))
                    }
                </Row> :
                <div>
                    <LoadingOutlined style={{ fontSize: "16px" }} />
                </div>
            }

        </div>

    );




    return (
        <>
            {commentSec ? <CommentOutlined
                onClick={() => {

                    setOpen(true)

                }}
                style={{ color: 'grey', cursor: 'pointer' }} /> : <PlusOutlined onClick={() => {

                    setOpen(true)

                }} style={{ color: 'white', background: 'grey', cursor: 'pointer' }} />

            }
            <Modal
                footer={null}
                centered
                open={open}
                onOk={() => setOpen(false)}
                onCancel={() => closeThis()}
                width={690}
                style={{ top: '0%', left: '0%', transform: 'translateX(-50%)' }}


            >
                <div className='modal-container'>


                    <TitleBox
                        title={"Task Hierarchy"}
                        body={
                            <div style={{ width: "100%" }}>
                                <Space style={{ width: "100%" }} size={12} direction='vertical'>

                                    <Typography className="article-title">3 - Opportunity to Comment, Information before Entry into Force, and Consultations</Typography>
                                    <Typography className="measure-title">3.1 - Publication</Typography>
                                    <Typography className="action-title2">3.1.1 - Members shall publish the general trade-related information listed in the proposal</Typography>
                                    <Form

                                        layout="horizontal"
                                        style={{
                                            maxWidth: 600,
                                        }}
                                        labelCol={{
                                            span: 4,
                                            style: { color: 'black', textAlign: 'left', fontWeight: 700, },
                                        }}
                                        wrapperCol={{
                                            span: 20,
                                        }}
                                    >

                                        <Form.Item label="3.1.1.1">
                                            <Input className='form-item' placeholder="Add your subtask header" />
                                            {/* <span className='status'>
            To Do
        </span> */}
                                        </Form.Item>
                                        <Form.Item label="Description">
                                            <TextArea className='form-item' rows={4} placeholder="Add your subtask description" />
                                        </Form.Item>

                                    </Form>
                                </Space>
                            </div>
                        }

                    />
                    {/* <TitleBox
                        title={"Members"}
                        body={
                            <div>
                                <Row>
                                    <Col span={10}>
                                        <Row gutter={16}>
                                            <Col span={3}>
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        width: 40,
                                                        height: 40,
                                                        borderRadius: "50%",
                                                        border: "2px dotted #999",
                                                        alignItems: "center",
                                                        justifyContent: "center",
                                                        borderImage: "repeating-linear-gradient(-45deg, #999, #999 5px, transparent 5px, transparent 10px) 1",

                                                    }}
                                                >
                                                    <UserAddOutlined />
                                                </div>

                                            </Col>
                                            <Col span={21} style={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }} >
                                                <Typography style={{ color: "black", fontWeight: 600 }} level={5} type="secondary">Add New Member</Typography>

                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col span={10}>
                                        <Row gutter={16}>
                                            <Col span={3}>
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        width: 40,
                                                        height: 40,
                                                        borderRadius: "50%",
                                                        border: "2px dotted #999",
                                                        alignItems: "center",
                                                        justifyContent: "center",
                                                        borderImage: "repeating-linear-gradient(-45deg, #999, #999 5px, transparent 5px, transparent 10px) 1",

                                                    }}
                                                >
                                                    <UserAddOutlined />
                                                </div>

                                            </Col>
                                            <Col span={21} style={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }} >
                                                <Typography style={{ color: "black", fontWeight: 600 }} level={5} type="secondary">Add New Member</Typography>

                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </div>
                        }

                    /> */}




                </div>

            </Modal>


            {/* <Modal
                footer={null}
                centered
                open={open}
                onOk={() => setOpen(false)}
                onCancel={() => closeThis()}
                width={'60vw'}
            // style={{ height: 'fit-content' }}
            >
                {/* <Breadcrumb>
                    <Breadcrumb.Item>
                        <a href='/ss'><Typography.Title className='breadcumTitle' level={5}>{action.article_no}. {action.article}</Typography.Title></a>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        <a href='/ss'><Typography.Title className='breadcumTitle' level={5}>{action.article_no}.{action.measure_no}. {action.measure_name}</Typography.Title></a>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        <a href='/ss'><Typography.Title className='breadcumTitle' level={5}>{action.article_no}.{action.measure_no}.{action.action_no}. {action.action}</Typography.Title></a>
                    </Breadcrumb.Item>
                </Breadcrumb>
                <Row gutter={24} style={{ marginTop: 10 }} >
                    <Col span={16} style={{ borderTop: '1px solid #f2f2f2', paddingTop: 20 }}>

                        <Space size={"small"} direction={"vertical"} style={{width:"100%"}}>
                            <Row >
                                <Col span={24}>

                                    <Typography.Title style={{ color: "#01796F", alignContent: "center", display: "flex" }} level={4}><ReconciliationOutlined style={{ fontSize: 30, marginRight: 10 }} />{create ? "Add New Sub Action" : "Sub Action Details"}</Typography.Title>

                                </Col>
                            </Row> 
                            {!create &&
                                <Row style={{ paddingLeft: 8, paddingTop: 10 }}>

                                    <Col span={3}>
                                        <StatusCompo status={status == 0 || status ? status : sub?.status} />
                                    </Col>
                                </Row>}


                            {create ?
                                <EditText style={{ fontSize: 20, paddingLeft: 10, border: "1px solid #D9D9D9", marginLeft: "10px", width: "96%", borderRadius: "10px" }} placeholder="Enter Sub Action Title Here" value={title} onChange={(e) => setTitle(e.target.value)} />
                                : <Typography.Title level={3}>{sub?.subaction}</Typography.Title>}
                            <Row gutter={24} style={{ paddingLeft: 10 }} >
                                {/* {mainAction ?
                                    <Col span={24}>
                                        {loaded ?
                                            <Popover content={participants} trigger="click" placement="left" onOpenChange={async (visible) => {
                                                if (visible) {
                                                    await getUserDetails()
                                                } else {
                                                    setSearchUsers([])
                                                }
                                            }}>
                                                <Tooltip onOpenChange={async (visible) => {
                                                    if (visible) {
                                                        await getUserDetails()
                                                    } else {
                                                        setSearchUsers([])
                                                    }


                                                }} title={
                                                    <div style={{ padding: "5px" }}>
                                                        <Avatar.Group>
                                                            {currentActionParticipants.length > 0 ? currentActionParticipants.map(element => {
                                                                return <Avatar size="large" style={{ backgroundColor: getRandomColor() }}>{element.first_name}</Avatar>
                                                            })
                                                                :
                                                                <Typography.Text style={{ color: "ActiveCaption" }}>{"No Participants added. click to Add Participants."} </Typography.Text>}

                                                        </Avatar.Group>
                                                    </div>} placement="top" color={"#fff"}>
                                                    <Avatar
                                                        style={{
                                                            backgroundColor: '#87d068',
                                                        }}
                                                        icon={<UserOutlined />}
                                                    />
                                                </Tooltip>
                                            </Popover> : <LoadingOutlined style={{ fontSize: "16px" }} />}
                                    </Col> :
                                    <Col span={24}>
                                        {currentActionParticipants.map((c, key) => {
                                            <Space key={key}>
                                                {/* <Avatar size='small'>{c}</Avatar>
                                                <Text>{c.title + " " + c.first_name + " " + c.last_name}</Text>
                                            </Space>
                                        })}
                                    </Col>
                                } *

                            </Row>
                            <Row gutter={24}>
                                {create ?
                                    <Col span={24} style={{ paddingRight: "10px" }}>
                                        <Input.TextArea rows={5} value={description} onChange={(e) => setDescription(e.target.value)} placeholder='Description' style={{ marginTop: 30, width: "97%" }} />
                                    </Col>
                                    :
                                    <Col span={24}>
                                        <Typography.Paragraph>{sub?.description}</Typography.Paragraph>
                                    </Col>}
                            </Row>
                            {create &&
                                <Row gutter={24} style={{ paddingLeft: 10 }}>
                                    <Col span={24} style={{ textAlign: "right", paddingRight: "4.3%" }}>
                                        <Button loading={subActionBtn} type="primary" size='middle' onClick={() => saveSubaction()}>Add New Sub Action</Button>
                                    </Col>


                                </Row>
                            }


                            <div style={{ marginTop: 30, height: '30vh' }}>
                                <Space size={"middle"} direction="vertical">
                                    <Row gutter={16}>
                                        <Col span={24}>

                                            <Typography.Title style={{ color: "#01796F", alignContent: "center", display: "flex" }} level={4}> <CloudUploadOutlined style={{ fontSize: 30, marginRight: 10 }} />Attachments</Typography.Title>
                                            <Divider />

                                        </Col>
                                    </Row>

                                    <Row gutter={16}>
                                        {/* <Col span={24}>
                                    <Dragger>
                                        <p className="ant-upload-text">Click or drag a file to this area to upload</p>
                                        <p className="ant-upload-hint">
                                            Support for a single or bulk upload. Strictly prohibited from uploading company data or other
                                            banned files.
                                        </p>
                                    </Dragger>
                                </Col> 
                                        <Col span={24}>
                                            <Upload
                                                action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                                                listType="picture-card"
                                                fileList={fileList}
                                                onPreview={handlePreview}
                                                onChange={handleChange}
                                            >
                                                {fileList.length >= 8 ? null : uploadButton}
                                            </Upload>
                                            <Modal open={previewOpen} title={previewTitle} footer={null} onCancel={handleCancel}>
                                                <img
                                                    alt="example"
                                                    style={{
                                                        width: '100%',
                                                    }}
                                                    src={previewImage}
                                                />
                                            </Modal>
                                        </Col>
                                    </Row>
                                </Space>
                            </div>
                        </Space>
                    </Col>
                    <Col span={24} style={{ borderLeft: '1px solid #f2f2f2', borderTop: '1px solid #f2f2f2' }}>
                        <Space style={{ height: '8vh', width: '100%', borderBottom: '1px solid #e5e5e5', padding: 10 }}>


                            <Typography.Title level={4} style={{ color: "#4C516D", alignContent: "center", display: "flex" }}> <HistoryOutlined style={{ fontSize: 25, marginRight: 10 }} />Activity History</Typography.Title>


                        </Space>
                        <div style={{ backgroundColor: "#FAFAFA", padding: 10 }}>

                            {activityListLoaded ?
                                <List>
                                    <VirtualList
                                        // data={mainAction ?
                                        //     allActivities.filter(element =>
                                        //         (element.activity_type === 1 || element.activity_type === 3) && element.action_id === action.action_id)
                                        //     :
                                        //     allActivities.filter(element => (element.activity_type === 2 || element.activity_type === 4) && element.action_id === sub.id)
                                        // }
                                        data={allActivities}
                                        height={ContainerHeight}
                                        itemHeight={50}
                                        itemKey="email"
                                    // onScroll={onScroll}


                                    >

                                        {(item, key) => (

                                            <List.Item key={key} style={{
                                                backgroundColor: " #FFFF",


                                                padding: 10, margin: 5, borderRadius: "0 10px 10px 10px"
                                            }}>

                                                {item.activity_type === 1 || item.activity_type === 2 ?
                                                    <>
                                                        <List.Item.Meta
                                                            avatar={<Avatar className="list-avatar-comment" shape="square" size={"large"} icon={<CommentOutlined />} />}
                                                            title={"By" + " " + item.title + " " + item.first_name + " " + item.last_name}
                                                            description={
                                                                <p style={{ width: "95%" }}>
                                                                    <span>{item.desc1.length > 50 ? item.desc1.slice(0, 50) + "... " : item.desc1}</span>
                                                                    <a onClick={() => setSeeMore(true)}>{item.desc1.length > 50 ? "see more" : ""}</a>
                                                                    <Modal
                                                                        closable
                                                                        title={"By" + " " + item.title + " " + item.first_name + " " + item.last_name}
                                                                        centered
                                                                        open={seeMore}
                                                                        footer={null}
                                                                        onCancel={() => setSeeMore(false)}
                                                                    >
                                                                        <p>{item.desc1}</p>
                                                                    </Modal>
                                                                </p>
                                                            }
                                                        />


                                                        <div> {new Date(item.created).toISOString().slice(8, 10) + "/" + new Date(item.created).toISOString().slice(5, 7)}</div>
                                                    </>
                                                    :
                                                    item.activity_type === 3 || item.activity_type === 4 ?
                                                        <>
                                                            <List.Item.Meta
                                                                avatar={<Avatar className="list-avatar-status" shape="square" size={"large"} icon={<InteractionOutlined />} />}
                                                                title={"By" + " " + item.title + " " + item.first_name + " " + item.last_name}
                                                                description={mainAction ? (getStatus(item.opt1) + " -> " + getStatus(item.opt2)) : (getStatus(item.opt2) + " -> " + getStatus(item.opt3))}
                                                            />

                                                            <div> {new Date(item.created).toISOString().slice(8, 10) + "/" + new Date(item.created).toISOString().slice(5, 7)}</div>
                                                        </>


                                                        : item.type === "upload" ?
                                                            <>
                                                                <List.Item.Meta
                                                                    avatar={<Avatar className="list-avatar-upload" shape="square" size={"large"} icon={<CloudUploadOutlined />} />}
                                                                    title={"Upload Attachment"}
                                                                    description={"Pending to  Approved"}
                                                                />

                                                                <div>Feb 03 2023</div>
                                                            </> : null
                                                }


                                            </List.Item>
                                        )}
                                    </VirtualList>
                                </List> : <LoadingOutlined style={{ fontSize: "16px", color: "blueviolet" }} />}

                        </div>
                        <div style={{ padding: 10 }}>
                            <Input.TextArea rows={3} value={comment} onChange={(e) => setComment(e.target.value)} placeholder='Enter Comment' />
                            <Button loading={commentLoading} style={{ marginTop: 10 }} type="primary" onClick={() => AddNewComment(mainAction ? action.action_id : sub.id)}>Add Comment</Button>
                        </div>
                    </Col>
                </Row>
            </Modal > */}
        </>
    );
};
export default SubActionActivities;
