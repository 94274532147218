import { Table, Button, Typography, Form, Descriptions, Divider, Alert, Tag, Space, Select, Drawer, Input, Upload, Radio, message, ConfigProvider } from "antd";
import { LinkOutlined, PlusOutlined, InboxOutlined } from '@ant-design/icons';
import { useEffect, useState } from "react";
import 'react-quill/dist/quill.snow.css';
import { addDonorInterests, addDonorRequest, getAgencies, getAgencyList, getDonorInterests, getMeasures, getMyDonorRequests, getPublishedDonorRequests } from "../../api/index.js";
import PageLayouts from "../../Components/PageLayouts/index.jsx";
import CustomTable from "../../Components/Table/index.js";
import ReactQuill from 'react-quill';
import { ToastContainer, toast, Zoom } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const DonorRequestsAgency = () => {
    const [selected, setSelected] = useState(null);
    const [open, setOpen] = useState(false);
    const [filtered_data, setFilteredData] = useState([]);
    const [interests, setInterests] = useState([]);
    const [purpose, setPurpose] = useState("All Countries");
    const [agency, setAgency] = useState(null);// selected Agency
    const [agencies, setAgencies] = useState(null);//agencies list from DB
    const [measure, setMeasure] = useState(null);//selected Measure
    const [measures, setMeasures] = useState(null);//Measures list from DB
    const [assistance, setAssistance] = useState(0);

    const onFinish = (values) => {

        values['agency'] = localStorage.ntfc_agency;
        values['scope'] = 1;
        values['assistance'] = assistance;
        values['description'] = description;

        addDonorRequest(values).then((out) => {
            if (out.data.status === 200) {
                form.resetFields();
                refreshRequests();

                toast.success("Donor Requrst Added Successfully", {

                    autoClose: 3000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progressClassName: 'toast-progress-bar',
                });
            } else {
                toast.error("error", {

                    autoClose: 3000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progressClassName: 'toast-progress-bar',
                });

            }
        }).catch((err) => {
            console.log(err);
            toast.error("error", {

                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progressClassName: 'toast-progress-bar',
            });
        })

    };

    const onFinishFailed = (errorInfo) => {
        // error("Request creation unsuccessful");
        toast.error("Request creation unsuccessful", {

            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progressClassName: 'toast-progress-bar',
        });
    };

    const [messageApi, contextHolder] = message.useMessage();

    const success = (msg) => {
        messageApi.open({
            type: 'success',
            content: msg,
            style: {
                marginTop: '30vh',
                height: '100vh',
                fontSize: '20px'
            },
            duration: 3
        });
    };

    const error = (msg) => {
        messageApi.open({
            type: 'error',
            content: msg,
            style: {
                marginTop: '30vh',
                height: '100vh',
                fontSize: '20px'
            },
            duration: 3
        });
    };

    const fetchInterests = () => {
        getDonorInterests().then((out) => setInterests(out.data.out))
    }

    const refreshRequests = () => {
        getAgencies().then((out) => {
            setAgencies(out.data.out);
        });

        getMeasures().then((out2) => {
            setMeasures(out2.data.out);
        });

        getMyDonorRequests().then((out3) => {
            setInterests(out3.data.out);
        });
    }

    const [form] = Form.useForm();
    const showDrawer = () => {
        setOpen(true);
    };
    const onClose = () => {
        setOpen(false);
        fetchInterests();
    };
    const [requiredMark, setRequiredMarkType] = useState('optional');
    const onRequiredTypeChange = ({ requiredMarkValue }) => {
        setRequiredMarkType(requiredMarkValue);
    };
    const [description, setDescription] = useState('');
    const normFile = (e) => {
        console.log('Upload event:', e);
        if (Array.isArray(e)) {
            return e;
        }
        return e?.fileList;
    };

    // const submit = (val) => {
    //     console.log(val);
    //     addDonorInterests(val).then(() => (console.log('')))
    // }
    // useEffect(() => { fetchInterests(); }, []);
    const filterData = (val) => {
        // const itms = data.filter((rec) => {
        //     return rec.article_id === Number(val) || !val
        // });
        // console.log(itms); setFilteredData(itms);
    }

    const columns = [
        // {
        //     title: 'ID',
        //     dataIndex: 'id',
        //     key: 'id'
        // },
        {
            title: 'Subject',
            dataIndex: 'subject',
            key: 'subject',
        },
        {
            title: 'Measure',
            dataIndex: 'measure_name',
            key: 'measure_name',
        },
        {
            title: 'Description',
            dataIndex: 'description',
            key: 'description',
        },
        // {
        //     title: 'Attachment',
        //     dataIndex: 'attachment',
        //     key: 'attachment',
        // },
        {
            title: 'Support Type',
            dataIndex: 'assistance',
            key: 'assistance',
            render: (_, { assistance }) => (
                <Tag color={assistance == 0 ? "#f50" : "#2a8c00"} key={assistance} style={{ width: 150, display: 'flex', justifyContent: 'center' }} >
                    {
                        assistance == 0 ? "TECH ASSISTANCE"
                            : "CAPACITY BUILDING"
                    }
                </Tag>
            ),
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: (_, { status }) => (
                <Tag color={status == 0 ? "#008282" : "#9B0259"} key={status} style={{ width: 120, display: 'flex', justifyContent: 'center' }} >
                    {
                        status == 0 ? "PENDING"
                            : "PUBLISHED"
                    }
                </Tag>
            ),
        },
    ];
    const [value, setValue] = useState(1);
    const onChange = (e) => {
        console.log('radio checked', e.target.value);
        setValue(e.target.value);
    };

    useEffect(() => {
        refreshRequests();
    }, []);

    return <>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            {contextHolder}
            <PageLayouts
                formTitle="Donor Requests"
                tableTitle="My Donor Requests"
                imageSrc="/images/marketing-company.png"
                bgColor="#005476"
                formSide={
                    <ConfigProvider
                        theme={{
                            componentStyle: {
                                '.ant-form-item-label': {
                                    color: 'white',
                                },
                                ':where(.css-dev-only-do-not-override-1bb13w7).ant-radio-wrapper span.ant-radio+* ': {
                                    color: 'white'
                                }
                            },
                        }}>
                        <Form
                            form={form}
                            layout="vertical"
                            onFinish={onFinish}
                            onFinishFailed={onFinishFailed}
                        >
                            <Form.Item style={{ color: "white" }} label="Subject" required name="subject">
                                <Input type='text' placeholder="Subject" />
                            </Form.Item>
                            <Form.Item label="Measure" required name="measure">
                                <Select
                                    options={measures}
                                    onChange={(val) => setMeasure(val)}
                                />
                            </Form.Item>
                            <Form.Item label="Proposal" required name="proposal">
                                <Radio.Group onChange={onChange} value={value} defaultValue={1}>
                                    <Radio value={1}>Upload</Radio>
                                    <Radio value={2}>Created</Radio>

                                </Radio.Group>
                            </Form.Item>

                            {value == 1 ?
                                <Form.Item label="Attachment">
                                    <br />
                                    <Form.Item name="attachment" valuePropName="fileList" getValueFromEvent={normFile} noStyle>
                                        <Upload.Dragger name="files" style={{ backgroundColor: "#CCDDE3" }}>
                                            <p className="ant-upload-drag-icon">
                                                <InboxOutlined />
                                            </p>
                                            <p className="ant-upload-hint">Click or drag a file to this area to upload</p>
                                        </Upload.Dragger>
                                    </Form.Item>
                                </Form.Item>
                                : ""}

                            <Form.Item label="Description" required name="description">
                                <Input.TextArea rows={5} maxLength={200} value={description} onChange={(e) => setDescription(e.target.value)} />
                                <div>
                                    <p style={{ float: "right", color: "white" }}>{description.length}/200</p>
                                </div>
                            </Form.Item>

                            <Form.Item label="Asist Type" required name="assistance">
                                <Select
                                    onChange={(val) => setAssistance(val)}
                                    defaultValue={0}
                                    options={
                                        [
                                            { value: 0, label: 'Technical Support' },
                                            { value: 1, label: 'Capacity Building' },
                                        ]
                                    }
                                />
                            </Form.Item>
                            <Form.Item>
                                <Space style={{ display: "flex", justifyContent: "flex-end" }}>
                                    <Button type='primary' htmlType='submit'>Submit</Button>
                                    {/* <Button onClick={}>Cancel</Button> */}
                                </Space>
                            </Form.Item>
                        </Form>
                    </ConfigProvider>
                }
                tableSide={
                    <Table
                        columns={columns}
                        dataSource={interests}
                        pagination={{
                            pageSize: 10,
                            total: filtered_data.length,
                        }}
                        headerClass="#005476"
                    />
                }
            />

            <ToastContainer
                transition={Zoom}
                position="top-center"
                autoClose={5000}
                hideProgressBar
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored"
                style={{ top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}
            />

        </div>
    </>

}

export default DonorRequestsAgency;