import { Checkbox, ConfigProvider, Modal } from "antd"
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { assignUnassignAgencyActions } from "../../../api";

const ActionConfirmCompo = ({ action, available, refreshPlan, agency, isChair }) => {
    const [modalOpen, setModalOpen] = useState(false);
    const [isChecked, setIsChecked] = useState(available);

    const assignUnassignAction = () => {


        assignUnassignAgencyActions({
            action: {
                agency_id: agency,
                article_id: action.article_id,
                measure_id: action.measure_id,
                action_id: action.id
            },
            assignment: isChecked
        }).then((out) => {

            if (out.data.status == 200) {
                toast.success(`Actions Updated successfully.`, {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
            } else {
                toast.error(`This action has already been completed. Cannot remove this action from action plan`, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
            }
            setIsChecked(available)
            refreshPlan();
        }).catch((error) => {
            console.log("error", error)
        })
    }

    useEffect(() => { setIsChecked(available) }, [available]);

    return <>
        <Checkbox checked={isChecked} onChange={(val) => { if (!isChair) { setIsChecked(val.target.checked); setModalOpen(true) } }} disabled={window.localStorage.getItem('view_only') == 1} />
        <Modal
            centered
            title="Confirm"
            open={modalOpen}
            onOk={() => { setModalOpen(!modalOpen); assignUnassignAction(); }}
            onCancel={() => { setModalOpen(false); setIsChecked(!isChecked) }}

        >
            <p>{`Are you sure to ${isChecked ? "assign" : "unassign"} this action?`}</p>
        </Modal>
    </>

}

export default ActionConfirmCompo;