import axios from 'axios';
import { toast } from 'react-toastify';
// const BASE_URL = "http://localhost:3001/api";
// QA URL
// const BASE_URL = "http://20.55.26.35:3001/api";
// LOONSLAB URL
// const BASE_URL = "http://20.55.26.35:3001/api";
// const BASE_URL = "http://20.55.26.35:3003/api";
// const BASE_URL = "http://192.168.8.188:8081/api";
// PROD URL
const BASE_URL = "https://ntfc.lk/api/api";


// const TOKEN = JSON.parse(window.localStorage.getItem('ntfc_user')).token

const delay = ms => new Promise(res => setTimeout(res, ms));

export const GET = async (path, body = {}, auth = true, blob = false) => {
  const TOKEN = await window.localStorage.getItem('ntfc_token');
  if (auth) {
    body.headers = { "Authorization": `Bearer ${TOKEN}` };
  }
  if (blob) {
    body.responseType = 'blob';
  }
  let res = null;
  try {
    res = await axios.get(path, body,);
    return res;
  } catch (e) {
    res = await e.response;
    if (res && res.status == 402 && window.localStorage.getItem('ntfc_session') == 1) {
      window.localStorage.setItem('ntfc_session', 0);
      toast.error(res.message, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      setTimeout(() => { window.location.href = "/login"; }, 2000);
    } else if (res && res.status == 401 && window.localStorage.getItem('ntfc_session') == 1) {
      window.localStorage.setItem('ntfc_session', 0);
      toast.error("Session expired. Please login again", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      setTimeout(() => { window.location.href = "/login"; }, 2000)
    } else if (res && (res.status == 401 || res.status == 402)) {
      setTimeout(() => { window.location.href = "/login"; }, 3000);
    }
    return {
      status: 500,
      data: {
        out: [],
        message: res && res.data ? res.data.message : ""
      }
    };
  }
}

export const POST = async (path, body, auth = true) => {
  const TOKEN = await window.localStorage.getItem('ntfc_token');
  let res = null;
  try {
    res = await axios.post(path, body, auth ? { headers: { "Authorization": `Bearer ${TOKEN}` } } : {})
    return res;
  } catch (e) {
    // toast.dismiss();
    res = await e.response;
    if (res && res.status == 402 && window.localStorage.getItem('ntfc_session') == 1) {
      window.localStorage.setItem('ntfc_session', 0);
      toast.error(res.message, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      setTimeout(() => { window.location.href = "/login"; }, 2000);
    } else if (res && res.status == 401 && window.localStorage.getItem('ntfc_session') == 1) {
      window.localStorage.setItem('ntfc_session', 0);
      toast.error("Session expired. Please login again", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      setTimeout(() => { window.location.href = "/login"; }, 2000)
    } else if (res && (res.status == 401 || res.status == 402)) {
      setTimeout(() => { window.location.href = "/login"; }, 3000);
    }
    return {
      status: 500,
      data: {
        out: [],
        message: res && res.data ? res.data.message : ""
      }
    };
  }
}

// ---------------- USERS

export const login = async (body) => {
  return await POST(BASE_URL + '/users/login', body, false);
};

export const logout = async (token) => {
  return await POST(BASE_URL + '/users/logout', {
    token: token,
  });
};

export const signup = async (body) => {
  return await POST(BASE_URL + "/users/signup", body, false);
};

export const approveRegistration = async (body) => {
  return await POST(BASE_URL + "/users/approveRegistration", body);
};

export const rejectRegistration = async (body) => {
  return await POST(BASE_URL + "/users/rejectRegistration", body);
};

export const allRegistrations = async (body) => {
  return await GET(BASE_URL + "/users/allRegistrations", body);
};

export const getPendingApprovals = async (body) => {
  return await GET(BASE_URL + "/users/getPendingApprovals", body);
};

export const requestPasscode = async (body) => {
  return await POST(BASE_URL + "/users/requestPasscode", body, false);
};

export const updatePassword = async (body) => {
  return await POST(BASE_URL + "/users/updatePassword", body, false);
};

export const updateUserByID = async (body) => {
  return await POST(BASE_URL + "/users/updateUserByID", body, false);
};

export const getPendingAndRejectedApprovals = async (body) => {
  return await GET(BASE_URL + "/users/getPendingAndRejectedApprovals", body);
};

export const getUsersByAgency = async (agency, body) => {
  return await GET(BASE_URL + `/users/getUsersByAgency/${agency}`, body);
};

export const getUserRequestsCount = async (id, body, token) => {
  return await GET(BASE_URL + `/users/getPendingApprovalsCount`, body);
};

export const getUserById = async (id) => {
  return await GET(BASE_URL + `/users/userByID/${id}`);
};

export const CreateNewUser = async (body) => {
  return await POST(BASE_URL + `/users/createUser`, body);
};

export const editUser = async (body) => {
  return await POST(BASE_URL + `/users/editUser`, body);
};
export const deactivateUser = async (id) => {
  return await POST(BASE_URL + `/users/deactivateUser`, id);
};

export const reactivateUser = async (id) => {
  return await POST(BASE_URL + `/users/reactivateUser`, id);
};

export const checkDependancyUser = async (id) => {
  return await POST(BASE_URL + `/users/checkEditable`, id);
};







// ---------------- AGENCIES

export const getAgencyList = async (body) => {
  return await GET(BASE_URL + "/agencies/getAgencyList", body);
};

export const getAgencyArticles = async (agency, body) => {
  return await GET(BASE_URL + `/agencies/getAgencyArticles/${agency}`, body);
};

export const getAgencies = async (body) => {
  return await GET(BASE_URL + "/agencies/getAgencies", body, false);
};

export const addAgency = async (body) => {
  return await POST(BASE_URL + "/agencies/createAgency", body);
};

export const updateAgency = async (body) => {
  return await POST(BASE_URL + "/agencies/updateAgency", body);
};

export const deleteAgency = async (body) => {
  return await POST(BASE_URL + "/agencies/deleteAgency", body);
};

export const getAgency = async (agency, body) => {
  return await GET(BASE_URL + "/agencies/getAgency/" + agency, body);
};


export const GetAllDonorList = async () => {
  return await GET(BASE_URL + `/agencies/getDonorAgencies`);
};

export const GetAgencyDependancies = async (agency) => {
  return await GET(BASE_URL + `/agencies/isAgencyTypeUpdatable/${agency}`);
};


// ---------------- ARTICLES

export const getArticles = async (body) => {
  return await GET(BASE_URL + "/articles/getArticles", body);
};

export const getArticlesList = async (body) => {
  return await GET(BASE_URL + "/articles/getArticlesList", body);
};

export const getArticle = async (id, body) => {
  return await GET(BASE_URL + `/articles/getArticle/${id}`, body);
};

export const addArticle = async (body) => {
  return await POST(BASE_URL + "/articles/createArticle", body);
};

export const updateArticle = async (body) => {
  return await POST(BASE_URL + "/articles/updateArticle", body);
};

export const deleteArticle = async (body) => {
  return await POST(BASE_URL + "/articles/deleteArticle", body);
};

export const getNextArticleNo = async (body) => {
  return await GET(BASE_URL + "/articles/getNextArticleNo", body);
};

export const GetAgencyArticels = async (article, status) => {
  return await GET(BASE_URL + `/articles/getArticleStatusPercentages/${article}/${status}`);
};

// ---------------- MEASURES

export const getMeasures = async (body) => {
  return await GET(BASE_URL + "/measures/getMeasures", body);
};

export const getMeasuresList = async (body) => {
  return await GET(BASE_URL + "/measures/getMeasuresList", body);
};

export const getMeasuresListByArticle = async (article, body) => {
  return await GET(BASE_URL + `/measures/getMeasuresListByArticle/${article}`, body);
};

export const getMeasure = async (id, body) => {
  return await GET(BASE_URL + `/measures/getMeasure/${id}`, body);
};

export const addMeasure = async (body) => {
  return await POST(BASE_URL + "/measures/createMeasure", body);
};

export const updateMeasure = async (body) => {
  return await POST(BASE_URL + "/measures/updateMeasure", body);
};

export const deleteMeasure = async (body) => {
  return await POST(BASE_URL + "/measures/deleteMeasure", body);
};

export const getNextMeasureNo = async (article, body) => {
  return await GET(BASE_URL + `/measures/getNextMeasureNo/${article}`, body);
};

// ---------------- ACTIONS

export const getActions = async (body) => {
  return await GET(BASE_URL + "/actions/getActions", body);
};

export const getActionsList = async (body) => {
  return await GET(BASE_URL + "/actions/getActionsList", body);
};

export const getAction = async (id, body) => {
  return await GET(BASE_URL + `/actions/getAction/${id}`, body);
};

export const addAction = async (body) => {
  return await POST(BASE_URL + "/actions/createAction", body);
};

export const updateAction = async (body) => {
  return await POST(BASE_URL + "/actions/updateAction", body);
};

export const deleteAction = async (body) => {
  return await POST(BASE_URL + "/actions/deleteAction", body);
};

export const getNextActionNo = async (measure, body) => {
  return await GET(BASE_URL + `/actions/getNextActionNo/${measure}`, body);
};

// ---------------- AGENCY MEASURES

export const getAgencyMeasures = async (agency, body) => {
  return await GET(BASE_URL + `/agency_measures/getAgencyMeasures/${agency}`, body);
};

export const getAgencyArticleMeasures = async (agency, article, body) => {
  return await GET(BASE_URL + `/agency_measures/getAgencyMeasuresByArticle/${agency}/${article}`, body);
};

export const getMeasureAgencies = async (body) => {
  return await POST(BASE_URL + `/agency_measures/getMeasureAgencies`, body);
};

// ---------------- AGENCY ACTIONS

export const getAgencyActions = async (id, body, token) => {
  return await GET(BASE_URL + `/agency_action/getAgencyActions/${id}`, body);
};

export const removeAgencyAction = async (body) => {
  return await POST(BASE_URL + `/agency_action/removeAgencyAction`, body);
};

export const addAgencyAction = async (body) => {
  return await POST(BASE_URL + `/agency_action/addAgencyAction`, body);
};

export const updateAgencyActions = async (body) => {
  return await POST(BASE_URL + `/agency_action/updateAgencyActions`, body);
};

export const assignUnassignAgencyActions = async (body) => {
  return await POST(BASE_URL + `/agency_action/assignUnassignAgencyActions`, body);
};

export const changeAgencyActionDeliveryDate = async (body) => {
  return await POST(BASE_URL + `/agency_action/changeAgencyActionDeliveryDate`, body);
};

export const changeAgencyActionStatus = async (body) => {
  return await POST(BASE_URL + `/agency_action/changeAgencyActionStatus`, body);
};

export const AddAgencyActionComment = async (body) => {
  return await POST(BASE_URL + `/agency_action/addActionComment`, body);
};

export const getAgencyActivities = async (agency_id, action_id) => {
  return await GET(BASE_URL + `/agency_action/getActivityLog/${agency_id}/${action_id}`);
};

export const addParticipants = async (body) => {
  return await POST(BASE_URL + `/agency_action/addActionSubactionParticipant`, body);
};
export const removeParticipants = async (body) => {
  return await POST(BASE_URL + `/agency_action/removeActionSubactionParticipant`, body);
};

export const getActionParticipants = async (agency_id, Action_id) => {
  return await GET(BASE_URL + `/agency_action/getActionParticipants/${agency_id}/${Action_id}`);
};

export const getAllPendingApprovals = async (body) => {
  return await GET(BASE_URL + `/agency_action/getPendingActionApprovals`, body);
};

export const ApproveAgencyActionStatus = async (body) => {
  return await POST(BASE_URL + `/agency_action/approveAgencyActionStatusChange`, body);
};

export const DiscardAgencyActionStatus = async (body) => {
  return await POST(BASE_URL + `/agency_action/discardAgencyActionStatusChange`, body);
};

export const getStatusChangeRequestsCount = async (id, body, token) => {
  return await GET(BASE_URL + `/agency_action/getPendingActionApprovalsCount`, body);
};

// Get agency wise all  details Articles --> Measures --> Actions
export const GetAgencyWiseAllArticles = async (agency) => {
  return await GET(BASE_URL + `/agency_action/getAgencyActionsStructure/${agency}`);
};

export const UplaodActionAttachments = async (body) => {
  return await POST(BASE_URL + `/agency_action/addActionFiles`, body);
};

// Get agency wise all  details Articles --> Measures --> Actions
export const GetAgencyUploads = async (agency, action) => {
  return await GET(BASE_URL + `/agency_action/getActionFiles/${agency}/${action}`);
};

export const DownloadFiles = async (token) => {
  return await GET(BASE_URL + `/agency_action/downloadActionFile/${token}`, {}, true, true);
};

export const DeleteActionFile = async (body) => {
  return await POST(BASE_URL + `/agency_action/deleteActionFiles`, body);
};
export const getActionForUser = async (body) => {
  return await GET(BASE_URL + `/agency_action/getAgencyActionsForUser`, body);
};



// --------------- AGENCY SUBACTIONS -----------------

export const addAgencySubAction = async (body) => {
  return await POST(BASE_URL + `/subactions/addSubAction`, body);
};

export const getAgencySubActions = async (body) => {
  return await POST(BASE_URL + `/subactions/getAgencySubActions`, body);
};

export const changeSubactionStatus = async (body) => {
  return await POST(BASE_URL + `/subactions/changeSubactionStatus`, body);
};

export const AddAgencySubActionComment = async (body) => {
  return await POST(BASE_URL + `/subactions/addSubActionComment`, body);
};

export const UpdateSubAction = async (body) => {
  return await POST(BASE_URL + `/subactions/updateSubAction`, body);
};

export const AddSubActionFiles = async (body) => {
  return await POST(BASE_URL + `/subactions/addSubactionFiles`, body);
};

export const GetSubActionFiles = async (agency, action, subaction) => {
  return await GET(BASE_URL + `/subactions/getSubactionFiles/${agency}/${action}/${subaction}`);
};

export const getSubActionsById = async (action_id, subaction_id) => {
  return await GET(BASE_URL + `/subactions/getAgencySubActionById/${action_id}/${subaction_id}`);
};

export const DeleteSubActionFile = async (body) => {
  return await POST(BASE_URL + `/subactions/deleteSubActionFiles`, body);
};


// --------------- DASHBOARDS -----------------

export const getAgencyWiseDashboard = async (id, body, token) => {
  return await GET(BASE_URL + `/dashboard/agencyWiseDashboard/${id}`, body);
};

export const getMeasureWiseCompletion = async (id, body, token) => {
  return await GET(BASE_URL + `/dashboard/getMeasureWiseCompletion/${id}`, body);
};

export const getCountryWiseDashboard = async (body, token) => {
  return await GET(BASE_URL + `/dashboard/countryWiseDashboard`, body);
};

export const GetArticleCompletionGraph = async (agency) => {
  return await GET(BASE_URL + `/dashboard/getArticleGraph/${agency}`);
};

export const GetDonorGraphData = async (agency) => {
  return await GET(BASE_URL + `/dashboard/getDonorGraph/${agency}`);
};

export const GetCompletionGraphData = async (agency) => {
  return await GET(BASE_URL + `/dashboard/getCompletionGraph/${agency}`);
};

export const getRecordsDashoard = async (body) => {
  return await POST(BASE_URL + `/dashboard/getRecordsByIds`, body);
};

// New DashBoard Requestes
export const getActionCardData = async (agency) => {
  return await GET(BASE_URL + `/dashboard/getActionCard/${agency}`);
};
export const getActionConfigurationCardData = async (agency) => {
  return await GET(BASE_URL + `/dashboard/getActionConfigurationCard/${agency}`);
};
export const getDonorCardData = async (agency) => {
  return await GET(BASE_URL + `/dashboard/getDonorCard/${agency}`);
};

export const getInquiryCardData = async (agency) => {
  return await GET(BASE_URL + `/dashboard/getInquiryCard/${agency}`);
};
export const getUserManagementCardData = async (agency) => {
  return await GET(BASE_URL + `/dashboard/getUserManagementCard/${agency}`);
};
export const getTfaCardData = async (agency) => {
  return await GET(BASE_URL + `/dashboard/getTfaCard/${agency}`);
};

export const getDashBoardStats = async (agency) => {
  return await GET(BASE_URL + `/dashboard/getDashBoardStats/${agency}`);
};

export const getAgencyWiseAllSummary = async () => {
  return await GET(BASE_URL + `/dashboard/agencyWiseSummaryAll`);
};

export const getNotifications = async () => {
  return await GET(BASE_URL + `/dashboard/getNotifications`);
};

export const readNotifications = async (body) => {
  return await POST(BASE_URL + `/dashboard/readNotifications`, body);
};

// --------------- DONOR REQUESTS -----------------

export const addDonorRequest = async (body) => {
  return await POST(BASE_URL + `/donor_requests/addDonorRequest`, body);
};

export const getDonorRequests = async (id, body, token) => {
  return await GET(BASE_URL + `/donor_requests/donorRequests`, body);
};

export const getPublishedDonorRequests = async (id, body, token) => {
  return await GET(BASE_URL + `/donor_requests/getPublishedDonorRequests`, body);
};

export const getMyDonorRequests = async (id, body, token) => {
  return await GET(BASE_URL + `/donor_requests/getMyDonorRequests`, body);
};

export const addDonorRequestInterest = async (body) => {
  return await POST(BASE_URL + `/donor_requests/addDonorRequestInterest`, body);
};

export const removeDonorRequestInterest = async (body) => {
  return await POST(BASE_URL + `/donor_requests/removeDonorRequestInterest`, body);
};

export const GetDonorRequestInterests = async () => {
  return await GET(BASE_URL + `/donor_requests/getMyDonorRequestInterests`);
};

export const GetPublishedDonorRequests = async () => {
  return await GET(BASE_URL + `/donor_requests/getPublishedDonorRequests`);
};

export const GetTechincalAssistanceCount = async () => {
  return await GET(BASE_URL + `/donor_requests/getTechnicalAssistanceCount`);
};

export const GetCapacityBuildingCount = async () => {
  return await GET(BASE_URL + `/donor_requests/getCapacityBuildingCount`);
};

export const getPendingDonorRequests = async () => {
  return await GET(BASE_URL + `/donor_requests/getPendingDonorRequests`);
};

export const publishDonorRequest = async (body) => {
  return await POST(BASE_URL + `/donor_requests/publishDonorRequest`, body);
};

export const rejectDonorRequest = async (body) => {
  return await POST(BASE_URL + `/donor_requests/rejectDonorRequest`, body);
};

export const getAllDonorRequests = async () => {
  return await GET(BASE_URL + `/donor_requests/getAllDonorRequests`);
};

export const donorRequestAssign = async (data) => {
  return await POST(BASE_URL + `/donor_requests/donorRequestAssign`, data);
};

export const getAllDonorRequestInterests = async () => {
  return await GET(BASE_URL + `/donor_requests/getAllDonorRequestInterests`);
};

export const GetAllAssignedRequests = async () => {
  return await GET(BASE_URL + `/donor_requests/getAllAssignedRequests`);
};

export const getDonorCounts = async () => {
  return await GET(BASE_URL + `/donor_requests/getDonorCounts`);
};

export const donorRequestUnassign = async (data) => {
  return await POST(BASE_URL + `/donor_requests/donorRequestUnassign`, data);
};

export const getDonorAssignedProjects = async () => {
  return await GET(BASE_URL + `/donor_requests/getMyAssignedDonorRequests`);
};

export const editMyDonorRequest = async (body) => {
  return await POST(BASE_URL + `/donor_requests/editMyDonorRequest`, body);
};

export const deleteMyDonorRequest = async (body) => {
  return await POST(BASE_URL + `/donor_requests/deleteMyDonorRequest`, body);
};

export const getConfirmedRequestProjects = async (body) => {
  return await GET(BASE_URL + `/donor_requests/getConfirmedRequestProjects`, body);
};

// --------------- DONOR INTERESTS -----------------

export const addDonorInterests = async (body) => {
  return await POST(BASE_URL + `/donor_interests/addDonorInterest`, body);
};

export const getDonorInterests = async (id, body, token) => {
  return await GET(BASE_URL + `/donor_interests/donorInterests`, body);
};

export const GetMyDonorInterests = async () => {
  return await GET(BASE_URL + `/donor_interests/getMyDonorInterests`);
};

export const GetDonorInterestsByAgency = async (agency_id) => {
  return await GET(BASE_URL + `/donor_interests/getDonorInterestsByAgency/${agency_id}`);
}

export const getMyDonorInterestAssignments = async () => {
  return await GET(BASE_URL + `/donor_interests/getMyDonorInterestAssignments`);
};

export const GetPendingAssignedRequests = async () => {
  return await GET(BASE_URL + `/donor_interests/getPendingDonorInterests`);
};

export const PublishedPendingAssignedRequests = async (data) => {
  return await POST(BASE_URL + `/donor_interests/publishDonorInterest`, data);
};

export const getPublishedDonordinterests = async () => {
  return await GET(BASE_URL + `/donor_interests/getPublishedDonorInterests`);
};

export const AddDonorInterestinterests = async (data) => {
  return await POST(BASE_URL + `/donor_interests/addDonorInterestsInterest`, data);
};

export const getAllDonorInterests = async () => {
  return await GET(BASE_URL + `/donor_interests/getAllDonorInterests`);
};

export const getAllDonorInterestInterests = async () => {
  return await GET(BASE_URL + `/donor_interests/getAllDonorInterestInterests`);
};

export const removeDonorIntereststInterest = async (body) => {
  return await POST(BASE_URL + `/donor_interests/removeDonorIntereststInterest`, body);
};
export const getMyDonorInterestsInterest = async () => {
  return await GET(BASE_URL + `/donor_interests/getMyDonorInterestInterests`);
};
export const AssignInterestsAgency = async (body) => {
  return await POST(BASE_URL + `/donor_interests/donorInterestAssign`, body);
};
export const unAssignInterestsAgency = async (body) => {
  return await POST(BASE_URL + `/donor_interests/donorInterestUnassign`, body);
};
export const getDonorAssists = async () => {
  return await GET(BASE_URL + `/donor_requests/getMyDonorAssists`);
};

export const getMyAssignedDonorInterests = async () => {
  return await GET(BASE_URL + `/donor_interests/getMyAssignedDonorInterests`);
}

export const getConfirmedInterestProjects = async (body) => {
  return await GET(BASE_URL + `/donor_interests/getConfirmedInterestProjects`, body);
};


// --------------- DONOR CONTRIBUTION -----------------

export const getDonorContribution = async (donor) => {
  return await GET(BASE_URL + `/contributions/getDonorContribution/${donor}`);
};

export const getMyAgencyContributionCreations = async () => {
  return await GET(BASE_URL + `/contributions/getMyAgencyContributionCreations`)
}

export const addContribution = async (data) => {
  return await POST(BASE_URL + `/contributions/addContribution`, data);
};

export const getAllContributions = async () => {
  return await GET(BASE_URL + `/contributions/getAllContributions`);
};

// --------------- PARTICIPANTS -----------------

export const AddActionParticipant = async (body) => {
  return await POST(BASE_URL + `/participants/addActionParticipant`, body);
};
export const GetActionParticipants = async (agency, action) => {
  return await GET(BASE_URL + `/participants/getActionParticipants/${agency}/${action}`);
};
export const RemoveActionParticipant = async (body) => {
  return await POST(BASE_URL + `/participants/removeActionParticipant`, body);
};
export const AddSubActionParticipant = async (body) => {
  return await POST(BASE_URL + `/participants/addActionSubactionParticipant`, body);
};
export const GetSubActionParticipant = async (agency, action, suaction) => {
  return await GET(BASE_URL + `/participants/getActionParticipants/${agency}/${action}/${suaction}`);
};
export const RemoveSubActionParticipant = async (body) => {
  return await POST(BASE_URL + `/participants/removeActionSubactionParticipant`, body);
};
export const searchParticipants = async (id, name) => {
  return await GET(BASE_URL + `/participants/searchParticipants/${id}/${name}`);
};
export const getAllUsers = async (id) => {
  return await GET(BASE_URL + `/participants/searchParticipants/${id}`);
};

// --------------- INQUIRIES -----------------

export const addInquiry = async (body) => {
  return await POST(BASE_URL + `/inquiries/addInquiry`, body);
};

export const getAllInquiries = async () => {
  return await GET(BASE_URL + `/inquiries/getAllInquiries`);
};

export const getMyInquiries = async () => {
  return await GET(BASE_URL + `/inquiries/getMyInquiries`);
};

export const getAllInquiryCounts = async () => {
  return await GET(BASE_URL + `/inquiries/getAllInquiryCounts`);
};

export const getPendingInquiries = async () => {
  return await GET(BASE_URL + `/inquiries/getPendingInquiries`);
};

export const getPendingInquiryCounts = async () => {
  return await GET(BASE_URL + `/inquiries/getPendingInquiryCounts`);
};

export const getPublishedInquiries = async () => {
  return await GET(BASE_URL + `/inquiries/getPublishedInquiries`);
};

export const getAnsweredInquiries = async () => {
  return await GET(BASE_URL + `/inquiries/getAnsweredInquiries`);
};

export const assignToInquiry = async (body) => {
  return await POST(BASE_URL + `/inquiries/assignToInquiry`, body);
};

export const forwardInquiryTo = async (body) => {
  return await POST(BASE_URL + `/inquiries/forwardInquiry`, body);
};

export const addInquiryResponse = async (body) => {
  return await POST(BASE_URL + `/inquiries/addInquiryResponse`, body);
};

export const publishInquiry = async (body) => {
  return await POST(BASE_URL + `/inquiries/publishInquiry`, body);
};

export const sendInquiryReminder = async (body) => {
  return await POST(BASE_URL + `/inquiries/sendInquiryReminder`, body);
};

export const unPublishInquiry = async (body) => {
  return await POST(BASE_URL + `/inquiries/unpublishInquiry`, body);
};

export const createSchedules = async (data) => {
  return await POST(BASE_URL + `/schedules/createEvent`, data);
};
export const UpdateSchedules = async (data) => {
  return await POST(BASE_URL + `/schedules/updateEvent`, data);
};


export const getUsersByAgencies = async (val) => {
  return await POST(BASE_URL + `/users/getUsersByAgencies`, val);
};

export const getSchedules = async (data) => {
  return await POST(BASE_URL + `/schedules/getEvents`, data);
};

export const deleteSchedules = async (data) => {
  return await POST(BASE_URL + `/schedules/deleteEvent`, data);
};
export const addSchedulesComments = async (data) => {
  return await POST(BASE_URL + `/schedules/addEventComment`, data);
};
export const addSchedulesParticipants = async (data) => {
  return await POST(BASE_URL + `/schedules/addEventParticipants`, data);
};

export const deleteSchedulesParticipants = async (data) => {
  return await POST(BASE_URL + `/schedules/deleteEventParticipants`, data);
};

export const downloadSheduleFiles = async (token) => {
  return await GET(BASE_URL + `/schedules/downloadFile/${token}`, {}, true, true);
};

export const deleteEventFiles = async (body) => {
  return await POST(BASE_URL + `/schedules/deleteEventComment`, body);
};

// --------------- CHAT -----------------

export const createChat = async (data) => {
  return await POST(BASE_URL + `/chats/createChat`, data);
};

export const getChats = async (data) => {
  return await GET(BASE_URL + `/chats/getChats`, data);
};

export const getGroupChats = async (data) => {
  return await GET(BASE_URL + `/chats/getGroupChats`, data);
};

export const getChat = async (chat_id) => {
  return await GET(BASE_URL + `/chats/getChat/${chat_id}`, {});
};

export const addMessage = async (data) => {
  return await POST(BASE_URL + `/chats/addMessage`, data);
};

export const downloadChatFiles = async (token) => {
  return await GET(BASE_URL + `/chats/downloadFile/${token}`, {}, true, true);
};

export const getUnreadCount = async (data) => {
  return await GET(BASE_URL + `/chats/getUnreadCount`, {});
};


export const addNewMember = async (data) => {
  return await POST(BASE_URL + `/chats/addParticipant`, data);
};

export const editContribution = async (data) => {
  return await POST(BASE_URL + `/contributions/updateContribution`, data);
};

export const editInterest = async (data) => {
  return await POST(BASE_URL + `/donor_interests/updateDonorInterest`, data);
};
export const editDonorProfile = async (data) => {
  return await POST(BASE_URL + `/users/editMyProfile`, data);
};

