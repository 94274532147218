import {
  Button,
  Card,
  Col,
  Row,
  Table,
  Space,
  Typography,
  Form,
  Input,
  Select,
  Checkbox,
  Popconfirm,
  Modal,
  Badge,
  Divider,
  Alert,
  Tag,
  Upload,
  Popover
} from "antd";
import { useEffect, useState } from "react";
import {
  DownloadFiles,
  addInquiry,
  assignToInquiry,
  getAgencies,
  getAllInquiries,
  getMyInquiries,
  getUsersByAgency,
} from "../../../api";
import { InboxOutlined } from "@ant-design/icons";
import Dragger from "antd/es/upload/Dragger";
import { ToastContainer, toast, Zoom } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";
import FullSearch from "../../../Components/FullSearch";

const MyInquiries = ({ showMessage, setRefreshCounts }) => {
  const [form] = Form.useForm();
  const [inquiries, setInquiries] = useState([]);
  const [inquiriesFull, setInquiriesFull] = useState([]);
  const [inquiry, setInquiry] = useState(null);
  const [agencies, setAgencies] = useState([]);
  const [agency, setAgency] = useState(null);
  const [users, setUsers] = useState([]);
  const [user, setUser] = useState(null);
  const [fileList, setFileList] = useState([]);
  const [publishable, setPublishable] = useState(false);
  const [ntfLevel, setNtfcLevel] = useState(null);
  const [ntfcAgency, setNtfcAgency] = useState(null);
  const [agencyError, setAgencyError] = useState(false);
  const [userError, setUserError] = useState(false);
  const [isModalOpen, setModalOpen] = useState(false);
  const [attachPublishale, seAttachtPublishable] = useState(false);
  const [formData, setFormData] = useState();
  const [show, setShow] = useState(false);
  const [inqForCount, setInquiryForCount] = useState("");
  const [isChair, setChair] = useState(false)


  const handleInputChange = (e) => {
    setInquiryForCount(e.target.value);
  };
  useEffect(() => {
    if (window.localStorage.getItem('ntfc_level') == 6) {
      setChair(true)
    } else {
      setChair(false)
    }
    loadData();
  }, []);



  const loadData = async () => {
    setNtfcLevel(await window.localStorage.getItem("ntfc_level"));
    setNtfcAgency(await window.localStorage.getItem("ntfc_agency"));
  };
  useEffect(() => {
    getMyInquiries().then((res) => {
      if (res && res.data && res.data.out) {
        const dat = res.data.out.map((item) => {
          return {
            ...item,
            created_format: formatDate(item.created_at),
            assigned_format: item.assignments && item.assignments.length > 0 ? moment(item.assignments[0]["created"]).format("DD/MM/YYYY") : "-",
            assignee_format: item.assignments && item.assignments.length > 0 ? item.assignments[0]["username"] : item.answer_pending === 0 ? "NTFC" : "-",
            agency_format: item.assignments && item.assignments.length > 0 ? item.assignments[0]["agency_name"] : "-"
          }
        })
        setInquiries(dat);
        setInquiriesFull(dat);
      }
    });
    getAgencies().then((res) => {
      if (res && res.data && res.data.out) {
        console.log('Agencieeesss', res.data.out)
        let new_object = {
          "value": 9999,
          "agency_type": 1,
          "label": "NTFC Secretariat"
        }

        if (window.localStorage.getItem("ntfc_level") != 2) {
          res.data.out.unshift(new_object);
          setAgencies(
            res.data.out.filter(
              (element) =>
                element.value != window.localStorage.getItem("ntfc_agency")
            )
          );
        } else {
          setAgencies(
            res.data.out
          );
        }
      }
    });
  }, []);

  useEffect(() => {
    getUsersByAgency(agency, {}).then((res) => {
      if (res && res.data && res.data.out) {
        setUsers(res.data.out);
      }
    });
  }, [agency]);

  const formatDate = (str) => {
    const dmy = str.split("T")[0].split("-");
    return `${dmy[2]}/${dmy[1]}/${dmy[0]}`;
  };

  const columns = [
    {
      title: "Title of the Inquiry",
      dataIndex: "topic",
      key: "topic",
      ellipsis: true,
      width: 150,
      render(text, record) {
        return {
          props: {
            style: {
              overflow: "hidden",
            },
          },
          children: <div>{text}</div>,
        };
      },
    },
    // {
    //     title: 'Description',
    //     dataIndex: 'description',
    //     key: 'description',
    //     width: '30%',
    //     render(text, record) {
    //         return {
    //             props: {
    //                 style: {
    //                     overflow: 'hidden'
    //                 },
    //             },
    //             children: <div>{text}</div>,
    //         };
    //     },
    // },
    // {
    //   title: "Created by",
    //   dataIndex: "name",
    //   key: "name",
    //   render(text, record) {
    //     return {
    //       props: {
    //         style: {
    //           overflow: "hidden",
    //         },
    //       },
    //       children: <div>{text}</div>,
    //     };
    //   },
    // },
    {
      title: "Created Date",
      dataIndex: "created_at",
      key: "created_at",
      width: 90,
      render: (_, { created_at }) => <>{formatDate(created_at)}</>,
    },
    {
      title: "Assignee",
      dataIndex: "assignments",
      key: "assignments",
      width: 100,
      render: (_, { assignments, answer_pending, responses }) => (
        <Space>

          {responses && responses.length > 0 ? <span style={{ whiteSpace: "pre-line" }}>{responses[responses.length - 1]['username']}</span> :
            (assignments && assignments.length > 0
              ? <span style={{ whiteSpace: "pre-line" }}> {assignments[0]["username"]}</span>
              : answer_pending === 0
                ? "NTFC"
                : "-")}
        </Space>
      ),
    },
    {
      title: "Assigned Date",
      dataIndex: "assignments",
      key: "assignment_date",
      width: 95,
      render: (_, { assignments, responses }) => (
        <Space>
          {responses && responses.length > 0 ? moment(responses[responses.length - 1]["created"]).format("DD/MM/YYYY") :
            (assignments && assignments.length > 0
              ? moment(assignments[0]["created"]).format("DD/MM/YYYY")
              : "-")}
        </Space>
      ),
    },
    {
      title: "Assigned Agency",
      dataIndex: "assignments",
      key: "assignment_agency",
      width: 120,
      // ellipsis: true,
      render: (_, { assignments, responses }) => (
        <Space>
          {assignments && assignments.length > 0
            ? <span style={{ whiteSpace: "pre-line" }}>{assignments[0]["agency_name"]}</span>
            : "-"}
        </Space>
      ),
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      width: 180,
      ellipsis: true,
      render: (_, { description }) => (
        <Space>
          {description.length > 30 ?
            <span>
              {`${description.substring(0, 25)}...`}
              <Popover arrow content={<div style={{ width: 400 }} >{description}</div>} >
                <a>see more</a>
              </Popover>
            </span>
            : description}
        </Space>

      ),



    },
    {
      title: "Status",
      key: "answer_pending",
      dataIndex: "answer_pending",
      width: 80,
      render: (_, { answer_pending }) => (
        <Space>
          <Tag
            bordered={false}
            style={{
              padding: "2px 3px",
              textAlign: "center",
              width: 70,
              color: "white",
              backgroundColor:
                answer_pending === 0
                  ? "#396eea"
                  : answer_pending === 1
                    ? "#FEBD5B"
                    : "#50CD89",
            }}
          >
            {answer_pending === 0
              ? "Submitted"
              : answer_pending === 1
                ? "Pending"
                : "Answered"}
          </Tag>
        </Space>
      ),
    },
  ];

  const normFile = (e) => {
    console.log("Upload event:", e);
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };

  const onFinish = (values) => {
    setFormData(values);
    setModalOpen(true);
  };

  const submitData = async () => {
    let values = formData;
    if (values.topic && values.description) {
      const formData = new FormData();
      console.log("file list", fileList)
      if (fileList) {
        formData.append("inquiry_file", fileList);
      }
      formData.append("topic", values.topic);
      formData.append("description", values.description);
      formData.append("publishable", publishable ? 1 : 0);
      formData.append("publishable_attachment", attachPublishale ? 1 : 0);
      formData.append("agency_id", agency);

      addInquiry(formData)
        .then((out) => {
          console.log("OUTY", out);
          if (out) {
            // if (Number(ntfLevel) > 5) {
            assignUser(out.data.id);
            // }
            form.resetFields();
            showMessage(1, "Inquiry Created Successfully");
            setPublishable(false);
            setFileList([]);
            getMyInquiries().then((res) => {
              if (res && res.data && res.data.out) {
                const dat = res.data.out.map((item) => {
                  return {
                    ...item,
                    created_format: formatDate(item.created_at),
                    assigned_format: item.assignments && item.assignments.length > 0 ? moment(item.assignments[0]["created"]).format("DD/MM/YYYY") : "-",
                    assignee_format: item.assignments && item.assignments.length > 0 ? item.assignments[0]["username"] : item.answer_pending === 0 ? "NTFC" : "-",
                    agency_format: item.assignments && item.assignments.length > 0 ? item.assignments[0]["agency_name"] : "-"
                  }
                })
                setInquiries(dat);
                setInquiriesFull(dat);
              }
              setRefreshCounts(true);
            });
          }
        })
        .catch((err) => {
          console.log("ERRORY", err);
        });
    } else {
      showMessage(0, "Title and Description required");
    }
  };

  async function handleFileUpload(file) {
    console.log("SET FILE", file);
    setFileList(file);
    console.log("File list", fileList);
    if (file) {
      setShow(true);
    } else {
      setShow(false);
    }
  }

  const props = {
    name: "file",
    multiple: false,
    // action: ,
    onChange(info) {
      console.log("FIle", info);
      const { status } = info.file;
      if (status !== "uploading") {
        console.log(info.file, info.fileList);
      }
      if (status === "done") {
        toast.success(`${info.file.name} file uploaded successfully.`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        handleFileUpload(info.file);
      } else if (status === "error") {
        toast.error(`${info.file.name} file upload failed.`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    },
    onDrop(e) {
      console.log("Dropped files", e.dataTransfer.files);
    },
  };

  const assignUser = (inquiry_id) => {
    setAgencyError(false);
    setUserError(false);

    if (!agency) {
      setAgencyError(true);
    }

    if (!user) {
      setUserError(true);
    }

    if (!agency) {
      return;
    }

    assignToInquiry({
      id: inquiry_id,
      agency_id: agency,
    }).then((out) => {
      form.resetFields();
      setInquiry(null);
      getMyInquiries().then((res) => {
        if (res && res.data && res.data.out) {
          const dat = res.data.out.map((item) => {
            return {
              ...item,
              created_format: formatDate(item.created_at),
              assigned_format: item.assignments && item.assignments.length > 0 ? moment(item.assignments[0]["created"]).format("DD/MM/YYYY") : "-",
              assignee_format: item.assignments && item.assignments.length > 0 ? item.assignments[0]["username"] : item.answer_pending === 0 ? "NTFC" : "-",
              agency_format: item.assignments && item.assignments.length > 0 ? item.assignments[0]["agency_name"] : "-"
            }
          })
          setInquiries(dat);
          setInquiriesFull(dat);
        }
        setRefreshCounts(true);
      });
      showMessage(1, out.data.message);
    });
  };

  const FetchFile = async (file) => {
    let out = await DownloadFiles(file.token);

    let blob = new Blob([out.data], { type: file.type });
    let url = window.URL.createObjectURL(blob);

    let link = document.createElement("a");
    link.href = url;

    link.download = file.url.split("-::-")[1];

    document.body.appendChild(link);
    link.click();
  };

  return (
    <div className="inqu-content-div">
      <Row gutter={[16, 16]} style={{ height: "100%" }}>
        <Col lg={6} xs={24} style={{ height: "100%" }}>
          <Card
            className="custom-scroll-div"
            style={{
              height: "calc(70vh - 2%)",
              background: "#f5f9ff",
              maxHeight: "100%",
              overflow: "auto",
            }}
          >
            <div style={{ paddingTop: "10px", paddingLeft: "10px" }}>
              <Typography.Title style={{ color: "#005476" }} level={3}>
                {inquiry ? "View Inquiry" : "Create Inquiry"}
              </Typography.Title>
            </div>
            <div style={{ paddingTop: "2px" }}>

              <Alert message={<Typography.Paragraph style={{ color: "#005476" }} level={3}>
                Please add Inquiries related to <strong>Trade Facilitation Agreement</strong>
              </Typography.Paragraph>}
                type="info"
              />

            </div>

            {inquiry && (
              <Row gutter={[16, 16]} style={{ padding: "10px 0px" }}>
                <Col span={24}>
                  {inquiry.publishable == 1 ? (
                    <Alert
                      message="This inquiry is publishable"
                      type="success"
                      showIcon
                    />
                  ) : (
                    <Alert
                      message="This inquiry is not publishable"
                      type="warning"
                      showIcon
                    />
                  )}
                </Col>
              </Row>
            )}
            <Form form={form} layout="vertical" onFinish={onFinish}>
              <Form.Item
                label="Title of the Inquiry"
                name="topic"
                required
                rules={[
                  {
                    required: true,
                    message: (
                      <p style={{ marginLeft: "12px" }}>Title is required</p>
                    ),
                  },
                  {
                    pattern:
                      /^(?=.*[a-zA-Z])[a-zA-Z0-9!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/? ]+$/, //if field has only spaces, numbers or special characters
                    message: (
                      <p style={{ marginLeft: "12px" }}>
                        Please enter a valid title with alphabetical characters
                      </p>
                    ),
                  },
                ]}
              >
                <Input
                  onChange={handleInputChange}
                  value={inqForCount}
                  type="text"
                  maxLength={100}
                  placeholder="Title of the Inquiry"
                  readOnly={inquiry ? true : false}
                />
              </Form.Item>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  width: "100%",
                }}
              >
                <p>{inqForCount.length} /100</p>
              </div>
              <Form.Item
                label="Inquiry Description"
                name="description"
                required
                rules={[
                  {
                    required: true,
                    message: (
                      <p style={{ marginLeft: "12px" }}>
                        Inquiry Description is required
                      </p>
                    ),
                  },
                  {
                    pattern:
                      /^(?=.*[a-zA-Z])[a-zA-Z0-9!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/? ]+$/, //if field has only spaces, numbers or special characters
                    message: (
                      <p style={{ marginLeft: "12px" }}>
                        Please enter a valid description with alphabetical
                        characters
                      </p>
                    ),
                  },
                ]}
              >
                <Input.TextArea
                  maxLength={500}
                  rows={4}
                  type="text"
                  placeholder="Inquiry Description"
                  showCount
                  readOnly={inquiry ? true : false}
                />
              </Form.Item>


              {inquiry &&
                inquiry.files.length > 0 &&
                <div style={{ marging: "10px 0" }}>
                  <Typography.Text style={{ fontWeight: 600 }}>
                    Attachments
                  </Typography.Text>
                </div>

              }


              {inquiry &&
                inquiry.files.map((file) => {
                  return file.response_id === null ? (
                    <Badge.Ribbon
                      style={{ display: file.publishable ? "flex" : "none" }}
                      placement="end"
                      text={file.publishable ? "Publishable" : null}
                    >
                      <Card>
                        <div
                          className="inquiry-btn"
                          onClick={() => FetchFile(file)}
                        >
                          {file.type.includes("image") ? (
                            <img
                              src="/image.png"
                              alt="image2"
                              style={{ height: 50, width: 50 }}
                            />
                          ) : (
                            <img
                              src="/file.png"
                              alt="file"
                              style={{ height: 50, width: 50 }}
                            />
                          )}
                          {file.url.toString().split("-::-")[1]}
                        </div>
                      </Card>
                    </Badge.Ribbon>
                  ) : null;
                })}
              <br />
              {inquiry && inquiry.responses && inquiry.responses.length > 0 ? (
                <Typography.Title style={{ color: "#005476" }} level={5}>
                  Responses
                </Typography.Title>
              ) : null}
              {inquiry &&
                inquiry.responses.map((response) => {
                  return (
                    <div>
                      <p>{response.content}</p>
                      {inquiry.files.map((file) => {
                        return file.response_id === response.id ? (
                          <div
                            className="inquiry-btn"
                            onClick={() => FetchFile(file)}
                          >
                            {file.type.includes("image") ? (
                              <img
                                src="/image.png"
                                alt="image"
                                style={{ height: 50, width: 50 }}
                              />
                            ) : (
                              <img
                                src="/file.png"
                                alt="file"
                                style={{ height: 50, width: 50 }}
                              />
                            )}
                            {file.url.toString().split("-::-")[1]}
                          </div>
                        ) : null;
                      })}
                      <p style={{ color: "grey", fontSize: 12, margin: 0 }}>
                        <i>Replied By</i>
                      </p>
                      <p>{response.username}</p>
                    </div>
                  );
                })}
              {!inquiry ? (
                <>
                  <Form.Item
                    label="Attachments"
                    name="attachments"
                    valuePropName="fileList"
                    getValueFromEvent={normFile}
                    noStyle
                  >
                    <Row gutter={16}>
                      <Col span={24}>
                        <p style={{ padding: "10px 0" }}>
                          Please ensure that only one attachment is uploaded at
                          a time, and the file size should be limited to a
                          maximum of 5MB.
                        </p>
                        <Dragger
                          style={{ margingTop: 10 }}
                          {...props}
                          showUploadList={{ showProgress: true }}
                          beforeUpload={(file) => {
                            const MAX_SIZE = 5 * 1024 * 1024; // 5 MB
                            if (file.size > MAX_SIZE) {
                              toast.error(
                                "File size exceeds limit! The maximum file upload size is 5MB.",
                                {
                                  position: "top-right",
                                  autoClose: 5000,
                                  hideProgressBar: false,
                                  closeOnClick: true,
                                  pauseOnHover: true,
                                  draggable: true,
                                  progress: undefined,
                                  theme: "colored",
                                }
                              );
                              setFileList([])
                              return Upload.LIST_IGNORE
                            } else {
                              handleFileUpload(file);
                            }
                            return false;
                          }}
                          maxCount={1}
                          onRemove={() => {
                            setShow(false);
                          }}
                        >
                          <p className="ant-upload-drag-icon">
                            <InboxOutlined tyle={{ color: "#33363F" }} />
                          </p>
                          <p className="ant-upload-text">
                            Click or drag a file to this area to upload <br />
                          </p>
                        </Dragger>
                      </Col>
                    </Row>
                  </Form.Item>
                </>) : null}

              {window.localStorage.getItem("ntfc_level") != 2 ?
                <Form.Item label="Agency" name="agency">
                  <Select
                    size="large"
                    placeholder="Agency"
                    style={{ width: "100%" }}
                    options={agencies}
                    onChange={(value) => {
                      setAgency(value);
                    }}
                  />
                </Form.Item>
                : null}
              {!inquiry ? (
                <>
                  <Divider />
                  <div style={{ margingBottom: "40px", width: "100%" }}>
                    <Typography.Text style={{ fontWeight: 600 }}>
                      Consent to publish
                    </Typography.Text>
                  </div>
                  <Form.Item name="publishable" style={{ marginTop: 15 }}>
                    <Checkbox
                      value={publishable}
                      onChange={(e) => setPublishable(e.target.checked)}
                    >
                      I hereby provide my consent to publish the inquiry in the
                      system.
                    </Checkbox>
                  </Form.Item>
                  {show && (
                    <Form.Item
                      name="publishable_attachment"
                      style={{ marginTop: 10 }}
                    >
                      <Checkbox
                        value={attachPublishale}
                        onChange={(e) => seAttachtPublishable(e.target.checked)}
                      >
                        Include the attachment
                      </Checkbox>
                    </Form.Item>
                  )}

                  <div
                    style={{
                      display: "flex",
                      width: "100%",
                      justifyContent: "end",
                    }}
                  >
                    <Modal
                      title={
                        <Typography.Title level={3}>
                          {inquiry ? "View Inquiry" : "Create Inquiry"}
                        </Typography.Title>
                      }
                      // className="custom-modal"
                      open={isModalOpen}
                      onOk={() => {
                        submitData();
                        setModalOpen(false);
                      }}
                      onCancel={() => {
                        setModalOpen(false);
                      }}
                      okText="Yes"
                      cancelText="No"
                      centered
                      width={600}
                    >
                      <div>
                        {publishable ? (
                          <Typography style={{ fontSize: 14 }}>


                            You have given consent to publish your inquiry. Once you create an inquiry, it will be shown to the NTFC Secretariat.
                            The NTFC Secretariat has the ability to publish your inquiry once a response is received.
                            <br /><br />
                            Are you sure you want to create this inquiry?{" "}
                            <br />

                          </Typography>
                        ) : (
                          <Typography>
                            Once you create an inquiry it will be shown to NTFC
                            Secretariat. Are you sure, you want to create this as
                            an inquiry?
                          </Typography>
                        )}
                      </div>
                    </Modal>
                    {/* <Popconfirm
                                    title="Create Inquiry"
                                    description={publishable ? "Are you sure, you want to add this as an inquiry?You have given consent to publish your inquiry. Once you add an inquiry it will be shown to NTFC Secretariat. NTFC secretariat have the ability to publsh your inquiry once a response is recieved"
                                        :
                                        "Once you add an inquiry it will be shown to NTFC Secretariat. Are you sure, you want to add this as an inquiry?"}
                                    onConfirm={() => form.submit()}
                                    onCancel={() => { }}
                                    okText="Yes"
                                    cancelText="No"
                                > */}
                    <Button type="primary" htmlType="submit"
                      disabled={window.localStorage.getItem('view_only') == 1}>
                      <b>Create</b>
                    </Button>
                    {/* </Popconfirm> */}
                  </div>
                </>
              ) : null}

              {inquiry ? (
                <>
                  <div
                    style={{
                      display: "flex",
                      width: "100%",
                      justifyContent: "end",
                    }}
                  >
                    <Space>
                      {Number(ntfLevel) > 5 && inquiry.assignments.length < 1 ? (
                        <Button
                          type="primary"
                          onClick={() => assignUser(inquiry.id)}
                          disabled={window.localStorage.getItem('view_only') == 1}
                        >
                          <b>Assign</b>
                        </Button>
                      ) : null}
                      <Button
                        type="primary"
                        onClick={() => {
                          form.resetFields();
                          setInquiry(null);
                        }}
                      >
                        <b>Close</b>
                      </Button>
                    </Space>
                  </div>
                </>
              ) : null}
            </Form>
          </Card>
        </Col>
        <Col lg={18} xs={24} style={{ overflow: "auto", height: "100%" }}>
          <FullSearch full={inquiriesFull} setFiltered={setInquiries} params={["topic", "name", "created_format", "assigned_format", "assignee_format", "agency_format"]} />
          <Card
            className="full-card"
            style={{
              height: "calc(70vh - 10%)",
              overflow: "auto",
              maxHeight: "100%",
            }}
          >
            <Table
              columns={columns}
              dataSource={inquiries}
              sticky={true}
              className="custom-table-header"
              pagination={{ pageSize: 5, total: inquiries.length }}
              rowClassName={(inq) =>
                inquiry && inq.id === inquiry.id
                  ? "inquiry-rows-selected"
                  : "inquiry-rows"
              }
              onRow={(record, rowIndex) => {
                return {
                  onClick: (event) => {
                    // if (record && record.id) {
                    //   setInquiry(null);
                    // } else {
                    form.resetFields();
                    setInquiry(record);
                    console.log(record);
                    form.setFieldValue("topic", record.topic);
                    form.setFieldValue("description", record.description);
                    if (record.assignments && record.assignments.length > 0) {
                      form.setFieldValue("agency", record.assignments[0].agency_id);
                    }
                    //   setUser(null);
                    // }
                  },
                };
              }}
            />
          </Card>
        </Col>
      </Row>
      {/* <ToastContainer /> */}
    </div>
  );
};

export default MyInquiries;
