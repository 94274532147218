import { Avatar, Badge, Button, Card, Col, ConfigProvider, Row, Space, Tooltip, Typography } from 'antd'
import React from 'react'
import { ToolOutlined, ControlOutlined, EyeOutlined, DeploymentUnitOutlined, BankOutlined, PlusSquareOutlined } from "@ant-design/icons";
import './actionConfig.css'
import { useState } from 'react';
import { useEffect } from 'react';
import { getActionConfigurationCardData } from '../../../../../api';

// Configuration floating buttons
const configurations = [
    {
        id: "agencies",
        ToolTipColor: "cyan",
        ToolTipTitle: "Agencies",
        icon: <BankOutlined />,
        url: `/add-agencies`,
        className: "config-item-title"
    },
    {
        id: "articles",
        ToolTipColor: "cyan",
        ToolTipTitle: "Articles",
        icon: <ControlOutlined />,
        url: `/add-articles`,
        className: "config-item-title"
    },
    {
        id: "measures",
        ToolTipColor: "cyan",
        ToolTipTitle: "Measures",
        icon: <DeploymentUnitOutlined />,
        url: `/add-measures`,
        className: "config-item-title"
    },
    {
        id: "actions",
        ToolTipColor: "cyan",
        ToolTipTitle: "Action ",
        icon: <ControlOutlined />,
        url: `/add-actions`,
        className: "config-item-title"
    },

    {
        id: "actionplan",
        ToolTipColor: "cyan",
        ToolTipTitle: "Setup Action Plan",
        icon: <ToolOutlined />,
        url: `/actionplan`,

    },






]
function ActionConfigurationCard({ user_level }) {

    const [cardData, setCardData] = useState(null)
    const [shouldAnimate, setShouldAnimate] = useState(false);
    const [configButtons, setConfigButtons] = useState()

    useEffect(() => {
        setShouldAnimate(true);
        loadData()

    }, [])

    const loadData = async () => {



        let agency = await window.localStorage.getItem('ntfc_agency')

        getActionConfigurationCardData(agency).then((out) => {
            console.log("Out ---->|||", out);
            let [agencies, articles, measures, action, actionplan,] = configurations
            action.count = out.data.out.actions
            measures.count = out.data.out.measures
            articles.count = out.data.out.articles
            agencies.count = out.data.out.agencies
            setConfigButtons(configurations)
            console.log("Configureeeeeeeeee", configurations)

        });

    }


    return (
        <Card className='action-config-card'>
            <div className='user-inside' style={{ padding: "14px 0", paddingLeft: 1.2 }}>
                <Row gutter={[4, 4]} align={"middle"}>
                    {configButtons?.map((e, i) => {
                        return <div onClick={() => {
                            window.location.href = e.url

                        }} className='action-config-plan-div' style={{ cursor: "pointer", backgroundColor: i == configButtons.length - 1 ? "#50CD89" : "rgba(217, 217, 217, 0.7)" }} >
                            <Col xs={i == configButtons.length - 1 ? 24 : 16}>
                                <Typography className={"config-item-title"}>
                                    {e.ToolTipTitle}
                                </Typography>
                            </Col>
                            <Col xs={4}>
                                {i < configButtons.length - 1 &&
                                    <Typography>
                                        {e.count}
                                    </Typography>}
                            </Col>
                            <Col xs={8}>

                                {i < configButtons.length - 1 &&
                                    <PlusSquareOutlined />
                                }
                            </Col>




                        </div>
                    })}


                </Row>





            </div>


        </Card>
    )
}

export default ActionConfigurationCard