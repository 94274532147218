import React from "react";
import TabLayout from "./TabLayout";
import {
  DownloadFiles,
  getDonorAssignedProjects,
  getMyDonorInterestAssignments,
} from "../../api";
import { useState } from "react";
import dayjs from "dayjs";
import {
  PlusOutlined,
  InboxOutlined,
  EyeOutlined,
  CloseOutlined,
  CloudUploadOutlined,
  CalendarOutlined,
  ContainerOutlined,
  ReconciliationOutlined,
  CalendarFilled,
  FileDoneOutlined,
  BorderlessTableOutlined,
  BoxPlotOutlined,
} from "@ant-design/icons";
import { Button, Col, Modal, Row, Table, Tag, Tooltip, Typography } from "antd";
import { useEffect } from "react";
import { BiCloudDownload } from "react-icons/bi";
import { MainColors, NewColorTheme } from "../../appConst";

function AgencyAssignedInterest({ data, setData }) {
  const [viewDialog, setViewDialog] = useState(false);
  const [currentRecord, setCurrentRecord] = useState();

  // Table Columns
  const columns = [
    {
      title: "Created Date",
      dataIndex: "created",
      key: "created",
      render: (_, { created }) =>
        created ? <div>{dayjs(created).format("DD/MM/YYYY")}</div> : "-",
    },

    {
      title: "Agency",
      dataIndex: "agency_name",
      key: "agency_name",
      render: (_, record) =>
        record ? (
          <div>{record.scope === 0 ? "National Wise" : record.agency_name}</div>
        ) : null,
    },
    {
      title: "Request Type",
      dataIndex: "support_type",
      key: "support_type",
      render: (_, { assistance }) => (
        <span
          // color={assistance == 0 ? "#f50" : "#2a8c00"}

          key={assistance}
        >
          {assistance == 0 ? <Tag style={{ display: "flex", justifyContent: "center" }} color={NewColorTheme.primaryColor}>TS/CB</Tag> :
            <Tag style={{ display: "flex", justifyContent: "center" }} color="#08B3D7">OTHER</Tag>}

        </span>
      ),
    },
    {
      title: "Subject",
      dataIndex: "subject",
      key: "subject",
    },

    {
      title: "Measure Name",
      dataIndex: "measure_name",
      key: "measure_name",
      render: (_, { measure_no, article_no, measure_name }) => (
        <p>{article_no + "." + measure_no + ". " + measure_name}</p>
      ),
    },

    {
      title: "Description",
      dataIndex: "description",
      key: "description",
    },
    // {
    //     title: 'Status',
    //     dataIndex: 'checked',
    //     key: 'checked',
    //     render: (_, { checked
    //     }) => (
    //         <p>{checked ? "Assisted" : "Not Assist"}</p>
    //     ),

    // },

    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      render: (_, record, index) =>
        record ? (
          <div>
            {console.log("recoed", record)}

            {/* <Switch
                                    type="primary"
                                    checked={record.checked}
                                    onChange={(checked) => handleSwitchChange(checked, record)}
                                /> */}
            <Tooltip title="View">
              <Button
                type="secondary"
                size="small"
                onClick={() => {
                  console.log("record", record);
                  setCurrentRecord(record);
                  setViewDialog(true);
                }}
                shape="circle"
                icon={<EyeOutlined style={{ color: "darkgreen" }} />}
              />
            </Tooltip>
          </div>
        ) : null,
    },
  ];

  useEffect(() => {
    loadData();
  }, []);

  const loadData = async () => {
    // setLoad(false)
    const out = await getMyDonorInterestAssignments();

    console.log("out", out);

    if (out.status === 200) {
      var sortedArray = out.data.out.sort(
        (a, b) => new Date(b.created) - new Date(a.created)
      );
      setData(sortedArray);
    }
  };

  const handleCancelView = () => {
    setViewDialog(false);
  };
  const FetchFile = async (file) => {
    let out = await DownloadFiles(file.token);

    let blob = new Blob([out.data], { type: file.type });
    let url = window.URL.createObjectURL(blob);

    let link = document.createElement("a");
    link.href = url;

    link.download = file.url.split("-::-")[1];

    document.body.appendChild(link);
    link.click();
  };
  return (
    <div className="custom-scroll-div tab-layout-div">
      <TabLayout
        table={
          <Table
            size="small"
            // className="custom-table-header"
            className="custom-table"
            rowClassName={(record, index) => index % 2 === 0 ? 'white-row' : 'gray-row'}

            scroll={{
              y: 400,
            }}
            columns={columns}
            dataSource={data}
            pagination={{
              pageSize: 5,
              total: data?.length,
            }}
            onRow={(record, rowIndex) => {
              return {
                onClick: () => {
                  console.log("record--->>", record);
                  setCurrentRecord(record);
                  setViewDialog(true);
                },
              };
            }}
          />
        }
      />

      <Modal
        centered
        className="custom-modal"
        title={
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div>
              {" "}
              <Typography className="custom-modal-title" level={2}>
                View Project
              </Typography>
            </div>
          </div>
        }
        open={viewDialog}
        width={750}
        onCancel={handleCancelView}
        footer={false}
      >
        <div
          className="custom-modal-body custom-scroll-div"
          style={{ height: 500, overflow: "auto" }}
        >
          <Row>
            <Col xs={24}>
              <Row gutter={[8, 8]} align={"top"}>
                <Col xs={24}>
                  <Row gutter={[8, 8]}>
                    <Col xs={2}>
                      <ReconciliationOutlined style={{ fontSize: 22 }} />
                    </Col>
                    <Col xs={22}>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "flex-start",
                        }}
                      >
                        <Typography.Title level={5}>
                          {" "}
                          Subject
                        </Typography.Title>
                        <Typography> {currentRecord?.subject}</Typography>
                      </div>
                    </Col>
                  </Row>
                </Col>
                <Col xs={12}>
                  <Row gutter={[8, 8]} align={"top"}>
                    <Col xs={4}>
                      <CalendarFilled style={{ fontSize: 22 }} />
                    </Col>
                    <Col xs={20}>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "flex-start",
                        }}
                      >
                        <Typography.Title level={5}>
                          {" "}
                          Created Date
                        </Typography.Title>

                        <Typography>
                          {" "}
                          {dayjs(currentRecord?.created).format("DD/MM/YYYY")}
                        </Typography>
                      </div>
                    </Col>
                  </Row>
                </Col>
                <Col xs={24}>
                  <Row gutter={[8, 8]} align={"top"}>
                    <Col xs={2}>
                      <BoxPlotOutlined style={{ fontSize: 22 }} />
                    </Col>
                    <Col xs={22}>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "flex-start",
                        }}
                      >
                        <Typography.Title level={5}> Measure</Typography.Title>

                        <Typography>
                          {" "}
                          {currentRecord?.article_no +
                            "." +
                            currentRecord?.measure_no +
                            ". " +
                            currentRecord?.measure_name}
                        </Typography>
                      </div>
                    </Col>
                  </Row>
                </Col>
                <Col xs={24}>
                  <Row gutter={[8, 8]} align={"top"}>
                    <Col xs={2}>
                      <BorderlessTableOutlined style={{ fontSize: 22 }} />
                    </Col>
                    <Col xs={22}>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "flex-start",
                        }}
                      >
                        <Typography.Title level={5}>
                          {" "}
                          Support Type
                        </Typography.Title>

                        <Typography>
                          {" "}
                          {currentRecord?.assistance == 0
                            ? "Technical Support or Capacity Building"
                            : "Other"}
                        </Typography>
                      </div>
                    </Col>
                  </Row>
                </Col>

                <Col xs={8}>
                  <Row gutter={[8, 8]} align={"top"}>
                    <Col xs={6}>
                      <CalendarOutlined style={{ fontSize: 22 }} />
                    </Col>
                    <Col xs={18}>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "flex-start",
                        }}
                      >
                        <Typography.Title level={5}>
                          {" "}
                          Tentative Start Date
                        </Typography.Title>

                        <Typography>
                          {" "}
                          {dayjs(currentRecord?.implementation_from).format(
                            "DD/MM/YYYY"
                          )}
                        </Typography>
                      </div>
                    </Col>
                  </Row>
                </Col>

                <Col xs={8}>
                  <Row gutter={[8, 8]} align={"top"}>
                    <Col xs={4}>
                      <CalendarOutlined style={{ fontSize: 22 }} />
                    </Col>
                    <Col xs={20}>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "flex-start",
                        }}
                      >
                        <Typography.Title level={5}>
                          {" "}
                          Tentative End Date
                        </Typography.Title>

                        <Typography>
                          {" "}
                          {dayjs(currentRecord?.implementation_to).format(
                            "DD/MM/YYYY"
                          )}
                        </Typography>
                      </div>
                    </Col>
                  </Row>
                </Col>
                <Col xs={8}>
                  <Row gutter={[8, 8]} align={"top"}>
                    <Col xs={6}>
                      <CalendarOutlined style={{ fontSize: 22 }} />
                    </Col>
                    <Col xs={18}>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "flex-start",
                        }}
                      >
                        <Typography.Title level={5}>
                          {" "}
                          Published Date
                        </Typography.Title>

                        <Typography>
                          {" "}
                          {currentRecord?.published_date
                            ? dayjs(currentRecord?.published_date).format(
                              "DD/MM/YYYY"
                            )
                            : "-"}
                        </Typography>
                      </div>
                    </Col>
                  </Row>
                </Col>
                <Col xs={24}>
                  <Row gutter={[8, 8]} align={"top"}>
                    <Col xs={2}>
                      <ContainerOutlined style={{ fontSize: 22 }} />
                    </Col>
                    <Col xs={22}>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "flex-start",
                        }}
                      >
                        <Typography.Title level={5}>
                          {" "}
                          Description
                        </Typography.Title>

                        <Typography>
                          {" "}
                          {currentRecord?.description
                            ? currentRecord?.description
                            : "-"}
                        </Typography>
                      </div>
                    </Col>
                  </Row>
                </Col>
                {currentRecord?.attachments?.length > 0 && (
                  <Col xs={24}>
                    <Row gutter={[8, 8]} align={"top"}>
                      <Col xs={2}>
                        <CloudUploadOutlined style={{ fontSize: 22 }} />
                      </Col>
                      <Col xs={22}>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "flex-start",
                          }}
                        >
                          <Typography.Title level={5}>
                            {" "}
                            Attachment
                          </Typography.Title>

                          {currentRecord?.attachments?.length > 0 &&
                            currentRecord?.attachments.map((element, key) => {
                              return (
                                <div
                                  style={{
                                    alignItems: "center",
                                    display: "flex",
                                    justifyContent: "space-between",
                                    width: "100%",
                                    cursor: "pointer",
                                    background: MainColors.LIGHTBACKCOLOR,
                                    borderRadius: 10,
                                    padding: 10,

                                    ":hover": {
                                      background: MainColors.DARKBACKCOLOR,
                                      color: "white",
                                      // Add any additional styles you want for the hover effect
                                    },
                                  }}
                                  key={key}
                                  onClick={() => FetchFile(element)}
                                >
                                  <div>
                                    <img
                                      src="/file.png"
                                      alt="file"
                                      style={{ height: 50, width: 50 }}
                                    />
                                    {element?.url &&
                                      (element?.url
                                        ?.toString()
                                        .split("-::-"))[1]}
                                  </div>
                                  <BiCloudDownload
                                    style={{ fontSize: 32, color: NewColorTheme.primaryColor }}
                                  />
                                </div>
                              );
                            })}
                        </div>
                      </Col>
                    </Row>
                  </Col>
                )}
                <Col xs={24}>
                  <Row gutter={[8, 8]} align={"top"}>
                    <Col xs={2}>
                      <FileDoneOutlined style={{ fontSize: 22 }} />
                    </Col>
                    <Col xs={22}>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "flex-start",
                        }}
                      >
                        <Typography.Title level={5}> Remarks</Typography.Title>

                        <Typography>
                          {" "}
                          {currentRecord?.reject_reason
                            ? currentRecord?.reject_reason
                            : currentRecord?.publish_reason
                              ? currentRecord?.publish_reason
                              : "-"}
                        </Typography>
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
        {/* <p>{record.checked ? "Are you sure to assist this project?" : "Are you sure to remove this project?"}</p> */}
      </Modal>
    </div>
  );
}

export default AgencyAssignedInterest;
