import React from 'react'
import { useState } from 'react'
import { UserOutlined, CalendarOutlined } from "@ant-design/icons";
import { getAgencies, getUserById } from '../../../../api';
import { useEffect } from 'react';
import { Avatar, Button, Col, Form, Popover, Row, Select, Space, Tooltip, Typography } from 'antd';


function NewNavBar({ onSelectChange }) {
    const [userData, setUserData] = useState(null)
    const [agencies, setAgencies] = useState();
    const [selectedAgency, setSelectedAgency] = useState(null);
    const [form] = Form.useForm();

    useEffect(() => {
        getUserData()
        loadData()
    }, [])

    const getUserData = async function () {
        const out = await getUserById(await window.localStorage.getItem('user_id'))
        let user = out.data.user

        if (out.status === 200) {

            if (user.user_level === 7) {
                user.userDesignation = "Super Admin"
                // userDashboardName = "Dashboard - NTFC Secretariat "
                // myTheme.dashBordBack = "#005476"
            } else if (user.user_level === 6) {
                user.userDesignation = "Secretariat"
                // userDashboardName = "Dashboard - NTFC Secretariat"
                // myTheme.dashBordBack = "#005476"

            } else if (user.user_level === 5) {
                user.userDesignation = "Head of the Department"
                // userDashboardName = "Dashboard - " + (user.agency_name ? user.agency_name : "N/A")


            } else if (user.user_level === 4) {
                user.userDesignation = "Technical User"
                // userDashboardName = "Dashboard - " + (user.agency_name ? user.agency_name : "N/A")


            } else if (user.user_level === 3) {
                // userDesignation = "Private"
                user.userDesignation = "Donor"
                // userDashboardName = "Dashboard - " + (user.agency_name ? user.agency_name : "N/A")


            } else if (user.user_level === 2) {
                user.userDesignation = "Private Sector User"
                // userDashboardName = "Dashboard -  " + (user.agency_name ? user.agency_name : "N/A")


            } else if (user.user_level === 1) {
                user.userDesignation = "User"
                // userDashboardName = "Dashboard - " + (user.agency_name ? user.agency_name : "N/A")

            }


            setUserData(user)




        }


    }

    const loadData = async () => {
        let agency = await window.localStorage.getItem('ntfc_agency')
        let level = await window.localStorage.getItem('ntfc_level')
        getAgencies().then((out) => {

            out.data.out.unshift({ label: "All Country Wise", value: 0 })
            if (Number(level) == 5 || Number(level) == 4) {

                let filteredAgency = out.data.out.filter((e) => e.value == agency)[0]
                let filterer = out.data.out.filter((e) => e.value == agency)
                filterer.unshift({ label: "All Country Wise", value: 0 })

                setAgencies(filterer)
                setSelectedAgency(filteredAgency)
                form.setFieldValue('agency', filteredAgency)
                onSelectChange(filteredAgency.value)

            } else {

                setAgencies(out.data.out)
                setSelectedAgency(out.data.out[0])
                onSelectChange(0)
                form.setFieldValue('agency', out.data.out[0])



            }

        });
    }


    return (
        <div className='custom-nav-bar' >
            <Row gutter={16} justify={"space-between"} align={"middle"}>
                <Col style={{ cursor: "pointer" }} span={12} onClick={() => {
                    window.location.href = '/newdashboard'
                }}>
                    <Space size={"large"}>
                        <img src='/logo.png' alt='ntfc logo' className='nav-logo' />
                        <Typography className='nav-bar-title'>

                            <span>National Trade</span>
                            <span>Facilitation Committee</span>
                        </Typography>
                    </Space>


                </Col>
                <Col span={12}>
                    <Row gutter={16} justify={"end"} align={"middle"}>
                        {/* <Col span={12}>
                            <div style={{ display: "flex", justifyContent: "flex-end", width: "100%" }}>
                                <Form form={form}>

                                    <Form.Item style={{ width: 400 }} name={"agency"}>


                                        <Select

                                            onChange={(e) => {
                                                setSelectedAgency(e)
                                                onSelectChange(e)

                                            }
                                            }
                                            style={{ width: '100%' }}
                                            placeholder="Select Agency"
                                            options={agencies} />
                                    </Form.Item>
                                </Form>
                            </div>


                        </Col> */}
                        {/* <Col span={4}>
                            <div style={{
                                display: 'flex',
                                justifyContent: 'end',
                                width: '100%'
                            }}>

                                <Button onClick={() => {
                                    window.location.href = "/newcalender"
                                }} type="primary" icon={<CalendarOutlined style={{ fontSize: 15 }} />}>Calender</Button>


                            </div>

                        </Col> */}
                        <Col span={12}>
                            <div className="user-details" >

                                <div className="name-designation">

                                    <Typography className="user-name" style={{ fontSize: 18 }}>

                                        {(userData ? userData?.first_name : "") + " " + (userData ? userData?.last_name : "")}
                                    </Typography>
                                    <br />
                                    <Typography className="user-designation">

                                        {(userData ? userData.agency_name : "") + " - " + (userData ? userData.userDesignation : "")}
                                    </Typography>
                                    <Typography className="user-designation">


                                    </Typography>


                                </div>
                                <Popover
                                    content=
                                    {
                                        <Button

                                            onClick={async () => {
                                                await window.localStorage.removeItem('ntfc_token');
                                                window.location.href = "/login";
                                            }}>
                                            Logout
                                        </Button>
                                    }
                                    trigger="click">

                                    <Avatar className="avatar" shape="square" size={50} icon={<UserOutlined />} />

                                </Popover>







                            </div>
                        </Col>


                    </Row>
                </Col>

            </Row>


        </div>
    )
}

export default NewNavBar