import { Badge, Avatar, Space, Typography, Card, Row, Col } from 'antd';
import { BsBell, BsCalendarEvent, BsPeople, BsUiChecks } from "react-icons/bs";
import './home.styles.css';
// import EventCalendar from './EventCalendar';
import EventCalendarOld from './EventCalendarOld';
import Tasks from './Tasks';
const { Title, Text } = Typography;

function Page() {

  return (
    <div className='content'>
      <Row gutter={16} style={{ marginTop: 10 }}>
        <Col className="gutter-row" span={6}>
          <Card className='reminders' size='small'>
            <Space size="large">
              <Badge count={5}>
                <Avatar style={{ backgroundColor: 'transparent' }} shape="square" size="large" icon={<BsBell color='#dd564a' />} />
              </Badge>
              <Title style={{ margin: 0 }} level={5}>Reminders</Title>
            </Space>
          </Card>
        </Col>
        <Col className="gutter-row" span={6}>
          <Card className='events' size='small'>
            <Space size="large">
              <Badge count={5}>
                <Avatar style={{ backgroundColor: 'transparent' }} shape="square" size="large" icon={<BsCalendarEvent color='#2d6a3f' />} />
              </Badge>
              <Title style={{ margin: 0 }} level={5}>Upcoming Events</Title>
            </Space>
          </Card>
        </Col>
        <Col className="gutter-row" span={6}>
          <Card className='meetings' size='small'>
            <Space size="large">
              <Badge count={5}>
                <Avatar style={{ backgroundColor: 'transparent' }} shape="square" size="large" icon={<BsPeople color='#e68943' />} />
              </Badge>
              <Title style={{ margin: 0 }} level={5}>Upcoming Meetings</Title>
            </Space>
          </Card>
        </Col>
        <Col className="gutter-row" span={6}>
          <Card className='tasks' size='small'>
            <Space size="large">
              <Badge count={1}>
                <Avatar style={{ backgroundColor: 'transparent' }} shape="square" size="large" icon={<BsUiChecks color='#61679c' />} />
              </Badge>
              <Title style={{ margin: 0 }} level={5}>Delayed Tasks</Title>
            </Space>
          </Card>
        </Col>
      </Row>
      <Row gutter={16} style={{ marginTop: 10 }}>
        <Col className="gutter-row" span={12}>
          <Space size={"large"} direction={'vertical'} style={{ width: '100%' }}>
            <Text strong>Ongoing Tasks</Text>
            <Tasks />
          </Space>
        </Col>
        <Col className="gutter-row" span={12}>
          <Space size={"large"} direction={'vertical'}>
            <Text strong>Event Calendar</Text>
            <EventCalendarOld />
          </Space>
        </Col>
      </Row>
    </div>
  );
}

export default Page;
