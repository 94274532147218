import { Modal, Row, Space, Tooltip, message, Typography, Button, Col, List, Avatar, Form, Input } from 'antd';
import React, { useState, useEffect } from 'react';
import TitleBox from '../TitleBox/TitleBox';
import MemberCard from '../MemberCard/MemberCard';
import { LoadingOutlined, MessageOutlined, CommentOutlined, InteractionOutlined } from '@ant-design/icons';

import { AddAgencyActionComment, addParticipants, getActionParticipants, getAgencyActivities, getAllUsers } from '../../api';
import CommentBox from '../CommentBox/CommentBox';
import VirtualList from 'rc-virtual-list';

const { Text } = Typography;

const CommentModal = ({ action }) => {
    console.log("Action Comment", action);
    const [AddCommentDialog, addComment] = useState(false)
    const [commentLoading, setLoad] = useState(false);
    const [allActivities, setActivities] = useState([]);
    const [seeMore, setSeeMore] = useState(false);
    const [commentAdding, setCommentLoad] = useState(false);
    const [comment, setComment] = useState("")

    useEffect(() => {

        getActivityLog();

    }, []);

    async function getActivityLog() {
        console.log("Action Comment data load", action);

        setLoad(true)
        let out = await getAgencyActivities(action.agency_id, action.action_id)

        if (out.status === 200) {
            setActivities(out.data.out)
            setLoad(false)
        }


    }


    useEffect(() => {
        message.config({
            maxCount: 1,
            duration: 2,
        });
    }, []);

    const AddNewComment = async (id) => {


        setCommentLoad(true)

        let sendData = {
            agency_id: action.agency_id,
            action_id: action.action_id,
            comment: comment

        }
        // if (!mainAction) {
        //     sendData.subaction_id = id
        // }
        const out = await AddAgencyActionComment(sendData)

        if (out.data.status === 200) {

            message.success(out.data.message);
            getActivityLog()
            setComment("")

        } else {

            message.error(out.data.message);

        }
        setCommentLoad(false)

    }

    return (
        <div>
            <div className="comment">
                <Tooltip title="Add Comment">
                    <Button onClick={() => {
                        addComment(true)
                    }} shape="circle" icon={<MessageOutlined />} />
                </Tooltip>
            </div>
            <Modal
                // title="Vertically centered modal dialog"
                centered
                open={AddCommentDialog}
                onOk={() => addComment(false)}
                onCancel={() => addComment(false)}
                footer={false}

            >
                <TitleBox
                    title={"Comments"}
                    body={
                        <div style={{ display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
                            <div style={{ marginLeft: -14 }}>
                                {!commentLoading ?
                                    <List>
                                        <VirtualList
                                            // data={mainAction ?
                                            //     allActivities.filter(element =>
                                            //         (element.activity_type === 1 || element.activity_type === 3) && element.action_id === action.action_id)
                                            //     :
                                            //     allActivities.filter(element => (element.activity_type === 2 || element.activity_type === 4) && element.action_id === sub.id)
                                            // }
                                            data={allActivities.filter(element => element.activity_type === 1 || element.activity_type === 2)}
                                            height={600}
                                            itemHeight={50}
                                            itemKey="email"
                                        // onScroll={onScroll}


                                        >

                                            {(item, key) => {

                                                <List.Item key={key} style={{
                                                    backgroundColor: " #FFFF",


                                                    padding: 10, margin: 5, borderRadius: "0 10px 10px 10px"
                                                }}>

                                                    <div> {new Date(item.created).toISOString().slice(8, 10) + "/" + new Date(item.created).toISOString().slice(5, 7)}</div>




                                                </List.Item>
                                            }}
                                        </VirtualList>
                                    </List> : <LoadingOutlined style={{ fontSize: "16px", color: "blueviolet" }} />}


                                {/* <CommentBox name="Beyond Nexy" comment={"Newk Comment"} /> */}

                            </div>
                            <div style={{
                                position: 'absolute',
                                bottom: '0px',
                                width: '97%',
                                padding: '10px',
                                left: '3px'
                            }}>
                                <Form onFinish={() => AddNewComment(action && action.action_id)}>
                                    <Form.Item
                                        // label="Comment"
                                        name="comment"
                                        rules={[{ required: true, message: 'Please enter a comment' }]}
                                    >
                                        <Input.TextArea style={{ borderRadius: 15 }} rows={5} value={comment} onChange={(e) => setComment(e.target.value)} placeholder='Enter Comment' />

                                    </Form.Item>
                                    <Button loading={commentAdding} style={{
                                        position: 'absolute',
                                        right: '16px',
                                        bottom: '46px'
                                    }} type="primary"
                                        htmlType='submit'
                                    >Send</Button>
                                </Form>
                            </div>
                        </div>
                    }

                />
            </Modal>
        </div>
    );
};

export default CommentModal;
