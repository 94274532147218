export const user_types = [

  {
    level: -1,
    type: "OTHER",
    label: "OTHER",
    value: "OTHER"

  },

  {
    level: 0,
    type: "PRESIDENTIAL",
    label: "PRESIDENTIAL SECRETARIAT",
    value: "PRESIDENTIALSECRETARIAT"

  },
  {
    level: 1,
    type: "DIRECTOR GENERAL",
    label: "DIRECTOR GENERAL",
    value: "DIRECTOR GENERAL"

  },
  {
    level: 2,
    type: "PRIVATE",
    label: "PRIVATE",
    value: "PRIVATE"

  },
  {
    level: 3,
    type: "DONOR",
    label: "DONOR",
    value: "DONOR"

  },
  {
    level: 4,
    type: "TECHNICAL",
    label: "TECHNICAL",
    value: "TECHNICAL"

  },
  {
    level: 5,
    type: "GOV. HOD.",
    label: "GOV. HOD.",
    value: "GOV. HOD."

  },
  {
    level: 6,
    type: "SECRETARIAT",
    label: "SECRETARIAT",
    value: "SECRETARIAT"

  },
  {
    level: 7,
    type: "SUPER ADMIN",
    label: "SUPER ADMIN",
    value: "SUPER ADMIN"

  }
]

export const user_types2 = [
  {
    level: 6,
    type: "SECRETARIAT",
    label: "SECRETARIAT",
    value: "SECRETARIAT"

  },
  {
    level: 5,
    type: "GOV. HOD.",
    label: "GOV. HOD.",
    value: "GOV. HOD."

  },
  {
    level: 4,
    type: "TECHNICAL",
    label: "TECHNICAL",
    value: "TECHNICAL"

  },
  {
    level: 3,
    type: "DONOR",
    label: "DONOR",
    value: "DONOR"

  },
  {
    level: 2,
    type: "PRIVATE",
    label: "PRIVATE",
    value: "PRIVATE"

  },
  {
    level: 1,
    type: "DIRECTOR GENERAL",
    label: "DIRECTOR GENERAL",
    value: "DIRECTOR GENERAL"

  },
  {
    level: 0,
    type: "PRESIDENTIAL",
    label: "PRESIDENTIAL",
    value: "PRESIDENTIAL"

  },
  {
    level: -1,
    type: "OTHER",
    label: "OTHER",
    value: "OTHER"

  },
]

export const user_types_limited = [
  {
    level: 1,
    type: "Other"
  },
  {
    level: 2,
    type: "Private"
  },
  {
    level: 3,
    type: "Donor"
  },
  {
    level: 4,
    type: "Gov Technical"
  },
  {
    level: 5,
    type: "Gov HOD"
  }
]

export const CalenderColors = {
  MEETING: '#096192',
  TODO: '#1399C6',
  EVENT: '#1171BA',
  MEASURE: '#00BFFF',
  ACTIONS: '#4B9CD3 ',

};
export const NewColorTheme = {
  primaryColor: "#004CA4",
  primmaryLightColor: "#76C0F5",
  primaryDarkColor: "#012F64",
  parimaryTitle: "#00002F",

  dbCardHeader: "#D9D9D9"
}

export const MainColors = {
  DASHBOARDBACK: NewColorTheme.primaryColor,
  PRIMARY: "#0078D4",
  TAGACTIVE: "#54B435",
  TAGPENDING: "#ff6823",
  LIGHTBACKCOLOR: "#f5f9ff",
  DARKBACKCOLOR: "#b4c6e1"
}

export const status = [
  {
    value: 0,
    label: "PENDING"
  },
  {
    value: 1,
    label: "PUBLISHED"
  },
  {
    value: 2,
    label: "ASSIGNED"
  },
  {
    value: 999,
    label: "REJECTED"
  }
]
export const ActionStatus = [
  {
    value: 0,
    label: "To Do"
  },
  {
    value: 1,
    label: "In Progress"
  },
  {
    value: 2,
    label: "Completed"
  },
  {
    value: 3,
    label: "Delayed"
  },
  {
    value: 4,
    label: "Missed"
  }
]

