import React, { useEffect, useState } from 'react'
import Donut from '../../Country/Donut';
import { Badge, Col, Collapse, Modal, Row, Space, Tabs, Tag, Typography } from 'antd';
import DonutForPopup from './DonutForPopup';
import {

    BankOutlined,
} from "@ant-design/icons";
import { getAgencyWiseDashboard, getCountryWiseDashboard } from '../../../../api';

const { Panel } = Collapse;
function SummaryDonut({ val }) {


    const [viewDialog, setViewDialog] = useState(false);
    const [tab, setTab] = useState();
    const [activeKey, setActiveKey] = useState(1);
    const [donutPreviewData, setPreviewData] = useState();
    const [donutData, setOpenModalData] = useState();
    const [allAgencies, setAllAgencies] = useState()
    const [dsStatus, setDSStatus] = useState("");
    const [dsActive, setDSActive] = useState(false);
    const [reports, setReports] = useState(null);

    useEffect(() => {
        getAgencyWiseDashboard(val, {}).then((out) => {
            setReports(out.data.data);
        });
    }, [val])



    const handleCancel = () => {
        console.log("tab", tab)
        setActiveKey(1)
        setViewDialog(false);

    };

    const setData = (params) => {

        setActiveKey(1)

        setOpenModalData(params)
        let filteredReport = reports
            .filter((element) => element.report_name === "Agency wise Summary")[0]
            .data.find((obj) => obj.status.replace(/\s/g, '').toLowerCase() === params.name.replace(/\s/g, '').toLowerCase());
        console.log(params, reports)

        const uniqueAgencies = filteredReport ? filteredReport.data.reduce((unique, obj) => {
            if (!unique.some(item => item.key === obj.agency_id)) {
                unique.push({ label: obj.agency_name, key: obj.agency_id, agency_name: obj.agency_name });
            }
            return unique;
        }, []) : [];

        function replaceAgencyName(name) {
            switch (name) {
                case "Sri Lanka Customs":
                    return "Custom";
                case "Department of Commerce":
                    return "DoC";
                case "Sri Lanka Cargo":
                    return "Cargo";
                default:
                    // Abbreviate the name to initials (first letters of each word)
                    return name
                        .split(" ")
                        .map(word => {
                            const trimmedWord = word.trim(); // Remove leading and trailing whitespace
                            const sanitizedWord = trimmedWord.replace(/[^a-zA-Z]/g, ""); // Remove non-alphabetic characters
                            const lowerWord = sanitizedWord.toLowerCase();
                            return lowerWord !== "and" && lowerWord !== "of" && sanitizedWord !== "&" && sanitizedWord.length > 0
                                ? sanitizedWord[0].toUpperCase()
                                : "";
                        })
                        .join("");
            }
        }
        const updatedAgencies = uniqueAgencies.map(agency => ({
            ...agency,
            label: replaceAgencyName(agency.label)
            // label: agency.label
        }));

        if (updatedAgencies.length > 0) {
            setTab(updatedAgencies[0].key)
            setAllAgencies(updatedAgencies)

            const result = [];
            const agencyMap = new Map();
            filteredReport.data.forEach(obj => {
                const { agency_name, agency_id, article_id, measure_id, action_id, article, measure, action, action_no, article_no, measure_no } = obj;

                if (!agencyMap.has(agency_id)) {
                    agencyMap.set(agency_id, { agency_id, articles: [], name: agency_name });
                    result.push(agencyMap.get(agency_id));
                }
                const agency = agencyMap.get(agency_id);

                const { articles } = agency;

                let articleObj = articles.find(a => a.article_id === article_id);
                if (!articleObj) {
                    articleObj = { article_id, measures: [], article };
                    articles.push(articleObj);
                }
                const { measures } = articleObj;

                let measureObj = measures.find(m => m.measure_id === measure_id);
                if (!measureObj) {

                    measureObj = { measure_id, actions: [], measure: measure };
                    measures.push(measureObj);
                }

                const { actions } = measureObj;

                if (!actions.some(a => a.action_id === action_id)) {
                    actions.push({ action_id, action, action_no, measure_no, article_no });

                }

            });

            console.log("result------------>>", result)


            result.forEach((item) => {
                let actionCount = 0;
                item.articles.forEach((article) => {
                    article.measures.forEach((measure) => {
                        actionCount += measure.actions.length;
                    });
                });

                item.actionCount = actionCount;
            });

            // Calculate the total actionCount
            let totalActionCount = 0;

            result.forEach((item) => {
                totalActionCount += item.actionCount;
            });

            // Add the actionPercentage key-value pair to each object
            result.forEach((item) => {
                const actionPercentage = ((item.actionCount / totalActionCount) * 100)?.toFixed(2);
                item.value = parseFloat(actionPercentage);
            });

            console.log("result))))___>>", result)
            setPreviewData(result);

            setViewDialog(true);
        }


    }
    const opeDonutModal = (params) => {

        setData(params)

    };

    const onChange = (key) => {
        console.log(key)
        setTab(key)
    };
    const donutSectorClick = (params) => {
        setActive(true);
        setDSStatus(params.name);
    };

    const setActive = (torf) => {
        setDSActive(torf);
    };
    return (
        <>
            <Donut
                onClickDonut={opeDonutModal}
                report={
                    reports && reports.length > 0
                        ? reports.filter(
                            (element) =>
                                element.report_name === "Agency wise Summary"
                        )[0]
                        : null
                }
                donutSectorClick={donutSectorClick}
            />
            <Modal
                closable={true}
                centered
                className="custom-modal"
                width={'80%'}
                footer={false}
                onCancel={handleCancel}
                title={
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                        }}
                    >
                        <div>
                            {" "}
                            <Typography className="custom-modal-title" level={2}>
                                {donutData ? donutData.name + " Actions Overview" : ""}
                            </Typography>
                        </div>
                    </div>
                }
                open={viewDialog}
            >

                <div className="custom-modal-body custom-scroll-div" style={{ height: "85vh", overflow: "auto" }}>
                    <Row gutter={[16, 16]}>

                        <Col xs={24} md={val == 0 ? 18 : 24}>
                            {val == 0 &&
                                <Tabs activeKey={tab} items={allAgencies} onChange={onChange} />
                            }

                            <div style={{ padding: "0px ", margin: "10px 0px", display: "flex", gap: 10, alignContent: "center" }}>
                                <BankOutlined style={{ fontSize: 25 }} /><Typography style={{ fontWeight: 600, fontSize: 20 }}>
                                    {allAgencies?.filter(el => el.key == tab)[0].agency_name}
                                </Typography>
                            </div>
                            <Space>
                                <Tag style={{ width: "85px", margin: "5px 0px " }} color="#1520A6">{"Articles : " + donutPreviewData?.filter(el => el.agency_id == tab)[0].articles.length}</Tag>
                                <Tag style={{ width: "85px", margin: "5px 0px " }} color="#108ee9">{"Actions : " + donutPreviewData?.filter(el => el.agency_id == tab)[0].actionCount}</Tag>

                                {/* <Tag style={{ width: "125px", margin: "5px 0px " }} color="#2db7f5">{"Contribution : " + donutPreviewData?.filter(el => el.agency_id == tab)[0].value + "%"}</Tag> */}

                            </Space>
                            <div
                                className="custom-scroll-div"
                                style={{
                                    height: "48vh",
                                    overflow: "auto",
                                    paddingRight: 16,
                                    minWidth: 300,
                                    marginTop: 15
                                }}
                            >

                                <Collapse size="small" defaultActiveKey={["0"]} >
                                    {donutPreviewData?.filter(el => el.agency_id == tab)[0].articles.sort((a, b) => parseInt(a.article.split('.')[0]) - parseInt(b.article.split('.')[0])).map((element, index) => {
                                        return (
                                            <Panel
                                                header={

                                                    element.article

                                                }
                                                key={index}
                                            >

                                                <Tag style={{ width: "62px", margin: "5px 0px " }} color="#1520A6">Measures</Tag>



                                                <Collapse size="small" defaultActiveKey={["0"]} >
                                                    {
                                                        element.measures?.map(
                                                            (measure, key) => {
                                                                return <Panel
                                                                    header={

                                                                        measure.measure

                                                                    }
                                                                    key={key}
                                                                >
                                                                    <Tag style={{ width: "62px", margin: "5px 0px " }} color="#1520A6">Actions</Tag>
                                                                    <div style={{ display: "flex", flexDirection: "column" }}>
                                                                        <Row gutter={[16, 16]}>


                                                                            {measure.actions.map((action, actkey) => {

                                                                                return <Col xs={24}>
                                                                                    <Row gutter={[1, 1]}>

                                                                                        <Col xs={1}>
                                                                                            <Badge color="blue" status="default" />
                                                                                        </Col>
                                                                                        <Col xs={23}>

                                                                                            <span className='popup-action-title' key={actkey} >

                                                                                                {action.article_no + "." + action.measure_no + "." + action.action_no + ". " + action.action}
                                                                                            </span>
                                                                                        </Col>
                                                                                    </Row>
                                                                                </Col>
                                                                            })}
                                                                        </Row>
                                                                    </div>


                                                                </Panel>
                                                            }
                                                        )
                                                    }
                                                </Collapse>
                                                {/* <div style={{ display: "flex", flexDirection: "column", width: "100%", }}>
                     

                      <Tag style={{ width: "62px" }} color="#1520A6">Article</Tag>
                      <p style={{ marginTop: 5, fontWeight: 700, fontSize: 13 }}>{element.article}</p>

                      <Tag style={{ marginTop: 8, width: "62px" }} color="#2f69b3">Measure</Tag>
                      <p style={{ marginTop: 5, fontWeight: 500, fontSize: 13 }}>{element.measure}</p>

                      <Tag style={{ marginTop: 8, width: "62px" }} color="#0492C2">Action</Tag>

                      <p >{element.article_no +
                        "." +
                        element.measure_no +
                        "." + " " +
                        element.action_no + " " + element.action}</p>
                      <p style={{ marginTop: 5 }}>Delivery  Date : {dayjs(element.delivery_date).format("DD/MM/YYYY")}</p>

                    </div> */}

                                            </Panel>
                                        );
                                    })}
                                </Collapse>
                                {/* {donutPreviewData?.data.map((element, index) => (
                                            <div>
                                                <div className={index == 0 ? "lines" : "sub_lines"}></div>
                                                <div className="measure_div">
                                                    <div className="measure_no">
                                                        {"Action" + " " + element.article_no + "." + element.measure_no + "." + element.action_no}
                                                    </div>
                                                    <div className="measure_name">
                                                        {element.action}
                                                    </div>
                                                    {/* <div className="status_c">
                                                        Completed
                                                    </div> 
                                                </div>
                                                <div className="measure_sub_div">
                                                    <div className="date_lbl">
                                                        Definitive Date
                                                    </div>
                                                    <div className="date">
                                                        2023/02/02
                                                    </div>
                                                </div>
                                                <div className="measure_sub_div">
                                                    <div className="date_lbl">
                                                        Completed Date
                                                    </div>
                                                    <div className="date">
                                                        2023/02/02
                                                    </div>
                                                </div>
                                            </div>
                                        ))} */}
                            </div>
                        </Col>
                        {val == 0 &&
                            <Col xs={24} md={6}>
                                <DonutForPopup data={donutPreviewData?.filter(el => el.agency_id == tab)[0]} tab={tab} />
                            </Col>
                        }


                    </Row>

                </div >
            </Modal >
        </>
    );

}

export default SummaryDonut