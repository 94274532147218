import React from 'react';
import ReactECharts from 'echarts-for-react';

const Counts = () => {
  const options = {
    yAxis: {
      type: 'category',
      data: ['6-12','12-24','24-36','36']
    },
    xAxis: {
      type: 'value'
    },
    color: [
      '#ff8282'
    ],
    textStyle: {
      fontFamily: 'Figtree'
    },
    series: [
      {
        data: [4, 2, 8, 1],
        type: 'bar',
        showBackground: true,
        backgroundStyle: {
          color: 'rgba(180, 180, 180, 0.2)'
        }
      }
    ]
  };

  return <ReactECharts option={options} />;
};

export default Counts;