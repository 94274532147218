import { Avatar, Divider, List, Popover, Typography } from "antd";
import { useEffect, useState } from "react";
import { getChats, getGroupChats } from "../../api";
import { NewColorTheme } from "../../appConst";
const { Text } = Typography;

const ChatList = ({ setSelectedChat, chats, setChats, groupChats, setGroupChats }) => {
    return <div >
        <div style={{ marginLeft: 0, height: "220px", overflow: "auto", marginTop: 10 }} className="custom-scroll-div">
            {console.log("Chats", chats)}
            <List
                dataSource={chats.sort((a, b) => new Date(b.created) - new Date(a.created))}
                style={{ paddingRight: 4, }}
                renderItem={(item, index) => (

                    <List.Item
                        onClick={() => {
                            const chats_temp = chats;
                            chats_temp.forEach((cht) => cht.selected = false);
                            chats_temp[index].selected = true;
                            setSelectedChat(chats_temp[index]);
                            setChats([...chats_temp]);

                            const chats_temp_g = groupChats;
                            chats_temp_g.forEach((cht) => cht.selected = false);
                            setGroupChats([...chats_temp_g]);
                        }}
                        key={item.id}
                        style={item.selected ? { background: NewColorTheme.primaryColor, borderRadius: 8 } : { background: "#d6eeff", borderRadius: 8 }}
                        className="chat-list-item"
                    >
                        <List.Item.Meta
                            style={{ alignItems: 'center', padding: "5px 15px", cursor: "pointer" }}
                            avatar={
                                <div >
                                    <Avatar style={{
                                        backgroundColor: 'white',
                                        color: NewColorTheme.primaryColor,
                                        fontWeight: 600
                                    }} size={36}>{item.chat_name.charAt(0)}</Avatar>
                                </div>
                            }
                            title={<Text strong style={item.selected ? { color: 'white' } : { color: NewColorTheme.primaryColor }}>{item.chat_name + (item.designation ? (" - " + item.designation) : "")}</Text>}
                            description={<Typography.Paragraph style={item.selected ? { color: 'white', fontSize: 11 } : { color: NewColorTheme.primaryColor, fontSize: 11 }} > {item.agency_name} </Typography.Paragraph>}
                        />
                        {item.unread != 0 &&
                            <div style={{ paddingRight: 20 }}><Avatar
                                style={{
                                    backgroundColor: 'white',
                                    color: NewColorTheme.primaryColor,
                                }}>{item.unread} </Avatar></div>
                        }

                    </List.Item>

                )}
            />
        </div>
        <Divider orientation="left" orientationMargin="0">
            Groups
        </Divider>
        {/* <Divider /> */}

        <div style={{ marginLeft: 0, height: "160px", overflow: "auto" }} className="custom-scroll-div">

            <List
                style={{ paddingRight: 6 }}
                dataSource={groupChats}
                renderItem={(item, index) => (
                    <Popover content={<div style={{ display: 'flex', flexDirection: "column" }}>
                        {
                            item.members?.map((element) => {
                                return <p>{element.user}</p>
                            })
                        }

                    </div>} title="Group Members">
                        <List.Item
                            onClick={() => {
                                const chats_temp_g = groupChats;
                                chats_temp_g.forEach((cht) => cht.selected = false);
                                chats_temp_g[index].selected = true;
                                setSelectedChat(chats_temp_g[index]);
                                setGroupChats([...chats_temp_g]);

                                const chats_temp = chats;
                                chats_temp.forEach((cht) => cht.selected = false);
                                setChats([...chats_temp]);
                            }}
                            key={item.id}
                            style={item.selected ? { background: NewColorTheme.primaryColor, borderRadius: 8 } : { background: "#d5edfe", borderRadius: 8 }}
                        >
                            <List.Item.Meta
                                style={{ alignItems: 'center', padding: "5px 15px", cursor: "pointer" }}
                                avatar={
                                    <div >
                                        <Avatar
                                            style={{
                                                backgroundColor: 'white',
                                                color: NewColorTheme.primaryColor,
                                                fontWeight: 700
                                            }}

                                            size={36}>{item.chat_name.charAt(0)}</Avatar>
                                    </div>
                                }
                                title={<Text strong style={item.selected ? { color: 'white' } : { color: NewColorTheme.primaryColor }}>{item.chat_name}</Text>}
                            />
                            {item.unread != 0 &&
                                <div style={{ paddingRight: 20 }}><Avatar
                                    style={{
                                        backgroundColor: 'white',
                                        color: NewColorTheme.primaryColor,
                                    }}>{item.unread} </Avatar></div>
                            }
                            {/* <div style={{ paddingRight: 20 }}><Avatar
                            style={{
                                backgroundColor: 'white',
                                color: NewColorTheme.primaryColor,
                            }}>{item.unread + "626262"} </Avatar></div> */}
                        </List.Item>
                    </Popover>

                )}
            />
        </div>
    </div>
}

export default ChatList;