import { Card, Col, Row, Skeleton, Tag, Typography } from 'antd'
import React from 'react'
import { BuildOutlined, RocketOutlined, BlockOutlined, AppstoreOutlined } from '@ant-design/icons';
import { BiCameraMovie } from "react-icons/bi";
import { useState } from 'react';
import { useEffect } from 'react';
import { CalenderColors, NewColorTheme } from '../../../../appConst';
import dayjs from 'dayjs';
import CounterCard from './CounterCard';
import '../NewCalender.css'
import { GetAgencyWiseAllArticles, getAgencies, getMeasures, getSchedules } from '../../../../api';

function SideBar() {


    const [upcomingEvents, setUpcomingEvents] = useState()
    const [eventCount, setEventCount] = useState(0)
    const [actionCount, setActionCount] = useState(0)
    const [loaded, setLoad] = useState(false)
    const [mainData, setData] = useState()
    const [sDate, setStDate] = useState()
    const [lDate, setLdDate] = useState()
    const [loading, setLoading] = useState(true)


    const [todoList, setTodoList] = useState()

    // --------------------


    useEffect(() => {

        LoadData()


    }, [])




    const LoadData = async function () {

        setLoad(false)

        const currentDate = dayjs();
        let firstDateOfMonth
        let lastDateOfMonth

        firstDateOfMonth = currentDate.startOf('month').format('DD/MM/YYYY');
        lastDateOfMonth = currentDate.endOf('month').format('DD/MM/YYYY');
        setStDate(firstDateOfMonth)
        setLdDate(lastDateOfMonth)

        // Get the current date

        if (await window.localStorage.getItem('ntfc_level') == 7 || await window.localStorage.getItem('ntfc_level') == 6) {
            getMeasures().then((m) => {

                let newMeasures = m.data.out.flatMap((element) => {

                    const measureObj = {
                        extendedProps: {

                            type: "measure",
                            measure: element.label,
                            article: element.article,
                            measure_def_date: element.extended_def_date ? dayjs(element.extended_def_date).format("DD/MM/YYYY") : dayjs(element.def_date).format("DD/MM/YYYY")
                        },

                        title: "Due Measure - " + element.label,
                        start: element.extended_def_date ? dayjs(element.extended_def_date).format("YYYY-MM-DD") : dayjs(element.def_date).format("YYYY-MM-DD"),
                        backgroundColor: CalenderColors.MEASURE,
                        borderColor: "white",
                    };

                    return [measureObj]
                })
                const filteredData = newMeasures.filter(item => {
                    const measureDate = item.extendedProps.measure_def_date;

                    return measureDate >= firstDateOfMonth && measureDate <= lastDateOfMonth
                });

                getEvents(newMeasures, firstDateOfMonth, lastDateOfMonth)

            });
        } else {
            AgencyActions(localStorage.ntfc_agency, firstDateOfMonth, lastDateOfMonth)
        }
        return;
    }


    const AgencyActions = async (agency, stdate, edDate) => {


        // Get the current date

        const out = await GetAgencyWiseAllArticles(agency)

        if (out.status === 200) {

            // Restructure the array that can match to the events for the calender
            const newArray = out.data.out.flatMap(item => {
                const { article_no, article, measures } = item;
                return measures.flatMap(({ measure_id, measure_name, article_no, measure_no, measure_summary, measure_def_date, actions }) => {
                    const measureObj = {
                        extendedProps: {
                            id: measure_id,
                            type: "measure",
                            measure: measure_no + ". " + measure_name,
                            article: article_no + ". " + article,
                            measure_def_date: dayjs(measure_def_date).format("DD/MM/YYYY")
                        },

                        title: "Due Measure - " + article_no + "." + measure_no + " " + measure_summary,
                        start: new Date(dayjs(measure_def_date).format("YYYY-MM-DD")).toISOString(),
                        backgroundColor: CalenderColors.MEASURE,
                        borderColor: "white",
                        allDay: true
                    };
                    const actionObjs = actions.map(({ action_id, action_status, action, participants, action_no, action_def_date, action_summary }) => (
                        {
                            extendedProps: {
                                id: action_id,
                                type: "action",
                                action: action_no + ". " + action,
                                participants: participants,
                                status: action_status,
                                article: article_no + ". " + article,
                                measure: measure_no + ". " + measure_name,
                                measure_def_date: dayjs(measure_def_date).format("DD/MM/YYYY"),
                                action_def_date: dayjs(action_def_date).format("DD/MM/YYYY")
                            },

                            title: "Due Action - " + article_no + "." + measure_no + "." + action_no + " " + action_summary,
                            start: new Date(dayjs(action_def_date).format("YYYY-MM-DD")).toISOString(),
                            backgroundColor: CalenderColors.ACTIONS,
                            borderColor: "white",
                            allDay: true
                        }
                    ));
                    return [measureObj, ...actionObjs];
                });
            });

            const filteredData = newArray.filter(item => {
                const measureDate = item.extendedProps.measure_def_date
                return measureDate >= stdate && measureDate <= edDate;
            });

            getEvents(newArray, stdate, edDate)

        } else {
            console.log("data loading falid")
        }

    }
    const getEvents = async function (actionTasks, stdate, edDate) {
        console.log("stdate", stdate, edDate)
        const currentDate = dayjs();
        let dateRange = {
            "from_date": stdate,
            "to_date": edDate
        }

        const out = await getSchedules(dateRange)


        const newArr = out.data.data.flatMap((element) => {
            const startDate = dayjs(element.from_date).format("YYYY-MM-DD")
            const endDate = dayjs(element.to_date).format("YYYY-MM-DD")
            const startTime = dayjs((element.from_time == 0 ? "0000" : element.from_time.toString().padStart(4, '0').toString()), 'HHmm').format('HH:mm')
            const endTime = dayjs((element.to_time == 0 ? "0000" : element.to_time.toString().padStart(4, '0').toString()), 'HHmm').format('HH:mm')
            const startDateISO = new Date(`${startDate}T${startTime}:00`).toISOString();
            const endDateISO = new Date(`${endDate}T${endTime}:00`).toISOString();


            const eventObj = {
                extendedProps: {
                    id: element.id,
                    type: getType(element.type),
                    participants: element.participants ? element.participants : [],
                    description: element.description,
                    url: element.url,
                    from_time: dayjs((element.from_time == 0 ? "0000" : element.from_time.toString().padStart(4, '0').toString()), 'HHmm').format('HH:mm'),
                    to_time: dayjs((element.to_time == 0 ? "0000" : element.to_time.toString().padStart(4, '0').toString()), 'HHmm').format('HH:mm'),
                    to_date: dayjs(element.to_date).format("YYYY-MM-DD"),
                    from_date: dayjs(element.from_date).format("YYYY-MM-DD"),
                    comments: element.comments ? element.comments : [],
                    created_by: element.created_by ? element.created_by : "",
                    backgroundColor: getColor(element.type),
                    borderColor: "white",
                },
                end: endDateISO,
                title: element.title,
                start: startDateISO,
                backgroundColor: getColor(element.type),
                borderColor: "white",
                allDay: false

            }

            return eventObj;
        })

        let AllEvents = [...actionTasks, ...newArr]
        console.log("All events", actionTasks)
        const currentDate2 = new Date();
        const thirtyDaysLater = new Date();
        thirtyDaysLater.setDate(currentDate2.getDate() + 30);

        const filteredArray = AllEvents.filter(item => {
            const startDate = new Date(item.start);
            return startDate > currentDate && startDate <= thirtyDaysLater;
        });
        let netSortedArr = filteredArray.sort((a, b) => {
            const dateA = new Date(a.start);
            const dateB = new Date(b.start);
            return dateA - dateB;
        })
        console.log('netSortedArr', netSortedArr)
        setTodoList(AllEvents)
        let incoming = netSortedArr
        let incoming2 = netSortedArr
        setUpcomingEvents(incoming?.filter((element) => element.extendedProps.type == "Meeting" || element.extendedProps.type == "Event" || element.extendedProps.type == "To Do"))
        let acCount = incoming?.filter((element) => (element.extendedProps.type == "measure" || element.extendedProps.type == "action")).length
        let eveCount = incoming2?.filter((element) => element.extendedProps.type == "Meeting" || element.extendedProps.type == "Event" || element.extendedProps.type == "To Do").length

        setActionCount(acCount)
        setEventCount(eveCount)
        setData(netSortedArr)
        setLoad(true)



    }
    const getType = (type) => {


        if (type == 0) {
            return "Meeting"
        } else if (type == 1) {
            return "Event"
        } else if (type == 2) {
            return "To Do"
        }

    }
    const getColor = (type) => {
        if (type === 0) {
            return CalenderColors.MEETING

        } else if (type === 1) {
            return CalenderColors.EVENT

        } else if (type === 2) {

            return CalenderColors.TODO
        }

    }
    // --------------------

    // useEffect(() => {
    //     setLoad(false)
    //     loadData()


    // }, [todoList])

    // const loadData = () => {
    //     let incoming = mainData
    //     let incoming2 = mainData
    //     setUpcomingEvents(incoming?.filter((element) => element.extendedProps.type == "Meeting" || element.extendedProps.type == "Event" || element.extendedProps.type == "To Do"))
    //     let acCount = incoming?.filter((element) => (element.extendedProps.type == "measure" || element.extendedProps.type == "action")).length
    //     let eveCount = incoming2?.filter((element) => element.extendedProps.type == "Meeting" || element.extendedProps.type == "Event" || element.extendedProps.type == "To Do").length

    //     setActionCount(acCount)
    //     setEventCount(eveCount)

    //     setData(todoList)
    //     setLoad(true)

    // }


    const getAgencyList = (type) => {
        if (type === "event") {
            setUpcomingEvents(mainData.filter((element) => element.extendedProps.type == "Meeting" || element.extendedProps.type == "Event" || element.extendedProps.type == "To Do"))
            console.log("todolist", todoList)
        } else {
            setUpcomingEvents(mainData.filter((element) => element.extendedProps.type == "measure" || element.extendedProps.type == "action"))

        }
    }

    const getGradient = (type) => {
        if (type == "Meeting") {
            return `linear-gradient( to right, ${CalenderColors.MEETING}  100%, #ABDCFF 100%)`
        } else if (type == "Event") {
            return `linear-gradient( to right, ${CalenderColors.EVENT}  100%, #F6CEEC 100%)`
        } else if (type == "To Do") {
            return `linear-gradient( 135deg, ${CalenderColors.TODO} 100%, #FEB692 100%)`
        } else if (type == "measure") {
            return `linear-gradient( 135deg, ${CalenderColors.MEASURE}  100%, #81FBB8 100%)`
        } else if (type == "action") {
            return `linear-gradient( 135deg, ${CalenderColors.ACTIONS}  100%, #FDEB71 100%)`
        }
    }

    return (
        <Card style={{ padding: 10 }}>
            {(loaded) ?
                <Row gutter={[16, 16]}>
                    <Col xs={24}>
                        <Row gutter={8}>
                            <Col xs={12}>
                                <CounterCard onClick={() => getAgencyList("event")}
                                    title="Upcoming Events"
                                    backgroundColor={NewColorTheme.primaryColor}
                                    count={eventCount}

                                />
                            </Col>
                            <Col xs={12}>
                                <CounterCard onClick={() => getAgencyList("action")}
                                    title="Upcoming Tasks"
                                    backgroundColor={NewColorTheme.primaryColor}
                                    count={actionCount}

                                />

                            </Col>

                        </Row>
                    </Col>
                    {/* To Do List */}
                    <Col xs={24}>

                        <div className='custom-scroll-div' style={{ width: "100%", height: "80vh", overflow: "auto", padding: 7 }} >
                            {upcomingEvents?.map((element, index) => {

                                return <Card
                                    key={index}
                                    style={{
                                        height: "max-content",
                                        width: "100%",
                                        display: "flex",
                                        backgroundImage: getGradient(element.extendedProps.type),
                                        // background: getColor(element.extendedProps.type),
                                        // padding: "10px",
                                        margin: "5px 0",


                                    }}
                                >
                                    <Row gutter={16}>
                                        <Col xs={5}
                                            style={{
                                                display: "flex",
                                                width: "100%",
                                                // height: "100%",
                                                alignItems: "center",
                                                justifyContent: "center"
                                            }}
                                        >
                                            {
                                                element.extendedProps.type == "action" ?
                                                    <RocketOutlined style={{ fontSize: 21, color: "white" }} /> :
                                                    element.extendedProps.type == "measure" ?
                                                        <BlockOutlined style={{ fontSize: 21, color: "white" }} /> :
                                                        element.extendedProps.type == "Meeting" ?
                                                            <BiCameraMovie style={{ fontSize: 21, color: "white" }} /> :
                                                            element.extendedProps.type == "Event" ?
                                                                <AppstoreOutlined style={{ fontSize: 21, color: "white" }} /> :
                                                                <BuildOutlined style={{ fontSize: 21, color: "white" }} />
                                            }

                                        </Col>
                                        <Col xs={19}>

                                            <div
                                                style={{
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    gap: 1

                                                    // width: "100%"
                                                }}
                                            >
                                                <Tag color='yellow' style={{ width: "max-content" }}>{element.extendedProps.type}</Tag>

                                                <Typography
                                                    style={{
                                                        fontFamily: "Figtree",
                                                        fontSize: 14,
                                                        fontWeight: 500,
                                                        color: "white"
                                                    }}>{element.title}</Typography>
                                                <Typography
                                                    style={{
                                                        fontFamily: "Figtree",
                                                        fontSize: 12,
                                                        fontWeight: 500,
                                                        color: "white"
                                                    }}
                                                >{"Due on - " + (element.start ? dayjs(element.start).format("DD/MM/YYYY") : "-")}</Typography>

                                            </div>

                                        </Col>

                                    </Row>

                                </Card>
                            })}

                        </div>

                    </Col>

                </Row> :

                <Skeleton
                    active
                    avatar
                    paragraph={{
                        rows: 4,
                    }}
                />}


        </Card>
    )
}

export default SideBar