import { Button, Col, ConfigProvider, Form, Input, Row, Select, Typography } from 'antd';
import { UsergroupAddOutlined } from "@ant-design/icons";
import { createChat, getAgencies, getUsersByAgencies } from '../../api';
import { useEffect, useState } from 'react';
import { NewColorTheme } from '../../appConst';
const { Text } = Typography;

const GroupChat = ({ closeModal }) => {
    const [participants, setParticipants] = useState([]);
    const [selectedAgencies, setSelectedAgencies] = useState([]);
    const [agencies, setAgencies] = useState([]);
    const [currentUsers, setUsers] = useState([]);
    const [chatName, setChatName] = useState("");
    const [form] = Form.useForm();

    const getParticipants = async (agencies) => {
        let body = {
            agencies: agencies
        }
        await getUsersByAgencies(body).then(out => {
            setUsers(out.data.out.map((obj, index) => ({ ...obj, label: obj.designation + " - " + obj.label })));
        });
    }

    useEffect(() => {
        form.resetFields()
        getAgencies().then((out) => {

            console.log("Agencies", out.data.out);
            if (out) {
                out.data.out.unshift({ label: "All Agencies", value: 0 })

                const newOption = { label: "NTFC Secretrait User", value: 9999 };
                const updatedOptions = [newOption].concat(out.data.out);
                // options.unshift({ label: "All Agencies", value: 0 });
                setAgencies(updatedOptions);

                // setAgencies(out.data.out)
            }
        });
    }, []);

    useEffect(() => {
        const participantsTemp = participants;
        const pt = participantsTemp.filter((p) => {
            let ret = false;
            selectedAgencies.forEach((ag) => {
                console.log(ag.toString(), p.agency_id.toString(), ag.toString() === p.agency_id.toString());
                if (ag.toString() === p.agency_id.toString()) {
                    ret = true;
                }
            })
            return ret;
        });
        setParticipants(pt);
    }, [selectedAgencies]);


    useEffect(() => {
        form.setFieldValue("participants", participants);
    }, [participants])

    useEffect(() => {
        form.resetFields()
        setUsers([])
        setSelectedAgencies([])
        form.resetFields(['participants'])
    }, [closeModal])

    const selectAgency = async (val) => {
        let agency_list = [];
        if (val.length == 0) {
            agency_list = agencies.map((obj, index) => ({ ...obj, disabled: false }));
            setAgencies(agency_list);
            setUsers([])
            setSelectedAgencies([])
            form.resetFields(['participants'])
        } else if (val[0] == 0) {
            agency_list = agencies.map((obj, index) => index === 0 ? obj : { ...obj, disabled: true });
            setSelectedAgencies(val)
            setAgencies(agency_list);
            getParticipants(val)
        } else {
            agency_list = agencies.map((obj, index) => index === 0 ? { ...obj, disabled: true } : { ...obj, disabled: false });
            form.resetFields(['participants'])
            setSelectedAgencies(val)
            setAgencies(agency_list);
            getParticipants(val)
        }
    }

    const createNewChat = () => {
        console.log(participants);
        createChat({
            group: 1,
            participants: participants.map((p) => p.value),
            chat_name: chatName
        }).then((out) => {
            if (out) {
                closeModal();
                console.log("NEW CHAT", out);
                form.resetFields()
                setUsers([])
                setParticipants([])
                setSelectedAgencies([])
            }
        }).catch((err) => {
            console.log("CCHAT ERROR", err);
        })
    }

    return <Col xs={24}>
        <div>

            <Form
                onFinish={createNewChat}
                form={form}
                layout="vertical">
                <Row gutter={16} >
                    <Col span={24}>
                        <Form.Item
                            label="Select Agency"
                            name="agency"
                            required={true}
                            rules={[{
                                required: true,
                                message: <p style={{ marginLeft: "12px" }}>Agency is required</p>,
                            }]}
                        >
                            <Select
                                value={selectedAgencies}
                                // maxTagCount='responsive'
                                mode='multiple'
                                placeholder="Select An Agency"
                                allowClear
                                style={{ width: "100%" }}
                                options={agencies.sort((a, b) => a.label.localeCompare(b.label))}
                                onChange={selectAgency}
                                filterOption={(inputValue, option) =>
                                    option.label.toLowerCase().indexOf(inputValue.toLowerCase()) !== -1
                                }
                            />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item
                            label="Select Participants"
                            name="participants"
                            required={true}
                            rules={[{
                                required: true,
                                message: <p style={{ marginLeft: "12px" }}>Participants are required</p>,
                            }]}
                        >
                            <Select
                                showSearch
                                mode='multiple'
                                placeholder="Select a person"
                                optionFilterProp="children"
                                onChange={(val, opts) => setParticipants(opts)}
                                style={{ width: "100%" }}
                                value={participants}
                                filterOption={(input, option) =>
                                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                }
                                options={currentUsers}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item
                            label="Group Name"
                            name="name"
                            required={true}
                            rules={[{
                                required: true,
                                message: <p style={{ marginLeft: "12px" }}>Chat Name is required</p>,
                            }]}
                        >
                            <Input placeholder='Group Name' value={chatName} onChange={(e) => setChatName(e.target.value)} />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <div style={{ display: "flex", justifyContent: "end", alignItems: "center" }}>
                            <ConfigProvider
                                theme={{
                                    token: {
                                        colorPrimary: NewColorTheme.primaryColor,
                                    },
                                }}
                            >
                                <Button size="large" type='primary' style={{ width: '100%' }} htmlType='submit'
                                // onClick={createNewChat}
                                >Create Group</Button>
                            </ConfigProvider>
                        </div>
                    </Col>
                </Row>

            </Form>
        </div>
    </Col>
}

export default GroupChat;