
import React, { useEffect, useState } from 'react'

import './measure.css'
import { Col, Row, Typography } from 'antd';
import CountUp from 'react-countup';


function MeasureChart({ data, count }) {
    const [shouldAnimate, setShouldAnimate] = useState(false);

    console.log("Mesauressssssssssss----->", data)

    useEffect(() => {
        setShouldAnimate(true);

    }, [count])


    function getColor(st) {
        if (parseInt(st) > 0 && parseInt(st) <= 25) {
            return "magenta"
        } else if (parseInt(st) >= 25 && parseInt(st) <= 50) {
            return "orange"
        } else if (parseInt(st) > 75 && parseInt(st) <= 100) {
            return "green"
        } else if (parseInt(st) > 50 && parseInt(st) <= 75) {
            return "yellow"
        } else if (parseInt(st) === 0) {
            return "red"
        }
    }

    return (<div >
        <div>
            <Typography className='stat-title'>Measures</Typography>
            <Typography className='stat-count'>
                {shouldAnimate && <CountUp end={count && count ? count : 0} duration={2} />}

            </Typography>

        </div>
        <div className="measure-grid">
            <Row gutter={[4, 4]} >
                {data?.map((e, i) => {
                    return <Col key={i} xs={4}>
                        <div className='measure-tile' style={{ backgroundColor: getColor(e.percentage) }}>
                            <Typography className='measure-tile-lable'>{e.no}</Typography>

                        </div>

                    </Col>
                })}



            </Row>

        </div>



    </div>
    )
}

export default MeasureChart


