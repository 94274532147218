import React from 'react';

function RowData({ number, content }) {
    return (
        <div className="row">
            <div className='row-no'>
                <span>{number}</span>
            </div>
            <div className='row-content'>
                <span>{content}</span>
            </div>
        </div>
    );
}

export default RowData;
