import { Divider, Typography, Table, Tag, Button, Tooltip, Space, message, Modal, Form, Input, ConfigProvider, Row, Col, Select } from 'antd';
import 'react-edit-text/dist/index.css';
import './requests.styles.css';
import { CloseOutlined, CheckOutlined, ArrowRightOutlined, ArrowLeftOutlined } from '@ant-design/icons';
import { useState, useEffect, Fragment, useRef } from 'react';
import { ApproveAgencyActionStatus, DiscardAgencyActionStatus, getActions, getAgencies, getAllPendingApprovals } from '../../api';
import moment from 'moment';
import FormLayouts from '../DonorsNew/Components/FormLayouts';
import { ToastContainer, toast, Zoom } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CustomeModal from '../../Components/Modal/CustomeModal';



function Page() {


    const [messageApi, contextHolder] = message.useMessage();
    const [data, setTblData] = useState([]);
    const [actions, setAllActions] = useState([]);
    const [pendingApproval, setPendingApproval] = useState([])
    const [addReason, SetReason] = useState(false)
    const [reasonAdding, SetReasonAdding] = useState(false)
    const [form] = Form.useForm();
    const [curretRecord, setCurrentRecord] = useState({})
    const [reason, setReasonText] = useState("")
    const [openMyModal, setMymodal] = useState()
    const [currentAction, setCurrentAction] = useState({})
    const [agency, setAgency] = useState(null);
    const [agencies, setAgencies] = useState([]);
    const [filteredData, setFilterData] = useState([]);
    const selectRef = useRef(null);

    useEffect(() => { getAgencies().then((out) => { setAgencies(out.data.out); }) }, []);

    useEffect(() => {
        loadData()

    }, []);

    const columns = [
        // {
        //     title: 'Action No',
        //     dataIndex: 'order',
        //     key: 'order',
        //     render: (_, { action_id }) => (
        //         getActionNo(action_id)

        //     ),
        // },action
        {
            title: 'Date',
            dataIndex: 'request_date',
            key: 'request_date',
            render: (date) => moment(date).format("DD/MM/YYYY"),

        },

        {
            title: 'Agency Name ',
            dataIndex: 'agency',
            key: 'agency',


        },

        {
            title: 'Status Changed By',
            dataIndex: 'requested_by_name',
            key: 'requested_by_name',

        },
        {
            title: 'Measure',
            dataIndex: 'measure_name',
            key: 'measure_name',

        },
        // {
        //     title: 'Action No',
        //     dataIndex: 'action_no',
        //     key: 'action_no',

        // },

        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            render: (_, { article_no, measure_no, action_no, action }) => (
                <div>
                    <div> <Tag color='#2db7f5'>{article_no + ". " + measure_no + ". " + action_no}</Tag></div>
                    <div>
                        {action}
                    </div>
                </div>

            ),

        },




        {
            title: 'Delivery Date',
            dataIndex: 'delivery_date',
            key: 'delivery_date',
            render: (date) => moment(date).format("DD/MM/YYYY"),
        },


        {
            title: 'Status Change',
            dataIndex: 'status',
            key: 'status',
            render: (_, { from_status, to_status }) => (
                getStatusChange(from_status, to_status)

            ),
        },


        {
            title: 'Action',
            key: 'action',
            render: (_, record) => (

                <Space size="middle">

                    <Tooltip title="Approve">
                        <Button type="primary" shape="circle" icon={<CheckOutlined />} size={'medium'} style={{ background: '#70D983' }}
                            onClick={() => approveActionStatus(record)}
                            disabled={window.localStorage.getItem('view_only') == 1}
                        />
                    </Tooltip>
                    <Tooltip title="Reject">
                        <Button type="primary" shape="circle" icon={<CloseOutlined />} size={'medium'} style={{ background: '#FF8282' }}
                            onClick={() => {
                                SetReason(true)
                                setCurrentRecord(record)
                            }}
                            disabled={window.localStorage.getItem('view_only') == 1}
                        />
                    </Tooltip>
                </Space>
            ),
        },
    ];
    const columnsHOD = [

        {
            title: 'Date',
            dataIndex: 'request_date',
            key: 'request_date',
            render: (date) => moment(date).format("DD/MM/YYYY"),

        },


        {
            title: 'Status Changed By',
            dataIndex: 'requested_by_name',
            key: 'requested_by_name',

        },
        {
            title: 'Measure',
            dataIndex: 'measure_name',
            key: 'measure_name',

        },


        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            render: (_, { article_no, measure_no, action_no, action }) => (
                <div>
                    <div> <Tag color='#2db7f5'>{article_no + ". " + measure_no + ". " + action_no}</Tag></div>
                    <div>
                        {action}
                    </div>
                </div>

            ),

        },




        {
            title: 'Delivery Date',
            dataIndex: 'delivery_date',
            key: 'delivery_date',
            render: (date) => moment(date).format("DD/MM/YYYY"),
        },


        {
            title: 'Status Change',
            dataIndex: 'status',
            key: 'status',
            render: (_, { from_status, to_status }) => (
                getStatusChange(from_status, to_status)

            ),
        },

        {
            title: 'Action',
            key: 'action',
            render: (_, record) => (

                <Space size="middle">

                    <Tooltip title="Approve">
                        <Button type="primary" shape="circle" icon={<CheckOutlined />} size={'medium'} style={{ background: '#70D983' }}
                            onClick={() => approveActionStatus(record)}
                            disabled={window.localStorage.getItem('view_only') == 1}
                        />
                    </Tooltip>
                    <Tooltip title="Reject">
                        <Button type="primary" shape="circle" icon={<CloseOutlined />} size={'medium'} style={{ background: '#FF8282' }}
                            onClick={() => {
                                SetReason(true)
                                setCurrentRecord(record)
                            }}
                            disabled={window.localStorage.getItem('view_only') == 1}
                        />
                    </Tooltip>
                </Space>
            ),
        },
    ];
    const columnsChair = [

        {
            title: 'Date',
            dataIndex: 'request_date',
            key: 'request_date',
            render: (date) => moment(date).format("DD/MM/YYYY"),

        },


        {
            title: 'Status Changed By',
            dataIndex: 'requested_by_name',
            key: 'requested_by_name',

        },
        {
            title: 'Measure',
            dataIndex: 'measure_name',
            key: 'measure_name',

        },


        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            render: (_, { article_no, measure_no, action_no, action }) => (
                <div>
                    <div> <Tag color='#2db7f5'>{article_no + ". " + measure_no + ". " + action_no}</Tag></div>
                    <div>
                        {action}
                    </div>
                </div>

            ),

        },




        {
            title: 'Delivery Date',
            dataIndex: 'delivery_date',
            key: 'delivery_date',
            render: (date) => moment(date).format("DD/MM/YYYY"),
        },


        {
            title: 'Status Change',
            dataIndex: 'status',
            key: 'status',
            render: (_, { from_status, to_status }) => (
                getStatusChange(from_status, to_status)

            ),
        },


    ];


    const getStatusChange = (from, to) => {

        const numStr = from?.toString();
        const numStr2 = to?.toString();
        const secondDigit = parseInt(numStr);
        const thirdDigit = parseInt(numStr2);

        return <div>

            <Tag color={getColor(secondDigit)} >
                {getStatus(secondDigit)}</Tag>
            <ArrowRightOutlined style={{ padding: "0 3px" }} />
            <Tag color={getColor(thirdDigit)} >
                {getStatus(thirdDigit)}
            </Tag>
        </div>

    }

    const getStatus = (digit) => {

        if (digit === 0) {
            return "To Do"
        } else if (digit === 1) {
            return "In Progress"
        } else if (digit === 2) {
            return "Completed"
        } else if (digit === 3) {
            return "Delayed"
        } else if (digit === 4) {
            return "Missed"
        }
    }

    const getColor = (digit) => {

        if (digit === 0) {
            return "volcano"
        } else if (digit === 1) {
            return "geekblue"
        } else if (digit === 2) {
            return "green"
        }
        else if (digit === 3) {
            return "orange"
        }
        else if (digit === 4) {
            return "red"
        }
    }

    const error = () => {
        messageApi.open({
            type: 'error',
            content: 'Data retrieve unsuccessful',
            style: {
                marginTop: '30vh',
                height: '100vh',
                fontSize: '20px'
            },
            duration: 3
        });
    };
    const approveActionStatus = (obj) => {

        console.log("obj", obj)

        let data = {
            agency_id: obj.agency_id,
            measure_id: obj.measure_id,
            action_id: obj.action_id,
            article_id: obj.article_id,

        }

        ApproveAgencyActionStatus(data).then((out) => {
            setFilterData(out.data.out)

            if (out.status === 200) {
                toast.success("Status changing request has been approved.", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
                setAgency(null)
            } else {
                toast.error(out.data.message, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
            }

            loadData()
            console.log("out", out)
        })
    }

    const DiscardActionStatus = (value) => {

        let obj = curretRecord
        
        let data = {
            agency_id: obj.agency_id,
            measure_id: obj.measure_id,
            action_id: obj.action_id,
            article_id: obj.article_id,
            reason: value.reason ?? ""
        }

        DiscardAgencyActionStatus(data).then((out) => {
            if (out.status === 200) {
                toast.success("Request to change the action status has been rejected.",
                    {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    });
                setReasonText("")
                form.resetFields()
                SetReason(false)
                setAgency(null)
            } else {
                toast.error(out.data.message,
                    {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    });
            }

            loadData()
        })
    }

    const getActionNo = (action_no) => {
        let actNo = actions.filter(element => element.value === action_no)[0]?.action_no
        console.log(actNo, '---------------');
        return <Typography.Text>{actNo.toString().padStart(2, '0')}</Typography.Text>
    }

    const getAllActions = async () => {
        const res = await getActions();
        if (res) {
            setAllActions(res.data.out)
            await getPendingApprovals(res.data.out);
        } else {
            error();
        }
    }

    const getPendingApprovals = async (actions) => {

        const res = await getAllPendingApprovals();

        if (res) {
            setTimeout(() => {

                setPendingApproval(res.data.out);
                mergeRecords(actions, res.data.out)
            }, 1000);
        } else {
            error();
        }
    }



    const loadData = async () => {
        await getAllActions()

    }
    const handleClose = () => {


        setMymodal(false)



    }

    function mergeRecords(actionsata, approvals) {
        console.log('***************', actionsata, actionsata)
        // setTblData(pendingApproval)
        if (approvals && actionsata) {
            approvals.forEach(obj2 => {
                const obj1 = actionsata.find(o => o.value === obj2.action_id);
                if (obj1) {
                    Object.assign(obj2, obj1);
                }
            });
            setTblData(approvals)
            setFilterData(approvals)
            console.log("--------", pendingApproval)

        }


        // setTblData(res.data.out);
    }

    const AddNewReason = async (value) => {


    }

    const filterData = (value) => {
        setAgency(value)
        console.log("filter", value)
        if (value) {
            let newData = data.filter(element => element.agency_id === value)
            setFilterData(newData)
        } else {
            setFilterData(data)
        }

    }
    return (
        <Fragment>


            <FormLayouts

                title="All Requests"
                table={
                    <Row gutter={[16, 16]} style={{ padding: 10 }}>

                        {(window.localStorage.getItem('ntfc_level') == 6 || window.localStorage.getItem('ntfc_level') == 7) &&
                            <Col xs={24} sm={24} md={7} lg={7} xl={7}>
                                <Select
                                    ref={selectRef}
                                    placeholder="Agency Name"
                                    onChange={filterData}
                                    style={{ width: '100%' }}
                                    options={agencies}
                                    value={agency}
                                    allowClear
                                />
                            </Col>

                        }




                        <Col xs={24}>
                            <Table
                                scroll={{
                                    y: 400,
                                }}
                                columns={window.localStorage.getItem('ntfc_level') == 5 ? columnsHOD : window.localStorage.getItem('ntfc_level') == 6 ? columnsChair : columns} dataSource={filteredData}
                                pagination={{
                                    pageSize: 10,

                                }}
                            />
                        </Col>

                    </Row>
                }
            >

            </FormLayouts>

            <ToastContainer />
            {/* <div style={{ display: "flex", justifyContent: "flex-start" }}>
                <Button
                    onClick={() => { window.location.href = "/newdashboard" }}
                    // disabled={disable ? true : false}
                    shape="circle"
                    icon={<ArrowLeftOutlined />}
                    style={{ width: 50, height: 50, backgroundColor: "white", color: "black", borderRadius: 50 }}
                />
            </div>
            <Typography.Title style={{ marginTop: 20 }} level={2}>All Requests</Typography.Title>
            <Divider />
            <Table style={{ marginTop: 50 }} columns={columns} dataSource={data} scroll={{ y: 500, }} /> */}
            <Modal
                className="custom-modal"
                centered
                footer={false}
                open={addReason}
                closable={true}
                onCancel={() => SetReason(false)}
                title={
                    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                        <div>     <Typography className='custom-modal-title' level={2}>Discard status change request</Typography></div>


                    </div>


                }
            // onOk={DiscardActionStatus}
            // onCancel={() => SetReason(false)}
            // okButtonProps={{ style: { backgroundColor: "#005476", color: "white" } }}

            >
                <div style={{ padding: "20px" }}>
                    <Form form={form} onFinish={DiscardActionStatus}>


                        <Form.Item
                            // label="Comment"
                            name="reason"
                            rules={[{ required: true, message: 'Please enter a reason for rejection' },
                            // {
                            //     pattern: /^(?!\s+$).*/,
                            //     message:
                            //         "Please enter a valid reason",
                            // }

                            ]}
                        >
                            <Input.TextArea style={{ borderRadius: 15 }} rows={5} placeholder='Enter reason' />

                        </Form.Item>
                        <Space style={{ display: "flex", width: "100%", justifyContent: "flex-end" }}>
                            <Button type='default' htmlType='button' onClick={() => { SetReason(false); form.resetFields() }}>
                                Cancel
                            </Button>
                            <Button type='primary' htmlType='submit'>
                                Discard Request
                            </Button>
                        </Space>

                    </Form>

                </div>


            </Modal>
            {/* <Modal
                style={{
                    top: 20,
                    height: "95vh",
                    backgroundColor: "transparent",

                }}
                width={1500}
                key={'modal-open'}
                footer={false}
                open={openMyModal}>

                <CustomeModal
                    onClose={handleClose}
                    ntfc={true}

                    action={currentAction} />



            </Modal> */}

        </Fragment >
    );
}

export default Page;
