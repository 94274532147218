import React from 'react';
import ReactECharts from 'echarts-for-react';
var chartDom = document.getElementById('main');

const colors = [
  '#E290FF',
  '#0492C2',
  '#50CD89',
  'rgba(255, 127, 86, 0.91)',
  '#F1416D'
]
const Donut = ({ report, donutSectorClick }) => {
  const options = {
    backgroundColor: 'white',
    title: {
      left: 'center',
      textStyle: {
        color: 'black'
      }
    },
    tooltip: {
      trigger: 'item'
    },

    visualMap: {
      show: false,
      min: 10,
      max: 600,
      inRange: {
        // colorLightness: [1, 1]
      }
    },

    colorBy: "series",
    series: [
      {
        name: 'Action wise implementation percentage',
        type: 'pie',
        radius: '70%',
        center: ['50%', '50%'],
        roseType: "angle",

        label: {
          color: 'black'
        },
        labelLine: {
          lineStyle: {
            color: '#B2CBD6'
          },
          smooth: 0.1,
          length: 10,
          length2: 20
        },
        itemStyle: {
          color: colors[colors.length],
          // shadowBlur: 200,
          // shadowColor: 'blue'
        },
        animationType: 'scale',
        animationEasing: 'elasticOut',
        animationDelay: function (idx) {
          return Math.random() * 500;
        },

        data: report && report.data ? report.data.map((item, index) => {
          let name = item.status + " " + Math.floor(item.percentage) + "%"
          return {
            value: Math.floor(item.percentage),
            name: name,
            itemStyle: {
              color: colors[index % colors.length]
            }

          }
        }) : []
      }
    ]
  };

  const onChartClick = (params) => {
    if (donutSectorClick) {
      donutSectorClick(params);
    }
  };

  return <ReactECharts option={options} onEvents={{ click: onChartClick, }} />;
};

export default Donut;