import { Avatar, Button, Divider, Drawer, Space, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { ArrowLeftOutlined } from "@ant-design/icons";
import UserDetails from "./UserDetails";
import "./PageHeader.css";
import { MenuOutlined } from "@ant-design/icons";
import {
  MergeCellsOutlined,
  SendOutlined,
  UserOutlined,
  WalletOutlined,
  BorderlessTableOutlined,
  LogoutOutlined,
  AppstoreOutlined,
} from "@ant-design/icons";
import { NewColorTheme } from "../../../appConst";
const PageHeader = ({ title, user, dashboard, AllEvents, backUrl, calender }) => {
  const [open, setOpenDrawer] = useState(false);
  const [drawerList, setList] = useState();

  const openDrawer = () => {
    console.log("drawer callling");
    setOpenDrawer(true);
  };

  const onClose = () => {
    setOpenDrawer(false);
  };

  const DrawerTile = ({ title, icon, url, Key }) => {
    console.log("-------", url);
    return (
      <div
        key={Key}
        className="my-drawer-content-list-item"
        onClick={() => {
          if (url == "/login") {
            window.localStorage.removeItem("ntfc_token");
          } else {
            window.location.href = url;
          }

        }}
      >
        <div style={{ display: "flex", gap: 10 }}>
          {icon}
          <Typography className="tile-title">{title}</Typography>
        </div>
        <Divider style={{ margin: 0 }} />
      </div>
    );
  };

  useEffect(() => {
    let DefaultdrawerList = [];
    DefaultdrawerList = [
      {
        title: "Dashboard",
        icon: <AppstoreOutlined className="drawer-item-icon" />,
        priority: 0,
        url: "/newdashboard",
      },
      {
        title: "Inquiry Management",
        url: "/inquiries",
        icon: <BorderlessTableOutlined className="drawer-item-icon" />,
        priority: 990,
      },

      {
        title: "Logout",
        url: "/login",
        icon: <LogoutOutlined className="drawer-item-icon" />,
        priority: 999,
      },
    ];
    console.log(
      "Upcoming def date-------------------->>>>",
      window.localStorage.getItem("ntfc_level")
    );
    if (
      window.localStorage.getItem("ntfc_level") != 3 &&
      window.localStorage.getItem("ntfc_level") != 2
    ) {
      if (
        window.localStorage.getItem("ntfc_level") != 4 &&
        window.localStorage.getItem("ntfc_level") != -1
      ) {
        DefaultdrawerList.push(
          {
            title: "Status Change Requests",
            icon: <MergeCellsOutlined className="drawer-item-icon" />,
            priority: 1,
            url: "/requests",
          },
          {
            title: "User Requests",
            url: "/userrequestshod",
            icon: <UserOutlined className="drawer-item-icon" />,
            priority: 2,
          }
        );
      }

      if (window.localStorage.getItem("ntfc_level") != -1) {
        DefaultdrawerList.push({
          title: "Donor Management",
          icon: <WalletOutlined className="drawer-item-icon" />,
          priority: 3,
          url: "/donormanagement",
        });
      }
    } else if (window.localStorage.getItem("ntfc_level") == 3) {
      DefaultdrawerList.push(
        {
          title: "Donor Requests",
          icon: <SendOutlined className="drawer-item-icon" />,
          priority: 4,
          url: "/donormanagement",
        },
        {
          title: "Donor Interests",
          url: "/donormanagement",
          icon: <SendOutlined className="drawer-item-icon" />,
          priority: 5,
        },
        {
          title: "Donor Contribution",
          url: "/donormanagement",
          icon: <SendOutlined className="drawer-item-icon" />,
          priority: 6,
        },

        {
          title: "Donor Profile",
          url: "/donormanagement",
          icon: <SendOutlined className="drawer-item-icon" />,
          priority: 7,
        }
      );
    } else {
      DefaultdrawerList.push({
        title: "Donor Management",
        icon: <WalletOutlined className="drawer-item-icon" />,
        priority: 3,
        url: "/donormanagement",
      });
    }
    let sortedArray = DefaultdrawerList.sort((a, b) => a.priority - b.priority);
    setList(sortedArray);
  }, []);

  return (
    <>
      <div
        style={{
          display: "flex",
          width: "100%",
          height: "12vh",
        }}
        className="header-one"
      >
        <div
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            paddingLeft: "40px",
          }}
        >
          {!dashboard && (
            <>
              <Space>
                <Button
                  onClick={() => {
                    if (AllEvents) {
                      window.location.href = "/newCalender";

                    } else {
                      if (backUrl) {
                        window.location.href = backUrl;
                      } else {
                        if (window.localStorage.getItem('ntfc_level') == 0 || window.localStorage.getItem('ntfc_level') == 1) {
                          window.location.href = "/presidentdashbaord";
                        } else {
                          window.location.href = "/newdashboard";
                        }
                      }

                    }


                  }}
                  shape="circle"
                  icon={<ArrowLeftOutlined style={{ color: "white" }} />}
                  style={{
                    width: 35,
                    height: 35,
                    backgroundColor: NewColorTheme.primaryColor,
                    color: "white",
                    borderRadius: 50,
                    zIndex: 999,
                    paddingTop: 5
                  }}
                />

                <Typography.Title
                  level={4}
                  style={{ margin: 0, color: NewColorTheme.primaryColor }}
                >
                  {title ? title : "Inquiries"}
                </Typography.Title>
              </Space>

              <hr
                style={{
                  background: NewColorTheme.primaryColor,
                  height: 1,
                  width: "60%",
                  border: "none",
                  marginLeft: 10,
                  marginRight: 0,
                }}
              />
            </>
          )}

          {dashboard && (
            <Space align="center">
              <img
                src="/ntfcLogo.png"
                alt="ntfc logo"
                style={{ marginRight: 35 }}
              ></img>
              {/* <Typography.Title level={2} style={{ margin: 0 }}>{"Welcome to" + " " + title}</Typography.Title> */}
            </Space>
          )}
        </div>
        <div
          style={{
            width: "30%",
            display: "flex",
            alignItems: "center",
            justifyContent: "end",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "end",
              paddingRight: "25px",
            }}
          >
            <UserDetails
              username={window.localStorage.getItem("ntfc_name")}
              designation={window.localStorage.getItem("ntfc_level")}
              user={user}
              calender={calender}
            />
          </div>
        </div>
      </div>
      <div className="mobile-view-header">
        <div>
          <Typography className="mobile-title">
            {title ? title : "Inquiries"}
          </Typography>
        </div>
        <div style={{ display: "flex", alignItems: "center", height: "100%" }}>
          <MenuOutlined
            style={{
              fontSize: "25px",
              color: "white",
            }}
            onClick={openDrawer}
          />
        </div>
      </div>
      <Drawer
        title="NTFC Monitoring Tool"
        placement={"left"}
        closable={false}
        onClose={onClose}
        open={open}
        key={"drawer"}
        className="my-drawer"
      >
        <div className="my-drawer-content">
          {drawerList &&
            drawerList
              ?.sort((a, b) => a.priority - b.priority)
              .map((element, index) => {
                return (
                  <DrawerTile
                    Key={index}
                    title={element.title}
                    icon={element.icon}
                    url={element.url}
                  />
                );
              })}
        </div>
      </Drawer>
    </>
  );
};

export default PageHeader;
