import { Typography } from 'antd'
import React from 'react'
import './TitleBox.styles.css'

function Title({ title }) {
    return (
        <div className="title-container" >
            <div className="title-div">
                <Typography className="section-title"> {title.toUpperCase()}</Typography>
            </div>
            <div className="line-div">
                <p className='title-line' ></p>
            </div>


        </div>
    )
}

export default Title;