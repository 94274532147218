import React, { useState } from 'react'

import DonarUser from '../Donors/components/DonarUser'
import '../Donors/components/dashboarddonors.css'
import { Button, Card, Col, Divider, Row, Spin, message } from 'antd'

import { CameraOutlined } from '@ant-design/icons';
import DashboardCards from '../Donors/components/DashboardCards';
import DonorDashboardCard from '../Donors/components/DonorDashboardCard';
import CustomTable from '../../Components/Table';
import RowData from './RowData';
import DonorInterestRow from './DonorInterestRow';
import { useEffect } from 'react';
import { GetDonorRequestInterests, addDonorRequestInterest, getMeasuresList, removeDonorRequestInterest } from '../../api';
import SimpleBar from 'simplebar-react';
import 'simplebar-react/dist/simplebar.min.css';



const DonorInterestMeasure = () => {


    const [measures, setMeasures] = useState([]);
    const [user, setUser] = useState("");
    const [messageApi, contextHolder] = message.useMessage();
    const [loaded, setLoad] = useState(false)
    let [matchingCount, setCount] = useState(0)

    useEffect(() => {
        setCount(0)
        message.config({
            maxCount: 1,
            duration: 2,
        });
    }, []);

    // Get All DOnors

    const updateMeasure = (id, checked) => {
        console.log("callinhg")
        getAllMeasures()

    };

    useEffect(() => {

        getAllMeasures()

    }, []);


    const getAllMeasures = async function () {
        setCount(0)
        const user = await window.localStorage.getItem('user_id')

        const out = await getMeasuresList()

        if (out.status === 200) {
            setMeasures(out.data.out)
            getDonorRequestInterest(user, out.data.out)
        }
    }

    const getDonorRequestInterest = async function (user, data) {

        const out = await GetDonorRequestInterests()
        console.log(out);
        if (out.status === 200) {
            matchingCount = 0
            let filterData = out.data.out.filter((element) => element.user_id === user)
            console.log("filterData", filterData)
            // Loop through each object in the first array
            for (let i = 0; i < data.length; i++) {
                const currentObject = data[i];

                // Find the matching object in the second array
                const matchingObject = filterData.find(obj => obj.request_id === currentObject.id);
                console.log("matchingObject", matchingObject)
                // If a matching object was found, add the age property to the current object in the first array
                if (matchingObject) {
                    console.log("COunt", matchingCount)
                    matchingCount++
                    console.log("COunt ++ ", matchingCount)
                    currentObject.checked = true;
                } else {
                    currentObject.checked = false;

                }
                setCount(matchingCount)
            }

            setMeasures(data)
            setLoad(true)
            console.log("Measures", measures)

        }
    }


    return (

        <div style={{ width: "65%", margin: "50px 0px", }}>

            <Card id="donorinterest-card" className='donorinterest-card' >
                {loaded ? <SimpleBar style={{ maxHeight: 500, padding: '10px 30px' }}>
                    {measures.map((element, key) => {
                        return <DonorInterestRow key={element.id} measure={element} updateMeasure={updateMeasure} />
                    })}

                </SimpleBar> :
                    <Spin size='large' />

                }

            </Card>

        </div>

    )
}

export default DonorInterestMeasure
// <div style={{ overflow: "hidden", height: "100vh" }}>
//     <div className="bgSidePanel"></div>
//     <div className="sidepanel-left"></div>

//     <div className="top-dashboard">
{/* <DonarUser
                    donorUser='Mr. Kalum Sanjaya'
                />
                <div className="divide-line">
                    <Divider orientation="left">
                        <p className='divider-text'>My Interests</p>
                    </Divider>
                </div> */}
{/* <div>
                    <Row gutter={16}>
                        <Col span={6}>
                            <DonorDashboardCard
                                cardClass="primaryCard"
                                CardText="Interested Measures"
                                CardVal={matchingCount}
                                textClass="primaryFont"
                                valClass="primaryVal"


                            />

                        </Col>
                        <Col span={9}>
                            <DonorDashboardCard
                                style={{ width: "100%" }}
                                cardClass="SecondaryCard2"
                                CardText="Interested Projects"
                                CardVal="5"
                                textClass="secondaryFont2"
                                valClass="secondaryVal2"


                            />

                        </Col>




                    </Row>
                </div> */}



{/* </div>

        </div> */}


    // )