import { Avatar, Button, Col, Row } from 'antd'
import React, { useState } from 'react'
import { UserOutlined, PlusOutlined, EyeOutlined } from '@ant-design/icons';
import { useEffect } from 'react';
import { getMyAgencyContributionCreations } from '../../../api';

const DashboardCards = (props) => {

  const [selectedCardIndex, setSelectedCardIndex] = useState(0);
  const [capCount, setCapCount] = useState(0);

  useEffect(() => {
    setSelectedCardIndex(parseInt(props.selectedCard))
    loadCounts()
  }, [])

  const loadCounts = async () => {
    getMyAgencyContributionCreations().then((out) => {

      setCapCount(out.data.out.length)
    })
  }


  const handleCardClick = (index) => {

    setSelectedCardIndex(index);
    props.onCardSelect(index); // Call the onCardSelect function with the selected index
  };
  return (
    <div style={{ paddingLeft: '5%', paddingRight: '5%' }}>

      <Row gutter={16}>

        {/* <Col span={6} onClick={() => window.location.href = props.card1url}> */}
        <Col span={8} onClick={() => handleCardClick(0)} className="cardBodyMain">
          <div className={selectedCardIndex === 0 ? "primaryCarddonor" : "SecondaryCarddoonor"}>
            <div className="cardBody">
              <Row gutter={16}>
                <Col span={16}>
                  <p
                    className={selectedCardIndex === 0 ? "primaryFont" : "secondaryFont"}
                  >{props.primaryCardText}</p>
                </Col>
                <Col span={8}>
                  <p
                    className={selectedCardIndex === 0 ? 'primaryVal' : "secondaryVal"}
                  >{props.primaryCardVal}</p>
                </Col>
              </Row>
            </div>
          </div>
        </Col>
        {/* <Col span={6} onClick={() => window.location.href = props.card2url}> */}
        {/* <Col span={8} onClick={() => { handleCardClick(1) }} className="cardBodyMain">
          <div className={selectedCardIndex === 1 ? "primaryCarddonor" : "SecondaryCarddoonor"}>
            <div className="cardBody">
              <Row gutter={16}>
                <Col span={16}>
                  <p
                    className={selectedCardIndex === 1 ? "primaryFont" : "secondaryFont"}
                  >{props.secondaryCardText}</p>
                </Col>
                <Col span={6}>
                  <p
                    className={selectedCardIndex === 1 ? 'primaryVal' : "secondaryVal"}
                  >{props.secondaryCardVal}</p>
                </Col>
              </Row>
            </div>
          </div>
        </Col> */}
        <Col span={8} onClick={() => { handleCardClick(1) }} className="cardBodyMain">
          <div className={selectedCardIndex === 1 ? "primaryCarddonor" : "SecondaryCarddoonor"}>
            <div className="cardBody">
              <Row gutter={16}>
                <Col span={16}>
                  <p
                    className={selectedCardIndex === 1 ? "primaryFont" : "secondaryFont"}
                  >{props.contribution}</p>
                </Col>
                <Col span={8}>
                  <p
                    className={selectedCardIndex === 1 ? 'primaryVal' : "secondaryVal"}
                  >{capCount}</p>
                </Col>
                {/* <Col span={6}>
                  <div className="btnAdd">

                    <Button
                      className=''
                      size='large'
                      icon={<EyeOutlined />}
                      shape="circle"
                    ></Button>
                  </div>
                </Col> */}

              </Row>
            </div>
          </div>
        </Col>
        <Col span={8} onClick={() => handleCardClick(2)} className="cardBodyMain">
          <div className={selectedCardIndex === 2 ? "primaryCarddonor" : "SecondaryCarddoonor"}>
            <div className="cardBody">
              <Row gutter={16}>
                <Col span={18}>
                  <p
                    className={selectedCardIndex === 2 ? "primaryFont" : "secondaryFont"}
                  >{props.lastCardText}</p>
                </Col>
                {/* <Col span={6}>
                  <div className="btnAdd">

                    <Button
                      className=''
                      size='large'
                      icon={<PlusOutlined />}
                      shape="circle"
                    ></Button>
                  </div>
                </Col> */}
              </Row>
            </div>
          </div>
        </Col>
        {/* <Col span={5} onClick={() => { handleCardClick(4) }} className="cardBodyMain">
          <div className={selectedCardIndex === 4 ? "primaryCarddonor" : "SecondaryCarddoonor"}>
            <div className="cardBody">
              <Row gutter={16}>
                <Col span={18}>
                  <p
                    className={selectedCardIndex === 4 ? "primaryFont" : "secondaryFont"}
                  >{props.inquirymgt}</p>
                </Col>                
              </Row>
            </div>
          </div>
        </Col> */}
      </Row>
    </div>
  )
}

export default DashboardCards
