import { Typography } from 'antd'
import React from 'react'
import { useState } from 'react';
import { useEffect } from 'react';
import { getDonorCardData } from '../../../../api';
import CountUp from 'react-countup';


function DonorProfileCount() {

    useEffect(() => {
        setShouldAnimate(true);
        loadData()

    }, [])


    const [cardData, setCardData] = useState(null)
    const [shouldAnimate, setShouldAnimate] = useState(false);

    const loadData = async () => {
        getDonorCardData(window.localStorage.getItem('ntfc_agency')).then((out) => {

            setCardData(out.data.out)


        });

    }
    return (
        <div
            onClick={(e) => {
                e.stopPropagation()
                window.location.href = "/donorprofile"
            }}

            style={{
                background: "white",
                borderRadius: "10px 0px 10px 10px",
                position: "relative",
                zIndex: 0,
                overflow: "hidden",
                transition: "0.6s ease-in",
                display: "flex"

            }}
            className='count-title-card2 enlarged-button2 '
        >
            <div style={{
                flex: 1,
                textAlign: "center"
            }}>

                <Typography
                    className="hover-typography user-request-action-typo"

                >
                    {shouldAnimate && <CountUp end={cardData && cardData.profiles
                        ? cardData.profiles
                        : 0} duration={2} />}


                </Typography>
            </div>
            <div style={{
                flex: 2,
                display: "flex",
                justifyContent: "flex-end",
                alignItems: 'center',
                paddingRight: 33,
                textAlign: 'right'
            }}>
                <Typography
                    className="hover-typography user-request-action-typo-name"
                >
                    Profiles
                </Typography>
            </div>






        </div>
    )
}

export default DonorProfileCount