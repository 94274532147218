import React from "react";
import { useEffect } from "react";
import {
  DownloadFiles,
  GetDonorRequestInterests,
  GetPublishedDonorRequests,
  addDonorRequestInterest,
  getDonorAssists,
  removeDonorRequestInterest,
} from "../../../api";
import { useState } from "react";
import TabLayout from "../TabLayout";
import {
  Button,
  Col,
  Input,
  Modal,
  Row,
  Space,
  Table,
  Tag,
  Tooltip,
  Typography,
} from "antd";
import dayjs from "dayjs";
import {
  PlusOutlined,
  InboxOutlined,
  EyeOutlined,
  CloseOutlined,
  CloudUploadOutlined,
  CalendarOutlined,
  ContainerOutlined,
  ReconciliationOutlined,
  CalendarFilled,
  FileDoneOutlined,
  BorderlessTableOutlined,
  BoxPlotOutlined,
  BankOutlined,
  ContactsOutlined
} from "@ant-design/icons";
import { ToastContainer, toast, Zoom } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import TextArea from "antd/es/input/TextArea";
import { MainColors, NewColorTheme } from "../../../appConst";
import { BiCloudDownload } from "react-icons/bi";
function DonorRequestsView({ data, setData, setInitData, setRefreshTimestamp }) {
  const [viewDialog, setViewDialog] = useState(false);
  const [currentRecord, setCurrentRecord] = useState();
  const [assistDescription, setAssistDescription] = useState("");
  const [assistContact, setAssistContact] = useState("");
  const [isChair, setChair] = useState(false)

  useEffect(() => {

    if (window.localStorage.getItem('ntfc_level') == 6) {
      setChair(true)
    } else {
      setChair(false)
    }
    loadData();
  }, []);

  const handleCancelView = () => {
    setAssistContact("");
    setAssistDescription("");
    setViewDialog(false);
  };
  // Table Columns
  const columns = [
    {
      title: "Created Date",
      dataIndex: "created",
      key: "created",
      render: (_, { created }) =>
        created ? <div>{dayjs(created).format("DD/MM/YYYY")}</div> : "-",
    },

    {
      title: "Agency",
      dataIndex: "agency_name",
      key: "agency_name",
      render: (_, record) =>
        record ? (
          <div>{record.agency == -1 ? "National Wise" : record.agency_name ? record.agency_name : "-"}</div>
        ) : null,
    },
    {
      title: "Request Type",
      dataIndex: "support_type",
      key: "support_type",
      render: (_, { assistance }) => (
        <span key={assistance}>{assistance == 0 ? "TS/CB" : "OTHER"}</span>
      ),
    },
    {
      title: "Subject",
      dataIndex: "subject",
      key: "subject",
    },

    {
      title: "Measure Name",
      dataIndex: "measure_name",
      key: "measure_name",
      width: 300,

    },

    {
      title: "Description",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "Status",
      dataIndex: "checked",
      key: "checked",
      render: (_, { checked }) => (
        <p>
          {checked ? (
            <Tag
              style={{ width: 120, textAlign: "center" }}
              color={"#007613"}
            >
              Interest Shown
            </Tag>
          ) : (
            <Tag
              style={{ width: 120, textAlign: "center" }}
              color={"#0492c2"}
            >
              Pending
            </Tag>
          )}
        </p>
      ),
    },

    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      render: (_, record, index) =>
        record ? (
          <div>


            {/* <Switch
                                type="primary"
                                checked={record.checked}
                                onChange={(checked) => handleSwitchChange(checked, record)}
                            /> */}
            <Tooltip title="To express your interest in this project, click the button.">
              <Button
                type="primary"
                size="small"
                onClick={() => {
                  setAssistContact(null)
                  setAssistDescription(null)
                  console.log("record--->", record);
                  if (record.checked) {
                    let filteredData = record.interests.find((ele) => ele.user_id == localStorage.getItem('user_id'))
                    record.InterestDescription = filteredData.description
                    record.InterestContact = filteredData.contact
                  }
                  setCurrentRecord(record);
                  setViewDialog(true);
                }}
                disabled={window.localStorage.getItem('view_only') == 1}
              // shape="circle"
              // icon={<EyeOutlined style={{ color: "darkgreen" }} />}
              >{record.checked ? "View my Intrest" : "Add my Interest"}</Button>
            </Tooltip>
          </div>
        ) : null,
    },
  ];
  const loadData = async () => {
    // setLoad(false)
    const out = await GetPublishedDonorRequests();

    console.log("out", out);

    if (out.status === 200) {
      var sortedArray = out.data.out.sort(
        (a, b) => new Date(b.created) - new Date(a.created)
      );
      const updatedData = sortedArray.map(obj => {
        const formattedMeasureName = `${obj.article_no}.${obj.measure_no} - ${obj.measure_name}`;
        return { ...obj, measure_name: formattedMeasureName };
      });
      // setData(updatedData);
      setInitData(updatedData)
      getDonorRequestInterest(updatedData);
    }
  };

  async function getDonorRequestInterest(incomingData) {

    const out = await getDonorAssists()
    // const out = await GetDonorRequestInterests();

    console.log("incomingData donorRequInterests", out)
    console.log("incomingData donorRequInterests", incomingData)

    const newData = incomingData.map((obj1) => {
      let isChecked = false;
      for (const obj2 of out.data.out) {
        console.log("Mapped", obj1.id, obj2.id);
        if (obj1.id === obj2.id) {
          isChecked = true;
          break; // Skip to the next obj1 when a match is found
        } else {
          isChecked = false;
        }
      }

      return { ...obj1, checked: isChecked };
    });

    console.log("Mapped", newData);
    setData(newData);
    // setLoad(true)
  }

  const interestSubmit = async () => {
    let body = {
      id: currentRecord.id,
      description: assistDescription,
      contact: assistContact,
    };

    if (!currentRecord?.checked) {
      let out = await addDonorRequestInterest(body);

      if (out.status === 200) {
        toast.success(out.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        setRefreshTimestamp()
      }
    } else {
      let out2 = await removeDonorRequestInterest(body);
      if (out2.status === 200) {
        toast.success(out2.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      } else {
        toast.error(out2.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    }

    setViewDialog(false);
    loadData();
  };

  const FetchFile = async (file) => {
    let out = await DownloadFiles(file.token);

    let blob = new Blob([out.data], { type: file.type });
    let url = window.URL.createObjectURL(blob);

    let link = document.createElement("a");
    link.href = url;

    link.download = file.url.split("-::-")[1];

    document.body.appendChild(link);
    link.click();
  };

  return (
    <div className="custom-scroll-div tab-layout-div">

      <TabLayout
        table={
          <Table
            className="custom-table"
            rowClassName={(record, index) => index % 2 === 0 ? 'white-row' : 'gray-row'}
            style={{ marginTop: -20 }}
            scroll={{
              y: 400,
            }}
            size="small"
            columns={columns}
            dataSource={data}
            pagination={{
              pageSize: 4,
              // total: data?.length,
            }}
          // onRow={(record, rowIndex) => {
          //   return {
          //     onClick: () => {
          //       console.log("record--->>", record);
          //       setAssistContact(null)
          //       setAssistDescription(null)
          //       setCurrentRecord(record);
          //       setViewDialog(true);
          //     },
          //   };
          // }}
          />
        }
      />

      <Modal
        centered
        maskClosable={false}
        className="custom-modal"
        title={
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div>
              {" "}
              <Typography className="custom-modal-title" level={2}>
                Interest to Assist
              </Typography>
            </div>
          </div>
        }
        open={viewDialog}
        width={750}
        onCancel={handleCancelView}
        footer={[
          !currentRecord?.checked && (
            <Row gutter={[8, 8]} align={"top"} style={{ marginBottom: 15 }}>
              <Col xs={24}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                  }}
                >
                  <Typography.Title level={5}>
                    {" "}
                    Mark My Interest
                  </Typography.Title>
                  <Row style={{ width: "100%" }} gutter={16}>
                    <Col
                      xs={12}
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "flex-start",
                      }}
                    >
                      <p style={{ display: "flex", textAlign: "left" }}>
                        Description
                      </p>
                      <Input.TextArea
                        style={{ borderRadius: 15, width: "100%" }}
                        rows={3}
                        placeholder="Description"
                        value={assistDescription}
                        onChange={(e) => setAssistDescription(e.target.value)}
                      />
                    </Col>
                    <Col xs={12}>
                      <p style={{ display: "flex", textAlign: "left" }}>
                        Contact Details
                      </p>
                      <Input.TextArea
                        style={{ borderRadius: 15, width: "100%" }}
                        rows={3}
                        placeholder="Contact Details"
                        value={assistContact}
                        onChange={(e) => setAssistContact(e.target.value)}
                      />
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          ),
          <Button size="large" onClick={handleCancelView}>
            {" "}
            Cancel
          </Button>,
          <Button
            style={{ backgroundColor: NewColorTheme.primaryColor, color: "white" }}
            size="large"
            type="primary"
            onClick={interestSubmit}
            disabled={window.localStorage.getItem('view_only') == 1}
          >
            {" "}
            {currentRecord?.checked ? "Remove Assist" : "Assist"}
          </Button>,
        ]}
      >
        <div
          className="custom-modal-body custom-scroll-div"
          style={{ height: '40vh', overflow: "auto" }}
        >
          <Row>
            <Col xs={24}>
              <Row gutter={[8, 8]} align={"top"}>
                <Col xs={24}>
                  <Row gutter={[8, 8]}>
                    <Col xs={2}>
                      <ReconciliationOutlined style={{ fontSize: 22 }} />
                    </Col>
                    <Col xs={22}>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "flex-start",
                        }}
                      >
                        <Typography.Title level={5}>
                          {" "}
                          Subject
                        </Typography.Title>
                        <Typography> {currentRecord?.subject}</Typography>
                      </div>
                    </Col>
                  </Row>
                </Col>
                <Col xs={12}>
                  <Row gutter={[8, 8]} align={"top"}>
                    <Col xs={4}>
                      <BankOutlined style={{ fontSize: 22 }} />
                    </Col>
                    <Col xs={20}>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "flex-start",
                        }}
                      >
                        <Typography.Title level={5}>
                          {" "}
                          Created Agency
                        </Typography.Title>

                        <Typography>
                          {" "}
                          {currentRecord?.agency_name}
                        </Typography>
                      </div>
                    </Col>
                  </Row>
                </Col>
                <Col xs={12}>
                  <Row gutter={[8, 8]} align={"top"}>
                    <Col xs={4}>
                      <CalendarFilled style={{ fontSize: 22 }} />
                    </Col>
                    <Col xs={20}>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "flex-start",
                        }}
                      >
                        <Typography.Title level={5}>
                          {" "}
                          Created Date
                        </Typography.Title>

                        <Typography>
                          {" "}
                          {dayjs(currentRecord?.created).format("DD/MM/YYYY")}
                        </Typography>
                      </div>
                    </Col>
                  </Row>
                </Col>
                <Col xs={24}>
                  <Row gutter={[8, 8]} align={"top"}>
                    <Col xs={2}>
                      <BoxPlotOutlined style={{ fontSize: 22 }} />
                    </Col>
                    <Col xs={22}>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "flex-start",
                        }}
                      >
                        <Typography.Title level={5}> Measure</Typography.Title>

                        <Typography>
                          {currentRecord?.measure ?

                            currentRecord?.measure_name : "-"}
                        </Typography>
                      </div>
                    </Col>
                  </Row>
                </Col>
                <Col xs={24}>
                  <Row gutter={[8, 8]} align={"top"}>
                    <Col xs={2}>
                      <BorderlessTableOutlined style={{ fontSize: 22 }} />
                    </Col>
                    <Col xs={22}>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "flex-start",
                        }}
                      >
                        <Typography.Title level={5}>
                          {" "}
                          Support Type
                        </Typography.Title>

                        <Typography>
                          {" "}
                          {currentRecord?.assistance == 0
                            ? "Technical Support or Capacity Building"
                            : "Other"}
                        </Typography>
                      </div>
                    </Col>
                  </Row>
                </Col>

                <Col xs={8}>
                  <Row gutter={[8, 8]} align={"top"}>
                    <Col xs={6}>
                      <CalendarOutlined style={{ fontSize: 22 }} />
                    </Col>
                    <Col xs={18}>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "flex-start",
                        }}
                      >
                        <Typography.Title level={5}>
                          {" "}
                          Tentative Start Date
                        </Typography.Title>

                        <Typography>
                          {" "}
                          {dayjs(currentRecord?.implementation_from).format(
                            "DD/MM/YYYY"
                          )}
                        </Typography>
                      </div>
                    </Col>
                  </Row>
                </Col>

                <Col xs={8}>
                  <Row gutter={[8, 8]} align={"top"}>
                    <Col xs={4}>
                      <CalendarOutlined style={{ fontSize: 22 }} />
                    </Col>
                    <Col xs={20}>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "flex-start",
                        }}
                      >
                        <Typography.Title level={5}>
                          {" "}
                          Tentative End Date
                        </Typography.Title>

                        <Typography>
                          {" "}
                          {dayjs(currentRecord?.implementation_to).format(
                            "DD/MM/YYYY"
                          )}
                        </Typography>
                      </div>
                    </Col>
                  </Row>
                </Col>
                <Col xs={8}>
                  <Row gutter={[8, 8]} align={"top"}>
                    <Col xs={6}>
                      <CalendarOutlined style={{ fontSize: 22 }} />
                    </Col>
                    <Col xs={18}>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "flex-start",
                        }}
                      >
                        <Typography.Title level={5}>
                          {" "}
                          Published Date
                        </Typography.Title>

                        <Typography>
                          {" "}
                          {currentRecord?.published_date
                            ? dayjs(currentRecord?.published_date).format(
                              "DD/MM/YYYY"
                            )
                            : "-"}
                        </Typography>
                      </div>
                    </Col>
                  </Row>
                </Col>
                <Col xs={24}>
                  <Row gutter={[8, 8]} align={"top"}>
                    <Col xs={2}>
                      <ContainerOutlined style={{ fontSize: 22 }} />
                    </Col>
                    <Col xs={22}>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "flex-start",
                        }}
                      >
                        <Typography.Title level={5}>
                          {" "}
                          Description
                        </Typography.Title>

                        <Typography>
                          {" "}
                          {currentRecord?.description
                            ? currentRecord?.description
                            : "-"}
                        </Typography>
                      </div>
                    </Col>
                  </Row>
                </Col>
                {currentRecord?.attachments?.length > 0 && (
                  <Col xs={24}>
                    <Row gutter={[8, 8]} align={"top"}>
                      <Col xs={2}>
                        <CloudUploadOutlined style={{ fontSize: 22 }} />
                      </Col>
                      <Col xs={22}>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "flex-start",
                          }}
                        >
                          <Typography.Title level={5}>
                            {" "}
                            Attachment
                          </Typography.Title>

                          {currentRecord?.attachments?.length > 0 &&
                            currentRecord?.attachments.map((element, key) => {
                              return (
                                <div
                                  style={{
                                    alignItems: "center",
                                    display: "flex",
                                    justifyContent: "space-between",
                                    width: "100%",
                                    cursor: "pointer",
                                    background: MainColors.LIGHTBACKCOLOR,
                                    borderRadius: 10,
                                    padding: 10,

                                    ":hover": {
                                      background: MainColors.DARKBACKCOLOR,
                                      color: "white",
                                      // Add any additional styles you want for the hover effect
                                    },
                                  }}
                                  key={key}
                                  onClick={() => FetchFile(element)}
                                >
                                  <div>
                                    <img
                                      src="/file.png"
                                      alt="file"
                                      style={{ height: 50, width: 50 }}
                                    />
                                    {element?.url &&
                                      (element?.url
                                        ?.toString()
                                        .split("-::-"))[1]}
                                  </div>

                                  <BiCloudDownload
                                    style={{ fontSize: 32, color: NewColorTheme.primaryColor }}
                                  />
                                </div>
                              );
                            })}
                        </div>
                      </Col>
                    </Row>
                  </Col>
                )}
                <Col xs={24}>
                  <Row gutter={[8, 8]} align={"top"}>
                    <Col xs={2}>
                      <FileDoneOutlined style={{ fontSize: 22 }} />
                    </Col>
                    <Col xs={22}>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "flex-start",
                        }}
                      >
                        <Typography.Title level={5}> Remarks</Typography.Title>

                        <Typography>
                          {" "}
                          {currentRecord?.reject_reason
                            ? currentRecord?.reject_reason
                            : currentRecord?.publish_reason
                              ? currentRecord?.publish_reason
                              : "-"}
                        </Typography>
                      </div>
                    </Col>
                  </Row>
                </Col>
                {currentRecord?.published_attachments?.length > 0 && (
                  <Col xs={24}>
                    <Row gutter={[8, 8]} align={"top"}>
                      <Col xs={2}>
                        <CloudUploadOutlined style={{ fontSize: 22 }} />
                      </Col>
                      <Col xs={22}>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "flex-start",
                          }}
                        >
                          <Typography.Title level={5}>
                            {" "}
                            Remarks Related Attachment
                          </Typography.Title>

                          {currentRecord?.published_attachments?.length > 0 &&
                            currentRecord?.published_attachments.map((element, key) => {
                              return (
                                <div
                                  style={{
                                    alignItems: "center",
                                    display: "flex",
                                    justifyContent: "space-between",
                                    width: "100%",
                                    cursor: "pointer",
                                    background: MainColors.LIGHTBACKCOLOR,
                                    borderRadius: 10,
                                    padding: 10,

                                    ":hover": {
                                      background: MainColors.DARKBACKCOLOR,
                                      color: "white",
                                      // Add any additional styles you want for the hover effect
                                    },
                                  }}
                                  key={key}
                                  onClick={() => FetchFile(element)}
                                >
                                  <div>
                                    <img
                                      src="/file.png"
                                      alt="file"
                                      style={{ height: 50, width: 50 }}
                                    />
                                    {element?.url &&
                                      (element?.url
                                        ?.toString()
                                        .split("-::-"))[1]}
                                  </div>

                                  <BiCloudDownload
                                    style={{ fontSize: 32, color: NewColorTheme.primaryColor }}
                                  />
                                </div>
                              );
                            })}
                        </div>
                      </Col>
                    </Row>
                  </Col>
                )}

                {currentRecord?.checked &&
                  <>
                    <Col xs={24}>
                      <Row gutter={[8, 8]} align={"top"}>
                        <Col xs={2}>
                          <ContainerOutlined style={{ fontSize: 22 }} />
                        </Col>
                        <Col xs={22}>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "flex-start",
                            }}
                          >
                            <Typography.Title level={5}>
                              {" "}
                              Interest Description
                            </Typography.Title>

                            <Typography>
                              {currentRecord?.InterestDescription}

                            </Typography>
                          </div>
                        </Col>
                      </Row>
                    </Col>
                    <Col xs={24}>
                      <Row gutter={[8, 8]} align={"top"}>
                        <Col xs={2}>
                          <ContactsOutlined style={{ fontSize: 22 }} />
                        </Col>
                        <Col xs={22}>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "flex-start",
                            }}
                          >
                            <Typography.Title level={5}>
                              {" "}
                              Interest Contacts
                            </Typography.Title>

                            <Typography>
                              {currentRecord?.InterestContact}

                            </Typography>
                          </div>
                        </Col>
                      </Row>
                    </Col>
                  </>
                }
              </Row>
            </Col>
          </Row>
        </div>
        {/* <p>{record.checked ? "Are you sure to assist this project?" : "Are you sure to remove this project?"}</p> */}
      </Modal>
      <ToastContainer />
    </div>
  );
}

export default DonorRequestsView;
