
import React from 'react'

import ReactECharts from 'echarts-for-react';
import './barChart.css'
import { Typography } from 'antd';
import { useEffect } from 'react';
import { useState } from 'react';
import CountUp from 'react-countup';

function BarChart({ data, count }) {
    const [shouldAnimate, setShouldAnimate] = useState(false);


    useEffect(() => {
        setShouldAnimate(true);


    }, [count])

    let options = {
        xAxis: {
            type: 'category',
            show: false,
        },
        yAxis: {
            type: 'value',
            show: false,
        },
        series: [
            {
                data: data,
                type: 'bar',
                itemStyle: {
                    // specify styles for individual bars
                    color: 'rgba(254, 137, 2, 0.7)', // custom color
                    borderRadius: [1, 1, 0, 0], // border radius for top corners only
                    label: {
                        show: true, // show label on top of each bar
                        position: 'top', // position the label on top of each bar
                        // set font weight to bold
                    },
                },
            }
        ]
    };

    return (<div className='bbar'>
        <div>
            <Typography className='stat-title'>Articles</Typography>
            <Typography className='stat-count'>
                {shouldAnimate && <CountUp end={count && count ? count : 0} duration={2} />}


            </Typography>

        </div>
        <div className='stat-chart'>
            <ReactECharts option={options} />
        </div>


    </div>
    )
}

export default BarChart


