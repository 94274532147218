import {
  message,
  Avatar,
  Button,
  Col,
  Collapse,
  Divider,
  Modal,
  Progress,
  Row,
  Space,
  Tooltip,
  Image,
  Typography,
  Spin,
  Badge,
  Tag,
  Alert,
  List,
  Form,
  Input,
  Select,
  Descriptions,
  Empty,
} from "antd";
import React, { useMemo } from "react";
import BoxHeader from "./componentsTasks/BoxHeader";
import HeaderCategory from "./componentsTasks/HeaderCategory";
import {
  DoubleRightOutlined,
  ArrowUpOutlined,
  ArrowDownOutlined,
  EyeOutlined,
  UserAddOutlined,
  InboxOutlined,
  MessageOutlined,
  FileTextOutlined,
  EditOutlined,
  UserOutlined,
  CommentOutlined,
  FileAddOutlined,
  PlusSquareOutlined,
  PlusOutlined,
  MinusOutlined,
  FilterOutlined,
  LoadingOutlined
} from "@ant-design/icons";
import moment from "moment";
import "./componentsTasks/Tasks.css";
import Member from "./componentsTasks/Member";
import { useEffect } from "react";
import {
  AddActionParticipant,
  AddAgencyActionComment,
  AddAgencySubActionComment,
  AddSubActionFiles,
  AddSubActionParticipant,
  DownloadFiles,
  GetAgencyWiseAllArticles,
  GetSubActionParticipant,
  RemoveActionParticipant,
  RemoveSubActionParticipant,
  UplaodActionAttachments,
  addAgencySubAction,
  addParticipants,
  changeAgencyActionStatus,
  changeSubactionStatus,
  deleteAgency,
  getActionParticipants,
  getAgencies,
  getAgency,
  getAllUsers,
  getArticles,
  getMeasuresListByArticle,
  getUserById,
  searchParticipants,
} from "../../api";
import { useState } from "react";
import StatusCompo from "./StatusCompo";
import TitleBox from "../../Components/TitleBox/TitleBox";
import MemberCard from "../../Components/MemberCard/MemberCard";
import CustomeModal from "../../Components/Modal/CustomeModal";
import AddParticipantsModal from "../../Components/AddParticipants/AddParticipantModal";
import CommentModal from "../../Components/CommentModal/CommentModal";
import AddAttachmentModal from "../../Components/Add Attchments/AddAttachments";
import SimpleBar from "simplebar-react";
import "simplebar-react/dist/simplebar.min.css";
import { BsPlusCircle, BsDashCircle } from "react-icons/bs";
import { ToastContainer, toast, Zoom } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { BiUpload } from "react-icons/bi";
import VirtualList from "rc-virtual-list";
import PDF from "../../Components/Modal/pdf.png";
import EXLS from "../../Components/Modal/excel.png";
import CSV from "../../Components/Modal/csv.png";
import Search from "antd/es/transfer/search";
import Title from "../../Components/TitleBox/Title";
import Dragger from "antd/es/upload/Dragger";
import FormLayouts from "../DonorsNew/Components/FormLayouts";
import { getUserData } from "../Donors/getCounts";
import { Fragment } from "react";
import { useNavigate } from 'react-router-dom';
import { ActionStatus, NewColorTheme } from "../../appConst";

const { Text } = Typography;
const { Panel } = Collapse;
let userDesignation = "";
let userDashboardName = "";
const NoopIcon = () => null;

function TasksActionPlan({ agency }) {
  const navigate = useNavigate();
  const [form] = Form.useForm();

  const [EditMode, setEditMode] = useState(false);
  const [viewMode, setViewMode] = useState("Action View");

  const [currentArticle, setCurrentArticle] = useState();
  const [currentMeasure, setCurrentMeasure] = useState();
  const [currentAction, setCurrentAction] = useState({});
  const [CurrentSubAction, setCurrentSubAction] = useState({});
  const [agencies, setAgencies] = useState([]);

  const [fileTitle, setTitle] = useState("");
  // get Agency from path
  const [mainDataAray, SetMainData] = useState([]);

  const [openMyModal, setMymodal] = useState();
  const [addParticipantsDialog, SetAddParticipants] = useState(false);

  const [currentActionParticipants, setCurrentActionParticipants] = useState(
    []
  );
  const [otherActionParticipants, setOtherActionParticipants] = useState([]);

  const [loadingMembers, setLoadMembers] = useState(false);
  const [users, setUsers] = useState([]);

  const [actionType, setActionType] = useState(null);

  const [AgencyName, setAgency] = useState("");

  const [userSearching, setSearch] = useState(false);

  const [Addcomments, SetAddComments] = useState(false);
  const [AddFiles, SetFilesModel] = useState(false);
  const [fileLoading, setFileLoading] = useState(false);

  const [curentActionMembers, setCurrentActionMembers] = useState([]);

  const [selectedAgency, setSelectedAgency] = useState();
  const [user, setUser] = useState({});
  const [userLevel, setUserLevel] = useState();
  const [currentAgency, setCurrentAgency] = useState();
  const [defaultAgency, setDefaultAgency] = useState({});
  const [ntfc, setNTFCMember] = useState(false);
  const [warningDialog, setWarningDialog] = useState(false);
  const [fileTitleDisable, setFileTitleDisable] = useState(false);
  const [warningData, setWarningData] = useState();
  const [FileTitleError, setFileTitleError] = useState(false)
  const [loading, setLoading] = useState(true)
  const [articles, setArticles] = useState([]);
  const [selectedArticle, setSelectedArticle] = useState(null);
  const [selectedMeasure, setSelectedMeasure] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [measures, setMeasures] = useState([]);

  const [initialDataArry, setInitialData] = useState([]);
  const [filters, setFilters] = useState({
    "article": null,
    "measure": null,
    "category": null,
    "status": null
  });

  const [filtered, setFiltered] = useState(false);

  const changeView = (e) => {
    setViewMode(e);
  };

  useMemo(() => {
    if (window.localStorage.getItem('ntfc_level') == 0) {
      window.location.href = "/presidentdashbaord";
    }
  }, []);

  useEffect(() => {
    LoadData();
    getArticles().then((art) => {
      setArticles(art.data.out.sort((a, b) => (a.article_no - b.article_no)));
    })
  }, []);

  useEffect(() => {
    filterData(null, null);
  }, [initialDataArry])

  useEffect(() => {
    if (!selectedArticle) {
      setMeasures([]);
      setSelectedMeasure(null);
    } else {
      setMeasurestoAutocomplete(selectedArticle);
    }
    filterData('article', selectedArticle);
  }, [selectedArticle])
  useEffect(() => {
    filterData('measure', selectedMeasure);
  }, [selectedMeasure])
  useEffect(() => {
    filterData('category', selectedCategory);
  }, [selectedCategory])
  useEffect(() => {
    filterData('status', selectedStatus);
  }, [selectedStatus])

  const LoadData = async function () {
    setUserLevel(await window.localStorage.getItem("ntfc_level"));
    console.log("u_level", await window.localStorage.getItem("ntfc_level"));

    setUser(user);
    if (
      (await window.localStorage.getItem("ntfc_level")) == 7 ||
      (await window.localStorage.getItem("ntfc_level")) == 6
    ) {
      getAgencies().then((out) => {
        setNTFCMember(true);
        if (!selectedAgency) {
          setAgency(`- ${out.data.out[0].agency_name}`);
          setCurrentAgency(out.data.out[0].value);
          AgencyActions(out.data.out[0].value);
          setSelectedAgency(
            out.data.out.filter((element) => element.agency_type == 1)[0].label
          );
          form.setFieldValue("agency", out.data.out.filter((element) => element.agency_type == 1)[0].label)
          const sortedArray = out.data.out.concat().slice(2).sort((a, b) => a.label.localeCompare(b.label));
          const resultArray = out.data.out.slice(0, 2).concat(sortedArray);
          setAgencies(
            resultArray.filter((element) => element.agency_type == 1)
          );
        }
      });
    } else {
      getAgency(await window.localStorage.getItem("ntfc_agency"), {}).then(
        (out) => {
          if (out && out.data && out.data.out && out.data.out.length > 0) {
            setAgency(`${out.data.out[0].agency_name}`);
            setCurrentAgency(localStorage.ntfc_agency);
            AgencyActions(localStorage.ntfc_agency);
          }
        }
      );
    }
  };
  const AgencyActions = async (agency) => {
    setLoading(true)
    const out = await GetAgencyWiseAllArticles(agency);
    if (out.status === 200) {

      const updatedMainDataArray = out.data.out.map((element) => {
        const updatedMeasures = element.measures.map((measure) => {
          const updatedActions = measure.actions.map((action) => {
            return { ...action, active_key: [] };
          });
          return { ...measure, actions: updatedActions };
        });
        return { ...element, measures: updatedMeasures };
      });

      if (param1) {
        setInitialData(updatedMainDataArray.filter(el => param1 == el.article_id))
      } else {
        setInitialData(updatedMainDataArray);
      }
      console.log("filter data", updatedMainDataArray)
      setLoading(false)

    } else {
      console.log("data loading falid");
    }
  };

  const filterBy = () => {
    let filteredArr = initialDataArry;
    console.log("FILTERING", filteredArr, initialDataArry);
    if (filters.article) {
      filteredArr = filteredArr.filter(item => item.article_id == filters.article);
    }
    if (filters.measure) {
      for (let i = 0; i < filteredArr.length; i++) {
        for (let j = 0; j < filteredArr[i].measures.length; j++) {
          if (filteredArr[i].measures[j].measure_id == filters.measure) {
            filteredArr[i].measures = [filteredArr[i].measures[j]];
          }
        }
      }
    }
    if (filters.category) {
      filteredArr = filteredArr.filter(item => item.measures && item.measures.findIndex(m => m.category == filters.category) > -1);
    }
    if (filters.status) {
      filteredArr = filterActionsByStatus(filteredArr, filters.status);
    }
    SetMainData(filteredArr);
  }

  const filterData = (params, val) => {
    // if (val) {
    let datArray = initialDataArry;
    if ((params == "article" && val) || selectedArticle) {
      datArray = getfilterData(params == "article" ? val : selectedArticle);
    }
    if ((params == "measure" && val) || selectedMeasure) {
      datArray = getfilterData(selectedArticle, params == "measure" ? val : selectedMeasure);
    }
    if ((params == "category" && val) || selectedCategory) {
      // form.resetFields(['article', 'measure'])
      setFiltered(true);
      datArray = datArray.map((item) => ({
        ...item,
        measures: item.measures.filter((measure) => params == "category" ? measure.category === val : measure.category === selectedCategory),
      }))
        .filter((item) => item.measures.length > 0);
    }
    if ((params == "status" && val) || selectedStatus == 0 || selectedStatus) {
      // form.resetFields(['article', 'measure', 'category'])
      setFiltered(true);
      datArray = filterActionsByStatus(datArray, params == "status" ? val : selectedStatus);
    }

    if ((selectedArticle || selectedArticle == 0 || selectedMeasure || selectedMeasure == 0
      || selectedCategory || selectedCategory == 0 || selectedStatus || selectedStatus == 0)) {
      setFiltered(true);
    } else {
      setFiltered(false);
    }

    SetMainData(datArray);

    // } else {
    //   SetMainData(initialDataArry)
    //   form.resetFields()
    //   setFiltered(false);
    // }
    console.log(initialDataArry.filter(item =>
      item.measures.some(measure => measure.category === val)
    ))
  }

  useEffect(() => {
    filterBy();
  }, [filters])

  function getfilterData(article_id, measure_id, action_id) {
    // Filter by article_id
    let filteredData = initialDataArry.filter(item => item.article_id == article_id);
    console.log('Filtered -Data', filteredData)
    // Filter by measure_id if provided
    if (measure_id !== undefined) {
      filteredData = filteredData.map(item => ({
        ...item,
        measures: item.measures.filter(measure => measure.measure_id == measure_id)
      }));
    }

    // Filter by action_id if provided
    if (action_id !== undefined) {
      filteredData = filteredData.map(item => ({
        ...item,
        measures: item.measures.map(measure => ({
          ...measure,
          actions: measure.actions.filter(action => action.action_id == action_id)
        }))
      }));
    }

    return filteredData;
  }
  function filterActionsByStatus(arr, status) {
    return arr.map((item) => {
      const filteredMeasures = item.measures.map((measure) => {
        const filteredActions = measure.actions.filter((action) => action.action_status === status);
        return { ...measure, actions: filteredActions };
      }).filter((item) => item.actions.length > 0);
      return { ...item, measures: filteredMeasures };
    }).filter((item) => item.measures.length > 0);
  }

  const setMeasurestoAutocomplete = (articleNo) => {
    getMeasuresListByArticle(articleNo, {}).then((mes) => {
      const filteredArticle = ((articles.filter((itm) => itm.value === articleNo))[0])?.article_no;
      if (mes.status == 200) {
        setMeasures(mes.data.out.map((el) => {
          return {
            value: el.id,
            label: `${filteredArticle}.${el.measure_no} - ${el.summary}`
          }
        }))
      }
    })
  }

  const LoadMembers = async (action) => {
    setLoadMembers(true);

    let allusers = [];


    // get all Participants
    await getAllUsers(action.agency_id).then((out) => {
      allusers = out.data.out;

      setUsers(out.data.out);
    });

    for (let i = 0; i < allusers.length; i++) {
      for (let j = 0; j < action.participants.length; j++) {
        if (allusers[i].uuid === action.participants[j].user_id) {
          allusers[i].matched = true;
          break;
        }
      }
    }

    console.log("all users", allusers);
    setUsers(allusers);
    setLoadMembers(false);
  };
  const onSearch = async (value) => {
    setSearch(false);
    let out = await searchParticipants(currentAction.agency_id, value);


    if (out.status === 200) {
      for (let i = 0; i < out.data.out.length; i++) {
        for (let j = 0; j < currentAction.participants.length; j++) {
          if (out.data.out[i].uuid === currentAction.participants[j].user_id) {
            out.data.out[i].matched = true;
            break;
          }
        }
      }
      setUsers(out.data.out.sort((a, b) => {
        if (a.matched === b.matched) {
          return 0;
        } else if (a.matched) {
          return 1;
        } else {
          return -1;
        }
      }));
    }
    // LoadMembers(currentAction);
    setSearch(true);
  };

  const addNewParticipant = async (user) => {
    let data = {
      agency_id: currentAction.agency_id,
      action_id: currentAction.action_id,
      participant: user.uuid,
    };
    let out;
    if (actionType === "Sub") {
      data.subaction_id = CurrentSubAction.id;
      out = await AddSubActionParticipant(data);
    } else {
      out = await AddActionParticipant(data);
    }

    if (out) {
      if (out.status === 200) {
        toast.success(out.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        AgencyActions(currentAgency);
      } else {
        toast.error(out.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    } else {
      toast.error("Internal Server Error", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }

    SetAddParticipants(false);
  };

  const removeParticipant = async (user) => {
    console.log("User", user);

    let data = {
      agency_id: currentAction.agency_id,
      action_id: currentAction.action_id,
    };
    let out;
    if (actionType === "Sub") {
      data.subaction_id = CurrentSubAction.id;
      data.participant = user.user_id;
      out = await RemoveSubActionParticipant(data);
    } else {
      data.participant = user.user_id;
      out = await RemoveActionParticipant(data);
    }
    if (out) {
      if (out.status === 200) {
        toast.success(out.data.message, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        AgencyActions(currentAgency);
      } else {
        toast.error(out.data.message,
          {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
      }
    } else {
      toast.error("Internal Server Error", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }

    SetAddParticipants(false);
  };
  const [sendData2, setSendData] = useState();

  const changeStatus = (stat, action, subAction, article, measure) => {
    let obj = {};
    obj.agency_id = action.agency_id;
    obj.new_status = stat;
    obj.article_id = measure.article_id;
    obj.measure_id = measure.measure_id;
    obj.action_id = action.action_id;

    if (subAction) {
      let sendData = {
        id: subAction.id,
        status: stat,
        action_id: action.action_id,
      };
      if (stat == subAction.status) {
        toast.error("Cannot change status to the same status", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        AgencyActions(currentAgency);
      } else {
        console.log("sendData -------->", sendData);
        setSendData(sendData);
        if (action.action_status == 0) {
          let warningMsg = {};
          warningMsg.subActionStatus = stat;
          warningMsg.actionName =
            action.article_no +
            "." +
            action.measure_no +
            "." +
            action.action_no +
            ". " +
            action.action;
          warningMsg.subAction =
            action.article_no +
            "." +
            action.measure_no +
            "." +
            action.action_no +
            "." +
            subAction.id +
            ". " +
            subAction.subaction;
          let filteredSubActions = action.subactions.filter(
            (element) => element.id !== subAction.id
          );

          if (stat === 2) {
            // Checking the other subctions status is complete or not
            const allStatusOne = filteredSubActions.every(
              (obj) => obj.status === 2
            );
            if (allStatusOne) {
              warningMsg.actionStatus = "Complete";
              warningMsg.act = 2;
            } else {
              warningMsg.actionStatus = "Inprogress";
              warningMsg.act = 1;
            }
          } else if (stat == 1) {
            warningMsg.actionStatus = "Inprogress";
            warningMsg.act = 1;
          }
          console.log("action Data", action);
          setWarningData(warningMsg);
          setWarningDialog(true);
          // toast.warning("Reminder! You are changing the sub action status, But the action is still in todo status. Please change your action status into inprogress and submit for approval",
          //     {
          //         position: "top-right",
          //         autoClose: 5000,
          //         hideProgressBar: false,
          //         closeOnClick: true,
          //         pauseOnHover: true,
          //         draggable: true,
          //         progress: undefined,
          //         theme: "colored",
          //     })
        } else
          changeSubactionStatus(sendData)
            .then((out) => {
              console.log("out -------->", out);
              if (out.data.status === 200) {
                toast.success(out.data.message, {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: "colored",
                });
                AgencyActions(currentAgency);
              } else {
                toast.error(out.data.message, {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: "colored",
                });
              }
            })
            .catch((error) => {
              console.log("error -------->", error);
            });
      }
    } else {
      if (stat == action.action_status) {
        toast.error("Cannot change status to same status", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      } else {
        changeAgencyActionStatus(obj)
          .then((out) => {
            console.log("Sttst out", out);
            if (out) {
              if (out.data.status === 200) {
                toast.success("Successfully requested the status change.", {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: "colored",
                });
              } else {
                toast.error(out.data.message, {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: "colored",
                });
              }
            } else {
              toast.error(
                "In order to complete the action, it is necessary to complete all sub-actions.",
                {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: "colored",
                }
              );
            }

            AgencyActions(currentAgency);
          })
          .catch((error) => {
            console.log("error", error);
          });
      }
    }
  };

  const dialogUpdateRequest = async () => {
    changeSubactionStatus(sendData2)
      .then((out) => {
        if (out.data.status === 200) {
          toast.success(out.data.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          AgencyActions(currentAgency);
        } else {
          toast.error(out.data.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        }
      })
      .catch((error) => {
        console.log("error -------->", error);
      });
  };

  const getStatus = (stat) => {
    if (stat == 0) {
      return "To Do";
    } else if (stat == 1) {
      return "Inprogress";
    } else if (stat == 2) {
      return "Complete";
    } else if (stat == 3) {
      return "Delayed";
    } else {
      return "Missed";
    }
  };

  const getColorStatus = (stat) => {
    if (stat == 0) {
      return "#8E8E8E";
    } else if (stat == 1) {
      return "#FDAA3D";
    } else if (stat == 2) {
      return "#00C874";
    } else if (stat == 3) {
      return "#E2445C";
    } else {
      return "#ff8282";
    }
  };

  const DownloadFiles2 = async (file) => {
    console.log("file---------", file);

    let out = await DownloadFiles(file.token);
    console.log("FIle", out);

    // Set the Content-Type header for known file types
    let contentType;
    switch (file.url.split(".").pop()) {
      case "pdf":
        contentType = "application/pdf";
        break;
      case "jpg":
      case "jpeg":
        contentType = "image/jpeg";
        break;
      case "png":
        contentType = "image/png";
        break;
      case "xlsx":
        contentType =
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
        break;
      case "doc":
      case "docx":
        contentType = "application/msword";
        break;
      default:
        contentType = "text/plain";
        break;
    }

    // Create a URL object from the file data with the Content-Type header
    let blob = new Blob([out], { type: contentType });
    let url = window.URL.createObjectURL(blob);

    // Create a link element
    let link = document.createElement("a");
    link.href = url;

    // Set the download attribute and file name
    link.download = file.title;

    // Append the link element to the document
    document.body.appendChild(link);

    // Trigger a click event on the link element
    link.click();
  };

  function getImageUrl(file) {
    let url;
    switch (file.url.split(".").pop()) {
      case "pdf":
        url = PDF;
        break;
      case "jpg":
      case "jpeg":
        url = "image/jpeg";
        break;
      case "png":
        url = "image/png";
        break;
      case "xlsx":
        url = EXLS;
        break;
      case "csv":
        url = CSV;
        break;
      case "doc":
      case "docx":
        url = "application/msword";
        break;
      default:
        url = "text/plain";
        break;
    }

    return url;
  }
  const [activeKey, setActiveKey] = useState([]);

  function getColor(percentage) {
    if (percentage <= 25) {
      return "#aed8e6";
    }
    if (percentage > 25 && percentage <= 85) {
      return "#0492c2";
    }
    if (percentage > 100) {
      return "#0492c2";
    }
  }

  const [commentAdding, setCommentLoad] = useState(false);
  const [comment, setComment] = useState("");

  const AddNewComment = async (value) => {
    let ntfc_agency = await window.localStorage.getItem("ntfc_agency");
    setCommentLoad(true);

    let sendData = {
      agency_id: parseInt(ntfc_agency),
      action_id: currentAction.action_id,
      comment: value.comment,
    };

    console.log("Atype", actionType);

    let out;
    if (actionType === "Sub") {
      console.log("sAtype......");
      sendData.subaction_id = CurrentSubAction.id;
      out = await AddAgencySubActionComment(sendData);
    } else {
      console.log("Atype......");
      out = await AddAgencyActionComment(sendData);
    }

    if (out.data.status === 200) {
      toast.success(out.data.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      AgencyActions(currentAgency);
      form.resetFields();
      form.setFieldValue("comment", "");
      setComment("");
    } else {
      toast.error(out.data.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
    setCommentLoad(false);

    SetAddComments(false);
  };
  const props = {
    name: "file",
    multiple: false,
    // action: ,

    onChange(info) {
      const { status } = info.file;
      if (status !== "uploading") {
        console.log(info.file, info.fileList);
      }
      if (status === "done") {
        toast.success(`${info.file.name} file uploaded successfully.`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        handleFileUpload(info.file);
      } else if (status === "error") {
        toast.error(`${info.file.name} file upload failed.`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    },
    onDrop(e) {
      console.log("Dropped files", e.dataTransfer.files);
    },
  };
  const [fileList, setFileList] = useState([]);
  async function handleFileUpload(file) {
    setFileLoading(true);

    const formData = new FormData();
    formData.append("action_file", file);
    formData.append("title", fileTitle);
    formData.append("agency", currentAction.agency_id);
    formData.append("action", currentAction.action_id);

    let out;
    if (actionType === "Sub") {
      formData.append("subaction", CurrentSubAction.id);
      out = await AddSubActionFiles(formData);
    } else {
      out = await UplaodActionAttachments(formData);
    }

    if (out.data.status === 200) {
      setFileLoading(false);

      toast.success(out.data.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      AgencyActions(currentAgency);
      setFileList([]);
      setTitle("");
    } else {
      toast.error(out.data.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
    SetFilesModel(false);
    setFileTitleDisable(false);
    // setUploadProgress(0);
  }

  const handleClose = () => {
    setMymodal(false);
    setActionType(null);
    LoadData();
  };

  const icon =
    activeKey.length > 0 ? <ArrowUpOutlined /> : <ArrowDownOutlined />;

  var url = new URL(window.location.href);
  var param1 = url.searchParams.get("param1");
  var param2 = url.searchParams.get("param2");
  var param3 = url.searchParams.get("param3");

  const MeasureRow = ({ element, key, measures, m }) => (
    <Collapse
      collapsible={openMyModal ? false : true}
      key={m}
      ghost={true}
      defaultActiveKey={["1"]}
      size="small"
      bordered={true}
      expandIcon={({ isActive }) => (
        <DoubleRightOutlined
          style={{
            fontSize: 12,
            color: "black",
          }}
          rotate={isActive ? 90 : 0}
        />
      )}
    >
      <Panel
        header={
          <Row
            justify="start"
            align="middle"
            gutter={16}
          >
            <Col span={12}>
              <div className="tblRaw2">
                <Badge.Ribbon
                  text={
                    <span>
                      Measure -{" "}
                      <i
                        style={{
                          color:
                            "#c7c5c5",
                        }}
                      >
                        Actions (
                        {
                          measures.actions
                            .length
                        }
                        )
                      </i>
                    </span>
                  }
                  placement="start"
                  style={{
                    marginTop: -30,
                    marginLeft: -5,
                  }}
                  color="#2C31A0"
                  size="small"
                >
                  <div
                    style={{
                      display: "flex",
                      paddingLeft: 10,
                    }}
                  >
                    <div className="tbl1RawNo">
                      <Typography.Text className="measure-row-title-no">
                        {" "}
                        {element.article_no +
                          ". " +
                          measures.measure_no +
                          ".  "}{" "}
                      </Typography.Text>
                    </div>
                    <div className="tbl1RawContent">
                      <Typography.Text className="measure-row-title">
                        {" "}
                        {" " +
                          measures.measure_name}
                        {/* <Tag color={
                                                measures.category === "C" ? "#2db7f5" : measures.category === "B" ? "cyan" : "purple"

                                            }>{measures.category}</Tag>

                                                {measures.measure_def_date &&
                                                    <Tag color="#87d068">
                                                        {moment(measures.measure_def_date).format('DD/MM/YYYY')}
                                                    </Tag>
                                                } */}
                      </Typography.Text>
                    </div>
                  </div>
                </Badge.Ribbon>
              </div>
            </Col>
            <Col
              className="mob-hide"
              span={3}
              style={{
                display: "flex",
                justifyContent: "start",
                marginLeft: -19
              }}
            >
              {/* <Progress
                                type="circle"
                                strokeColor={getColor(measures.measure_completion)}
                                // trailColor={getColor(action.action_completion)}
                                percent={measures.measure_completion.toFixed(0)}
                                strokeWidth={10}
                                size={50}
                            // format={(number) => `进行中，已完成${number}%`}
                            /> */}
              {/* {!filtered ?  */}
              <Progress
                type="circle"
                strokeColor={getColor(
                  measures.measure_completion
                )}
                // trailColor={getColor(action.action_completion)}
                percent={measures.measure_completion?.toFixed()}
                strokeWidth={10}
                size={50}
                format={(percent) =>
                  `${percent}%`
                }
              // format={(number) => `进行中，已完成${number}%`}
              />
              {/* : null} */}
            </Col>
            {/* <Col span={4}> */}
            {/* <Progress type="circle" percent={30} size={20}/>
                        <div className="progressContainer-1">


                            <div className="statusBtn">
                                <StatusCompo status={measures.measure_completion} action={measures} setStatus={() => changeStatus()} />
                            </div>
                        </div>
                    </Col> */}
            <Col
              className="mob-hide"
              span={1}
              style={{
                display: "flex",
                justifyContent: "start",
              }}
            ></Col>

            <Col
              className="mob-hide"
              span={2}
            ></Col>
            <Col
              className="mob-hide"
              span={1}
            ></Col>
            <Col
              className="mob-hide"
              span={2}
            ></Col>
            <Col
              className="mob-hide action-task-category"
              span={1}
              onClick={(e) => {
                e.stopPropagation()
              }}

              style={{
                display: "flex",
                justifyContent: "start",
                marginLeft: -30,
                cursor: "default"
              }}
            >
              {measures.category ===
                "A" ? (
                <Tag>A</Tag>
              ) : measures.category ===
                "B" ? (
                <Tag color="#2db7f5">
                  B
                </Tag>
              ) : (
                <Tag color="#108ee9">
                  C
                </Tag>
              )}
            </Col>

            <Col
              className="mob-hide meassure-def-date"
              span={2}
              onClick={(e) => {
                e.stopPropagation()
              }}
              style={{ cursor: "default" }}

            >

              {measures.extended_def_date ?
                <Badge.Ribbon text="Extended"
                  // color="#a02c4c"
                  size="small" placement="start"
                  style={{
                    marginTop: -27,
                    marginLeft: 1,
                  }}
                >
                  <Tag color={NewColorTheme.primaryColor}>

                    <Typography.Text
                      style={{
                        fontSize: 12,
                        color: "white",
                      }}
                    >
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        {
                          moment(
                            measures.extended_def_date
                          ).format(
                            "DD/MM/YYYY"
                          )
                        }

                      </div>

                    </Typography.Text>
                  </Tag>
                </Badge.Ribbon> :
                <Tag color={NewColorTheme.primaryColor}>

                  <Typography.Text
                    style={{
                      fontSize: 12,
                      color: "white",
                    }}
                  >

                    {measures.measure_def_date
                      ? moment(
                        measures.measure_def_date
                      ).format(
                        "DD/MM/YYYY"
                      )
                      : ""}
                  </Typography.Text>
                </Tag>
              }

            </Col>
          </Row>
        }
        key="1"
      >
        <div className="action-row">
          {/*  */}
          <Space
            direction="vertical"
            style={{ width: "100%" }}
          >
            {measures.actions &&
              measures.actions.map(
                (action, a) => {
                  return (
                    <Collapse
                      // activeKey={mainDataAray[key].measures[m].actions[a].active_key}
                      // expandIconPosition="left"
                      key={a}
                      ghost={true}
                      size="small"
                      expandIcon={({
                        isActive,
                      }) => (
                        <NoopIcon
                          style={{
                            fontSize: 12,
                            color:
                              "black",
                          }}
                          rotate={
                            isActive
                              ? 90
                              : 0
                          }
                        />
                      )}
                    >
                      <Panel
                        style={{
                          transition:
                            "all 0.5s ease-in-out",
                        }}
                        header={
                          <Row
                            onClick={() => {
                              // setEditMode(false)
                              // setMymodal(true)
                              // setCurrentAction(action)
                              // setCurrentArticle(element)
                              // setCurrentMeasure(measures)
                            }}
                            key={a}
                            justify="start"
                            align="middle"
                            gutter={16}
                          >
                            <Col
                              span={12}
                            >
                              <div>
                                <NoopIcon
                                  style={{
                                    fontSize: 12,
                                    color:
                                      "black",
                                  }}
                                />
                              </div>

                              <div
                                className="tblRaw3"
                                onClick={() => {
                                  // setEditMode(false)
                                  // setMymodal(true)
                                  // setCurrentAction(action)
                                  // setCurrentArticle(element)
                                  // setCurrentMeasure(measures)
                                }}
                              >
                                <Badge.Ribbon
                                  text={
                                    <span>
                                      Action
                                      -{" "}
                                      <i
                                        style={{
                                          color:
                                            "#c7c5c5",
                                        }}
                                      >
                                        Sub
                                        Actions
                                        (
                                        {
                                          action
                                            .subactions
                                            .length
                                        }
                                        )
                                      </i>
                                    </span>
                                  }
                                  placement="start"
                                  style={{
                                    marginTop:
                                      -30,
                                    marginLeft:
                                      -5,
                                  }}
                                  color="#3339BB"
                                  size="small"
                                >
                                  <div
                                    style={{
                                      display:
                                        "flex",
                                      paddingLeft: 15,
                                      justifyContent:
                                        "space-between",
                                      width:
                                        "100%",
                                    }}
                                  >
                                    <Typography.Text
                                      className="sub-row-title"
                                      onClick={() =>
                                        console.log(
                                          "Clicked"
                                        )
                                      }
                                    >
                                      {element.article_no +
                                        ". " +
                                        measures.measure_no +
                                        ". " +
                                        action.action_no +
                                        ". " +
                                        (action
                                          .action_summary
                                          .length >
                                          70
                                          ? action.action_summary.substring(
                                            0,
                                            80
                                          ) +
                                          "..."
                                          : action.action_summary)}
                                    </Typography.Text>
                                  </div>
                                </Badge.Ribbon>
                                <div
                                  style={{
                                    display:
                                      "flex",
                                    gap: 10,
                                  }}
                                >
                                  {action.action_status !==
                                    2 &&
                                    ntfc ===
                                    false &&
                                    action.action_status !==
                                    4 && (
                                      <Tooltip
                                        title="Add New Sub Action"
                                        color="green"
                                      >
                                        <PlusOutlined
                                          onClick={(
                                            e
                                          ) => {
                                            e.stopPropagation();
                                            setEditMode(
                                              false
                                            );
                                            setViewMode(
                                              "Add New Sub Action"
                                            );
                                            setCurrentActionMembers(
                                              []
                                            );
                                            setCurrentActionParticipants(
                                              []
                                            );
                                            setMymodal(
                                              true
                                            );
                                            setCurrentAction(
                                              action
                                            );
                                            setCurrentArticle(
                                              element
                                            );
                                            setCurrentMeasure(
                                              measures
                                            );
                                            // handleClick(key, m, a);
                                          }}
                                        />
                                      </Tooltip>
                                    )}

                                  <Tooltip
                                    title="View Action"
                                    color="magenta"
                                  >
                                    <EyeOutlined
                                      onClick={(
                                        e
                                      ) => {
                                        e.stopPropagation();
                                        setEditMode(
                                          false
                                        );
                                        setViewMode(
                                          "Action View"
                                        );
                                        setMymodal(
                                          true
                                        );
                                        setCurrentAction(
                                          action
                                        );
                                        setCurrentArticle(
                                          element
                                        );
                                        setCurrentMeasure(
                                          measures
                                        );
                                        // handleClick(key, m, a);
                                      }}
                                    />
                                  </Tooltip>
                                </div>
                              </div>
                            </Col>
                            <Col
                              className="mob-hide action-task-progress"
                              onClick={(e) => {
                                e.stopPropagation()
                              }}
                              span={2}
                              style={{
                                paddingRight:
                                  "8px",
                                display:
                                  "flex",
                                justifyContent:
                                  "start",
                                paddingLeft:
                                  "0",
                                marginLeft:
                                  "-35px",
                                cursor: "default"
                              }}
                            >
                              {/* {!filtered ?  */}
                              <Progress
                                type="circle"
                                strokeColor={getColor(
                                  action.action_completion
                                )}
                                // trailColor={getColor(action.action_completion)}
                                percent={action.action_completion?.toFixed()}
                                strokeWidth={
                                  10
                                }
                                size={50}
                                format={(
                                  percent
                                ) =>
                                  `${percent}%`
                                }
                              // format={(number) => `进行中，已完成${number}%`}
                              />
                              {/* : null} */}
                            </Col>
                            <Col
                              className="mob-hide action-weightage"
                              onClick={(e) => {
                                e.stopPropagation()
                              }}
                              span={2}
                              style={{
                                display:
                                  "flex",
                                justifyContent:
                                  "start",
                                cursor: "default"
                              }}
                            >
                              <Avatar
                                style={{
                                  backgroundColor:
                                    "rgb(196 241 241)",
                                  color:
                                    "rgb(30 80 80)",
                                }}
                              >
                                <strong>
                                  {" "}
                                  {
                                    action.weightage
                                  }
                                </strong>
                              </Avatar>
                            </Col>
                            <Col
                              className="mob-hide"
                              span={1}
                              style={{
                                display:
                                  "flex",
                                justifyContent:
                                  "start",
                              }}
                            ></Col>

                            <Col
                              className="mob-hide action-task-status"
                              onClick={(e) => {
                                e.stopPropagation()
                              }}
                              span={2}
                              style={{
                                paddingLeft:
                                  "8px",
                                paddingRight:
                                  "8px",
                                marginLeft:
                                  "-90px",
                                marginRight:
                                  "23px",
                                cursor: "default"
                              }}
                            >
                              <div
                                className="progressContainer"
                                onClick={(
                                  e
                                ) => {
                                  e.stopPropagation();
                                }}
                              >
                                <div className="statusBtn">
                                  <StatusCompo
                                    disabled={
                                      action.action_status ===
                                        2 ||
                                        ntfc ===
                                        true
                                        ? true
                                        : false
                                    }
                                    status={
                                      action.action_status
                                    }
                                    action={
                                      action
                                    }
                                    setStatus={
                                      changeStatus
                                    }
                                    measure={
                                      measures
                                    }
                                  />
                                </div>
                              </div>
                            </Col>
                            <Col
                              className="mob-hide"
                              onClick={(e) => {
                                e.stopPropagation()
                              }}
                              span={3}
                              style={{
                                marginRight: 13,
                                cursor: action.action_status !=
                                  2 ? "cursor" : "default",
                                paddingRight: '16px',
                                paddingLeft: '9px'
                              }}
                            >
                              <div className="assigneeContainer" onClick={(e) => {
                                e.stopPropagation();
                              }}>
                                <div className="memberContainer">
                                  <div
                                    className="membersRow"
                                    style={{
                                      paddingLeft:
                                        "40px !important",
                                    }}
                                  >
                                    <Badge
                                      count={ntfc ? 0 :
                                        action
                                          .participants
                                          .length
                                      }
                                    >
                                      <Avatar
                                        style={{
                                          backgroundColor: (ntfc || action.action_status ==
                                            2) ? null :
                                            "#fde3cf",
                                          color: ntfc || (action.action_status ==
                                            2) ? null :
                                            "#f56a00",
                                        }}
                                        icon={
                                          <UserOutlined />
                                        }
                                        onClick={(
                                          e
                                        ) => {
                                          console.log(
                                            "NTFC===============",
                                            ntfc
                                          );
                                          if (
                                            action.action_status !==
                                            2 &&
                                            !ntfc
                                          ) {
                                            setActionType(
                                              null
                                            );
                                            e.stopPropagation();
                                            setCurrentAction(
                                              action
                                            );
                                            setCurrentActionMembers(
                                              action.participants
                                            );
                                            LoadMembers(
                                              action
                                            );
                                            SetAddParticipants(
                                              true
                                            );

                                            // getUsers("", action)
                                          } else {
                                            if (!ntfc) {
                                              toast.error(
                                                "You cannot add participants for an action that has already been completed.",
                                                {
                                                  position:
                                                    "top-right",
                                                  autoClose: 5000,
                                                  hideProgressBar: false,
                                                  closeOnClick: true,
                                                  pauseOnHover: true,
                                                  draggable: true,
                                                  progress:
                                                    undefined,
                                                  theme:
                                                    "colored",
                                                }
                                              );
                                            }

                                          }
                                        }}
                                      />
                                    </Badge>
                                  </div>
                                  {/* Adding Participants */}
                                  {/* <AddParticipantsModal action={action} actionType="Main" /> */}
                                </div>
                                <div className="memberContainer" onClick={(e) => {
                                  e.stopPropagation();
                                }}>
                                  <Badge
                                    count={ntfc ? 0 :
                                      action.comments
                                    }
                                  >
                                    <Avatar
                                      style={{
                                        backgroundColor: (ntfc || action.action_status ==
                                          2) ? null :
                                          "#078b8b",
                                        color: (ntfc || action.action_status ==
                                          2) ? null :
                                          "white",
                                      }}
                                      icon={
                                        <CommentOutlined />
                                      }
                                      onClick={(
                                        e
                                      ) => {
                                        if (
                                          action.action_status !==
                                          2 &&
                                          !ntfc
                                        ) {
                                          setActionType(
                                            null
                                          );
                                          e.stopPropagation();
                                          setCurrentAction(
                                            action
                                          );
                                          SetAddComments(
                                            true
                                          );
                                        } else {
                                          if (!ntfc) {
                                            toast.error(
                                              "You cannot add comments for an action that has already been completed.",
                                              {
                                                position:
                                                  "top-right",
                                                autoClose: 5000,
                                                hideProgressBar: false,
                                                closeOnClick: true,
                                                pauseOnHover: true,
                                                draggable: true,
                                                progress:
                                                  undefined,
                                                theme:
                                                  "colored",
                                              }
                                            );
                                          }

                                        }
                                      }}
                                    />
                                  </Badge>
                                </div>
                                <div className="attachments" onClick={(e) => {
                                  e.stopPropagation();
                                }}>
                                  <Badge

                                    count={ntfc ? 0 :
                                      action.files
                                    }
                                  >
                                    <Avatar
                                      style={{
                                        backgroundColor: (ntfc) ? null :
                                          "#5AC724",
                                        color: (ntfc) ? null :
                                          "white",
                                      }}
                                      icon={
                                        <FileAddOutlined />
                                      }
                                      onClick={(
                                        e
                                      ) => {
                                        if (
                                          ntfc ===
                                          false
                                        ) {
                                          e.stopPropagation();
                                          setActionType(
                                            null
                                          );
                                          setCurrentAction(
                                            action
                                          );
                                          setFileTitleError(false)
                                          SetFilesModel(
                                            true
                                          );
                                          setTitle("")
                                        } else {
                                          if (!ntfc) {
                                            toast.error(
                                              "You cannot add files for an action that has already been completed.",
                                              {
                                                position:
                                                  "top-right",
                                                autoClose: 5000,
                                                hideProgressBar: false,
                                                closeOnClick: true,
                                                pauseOnHover: true,
                                                draggable: true,
                                                progress:
                                                  undefined,
                                                theme:
                                                  "colored",
                                              }
                                            );
                                          }

                                        }
                                      }}
                                    />
                                  </Badge>
                                </div>
                              </div>
                            </Col>
                            <Col
                              onClick={(e) => {
                                e.stopPropagation();
                              }}
                              className="mob-hide"
                              span={1}
                            ></Col>

                            <Col
                              onClick={(e) => {
                                e.stopPropagation();
                              }}
                              className="mob-hide"
                              span={2}
                              style={{
                                display:
                                  "flex",
                                justifyContent:
                                  "flex-end",
                                cursor: "default"
                              }}
                            >
                              <Tag
                                color="purple"
                                className="action-def-date"
                                style={{
                                  marginRight:
                                    "-76%",
                                }}
                              >
                                {" "}
                                <Typography.Text
                                  style={{
                                    fontSize: 12,
                                  }}
                                >
                                  {" "}
                                  {action.action_def_date
                                    ? moment(
                                      action.action_def_date
                                    ).format(
                                      "DD/MM/YYYY"
                                    )
                                    : measures.measure_def_date
                                      ? moment(
                                        measures.measure_def_date
                                      ).format(
                                        "DD/MM/YYYY"
                                      )
                                      : "-"}
                                </Typography.Text>
                              </Tag>
                            </Col>
                          </Row>
                        }
                        key="1"
                      >
                        <div className="subAction-row">
                          {/*  */}
                          <Space
                            direction="vertical"
                            style={{
                              width:
                                "100%",
                            }}
                          >
                            {action.subactions &&
                              action.subactions.map(
                                (
                                  subAction,
                                  sub
                                ) => {
                                  return (
                                    <>
                                      <Row
                                        key={
                                          a
                                        }
                                        justify="start"
                                        align="middle"
                                        gutter={
                                          16
                                        }
                                      >
                                        <Col
                                          span={
                                            12
                                          }
                                        >
                                          {/* <div style={{ display: "flex", width: "100%", justifyContent: "flex-end", marginTop: -26 }}>
                                                                        <Button style={{
                                                                            position: 'relative',
                                                                            left: '18px',
                                                                            top: '35px'
                                                                        }} shape="circle" icon={icon} />
                                                                    </div> */}
                                          <Tooltip
                                            title={
                                              ntfc
                                                ? "View Sub Action"
                                                : "Edit Sub Action"
                                            }
                                            color={
                                              "#FAAD14"
                                            }
                                          >
                                            <div
                                              className="tblRaw4"
                                              onClick={() => {
                                                if (
                                                  !ntfc
                                                ) {
                                                  setEditMode(
                                                    true
                                                  );
                                                  setViewMode(
                                                    "Edit SubAction"
                                                  );
                                                } else {
                                                  setViewMode(
                                                    "Action View"
                                                  );
                                                }

                                                setCurrentSubAction(
                                                  subAction
                                                );
                                                setCurrentAction(
                                                  action
                                                );
                                                setCurrentArticle(
                                                  element
                                                );
                                                setCurrentMeasure(
                                                  measures
                                                );
                                                setMymodal(
                                                  true
                                                );
                                              }}
                                              style={{
                                                cursor:
                                                  "pointer",
                                              }}
                                            >
                                              <Badge.Ribbon
                                                text="Sub Action"
                                                placement="start"
                                                style={{
                                                  marginTop:
                                                    -30,
                                                  marginLeft:
                                                    -5,
                                                  display:
                                                    sub ===
                                                      0
                                                      ? "flex"
                                                      : "none",
                                                }}
                                                color="#444ACC"
                                                size="small"
                                              >
                                                <Typography.Text className="sub-row-title">
                                                  {element.article_no +
                                                    ". " +
                                                    measures.measure_no +
                                                    ". " +
                                                    action.action_no +
                                                    ". " +
                                                    subAction.id +
                                                    ". " +
                                                    subAction.subaction}
                                                </Typography.Text>
                                              </Badge.Ribbon>
                                              {ntfc ? (
                                                <EyeOutlined />
                                              ) : (
                                                <EditOutlined />
                                              )}
                                            </div>
                                          </Tooltip>
                                        </Col>
                                        {/* <Col span={3} style={{ display: "flex", justifyContent: "start", }}>

                                                                    <Progress
                                                                        type="circle"
                                                                        strokeColor={getColor(subAction.completion)}
                                                                        // trailColor={getColor(subAction.completion)}
                                                                        percent={subAction.completion}
                                                                        strokeWidth={10}
                                                                        size={50}
                                                                    // format={(number) => `进行中，已完成${number}%`}
                                                                    />

                                                                </Col> */}

                                        <Col
                                          className="mob-hide"
                                          span={
                                            4
                                          }
                                        ></Col>
                                        <Col
                                          className="mob-hide sub-action-status"
                                          span={
                                            2
                                          }
                                          style={{
                                            marginLeft:
                                              "-9.9%",
                                          }}
                                        >
                                          <div
                                            className="progressContainer"
                                            onClick={(
                                              e
                                            ) => {
                                              e.stopPropagation();
                                            }}
                                          >
                                            <div className="statusBtn">
                                              <StatusCompo
                                                disabled={
                                                  action.action_status ===
                                                    2 ||
                                                    ntfc
                                                    ? true
                                                    : false
                                                }
                                                status={
                                                  subAction.status
                                                }
                                                action={
                                                  action
                                                }
                                                setStatus={
                                                  changeStatus
                                                }
                                                subAction={
                                                  subAction
                                                }
                                                article={
                                                  element
                                                }
                                                measure={
                                                  measures
                                                }
                                              />
                                            </div>
                                          </div>
                                        </Col>

                                        <Col
                                          className="mob-hide sub-action-action"
                                          span={
                                            3
                                          }
                                          style={{
                                            marginLeft: 28,
                                          }}
                                        >
                                          <div className="assigneeContainer">
                                            <div
                                              className="memberContainer"
                                              style={{
                                                cursor:
                                                  "pointer",
                                              }}
                                            >
                                              <div
                                                className="membersRow"
                                                style={{
                                                  paddingLeft:
                                                    "40px !important",
                                                }}
                                              >
                                                <Badge
                                                  count={ntfc ? 0 :
                                                    subAction
                                                      .participants
                                                      .length
                                                  }
                                                >
                                                  <Avatar
                                                    style={{
                                                      backgroundColor: (ntfc || subAction.status ==
                                                        2) ? null :
                                                        "#fde3cf",
                                                      color: (ntfc || subAction.status ==
                                                        2) ? null :
                                                        "#f56a00",
                                                    }}
                                                    icon={
                                                      <UserOutlined />
                                                    }
                                                    onClick={(
                                                      e
                                                    ) => {
                                                      e.stopPropagation();
                                                      if (
                                                        subAction.status !==
                                                        2
                                                      ) {
                                                        if (
                                                          !ntfc
                                                        ) {
                                                          setActionType(
                                                            "Sub"
                                                          );

                                                          setCurrentAction(
                                                            action
                                                          );
                                                          setCurrentSubAction(
                                                            subAction
                                                          );
                                                          setCurrentActionMembers(
                                                            subAction.participants
                                                          );
                                                          // getUsers("", action)
                                                          LoadMembers(
                                                            subAction
                                                          );
                                                          SetAddParticipants(
                                                            true
                                                          );
                                                        }
                                                      } else {
                                                        if (!ntfc) {
                                                          toast.error(
                                                            "You cannot add participants for sub action that has already been completed",
                                                            {
                                                              position:
                                                                "top-right",
                                                              autoClose: 5000,
                                                              hideProgressBar: false,
                                                              closeOnClick: true,
                                                              pauseOnHover: true,
                                                              draggable: true,
                                                              progress:
                                                                undefined,
                                                              theme:
                                                                "colored",
                                                            }
                                                          );
                                                        }

                                                      }
                                                    }}
                                                  />
                                                </Badge>
                                              </div>
                                            </div>
                                            <div
                                              className="memberContainer"
                                              style={{
                                                cursor:
                                                  "pointer",
                                              }}
                                            >
                                              <Badge
                                                count={ntfc ? 0 :
                                                  subAction.comments
                                                }
                                              >
                                                <Avatar
                                                  style={{
                                                    backgroundColor: (ntfc || subAction.status ==
                                                      2) ? null :
                                                      "#078b8b",
                                                    color: (ntfc || subAction.status ==
                                                      2) ? null :
                                                      "white",
                                                  }}
                                                  icon={
                                                    <CommentOutlined />
                                                  }
                                                  onClick={(
                                                    e
                                                  ) => {
                                                    e.stopPropagation();
                                                    if (
                                                      !ntfc
                                                    ) {
                                                      SetAddComments(
                                                        true
                                                      );
                                                      setCurrentAction(
                                                        action
                                                      );
                                                      setCurrentSubAction(
                                                        subAction
                                                      );
                                                      setActionType(
                                                        "Sub"
                                                      );
                                                    } else {
                                                      if (!ntfc) {
                                                        toast.error(
                                                          "You cannot add comments for sub action that has already been completed",
                                                          {
                                                            position:
                                                              "top-right",
                                                            autoClose: 5000,
                                                            hideProgressBar: false,
                                                            closeOnClick: true,
                                                            pauseOnHover: true,
                                                            draggable: true,
                                                            progress:
                                                              undefined,
                                                            theme:
                                                              "colored",
                                                          }
                                                        );
                                                      }

                                                    }
                                                  }}
                                                />
                                              </Badge>
                                            </div>
                                            <div
                                              className="attachments"
                                              style={{
                                                cursor:
                                                  "pointer",
                                              }}
                                            >
                                              <Badge
                                                count={ntfc ? 0 :
                                                  subAction.files
                                                }
                                              >
                                                <Avatar
                                                  style={{
                                                    backgroundColor: (ntfc) ? null :
                                                      "#5AC724",
                                                    color: (ntfc) ? null :
                                                      "white",
                                                  }}
                                                  icon={
                                                    <FileAddOutlined />
                                                  }
                                                  onClick={(
                                                    e
                                                  ) => {
                                                    if (
                                                      !ntfc
                                                    ) {
                                                      e.stopPropagation();
                                                      SetFilesModel(
                                                        true
                                                      );
                                                      setTitle("")
                                                      setCurrentAction(
                                                        action
                                                      );
                                                      setCurrentSubAction(
                                                        subAction
                                                      );
                                                      setActionType(
                                                        "Sub"
                                                      );
                                                    } else {
                                                      if (!ntfc) {
                                                        toast.error(
                                                          "You cannot add file for sub action that has already been completed",
                                                          {
                                                            position:
                                                              "top-right",
                                                            autoClose: 5000,
                                                            hideProgressBar: false,
                                                            closeOnClick: true,
                                                            pauseOnHover: true,
                                                            draggable: true,
                                                            progress:
                                                              undefined,
                                                            theme:
                                                              "colored",
                                                          }
                                                        );
                                                      }

                                                    }
                                                  }}
                                                />
                                              </Badge>
                                            </div>
                                          </div>
                                        </Col>

                                        <Col
                                          className="mob-hide"
                                          span={
                                            2
                                          }
                                        >
                                          <Tag
                                            className="sub-action-def-date"
                                            color="purple"
                                            style={{
                                              marginLeft:
                                                "116%",
                                            }}
                                          >
                                            <Typography.Text
                                              style={{
                                                fontSize: 12,
                                              }}
                                            >
                                              {" "}
                                              {action.action_def_date
                                                ? moment(
                                                  action.action_def_date
                                                ).format(
                                                  "DD/MM/YYYY"
                                                )
                                                : measures.measure_def_date
                                                  ? moment(
                                                    measures.measure_def_date
                                                  ).format(
                                                    "DD/MM/YYYY"
                                                  )
                                                  : "-"}
                                            </Typography.Text>
                                          </Tag>
                                        </Col>
                                      </Row>
                                      <Divider
                                        style={{
                                          marginTop:
                                            0,
                                          marginBottom: 2
                                        }}
                                      />
                                    </>
                                  );
                                }
                              )}
                          </Space>
                        </div>
                      </Panel>
                    </Collapse>
                  );
                }
              )}
          </Space>
        </div>
      </Panel >
    </Collapse >
  )

  const ArtcleRow = ({ element, key }) => (<Collapse
    // expandIconPosition="hidden"
    key={key}
    defaultActiveKey={["1"]}
    style={{ alignItems: "center" }}
    // className='hidden-class'
    ghost={true}
    expandIcon={({ isActive }) => (
      <NoopIcon
        style={{ fontSize: 12, color: "black" }}
        rotate={isActive ? 90 : 0}
      />
    )}
  >
    <Panel
      header={
        <Row
          justify="start"
          align="middle"
          gutter={16}
        >
          <Col la={12} xs={10}>
            <div className="tblRaw1">
              <Badge.Ribbon
                text={`Article`}
                placement="start"
                style={{
                  marginTop: -30,
                  marginLeft: -5,
                }}
                color={NewColorTheme.primaryColor}
              >
                <Typography.Text
                  style={{
                    fontFamily: "Figtree",
                    fontStyle: "normal",
                    fontWeight: 600,
                    fontSize: "14px",
                    lineHeight: "22px",
                    color: "#000000",
                  }}
                >
                  {element.article_no +
                    ". " +
                    element.article_summary}
                </Typography.Text>
              </Badge.Ribbon>
            </div>
          </Col>
          <Col
            className="mob-hide"
            span={3}
            style={{
              paddingRight: "8px",
              display: "flex",
              justifyContent: "start",
              paddingLeft: "0",
              marginLeft: "9%",
            }}
          >
            {/* {!filtered ?  */}
            <Progress
              type="circle"
              strokeColor={getColor(
                element.article_completion
              )}
              // trailColor={getColor(action.action_completion)}
              percent={element.article_completion}
              strokeWidth={10}
              size={50}
            // format={(number) => `进行中，已完成${number}%`}
            />
            {/* : null */}
          </Col>
        </Row>
      }
      key="1"
    >
      <div style={{ paddingLeft: 30 }}>
        <Space
          direction="vertical"
          style={{ width: "100%" }}
        >
          {element.measures &&
            element.measures.map((measures, m) => {
              return (
                param2 ? (param2 == measures.measure_id ? <MeasureRow element={element} key={key} measures={measures} m={m} /> : null) : <MeasureRow element={element} key={key} measures={measures} m={m} />
              );
            })}
        </Space>
      </div>
      <Divider></Divider>
    </Panel >
  </Collapse >)

  return (
    <Fragment>
      <FormLayouts
        backUrl={param3 == "summary" ? "/summary" : null}
        scroll={false}
        title="Action Plan"
        table={
          <Row>
            <div className="boxAction-task">
              <Row gutter={16} className="boxHeader-task">
                {userLevel === "7" || userLevel === "6" ? (
                  <Col lg={12} xs={18}>
                    <Row gutter={16}>
                      <Col lg={6} xs={14}>
                        <p>Select an Agency</p>
                      </Col>
                      <Col lg={9} xs={10}>
                        {(userLevel === "7" || userLevel === "6") && (
                          <Form form={form}>
                            <Form.Item name="agency" required>
                              <Select
                                placeholder="Select An Agency"

                                options={agencies}
                                onChange={(val) => {
                                  form.resetFields(['article', 'measure', 'category', 'status']);
                                  setSelectedArticle(null)
                                  setSelectedMeasure(null)
                                  setSelectedCategory(null)
                                  setSelectedStatus(null)
                                  AgencyActions(val);
                                  setSelectedAgency(val);
                                }}
                              />
                            </Form.Item>
                          </Form>
                        )}
                      </Col>
                      {param1 || param2 ? <Button onClick={() => window.location.href = "/tasksactionplan"} icon={<FilterOutlined />}>Remove Filter</Button> : null}
                    </Row>
                  </Col>
                ) : (
                  <Col lg={12} xs={18}>
                    <div style={{ display: "flex", gap: 10 }}>
                      <p>{AgencyName}</p>
                      {param1 || param2 ? <Button onClick={() => window.location.href = "/tasksactionplan"} icon={<FilterOutlined />}>Remove Filter</Button> : null}

                    </div>

                  </Col>
                )}

                <Col span={2} className="mob-hide">
                  <div className="text"  >
                    <p>Progress</p>
                  </div>
                </Col>
                <Col span={2} className="mob-hide">
                  <div className="text">
                    <p>Weightage</p>
                  </div>
                </Col>

                <Col lg={2} className="mob-hide">
                  <div className="text">
                    <p>Status</p>
                  </div>
                </Col>
                <Col span={2} className="mob-hide">
                  <div className="text">
                    <p>Actions</p>
                  </div>
                </Col>
                <Col span={2} className="mob-hide">
                  <div className="text">
                    <p>Category</p>
                  </div>
                </Col>
                <Col span={2} className="mob-hide">
                  <div className="text">
                    <p>Delivery Date</p>
                  </div>
                </Col>
                <Col xs={24}>
                  <Row gutter={4}>

                    <Col lg={4} xs={24}>

                      <Form form={form}>
                        <Form.Item name="article" required>
                          <Select
                            placeholder="Filter by Article"
                            allowClear
                            options={articles}
                            onChange={(val) => {
                              // setFilters({ ...filters, article: val });
                              // filterData('article', val);
                              form.resetFields(['measure']);
                              setSelectedMeasure(null);
                              setSelectedArticle(val)
                            }}
                          />
                        </Form.Item>
                      </Form>

                    </Col>
                    <Col lg={4} xs={24}>

                      <Form form={form}>
                        <Form.Item name="measure" required>
                          <Select
                            placeholder="Filter by Measure"
                            allowClear
                            options={measures}
                            onChange={(val) => {
                              // setFilters({ ...filters, measure: val });
                              // filterData('measure', val);
                              setSelectedMeasure(val)

                            }}
                          />
                        </Form.Item>
                      </Form>

                    </Col>
                    <Col lg={4} xs={24}>

                      <Form form={form}>
                        <Form.Item name="category" required>
                          <Select
                            placeholder="Filter by Category"
                            allowClear
                            options={[{ value: "A", label: "A" },
                            { value: "B", label: "B" },
                            { value: "C", label: "C" }]}
                            onChange={(val) => {
                              // setFilters({ ...filters, category: val });
                              // filterData('category', val);
                              setSelectedCategory(val);
                            }}
                          />
                        </Form.Item>
                      </Form>

                    </Col>
                    <Col lg={4} xs={24}>

                      <Form form={form}>
                        <Form.Item name="status" required>
                          <Select
                            placeholder="Filter by Status"
                            allowClear
                            options={ActionStatus}
                            onChange={(val) => {
                              // setFilters({ ...filters, status: val });
                              // filterData('status', val);
                              setSelectedStatus(val);
                            }}
                          />
                        </Form.Item>
                      </Form>

                    </Col>
                    {/* <Col lg={4} xs={24}>

                      <Form form={form}>
                        <Form.Item name="action" required>
                          <Select
                            placeholder="Filter by Action"
                            allowClear
                            options={agencies}
                            onChange={(val) => {
                              AgencyActions(val);
                              setSelectedAgency(val);
                            }}
                          />
                        </Form.Item>
                      </Form>

                    </Col>
                     */}

                  </Row>

                </Col>
              </Row>

              <div className="boxBody-task">
                <SimpleBar style={{ maxHeight: "66vh" }}>
                  <Row gutter={16}>
                    <Col span={24}>
                      <Space
                        direction="vertical"
                        style={{
                          width: "100%",
                          marginTop: 20,
                          height: "100vh",
                        }}
                      >
                        {!loading ?
                          mainDataAray.length > 0 ?
                            mainDataAray.map((element, key) => {
                              return (
                                param1 ? (param1 == element.article_id ? <ArtcleRow element={element} key={key} /> :

                                  null
                                ) : <ArtcleRow element={element} key={key} />
                              );
                            }) :
                            <Alert type="info"
                              showIcon
                              message="Note"
                              description="This is not assigned to the action plan of the agency"
                            />
                          : <LoadingOutlined />

                        }
                      </Space>
                    </Col>
                  </Row>
                </SimpleBar>
              </div>
              <div className="boxFooter-task"></div>
            </div>
          </Row>
        }
      />

      {/* <div className="pageFooter-task"></div> */}

      <Modal
        // title="Vertically centered modal dialog"
        className="custom-modal"
        centered
        open={addParticipantsDialog}
        onOk={() => SetAddParticipants(false)}
        onCancel={() => { SetAddParticipants(false); form.resetFields(['search']) }}
        footer={false}
        width={800}
        title={
          <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
            <div>     <Typography className='custom-modal-title' level={2}>Members</Typography></div>


          </div>


        }
      >
        <div className='custom-modal-body'>
          <Row >
            <Col xs={24} md={12}>

              <TitleBox
                titleBoxColor={NewColorTheme.primaryColor}
                title={"Current Members"}
                body={
                  <div style={{ width: "100%" }}>
                    <div>
                      {!loadingMembers ? (
                        curentActionMembers.length > 0 ?
                          <div style={{ marginLeft: 0, height: "400px", overflow: "auto" }} className='custom-scroll-div'>

                            <List dataSource={curentActionMembers}

                              renderItem=
                              {(item, k) => (
                                <List.Item key={item.email}>
                                  <List.Item.Meta
                                    avatar={
                                      <Avatar
                                        style={{
                                          backgroundColor: NewColorTheme.primaryColor,
                                        }}
                                        icon={<UserOutlined />}
                                      />
                                    }
                                    title={
                                      item.title +
                                      " " +
                                      item.first_name +
                                      " " +
                                      item.last_name
                                    }
                                    description={item.designation}
                                  />
                                  <Button
                                    shape="square"
                                    onClick={() => removeParticipant(item)}
                                    icon={<MinusOutlined />}
                                  />
                                </List.Item>
                              )}
                            >


                            </List>
                          </div>
                          :
                          <Empty
                            image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
                            imageStyle={{
                              height: 60,
                            }}
                            description={
                              <span>
                                There are no current members in this action.
                                You can add new members from the "Add Members" section.
                              </span>
                            }

                          >

                          </Empty>


                      ) : (
                        <Spin />
                      )}
                    </div>
                  </div>
                }
              />

            </Col>
            <Col xs={24} md={12}>
              <TitleBox
                titleBoxColor={NewColorTheme.primaryColor}
                title={"Add Members"}
                body={

                  <div style={{ width: "100%" }}>
                    <Form form={form} onFinish={(values) => onSearch(values.search)}>
                      <Form.Item name="search">
                        <Search
                          placeholder="input search text"
                          onSearch={onSearch}
                          enterButton
                        />
                      </Form.Item>
                    </Form>

                    <div>
                      {!loadingMembers ? (
                        <div style={{ marginLeft: 0, height: "400px", overflow: "auto", paddingRight: 15 }} className='custom-scroll-div'>
                          {users.length > 0 ? <List dataSource={users}
                            renderItem={(item, k) => (
                              <Tooltip
                                title={
                                  "Add " +
                                  item.title +
                                  " " +
                                  item.first_name +
                                  " " +
                                  item.last_name
                                }
                                color={"#108ee9"}
                                key={k}
                              >
                                <List.Item key={item.email}>
                                  <List.Item.Meta
                                    avatar={
                                      <Avatar
                                        style={{
                                          backgroundColor: NewColorTheme.primaryColor,
                                        }}
                                        icon={<UserOutlined />}
                                      />
                                    }
                                    title={
                                      item.title +
                                      " " +
                                      item.first_name +
                                      " " +
                                      item.last_name
                                    }
                                    description={item.designation}
                                  />
                                  {!item.matched && (
                                    <Button
                                      shape="square"
                                      onClick={() => addNewParticipant(item)}
                                      icon={<PlusOutlined />}
                                    />
                                  )}
                                </List.Item>
                              </Tooltip>
                            )}
                          >



                          </List> :

                            <Empty
                              image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
                              imageStyle={{
                                height: 60,
                              }}
                              description={
                                <span>
                                  There are no members that match your search criteria.
                                </span>
                              }
                            >

                            </Empty>

                          }

                        </div>
                      ) : (
                        <Spin />
                      )}
                    </div>
                  </div>
                }
              />
            </Col>
          </Row>
        </div>


      </Modal>
      <Modal
        // title="Vertically centered modal dialog"
        closable
        centered
        open={AddFiles}
        onOk={() => SetFilesModel(false)}
        onCancel={() => { SetFilesModel(false); setTitle("") }}
        footer={false}
        className="custom-modal"
        title={
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div>
              {" "}
              <Typography className="custom-modal-title" level={2}>
                Add Attachments
              </Typography>
            </div>
          </div>
        }
      >
        <div style={{ width: "100%", padding: 20 }}>
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <Typography>
                Please enter a title before uploading the file.
              </Typography>
            </Col>

            <Col span={24}>
              <Input
                disabled={fileTitleDisable}
                value={fileTitle}
                placeholder="Enter a file name"
                onChange={(e) => {

                  const inputValue = e.target.value;


                  if (inputValue.length === 0 || inputValue.startsWith(" ")) {
                    setFileTitleError(true)
                    // Display an error message or handle the error condition
                    console.log('Error: File title cannot be empty.');
                  } else {
                    setFileTitleError(false)

                  }
                  setTitle(inputValue);
                }
                }

              />
              {FileTitleError && <span style={{ fontSize: 12, color: "red" }}>Invalid File Title</span>
              }
            </Col>

            <Col span={24} style={{ marginTop: 5 }}>
              <Alert style={{ marginBottom: "20px" }} type='info' message=
                {"Please ensure that only one attachment is uploaded at a time, and the maximum file size should be 5MB"}
              >

              </Alert>

              <Dragger
                {...props}
                fileList={fileList}
                showUploadList={{ showProgress: true }}
                disabled={fileTitle ? false : true}
                beforeUpload={(file) => {
                  console.log("sizeeeeeeeee", file);
                  const MAX_SIZE = 5 * 1024 * 1024; // 5 MB
                  if (file.size > MAX_SIZE) {
                    toast.error(
                      "File size exceeds limit! The maximum file upload size is 5MB."
                    );
                    return false;
                  } else {
                    setFileTitleDisable(true);
                    handleFileUpload(file);
                  }

                  return false; // to prevent automatic upload
                }}
              >
                <p className="ant-upload-drag-icon">
                  <InboxOutlined tyle={{ color: "#33363F" }} />
                </p>
                <p className="ant-upload-text">
                  Click or drag a file to this area to upload
                </p>
              </Dragger>
              {fileLoading && (
                <Typography.Text style={{ color: "green", fontWeight: 700 }}>
                  Please Wait..Your File is uploding
                </Typography.Text>
              )}
            </Col>
          </Row>
        </div>
      </Modal>
      <Modal
        // title="Vertically centered modal dialog"
        centered
        open={Addcomments}
        onOk={() => SetAddComments(false)}
        onCancel={() => {
          form.resetFields()
          SetAddComments(false)
        }}
        footer={false}
        className="custom-modal"
        title={
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div>
              {" "}
              <Typography className="custom-modal-title" level={2}>
                Add Comments
              </Typography>
            </div>
          </div>
        }
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            padding: 20,
          }}
        >
          <Form form={form} onFinish={AddNewComment} layout="vertical">
            <Form.Item

              label="Enter your comment here"
              name="comment"
              rules={[
                { required: true, message: "Please enter a comment" },
                {
                  pattern: /^(?!\s+$).*/,

                  message: "Please enter a valid comment",
                },
              ]}
            >
              <Input.TextArea
                // style={{ borderRadius: 15 }}
                disabled={window.localStorage.getItem('view_only') == 1}
                rows={5}
                placeholder="Enter Comment"
              />
            </Form.Item>

            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <Button
                size="large"
                loading={commentAdding}
                type="primary"
                htmlType="submit"
                disabled={window.localStorage.getItem('view_only') == 1}
              >
                Add Comment
              </Button>
            </div>
          </Form>
        </div>
      </Modal>
      <Modal
        closable={true}
        style={{
          top: 20,
          height: "95vh",
          backgroundColor: "transparent",
        }}
        width={viewMode === "Add New Sub Action" ? 750 : 1500}
        key={openMyModal ? "modal-open" : "modal-closed"}
        footer={false}
        open={openMyModal}
        onOk={() => setMymodal(false)}
        onCancel={handleClose}
        className="task-plan-modal custom-modal"
        title={
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div>
              {" "}
              <Typography className="custom-modal-title" level={2}>
                {viewMode === "Add New Sub Action"
                  ? "Add New Sub Action"
                  : EditMode
                    ? "Edit Sub Action"
                    : "View Action"}
              </Typography>
            </div>
          </div>
        }
      >
        <CustomeModal
          changeView={changeView}
          onClose={handleClose}
          ntfc={ntfc}
          viewMode={viewMode}
          editMode={EditMode}
          action={currentAction}
          article={currentArticle}
          measures={currentMeasure}
          subAction={CurrentSubAction}
          AgencyActions={changeStatus}
          currentStatus={
            EditMode ? CurrentSubAction.status : currentAction.action_status
          }
        />
      </Modal>
      <Modal
        closable={true}
        className="custom-modal"
        centered
        onCancel={() => setWarningDialog(false)}
        title={
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div>
              {" "}
              <Typography className="custom-modal-title" level={2}>
                {"Reminder"}
              </Typography>
            </div>
          </div>
        }
        open={warningDialog}
        footer={[
          <Button
            key="submit"
            onClick={() => {
              setWarningDialog(false);
            }}
          >
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            onClick={() => {
              setWarningDialog(false);
              dialogUpdateRequest();
            }}
          >
            Change the Status
          </Button>,
        ]}
      // onCancel={() => { confirmationDialog(false) }}
      // okText="Ok"
      // cancelText="No"
      >
        <div
          style={{
            padding: 20,
            gap: 15,
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div>
            <Descriptions size="small" bordered>
              <Descriptions.Item span={24} label="Action">
                {warningData?.actionName}
              </Descriptions.Item>
              <Descriptions.Item span={24} label="Sub Action">
                {warningData?.subAction}
              </Descriptions.Item>
            </Descriptions>
          </div>
          <div>
            <span style={{ fontSize: 15, fontWeight: 500 }}>
              You are changing the sub action status into{" "}
              <Tag color={getColorStatus(warningData?.subActionStatus)}>
                {" "}
                {getStatus(warningData?.subActionStatus)}
              </Tag>
              ,But the action is still in{" "}
              <Tag color={getColorStatus(0)}>To Do</Tag> status.
            </span>
          </div>
          <div>
            <span style={{ fontSize: 15, fontWeight: 500 }}>
              You have to change the action status into{" "}
              <Tag color={getColorStatus(warningData?.act)}>
                {warningData?.actionStatus}
              </Tag>
              .
            </span>
          </div>
          <div>
            <span style={{ fontSize: 15, fontWeight: 600 }}>
              Are you sure to change the sub action status before changing the
              action status.
            </span>
          </div>
        </div>
      </Modal>
      <ToastContainer />
    </Fragment >
  );
}

export default TasksActionPlan;
