import { Button, Card, Col, Row, Table, Space, Typography, Alert } from "antd";
import { useEffect, useState } from "react";
import {
  DownloadFiles,
  getAllInquiries,
  getAnsweredInquiries,
  publishInquiry,
  unPublishInquiry,
} from "../../../api";
import moment from "moment";
import { NewColorTheme } from "../../../appConst";
import FullSearch from "../../../Components/FullSearch";

const AnsweredInquiries = ({ showMessage, setRefreshCounts }) => {
  const [inquiries, setInquiries] = useState([]);
  const [inquiriesFull, setInquiriesFull] = useState([]);
  const [inquiry, setInquiry] = useState(null);
  const [ntfc_level, setNtfcLevel] = useState(null);
  const [isChair, setChair] = useState(false)
  useEffect(() => {
    if (window.localStorage.getItem('ntfc_level') == 6) {
      setChair(true)
    } else {
      setChair(false)
    }
    loadData();
  }, []);

  const loadData = async () => {
    getAnsweredInquiries().then((res) => {
      if (res && res.data && res.data.out) {
        const dat = res.data.out.map((item) => {
          return {
            ...item,
            created_format: formatDate(item.created_at),
            assigned_format: item.assignments && item.assignments.length > 0 ? moment(item.assignments[0]["created"]).format("DD/MM/YYYY") : "-",
            assignee_format: item.assignments && item.assignments.length > 0 ? item.assignments[0]["username"] : item.answer_pending === 0 ? "NTFC" : "-",
            agency_format: item.responses && item.responses.length > 0 ? item.responses[item.responses.length - 1]["agency_name"] : "-"
          }
        })
        setInquiries(dat);
        setInquiriesFull(dat);
      }
    });
    setNtfcLevel(await window.localStorage.getItem("ntfc_level"));
  };

  const formatDate = (str) => {
    const dmy = str.split("T")[0].split("-");
    return `${dmy[2]}/${dmy[1]}/${dmy[0]}`;
  };

  const columns = [
    {
      title: "Title of the Inquiry",
      dataIndex: "topic",
      key: "topic",
      render(text, record) {
        return {
          props: {
            style: {
              overflow: "hidden",
            },
          },
          children: <div>{text}</div>,
        };
      },
    },
    // {
    //     title: 'Description',
    //     dataIndex: 'description',
    //     key: 'description',
    //     width: '30%',
    //     render(text, record) {
    //         return {
    //             props: {
    //                 style: {
    //                     overflow: 'hidden'
    //                 },
    //             },
    //             children: <div>{text}</div>,
    //         };
    //     },
    // },
    {
      title: "Created By",
      dataIndex: "name",
      key: "name",
      render(text, record) {
        return {
          props: {
            style: {
              overflow: "hidden",
            },
          },
          children: <div>{text}</div>,
        };
      },
    },
    {
      title: "Date",
      dataIndex: "created_at",
      key: "created_at",
      render: (_, { created_at }) => <>{formatDate(created_at)}</>,
    },
    {
      title: "Answered By",
      dataIndex: "responses",
      key: "responses",
      render: (_, { responses }) => (
        <Space>
          {responses && responses.length > 0
            ? responses[responses.length - 1]["username"]
            : "-"}
        </Space>
      ),
    },
    {
      title: "Answered Agency",
      dataIndex: "responses",
      key: "responses_agency",
      render: (_, { responses }) => (
        <div style={{ whiteSpace: "pre-line" }}>
          {responses && responses.length > 0
            ? responses[responses.length - 1]["agency_name"]
            : "-"}
        </div>
      ),
    },
    {
      title: "Status",
      key: "answer_pending",
      dataIndex: "answer_pending",
      render: (_, { answer_pending, status }) => (
        <Space>
          <Button
            style={{
              color: "white",
              backgroundColor:
                answer_pending === 0
                  ? "#396eea"
                  : answer_pending === 1
                    ? "#FEBD5B"
                    : status === 1
                      ? "#08d4d4"
                      : "#50CD89",
            }}
          >
            {answer_pending === 0
              ? "Submitted"
              : answer_pending === 1
                ? "Answer Pending"
                : status === 1
                  ? "Published"
                  : "Answered"}
          </Button>
        </Space>
      ),
    },
  ];

  const FetchFile = async (file) => {
    let out = await DownloadFiles(file.token);

    let blob = new Blob([out.data], { type: file.type });
    let url = window.URL.createObjectURL(blob);

    let link = document.createElement("a");
    link.href = url;

    link.download = file.url.split("-::-")[1];

    document.body.appendChild(link);
    link.click();
  };

  const publish = () => {
    publishInquiry({
      id: inquiry.id,
    }).then((out) => {
      if (out.status == 200) {
        showMessage(1, out.data.message);
        setInquiry(null);
        setRefreshCounts(true);
        getAnsweredInquiries().then((res) => {
          if (res && res.data && res.data.out) {
            const dat = res.data.out.map((item) => {
              return {
                ...item,
                created_format: formatDate(item.created_at),
                assigned_format: item.assignments && item.assignments.length > 0 ? moment(item.assignments[0]["created"]).format("DD/MM/YYYY") : "-",
                assignee_format: item.assignments && item.assignments.length > 0 ? item.assignments[0]["username"] : item.answer_pending === 0 ? "NTFC" : "-",
                agency_format: item.responses && item.responses.length > 0 ? item.responses[item.responses.length - 1]["agency_name"] : "-"
              }
            })
            setInquiries(dat);
            setInquiriesFull(dat);
          }
        });
      } else {
        showMessage(null, out.data.message);
      }

    });
  };

  const unpublish = async () => {
    unPublishInquiry({
      id: inquiry.id,
    }).then((out) => {
      showMessage(1, out.data.message);
      setInquiry(null);
      setRefreshCounts(true);
      getAnsweredInquiries().then((res) => {
        if (res && res.data && res.data.out) {
          const dat = res.data.out.map((item) => {
            return {
              ...item,
              created_format: formatDate(item.created_at),
              assigned_format: item.assignments && item.assignments.length > 0 ? moment(item.assignments[0]["created"]).format("DD/MM/YYYY") : "-",
              assignee_format: item.assignments && item.assignments.length > 0 ? item.assignments[0]["username"] : item.answer_pending === 0 ? "NTFC" : "-",
              agency_format: item.responses && item.responses.length > 0 ? item.responses[item.responses.length - 1]["agency_name"] : "-"
            }
          })
          setInquiries(dat);
          setInquiriesFull(dat);
        }
      });
    });
  };

  return (
    <div className="inqu-content-div">
      <Row gutter={[16, 16]} style={{ height: "100%" }}>
        <div className="pc-hide" style={{ width: "100%", padding: "2%" }}>
          <Alert
            message="Please choose a table row to view the data."
            type="info"
            showIcon
          />
        </div>
        <Col className="mob-hide" lg={6} xs={24} style={{ height: "100%" }}>
          <Card
            style={{
              height: "calc(70vh - 2%)",
              background: "#f5f9ff",
              maxHeight: "100%",
              overflow: "auto",
            }}
          >
            {inquiry ? (
              <div>
                {/* <Typography.Title style={{ color: '#005476' }} level={3}>{inquiry.topic}</Typography.Title>
                        <Typography.Text style={{ color: '#005476' }}>{inquiry.description}</Typography.Text> */}
                <Typography.Title
                  style={{
                    color: "#005476",
                    marginTop: "10px",
                    margineLeft: "10px",
                  }}
                  level={3}
                >
                  Inquiry Details
                </Typography.Title>
                <Typography.Title
                  style={{
                    color: "#005476",
                    width: "100%",
                    display: "inline-block",
                    marginBottom: "-20px",
                    marginTop: "-10px",
                  }}
                  level={5}
                >
                  Created At
                </Typography.Title>
                <Typography.Text
                  style={{
                    color: "#005476",
                    width: "100%",
                    display: "inline-block",
                    paddingLeft: "10px",
                  }}
                >
                  {moment(inquiry.created_at).format("DD/MM/YYYY")}
                </Typography.Text>
                <Typography.Title
                  style={{
                    color: "#005476",
                    width: "100%",
                    display: "inline-block",
                    marginBottom: "-20px",
                    marginTop: "-5px",
                  }}
                  level={5}
                >
                  Created By
                </Typography.Title>
                <Typography.Text
                  style={{
                    color: "#005476",
                    width: "100%",
                    display: "inline-block",
                    paddingLeft: "10px",
                  }}
                >
                  {inquiry.name}
                </Typography.Text>
                <Typography.Title
                  style={{
                    color: "#005476",
                    width: "100%",
                    display: "inline-block",
                    marginBottom: "-20px",
                    marginTop: "-5px",
                  }}
                  level={5}
                >
                  Title
                </Typography.Title>
                <Typography.Text
                  style={{
                    color: "#005476",
                    width: "100%",
                    display: "inline-block",
                    paddingLeft: "10px",
                  }}
                >
                  {inquiry.topic}
                </Typography.Text>
                <Typography.Title
                  style={{
                    color: "#005476",
                    width: "100%",
                    display: "inline-block",
                    marginBottom: "-20px",
                    marginTop: "-5px",
                  }}
                  level={5}
                >
                  Description
                </Typography.Title>
                <Typography.Text
                  style={{
                    color: "#005476",
                    width: "100%",
                    display: "inline-block",
                    paddingLeft: "10px",
                  }}
                >
                  {inquiry.description}
                </Typography.Text>
                {inquiry.assignments.length > 0 ? (
                  <div>
                    <Typography.Title
                      style={{
                        color: "#005476",
                        width: "100%",
                        display: "inline-block",
                        marginBottom: "-20px",
                        marginTop: "-5px",
                      }}
                      level={5}
                    >
                      Assignee
                    </Typography.Title>
                    <Typography.Text
                      style={{
                        color: "#005476",
                        width: "100%",
                        display: "inline-block",
                        paddingLeft: "10px",
                      }}
                    >
                      {inquiry.assignments[0].username}
                    </Typography.Text>
                    <Typography.Title
                      style={{
                        color: "#005476",
                        width: "100%",
                        display: "inline-block",
                        marginBottom: "-20px",
                        marginTop: "-5px",
                      }}
                      level={5}
                    >
                      Assigne At
                    </Typography.Title>
                    <Typography.Text
                      style={{
                        color: "#005476",
                        width: "100%",
                        display: "inline-block",
                        paddingLeft: "10px",
                      }}
                    >
                      {moment(inquiry.assignments[0].created).format(
                        "DD/MM/YYYY"
                      )}
                    </Typography.Text>
                    <Typography.Title
                      style={{
                        color: "#005476",
                        width: "100%",
                        display: "inline-block",
                        marginBottom: "-20px",
                        marginTop: "-5px",
                      }}
                      level={5}
                    >
                      Assigned Agency
                    </Typography.Title>
                    <Typography.Text
                      style={{
                        color: "#005476",
                        width: "100%",
                        display: "inline-block",
                        paddingLeft: "10px",
                      }}
                    >
                      {inquiry.assignments[0].agency_name}
                    </Typography.Text>
                  </div>
                ) : (
                  ""
                )}
                <Typography.Title
                  style={{
                    color: "#005476",
                    width: "100%",
                    display: "inline-block",
                    marginBottom: "-20px",
                    marginTop: "-5px",
                  }}
                  level={5}
                >
                  Consent
                </Typography.Title>
                <Typography.Text
                  style={{
                    color: "#005476",
                    width: "100%",
                    display: "inline-block",
                    paddingLeft: "10px",
                  }}
                >
                  This user has{" "}
                  {inquiry.publishable == 0 ? "Not Given" : "Given"} Consent to
                  Publish
                </Typography.Text>
                {inquiry.files &&
                  inquiry.files.filter((element) => element.response_id == null)
                    .length > 0 ? (
                  <Typography.Title style={{ color: "#005476" }} level={5}>
                    Attachments
                  </Typography.Title>
                ) : null}
                {inquiry.files.map((file) => {
                  return file.response_id === null ? (
                    <div
                      className="inquiry-btn"
                      onClick={() => FetchFile(file)}
                    >
                      {file.type.includes("image") ? (
                        <img
                          src="/image.png"
                          alt="image"
                          style={{ height: 50, width: 50 }}
                        />
                      ) : (
                        <img
                          src="/file.png"
                          alt="file"
                          style={{ height: 50, width: 50 }}
                        />
                      )}
                      {file.url.toString().split("-::-")[1]}
                    </div>
                  ) : null;
                })}
                <br />
                {inquiry.responses && inquiry.responses.length > 0 ? (
                  <Typography.Title style={{ color: "#005476" }} level={5}>
                    Responses
                  </Typography.Title>
                ) : null}
                {inquiry.responses.map((response) => {
                  return (
                    <div>
                      <p>{response.content}</p>
                      {inquiry.files.map((file) => {
                        return file.response_id === response.id ? (
                          <div
                            className="inquiry-btn"
                            onClick={() => FetchFile(file)}
                          >
                            {file.type.includes("image") ? (
                              <img
                                src="/image.png"
                                alt="image"
                                style={{ height: 50, width: 50 }}
                              />
                            ) : (
                              <img
                                src="/file.png"
                                alt="file"
                                style={{ height: 50, width: 50 }}
                              />
                            )}
                            {file.url.toString().split("-::-")[1]}
                          </div>
                        ) : null;
                      })}
                      <p style={{ color: "grey", fontSize: 12, margin: 0 }}>
                        <i>Replied By</i>
                      </p>
                      <p>{response.username}</p>
                    </div>
                  );
                })}
                {Number(ntfc_level) > 5 && !isChair ? (
                  <>
                    {inquiry.status == 1 ? (
                      <Button type="primary" disabled={window.localStorage.getItem('view_only') == 1} onClick={() => unpublish()}>
                        Unpublish Inquiry
                      </Button>
                    ) : (
                      <Button
                        disabled={!inquiry.publishable || inquiry.status == 1 || window.localStorage.getItem('view_only') == 1}
                        type="primary"
                        onClick={() => publish()}
                      >
                        {(!inquiry.publishable || inquiry.status == 1) ? "Answered" : "Publish"}
                      </Button>
                    )}
                  </>
                ) : null}
              </div>
            ) : <div style={{ color: NewColorTheme.primaryColor }}>Please select a row for view inquiry details</div>}
          </Card>
        </Col>
        <Col lg={18} xs={24} style={{ overflow: "auto", height: "100%" }}>
          <FullSearch full={inquiriesFull} setFiltered={setInquiries} params={["topic", "name", "created_format", "assigned_format", "assignee_format", "agency_format"]} />
          <Card
            className="full-card"
            style={{
              height: "calc(70vh - 10%)",
              overflow: "auto",
              maxHeight: "100%",
            }}
          >
            <Table
              columns={columns}
              dataSource={inquiries}
              sticky={true}
              className="custom-table-header"
              pagination={{ pageSize: 5, total: inquiries.length }}
              rowClassName={(inq) =>
                inquiry && inq.id === inquiry.id
                  ? "inquiry-rows-selected"
                  : "inquiry-rows"
              }
              onRow={(record, rowIndex) => {
                return {
                  onClick: (event) => {
                    setInquiry(record);
                  },
                };
              }}
            />
          </Card>
        </Col>
        <Col className="pc-hide" lg={6} xs={24} style={{ height: "100%" }}>
          <Card
            style={{
              height: "calc(70vh - 2%)",
              background: "#f5f9ff",
              maxHeight: "100%",
              overflow: "auto",
            }}
          >
            {inquiry ? (
              <div>
                {/* <Typography.Title style={{ color: '#005476' }} level={3}>{inquiry.topic}</Typography.Title>
                        <Typography.Text style={{ color: '#005476' }}>{inquiry.description}</Typography.Text> */}
                <Typography.Title
                  style={{
                    color: "#005476",
                    marginTop: "10px",
                    margineLeft: "10px",
                  }}
                  level={3}
                >
                  Inquiry Details
                </Typography.Title>
                <Typography.Title
                  style={{
                    color: "#005476",
                    width: "100%",
                    display: "inline-block",
                    marginBottom: "-20px",
                    marginTop: "-10px",
                  }}
                  level={5}
                >
                  Created At
                </Typography.Title>
                <Typography.Text
                  style={{
                    color: "#005476",
                    width: "100%",
                    display: "inline-block",
                    paddingLeft: "10px",
                  }}
                >
                  {moment(inquiry.created_at).format("DD/MM/YYYY")}
                </Typography.Text>
                <Typography.Title
                  style={{
                    color: "#005476",
                    width: "100%",
                    display: "inline-block",
                    marginBottom: "-20px",
                    marginTop: "-5px",
                  }}
                  level={5}
                >
                  Created By
                </Typography.Title>
                <Typography.Text
                  style={{
                    color: "#005476",
                    width: "100%",
                    display: "inline-block",
                    paddingLeft: "10px",
                  }}
                >
                  {inquiry.name}
                </Typography.Text>
                <Typography.Title
                  style={{
                    color: "#005476",
                    width: "100%",
                    display: "inline-block",
                    marginBottom: "-20px",
                    marginTop: "-5px",
                  }}
                  level={5}
                >
                  Title
                </Typography.Title>
                <Typography.Text
                  style={{
                    color: "#005476",
                    width: "100%",
                    display: "inline-block",
                    paddingLeft: "10px",
                  }}
                >
                  {inquiry.topic}
                </Typography.Text>
                <Typography.Title
                  style={{
                    color: "#005476",
                    width: "100%",
                    display: "inline-block",
                    marginBottom: "-20px",
                    marginTop: "-5px",
                  }}
                  level={5}
                >
                  Description
                </Typography.Title>
                <Typography.Text
                  style={{
                    color: "#005476",
                    width: "100%",
                    display: "inline-block",
                    paddingLeft: "10px",
                  }}
                >
                  {inquiry.description}
                </Typography.Text>
                {inquiry.assignments.length > 0 ? (
                  <div>
                    <Typography.Title
                      style={{
                        color: "#005476",
                        width: "100%",
                        display: "inline-block",
                        marginBottom: "-20px",
                        marginTop: "-5px",
                      }}
                      level={5}
                    >
                      Assignee
                    </Typography.Title>
                    <Typography.Text
                      style={{
                        color: "#005476",
                        width: "100%",
                        display: "inline-block",
                        paddingLeft: "10px",
                      }}
                    >
                      {inquiry.assignments[0].username}
                    </Typography.Text>
                    <Typography.Title
                      style={{
                        color: "#005476",
                        width: "100%",
                        display: "inline-block",
                        marginBottom: "-20px",
                        marginTop: "-5px",
                      }}
                      level={5}
                    >
                      Assigne At
                    </Typography.Title>
                    <Typography.Text
                      style={{
                        color: "#005476",
                        width: "100%",
                        display: "inline-block",
                        paddingLeft: "10px",
                      }}
                    >
                      {moment(inquiry.assignments[0].created).format(
                        "DD/MM/YYYY"
                      )}
                    </Typography.Text>
                    <Typography.Title
                      style={{
                        color: "#005476",
                        width: "100%",
                        display: "inline-block",
                        marginBottom: "-20px",
                        marginTop: "-5px",
                      }}
                      level={5}
                    >
                      Assigned Agency
                    </Typography.Title>
                    <Typography.Text
                      style={{
                        color: "#005476",
                        width: "100%",
                        display: "inline-block",
                        paddingLeft: "10px",
                      }}
                    >
                      {inquiry.assignments[0].agency_name}
                    </Typography.Text>
                  </div>
                ) : (
                  ""
                )}
                <Typography.Title
                  style={{
                    color: "#005476",
                    width: "100%",
                    display: "inline-block",
                    marginBottom: "-20px",
                    marginTop: "-5px",
                  }}
                  level={5}
                >
                  Consent
                </Typography.Title>
                <Typography.Text
                  style={{
                    color: "#005476",
                    width: "100%",
                    display: "inline-block",
                    paddingLeft: "10px",
                  }}
                >
                  This user has{" "}
                  {inquiry.publishable == 0 ? "Not Given" : "Given"} Consent to
                  Publish
                </Typography.Text>
                {inquiry.files &&
                  inquiry.files.filter((element) => element.response_id == null)
                    .length > 0 ? (
                  <Typography.Title style={{ color: "#005476" }} level={5}>
                    Attachments
                  </Typography.Title>
                ) : null}
                {inquiry.files.map((file) => {
                  return file.response_id === null ? (
                    <div
                      className="inquiry-btn"
                      onClick={() => FetchFile(file)}
                    >
                      {file.type.includes("image") ? (
                        <img
                          src="/image.png"
                          alt="image"
                          style={{ height: 50, width: 50 }}
                        />
                      ) : (
                        <img
                          src="/file.png"
                          alt="file"
                          style={{ height: 50, width: 50 }}
                        />
                      )}
                      {file.url.toString().split("-::-")[1]}
                    </div>
                  ) : null;
                })}
                <br />
                {inquiry.responses && inquiry.responses.length > 0 ? (
                  <Typography.Title style={{ color: "#005476" }} level={5}>
                    Responses
                  </Typography.Title>
                ) : null}
                {inquiry.responses.map((response) => {
                  return (
                    <div>
                      <p>{response.content}</p>
                      {response.files && response.files.length > 0 ? response.files.map((file) => {
                        return file.response_id === response.id ? (
                          <div
                            className="inquiry-btn"
                            onClick={() => FetchFile(file)}
                          >
                            {file.type.includes("image") ? (
                              <img
                                src="/image.png"
                                alt="image"
                                style={{ height: 50, width: 50 }}
                              />
                            ) : (
                              <img
                                src="/file.png"
                                alt="file"
                                style={{ height: 50, width: 50 }}
                              />
                            )}
                            {file.url.toString().split("-::-")[1]}
                          </div>
                        ) : null;
                      }) : null}
                      <p style={{ color: "grey", fontSize: 12, margin: 0 }}>
                        <i>Replied By</i>
                      </p>
                      <p>{response.username}</p>
                    </div>
                  );
                })}
                {Number(ntfc_level) > 5 ? (
                  <>
                    {inquiry.status == 1 ? (
                      <Button type="primary" disabled={window.localStorage.getItem('view_only') == 1} onClick={() => unpublish()}>
                        Unpublish Inquiry
                      </Button>
                    ) : (
                      <Button
                        disabled={!inquiry.publishable || inquiry.status == 1 || window.localStorage.getItem('view_only') == 1}
                        type="primary"
                        onClick={() => publish()}
                      >
                        {(!inquiry.publishable || inquiry.status == 1) ? "Answered" : "Publish"}
                      </Button>
                    )}
                  </>
                ) : null}
              </div>
            ) : null}
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default AnsweredInquiries;
