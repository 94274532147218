import { List, Typography, Input, Space, Button, message, Tooltip, Form, Select, Row, Col } from 'antd';
import { PlusOutlined, CloseOutlined, EditOutlined } from '@ant-design/icons';
import { useEffect, useState } from 'react';
import { addAgency, getAgencyList, updateAgency } from '../../api';
import { AGENCY_TYPES } from '../../constants';
import PhoneInput from 'react-phone-input-2';

const Agencies = ({ agency }) => {
    const [items, setItems] = useState([]);

    const [data, setData] = useState([]);
    const [filtered_data, setFilteredData] = useState([]);

    const [selected, setSelected] = useState(null);

    const [agencyName, setAgencyName] = useState("");
    const [agencyType, setAgencyType] = useState(null);
    const [agencyDesc, setAgencyDesc] = useState("");
    const [agencyPhone, setAgencyPhone] = useState("");
    const [agencyAddress, setAgencyAddress] = useState("");
    const [numberError, setNumberError] = useState("");

    const [form] = Form.useForm();

    const refreshAgencies = () => {
        getAgencyList().then((out) => {
            setItems(out.data.out);
        });
    }

    const [messageApi, contextHolder] = message.useMessage();

    const success = (msg) => {
        messageApi.open({
            type: 'success',
            content: msg,
            style: {
                marginTop: '30vh',
                height: '100vh',
                fontSize: '20px'
            },
            duration: 3
        });
    };

    const error = (msg) => {
        messageApi.open({
            type: 'error',
            content: msg,
            style: {
                marginTop: '30vh',
                height: '100vh',
                fontSize: '20px'
            },
            duration: 3
        });
    };

    const handleOnChange = (value) => {
        if ((/^[^0-9]*$/.test(value))) {
            form.setFieldValue('agency_name', value)
            setAgencyName(value)
            setNumberError(false)
        } else {
            form.setFieldValue('agency_name', agencyName)
            setAgencyName(agencyName)
            setNumberError(true)
        }
    }

    const onFinish = (values) => {
        console.log('Success:', values);
        if (!selected) {
            addAgency(values).then((out) => {
                if (out.data.status === 200) {
                    form.resetFields();
                    refreshAgencies();
                    success("Agency Created");
                } else {
                    error("Agency Was Not Created");
                }
            }).catch((err) => {
                console.log(err);
                messageApi.open({
                    type: 'error',
                    content: 'Agency creation unsuccessful',
                    style: {
                        marginTop: '30vh',
                        height: '100vh',
                        fontSize: '20px'
                    },
                    duration: 3
                });
            })
        } else {
            values.id = selected.id;
            updateAgency(values).then((out) => {
                if (out.data.status === 200) {
                    form.resetFields();
                    refreshAgencies();
                    success("Agency Updated");
                    setSelected(null);
                } else {
                    error("Agency Was Not Updated");
                }
            }).catch((err) => {
                console.log(err);
                messageApi.open({
                    type: 'error',
                    content: 'Agency update unsuccessful',
                    style: {
                        marginTop: '30vh',
                        height: '100vh',
                        fontSize: '20px'
                    },
                    duration: 3
                });
            })
        }
    };
    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
        if (!selected) {
            error("Agency creation unsuccessful");
        } else {
            error("Agency update unsuccessful");
        }
    };

    useEffect(() => {
        refreshAgencies()
    }, []);

    useEffect(() => { setData(items); setFilteredData(items) }, [items]);

    useEffect(() => {
        if (selected) {
            form.setFieldValue('agency_name', selected.agency_name)
            form.setFieldValue('agency_type', selected.agency_type)
            form.setFieldValue('description', selected.description)
            // form.setFieldValue('phone', selected.phone)
            form.setFieldValue('address', selected.address)
        } else {
            form.resetFields();
            setAgencyName("");
        }
        // eslint-disable-next-line
    }, [selected]);

    return <div style={{ margin: '0 5px' }}>
        {contextHolder}
        <Row gutter={16}>
            <Col span={10}>
                <Typography.Title level={5} style={{ margin: 0 }}>Agencies</Typography.Title>
                <Space direction='vertical' style={{ width: '100%' }}>
                    <Typography.Text type="secondary">Please Complete the Form...</Typography.Text>
                    <Form
                        form={form}
                        layout="vertical"
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                    >
                        <Row gutter={16}>
                            <Col className="gutter-row" span={24}>
                                <Form.Item label="Agency Name" name="agency_name" required
                                    rules={[{
                                        required: true,
                                        message: <p style={{ marginLeft: "12px" }}>Agency Name is required</p>,
                                    },
                                    { min: 5, message: 'At least 5 characters required' },
                                    ]}
                                >
                                    <Input type='text' placeholder="Name" value={agencyName} onChange={(e) => handleOnChange(e.target.value)} />
                                </Form.Item>
                                {/* {numberError ?
                                    <p style={{ marginTop: "-20px", marginLeft: "12px", color: "#ff4d4f" }}>Agency Name cannot contain numbers</p>
                                    : ""} */}
                            </Col>
                            <Col className="gutter-row" span={24}>
                                <Form.Item label="Agency Type" name="agency_type" required
                                    rules={[{
                                        required: true,
                                        message: <p style={{ marginLeft: "12px" }}>Agency Type is required</p>,
                                    }]}
                                >
                                    <Select
                                        options={AGENCY_TYPES}
                                        onChange={(e) => {
                                            const flitered = AGENCY_TYPES.filter((it) => it.value === e)
                                            if (flitered.length > 0) {
                                                setAgencyType(e);
                                            }
                                        }}
                                        value={agencyType}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col className="gutter-row" span={24}>
                                <Form.Item label="Description" name="description">
                                    <Input.TextArea rows={4} placeholder="Description" value={agencyDesc} onChange={(e) => setAgencyDesc(e.target.value)} />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col className="gutter-row" span={24}>
                                <Form.Item label="Phone Number" name="phone" required
                                    rules={[{
                                        required: true,
                                        message: <p style={{ marginLeft: "12px" }}>Phone Number is required</p>,
                                    }, {
                                        max: 15,
                                        message: 'Maximum 15 digits allowed',
                                    },

                                    ]}>
                                    <PhoneInput
                                        country={'lk'}
                                        value={agencyPhone}
                                        onChange={setAgencyPhone}
                                        placeholder="Phone Number"
                                        inputStyle={{ width: "100%" }}
                                        // inputStyle={{ width: "100%", borderRadius: "30px" }}
                                        //   className="select-custom-pn"
                                        inputProps={{
                                            placeholder: 'Phone Number',
                                            // style: { width: '32.5vw', borderRadius: '30px' }
                                        }}
                                    />
                                </Form.Item>
                            </Col>
                            <Col className="gutter-row" span={24}>
                                <Form.Item label="Address" name="address" required
                                    rules={[{
                                        required: true,
                                        message: <p style={{ marginLeft: "12px" }}>Address is required</p>,
                                    }]}>
                                    <Input type='text' placeholder="Address" value={agencyAddress} onChange={(e) => setAgencyAddress(e.target.value)} />
                                </Form.Item>
                            </Col>
                        </Row>
                        {!selected ?
                            <Button type='primary' htmlType="submit" icon={<PlusOutlined />}>
                                Add Agency
                            </Button>
                            :
                            <Space>
                                <Button type='primary' htmlType="submit" icon={<EditOutlined />} style={{ background: 'green' }}>
                                    Update
                                </Button>
                                <Button type='primary' danger htmlType="submit" icon={<CloseOutlined />} onClick={() => setSelected(null)}>
                                    Cancel
                                </Button>
                            </Space>
                        }
                    </Form>
                </Space>
            </Col>
            <Col span={14}>
                <div style={{ display: 'flex', justifyContent: 'end', width: '100%', marginBottom: 10 }}>
                    <Input.Search style={{ width: 200 }} placeholder="search" onSearch={(val) => {
                        console.log(data);
                        const itms = data.filter((rec) => rec.agency_name.includes(val));
                        console.log(itms); setFilteredData(itms);
                    }} />
                </div>
                <List
                    bordered
                    className='config-list'
                    style={{ height: '50vh', overflow: 'auto', borderColor: '#f0f0f0' }}
                    dataSource={filtered_data}
                    header={<div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', alignItems: 'center' }}>
                        <Row gutter={16} style={{ width: '100%' }}>
                            <Col span={12}>
                                <Typography.Text>Agency Name</Typography.Text>
                            </Col>
                            <Col span={6}>
                                <Typography.Text>Agency Type</Typography.Text>
                            </Col>
                            <Col span={5}>
                                <Typography.Text>Phone</Typography.Text>
                            </Col>
                            <Col span={1}>
                            </Col>
                        </Row>
                    </div>}
                    renderItem={(item) => (
                        <List.Item className={agency && agency.id === item.id ? 'config-list-item-selected' : 'config-list-item'}>
                            <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', alignItems: 'center' }}>
                                <Row gutter={16} style={{ width: '100%' }}>
                                    <Col span={12}>
                                        <Typography.Text style={agency && agency.id === item.id ? { color: 'white' } : {}}>{item.agency_name}</Typography.Text>
                                    </Col>
                                    <Col span={6}>
                                        <Typography.Text style={agency && agency.id === item.id ? { color: 'white' } : {}}>{(AGENCY_TYPES.filter((type) => type.value === item.agency_type))[0].label}</Typography.Text>
                                    </Col>
                                    <Col span={5}>
                                        <Typography.Text style={agency && agency.id === item.id ? { color: 'white' } : {}}>{item.phone}</Typography.Text>
                                    </Col>
                                    <Col span={1}>
                                        <Tooltip title="View/Edit">
                                            <Button type="secondary" size='small' onClick={() => setSelected(item)} shape="circle" icon={<EditOutlined style={{ color: '#946e6e' }} />} />
                                        </Tooltip>
                                    </Col>
                                </Row>
                            </div>
                        </List.Item>
                    )}
                />
            </Col>
        </Row>
    </div>
}

export default Agencies;