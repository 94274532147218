import { Outlet, useLocation } from "react-router-dom";
import Header from "./header";
import { BsGrid, BsSpeedometer2, BsListTask, BsPersonCircle, BsGearWide, BsGift, BsPeople, RiGitPullRequestFill } from "react-icons/bs"
import { Card, Space } from "antd";
import "./main.styles.css";
import { AiOutlinePullRequest } from "react-icons/ai";
import { useEffect, useState } from "react";

const Main = () => {
    const SideItem = ({ children }) => <div className="side-item-container">
        {children}
    </div>
    const location = useLocation();
    const getClass = (path) => {
        if (location.pathname === path) {
            return "side-item-selected";
        } else {
            return "side-item"
        }
    }

    const [Token, setToken] = useState(null)

    useEffect(() => {
        getToken()
    }, []);

    const getToken = async () => {
        const token = await window.localStorage.getItem('ntfc_token');
        setToken(Number(token))
        if (!token) {
            window.location.href = "/login";
        }
    }


    return (
        location.pathname === "/" ?
            <Outlet />
            :
            <div className="container">
                <div className="navbar">
                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                        <Space direction="vertical">
                            <SideItem align="center">
                                <img style={{ width: '80px' }} src="/ntfc.png" alt='logo' />
                            </SideItem>
                            <SideItem align="center">
                                <div style={{ width: 50, borderTop: '1px solid #F0F6FF' }}></div>
                            </SideItem>
                            <SideItem>
                                <BsSpeedometer2 size={'2.5vw'} color="white" className={getClass("/dashboard")} onClick={() => window.location.href = "/dashboard"} />
                            </SideItem>
                            {Token && Token < 6 ? <SideItem>
                                <BsListTask size={'2.5vw'} color="white" className={getClass("/tasks")} onClick={() => window.location.href = "/tasks"} />
                            </SideItem> : null}
                            <SideItem>
                                <BsPersonCircle size={'2.5vw'} color="white" className={getClass("/users")} onClick={() => window.location.href = "/users"} />
                            </SideItem>
                            {Token && Token > 5 ? <SideItem>
                                <BsGearWide size={'2.5vw'} color="white" className={getClass("/config")} onClick={() => window.location.href = "/config"} />
                            </SideItem> : null}
                            <SideItem>
                                <BsGift size={'2.5vw'} color="white" className={getClass("/donors")} onClick={() => window.location.href = "/donors"} />
                            </SideItem>
                            <SideItem>
                                <BsPeople size={'2.5vw'} color="white" className={getClass("/inquiries")} onClick={() => window.location.href = "/inquiries"} />
                            </SideItem>
                            <SideItem>
                                <AiOutlinePullRequest size={'2.5vw'} color="white" className={getClass("/requests")} onClick={() => window.location.href = "/requests"} />
                            </SideItem>
                            <SideItem>
                                <BsGrid size={'2.5vw'} color="white" className="side-item" />
                            </SideItem>
                        </Space>
                        <Header />
                    </div>
                </div>
                <div className="spacer" />
                <div>
                    {/* <Header /> */}
                    <div style={{ width: '100%', height: '100%', overflow: "auto" }}>
                        <div className="site-card-border-less-wrapper">
                            <Card style={{ height: '100%', borderColor: '#DFDFE8' }}>
                                <Outlet style={{ backgroundColor: "#DFDFE8" }} />
                            </Card>
                        </div>
                    </div>
                </div>
            </div>
    )
};

export default Main;