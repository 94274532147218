import { Avatar, Button, Card, Col, Row, Space, Typography, Progress, Collapse, Result } from 'antd';
import { QuestionOutlined, DoubleRightOutlined, CommentOutlined, ProjectOutlined, RiseOutlined, PieChartOutlined } from '@ant-design/icons';


import { useEffect, useState } from 'react';
import { getAgencyActions, getAgencySubActions } from '../../api';
import ActionItem from './ActionItem';
import moment from 'moment/moment';
const { Text, Title } = Typography;
const { Panel } = Collapse;

const style = {

};

const MeasureGroup = ({ measure }) => {


    const [actions, setActions] = useState([]);

    useEffect(() => {

        if (measure) {
            getAgencyActions(measure.agency_id, {}).then((acts) => {

                if (acts && acts.data) {
                    setActions(acts.data.out);

                }
            });

        }
    }, [measure]);

    return measure ? <div>
        <Collapse ghost defaultActiveKey={['1']}
            mode="horizontal"
            style={{ display: 'flex', alignItems: 'center' }}
            expandIcon={({ isActive }) => <DoubleRightOutlined style={{ fontSize: 15, color: '#AED8E6' }} rotate={isActive ? 90 : 0} />}>
            <Panel header={
                <div>
                    <Space direction="vertical" style={{ width: "100%" }}>
                        <Title level={3}>{measure.article_no}.{measure.measure_no} - {measure.measure_name} <span style={{ fontSize: 15, color: "white" }}>{measure.actions.length > 0 ? measure.actions.length : '00'} Items</span> </Title>

                        <Row justify="space-between" gutter={{
                            xs: 8,
                            sm: 16,
                            md: 24,
                            lg: 32,
                        }}>
                            <Col span={7}>
                                <div className='card-item'>
                                    <Row gutter={16}>
                                        <Col span={6} >
                                            <Avatar className="list-avatar-progress" shape="circle" size={64} icon={<RiseOutlined />}>
                                            </Avatar>
                                        </Col>
                                        <Col span={18} className="list-content">
                                            <Title style={{ color: "white", fontWeight: 600 }} className='list-title' level={5} type="secondary">Completion Progress</Title>
                                            <Progress percent={measure.measure_completion} style={{ width: 200 }}
                                                strokeColor={{ from: '#00C874', to: '#FFF0FF' }} />
                                        </Col>

                                    </Row>

                                </div>
                            </Col>
                            <Col span={7}>
                                <div className='card-item'>

                                    <Row gutter={16}>
                                        <Col span={6}>
                                            <Avatar className="list-avatar-date" shape="circle" size={64} icon={<ProjectOutlined />}>
                                            </Avatar>
                                        </Col>
                                        <Col span={18} className="list-content">
                                            <Title style={{ color: "white", fontWeight: 600 }} className='list-title' level={5} type="secondary">Definitive Date</Title>
                                            <Title style={{ width: 200, margin: 0, color: "white" }} level={5}>{new Date(measure.measure_def_date).getFullYear()}-{(new Date(measure.measure_def_date).getMonth() + 1).toString().padStart(2, '0')}-{(new Date(measure.measure_def_date).getDate()).toString().padStart(2, '0')}</Title>
                                        </Col>

                                    </Row>


                                </div>
                            </Col>
                            <Col span={7}><div className='card-item'>
                                <Row gutter={16}>
                                    <Col span={6}>
                                        <Avatar className="list-avatar-category" shape="circle" size={64} icon={<PieChartOutlined />}>
                                        </Avatar>
                                    </Col>
                                    <Col span={18} className="list-content">
                                        <Title style={{ color: "white", fontWeight: 600 }} className='list-title' level={5} type="secondary">Category</Title>
                                        <Title style={{ width: 200, margin: 0, color: "white" }} level={5}>{measure.category}</Title>
                                    </Col>

                                </Row>



                            </div></Col>
                            {/* <Col span={6}><div style={style}>{measure.measure_completion}%</div></Col> */}
                        </Row>
                    </Space>


                    {/* <div style={{ display: 'flex', justifyContent: 'space-between', marginLeft: 50, marginRight: 50, alignItems: 'center' }}>

                        <Progress percent={measure.measure_completion} style={{ width: 200 }}
                            strokeColor={{ from: '#E2445C', to: '#FFF0FF' }} />
                        <div>
                            <Text type="secondary">Definitive Date</Text>
                            <Title style={{ margin: 0 }} level={5}>{new Date(measure.measure_def_date).getFullYear()}-{(new Date(measure.measure_def_date).getMonth() + 1).toString().padStart(2, '0')}-{(new Date(measure.measure_def_date).getDate()).toString().padStart(2, '0')}</Title>
                        </div>
                        <div>
                            <Text type="secondary">Category</Text>
                            <Title style={{ margin: 0 }} level={5}>{measure.category}</Title>
                        </div>
                        <div style={{ background: '#E2445C', color: 'white', padding: 5, borderRadius: 10 }}>
                            {measure.measure_completion}%
                        </div>
                    </div> */}
                </div>
            }
                key="1" style={{ width: '100%' }}>
                <Space direction='vertical' style={{ width: '100%' }}>
                    {measure.actions.map((action, index) => {

                        return <ActionItem key={index} action={action} pending={index} />;


                    })}
                </Space>
            </Panel>
        </Collapse>
    </div>
        : <div style={{
            display: 'flex',
            width: '100%',
            height: '100vh',
            justifyContent: 'center',
            alignItems: 'center'
        }}><Result
                title="Nothing Selected"
            />
        </div>
};

export default MeasureGroup;