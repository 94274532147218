import React from 'react';
import { Table } from 'antd';
import ReactDOMServer from 'react-dom/server';
import './table.styles.css';

function CustomTable(props) {
  const { columns, data, pagination, scroll, loadingRow, headerClass } = props;
  function removeValues(obj) {
    if (Array.isArray(obj)) {
      return obj.map(removeValues);
    } else if (obj && typeof obj === "object") {
      return Object.fromEntries(
        Object.entries(obj).map(([key, value]) => [key, removeValues(value)])
      );
    } else {
      return ""; // or ""
    }
  }

  let data2 = data
  let fullLength = pagination ? pagination.pageSize : 10
  let minLength = data.length % fullLength

  if (0 < minLength) {
    let emptyData = removeValues(data[0])
    emptyData.moreData = true
    for (let index = minLength; index < fullLength; index++) {
      data2.push(emptyData)
    }
  }

  console.log("data2", minLength)
  return (
    <Table
      columns={columns}
      dataSource={data2}
      scroll={scroll}
      rowClassName={(record, index) => {

        if (record.moreData && loadingRow) {
          // add a class name to the row for any additional empty rows
          return "loading_row";
        }
        return "";
      }}
      sticky
      pagination={pagination}
      locale={{ emptyText: '   ' }}
    />
  );
}

export default CustomTable;