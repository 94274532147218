import { Avatar, Button, Col, ConfigProvider, Row } from 'antd'
import React from 'react'
import { UserOutlined, CaretDownOutlined, ArrowLeftOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';

const DonarUser = (props) => {
    return (
        <>
            <Row
                justify="end"
                style={{ paddingLeft: 50 }}

            >
                <Col span={20}>
                    <ConfigProvider
                        theme={{
                            token: {
                                colorPrimary: '#006666',
                            },
                        }}
                    >
                        <Button
                            onClick={() => { window.location.href = "/newdashboard" }}
                            // disabled={disable ? true : false}
                            shape="circle"
                            icon={<ArrowLeftOutlined style={{ color: "#006666" }} />}
                            style={{ width: 50, height: 50, backgroundColor: "#A8CCCC", color: "black", borderRadius: 50, zIndex: 999 }}
                            disabled={window.localStorage.getItem('view_only') == 1}
                        />
                    </ConfigProvider>
                </Col>
                <Col span={4}>


                    <div className="profile-user-donor">
                        {/* <Link Link to='/donorprofile'> */}
                        <div style={{ display: "flex", justifyContent: "center" }}>
                            <div className="name-donor">
                                <p>{props.donorUser}</p>
                                <p>{props.designation}</p>
                            </div>
                            <div className="avatar-donor" style={{ margin: "0 12px" }}>
                                <Avatar size={64} shape='square' icon={<UserOutlined />}></Avatar>
                            </div>
                            <div className="expand-donor">
                                <CaretDownOutlined />
                            </div>
                        </div>
                        {/* </Link> */}
                    </div>

                </Col>
            </Row>
        </>
    )
}

export default DonarUser
