import { Avatar, Button, Popover, Typography } from 'antd';
import React from 'react'
import { useEffect, useState } from 'react';
import { getUserById } from '../../../../api';
import { UserOutlined } from "@ant-design/icons";

function NewUserData() {
    const [userData, setUserData] = useState(null)

    useEffect(() => {
        console.log("user--------------->>>>")
        getUserData()

    }, [])

    const getUserData = async function () {
        const out = await getUserById(await window.localStorage.getItem('user_id'))
        if (out.status === 200) {
            let user = out.data.user;
            if (window.localStorage.getItem('ntfc_level') == 7) {
                user.userDesignation = "Super Admin"
                // userDashboardName = "Dashboard - NTFC Secretariat "
                // myTheme.dashBordBack = "#005476"
            } else if (window.localStorage.getItem('ntfc_level') == 6) {
                user.userDesignation = "Secretariat"
                // userDashboardName = "Dashboard - NTFC Secretariat"
                // myTheme.dashBordBack = "#005476"

            } else if (window.localStorage.getItem('ntfc_level') == 5) {
                user.userDesignation = "Head of the Department"
                // userDashboardName = "Dashboard - " + (user.agency_name ? user.agency_name : "N/A")


            } else if (window.localStorage.getItem('ntfc_level') == 4) {
                user.userDesignation = "Technical User"
                // userDashboardName = "Dashboard - " + (user.agency_name ? user.agency_name : "N/A")


            } else if (window.localStorage.getItem('ntfc_level') == 3) {
                // userDesignation = "Private"
                user.userDesignation = "Donor"
                // userDashboardName = "Dashboard - " + (user.agency_name ? user.agency_name : "N/A")


            } else if (window.localStorage.getItem('ntfc_level') == 2) {
                user.userDesignation = "Private Sector User"
                // userDashboardName = "Dashboard -  " + (user.agency_name ? user.agency_name : "N/A")


            } else if (window.localStorage.getItem('ntfc_level') == 1) {
                user.userDesignation = "Director General"
                // userDashboardName = "Dashboard - " + (user.agency_name ? user.agency_name : "N/A")

            } else if (window.localStorage.getItem('ntfc_level') == 0) {
                user.userDesignation = "Presidential Secretariat"
                // userDashboardName = "Dashboard - " + (user.agency_name ? user.agency_name : "N/A")

            } else if (window.localStorage.getItem('ntfc_level') == -1) {
                user.userDesignation = "Other"
                // userDashboardName = "Dashboard - " + (user.agency_name ? user.agency_name : "N/A")

            }


            setUserData(user)


            console.log("user--------------->>>>", user)

        }


    }
    return (
        <div className="user-details" >

            <div className="name-designation-new">

                <Typography className="user-name-new" style={{ whiteSpace: "nowrap", color: "white" }}>

                    {(userData ? userData?.first_name : "") + " " + (userData ? userData?.last_name : "")}
                </Typography>

                <Typography className="user-designation-new" style={{ whiteSpace: "nowrap", color: "white" }}>

                    {(userData ? userData.agency_name : "") + " - " + (userData ? userData.userDesignation : "")}
                </Typography>



            </div>
            <Popover
                content=
                {
                    <Button

                        onClick={async () => {
                            await window.localStorage.removeItem('ntfc_token');
                            window.location.href = "/login";
                        }}>
                        Logout
                    </Button>
                }
                trigger="click">

                <Avatar className="avatar" shape="square" size={40} icon={<UserOutlined />} />

            </Popover>
        </div>
    )
}

export default NewUserData