import { Segmented, Select, Space, Typography } from "antd";
import { useState } from "react";

const DonutLegend = () => {
  return (
    <div className="flex-container">
      <div className="legend-itm-wrapper">
        <div className="lengend-color" />
        <div className="legend-lbl">To Do</div>
      </div>
      <div className="legend-itm-wrapper">
        <div className="lengend-color" style={{ background: "#DB3031" }} />
        <div className="legend-lbl">In Progress</div>
      </div>
      <div className="legend-itm-wrapper">
        <div className="lengend-color" style={{ background: "#FDAD42" }} />
        <div className="legend-lbl">Completed</div>
      </div>
      <div className="legend-itm-wrapper">
        <div className="lengend-color" style={{ background: "#8676FF" }} />
        <div className="legend-lbl">Delayed</div>
      </div>
      <div className="legend-itm-wrapper">
        <div className="lengend-color" style={{ background: "#0492c2" }} />
        <div className="legend-lbl">Missed</div>
      </div>
    </div>
  );
};

export default DonutLegend;
