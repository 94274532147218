import { Avatar, Button, Popover, Typography } from 'antd';
import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import { UserOutlined } from "@ant-design/icons";
import { getUserById } from '../../../api';
import { NewColorTheme } from '../../../appConst';


function UserDetails({ username, designation, dashboardName, user, calender }) {

    const [dateTime, setDateTime] = useState(new Date());
    const [userDesig, setUserDesig] = useState("");
    const [userData, setUser] = useState()

    useEffect(() => {

        loadData()
        const intervalId = setInterval(() => {
            setDateTime(new Date());
        }, 1000);
        return () => clearInterval(intervalId);
    }, [])

    const loadData = async () => {
        const out = await getUserById(await window.localStorage.getItem('user_id'))
        if (out.status == 200) {
            let user = out.data.user

            if (window.localStorage.getItem('ntfc_level') == 7) {
                user.userDesignation = "Super Admin"
                // userDashboardName = "Dashboard - NTFC Secretariat "
                // myTheme.dashBordBack = "#005476"
            } else if (window.localStorage.getItem('ntfc_level') == 6 && window.localStorage.getItem('role_id') == 99) {
                user.userDesignation = "PR to WTO"
                // userDashboardName = "Dashboard - NTFC Secretariat"
                // myTheme.dashBordBack = "#005476"

            } else if (window.localStorage.getItem('ntfc_level') == 6 && window.localStorage.getItem('view_only') == 1) {
                user.userDesignation = "Co-chair/Chair Person"
                // userDashboardName = "Dashboard - NTFC Secretariat"
                // myTheme.dashBordBack = "#005476"

            } else if (window.localStorage.getItem('ntfc_level') == 6) {
                user.userDesignation = "Secretariat"
                // userDashboardName = "Dashboard - NTFC Secretariat"
                // myTheme.dashBordBack = "#005476"

            } else if (window.localStorage.getItem('ntfc_level') == 5) {
                user.userDesignation = "Head of the Department"
                // userDashboardName = "Dashboard - " + (user.agency_name ? user.agency_name : "N/A")


            } else if (window.localStorage.getItem('ntfc_level') == 4) {
                user.userDesignation = "Technical User"
                // userDashboardName = "Dashboard - " + (user.agency_name ? user.agency_name : "N/A")


            } else if (window.localStorage.getItem('ntfc_level') == 3) {
                // userDesignation = "Private"
                user.userDesignation = "Donor"
                // userDashboardName = "Dashboard - " + (user.agency_name ? user.agency_name : "N/A")


            } else if (window.localStorage.getItem('ntfc_level') == 2) {
                user.userDesignation = "Private Sector User"
                // userDashboardName = "Dashboard -  " + (user.agency_name ? user.agency_name : "N/A")


            } else if (window.localStorage.getItem('ntfc_level') == 1) {
                user.userDesignation = "Director General"
                // userDashboardName = "Dashboard - " + (user.agency_name ? user.agency_name : "N/A")

            } else if (window.localStorage.getItem('ntfc_level') == 0) {
                user.userDesignation = "Presidential Secretariat"
                // userDashboardName = "Dashboard - " + (user.agency_name ? user.agency_name : "N/A")

            } else if (window.localStorage.getItem('ntfc_level') == -1) {
                user.userDesignation = "Other"
                // userDashboardName = "Dashboard - " + (user.agency_name ? user.agency_name : "N/A")

            }
            setUser(user)
        }
    }


    const dateOptions = {
        day: '2-digit', month: '2-digit', year: 'numeric'
    };

    const timeOptions = {
        hour: "numeric",
        minute: "numeric",
        // second: "numeric",
    };

    const date = dateTime.toLocaleDateString('en-GB', dateOptions).replace(/\//g, '/');
    const time = dateTime.toLocaleTimeString(undefined, timeOptions);

    const getDesignation = (desig) => {
        console.log("Desig", desig);
        let designation_out = "";
        switch (Number(desig)) {
            case 1:
                designation_out = "Director General";
                break;
            case 2:
                designation_out = "Private Sector User";
                break;
            case 3:
                designation_out = "Donor";
                break;
            case 4:
                designation_out = "Technical User";
                break;
            case 5:
                designation_out = "Head of the Department";
                break;
            case 6:
                designation_out = "Secretariat";
                break;
            case 7:
                designation_out = "Super Admin";
                break;
            default:
                break;
        }
        console.log("RETURNING", designation_out);
        return designation_out;
    }

    useEffect(() => {
        setUserDesig(getDesignation(designation));
    }, [designation])

    return (

        <div className="user-details" >

            <div className="name-designation">

                <Typography className="user-name" style={{ color: calender ? NewColorTheme.primaryColor : 'white' }}>
                    {username}
                </Typography>
                <br />
                <Typography className="user-designation" style={{ color: calender ? NewColorTheme.primaryColor : 'white' }}>
                    {(userData ? userData.agency_name : "") + " - " + (userData ? userData.userDesignation : "")}
                </Typography>


            </div>
            <Popover
                content=
                {
                    <Button

                        onClick={async () => {
                            await window.localStorage.removeItem('ntfc_token');
                            window.location.href = "/login";
                        }}>
                        Logout
                    </Button>
                }
                trigger="click">

                <Avatar className="avatar" shape="square" size={50} icon={<UserOutlined />} />

            </Popover>

            {/* <div className="date-time">
                <Typography className="time" style={{ color: 'white' }}>  {time}</Typography>
                <Typography className="date2" style={{ color: 'white' }}>  {date}</Typography>

            </div> */}



        </div>
    )
}

export default UserDetails