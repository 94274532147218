import React, { useEffect, useState } from 'react';
import ReactECharts from 'echarts-for-react';
import { Tooltip } from 'antd';

const DonutForPopup = ({ data, tab }) => {
    console.log("data--------------------<<<<oooooooo", data)
    const [percentage, setPercentage] = useState()


    useEffect(() => {
        setPercentage(data)

    }, [tab])

    let option = {
        // title: {
        //     text: 'Referer of a Website',
        //     subtext: 'Fake Data',
        //     left: 'center'
        // },
        tooltip: {
            trigger: 'item'
        },

        legend: {
            orient: 'vertical',
            top: 'bottom',
            bottom: '-1%',

            // show: false
        },
        series: [
            {
                name: 'Agency wise contribution',
                type: 'pie',
                radius: '50%',
                itemStyle: {

                    borderColor: '#fff',
                    borderWidth: 2
                },
                data: [{
                    value: percentage ? percentage.value.toFixed(2) : 0,
                    name: percentage ? percentage.name : "",

                },
                {
                    value: percentage ? (100 - percentage.value).toFixed(2) : 0, name: "Other"
                }],
                label: {
                    show: true,
                    position: 'outside', // Display labels outside the pie
                    formatter: function (params) {
                        const { name, value } = params;
                        const formattedValue = parseFloat(value).toFixed(2);
                        return `${formattedValue}%`;
                    }, // Use '{b}' for name and '{c}' for value
                    fontSize: 12, // Adjust the font size as needed
                    edgeDistance: "25%",
                    lineHeight: 15,
                },
                labelLine: {
                    show: true,
                    // smooth: true,
                    minTurnAngle: 45,
                    maxSurfaceAngle: 45

                },
                emphasis: {
                    label: {
                        show: true,
                        fontSize: 12,
                        fontWeight: 'bold'
                    },

                },

            }
        ]
    };


    return <ReactECharts style={{ top: "20%" }}

        option={option}

    />;
};

export default DonutForPopup;