import { Avatar, Button, Col, ConfigProvider, Divider, Dropdown, Form, List, Modal, Row, Segmented, Select, Space, Typography } from 'antd';
import { useState } from 'react';
import { CloseOutlined, PlusOutlined, UserOutlined } from "@ant-design/icons";
import PersonalChat from './PersonalChat';
import GroupChat from './GroupChat';
import { addNewMember, getAgencies, getUsersByAgencies } from '../../api';
import { useEffect } from 'react';
import { toast, } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { NewColorTheme } from '../../appConst';

const AddMembers = ({ selectedChat, refreshChats }) => {

    const [form] = Form.useForm();
    const [participants, setParticipants] = useState([]);
    const [selectedAgencies, setSelectedAgencies] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [chatType, setChatType] = useState('Personal');
    const [currentUsers, setUsers] = useState([]);
    const [agencies, setAgencies] = useState([]);
    const [isFormVisible, setIsFormVisible] = useState(false);

    const handleButtonClick = () => {
        setIsFormVisible(!isFormVisible); // Toggle the visibility of the form
    };

    const handleFormSubmit = (values) => {
        // Handle form submission logic here
        console.log(values);
    };

    useEffect(() => {
        form.resetFields()
        setIsFormVisible(false)
        getAgencies().then((out) => {
            if (out) {
                out.data.out.unshift({ label: "All Agencies", value: 0 })
                setAgencies(out.data.out)
            }
        });
    }, []);

    const getParticipants = async (agencies) => {
        let body = {
            agencies: agencies
        }
        await getUsersByAgencies(body).then(out => {
            const filteredSecondArray = out.data.out.filter(item => {
                const found = selectedChat.members.find(obj => obj.uuid === item.value);
                return found === undefined;
            });
            setUsers(filteredSecondArray.map((obj, index) => ({ ...obj, label: obj.designation + " - " + obj.label })));
        });
    }

    const selectAgency = async (val) => {
        if (val.length == 0) {
            setAgencies(agencies.map((obj, index) => ({ ...obj, disabled: false })));
            setUsers([])
            setParticipants([])
            setSelectedAgencies([])
        } else if (val[0] == 0) {
            setSelectedAgencies(val)
            setAgencies(agencies.map((obj, index) => index === 0 ? obj : { ...obj, disabled: true }));
            getParticipants(val)
        } else {
            setSelectedAgencies(val)
            setAgencies(agencies.map((obj, index) => index === 0 ? { ...obj, disabled: true } : { ...obj, disabled: false }));
            getParticipants(val)
        }
    }
    const showModal = () => {
        setIsModalOpen(true);
    };
    const handleOk = () => {
        setIsModalOpen(false);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
        form.resetFields()
        setIsFormVisible(false)

    };

    const handleMenuClick = (e) => {

        console.log('click', e);
    };
    const menuProps = {
        // items,
        onClick: handleMenuClick,
    };

    const addMember = async () => {

        addNewMember({
            chat_id: selectedChat?.id,
            participant: participants.value
        }).then((out) => {
            if (out) {
                handleOk()
                refreshChats()
                console.log("NEW CHAT", out);
                form.resetFields()
                setUsers([])
                setParticipants([])
                setSelectedAgencies([])
                toast.success("New member has been added successfully.", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
            }
        }).catch((err) => {
            console.log("CCHAT ERROR", err);
        })
    }

    return (
        <>
            <div style={{ width: '100%' }}>
                <ConfigProvider
                    theme={{
                        token: {
                            colorPrimary: NewColorTheme.primaryColor,
                            width: "100%"
                        },
                    }}
                >
                    <Button type="primary" icon={<UserOutlined />} onClick={showModal} size={'middle'} />
                </ConfigProvider>
            </div>

            <Modal
                closable={false}

                className="custom-modal"
                // width={700}
                footer={false}
                onCancel={handleCancel}
                title={
                    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                        <div><Typography className='custom-modal-title' level={2}>Chat Members</Typography></div>
                        <div>
                            <ConfigProvider
                                theme={{
                                    token: {
                                        colorPrimary: NewColorTheme.primaryColor,
                                    },
                                }}
                            >
                                <Button onClick={handleCancel} icon={<CloseOutlined style={{ color: 'white' }} />} type="primary" shape="circle">
                                </Button>
                            </ConfigProvider>
                        </div>
                    </div>
                } open={isModalOpen} onOk={handleOk} centered>

                <div className='custom-modal-body'>
                    <ConfigProvider
                        theme={{
                            token: {
                                colorPrimary: NewColorTheme.primaryColor,
                            },
                        }}
                    >
                        <Button type='primary' onClick={handleButtonClick}>Add New Member</Button>
                    </ConfigProvider>
                    {isFormVisible && (
                        <Form
                            style={{ marginTop: 10 }}
                            form={form}
                            layout='vertical'
                            onFinish={addMember}

                        >

                            <Row gutter={[8, 8]} style={{ width: "100%" }}>
                                <Col span={24}>
                                    <Form.Item
                                        label="Select Agency"
                                        name="agency"
                                        required={true}
                                        rules={[{
                                            required: true,
                                            message: <p style={{ marginLeft: "12px" }}>Agency is required</p>,
                                        }]}

                                    >
                                        <Select
                                            value={selectedAgencies}
                                            mode='multiple'
                                            placeholder="Select An Agency"
                                            allowClear
                                            style={{ width: "100%" }}
                                            options={agencies}
                                            onChange={selectAgency}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={24} >
                                    <Form.Item
                                        required={true}
                                        label="Select Member"
                                        name="participants"
                                        rules={[{
                                            required: true,
                                            message: <p style={{ marginLeft: "12px" }}>Member is required</p>,
                                        }]}
                                    >
                                        <Select
                                            showSearch
                                            placeholder="Select a member"
                                            optionFilterProp="children"
                                            onChange={(val, opts) => setParticipants(opts)}
                                            style={{ width: "100%" }}
                                            value={participants}
                                            filterOption={(input, option) =>
                                                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                            }
                                            options={currentUsers}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={24} >
                                    <div style={{ display: "flex", justifyContent: "end", alignItems: "center" }}>
                                        <ConfigProvider
                                            theme={{
                                                token: {
                                                    colorPrimary: NewColorTheme.primaryColor,
                                                },
                                            }}
                                        >
                                            <Button type='primary' size='middle' style={{ width: '100%' }} htmlType='submit'
                                            // onClick={createNewChat}
                                            >
                                                Add Member
                                            </Button>
                                        </ConfigProvider>
                                    </div>


                                </Col>
                                <Col xs={24}>
                                    <Divider />

                                </Col>
                            </Row>

                        </Form>

                    )}

                    <Space className='custom-scroll-div' direction='vertical' style={{ height: 400, width: '100%', display: "flex", overflow: 'auto', overflowX: "hidden", padding: "0px 10px" }}>

                        <List
                            itemLayout="horizontal"
                            dataSource={selectedChat?.members}
                            renderItem={(item, index) => (
                                <List.Item>
                                    <List.Item.Meta
                                        avatar={<Avatar icon={<UserOutlined />} />}
                                        title={<a href="https://ant.design">{item.user.split(" - ")[0]}</a>}
                                        description={item.user.split(" - ")[1]}
                                    />
                                </List.Item>
                            )}
                        />

                    </Space>
                </div>
            </Modal>

        </>
    );
};
export default AddMembers;