import React from "react";
import DonarUser from "../../Donors/components/DonarUser";
import { Card, Col, Divider, Row, Button, ConfigProvider } from "antd";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { useEffect } from "react";
import { getUserData } from "../../Donors/getCounts";
import { useState } from "react";
import UserDetails from "../../../Components/DashBoard/UserDetails";
import PageHeader from "../../Inquiries/PageHeader";
import "./Form.css";
import { NewColorTheme } from "../../../appConst";
export default function FormLayouts({
  AllEvents = false,
  scroll = true,
  title,
  formSide,
  table,
  formTitle,
  formCardColor = "#A8CCCC",
  backUrl
}) {
  const [user, setUser] = useState({});
  useEffect(() => {
    getUserDetails();
  }, []);
  const getUserDetails = async function () {
    const user = await getUserData();
    console.log(user);
    setUser(user);
  };

  return (
    <div style={{ overflow: "hidden" }}>
      <div
        className="bgSidePanel"
        style={{ overflow: "hidden", background: NewColorTheme.primaryColor }}
      ></div>
      <div className="sidepanel-left" style={{ background: "white" }}></div>

      <div>
        <PageHeader AllEvents={AllEvents} title={title} user={user} backUrl={backUrl} />
        <div className="form-layout-div-config">
          <Row gutter={[16, 16]} style={{ height: "100%" }}>
            {formSide && (
              <Col lg={7} xs={24} style={{ height: "100%" }}>
                <div
                  className={scroll ? "custom-scroll-div" : ""}
                  style={{
                    background: "#f4f4f9",
                    height: "calc(85vh - 2%)",
                    maxHeight: "100%",
                    overflowY: "auto",

                    padding: "10px 20px",
                    overflowX: "hidden",
                  }}
                >
                  <Divider orientation="left" orientationMargin="0">
                    {formTitle}
                  </Divider>
                  {formSide}
                </div>
              </Col>
            )}

            <Col
              lg={formSide ? 17 : 24}
              xs={24}
              style={{ overflowY: "hidden", height: "100%" }}
            >
              <Card
                className="full-card "
                style={{
                  height: "calc(85vh - 2%)",
                  overflow: "auto",
                  maxHeight: "100%",
                  overflowX: "hidden",
                }}
              >
                {table}
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
}
