import { useNavigate } from 'react-router-dom';

import { Card, Col, ConfigProvider, Divider, Grid, Modal, Row, Space, Tooltip, Typography, message } from "antd";
import { Button, Form, Input } from "antd";
import "./login2.styles.css";
import { login } from "../../api";
import { UserOutlined, LockOutlined, CloseOutlined } from "@ant-design/icons";
import { ToastContainer, toast, Zoom } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useEffect, useState } from "react";
import { NewColorTheme } from '../../appConst';
import axios from 'axios';
const { Meta } = Card;
const { Title, Link } = Typography;

function Page() {
  const [form] = Form.useForm();
  const [messageApi, contextHolder] = message.useMessage();
  const [showError, setShowError] = useState(false);
  const navigate = useNavigate();


  const success = () => {
    messageApi.open({
      type: "success",
      content: "Log in successful",
      style: {
        marginTop: "30vh",
        height: "100vh",
        fontSize: "20px",
      },
      duration: 3,
    });
  };

  const error = () => {
    messageApi.open({
      type: "error",
      content: "Log in unsuccessful",
      style: {
        marginTop: "30vh",
        height: "100vh",
        fontSize: "20px",
      },
      duration: 3,
    });
  };



  useEffect(() => {
    const preventBack = (event) => {
      event.preventDefault();
      navigate(1); // Forward one step to stay on the current page
    };

    const handleBeforeUnload = () => "Are you sure you want to leave?"; // Show a confirmation message when the user tries to leave the page

    window.addEventListener('beforeunload', handleBeforeUnload);
    window.addEventListener('popstate', preventBack);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
      window.removeEventListener('popstate', preventBack);
    };
  }, [navigate]);








  const onFinish = async (values) => {
    console.log("Success:", values);
    // if (values.username === "admin") {
    //   window.sessionStorage.setItem('admin', true);
    // } else {
    //   window.sessionStorage.setItem('admin', false);
    // }

    login(values)
      .then((out) => {
        if (out.data.status === 200) {
          window.localStorage.setItem("ntfc_token", out.data.token);
          window.localStorage.setItem("ntfc_agency", out.data.agency);
          window.localStorage.setItem("ntfc_level", out.data.level);
          window.localStorage.setItem("ntfc_name", out.data.name);
          window.localStorage.setItem("user_id", out.data.id);
          window.localStorage.setItem("view_only", out.data.view_only);
          window.localStorage.setItem("role_id", out.data.role_id);
          window.localStorage.setItem('ntfc_session', 1);
          if (out.data.level == 0 || out.data.level == 1) {
            window.location.href = "/presidentdashbaord";
          } else {
            window.location.href = "/newDashboard";
          }
        } else {
          setShowError(true);
          toast.error(
            out.data ? out.data.message : 'Login unsuccessful! You may have entered an incorrect username or password',
            {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            }
          );
          // messageApi.open({
          //   type: 'error',
          //   content: 'Login unsuccessful',
          //   style: {
          //     marginTop: '30vh',
          //     height: '100vh',
          //     fontSize: '20px'
          //   },
          //   duration: 3
          // });
        }
      })
      .catch((err) => {
        toast.error(err.data ? err.data.message : "Login unsuccessful", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        // messageApi.open({
        //   type: 'error',
        //   content: 'Login unsuccessful',
        //   style: {
        //     marginTop: '30vh',
        //     height: '100vh',
        //     fontSize: '20px'
        //   },
        //   duration: 3
        // });
      });

    // let new_values = {};

    // new_values['email'] = values.username;
    // new_values['pwd'] = values.password;

    // window.location.href = "/dashboard";

    // const logged = await login(new_values);
    // if (logged) {
    //   setTimeout(() => {
    //     success();
    //     localStorage.setItem('ntfc_user', JSON.stringify(logged.data));
    //     window.location.href = "/dashboard";
    //   }, 1000);
    // } else {
    //   error();
    // }
  };
  const renderCenterLeftControls = ({ previousSlide }) => null; // Hide the previous button

  const renderCenterRightControls = ({ nextSlide }) => null; // Hide the next button

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const [isModalOpen, setIsModalOpen] = useState(false);
  const handleCancel = () => {
    setIsModalOpen(false);


  };

  const handleDownload = (url, name) => {
    // Define the URL of the PDF file in the public folder


    // Use Axios to make a GET request to fetch the PDF file
    axios({
      url: url,
      method: 'GET',
      responseType: 'blob',
    }).then((response) => {
      // Create a Blob object from the response data
      const blob = new Blob([response.data], { type: 'application/pdf' });

      // Create a temporary link element to trigger the download
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = name; // Optional: Change the download filename
      link.click();
    });
  };
  return (
    <div className="container2">
      <div
        className="container2 mobile-hide"
        style={{
          position: "relative",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
          width: "100%",
        }}
      >
        <video
          src={"/back.webm"}
          controls={false}
          autoPlay
          loop
          muted
          style={{ width: "100%", height: "100%", objectFit: "cover" }}
        />
        {/* <Carousel
          // className="custom-carousel"
          autoplay={true}
          wrapAround={true}
          autoplayInterval={5000}
          withoutControls={true}
          speed={1000}
          transitionMode="fade"


        >
          <img src="/ntfc_background.jpg" alt="image2" />
          <img src="/ntfc_background.jpg" alt="image2" />
          <img src="/ntfc_background.jpg" alt="image2" />


        </Carousel> */}
      </div>
      {contextHolder}
      <div className="overlay"></div>
      <Card className="no-padding2 login-card-container">
        <Row gutter={16}>
          <Col xs={24} sm={12} className="mobile-hide">
            <div
              style={{
                padding: "25px",
                height: "75vh",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "start",
                gap: 20,
              }}
            >
              {/* <img src="/ntfc.png" width={125} alt="logo" /> */}
              <Title
                className="login-welcome"
                level={1}
                style={{ color: "white" }}
              >
                Welcome!
              </Title>
              <div style={{ marginTop: -10 }}>
                <Typography.Title
                  level={2}
                  style={{ color: "white", margin: 0 }}
                >
                  NTFC Monitoring Tool
                </Typography.Title>
                {/* <Typography.Title level={2} style={{ color: "white", margin: 0 }}>Monitoring Tool</Typography.Title> */}
              </div>

              <p
                className="tool-details"
                style={{ textAlign: "justify", color: "white" }}
              >
                NTFC monitoring tool is a tool designed to monitor the progress
                of the TFA implementation in Sri Lanka. Donor management,
                Inquiry management, Calendar, and Internal chat facility are
                some of the other features of this tool that support not only
                TFA implementation progress monitoring but also, the improving
                the efficiency of the agencies.
              </p>
              <div style={{ width: "100%", gap: 10, display: 'flex' }}>
                <Button
                  style={{
                    borderRadius: 10,
                    color: "#142348",
                    backgroundColor: "white",
                    width: "50%",
                  }}
                  type="default"
                  href="/signup"
                  size="large"
                >
                  <b>Register Now</b>
                </Button>
                <Button
                  style={{
                    borderRadius: 10,
                    color: "white",
                    backgroundColor: "transparent",
                    width: "50%",
                  }}
                  type="default"
                  onClick={() => setIsModalOpen(true)}
                  size="large"
                >
                  <b>Guidelines</b>
                </Button>
              </div>

            </div>
          </Col>
          <Col xs={24} sm={12}>
            <div className="form-side" >
              <div style={{ marginBottom: '3%' }}>
                <img src="/ntfc.png" className="login-img" alt="logo" />
              </div>

              <div>
                <p
                  className="login-info-msg"
                  style={{ margin: 0, color: "#ADA4A9" }}
                >
                  Enter your credentials to login to your account.
                </p>
                {/* <p style={{ margin: 0, color: '#ADA4A9', marginBottom: 15 }}></p> */}
              </div>

              <Form
                style={{ width: "100%" }}
                form={form}
                layout="vertical"
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
              >
                <Form.Item
                  style={{ width: "100%" }}
                  name="email"
                  required
                  rules={[
                    {
                      type: "email",
                      message: (
                        <p style={{ marginLeft: "12px" }}>Invalid Email</p>
                      ),
                    },
                    {
                      required: true,
                      message: (
                        <p style={{ marginLeft: "12px" }}>Email is required</p>
                      ),
                    },
                  ]}
                >
                  <Input
                    type="text"
                    placeholder="Email"
                    size="large"
                    style={{ borderRadius: 10, width: "100%" }}
                    suffix={<UserOutlined style={{ color: "#D7D3D5" }} />}
                    onChange={() => setShowError(false)}
                  />
                </Form.Item>
                <Form.Item
                  required
                  name="pwd"
                  rules={[
                    {
                      required: true,
                      message: (
                        <p style={{ marginLeft: "12px" }}>
                          Password is required
                        </p>
                      ),
                    },
                  ]}
                >
                  <Input.Password
                    onChange={() => setShowError(false)}
                    type="password"
                    placeholder="Password"
                    size="large"
                    style={{ borderRadius: 10 }}
                    suffix={<LockOutlined style={{ color: "#D7D3D5" }} />}
                  />
                </Form.Item>
                {showError && (
                  <span style={{ color: "red" }}>
                    You may have entered an incorrect username or password.
                  </span>
                )}

                <Form.Item>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Link style={{ color: "#2B52B4" }} href="/update_password">
                      <b>Forgot Password?</b>
                    </Link>
                  </div>
                </Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  style={{
                    background: "#2B52B4",
                    color: "white",
                    fontWeight: "bold",
                    borderRadius: 10,
                    padding: "0 40px",
                    width: "100%",
                  }}
                  size="large"
                >
                  Login
                </Button>
              </Form>
              <div className="pc-hide">
                <span>
                  Don't have an account?
                  <a
                    href="/signup"
                    style={{ textAlign: "left", color: "#2B52B4" }}
                  >
                    <b> Register Now</b>
                  </a>
                </span>
              </div>
              <Divider dashed />
              <div style={{
                display: 'flex'
              }}>
                <Divider />
              </div>
              <div className='botton-logo-bar'>
                <div style={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center'
                }}>
                  <span style={{ color: "#848ca1", textAlign: "center", fontSize: 15, backgroundColor: 'white' }}>
                    Supported by
                  </span>
                </div>

                <div style={{
                  width: "100%"
                }}>
                  <div className='login--bottom-card-img'>
                    <img className='login--bottom-card-img3' src="/gov.png" alt="logo" />

                    <img className='login--bottom-card-img1' src="/ITC_logo_EN_RGB.png" alt="logo" />
                    <img className='login--bottom-card-img2' src="/image004.png" alt="logo" />
                  </div>

                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Card>





      <ToastContainer />
      <Modal
        closable={false}

        className="custom-modal"
        width={"60%"}
        footer={false}
        onCancel={handleCancel}
        title={
          <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
            <div><Typography className='custom-modal-title' level={2}>Guidelines</Typography></div>
            <div>
              <ConfigProvider
                theme={{
                  token: {
                    colorPrimary: NewColorTheme.primaryColor,
                  },
                }}
              >
                <Button onClick={handleCancel} icon={<CloseOutlined style={{ color: 'white' }} />} type="primary" shape="circle">
                </Button>
              </ConfigProvider>
            </div>
          </div>
        } open={isModalOpen} centered>

        <div className='custom-modal-body' style={{ overflow: 'auto', height: "55vh" }}>
          <Row

            gutter={[{
              xs: 8,
              sm: 16,
              md: 24,

            }, {
              xs: 8,
              sm: 16,
              md: 24,

            }]}
          >

            <Col span={24}>
              <Divider orientation="left" orientationMargin="0">
                PDF guidelines
              </Divider>
            </Col>
            <Col className="gutter-row" span={{
              xs: 24,
              sm: 12,
              md: 6,

            }}>
              <Tooltip placement="bottom" title={"NTFC (Director Generl)"} >
                <Card
                  onClick={() => {
                    handleDownload('/pdf/User Manual for NTFC (Director Generl).pdf', 'NTFC Director General')
                  }}
                  hoverable
                  style={{
                    width: 184, height: "max-content",
                    padding: "5px 0  0 5px"

                  }}
                  cover={<img alt="example" src="/pdf.png" />}
                >
                  <Meta title="NTFC (Director Generl)" />
                </Card>
              </Tooltip>
            </Col>
            <Col className="gutter-row" span={{
              xs: 24,
              sm: 12,
              md: 6,

            }}>
              <Tooltip placement="bottom" title={"NTFC (Donor)"} >
                <Card
                  onClick={() => {
                    handleDownload('/pdf/User Manual for NTFC (Donor).pdf', 'Donor')
                  }}
                  hoverable
                  style={{
                    width: 184, height: "max-content",
                    padding: "5px 0  0 5px"
                  }}
                  cover={<img alt="example" src="/pdf.png" style={{
                    width: '50px !important'
                  }} />}
                >
                  <Meta title="NTFC (Donor)" />
                </Card>
              </Tooltip>
            </Col>
            <Col className="gutter-row" span={{
              xs: 24,
              sm: 12,
              md: 6,

            }}>
              <Tooltip placement="bottom" title={"NTFC (HOD)"} >
                <Card
                  onClick={() => {
                    handleDownload('/pdf/User Manual for NTFC (HOD).pdf', 'HOD')
                  }}
                  hoverable
                  style={{
                    width: 184, height: "max-content",
                    padding: "5px 0  0 5px"
                  }}
                  cover={<img alt="example" src="/pdf.png" />}
                >
                  <Meta title="NTFC (HOD)" />
                </Card>
              </Tooltip>
            </Col>
            <Col className="gutter-row" span={{
              xs: 24,
              sm: 12,
              md: 6,

            }}>
              <Tooltip placement="bottom" title={"NTFC (Private User)"} >
                <Card
                  onClick={() => {
                    handleDownload('/pdf/User Manual for NTFC (Private User).pdf', 'Private user')
                  }}
                  hoverable
                  style={{
                    width: 184, height: "max-content",
                    padding: "5px 0  0 5px"
                  }}
                  cover={<img alt="example" src="/pdf.png" />}
                >
                  <Meta title="NTFC (Private User)" />
                </Card>
              </Tooltip>
            </Col>
            <Col className="gutter-row" span={{
              xs: 24,
              sm: 12,
              md: 6,

            }}>
              <Tooltip placement="bottom" title={"NTFC (Super Admin,Admin Secretariat, Co-Chair, PR to WTO)"} >
                <Card
                  onClick={() => {
                    handleDownload('/pdf/User Manual for NTFC (Super Admin,Admin Secretariat, Co-Chair, PR to WTO).pdf', 'Co Chair')
                  }}
                  hoverable
                  style={{
                    width: 184, height: "max-content",
                    padding: "5px 0  0 5px"
                  }}
                  cover={<img alt="example" src="/pdf.png" />}
                >
                  <Meta title="NTFC (Super Admin,Admin Secretariat, Co-Chair, PR to WTO)" />
                </Card>
              </Tooltip>
            </Col>
            <Col span={24}>
              <Divider orientation="left" orientationMargin="0" >
                Video guidelines
              </Divider>
            </Col>
            <Col className="gutter-row" span={24}>
              <Tooltip placement="bottom" title={"Technical User"} >
                <Card
                  onClick={() => {
                    var url = "https://youtu.be/04-9uiAJfMU";
                    window.open(url, '_blank');
                  }}
                  hoverable
                  style={{
                    width: 184,
                    height: "max-content",
                    padding: "5px 0  0 5px"

                  }}
                  cover={<img alt="example" src="/yt.png" />}
                >
                  <Meta title="Technical User" />
                </Card>
              </Tooltip>
            </Col>
          </Row>


        </div>
      </Modal>
    </div>
  );
}

export default Page;
