import React, { useEffect, useState } from 'react'
import { getInquiryCardData } from '../../../../../api';
import { Skeleton, Typography } from 'antd';
import CountUp from 'react-countup';
import { NewColorTheme } from '../../../../../appConst';


function NewInquiryCard({ techUser }) {


    const [cardData, setCardData] = useState(null)
    const [shouldAnimate, setShouldAnimate] = useState(false);

    useEffect(() => {
        setShouldAnimate(true);
        loadData()

    }, [])

    const loadData = async () => {

        let agency
        let userLevel = await window.localStorage.getItem('ntfc_level')
        if (userLevel == 6 || userLevel == 7) {
            agency = 0
        } else {
            agency = window.localStorage.getItem('ntfc_agency')
        }

        getInquiryCardData(agency).then((out) => {

            setCardData(out.data.out)

        });

    }

    return (
        <div>
            {cardData ?
                <div >


                    <div className='inquiry-divs' style={techUser ? { flexDirection: "column", marginBottom: 10 } : {}}>
                        <Typography className='inquiry-count' style={{ color: NewColorTheme.primaryColor, fontFamily: "Figtree" }}>
                            {shouldAnimate && <CountUp end={cardData && cardData.answered ? cardData.answered : 0} duration={2} />}

                        </Typography>

                        <div
                            className='inquiry-tile-new'
                        >
                            <span className='inquiry-count-new' style={{ fontSize: 33, color: NewColorTheme.primaryColor, fontFamily: "Figtree" }}>Answered</span>
                            <span className='inquiry-count-content' style={techUser ? { color: NewColorTheme.primaryColor, fontFamily: "Figtree", textAlign: "center" } : { color: NewColorTheme.primaryColor, fontFamily: "Figtree" }}>Inquiries</span>
                        </div>

                    </div>
                    {/* <div style={{ display: "flex", justifyContent: "end", gap: 5, position: "absolute", right: "0", marginRight: "3%", top: "5px" }}>
<EyeOutlined style={{ fontSize: 25, color: "white" }} />
</div> */}
                    <div className='inquiry-divs' style={techUser ? { flexDirection: "column" } : {}}>
                        <Typography className='inquiry-count' style={{ color: NewColorTheme.primaryColor, fontFamily: "Figtree" }}>
                            {shouldAnimate && <CountUp end={cardData && cardData.pending ? cardData.pending : 0} duration={2} />}
                        </Typography>

                        <div
                            className='inquiry-tile-new'
                        >
                            <span className='inquiry-count-new' style={{ fontSize: 33, color: NewColorTheme.primaryColor, fontFamily: "Figtree" }} >Pending</span>
                            <span className='inquiry-count-content' style={techUser ? { color: NewColorTheme.primaryColor, fontFamily: "Figtree", textAlign: "center" } : { color: NewColorTheme.primaryColor, fontFamily: "Figtree" }} >Answers</span>
                        </div>

                    </div>
                    <div style={{ width: "100%", justifyContent: "end", display: "flex" }}>
                        <img src="/inq.png" alt={"inquries"} width={100}></img>

                    </div>

                </div>
                :
                <Skeleton
                    active
                    avatar
                    paragraph={{
                        rows: 4,
                    }}
                />
            }


        </div>
    )
}

export default NewInquiryCard