import { Space, Typography, Card, Statistic } from 'antd';
import CountUp from 'react-countup';
import './dashboard.styles.css';
import Lines from './Lines';
import Donut from './Donut';
import HBar from './HBar';
import Dates from './Dates';
import Counts from './Counts';
import { useEffect, useState } from 'react';
import { getCountryWiseDashboard } from '../../../api';
const { Text, Title } = Typography;

const formatter = (value) => <CountUp end={value} separator="," />;

function Page() {

  const [reports, setReports] = useState(null);

  useEffect(() => {
    getCountryWiseDashboard({}).then((out) => {
      setReports(out.data.data);
    })
  }, []);


  return (
    <div className='content'>
      <div style={{ marginTop: 10, marginBottom: 10, display: 'flex', justifyContent: 'center', width: '100%' }}>
        <Card className='completed' size='small' style={{ width: '20%', marginLeft: 5, marginRight: 5 }}>
          <Space>
            <div style={{ width: 35 }}>
              <Statistic value={reports ? reports[0].data[2].count : 0} formatter={formatter} style={{ fontWeight: 'bold' }} valueStyle={{ color: '#70D983' }} />
            </div>
            <span className="vertical-line" />
            <div style={{ display: 'flex', alignItems: 'start', flexDirection: 'column', justifyContent: 'center' }}>
              <Title style={{ margin: 0 }} level={5}>Completed</Title>
              {/* <Text type="secondary" style={{ lineHeight: 0.8 }}>Measures</Text> */}
            </div>
          </Space>
        </Card>
        <Card className='in-progress' size='small' style={{ width: '20%', marginLeft: 5, marginRight: 5 }}>
          <Space>
            <div style={{ width: 35 }}>
              <Statistic value={reports ? reports[0].data[1].count : 0} formatter={formatter} style={{ fontWeight: 'bold' }} valueStyle={{ color: '#FAC858' }} />
            </div>
            <span className="vertical-line" />
            <div style={{ display: 'flex', alignItems: 'start', flexDirection: 'column', justifyContent: 'center' }}>
              <Title style={{ margin: 0 }} level={5}>In Progress</Title>
              {/* <Text type="secondary" style={{ lineHeight: 0.8 }}>Measures</Text> */}
            </div>
          </Space>
        </Card>
        <Card className='delayed-started' size='small' style={{ width: '20%', marginLeft: 5, marginRight: 5 }}>
          <Space>
            <div style={{ width: 35 }}>
              <Statistic value={reports ? reports[0].data[3].count : 0} formatter={formatter} style={{ fontWeight: 'bold' }} valueStyle={{ color: '#A969C6' }} />
            </div>
            <span className="vertical-line" />
            <div style={{ display: 'flex', alignItems: 'start', flexDirection: 'column', justifyContent: 'center' }}>
              <Title style={{ margin: 0 }} level={5}>Delayed</Title>
              {/* <Text type="secondary" style={{ lineHeight: 0.8 }}>Measures</Text> */}
            </div>
          </Space>
        </Card>
        <Card className='delayed-not-started' size='small' style={{ width: '20%', marginLeft: 5, marginRight: 5 }}>
          <Space>
            <div style={{ width: 35 }}>
              <Statistic value={reports ? reports[0].data[4].count : 0} formatter={formatter} style={{ fontWeight: 'bold' }} valueStyle={{ color: '#ff8282' }} />
            </div>
            <span className="vertical-line" />
            <div style={{ display: 'flex', alignItems: 'start', flexDirection: 'column', justifyContent: 'center' }}>
              <Title style={{ margin: 0 }} level={5}>Missed</Title>
              {/* <Text type="secondary" style={{ lineHeight: 0.8 }}>Measures</Text> */}
            </div>
          </Space>
        </Card>
        <Card className='to-do' size='small' style={{ width: '20%', marginLeft: 5, marginRight: 5 }}>
          <Space>
            <div style={{ width: 35 }}>
              <Statistic value={reports ? reports[0].data[0].count : 0} formatter={formatter} style={{ fontWeight: 'bold' }} valueStyle={{ color: '#5470C6' }} />
            </div>
            <span className="vertical-line" />
            <div style={{ display: 'flex', alignItems: 'start', flexDirection: 'column', justifyContent: 'center' }}>
              <Title style={{ margin: 0 }} level={5}>To Do</Title>
              {/* <Text type="secondary" style={{ lineHeight: 0.8 }}>Measures</Text> */}
            </div>
          </Space>
        </Card>
      </div>
      <Space style={{ marginTop: 25 }}>
        <Space direction='vertical' size='large' style={{ width: '40vw' }}>
          <Card style={{ boxShadow: "1px 0px 5px -2px rgba(33, 19, 13, 0.3)" }} title="Action wise implementation percentage">
            {/* <Text strong type="secondary">Action wise implementation percentage</Text> */}
            <Donut report={reports && reports.length > 0 ? reports[1] : null}  />
          </Card>
        </Space>
        <Space direction='vertical' size='large' style={{ width: '50vw' }}>
          <Card style={{ boxShadow: "1px 0px 5px -2px rgba(33, 19, 13, 0.3)" }} title="Agency Wise Measure Implementation Status">
            {/* <Text strong level={5} type="secondary">Agency Wise Measure Implementation Status</Text> */}
            <HBar report={reports && reports.length > 0 ? reports[4] : null} />
          </Card>
        </Space>
      </Space>
      <Space style={{ marginTop: 10 }} align='start'>
        <Space direction='vertical' size='large' style={{ width: '40vw' }}>
          <Card style={{ boxShadow: "1px 0px 5px -2px rgba(33, 19, 13, 0.3)" }} title="Remaining Time to Definitive Date vs Measure Count">
            {/* <Text strong level={5} type="secondary">Remaining Time to Definitive Date vs Measure Count</Text> */}
            <Counts />
          </Card>
        </Space>
        <Space direction='vertical' size='large' style={{ width: '50vw' }}>
          <Card style={{ boxShadow: "1px 0px 5px -2px rgba(33, 19, 13, 0.3)" }} title="Expected vs Actual Measure Completion">
            {/* <Text strong level={5} type="secondary">Expected vs Actual Measure Completion</Text> */}
            <Lines />
          </Card>
        </Space>
      </Space>
      {/* <Space style={{ marginTop: 40 }} align='start'>
        <Space direction='vertical' size='large' style={{ width: '40vw' }}>
          <Card style={{ boxShadow: "1px 0px 5px -2px rgba(33, 19, 13, 0.3)"  }} title="Definitive Dates in Upcoming 12 Months">
            <Text strong level={5} type="secondary">Definitive Dates in Upcoming 12 Months</Text>
            <Dates/>
          </Card>
        </Space>
      </Space> */}
    </div>
  );
}

export default Page;
