import { Card, Typography } from 'antd'
import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import { getInquiryCardData } from '../../../../api';
import CountUp from 'react-countup';
import '../dash5.css'
import { NewColorTheme } from '../../../../appConst';

function InquiryManagement5() {
    const [cardData, setCardData] = useState(null)
    const [shouldAnimate, setShouldAnimate] = useState(false);

    useEffect(() => {
        setShouldAnimate(true);
        loadData()

    }, [])

    const loadData = async () => {

        let agency
        let userLevel = await window.localStorage.getItem('ntfc_level')
        if (userLevel == 6 || userLevel == 7) {
            agency = 0
        } else {
            agency = window.localStorage.getItem('ntfc_agency')
        }

        getInquiryCardData(agency).then((out) => {

            setCardData(out.data.out)

        });

    }
    return (
        <div
            onClick={() => {
                window.location.href = "/inquiries"

            }} className=" new-inq-five-card enlarged-button2" style={{ background: "#6088ED", cursor: "pointer", borderRadius: "10px 0px 10px 10px", border: "none !important", }}>
            <div className="custom-db-card-header" style={{ backgroundColor: NewColorTheme.dbCardHeader, borderRadius: "10px 0px 0px 0px" }}>
                <Typography className='custom-db-card-header-title' style={{ color: NewColorTheme.parimaryTitle }}>Inquiry management</Typography>

            </div>
            <div className=' new-inq-five-card-body-new' style={{ background: "white" }} >
                <div className='inquiry-divs'>
                    <Typography className='inquiry-count-five' style={{ color: NewColorTheme.parimaryTitle }} >
                        {shouldAnimate && <CountUp end={cardData && cardData.answered ? cardData.answered : 0} duration={2} />}

                    </Typography>

                    <div
                        className='inquiry-tile-new'
                    >
                        <span className='inquiry-count-new' style={{ color: NewColorTheme.parimaryTitle }}>Answered</span>
                        <span className='inquiry-count-content' style={{ color: NewColorTheme.parimaryTitle, fontFamily: "Figtree", marginTop: 3 }}>Inquiries</span>
                    </div>

                </div>
                {/* <div style={{ display: "flex", justifyContent: "end", gap: 5, position: "absolute", right: "0", marginRight: "3%", top: "5px" }}>
    <EyeOutlined style={{ fontSize: 25, color: "white" }} />
</div> */}
                <div className='inquiry-divs'>
                    <Typography className='inquiry-count-five' style={{ color: NewColorTheme.parimaryTitle }}>
                        {shouldAnimate && <CountUp end={cardData && cardData.pending ? cardData.pending : 0} duration={2} />}
                    </Typography>

                    <div
                        className='inquiry-tile-new'
                    >
                        <span className='inquiry-count-new' style={{ color: NewColorTheme.parimaryTitle }} >Pending</span>
                        <span className='inquiry-count-content' style={{ color: NewColorTheme.parimaryTitle, fontFamily: "Figtree", marginTop: 3 }} >Answers</span>
                    </div>

                </div>
            </div>


        </div>
    )
}

export default InquiryManagement5