import { Typography } from 'antd'
import React from 'react'
import './subtitle.css'


function SubTitleDiv({ title, content }) {
    return (
        <div className='donor-content-main-div'>
            <div className='donor-content-sub-title-div'>
                <Typography className='prof-sub-title'>
                    {title}
                </Typography>


            </div>
            <div className='content-div'>
                {content}
            </div>
        </div>
    )
}

export default SubTitleDiv