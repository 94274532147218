import React from "react";
import { useEffect } from "react";
import {
  DownloadFiles,
  addContribution,
  editContribution,
  getAgencies,
  getAgencyMeasures,
  getMeasures,
  getMyAgencyContributionCreations,
} from "../../../api";
import { useState } from "react";
import TabLayout from "../TabLayout";
import {
  Button,
  Col,
  ConfigProvider,
  DatePicker,
  Form,
  Input,
  Modal,
  Popover,
  Row,
  Select,
  Space,
  Table,
  Tag,
  Tooltip,
  Typography,
} from "antd";
import dayjs from "dayjs";
import { ToastContainer, toast, Zoom } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import TextArea from "antd/es/input/TextArea";
import Dragger from "antd/es/upload/Dragger";
import {
  PlusOutlined,
  InboxOutlined,
  EyeOutlined,
  CloseOutlined,
  CloudUploadOutlined,
  CalendarOutlined,
  ContainerOutlined,
  ReconciliationOutlined,
  CalendarFilled,
  FileDoneOutlined,
  BorderlessTableOutlined,
  BoxPlotOutlined,
  EditOutlined,
  BankOutlined
} from "@ant-design/icons";
import { BiCloudDownload } from "react-icons/bi";
import { MainColors, NewColorTheme } from "../../../appConst";

function DonorContributionView({ setRefreshTimestamp, data, setData }) {
  const [agencies, setAgencies] = useState([]);
  const [currentRecord, setCurrentRecord] = useState(null);
  const [viewDialog, setViewDialog] = useState(false);
  const [form] = Form.useForm();
  const [selected, setSelected] = useState(null);
  const handleCancelView = () => {
    setViewDialog(false);
  };
  const MyContributions = [
    {
      title: "Agency",
      dataIndex: "agency_name",
      key: "agency_name",
      width: 120,

    },
    {
      title: "Subject",
      dataIndex: "project_name",
      key: "project_name",
      width: 120,
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      width: 110,
      ellipsis: true,
      render: (_, { description }) => (
        <Space>
          {description.length > 30 ?
            <span>
              {`${description.substring(0, 5)}...`}
              <Popover arrow content={<div style={{ width: 400 }} >{description}</div>} >
                <a>see more</a>
              </Popover>
            </span>
            : description}
        </Space>

      ),

    },

    {
      title: "Support Type",
      dataIndex: "assistance",
      key: "assistance",
      ellipsis: true,

      render: (_, record) =>
        record ? (
          <div>
            {record.assistance == 0 ? <Tag style={{ display: "flex", justifyContent: "center" }} color={NewColorTheme.primaryColor}>TS/CB</Tag> :
              <Tag style={{ display: "flex", justifyContent: "center" }} color="#08B3D7">OTHER</Tag>}

          </div>
        ) : null,
    },

    {
      title: "TFA Measure",
      dataIndex: "measure_name",
      key: "measure_name",
      width: 120,
      render: (_, record) =>
        record ? (
          <div style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
            {record.article_no + "." + record.measure_no + ". " + record.measure_name}

          </div>
        ) : null,

    },

    // {
    //     title: 'Attachments',
    //     dataIndex: 'attachments',
    //     key: 'attachments',

    // },

    {
      title: "Created by",
      dataIndex: "name",
      key: "name",

      // key: 'created_by',
    },
    // {
    //     title: 'Accepted Date',
    //     dataIndex: 'accepted_date',
    //     key: 'accepted_date',

    // },
    // {
    //     title: 'Published Date',
    //     dataIndex: 'published_date',
    //     key: 'published_date',

    // },
    {
      title: "Start Date",
      dataIndex: "start_date",
      key: "start_date",
      width: 85,

      render: (_, record) =>
        record ? (
          <div>
            <span style={{ whiteSpace: "nowrap" }}> {record.start_date
              ? dayjs(record.start_date).format("DD/MM/YYYY")
              : "-"}</span>

          </div>
        ) : null,
    },
    {
      title: "End Date",
      dataIndex: "end_date",
      key: "end_date",
      width: 85,

      render: (_, record) =>
        record ? (
          <div>
            <span style={{ whiteSpace: "nowrap" }}>
              {record.end_date
                ? dayjs(record.end_date).format("DD/MM/YYYY")
                : "-"}

            </span>

          </div>
        ) : null,
    },
    // {
    //     title: 'Attached Documents',
    //     dataIndex: 'files',
    //     key: 'files',
    //     render: (_, { files }) => (
    //         files ?
    //             <div>

    //                 {

    //                     files?.length > 0 && files.map((element, key) => {
    //                         return <div key={key} onClick={() => FetchFile(element)}>
    //                             <img src="/file.png" alt="file" style={{ height: 50, width: 50 }} />
    //                             {(element?.url && (element?.url?.toString().split("-::-"))[1])}
    //                         </div>

    //                     })
    //                 }
    //             </div>

    //             : null
    //     ),
    // },
    {
      title: "Status",
      dataIndex: "completed",
      key: "completed",
      width: 90,
      render: (_, record) =>
        record ? (
          <div>{record.completed == 0 ? "Ongoing" : "Completed"}</div>
        ) : null,
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) =>
        record ? (
          <div>
            <Tooltip title="View Contribution">
              <Button
                type="secondary"
                size="small"
                onClick={() => {
                  form.resetFields();
                  // setSummary("")
                  // setDescription("")
                  setCurrentRecord(record);
                  setSelected(null)
                  setViewDialog(true);
                  console.log("View", record);

                }}
                shape="circle"
                icon={<EyeOutlined style={{ color: "darkgreen" }} />}
              />
            </Tooltip>
            <Tooltip title="Edit Contribution">
              <Button
                type="secondary"
                size="small"
                onClick={(e) => {
                  form.resetFields();
                  e.stopPropagation();
                  setSelected(record)
                  console.log("EDit", record);
                }}
                shape="circle"
                icon={<EditOutlined style={{ color: "darkgreen" }} />}
              />
            </Tooltip>
          </div>
        ) : null,
    },
  ];

  useEffect(() => {
    if (selected) {
      console.log("selected", selected)

      // let def_date = `${new Date().getFullYear()}-${new Date().getMonth() + 1}-${new Date().getDate()}`;
      // if (selected.def_date) {
      //     def_date = moment(selected.def_date).format("DD/MM/YYYY");
      // }
      form.setFieldValue('subject', selected.project_name)

      form.setFieldValue('description', selected.description)

      form.setFieldValue('measure', selected.measure)
      form.setFieldValue('agency', selected.agency)
      form.setFieldValue('support_type', selected.assistance)
      form.setFieldValue('duration', selected.summary)
      form.setFieldValue('completed', selected.completed)
      setFromDate(selected.start_date)
      setToDate(selected.end_date)

    } else {
      form.resetFields();
      // setAction("")
      // setRemarks("")

    }
    // eslint-disable-next-line
  }, [selected]);

  useEffect(() => {
    loadData();
  }, []);

  const loadData = async () => {


    getMyAgencyContributionCreations().then((out) => {

      var sortedArray = out.data.out.sort(
        (a, b) => new Date(b.created) - new Date(a.created)
      );
      setData(sortedArray);
      setRefreshTimestamp(Date.now())
    });

    getMeasures().then((out2) => {
      setMeasures(out2.data.out);
    });

    getAgencies().then((out) => setAgencies(out.data.out));
  };

  const [submitBtn, SetSubmitBtnLoad] = useState(false);
  const [fileList2, setFileList2] = useState([]);
  const [support_type, setAssistance] = useState(0);
  const [value, setValue] = useState(0);
  const [toDate, setToDate] = useState(null);
  const [fromDate, setFromDate] = useState(undefined);
  const [uploadLoad, setUploadLoad] = useState(true);
  const [description, setDescription] = useState("");
  const [measures, setMeasures] = useState([]);
  const [measure, setMeasure] = useState(null);
  const [status, setStatus] = useState(0);

  const submitContribution = async (val) => {
    console.log("valuesss", val);
    SetSubmitBtnLoad(true);

    const formData = new FormData();
    formData.append("contribution_file", fileList2);
    formData.append("project_name", val.subject);
    formData.append("description", val.description);
    formData.append("measure", val.measure);
    formData.append("assistance", support_type);
    formData.append("duration", value);
    formData.append("agency", val.agency);
    formData.append("completed", status);
    formData.append("start_date", fromDate);
    formData.append("end_date", toDate);
    setUploadLoad(false);
    // let formData
    let out
    if (!selected) {

      out = await addContribution(formData);
    } else {
      formData.append("id", selected.id);
      out = await editContribution(formData);
    }


    if (out.status === 200) {
      toast.success(selected ? "Donor contribution has been updated successfully!" : "Donor contribution has been added successfully!", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      form.resetFields();
      setDescription("");
      setSelected(null)
      form.resetFields()
      setToDate(null)
      setFromDate(null)
    } else {
      toast.error("File Upload Error", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      form.resetFields();
      setDescription("");
    }

    // getDonorContribution(userData.agency_id).then((out) => {
    //     setContributionData(out.data.out);
    //     setContLoaded(true);
    // });
    loadData()
    form.resetFields();
    setDescription("");
    SetSubmitBtnLoad(false);
    setUploadLoad(true);
  };

  const FetchFile = async (file) => {
    let out = await DownloadFiles(file.token);

    let blob = new Blob([out.data], { type: file.type });
    let url = window.URL.createObjectURL(blob);

    let link = document.createElement("a");
    link.href = url;

    link.download = file.url.split("-::-")[1];

    document.body.appendChild(link);
    link.click();
  };

  const handleAgency = (value) => {
    // setAgencyID(value)
    if (value == -1) {
      getMeasures().then((out2) => {
        setMeasures(out2.data.out);
      });
    } else {
      getAgencyMeasures(value).then((out) => {
        setMeasures(
          out.data.out.map((m) => {
            return {
              value: m.measure_id,
              label: m.article_no + ". " + m.measure_no + ". " + m.summary,
            };
          })
        );
      });
    }
  };

  const onFromDateChange = (date, dateString) => {
    setFromDate(dayjs(date).format());
  };

  const onToDateChange = (date, dateString) => {
    setToDate(dayjs(date).format());
  };

  const props = {
    name: "file",
    multiple: false,
    action: "https://www.mocky.io/v2/5cc8019d300000980a055e76",

    onChange(info) {
      console.log("FIle", info);
      const { status } = info.file;
      if (status !== "uploading") {
        console.log(info.file, info.fileList);
      }
      if (status === "done") {
        // message.success(`${info.file.name} file uploaded successfully.`);
        handleFileUpload(info.file);
      } else if (status === "error") {
        // message.error(`${info.file.name} file upload failed.`);
      }
    },
    onDrop(e) {
      console.log("Dropped files", e.dataTransfer.files);
    },
  };

  async function handleFileUpload(file) {
    setFileList2(file);
  }
  return (
    <div className="custom-scroll-div tab-layout-div">
      <TabLayout
        title={selected ? "Edit Contribution" : "Add Contribution"}
        formSide={
          <div>
            <Form form={form} layout="vertical" onFinish={submitContribution} scrollToFirstError>
              <Form.Item
                label="Agency Name"
                wrapperCol={{ span: 24 }}
                rules={[
                  {
                    required: true,
                    message: "Agency is required",
                  },
                ]}
                required
                name="agency"
              >
                <Select
                  name="agency"
                  placeholder="Agency Name"
                  // size="large"
                  style={{ width: "100%" }}
                  options={agencies.filter((a) => a.agency_type === 1)}
                  onChange={(value) => {
                    handleAgency(value);
                  }}
                // options={[{ value: 999, label: "Island wise" }, ...agencies]}
                />
              </Form.Item>
              <Form.Item
                label="Measure"
                wrapperCol={{ span: 24 }}
                rules={[
                  {
                    required: true,
                    message: "Measure is required",
                  },
                ]}
                required
                name="measure"
              >
                <Select
                  placeholder="Select Measure"
                  options={measures}
                  onChange={(val) => setMeasure(val)}
                />
              </Form.Item>
              <Form.Item
                required
                rules={[
                  {
                    required: true,
                    message: "Subject is required",
                  },
                  {
                    pattern:
                      /^(?=.*[a-zA-Z])[a-zA-Z0-9!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/? ]+$/, //if field has only spaces, numbers or special characters
                    message: (
                      <p style={{ marginLeft: "12px" }}>
                        Please enter a valid Subject with alphabetical
                        characters
                      </p>
                    ),
                  },
                ]}
                label="Subject"
                wrapperCol={{ span: 24 }}
                name="subject"
              >
                <Input placeholder="Subject" />
              </Form.Item>
              <Form.Item
                label="Support Type"
                wrapperCol={{ span: 24 }}
                rules={[
                  {
                    required: true,
                    message: "Support Type is required",
                  },
                ]}
                required
                name="support_type"
              >
                <Select
                  placeholder="Support Type"
                  onChange={(val) => setAssistance(val)}
                  // defaultValue={0}
                  options={[
                    {
                      value: 0,
                      label: "Technical Support or Capacity Building",
                    },
                    { value: 1, label: "Other" },
                  ]}
                />
              </Form.Item>
              <Form.Item
                label="Tentative Start Date"
                name="start_date"
                // required
                valuePropName="date"
              // rules={[{
              //     required: true,
              //     message: <p style={{ marginLeft: "12px" }}>Start Date is required</p>,
              // }]}
              >
                <DatePicker
                  style={{ width: "100%" }}
                  // size="large"
                  onChange={onFromDateChange}
                  format="DD/MM/YYYY"
                  value={fromDate ? dayjs(fromDate) : null}
                />
              </Form.Item>
              <Form.Item
                label="Tentative End Date"
                name="end_date"
                // required
                valuePropName="date"
              // rules={[{
              //     required: true,
              //     message: <p style={{ marginLeft: "12px" }}>End Date is required</p>,
              // }]}
              >
                <DatePicker
                  style={{ width: "100%" }}
                  // size="large"
                  onChange={onToDateChange}
                  format="DD/MM/YYYY"
                  disabledDate={(current) =>
                    current && current.isBefore(form.getFieldValue("start_date"))
                  }
                  value={toDate ? dayjs(toDate) : null}
                />
              </Form.Item>
              <Form.Item
                label="Status"
                wrapperCol={{ span: 24 }}
                rules={[
                  {
                    required: true,
                    message: "Status is required",
                  },
                ]}
                required
                name="completed"
              >
                <Select
                  placeholder="Status"
                  onChange={(val) => setStatus(val)}
                  // defaultValue={0}
                  options={[
                    {
                      value: 0,
                      label: "Ongoing",
                    },
                    { value: 1, label: "Completed" },
                  ]}
                />
              </Form.Item>
              {uploadLoad ? (
                <div style={{ margin: "30px 0px" }}>
                  <p>
                    Please ensure that only one attachment is uploaded at a
                    time, and the file size should be limited to a maximum of
                    5MB.
                  </p>
                  <Dragger
                    {...props}
                    showUploadList={{ showProgress: true }}
                    multiple={false}
                    beforeUpload={(file) => {
                      const MAX_SIZE = 5 * 1024 * 1024; // 5 MB
                      if (file.size > MAX_SIZE) {
                        toast.error(
                          "File size exceeds limit! The maximum file upload size is 5MB.",
                          {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "colored",
                          }
                        );
                        return false;
                      } else {
                        handleFileUpload(file);
                      }
                      return false;
                      // to prevent automatic upload
                    }}
                    maxCount={1}
                  >
                    <p className="ant-upload-drag-icon">
                      <InboxOutlined tyle={{ color: "#33363F" }} />
                    </p>
                    <p className="ant-upload-text">
                      Click or drag a file to this area to upload
                    </p>
                  </Dragger>
                </div>
              ) : (
                ""
              )}
              <Form.Item
                required
                rules={[
                  {
                    required: true,
                    message: "Description is required",
                  },
                  {
                    pattern:
                      /^(?=.*[a-zA-Z])[a-zA-Z0-9!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/? ]+$/, //if field has only spaces, numbers or special characters
                    message: (
                      <p style={{ marginLeft: "12px" }}>
                        Please enter a valid project description with
                        alphabetical characters
                      </p>
                    ),
                  },
                ]}
                label="Project Description"
                wrapperCol={{ span: 24 }}
                name="description"
              >
                <TextArea placeholder="Description" rows={4} />
              </Form.Item>
              <Form.Item wrapperCol={{ span: 24 }}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    width: "100%",
                  }}
                >
                  {selected ? <ConfigProvider
                    theme={{
                      token: {
                        colorPrimary: "#272c95"
                      },
                    }}
                  >
                    <Button
                      htmlType="button"
                      size="large"
                      type="primary"
                      style={{ marginRight: 5 }}
                      onClick={() => {
                        setSelected(null);
                        setFromDate(null);
                        setToDate(null);
                      }}
                    >
                      Cancel
                    </Button>
                  </ConfigProvider> : null}
                  <ConfigProvider
                    theme={{
                      token: {
                        colorPrimary: "#272c95",
                      },
                    }}
                  >
                    <Button
                      loading={submitBtn}
                      htmlType="submit"
                      size="large"
                      type="primary"
                      disabled={window.localStorage.getItem('view_only') == 1}
                    >
                      {selected ? "Save" : "Create"}
                    </Button>
                  </ConfigProvider>
                </div>
              </Form.Item>
            </Form>
          </div>
        }
        table={
          <Table
            className="custom-table"
            rowClassName={(record, index) => index % 2 === 0 ? 'white-row' : 'gray-row'}

            scroll={{
              y: 400,
            }}
            size="small"
            columns={MyContributions}
            dataSource={data}
            pagination={{
              pageSize: 4,
              // total: data?.length,
            }}
          // onRow={(record, rowIndex) => {
          //   return {
          //     onClick: () => {
          //       form.resetFields()
          //       setFromDate(null)
          //       setToDate(null)
          //       setSelected(null)
          //       setCurrentRecord(record);
          //       setViewDialog(true);
          //     },
          //   };
          // }}
          />
        }
      />
      <Modal
        centered
        className="custom-modal"
        title={
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div>
              {" "}
              <Typography className="custom-modal-title" level={2}>
                Project Details
              </Typography>
            </div>
          </div>
        }
        open={viewDialog}
        width={750}
        onCancel={handleCancelView}
        footer={false}
      >
        <div
          className="custom-modal-body custom-scroll-div"
          style={{ height: 550, overflow: "auto" }}
        >
          <Row>
            <Col xs={24}>
              <Row gutter={[8, 8]} align={"top"}>
                <Col xs={24}>
                  <Row gutter={[8, 8]}>
                    <Col xs={2}>
                      <ReconciliationOutlined style={{ fontSize: 22 }} />
                    </Col>
                    <Col xs={22}>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "flex-start",
                        }}
                      >
                        <Typography.Title level={5}>
                          {" "}
                          Subject
                        </Typography.Title>
                        <Typography> {currentRecord?.project_name}</Typography>
                      </div>
                    </Col>
                  </Row>
                </Col>
                <Col xs={12}>
                  <Row gutter={[8, 8]} align={"top"}>
                    <Col xs={4}>
                      <CalendarFilled style={{ fontSize: 22 }} />
                    </Col>
                    <Col xs={20}>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "flex-start",
                        }}
                      >
                        <Typography.Title level={5}>
                          {" "}
                          Created Date
                        </Typography.Title>

                        <Typography>
                          {" "}
                          {dayjs(currentRecord?.created).format("DD/MM/YYYY")}
                        </Typography>
                      </div>
                    </Col>
                  </Row>
                </Col>
                <Col xs={12}>
                  <Row gutter={[8, 8]} align={"top"}>
                    <Col xs={4}>
                      <BankOutlined style={{ fontSize: 22 }} />
                    </Col>
                    <Col xs={20}>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "flex-start",
                        }}
                      >
                        <Typography.Title level={5}>
                          {" "}
                          Agency
                        </Typography.Title>

                        <Typography>
                          {" "}
                          {currentRecord?.agency_name}
                        </Typography>
                      </div>
                    </Col>
                  </Row>
                </Col>
                <Col xs={24}>
                  <Row gutter={[8, 8]} align={"top"}>
                    <Col xs={2}>
                      <BoxPlotOutlined style={{ fontSize: 22 }} />
                    </Col>
                    <Col xs={22}>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "flex-start",
                        }}
                      >
                        <Typography.Title level={5}> Measure</Typography.Title>

                        <Typography>
                          {" "}
                          {currentRecord?.article_no +
                            "." +
                            currentRecord?.measure_no +
                            ". " +
                            currentRecord?.measure_name}
                        </Typography>
                      </div>
                    </Col>
                  </Row>
                </Col>
                <Col xs={24}>
                  <Row gutter={[8, 8]} align={"top"}>
                    <Col xs={2}>
                      <BorderlessTableOutlined style={{ fontSize: 22 }} />
                    </Col>
                    <Col xs={22}>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "flex-start",
                        }}
                      >
                        <Typography.Title level={5}>
                          {" "}
                          Support Type
                        </Typography.Title>

                        <Typography>
                          {" "}
                          {currentRecord?.assistance == 0
                            ? "Technical Support or Capacity Building"
                            : "Other"}
                        </Typography>
                      </div>
                    </Col>
                  </Row>
                </Col>
                {currentRecord?.implementation_from && (
                  <Col xs={8}>
                    <Row gutter={[8, 8]} align={"top"}>
                      <Col xs={6}>
                        <CalendarOutlined style={{ fontSize: 22 }} />
                      </Col>
                      <Col xs={18}>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "flex-start",
                          }}
                        >
                          <Typography.Title level={5}>
                            {" "}
                            Tentative Start Date
                          </Typography.Title>

                          <Typography>
                            {" "}
                            {dayjs(currentRecord?.implementation_from).format(
                              "DD/MM/YYYY"
                            )}
                          </Typography>
                        </div>
                      </Col>
                    </Row>
                  </Col>
                )}

                {currentRecord?.implementation_to && (
                  <Col xs={8}>
                    <Row gutter={[8, 8]} align={"top"}>
                      <Col xs={4}>
                        <CalendarOutlined style={{ fontSize: 22 }} />
                      </Col>
                      <Col xs={20}>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "flex-start",
                          }}
                        >
                          <Typography.Title level={5}>
                            {" "}
                            Tentative End Date
                          </Typography.Title>

                          <Typography>
                            {" "}
                            {dayjs(currentRecord?.implementation_to).format(
                              "DD/MM/YYYY"
                            )}
                          </Typography>
                        </div>
                      </Col>
                    </Row>
                  </Col>
                )}
                {currentRecord?.published_date && (
                  <Col xs={8}>
                    <Row gutter={[8, 8]} align={"top"}>
                      <Col xs={6}>
                        <CalendarOutlined style={{ fontSize: 22 }} />
                      </Col>
                      <Col xs={18}>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "flex-start",
                          }}
                        >
                          <Typography.Title level={5}>
                            {" "}
                            Published Date
                          </Typography.Title>

                          <Typography>
                            {" "}
                            {currentRecord?.published_date
                              ? dayjs(currentRecord?.published_date).format(
                                "DD/MM/YYYY"
                              )
                              : "-"}
                          </Typography>
                        </div>
                      </Col>
                    </Row>
                  </Col>
                )}

                <Col xs={24}>
                  <Row gutter={[8, 8]} align={"top"}>
                    <Col xs={2}>
                      <ContainerOutlined style={{ fontSize: 22 }} />
                    </Col>
                    <Col xs={22}>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "flex-start",
                        }}
                      >
                        <Typography.Title level={5}>
                          {" "}
                          Description
                        </Typography.Title>

                        <Typography>
                          {" "}
                          {currentRecord?.description
                            ? currentRecord?.description
                            : "-"}
                        </Typography>
                      </div>
                    </Col>
                  </Row>
                </Col>
                {currentRecord?.files?.length > 0 && (
                  <Col xs={24}>
                    <Row gutter={[8, 8]} align={"top"}>
                      <Col xs={2}>
                        <CloudUploadOutlined style={{ fontSize: 22 }} />
                      </Col>
                      <Col xs={22}>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "flex-start",
                          }}
                        >
                          <Typography.Title level={5}>
                            {" "}
                            Attachment
                          </Typography.Title>

                          {currentRecord?.files?.length > 0 &&
                            currentRecord?.files.map((element, key) => {
                              return (
                                <div
                                  style={{
                                    alignItems: "center",
                                    display: "flex",
                                    justifyContent: "space-between",
                                    width: "100%",
                                    cursor: "pointer",
                                    background: MainColors.LIGHTBACKCOLOR,
                                    borderRadius: 10,
                                    padding: 10,

                                    ":hover": {
                                      background: MainColors.DARKBACKCOLOR,
                                      color: "white",
                                      // Add any additional styles you want for the hover effect
                                    },
                                  }}
                                  key={key}
                                  onClick={() => FetchFile(element)}
                                >
                                  <div>
                                    <img
                                      src="/file.png"
                                      alt="file"
                                      style={{ height: 50, width: 50 }}
                                    />
                                    {element?.url &&
                                      (element?.url
                                        ?.toString()
                                        .split("-::-"))[1]}
                                  </div>

                                  <BiCloudDownload
                                    style={{ fontSize: 32, color: NewColorTheme.primaryColor }}
                                  />
                                </div>
                              );
                            })}
                        </div>
                      </Col>
                    </Row>
                  </Col>
                )}
                {(currentRecord?.reject_reason || currentRecord?.publish_reason) &&
                  <Col xs={24}>
                    <Row gutter={[8, 8]} align={"top"}>
                      <Col xs={2}>
                        <FileDoneOutlined style={{ fontSize: 22 }} />
                      </Col>
                      <Col xs={22}>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "flex-start",
                          }}
                        >
                          <Typography.Title level={5}> Remarks</Typography.Title>

                          <Typography>
                            {" "}
                            {currentRecord?.reject_reason
                              ? currentRecord?.reject_reason
                              : currentRecord?.publish_reason
                                ? currentRecord?.publish_reason
                                : "-"}
                          </Typography>
                          {currentRecord?.published_attachments?.length > 0 &&
                            currentRecord?.published_attachments.map((element, key) => {
                              return (
                                <div
                                  style={{
                                    alignItems: "center",
                                    display: "flex",
                                    justifyContent: "space-between",
                                    width: "100%",
                                    cursor: "pointer",
                                    background: MainColors.LIGHTBACKCOLOR,
                                    borderRadius: 10,
                                    padding: 10,

                                    ":hover": {
                                      background: MainColors.DARKBACKCOLOR,
                                      color: "white",
                                      // Add any additional styles you want for the hover effect
                                    },
                                  }}
                                  key={key}
                                  onClick={() => FetchFile(element)}
                                >
                                  <div>
                                    <img
                                      src="/file.png"
                                      alt="file"
                                      style={{ height: 50, width: 50 }}
                                    />
                                    {element?.url &&
                                      (element?.url
                                        ?.toString()
                                        .split("-::-"))[1]}
                                  </div>
                                  <BiCloudDownload
                                    style={{ fontSize: 32, color: NewColorTheme.primaryColor }}
                                  />
                                </div>
                              );
                            })}
                        </div>
                      </Col>
                    </Row>
                  </Col>
                }

              </Row>
            </Col>
          </Row>
        </div>
        {/* <p>{record.checked ? "Are you sure to assist this project?" : "Are you sure to remove this project?"}</p> */}
      </Modal>

      <ToastContainer />
    </div>
  );
}

export default DonorContributionView;
