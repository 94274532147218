import React from 'react';
import ReactECharts from 'echarts-for-react';

const HBar = ({ report }) => {
  const options = {
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        // Use axis to trigger tooltip
        type: 'shadow' // 'shadow' as default; can also be 'line' or 'shadow'
      }
    },
    legend: {
      left: 'auto',
      textStyle: {
        fontSize: 14,
      },
    },
    toolbox: {
      show: true,
      feature: {
        mark: { show: true },
        restore: { show: true },
        saveAsImage: { show: true }
      }
    },
    grid: {
      left: '3%',
      right: '4%',
      bottom: '3%',
      containLabel: true
    },
    yAxis: {
      type: 'value'
    },
    xAxis: {
      type: 'category',
      data: report && report.data ? report.data.agency_names : []
    },
    textStyle: {
      fontFamily: 'Figtree',
      fontSize: 14,
    },
    color: [
      '#1520A6',
      '#0492C2',
      '#2f69b3',
      '#4169E1',
      '#AED8E6'
    ],
    series: [
      {
        name: 'Completed',
        type: 'bar',
        stack: 'total',
        label: {
          show: true,
          fontSize: 14,
        },
        emphasis: {
          focus: 'series'
        },
        data: report && report.data ? report.data.completed.map((comp, idx) => ((comp / (report.data.total[idx])) * 100)?.toFixed(2)) : []
      },
      {
        name: 'In Progress',
        type: 'bar',
        stack: 'total',
        label: {
          show: true,
          fontSize: 14,
        },
        emphasis: {
          focus: 'series'
        },
        data: report && report.data ? report.data.inprogress.map((comp, idx) => ((comp / (report.data.total[idx])) * 100)?.toFixed(2)) : []
      },
      {
        name: 'Delayed',
        type: 'bar',
        stack: 'total',
        label: {
          show: true,
          fontSize: 14,
        },
        emphasis: {
          focus: 'series'
        },
        data: report && report.data ? report.data.delayed.map((comp, idx) => ((comp / (report.data.total[idx])) * 100)?.toFixed(2)) : []
      },
      {
        name: 'Missed',
        type: 'bar',
        stack: 'total',
        label: {
          show: true,
          fontSize: 14,
        },
        emphasis: {
          focus: 'series'
        },
        data: report && report.data ? report.data.missed.map((comp, idx) => ((comp / (report.data.total[idx])) * 100)?.toFixed(2)) : []
      },
      {
        name: 'To Do',
        type: 'bar',
        stack: 'total',
        label: {
          show: true,
          fontSize: 14,
        },
        emphasis: {
          focus: 'series'
        },
        data: report && report.data ? report.data.todo.map((comp, idx) => ((comp / (report.data.total[idx])) * 100)?.toFixed(2)) : []
      }
    ]
  };

  return <ReactECharts option={options} />;
};

export default HBar;