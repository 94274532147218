import React from 'react';
import { List, Typography, Input, Space, Button, message, Tooltip, Form, Select, Row, Col } from 'antd';
import { PlusOutlined, CloseOutlined, EditOutlined } from '@ant-design/icons';
import { useEffect, useState } from 'react';
import { addAgency, getAgencyList, updateAgency } from '../../api';
import { AGENCY_TYPES } from '../../constants';
import './agencies.styles.css';
import CustomTable from '../../Components/Table';
import PageLayouts from '../../Components/PageLayouts'

const Agencies = ({ agency }) => {
    const [items, setItems] = useState([]);

    const [data, setData] = useState([]);
    const [filtered_data, setFilteredData] = useState([]);

    const [selected, setSelected] = useState(null);

    const [agencyName, setAgencyName] = useState("");
    const [agencyType, setAgencyType] = useState(null);
    const [agencyDesc, setAgencyDesc] = useState("");
    const [agencyPhone, setAgencyPhone] = useState("");
    const [agencyAddress, setAgencyAddress] = useState("");

    const [form] = Form.useForm();


    const columns = [
        {
            title: 'Agency Name',
            dataIndex: 'agency_name',
            key: 'agency_name',

        },
        // {
        //     title: 'Action Title',
        //     dataIndex: 'label',
        //     key: 'label',

        // },

        {
            title: 'Agency Type',
            dataIndex: 'agency_type',
            key: 'agency_type',
            render: (_, { agency_type }) => (
                agency_type ? (AGENCY_TYPES.filter((type) => type.value === agency_type))[0].label : ""

            ),
        },
        {
            title: 'Phone',
            dataIndex: 'phone',
            key: 'phone',

        },

        {
            title: 'Action',
            key: 'action',
            render: (_, record) => (
                record ?
                    <Tooltip title="View/Edit">
                        <Button type="secondary" size='small' onClick={() => setSelected(record)} shape="circle" icon={<EditOutlined style={{ color: '#946e6e' }} />} />
                    </Tooltip>
                    : null
            ),
        },
    ];


    const refreshAgencies = () => {
        getAgencyList().then((out) => {
            setItems(out.data.out);
        });
    }

    const [messageApi, contextHolder] = message.useMessage();

    const success = (msg) => {
        messageApi.open({
            type: 'success',
            content: msg,
            style: {
                marginTop: '30vh',
                height: '100vh',
                fontSize: '20px'
            },
            duration: 3
        });
    };

    const error = (msg) => {
        messageApi.open({
            type: 'error',
            content: msg,
            style: {
                marginTop: '30vh',
                height: '100vh',
                fontSize: '20px'
            },
            duration: 3
        });
    };

    const onFinish = (values) => {
        console.log('Success:', values);
        if (!selected) {
            addAgency(values).then((out) => { form.resetFields(); refreshAgencies(); success("Agency Added"); })
        } else {
            values.id = selected.id;
            updateAgency(values).then((out) => { form.resetFields(); refreshAgencies(); success("Agency Updated"); })
        }
    };
    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
        error("Something went wrong!");
    };

    useEffect(() => {
        refreshAgencies()
    }, []);

    useEffect(() => { setData(items); setFilteredData(items) }, [items]);

    useEffect(() => {
        if (selected) {
            form.setFieldValue('agency_name', selected.agency_name)
            form.setFieldValue('agency_type', selected.agency_type)
            form.setFieldValue('description', selected.description)
            form.setFieldValue('phone', selected.phone)
            form.setFieldValue('address', selected.address)
        } else {
            form.resetFields();
        }
        // eslint-disable-next-line
    }, [selected]);

    return <div style={{ margin: '0 5px' }}>
        {contextHolder}
        <PageLayouts
            formTitle="AGENCIES"
            tableTitle="Resisted Agencies View"
            imageSrc="/images/marketing-company.png"
            formSide={

                <div>
                    <Space direction='vertical' style={{ width: '100%' }}>
                        <Form
                            form={form}
                            layout="vertical"
                            onFinish={onFinish}
                            onFinishFailed={onFinishFailed}
                        >

                            <Row >

                                <Form
                                    form={form}
                                    layout="vertical"
                                    onFinish={onFinish}
                                    onFinishFailed={onFinishFailed}
                                >
                                    <Row gutter={16}>
                                        <Col className="gutter-row" span={12}>
                                            <Form.Item label="Agency Name" name="agency_name" required>
                                                <Input type='text' placeholder="Name" value={agencyName} onChange={(e) => setAgencyName(e.target.value)} />
                                            </Form.Item>
                                        </Col>
                                        <Col className="gutter-row" span={12}>
                                            <Form.Item label="Agency Type" name="agency_type" required>
                                                <Select
                                                    options={AGENCY_TYPES}
                                                    onChange={(e) => {
                                                        const flitered = AGENCY_TYPES.filter((it) => it.value === e)
                                                        if (flitered.length > 0) {
                                                            setAgencyType(e);
                                                        }
                                                    }}
                                                    value={agencyType}
                                                />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row gutter={16}>
                                        <Col className="gutter-row" span={24}>
                                            <Form.Item label="Description" name="description">
                                                <Input.TextArea rows={4} placeholder="Description" value={agencyDesc} onChange={(e) => setAgencyDesc(e.target.value)} />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row gutter={16}>
                                        <Col className="gutter-row" span={12}>
                                            <Form.Item label="Phone Number" name="phone" required>
                                                <Input type='text' placeholder="Phone Number" value={agencyPhone} onChange={(e) => setAgencyPhone(e.target.value)} />
                                            </Form.Item>
                                        </Col>
                                        <Col className="gutter-row" span={12}>
                                            <Form.Item label="Address" name="address" required>
                                                <Input type='text' placeholder="Address" value={agencyAddress} onChange={(e) => setAgencyAddress(e.target.value)} />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    {!selected ? <Button type='primary' htmlType="submit" icon={<PlusOutlined />}>
                                        Add Agency
                                    </Button> : <Space>
                                        <Button type='primary' htmlType="submit" icon={<EditOutlined />} style={{ background: 'green' }}>
                                            Update
                                        </Button>
                                        <Button type='primary' danger htmlType="submit" icon={<CloseOutlined />} onClick={() => setSelected(null)}>
                                            Cancel
                                        </Button>
                                    </Space>}
                                </Form>



                            </Row>


                        </Form>
                    </Space>
                </div>
            }

            tableSide={
                <CustomTable columns={columns} data={filtered_data}
                    pagination={{
                        pageSize: 10,
                        total: filtered_data.length,
                    }} />
            }

        ></PageLayouts>
    </div>
}

export default Agencies;