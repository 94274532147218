import { List, Typography, Input, Space, Button, message, Tooltip, Form, Select, Row, Col, DatePicker, Modal } from 'antd';
import { PlusOutlined, CloseOutlined, EditOutlined } from '@ant-design/icons';
import { useEffect, useState } from 'react';
import { addAction, getActionsList, updateAction, getArticles, getMeasures, getMeasuresListByArticle, getNextActionNo } from '../../api';
import TextArea from 'antd/es/input/TextArea';
import moment from 'moment';
import dayjs from 'dayjs';

const Actions = ({ article }) => {
    const [items, setItems] = useState([]);

    const [data, setData] = useState([]);
    const [filtered_data, setFilteredData] = useState([]);
    const [articles, setArticles] = useState([]);
    const [measures, setMeasures] = useState([]);
    const [defDate, setDefDate] = useState('');
    const [selectedArticle, setSelectedArticle] = useState(null);
    const [selectedMeasure, setSelectedMeasure] = useState(null);
    const [messagePopup,setMessagePopup] = useState(false);

    const [selected, setSelected] = useState(null);

    const [form] = Form.useForm();

    const refreshActions = () => {
        getActionsList().then((out) => {
            setItems(out.data.out);
        });
        getMeasures().then((m) => {
            setMeasures(m.data.out);
        });
    }

    const [messageApi, contextHolder] = message.useMessage();

    const success = (msg) => {
        messageApi.open({
            type: 'success',
            content: msg,
            style: {
                marginTop: '30vh',
                height: '100vh',
                fontSize: '20px'
            },
            duration: 3
        });
    };

    const error = (msg) => {
        messageApi.open({
            type: 'error',
            content: msg,
            style: {
                marginTop: '30vh',
                height: '100vh',
                fontSize: '20px'
            },
            duration: 3
        });
    };

    const filterData = (val) => {
        const itms = data.filter((rec) => {
            return rec.article_id === Number(val) || !val
        });
        console.log(itms); setFilteredData(itms);
    }

    const onFinish = (values) => {
        values.def_date = defDate;

        let prev_action_no = values.action_no;
        let repeated = false;

        if (selected) {
            items.forEach(item => {
                if (item.article_id == values.article_id && item.measure_id == values.measure_id) {
                    if (item.measure_no == prev_action_no && item.action_no != selected.action_no) {
                        repeated = true;
                    }
                }
            })
        } else {
            items.forEach(item => {
                if (item.article_id == values.article_id && item.measure_id == values.measure_id) {
                    if (item.action_no == prev_action_no) {
                        repeated = true;
                    }
                }
            })
        }

        if (repeated) {
            error("Action Number Already Exists");
            return;
        }

        if (!selected) {
            addAction(values).then((out) => {
                if (out.data.status === 200) {
                    form.resetFields();
                    refreshActions();
                    success("Action Created");
                } else {
                    error("Action Was Not Created");
                }
            }).catch((err) => {
                console.log(err);
                messageApi.open({
                    type: 'error',
                    content: 'Action creation unsuccessful',
                    style: {
                        marginTop: '30vh',
                        height: '100vh',
                        fontSize: '20px'
                    },
                    duration: 3
                });
            })
        } else {
            values.id = selected.id;
            updateAction(values).then((out) => {
                if (out.data.status === 200) {                    
                    form.resetFields();
                    refreshActions();
                    success("Action Updated");
                    setSelected(null);
                } else {
                    error("Action Was Not Updated");
                }
            }).catch((err) => {
                console.log(err);
                messageApi.open({
                    type: 'error',
                    content: 'Action update unsuccessful',
                    style: {
                        marginTop: '30vh',
                        height: '100vh',
                        fontSize: '20px'
                    },
                    duration: 3
                });
            })
        }
    };
    const onFinishFailed = (errorInfo) => {
        if (!selected) {
            error("Action creation unsuccessful");
        } else {
            error("Action update unsuccessful");
        }
    };

    const onDateChange = (date, dateString) => {
        setDefDate(dateString);
    };

    useEffect(() => {
        getArticles().then((art) => {
            setArticles(art.data.out);
        })
        refreshActions()
    }, []);

    useEffect(() => { setData(items); setFilteredData(items) }, [items]);

    useEffect(() => {
        if (selected) {
            let def_date = `${new Date().getFullYear()}-${new Date().getMonth() + 1}-${new Date().getDate()}`;
            if (selected.def_date) {
                def_date = moment(selected.def_date).format("DD/MM/YYYY");
            }
            form.setFieldValue('article_id', selected.article_id)
            form.setFieldValue('measure_id', selected.measure_id)
            form.setFieldValue('action', selected.action)
            form.setFieldValue('action_no', selected.action_no)
            form.setFieldValue('def_date', def_date)
            form.setFieldValue('summary', selected.summary)
            form.setFieldValue('category', selected.category)
            form.setFieldValue('description', selected.description)
            form.setFieldValue('weightage', selected.weightage)
        } else {
            form.resetFields();
        }
        // eslint-disable-next-line
    }, [selected]);

    useEffect(() => {
        if (selectedArticle) {
            setSelectedMeasure(null);
            form.setFieldValue('measure_id', '')
            filterData(selectedArticle);
            getMeasuresListByArticle(selectedArticle, {}).then((mes) => {
                const filteredArticle = ((articles.filter((itm) => itm.value === selectedArticle))[0]).article_no;
                setMeasures(mes.data.out.map((el) => {
                    return {
                        value: el.id,
                        label: `${filteredArticle}.${el.measure_no} - ${el.summary}`
                    }
                }))
            })
        }
        // eslint-disable-next-line
    }, [selectedArticle]);

    useEffect(() => {
        if (!selectedMeasure) {
            form.setFieldValue('action_no', '')
        } else {
            getNextActionNo(selectedMeasure).then((out) => {
                if (out.data && out.data.out.length > 0) {
                    const id = out.data.out[0].id;
                    if (id) {
                        form.setFieldValue('action_no', id.toString())
                    } else {
                        form.setFieldValue('action_no', '1')
                    }
                } else {
                    form.setFieldValue('action_no', '')
                }
                console.log("Mes Out", out);
            })
        }
    }, [selectedMeasure])

    return <div style={{ margin: '0 5px' }}>
        {contextHolder}
        <Row gutter={16}>
            <Col span={10}>
                <Typography.Title level={5} style={{ margin: 0 }}>Actions</Typography.Title>
                <Space direction='vertical' style={{ width: '100%' }}>
                    <Typography.Text type="secondary">Please Complete the Form...</Typography.Text>
                    <Form
                        form={form}
                        layout="vertical"
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                    >
                        <Row gutter={16}>
                            <Col className="gutter-row" span={12}>
                                <Form.Item label="Article" name="article_id" required
                                    rules={[{
                                        required: true,
                                        message: <p style={{ marginLeft: "12px" }}>Article is required</p>,
                                    }]}
                                >
                                    <Select
                                        options={articles}
                                        onChange={(val) => setSelectedArticle(val)}
                                    />
                                </Form.Item>
                            </Col>
                            <Col className="gutter-row" span={12}>
                                <Form.Item label="Measure" name="measure_id" required
                                    rules={[{
                                        required: true,
                                        message: <p style={{ marginLeft: "12px" }}>Measure is required</p>,
                                    }]}
                                >
                                    <Select
                                        options={measures}
                                        onChange={(val) => setSelectedMeasure(val)}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col className="gutter-row" span={6}>
                                <Form.Item label="Action No" name="action_no" required
                                    rules={[{
                                        required: true,
                                        message: <p style={{ marginLeft: "12px" }}>Action No is required</p>,
                                    }]}
                                >
                                    <Input type='number' placeholder="Action No" />
                                </Form.Item>
                            </Col>
                            <Col className="gutter-row" span={18}>
                                <Form.Item label="Action" name="action" required
                                    rules={[{
                                        required: true,
                                        message: <p style={{ marginLeft: "12px" }}>Action is required</p>,
                                    }]}
                                >
                                    <Input type='text' placeholder="Action" />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col className="gutter-row" span={12}>
                                <Form.Item label="Weightage" name="weightage" required
                                    rules={[{
                                        required: true,
                                        message: <p style={{ marginLeft: "12px" }}>Weightage is required</p>,
                                    }]}
                                >
                                    <Input type='number' placeholder="Weightage" />
                                </Form.Item>
                            </Col>
                            <Col className="gutter-row" span={12}>
                                <Form.Item label="Definitive Date" name="def_date" required valuePropName='date'
                                    rules={[{
                                        required: true,
                                        message: <p style={{ marginLeft: "12px" }}>Definitive Date is required</p>,
                                    }]}
                                >
                                    <DatePicker
                                        style={{ width: '100%' }}
                                        onChange={onDateChange}
                                        format="DD/MM/YYYY"
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col className="gutter-row" span={24}>
                                <Form.Item label="Summary" name="summary" required
                                    rules={[{
                                        required: true,
                                        message: <p style={{ marginLeft: "12px" }}>Summary is required</p>,
                                    }]}
                                >
                                    <TextArea rows={4} placeholder='Summary' />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col className='gutter-row' span={24}>
                                <Form.Item label="Remarks" name="remarks" valuePropName='remarks'>
                                    <TextArea rows={4} placeholder='Remarks' />
                                </Form.Item>
                            </Col>
                        </Row>
                        {!selected ? <Button type='primary' htmlType="submit" icon={<PlusOutlined />}>
                            Add Action
                        </Button> : 
                        <Space>
                            <Button type='primary' htmlType="submit" icon={<EditOutlined />} style={{ background: 'green' }}>
                                Update
                            </Button>
                            <Button type='primary' danger htmlType="submit" icon={<CloseOutlined />} onClick={() => setSelected(null)}>
                                Cancel
                            </Button>
                            <Modal title="Basic Modal" open={messagePopup} onCancel={()=>setMessagePopup(false)} footer={null} closable>
        <p>Some contents...</p>
        <p>Some contents...</p>
        <p>Some contents...</p>
      </Modal>
                        </Space>}
                    </Form>
                </Space>
            </Col>
            <Col span={14}>
                <div style={{ display: 'flex', justifyContent: 'end', width: '100%', marginBottom: 10 }}>
                    <Input.Search style={{ width: 200 }} placeholder="search" onSearch={(val) => filterData(val)} />
                </div>
                <List
                    bordered
                    className='config-list'
                    style={{ height: '50vh', overflow: 'auto', borderColor: '#f0f0f0' }}
                    dataSource={filtered_data}
                    header={<div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', alignItems: 'center' }}>
                        <Row gutter={16} style={{ width: '100%' }}>
                            <Col span={3}>
                                <Typography.Text>#</Typography.Text>
                            </Col>
                            <Col span={10}>
                                <Typography.Text>Action</Typography.Text>
                            </Col>
                            <Col span={6}>
                                <Typography.Text>Summary</Typography.Text>
                            </Col>
                            <Col span={4}>
                                <Typography.Text>Definitive Date</Typography.Text>
                            </Col>
                            <Col span={1}>
                            </Col>
                        </Row>
                    </div>}
                    renderItem={(item) => (
                        <List.Item className={article && article.id === item.id ? 'config-list-item-selected' : 'config-list-item'}>
                            <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', alignItems: 'center' }}>
                                <Row gutter={16} style={{ width: '100%' }}>
                                    <Col span={3}>
                                        <Typography.Text style={article && article.id === item.id ? { color: 'white' } : {}}>{item.article_no}.{item.measure_no}.{item.action_no}</Typography.Text>
                                    </Col>
                                    <Col span={10}>
                                        <Typography.Text style={article && article.id === item.id ? { color: 'white' } : {}}>{item.action}</Typography.Text>
                                    </Col>
                                    <Col span={6}>
                                        <Typography.Text style={article && article.id === item.id ? { color: 'white' } : {}}>{item.summary}</Typography.Text>
                                    </Col>
                                    {/* <Col span={3}>
                                        <Typography.Text style={article && article.id === item.id ? { color: 'white' } : {}}>{item.category}</Typography.Text>
                                    </Col> */}
                                    <Col span={4}>
                                        <Typography.Text style={article && article.id === item.id ? { color: 'white' } : {}}>
                                            {item.def_date ? moment(item.def_date).format('DD/MM/YYYY') : "-"}
                                        </Typography.Text>
                                    </Col>
                                    <Col span={1}>
                                        <Tooltip title="View/Edit">
                                            <Button type="secondary" size='small' onClick={() => setSelected(item)} shape="circle" icon={<EditOutlined style={{ color: '#946e6e' }} />} />
                                        </Tooltip>
                                    </Col>
                                </Row>
                            </div>
                        </List.Item>
                    )}
                />
            </Col>
        </Row>
    </div>
}

export default Actions;