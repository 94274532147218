import { Alert, Avatar, Button, Col, ConfigProvider, Empty, Input, Row, Space, Tag, Tooltip, Typography, Upload } from "antd";
import { useEffect, useState } from "react";
import { addMessage, downloadChatFiles, getChat } from "../../api";
import { SendOutlined, PaperClipOutlined, DeleteOutlined, UserOutlined, DownloadOutlined } from "@ant-design/icons";
import dayjs from 'dayjs';
import './chat.styles.css';
import Marquee from 'react-fast-marquee';
import AddMembers from "./AddMembers";
import { NewColorTheme } from "../../appConst";
const { TextArea } = Input;

const ChatBox = ({ selectedChat, refreshChats }) => {

    console.log("selected Chat", selectedChat)
    const [message, setMessage] = useState("");
    const [messageFeed, setMessageFeed] = useState([]);
    const [attachedFiles, setAttachedFiles] = useState([]);
    const [lastRefresh, setLastRefresh] = useState("");
    const [divRef, setDivRef] = useState(null);
    const [memberDialog, setMemberDialog] = useState(false)
    const scrollToBottom = () => {
        if (divRef) {
            divRef.scrollTop = divRef.scrollHeight;
        }
    };

    const refreshFeed = () => {
        getChat(selectedChat.id).then((out) => {
            if (out) {
                setMessageFeed([...out.data.out]);
                setLastRefresh(`On ${dayjs(new Date()).format('DD/MM/YYYY HH:mm:ss')}`);
            }
        });
        scrollToBottom()
    }

    useEffect(() => {
        if (selectedChat) {
            setMessageFeed([]);
            refreshFeed();
        }
    }, [selectedChat]);

    // useEffect(() => {
    //     setInterval(() => {
    //         if (selectedChat) {
    //             refreshFeed();
    //         }
    //     }, 60000);
    // },[]);

    const sendMessage = () => {
        setMessage("");
        if (attachedFiles.length > 0) {
            console.log("attach file", message)
            const formData = new FormData();
            formData.append('chat_id', selectedChat.id);
            formData.append('message', message ? message : "file added");
            formData.append('chat_file', attachedFiles[0]);
            addMessage(formData).then((cht) => {
                if (cht) {
                    console.log("cht", cht)
                    setAttachedFiles([]);
                    refreshFeed();
                }
            })
        } else {
            console.log("without file", message)
            addMessage({
                chat_id: selectedChat.id,
                message
            }).then((cht) => {
                if (cht) {
                    console.log("cht", cht)
                    refreshFeed();
                }
            })
        }
    }

    const attachFile = (file) => {
        setAttachedFiles([file]);
        return false;
    }

    const fetchFile = async (file) => {
        let out = await downloadChatFiles(file.attachment)

        let blob = new Blob([out.data], { type: file.attachment_type });
        let url = window.URL.createObjectURL(blob);

        let link = document.createElement('a');
        link.href = url;

        link.download = file.attachment_name;

        document.body.appendChild(link);
        link.click();
    }

    return selectedChat ? <div>
        {selectedChat?.is_group == 1 &&
            <div style={{ width: "100%", height: "max-content", padding: "20px", background: "white", display: "flex", justifyContent: "space-between" }}>

                <div>
                    <Typography.Title style={{ whiteSpace: "nowrap" }} level={3}>{selectedChat?.chat_name}</Typography.Title>

                </div>

                {/* <Alert
                                icon={<UserOutlined style={{ fontSize: '24px', color: '#1890ff' }} />}
                                type="info"
                                banner
                                message={
                                    <Marquee pauseOnHover gradient={false}>
                                        {selectedChat?.members?.map((element, index) => {
                                            return " " + element + (selectedChat?.members?.length - 1 == index ? "" : ', ')

                                        })}
                                    </Marquee>
                                }
                            /> */}
                <div>
                    <Tooltip title="Members" >
                        <AddMembers selectedChat={selectedChat} refreshChats={refreshChats} />
                    </Tooltip>
                </div>








            </div>
        }
        <div style={{
            width: '100%', height: '70vh',
            // background: '#1677ff1c',
            // backgroundColor: "#e6f7f2",

            backgroundColor: '#d5edfe',
            // opacity: 0.8,
            // backgroundImage: 'repeating-linear-gradient(45deg, #fbfcfd 25%, transparent 25%, transparent 75%, #fbfcfd 75%, #fbfcfd), repeating-linear-gradient(45deg, #fbfcfd 25%, #e6f7f2 25%, #e6f7f2 75%, #fbfcfd 75%, #fbfcfd)',
            // backgroundPosition: '0 0, 10px 10px',
            // backgroundSize: '20px 20px',
            padding: 10, display: 'flex', alignItems: 'end', overflow: 'auto'
        }}>
            <Space ref={setDivRef} className="custom-scroll-div-hide" direction='vertical' style={{

                width: '100%', height: '70vh', overflow: 'auto', paddingTop: 20
            }}>


                {messageFeed.map((msg) => {
                    const styleObj = { padding: 10, boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px', width: 'fit-content', display: 'grid' };
                    if (msg.by_me) {
                        return <div style={{ ...styleObj, borderRadius: "10px 10px  0px 10px", background: 'white', float: 'right', color: 'white' }}>
                            {msg.message != "file added" &&
                                <Typography.Text style={{ color: 'black', fontSize: 16, }}>    {msg.message}</Typography.Text>

                            }
                            {msg.attachment ? <div style={{ display: 'flex', gap: 8, cursor: 'pointer', padding: 5, background: 'white', borderRadius: 5 }}
                                onClick={() => fetchFile(msg)}>
                                <img src="/file.png" style={{ width: 20, height: 20 }} alt={msg.attachment_name} />
                                <Typography.Text style={{ color: 'black' }}>{msg.attachment_name}</Typography.Text>
                            </div> : null}
                            <Typography.Text style={{ fontSize: 12, color: 'black' }}>{dayjs(msg.created).format('DD/MM/YYYY hh:mm A')}</Typography.Text>
                        </div>
                    } else {
                        return <div style={{ ...styleObj, borderRadius: "0px 10px  10px 10px", background: 'white', float: 'left' }}>
                            <Typography.Text style={{ fontSize: 16, }}>    {msg.message}</Typography.Text>
                            {msg.attachment ? <div style={{ display: 'flex', gap: 8, cursor: 'pointer', padding: 5, background: 'white', borderRadius: 5 }}
                                onClick={() => fetchFile(msg)}>
                                <img src="/file.png" style={{ width: 20, height: 20 }} alt={msg.attachment_name} />
                                <Typography.Text>{msg.attachment_name}</Typography.Text>
                            </div> : null}
                            <Typography.Text style={{ fontSize: 12 }}>{dayjs(msg.created).format('DD/MM/YYYY hh:mm A')}</Typography.Text>
                            {selectedChat.is_group ? <Typography.Text style={{ fontSize: 12 }}>{msg.user}</Typography.Text> : null}
                        </div>
                    }
                })}
            </Space>
        </div>
        <div style={{ width: '100%', paddingTop: 10 }}>
            {attachedFiles.map((file) => {
                return <div style={{ display: 'flex', padding: '5px 20px', gap: 10 }}>
                    <PaperClipOutlined style={{ color: '#1677ff' }} />
                    <Typography.Text>{file.name}</Typography.Text>
                    <DeleteOutlined style={{ color: '#C21807' }} onClick={() => setAttachedFiles([])} />
                </div>
            })}
            <div style={{ display: 'flex', gap: 8, width: '100%', padding: '5px 0', flexDirection: "column" }}>
                <div style={{ display: 'flex', gap: 8 }}>
                    <TextArea
                        size="large"
                        bordered={false}
                        placeholder="Enter your message here..."
                        value={message}
                        onChange={(eve) => setMessage(eve.target.value)}
                        onKeyUp={(event) => {
                            if (event.keyCode === 13) {
                                sendMessage();
                            }
                        }}
                        autoSize={{
                            minRows: 3,
                            maxRows: 6,
                        }}
                    />
                    {/* <Input
                        size="large"
                        bordered={false}
                        placeholder="Enter your message here..."
                        value={message}
                        onChange={(eve) => setMessage(eve.target.value)}
                        onKeyUp={(event) => {
                            if (event.keyCode === 13) {
                                sendMessage();
                            }
                        }}
                    /> */}
                    <Tooltip title="Attach File">
                        <Upload className="wraped-upload" beforeUpload={attachFile} maxCount={1}>
                            <Button icon={<PaperClipOutlined />} />
                        </Upload>
                    </Tooltip>
                    <Tooltip title="Send Message">
                        <ConfigProvider
                            theme={{
                                token: {
                                    colorPrimary: NewColorTheme.primaryColor,
                                    width: "100%"
                                },
                            }}
                        >
                            <Button type='primary' onClick={() => sendMessage()} icon={<SendOutlined />} />
                        </ConfigProvider>
                    </Tooltip>
                </div>
                <div style={{ display: "flex", width: "100%", justifyContent: "flex-end" }}>
                    <Typography.Text style={{ fontSize: 14 }}>{lastRefresh ? `Last Refresh: ${lastRefresh}` : ""}</Typography.Text>

                </div>


            </div>

        </div>
    </div > : <div
        style={{
            width: '100%',
            justifyContent: 'center',
            display: 'flex',
            height: '100%',
            alignItems: 'center'
        }}>
        <Empty
            image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
            imageStyle={{
                height: 120,
            }}
            description={
                <span>
                    <Typography style={{ fontFamily: "figtree", fontSize: 18, color: "" }}>No Chat Selected</Typography>
                </span>
            }
        >
            {/* <Button type="primary">Create Now Conversation</Button> */}
        </Empty>
    </div>
}

export default ChatBox;