import { Segmented, Select, Space, Typography } from 'antd';
import { useState } from 'react';


export const progressStatus = {
  CRITICAL : "critical",
  NORMAL : "normal",
  INTERMEDIATE : "intermediate"
}

const ProgressChart = ({startData, endDate,leftText, rightText,progress})=> {

  const classSwitch = () => {
    if(progress>60){
      return progressStatus.NORMAL;
    }
    if(progress>=30){
      return progressStatus.INTERMEDIATE;
    }
    return progressStatus.CRITICAL;
    
  }

  return (
    <div className='progress-content-wrapper'>
      <div className='start-text'>{startData}</div>
      <div className='progress-chrat-wrapper' >
        <div className='left-text-content'>{leftText} <span className={`progress-val ${progress<30?"critical-color":""}`}>{`${progress}%`}</span></div>
        <div className={`progress ${classSwitch()}`} style={{width:`${progress}%`}}></div>
        <div className='right-text-content'>{rightText}</div>
      </div>
      <div className='end-text'>{endDate}</div>
      </div>
      
  );
}

export default ProgressChart;
