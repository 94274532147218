import { Button, Card, Col, FloatButton, Form, Image, Row, Select, Space, Spin, Tooltip, Typography } from 'antd'
import React, { useState, useEffect } from 'react'
import { getAgencies, getAgencyWiseDashboard, getCountryWiseDashboard, getPendingInquiryCounts, getStatusChangeRequestsCount, getUserById, getUserRequestsCount } from '../../../api'
import './newdashboard.styles.css'
import UserDetails from '../../../Components/DashBoard/UserDetails'
import SmallCard from '../../../Components/DashBoard/SmallCard'
import CountUp from 'react-countup';
import NewProgressChart from '../../../Components/DashBoard/NewProgressChart'
import Donut from '../../../Components/DashBoard/Donut'
import ProgressChart from '../../../Components/DashBoard/ProgressChart'
import { ToolOutlined, ControlOutlined, DeploymentUnitOutlined, BankOutlined, BookOutlined, UserOutlined, PlusCircleTwoTone, PlusOutlined, CustomerServiceOutlined, CommentOutlined, SettingOutlined } from "@ant-design/icons";
import { GetAgencyWiseAllArticles } from '../../../api'
import DonutSectorData from '../DonutSectorData'
import loading from './loading.gif'
import { BiCog } from "react-icons/bi";
import HBar from '../Agency/HBar'
import dayjs from 'dayjs';
import MainCards from '../NewDashBord2/Components/MainCards'

const myTheme = {
    dashBordBack: "#005476"
}

let userDesignation = ''
let userDashboardName = ''


export const NewDashboard = () => {

    const [form] = Form.useForm();
    const [loaded, setLoad] = useState(false)
    const [userLevel, setUserLevel] = useState()
    const [userData, setUserData] = useState({})
    const [UserRequests, setCount] = useState({})
    const [StatusCount, setStatusCount] = useState({})
    const [InquiryCount, setInquiryCount] = useState({})
    const [userAgency, setUserAgency] = useState()
    const [reports, setReports] = useState(null);
    const [shouldAnimate, setShouldAnimate] = useState(false);
    const [Donutloaded, setDonut] = useState(true)
    const [agencies, setAgencies] = useState();
    const [selectedAgency, setSelectedAgency] = useState(null);
    const [articleCompletion, setArticleCompletion] = useState([]);
    const [dsActive, setDSActive] = useState(false);
    const [dsStatus, setDSStatus] = useState("");

    useEffect(() => {
        setShouldAnimate(true);
        setDonut(false)

        getArticles()
    }, []);

    const getArticles = async () => {
        GetAgencyWiseAllArticles(await window.localStorage.getItem('ntfc_agency')).then((out) => {
            setArticleCompletion(out.data.out)
        })
    }

    useEffect(() => {

        LoadData()
    }, []);

    const LoadData = async () => {
        setLoad(false)

        setUserLevel(await window.localStorage.getItem('ntfc_level'));
        setUserAgency(await window.localStorage.getItem('ntfc_agency'))
        getUserData()
        getUserRequests()
        getStatusChangeRequests()
        getInquiryCounts()

        // getCountryWiseDashboard({}).then((out) => {
        //     setReports(out.data.data);
        // })


    }

    const donutSectorClick = (params) => {
        setActive(true);
        setDSStatus(params.name);

    }

    const setActive = (torf) => {
        setDSActive(torf)
    }

    const getUserData = async function () {
        const user = await getUserById(await window.localStorage.getItem('user_id'))



        if (user.status === 200) {
            setUserData(user.data.user)

            if (user.data.user.user_level === 7) {
                userDesignation = "Super Admin"
                userDashboardName = "Dashboard - NTFC Secretariat "
                // myTheme.dashBordBack = "#005476"


            } else if (user.data.user.user_level === 6) {
                userDesignation = "Secretariat"
                userDashboardName = "Dashboard - NTFC Secretariat"
                // myTheme.dashBordBack = "#005476"

            } else if (user.data.user.user_level === 5) {
                userDesignation = "Head of the Department"
                userDashboardName = "Dashboard - " + (user.data.user.agency_name ? user.data.user.agency_name : "N/A")


            } else if (user.data.user.user_level === 4) {
                userDesignation = "Technical User"
                userDashboardName = "Dashboard - " + (user.data.user.agency_name ? user.data.user.agency_name : "N/A")


            } else if (user.data.user.user_level === 3) {
                // userDesignation = "Private"
                userDesignation = "Donor"
                userDashboardName = "Dashboard - " + (user.data.user.agency_name ? user.data.user.agency_name : "N/A")


            } else if (user.data.user.user_level === 2) {
                userDesignation = "Private Sector User"
                userDashboardName = "Dashboard -  " + (user.data.user.agency_name ? user.data.user.agency_name : "N/A")


            } else if (user.data.user.user_level === 1) {
                userDesignation = "User"
                userDashboardName = "Dashboard - " + (user.data.user.agency_name ? user.data.user.agency_name : "N/A")

            }
            let agency = await window.localStorage.getItem('ntfc_agency')
            let level = await window.localStorage.getItem('ntfc_level')

            getAgencies().then((out) => {


                out.data.out.unshift({ label: "All Country Wise", value: 0 })


                let filteredAgency = out.data.out.filter((e) => e.value == agency)[0]

                if (Number(level) == 5 || Number(level) == 4) {


                    let filterer = out.data.out.filter((e) => e.value == agency)
                    filterer.unshift({ label: "All Country Wise", value: 0 })
                    setAgencies(filterer)
                    form.setFieldValue('agency', filteredAgency)
                    setSelectedAgencyDonut(filteredAgency.value)
                    setSelectedAgency(filteredAgency)
                    GetAgencyWiseAllArticles(filteredAgency.value).then((out) => {
                        setArticleCompletion(out.data.out)
                    })

                } else {
                    setAgencies(out.data.out)
                    form.setFieldValue('agency', out.data.out[0])
                    setSelectedAgencyDonut(out.data.out[0].value)
                    GetAgencyWiseAllArticles(0).then((out) => {
                        setArticleCompletion(out.data.out)
                    })
                    setSelectedAgency("All Country Wise")
                }

            });




        }


        setLoad(true)

    }

    const getUserRequests = async function () {
        const requestsCount = await getUserRequestsCount()

        if (requestsCount.status === 200) {
            setCount(requestsCount.data)
        }
    }
    const getStatusChangeRequests = async function () {
        const StatusCount = await getStatusChangeRequestsCount()

        if (StatusCount.status === 200) {
            setStatusCount(StatusCount.data)
        }
    }

    const getInquiryCounts = async function () {
        const InquiryCount = await getPendingInquiryCounts()

        if (InquiryCount.status === 200) {
            setInquiryCount(InquiryCount.data)
        }
    }


    // Card Component 
    const getColor = (count) => {

        if (parseInt(count) == 0) {
            return "gray"
        } else if (parseInt(count) <= 10) {
            return "#53EF6C"
        } else if (parseInt(count) <= 20) {
            return "#FEA500"
        } else {
            return "#aed8e6"
        }
    }
    const getLength = (length) => {
        if (length <= 4) {
            return 12
        } else if (length <= 6) {
            return 12
        } else {
            return 6
        }
    }

    const setSelectedAgencyDonut = async (val) => {

        console.log("Value-----", val)
        setDonut(false)

        if (val.value === 0) {
            getCountryWiseDashboard({}).then((out) => {
                console.log("out country-----", out)
                setReports(out.data.data);
                setDonut(true)
            })
        } else {
            getAgencyWiseDashboard(val, {}).then((out) => {
                console.log("out-----", out)
                setReports(out.data.data);
                setDonut(true)


            })
        }


    }

    return (
        <div style={{ padding: '20px 50px', width: "100%" }} >
            <div className="bgSidePanel"></div>
            <div className="sidepanel-left"></div>

            {userData && loaded ?
                <>

                    <div >
                        <UserDetails
                            dashborad={true}
                            username={(userData ? userData?.first_name : "") + " " + (userData ? userData?.last_name : "")}
                            designation={userDesignation}


                        />
                        <div className="dashbord-title-div">
                            <Typography className="dashboard-name">{userDashboardName}</Typography>
                            <p
                                style={{
                                    borderBottom: '2px solid #ffff',
                                    width: "100%"
                                }} >

                            </p>
                        </div>


                        <div className="dashbord-card-div" >
                            {/* {(userLevel && userData) && <MainCards userLevel={userLevel} userData={userData} />} */}
                            <Row gutter={[16, 16]} style={{ paddingTop: 10 }}>



                                {userLevel !== '4' &&
                                    <Col span={6}>
                                        <SmallCard
                                            backColor={0}
                                            disable={false}
                                            title="User Management"
                                            mainCount={UserRequests.count}
                                            countName={"Requests"}
                                            techUser={false}
                                            simpleText1="All Users"
                                            simpleText2="User Groups"
                                            borderColor={getColor(UserRequests.count)}
                                            // url="/users/0"
                                            opt1url="/allusers"
                                            requestCountCardUrl="/userrequestshod"
                                        />
                                    </Col>
                                }
                                {userLevel !== '4' &&
                                    <Col span={6}>
                                        <SmallCard
                                            backColor={0}
                                            disable={false}
                                            title="Task Status Change Requests"
                                            mainCount={StatusCount.count}
                                            countName={"Requests"}
                                            simpleText1="Overdue"
                                            simpleText2="User Groups"
                                            borderColor={getColor(StatusCount.count)}
                                            url="/requests"

                                        />
                                    </Col>}

                                <Col span={6}>
                                    <SmallCard
                                        userLevel={userLevel}
                                        donor={true}
                                        url="/newdashboard"
                                        opt1url="/donors"
                                        opt2url="/donorrequestsntfc"
                                        opt3url="/donorrequestsvalidate"
                                        backColor={0}
                                        disable={(userLevel == '4' || userLevel == '5') ? false : false}
                                        title="Donor Management"
                                        mainCount={0}
                                        countName={"Requests"}
                                        simpleText1="Donor Profile"
                                        simpleText2="My Donor Requests"
                                        simpleText3="EOI"
                                        simpleText4="Assigned Requests"
                                        requestCountCardUrl="/alldonorreuestsbyagency"
                                        opt4url="/allassigneddonorrequest"
                                        borderColor={getColor(0)}
                                        donorInterest='/getalldonorinterests'
                                        opt5url='/agencyinterestdonorinterest'
                                        simpleText5="Donor Interest"


                                    />
                                </Col>
                                <Col span={6}>
                                    <SmallCard
                                        backColor={0}
                                        disable={(userLevel == '4' || userLevel == '5') ? false : false}
                                        title="Inquiries Management"
                                        mainCount={InquiryCount ? InquiryCount.count : 0}
                                        countName={"Requests"}
                                        simpleText1="Create Inquiry"
                                        simpleText2="View Inquiries"
                                        borderColor={getColor(0)}
                                        url="/inquiries/2"
                                        opt2url="/inquiries"
                                        opt1url="/inquiries"
                                    />
                                </Col>

                                {userLevel === '6' || userLevel === '7' &&
                                    <Col span={6}>
                                        <SmallCard
                                            backColor={0}
                                            disable={false}
                                            title="Action Plan Management"
                                            mainCount={UserRequests.count}
                                            countName={"Requests"}
                                            techUser={false}
                                            // simpleText1="All Users"
                                            // simpleText2="User Groups"
                                            borderColor={getColor(UserRequests.count)}
                                            url="/tasksactionplan"
                                        // opt1url="/users/2"
                                        />
                                    </Col>
                                }
                                {/* Action Mangement */}
                                {(userLevel === "4" || userLevel === "5") &&
                                    <Col span={24}>
                                        <Row gutter={24}>
                                            <Col span={24}>
                                                <Card className="card" onClick={() => { window.location.href = "/tasksactionplan" }}>

                                                    <Row gutter={16} >
                                                        <Col span={24}>
                                                            <div className="card-title-div">

                                                                <Typography className={"card-title"}>Action Management</Typography>
                                                            </div>

                                                        </Col>

                                                        <Col span={24}>
                                                            <Row gutter={16} align="middle" justify="space-between">

                                                                <Col span={4}>
                                                                    <div className="main-number-count-todo" >
                                                                        <div className="count-div" >
                                                                            {/* <div>{countUp}</div> */}
                                                                            <Typography className={"count"}>

                                                                                {shouldAnimate && <CountUp end={reports && reports.length > 0 ? reports[0].data[0].count : 0} duration={2} />}
                                                                            </Typography>
                                                                            <p className={"countText"}>To Do</p>
                                                                        </div>
                                                                    </div>

                                                                </Col>
                                                                <Col span={4}>
                                                                    <div className="main-number-count-inprogress" >
                                                                        <div className="count-div" >
                                                                            {/* <div>{countUp}</div> */}
                                                                            <Typography className={"count"}>
                                                                                {shouldAnimate && <CountUp end={reports && reports.length > 0 ? reports[0].data[1].count : 0} duration={2} />}
                                                                            </Typography>
                                                                            <p className={"countText"} style={{ whiteSpace: "nowrap" }}>In Progress</p>
                                                                        </div>
                                                                    </div>

                                                                </Col>
                                                                <Col span={8}>
                                                                    <div className="main-number-count-complete" >
                                                                        <div className="count-div" >
                                                                            {/* <div>{countUp}</div> */}
                                                                            <Typography className={"count"}>
                                                                                {shouldAnimate && <CountUp end={reports && reports.length > 0 ? reports[0].data[2].count : 0} duration={2} />}
                                                                            </Typography>
                                                                            <p className={"countText"}>Completed</p>
                                                                        </div>
                                                                    </div>

                                                                </Col>
                                                                <Col span={4}>
                                                                    <div className="main-number-count-delayed" >
                                                                        <div className="count-div" >
                                                                            {/* <div>{countUp}</div> */}
                                                                            <Typography className={"count"}>
                                                                                {shouldAnimate && <CountUp end={reports && reports.length > 0 ? reports[0].data[3].count : 0} duration={2} />}
                                                                            </Typography>
                                                                            <p className={"countText"}>Delayed</p>
                                                                        </div>
                                                                    </div>

                                                                </Col>
                                                                <Col span={4}>
                                                                    <div className="main-number-count-missed" style={{ border: `1px solid white` }}>
                                                                        <div className="count-div" >
                                                                            {/* <div>{countUp}</div> */}
                                                                            <Typography className={"count"}>
                                                                                {shouldAnimate && <CountUp end={reports && reports.length > 0 ? reports[0].data[4].count : 0} duration={2} />}
                                                                            </Typography>
                                                                            <p className={"countText"}>Missed</p>
                                                                        </div>
                                                                    </div>

                                                                </Col>


                                                            </Row>

                                                        </Col>




                                                    </Row>



                                                </Card>
                                            </Col>

                                        </Row>
                                    </Col>
                                }

                                <Col span={24}>
                                    <Card className="card" style={{ paddingTop: 15, paddingLeft: 15 }} >
                                        <Row gutter={[16, 20]} justify="center">
                                            <Col span={24}>
                                                <div style={{
                                                    // marginTop: 15,
                                                    justifyContent: 'flex-start',
                                                    display: 'flex',
                                                    alignItems: 'center'
                                                }}>
                                                    <Form form={form}>

                                                        <Form.Item name="agency" required>
                                                            <Select
                                                                onChange={(e) => {
                                                                    setSelectedAgency(e)
                                                                    setSelectedAgencyDonut(e)
                                                                }
                                                                }
                                                                placeholder="Select Agency"
                                                                allowClear

                                                                options={agencies}



                                                            />
                                                        </Form.Item>
                                                    </Form>
                                                </div>
                                            </Col>


                                            <Col span={18}>
                                                <Row gutter={24} >

                                                    <Col span={24}>
                                                        {/* <div className="title-div-bars">
                                                            <Typography className="card-title">Article Completion status</Typography>
                                                            <Select
                                                                allowClear
                                                                options={agencies}
                                                                onChange={(val) => setSelectedAgency(val)}
                                                                placeholder="Agencies"
                                                                style={{ width: '16vw' }}
                                                                defaultValue={{ value: "All" }}

                                                            />
                                                        </div> */}

                                                        <Row gutter={[16, 30]} >
                                                            <Col span={24}>
                                                                <Typography className="card-title">TFA Article Completion status</Typography>
                                                            </Col>

                                                            {
                                                                articleCompletion.map((item, index) => (
                                                                    <Col span={getLength(articleCompletion.length)} className="bar-chart-col" key={index}>
                                                                        <ProgressChart
                                                                            articleNo={item.article_no}
                                                                            title={item.article}
                                                                            rightText={Math.floor(item.article_completion) + "%"}
                                                                            progress={item.article_completion}
                                                                        />
                                                                    </Col>
                                                                ))}
                                                        </Row>

                                                    </Col>
                                                </Row>
                                            </Col>


                                            <Col span={5}>
                                                <Row gutter={[16, 16]} >

                                                    <Col span={24}>
                                                        <div style={{ display: "flex", width: "100%", justifyContent: "center" }}>
                                                            <Typography className="card-title">TFA Implemation Summary</Typography>

                                                        </div>

                                                    </Col>

                                                    {Donutloaded ?
                                                        <Col span={24}>
                                                            <Donut report={reports && reports.length > 0 ? reports.filter(element => element.report_name === "Action wise implementation percentage")[0] : null} donutSectorClick={donutSectorClick} />
                                                            {/* <DonutSectorData
                                                                active={dsActive}
                                                                setActive={setActive}
                                                                donut_title="TFA Implementation Summary"
                                                                donut={true}
                                                                status={dsStatus}
                                                                dataSource={reports && reports.length > 0 ? reports.filter(element => element.report_name === "Agency wise Summary")[0] : null}
                                                            /> */}
                                                        </Col>
                                                        :
                                                        <Col span={24}>
                                                            <div style={{
                                                                height: '220px',
                                                                justifyContent: 'center',
                                                                display: 'flex',
                                                                alignItems: 'center'

                                                            }}>
                                                                <Spin tip="Loading" size="large" />
                                                            </div>


                                                        </Col>}

                                                    <Col span={24}>
                                                        <div style={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
                                                            <span style={{
                                                                background: "#FFFFFF",
                                                                border: "1px solid #0492c2",
                                                                borderRadius: "5px",
                                                                color: "#0492c2",
                                                                padding: "3px 8px",
                                                                fontSize: 12,




                                                            }}>
                                                                Complete
                                                            </span>
                                                            <span style={{
                                                                background: "#FFFFFF",
                                                                border: "1px solid #3346E7",
                                                                borderRadius: "5px",
                                                                color: "#3346E7",
                                                                padding: "3px 8px",
                                                                fontSize: 12


                                                            }}>
                                                                In Progress
                                                            </span>
                                                            <span style={{
                                                                background: "#FFFFFF",
                                                                border: "1px solid #E290FF",
                                                                borderRadius: "5px",
                                                                color: "#E290FF",
                                                                padding: "3px 8px",
                                                                fontSize: 12


                                                            }}>
                                                                To Do
                                                            </span>
                                                            <span style={{
                                                                background: "#FFFFFF",
                                                                border: "1px solid #2f69b3",
                                                                borderRadius: "5px",
                                                                color: "#2f69b3",
                                                                padding: "3px 8px",
                                                                fontSize: 12


                                                            }}>
                                                                Delayed
                                                            </span>
                                                            <span style={{
                                                                background: "#FFFFFF",
                                                                border: "1px solid #FF9900",
                                                                borderRadius: "5px",
                                                                color: "#FF9900",
                                                                padding: "3px 8px",
                                                                fontSize: 12


                                                            }}>
                                                                Missed
                                                            </span>

                                                        </div>

                                                    </Col>



                                                </Row>

                                            </Col>


                                        </Row>

                                    </Card>
                                </Col>

                                {/* Expected vs Actual Measure Completion */}
                                {selectedAgency !== "All Country Wise" &&
                                    <Col span={24} >
                                        <Card
                                            className="card"
                                        >
                                            <Row gutter={16} >
                                                <Col span={24}>
                                                    <div className="card-title-div">

                                                        <Typography className={"card-title"}>Expected vs Actual Measure Completion</Typography>
                                                    </div>

                                                </Col>
                                                <Col span={24}>
                                                    {console.log("report", reports?.filter((element) => element.report_name == "Expected vs Actual Measure Completion")[0].data)}

                                                    {reports?.filter((element) => element.report_name == "Expected vs Actual Measure Completion")[0].data.map((e, index) => {
                                                        console.log(dayjs(e.started_date).format('DD/MM/YYYY'))
                                                        return <NewProgressChart
                                                            key={index}
                                                            startData={dayjs(e.started_date).format('DD/MM/YYYY')}
                                                            endDate={dayjs(e.end_date).format('DD/MM/YYYY')}
                                                            leftText={e.article_no + ". " + e.measure_no + ". " + e.article}
                                                            rightText={e.day_diff}
                                                            progress={Math.floor(e.completion)}
                                                        />
                                                    }


                                                    )


                                                    }
                                                </Col>
                                            </Row>

                                        </Card>


                                    </Col>
                                }
                                <Col span={24}>
                                    <Card
                                        className="card"
                                    >
                                        <Space style={{ marginTop: 10 }} align="start">
                                            <Space direction="vertical" size="large" style={{ width: "90vw" }}>
                                                <Card
                                                    style={{
                                                        boxShadow: "1px 0px 5px -2px rgba(33, 19, 13, 0.3)",
                                                        textAlign: "center",
                                                    }}
                                                    title="Agency Wise Measure Implementation Status"
                                                >
                                                    <HBar report={reports && reports.length > 0 ? reports.filter((e) => e.report_name == "Measure Status Summary")[0] : null} />
                                                </Card>
                                            </Space>
                                        </Space>
                                    </Card>
                                </Col>



                                {userLevel == 6 || userLevel == 7 ?
                                    <Col span={24} style={{ display: "none" }}>
                                        <Row gutter={[16, 16]}>
                                            <Col span={24}>
                                                <Card className="card" >
                                                    <Row gutter={20} >
                                                        <Col span={24}>
                                                            <div className="card-title-div">
                                                                <Typography className={"card-title"}>{"Configurations"}</Typography>
                                                            </div>

                                                        </Col>
                                                        <Col span={4} >
                                                            <div className="side-component-1" onClick={() => {
                                                                window.location.href = `/add-agencies`
                                                            }}>

                                                                <Typography className={"side-component-text"}>{'Agencies'}</Typography>
                                                                <Button
                                                                    onClick={() => {
                                                                        window.location.href = `/add-agencies`
                                                                    }}
                                                                    // disabled={disable ? true : false}
                                                                    shape="circle"
                                                                    icon={<PlusOutlined />}
                                                                    style={{ width: 30, height: 30, backgroundColor: "white", color: "black", borderRadius: 50 }}
                                                                />
                                                            </div>
                                                        </Col>

                                                        <Col span={4} >
                                                            <div className="side-component-1" onClick={() => {
                                                                window.location.href = `/add-articles`
                                                            }}>
                                                                <Typography className={"side-component-text"}>{'Articles'}</Typography>
                                                                <Button
                                                                    // disabled={disable ? true : false}
                                                                    shape="circle"
                                                                    icon={<PlusOutlined />}
                                                                    style={{ width: 30, height: 30, backgroundColor: "white", color: "black", borderRadius: 50 }}
                                                                />
                                                            </div>
                                                        </Col>

                                                        <Col span={4} >
                                                            <div className="side-component-1" onClick={() => {
                                                                window.location.href = `/add-measures`
                                                            }}>
                                                                <Typography className={"side-component-text"}>{'Measures'}</Typography>
                                                                <Button
                                                                    // disabled={disable ? true : false}
                                                                    shape="circle"
                                                                    icon={<PlusOutlined />}
                                                                    style={{ width: 30, height: 30, backgroundColor: "white", color: "black", borderRadius: 50 }}
                                                                />
                                                            </div>
                                                        </Col>

                                                        <Col span={4} >
                                                            <div className="side-component-1" onClick={() => {
                                                                window.location.href = `/add-actions`
                                                            }}>
                                                                <Typography className={"side-component-text"}>{'Actions'}</Typography>
                                                                <Button
                                                                    // disabled={disable ? true : false}
                                                                    shape="circle"
                                                                    icon={<PlusOutlined />}
                                                                    style={{ width: 30, height: 30, backgroundColor: "white", color: "black", borderRadius: 50 }}
                                                                />
                                                            </div>
                                                        </Col>

                                                        <Col span={4} >
                                                            <div className="side-component-1" onClick={() => {
                                                                window.location.href = `/actionplan`
                                                            }}>
                                                                <Typography className={"side-component-text"}>{'Action Plan'}</Typography>
                                                                <Button
                                                                    // disabled={disable ? true : false}
                                                                    shape="circle"
                                                                    icon={<PlusOutlined />}
                                                                    style={{ width: 30, height: 30, backgroundColor: "white", color: "black", borderRadius: 50 }}
                                                                />
                                                            </div>
                                                        </Col>

                                                    </Row>
                                                </Card>
                                            </Col>
                                        </Row>
                                    </Col>
                                    : ""}

                            </Row>

                        </div>





                    </div>
                    {/* <div className="footer-div" style={{ backgroundColor: myTheme.dashBordBack }}>
                    </div> */}

                </>
                :
                <div className="spin-container">
                    <Image src={loading} ></Image>
                    {/* <Spin size="large" /> */}
                </div>
            }











            {/* <Header
                username={(userData?.first_name) + " " + (userData?.last_name)}
                backgroundColor={
                    userLevel === "7" ? "#005476" :
                        userLevel === "6" ? "#005476" :
                            userLevel === "5" ? "#650164" :
                                userLevel === "4" ? "#650164" :
                                    null}
                designation={
                    userLevel === "7" ? "Super Admin" :
                        userLevel === "6" ? "Secretariat " :
                            userLevel === "5" ? "Government Head of the Department " :
                                userLevel === "4" ? "Government Techical" :
                                    userLevel === "3" ? "Private" :
                                        userLevel === "2" ? "Donor" :
                                            userLevel === "1" ? "Other " :
                                                ""}
                dashboardName={
                    userLevel === "7" ? "Super Admin Dashboard" :
                        userLevel === "6" ? "NTFC Secretariat Dashboard" :
                            userLevel === "5" ? "HOD Dashboard " :
                                userLevel === "4" ? "Technical User Dashboard" :
                                    null}
                UserRequests={UserRequests}
                StatusCount={StatusCount}
                donorMangemnet={0}
                InquriManagement={0}
                userLevel={userLevel}
                userAgency={userAgency}
            >

            </Header> */}
            {userLevel == 6 || userLevel == 7 &&
                <FloatButton.Group

                    trigger="click"
                    type="primary"
                    style={{
                        right: 50,
                        fontSize: 0, lineHeight: 0
                    }}
                    icon={<SettingOutlined />}


                >
                    <Tooltip color="cyan" placement="left" title="Setup Action Plan">
                        <FloatButton onClick={() => {
                            window.location.href = `/actionplan`
                        }} icon={<ToolOutlined />} />
                    </Tooltip>


                    <Tooltip color="cyan" placement="left" title="Setup Actions">
                        <FloatButton onClick={() => {
                            window.location.href = `/add-actions`
                        }} icon={<ControlOutlined />} />
                    </Tooltip>
                    <Tooltip color="cyan" placement="left" title="Setup Measures">
                        <FloatButton onClick={() => {
                            window.location.href = `/add-measures`
                        }} icon={<DeploymentUnitOutlined />} />
                    </Tooltip>
                    <Tooltip color="cyan" placement="left" title="Setup Articles">
                        <FloatButton onClick={() => {
                            window.location.href = `/add-articles`
                        }} icon={<BookOutlined />} />
                    </Tooltip>
                    <Tooltip color="cyan" placement="left" title="Setup Agencies">
                        <FloatButton onClick={() => window.location.href = `/add-agencies`} icon={<BankOutlined />} />
                    </Tooltip>

                </FloatButton.Group>
            }






        </div>
    )
}
