import { List, Typography, Input, Space, Button, message, Tooltip, Form, Row, Col, ConfigProvider, Table, Modal, Divider, Spin, Card, FloatButton } from 'antd';
import { PlusOutlined, CloseOutlined, EditOutlined, RadarChartOutlined, EyeOutlined, BankOutlined, BlockOutlined, ContainerOutlined, EnvironmentOutlined, SettingOutlined, ToolOutlined, ControlOutlined, DeploymentUnitOutlined, BookOutlined } from '@ant-design/icons';
import { useEffect, useState } from 'react';
import { addArticle, getArticlesList, updateArticle, getNextArticleNo } from '../../api';
import PageLayouts from '../../Components/PageLayouts';
import CustomTable from '../../Components/Table';
import TextArea from 'antd/es/input/TextArea';
import { ToastContainer, toast, Zoom } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import FormLayouts from '../DonorsNew/Components/FormLayouts';
import CardTitle from '../../Components/ViewDialogCardTitle/CardTitle';
import { Fragment } from 'react';
import FullSearch from '../../Components/FullSearch';



const AddArticles = ({ article }) => {
    const [items, setItems] = useState([]);

    const [loading, setLoad] = useState(true)


    const [data, setData] = useState([]);
    const [filtered_data, setFilteredData] = useState([]);
    const [full, setFull] = useState([]);
    const [viewOnly, setViewOnly] = useState(false);
    const [summary, setSummary] = useState("");

    const [selected, setSelected] = useState(null);

    const [currentRecord, setCurrentRecord] = useState({})
    const [isModalOpen, setIsModalOpen] = useState(false);

    const [form] = Form.useForm();
    const [article_name, setArticles] = useState()

    const [btnDisable, setDisable] = useState(true)

    const isFieldsTouched = () => {
        return form.isFieldsTouched();
    };
    const [isChair, setChair] = useState(false)



    const columns = [
        {
            title: 'Article No',
            dataIndex: 'article_no',
            key: 'article_no',
            width: 100,
            render: (_, record) => (
                record &&
                <div style={{ textAlign: 'center' }}>
                    <p>{record.article_no}</p>

                </div>

            ),



        },
        {
            title: 'Article',
            dataIndex: 'article',
            key: 'article',
            width: isChair ? 650 : 350,

        },

        {
            title: 'Article Summary',
            dataIndex: 'summary',
            key: 'summary',
            width: isChair ? 400 : 280,
        },
        {
            title: 'Action',
            key: 'action',
            render: (_, record) => (
                record && !record.moreData ?
                    <div>
                        {!isChair &&
                            <Tooltip title="Update Article" >
                                <Button type="secondary" size='small' onClick={() => {

                                    if (selected !== record) {
                                        form.resetFields();

                                    }
                                    setSelected(record)
                                    setDisable(true)

                                }} shape="circle" icon={<EditOutlined style={{ color: '#946e6e' }} />} />
                            </Tooltip>
                        }

                        <Tooltip title="View Article">
                            <Button type="secondary" size='small' onClick={() => {
                                setSelected(null)
                                form.resetFields();
                                setCurrentRecord(record)
                                showModal(true)
                                setDisable(true)
                                console.log(record)
                            }} shape="circle" icon={<EyeOutlined style={{ color: 'darkgreen' }} />} />
                        </Tooltip>
                    </div>
                    : null
            ),
        },


    ]; const showModal = () => {
        setIsModalOpen(true);
    };
    const handleOk = () => {
        setIsModalOpen(false);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const refreshArticles = () => {
        setDisable(true)
        setLoad(true)
        getArticlesList().then((out) => {
            setItems(out.data.out.sort((a, b) => a.article_no - b.article_no));
        });
        getNextArticleNo().then((out) => {
            form.setFieldValue("article_no", out.data.out[0].id)
            form.validateFields(['article_no']);
        });
        setLoad(false)
    }

    const [messageApi, contextHolder] = message.useMessage();



    const onFinish = (values) => {
        console.log('Success:', values);

        let prev_article_no = values.article_no;
        let prev_article = values.article
        let repeated = false;
        let nameRepeated = false

        if (selected) {
            items.forEach(item => {
                if (item.article_no == prev_article_no && item.article_no != selected.article_no) {
                    repeated = true;
                }
                if (item.article == prev_article && item.article != selected.article) {
                    nameRepeated = true;
                }
            })
        } else {
            items.forEach(item => {
                if (item.article_no == prev_article_no) {
                    repeated = true;
                }
                if (item.article == prev_article) {
                    nameRepeated = true;
                }
            })
        }

        if (repeated) {


            toast.error("Article Number Already Exists", {

                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
            return;
        }
        if (nameRepeated) {


            toast.error("Article Name Already Exists", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
            return;
        }

        if (!selected) {
            addArticle(values).then((out) => {


                if (out) {
                    console.log("out ================== 22222", out)
                    toast.success('The article has been successfully added', {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    })
                    form.resetFields();
                    refreshArticles();
                    setSummary("");
                    setArticles("")
                    setDisable(true)

                } else {
                    refreshArticles();
                    toast.error("Error on adding a article", {

                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    });


                }
            }).catch((err) => {
                console.log(err);
                refreshArticles();
                toast.error("Error", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
                // messageApi.open({
                //     type: 'error',
                //     content: 'Article creation unsuccessful',
                //     style: {
                //         marginTop: '30vh',
                //         height: '100vh',
                //         fontSize: '20px'
                //     },
                //     duration: 3
                // });
            })
        } else {
            values.id = selected.id;

            if (isFieldsTouched()) {
                setDisable(false)
                updateArticle(values).then((out) => {
                    if (out.data.status === 200) {
                        form.resetFields();
                        refreshArticles();
                        toast.success("The article has been successfully updated", {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "colored",
                        })

                        setSelected(null);
                        setDisable(true)
                    } else {
                        toast.error("The article update was unsuccessful", {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "colored",
                        })

                    }
                }).catch((err) => {
                    console.log(err);
                    toast.error("The article update was unsuccessful", {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    })

                })
            } else {
                setDisable(true)
                toast.warning("There are no changes to update the article", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                })
                form.resetFields();
                refreshArticles();
                setSummary("");
                setArticles("")
            }

        }
        setSummary("")
        setArticles("")
    };
    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);

        if (!selected) {
            toast.error("The article added was unsuccessful", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            })

        } else {
            toast.error("The article added was unsuccessful", {

                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            })

        }
        getNextArticleNo().then((out) => {
            form.setFieldValue('article_no', out.data.out[0].id)
        });
    };

    useEffect(() => {
        if (window.localStorage.getItem('ntfc_level') == 6) {
            setChair(true)
        } else {
            setChair(false)
        }
        refreshArticles();
    }, []);

    const handleSelected = (value) => {
        setSelected(value)
    }

    useEffect(() => { setData(items); setFilteredData(items); setFull(items); }, [items]);

    useEffect(() => {
        if (selected) {
            form.setFieldValue('article_no', selected.article_no)
            form.setFieldValue('article', selected.article)
            form.setFieldValue('summary', selected.summary)
            setSummary(selected.summary)
            setArticles(selected.article)
        } else {
            form.resetFields();
        }
        // eslint-disable-next-line
    }, [selected]);

    return <Fragment>
        <FormLayouts
            formTitle={"Add Articles"}
            title="Articles"
            formSide={!isChair ?

                <Form
                    scrollToFirstError
                    form={form}
                    layout="vertical"
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    onValuesChange={(changedValues, allValues) => {
                        if (changedValues) {
                            setDisable(false)
                        } else {
                            setDisable(true)
                        }

                    }}
                >
                    <Row gutter={16}>
                        <Col className="gutter-row" span={24}>
                            <Form.Item label="Article No" name="article_no" required
                                rules={[{
                                    required: true,
                                    message: <p style={{ marginLeft: "12px" }}>Article No is required</p>,
                                },
                                {
                                    pattern: /^[1-9]\d{0,2}$/,
                                    message: <p style={{ marginLeft: "12px" }}>Article no must be a positive number up to three digits</p>,
                                },
                                    // {
                                    //     validator: (_, value) => {
                                    //         if (value && value.trim().length === 0) {
                                    //             return Promise.reject("Article No cannot contain only spaces");
                                    //         }
                                    //         return Promise.resolve();
                                    //     },
                                    // },

                                ]}
                            >
                                <Input type='text' placeholder="Article No" disabled={viewOnly ? true : false}
                                    style={{ backgroundColor: viewOnly ? "#dbdbdb" : "", borderRadius: "5px" }} maxLength={3} showCount />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="gutter-row" span={24}>
                            <Form.Item label="Article Summary" name="summary" required
                                // validateTrigger="onInput"
                                rules={[
                                    {
                                        required: true,
                                        message: <p style={{ marginLeft: "12px" }}>Article summary is required</p>,
                                    },
                                    {
                                        pattern: /^\S.*$/,
                                        message: <p style={{ marginLeft: "12px" }}>Invalid article summary</p>,
                                    },

                                ]}
                            >
                                <Input
                                    //  value={summary}

                                    // onChange={(e) => {

                                    //     setSummary(e.target.value);
                                    //     form.setFieldValue('summary', e.target.value)
                                    // }}
                                    showCount
                                    maxLength={40} type='text' placeholder="Article Summary" disabled={viewOnly ? true : false}
                                    style={{ backgroundColor: viewOnly ? "#dbdbdb" : "", borderRadius: "5px" }} />

                                {/* <div>
                                    <p style={{ float: "right", color: "black", marginBottom: "-20px" }}>{summary?.length}/40</p>
                                </div> */}
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col className="gutter-row" span={24}>
                            <Form.Item label="Article" name="article" required
                                // validateTrigger="onInput"
                                rules={[

                                    {
                                        required: true,
                                        message: <p style={{ marginLeft: "12px" }}>Article is required</p>,
                                    },
                                    {
                                        pattern: /^\S.*$/,
                                        message: <p style={{ marginLeft: "12px" }}>Invalid Article</p>,
                                    },
                                    // {
                                    //     pattern: /^(?!\s)[a-zA-Z\s]+$/,

                                    //     message: "Please enter a valid art",
                                    // },

                                ]}
                            >
                                <TextArea

                                    // onChange={(e) => {

                                    //     setArticles(e.target.value);
                                    //     form.setFieldValue('article', e.target.value)
                                    // }
                                    // }

                                    // value={article_name}
                                    showCount
                                    maxLength={500} rows={4} placeholder='Article' disabled={viewOnly ? true : false}
                                    style={{ backgroundColor: viewOnly ? "#dbdbdb" : "", borderRadius: "5px" }}

                                />
                                {/* <div>
                                    <p style={{ float: "right", color: "black", marginBottom: "-20px" }}>{article_name?.length}/200</p>
                                </div> */}
                            </Form.Item>
                        </Col>
                    </Row>
                    {viewOnly ?
                        <div style={{ display: "flex", justifyContent: "flex-end", marginTop: "20px" }}>
                            <Button style={{ marginRight: 10 }} type='primary' danger icon={<CloseOutlined />}
                                onClick={() => {
                                    setSelected(null);
                                    setViewOnly(false);
                                    setSummary("");
                                }}>
                                Cancel
                            </Button>
                        </div>
                        :
                        <div style={{ marginTop: "20px" }}>
                            {!selected ?
                                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                                    <Button size='large' type='primary' htmlType="submit" icon={<PlusOutlined />}
                                        disabled={window.localStorage.getItem('view_only') == 1}
                                    >
                                        Add Article
                                    </Button>
                                </div>
                                : <div style={{ display: "flex", justifyContent: "flex-end" }}>
                                    <div>
                                        <Button size='large' style={{ marginRight: 5 }} type='primary' danger icon={<CloseOutlined />}
                                            onClick={() => {
                                                setDisable(true)
                                                setSelected(null);
                                                setViewOnly(false);
                                                setSummary("");
                                                setArticles("")
                                                refreshArticles()
                                            }}>
                                            Cancel
                                        </Button>
                                    </div>
                                    <div>
                                        <Form.Item>


                                            <Button
                                                disabled={window.localStorage.getItem('view_only') == 1 || btnDisable}
                                                size='large' type='primary' htmlType="submit" icon={<EditOutlined />} >
                                                Update
                                            </Button>
                                        </Form.Item>

                                    </div>
                                </div>}
                        </div>
                    }
                </Form> : null

            }
            table={
                !loading ?

                    <div>
                        <FullSearch full={full} setFiltered={setFilteredData} params={["article_no", "article", "summary"]} />
                        <Table
                            rowHover={false}
                            scroll={{
                                y: 400,
                            }}
                            className='custom-table-header custom-table2'

                            columns={columns} dataSource={filtered_data}
                            pagination={{
                                pageSize: 5,
                                total: filtered_data.length,
                                showSizeChanger: false,
                            }} />
                    </div>
                    :
                    <div>
                        <Spin />
                    </div>

            }
        />


        <Modal
            closable={true}

            className="custom-modal"
            // width={700}
            footer={false}
            onCancel={handleCancel}
            title={
                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                    <div>     <Typography className='custom-modal-title' level={2}>Article Details</Typography></div>
                </div>


            } open={isModalOpen} onOk={handleOk} centered >

            <div className='custom-modal-body'>
                <Row gutter={[16, 16]}>
                    <Col span={24}>
                        <CardTitle
                            icon={<RadarChartOutlined />}
                            title={"Article No"}
                            content={currentRecord.article_no}

                        />

                    </Col>
                    <Col span={24}>

                    </Col>
                    <Col span={24}>
                        <CardTitle
                            icon={<ContainerOutlined />}
                            title={"Article Summary"}
                            content={currentRecord.summary}
                        />
                    </Col>
                    <Col span={24}>
                        <CardTitle
                            icon={<BlockOutlined />}
                            title={"Article"}
                            content={currentRecord.article}
                        />
                    </Col>

                </Row>


            </div>



        </Modal>
        {/* <FloatButton.Group

            trigger="click"
            type="primary"
            style={{
                right: 20,
                fontSize: 0, lineHeight: 0
            }}
            icon={<SettingOutlined />}


        >
            <Tooltip color="cyan" placement="left" title="Setup Action Plan">
                <FloatButton onClick={() => {
                    window.location.href = `/actionplan`
                }} icon={<ToolOutlined />} />
            </Tooltip>


            <Tooltip color="cyan" placement="left" title="Setup Actions">
                <FloatButton onClick={() => {
                    window.location.href = `/add-actions`
                }} icon={<ControlOutlined />} />
            </Tooltip>
            <Tooltip color="cyan" placement="left" title="Setup Measures">
                <FloatButton onClick={() => {
                    window.location.href = `/add-measures`
                }} icon={<DeploymentUnitOutlined />} />
            </Tooltip>
            <Tooltip color="cyan" placement="left" title="Setup Articles">
                <FloatButton onClick={() => {
                    window.location.href = `/add-articles`
                }} icon={<BookOutlined />} />
            </Tooltip>
            <Tooltip color="cyan" placement="left" title="Setup Agencies">
                <FloatButton onClick={() => window.location.href = `/add-agencies`} icon={<BankOutlined />} />
            </Tooltip>

        </FloatButton.Group> */}


        <ToastContainer

        />


    </Fragment >
}

export default AddArticles;