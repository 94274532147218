import { Button, Col, Form, Input, Row } from 'antd';
import React, { useState } from 'react'
import TabsMonitor from './TabsMonitor';
import './monitoringtool.css'

const Page = () => {
    const [showDiv, setShowDiv] = useState(false);

    const handleClick = () => {
      setShowDiv(!showDiv);
    }
  return (
    <>
      
      <Row gutter={16}>
        <Col span={6}>
            <div className="side-bar">
                <div className="btnContainer-side">

                    <div className="createBtn">
                        <Button
                         className="btnCreate"
                         onClick={handleClick}
                       >Create New Inquiry</Button>
                    </div>
                    <div>
                    </div>

                    <div className="btnGroup-side">
                        <div className="allBtn">
                            <Button
                            className="btnAll"
                            >All</Button>
                        </div>
                        <div className="myInquires">
                            <Button
                             className="btnInq"
                             >My Inquiries</Button>
                        </div>
                    </div>
                    {showDiv && 
                    <div className="hiddenForm" >
                        <Form

                            layout="vertical"
                        >
                            <Row gutter={16}>
                                <Col className="gutter-row" span={24}>
                                    <Form.Item label="Inquiry Topic" name="inquiry_id" 
                                    
                                    >
                                        <Input type='text' placeholder="Inquiry Topic" />
                                    </Form.Item>
                                </Col>

                                <Col className="gutter-row" span={24}>
                                    <Form.Item label="Inquiry Description" name="inquiry_description" 
                                    >
                                        <Input.TextArea rows={4} placeholder="Inquiry Description" />
                                    </Form.Item>
                                </Col>
                            
                            </Row>
                        
                                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                                    <Button type='primary' htmlType="submit" >
                                        Save
                                    </Button>
                                </div>
                        </Form>
                    </div>
                    }
                    
                </div>
            </div>
        </Col>
        <Col span={18}>
            <div className="tabs">
                  <TabsMonitor />
            </div> 
        </Col>
        
      </Row>
    </>
  )
}
export default Page