import { Row, Col, Tabs, Typography } from 'antd';
import 'react-edit-text/dist/index.css';
import './config.styles.css';
import Agencies from './Agencies';
import { useState } from 'react';
import Articles from './Article';
import Measures from './Measure';
import Actions from './Actions';
import ActionPlan from './ActionPlan';

function Page() {
  const onChange = (key) => {
    console.log(key);
  };

  const tabs = [
    { label: 'Agencies', key: 0, children: <Agencies /> },
    { label: 'Articles', key: 1, children: <Articles /> },
    { label: 'Measures', key: 2, children: <Measures /> },
    { label: 'Actions', key: 3, children: <Actions /> },
    { label: 'Action Plan', key: 4, children: <ActionPlan /> },
  ];

  return (
    <div className='content'>
      <Typography.Title style={{ marginTop: 20 }} level={2}>Configurations</Typography.Title>
      <Tabs
        onChange={onChange}
        items={tabs}
        style={{ height: '-webkit-fill-available' }}
      // type="card"
      // centered
      />
    </div>
  );
}

export default Page;
