import { Card, Col, Row, Skeleton, Typography } from 'antd'
import React from 'react'
import { useEffect } from 'react'

import { useState } from 'react';
import { getAgency } from '../../../../api';


function DonorProfileDetailsForDB() {

    const [data, setData] = useState([]);
    const [loaded, setLoad] = useState(false)

    useEffect(() => {
        loadData()
    }, [])

    const loadData = async () => {

        getAgency(localStorage.getItem('ntfc_agency')).then((out) => {
            console.log("data------>>", out)
            setData(out.data.out[0]);
            setLoad(true)
        })
    }

    return (
        <div style={{ width: "100%" }}>
            <Card className='dash5-card' style={{ height: "20vh" }}>
                {loaded ?
                    <div className='new-action-card-new' style={{ height: "max-content" }}>

                        <Typography className='ac-new-inner-card-title-donor'>{data?.agency_name}</Typography>
                        <Row gutter={[16, 16]}>
                            {/* <Col xs={5}>
                                <div style={{ display: "flex", justifyContent: "center" }}>
                                    <img src="/logo.png" width={80} />
                                </div>
                            </Col> */}
                            <Col xs={24}>
                                <Typography className='ac-new-inner-card-title-donor-content'>{data?.description}</Typography>
                            </Col>

                        </Row>


                    </div>
                    : <Skeleton
                        active
                        avatar
                        paragraph={{
                            rows: 4,
                        }}
                    />


                }

            </Card>

        </div>
    )
}

export default DonorProfileDetailsForDB