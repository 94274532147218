import { Avatar, Button, Card, Col, FloatButton, Row, Space, Typography } from "antd";
import React, { useState, useEffect } from "react";
import "./header.styles.css";
import { PlusOutlined, PlusCircleTwoTone } from "@ant-design/icons";
// import { useCountUp } from 'react-countup';

import CountUp from 'react-countup';
import { getStatusClassNames } from "antd/es/_util/statusUtils";

function SmallCard(props) {

    const [shouldAnimate, setShouldAnimate] = useState(false);
    const [paused, setPaused] = useState(false);

    const animation = {
        d: 'M60,10L60,90L140,90L140,10Z',
        yoyo: true,
        repeat: -1,
        duration: 1000,
    };

    useEffect(() => {
        setShouldAnimate(true);
    }, []);



    const { opt5url, userLevel, simpleText4, donor, title, mainCount, countName, simpleText1, simpleText2, simpleText3, disable, borderColor, url, requestCountCardUrl, techUser, backColor, opt1url, opt2url, cardUrl, opt3url, opt4url, donorInterest, simpleText5,main_url, } = props;
    const getClassName = () => {
        console.log(backColor);
        if (backColor <= 10) {
            return "greencard"
        } else if (backColor <= 20) {
            return "yellowcard"
        } else if (backColor > 20) {
            return "redcard"
        } else {
            return "card"
        }
    }

    return (
        <Card className={disable ? "card-disable" : "card"}  
        onClick={(event) => {
            event.stopPropagation();
            main_url ? window.location.href = main_url : console.log("clicked")
        }}
        >

            <Row gutter={16} >

                <Col span={24}>
                    <div className="card-title-div">

                        <Typography className={disable ? "card-title-disable" : "card-title"}>{title}</Typography>
                    </div>

                </Col>


                {donor && (userLevel === "6" || userLevel === "7") ?
                    <Col span={10}>
                        <Row gutter={16}>
                            <Col span={24}

                                style={{ marginTop: 9, }}
                            >
                                <div className="side-component-1" onClick={(event) => {
                                    event.stopPropagation();
                                    console.log("clikced")
                                    window.location.href = requestCountCardUrl
                                }}>

                                    <Typography className={disable ? "side-component-text-disable" : "side-component-text"}>{"Agency Requests"}</Typography>
                                    {/* <Button
                                        disabled={disable ? true : false}
                                        shape="circle"
                                        icon={<div>{mainCount}</div>}
                                        style={{ width: 30, height: 30, backgroundColor: disable ? "#D9D9D9" : "white", color: disable ? "gray" : "black", borderRadius: 50 }}
                                    /> */}

                                </div>

                            </Col>
                            <Col span={24} style={{ marginTop: 5, }}

                                onClick={(event) => {
                                    event.stopPropagation();
                                    donorInterest ? window.location.href = donorInterest : console.log("clicked")
                                }}>
                                <div className="side-component-1">

                                    <Typography className={disable ? "side-component-text-disable" : "side-component-text"}>{"All Interests"}</Typography>
                                    {/* <Button
                                        disabled={disable ? true : false}
                                        shape="circle"
                                        icon={<div>{mainCount}</div>}
                                        style={{ width: 30, height: 30, backgroundColor: disable ? "#D9D9D9" : "white", color: disable ? "gray" : "black", borderRadius: 50 }}
                                    /> */}

                                </div>

                            </Col>

                        </Row>


                    </Col> :
                    <Col span={techUser ? 24 : 10}
                    >

                        <div className="main-number-count my-card" style={{ border: `1px solid ${borderColor}` }}
                            onClick={(event) => {
                                event.stopPropagation();
                                console.log("clikced")
                                if (url) {
                                    window.location.href = url
                                }
                                if (requestCountCardUrl) {
                                    requestCountCardUrl && (window.location.href = requestCountCardUrl)
                                }


                            }}


                        >
                            <div className="count-div" >
                                {/* <div>{countUp}</div> */}
                                <Typography style={{ color: borderColor }} className={disable ? "count-disable" : "count"}>{shouldAnimate && <CountUp end={mainCount} duration={4} />}</Typography>
                                <p style={{ color: borderColor }} className={disable ? "countText-disable" : "countText"}>{countName}</p>
                            </div>
                        </div>

                    </Col>

                }

                <Col span={14} style={{ padding: "8px 5px", display: techUser ? "none" : "flex" }}>
                    <Row gutter={16}>

                        <Col span={donor ? 12 : 24}
                            style={{ marginBottom: 5 }}
                            onClick={(event) => {
                                event.stopPropagation();
                                opt1url ? window.location.href = opt1url : console.log("clicked")

                            }}>
                            <div className="side-component-1">

                                <Typography className={disable ? "side-component-text-disable" : "side-component-text"}>{simpleText1}</Typography>
                                <Button
                                    disabled={disable ? true : false}
                                    shape="circle"
                                    icon={<PlusOutlined />}
                                    style={{ width: 30, height: 30, backgroundColor: disable ? "#D9D9D9" : "white", color: disable ? "gray" : "black", borderRadius: 50 }}
                                />

                            </div>
                        </Col>

                        <Col span={donor ? 12 : 24}

                            onClick={(event) => {
                                event.stopPropagation();
                                opt2url ? window.location.href = opt2url : console.log("clicked")
                            }}>
                            <div className="side-component-1">
                                <Typography className={disable ? "side-component-text-disable" : "side-component-text"}>{simpleText2}</Typography>
                                <Button
                                    disabled={disable ? true : false}
                                    shape="circle"
                                    icon={<PlusOutlined />}
                                    style={{ width: 30, height: 30, backgroundColor: disable ? "#D9D9D9" : "white", color: disable ? "gray" : "black", borderRadius: 50 }}
                                />
                            </div>

                        </Col>
                        {donor && (userLevel == "6" || userLevel == "7") &&
                            <Col span={12}

                                onClick={(event) => {
                                    event.stopPropagation();
                                    opt3url ? window.location.href = opt3url : console.log("clicked")
                                }}>
                                <div className="side-component-1">
                                    <Typography className={disable ? "side-component-text-disable" : "side-component-text"}>{simpleText3}</Typography>
                                    <Button
                                        disabled={disable ? true : false}
                                        shape="circle"
                                        icon={<PlusOutlined />}
                                        style={{ width: 30, height: 30, backgroundColor: disable ? "#D9D9D9" : "white", color: disable ? "gray" : "black", borderRadius: 50 }}
                                    />
                                </div>

                            </Col>

                        }
                        {
                            donor &&
                            <Col span={12}

                                onClick={(event) => {
                                    event.stopPropagation();
                                    opt3url ? window.location.href = opt4url : console.log("clicked")
                                }}>
                                <div className="side-component-1">
                                    <Typography className={disable ? "side-component-text-disable" : "side-component-text"}>{simpleText4}</Typography>
                                    <Button
                                        disabled={disable ? true : false}
                                        shape="circle"
                                        icon={<PlusOutlined />}
                                        style={{ width: 30, height: 30, backgroundColor: disable ? "#D9D9D9" : "white", color: disable ? "gray" : "black", borderRadius: 50 }}
                                    />
                                </div>

                            </Col>
                        }

                        {
                            donor && (userLevel !== "6" && userLevel !== "7") &&
                            <Col span={12}

                                onClick={(event) => {
                                    event.stopPropagation();
                                    opt3url ? window.location.href = opt5url : console.log("clicked")
                                }}>
                                <div className="side-component-1">
                                    <Typography className={disable ? "side-component-text-disable" : "side-component-text"}>{simpleText5}</Typography>
                                    <Button
                                        disabled={disable ? true : false}
                                        shape="circle"
                                        icon={<PlusOutlined />}
                                        style={{ width: 30, height: 30, backgroundColor: disable ? "#D9D9D9" : "white", color: disable ? "gray" : "black", borderRadius: 50 }}
                                    />
                                </div>

                            </Col>
                        }



                    </Row>

                </Col>


            </Row>



        </Card>

    );
}

export default SmallCard;
