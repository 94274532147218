import { Avatar, Badge, Button, Col, Drawer, FloatButton, Input, List, Row, Space, Tooltip, Typography } from "antd";
import { MessageTwoTone } from "@ant-design/icons"
import { useEffect, useState } from "react";
import CreateConversation from "./CreateConversation";
import ChatList from "./ChatList";
import ChatBox from "./ChatBox";
import { getChats, getGroupChats, getUnreadCount } from "../../api";
import { WechatOutlined, CommentOutlined, CloseOutlined } from "@ant-design/icons";
import { NewColorTheme } from "../../appConst";
const { Text, Title } = Typography;



const Chat = () => {
    const [open, setOpen] = useState(false);
    const [selectedChat, setSelectedChat] = useState(null);
    const [chats, setChats] = useState([]);
    const [groupChats, setGroupChats] = useState([]);
    const [filteredChats, setFilteredChats] = useState([]);
    // const [filteredGroupChats, setFilteredGroupChats] = useState([]);
    const [searchVal, setsearchVal] = useState(null);
    const [unread, setUnread] = useState(0);

    const fetchUnread = () => {
        getUnreadCount().then((res) => {
            if (res && res.data) {
                setUnread(res.data.unread_count);
            }
        })
    }

    useEffect(() => {
        fetchUnread();
        setInterval(fetchUnread, 60000);
    }, []);


    const onClose = () => {
        setOpen(false);
        setSelectedChat(null)
    };

    useEffect(() => {
        setsearchVal(null)
        refreshChats()
    }, [open])


    const refreshChats = () => {
        setsearchVal("")
        getChats({}).then((out) => {
            if (out) {
                setChats(out.data.out);
                setFilteredChats(out.data.out)
            }
        });
        getGroupChats({}).then((out) => {
            if (out) {
                setGroupChats(out.data.out);
            }
            // setFilteredGroupChats(out.data.out)
        });
    }

    useEffect(() => {
        setsearchVal("")
        refreshChats();
    }, []);

    const search = (e) => {
        setsearchVal(e.target.value)
        const filtered = chats.filter((req) =>
            req.chat_name.toLowerCase().includes(e.target.value.toLowerCase())
        );
        setFilteredChats([...filtered]);


    }

    return <div>
        <Badge count={unread}>
            <Tooltip title="NTFC Internal Message">
                <Button
                    className="enlarged-button"
                    onClick={() => setOpen(true)}
                    size='small'
                    // shape="circle" 
                    icon={<CommentOutlined style={{ color: NewColorTheme.primaryColor }} />}
                />
            </Tooltip>
        </Badge>
        {/* <FloatButton
            icon={<WechatOutlined style={{
                fontSize: '47px',
                paddingLeft: '-5px',
                marginLeft: '-12px'
            }} />}
            shape="square"
            type="primary"
            description="Internal Communication"
            style={{
                right: '80px',
                height: '100px',
                width: '100px',
                padding: '2px'
            }} onClick={() => setOpen(true)} /> */}
        <Drawer
            closable={false}
            placement={"right"}
            width={"80vw"}
            onClose={onClose}
            open={open}
            style={{ borderRadius: '20px 0 0 20px', overflow: 'hidden' }}
            className="custom-scroll-div my-drawer"
        >
            <div style={{ height: "100vh", overflow: 'auto' }} className="custom-scroll-div">
                {/* <div style={{ width: '20vw', height: '5vh', }}> */}
                <div style={{ padding: "10px 14px", background: NewColorTheme.primaryColor, display: "flex", justifyContent: "space-between" }}>
                    <Typography style={{ color: "white", fontSize: "16px" }} level={3}>NTFC Messaging Platform</Typography>

                    <CloseOutlined onClick={onClose} style={{ color: "white", fontWeight: 900, cursor: "pointer" }} />



                </div>

                {/* </div> */}
                <div style={{ display: 'flex' }}>
                    <div
                        style={{
                            width: '40vw',
                            // borderRight: '1px solid #e0e0e0',
                            height: '100%',
                            padding: 20,
                            display: "flex",
                            flexDirection: "column",
                            background: "white"
                        }}
                    >

                        <Row gutter={[8, 8]} style={{ width: '100%' }} >
                            <Col xs={24} style={{ width: '100%' }}>

                                <CreateConversation refreshChats={refreshChats} />


                            </Col>

                            <Col xs={24} style={{ width: '100%' }}>
                                <Input.Search
                                    value={searchVal}
                                    placeholder="Type to search" style={{ marginBottom: 10 }} onChange={search} allowClear

                                />
                            </Col>




                        </Row>


                        <ChatList setSelectedChat={setSelectedChat} chats={filteredChats} setChats={setChats} groupChats={groupChats} setGroupChats={setGroupChats} />

                    </div>
                    <div style={{ width: '70vw', padding: "3px 20px 20px 5px", background: "#f0f9ff" }}>
                        <ChatBox selectedChat={selectedChat} refreshChats={refreshChats} />
                    </div>
                </div>
            </div >


        </Drawer >
    </div >
}

export default Chat;