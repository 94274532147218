import { Badge, Button, Divider, Popover, Typography } from "antd";
import { useEffect, useState } from "react";

const StatusCompo = ({ status, setStatus = () => { }, action, onClick, subAction, article, measure, disabled = false }) => {
    const [open, setOpen] = useState(false)
    const statusUpdate = (stat) => {
        // task.status = stat;
        // updateTask({ measure, task_id: task.id, task });
        setOpen(false);
        setStatus(stat, action, subAction, article, measure);
    }
    const statusToggle = (
        <div style={{
            width: '8vw',
            textAlign: 'center'
        }}>
            <Typography.Text strong>Change Status</Typography.Text>
            <Divider style={{ margin: 5 }} />

            {(status !== 4 && status !== 3) && <Button style={{ width: '105px', margin: '5px 0 5px 0', background: '#8E8E8E', color: 'white', border: '#8E8E8E 1px solid' }} onClick={() => statusUpdate(0)}>To Do</Button>}
            {(status !== 4 && status !== 3) && <Button style={{ width: '105px', margin: '5px 0 5px 0', background: '#FDAA3D', color: 'white', border: '#FDAA3D 1px solid' }} onClick={() => statusUpdate(1)}>In-progress</Button>}
            <Button style={{ width: '105px', margin: '5px 0 5px 0', background: '#00C874', color: 'white', border: '#00C874 1px solid' }} onClick={() => statusUpdate(2)}>Completed</Button>
            {/* <Button style={{ width: '105px', margin: '5px 0 5px 0', background: '#ff8282', color: 'white', border: '#ff8282 1px solid' }} onClick={() => statusUpdate(4)}>Missed</Button> */}
        </div>
    );
    return <div style={{ width: '100%', height: "100%", alignItems: "center", justifyContent: "start", display: "flex", zIndex: 99989 }}>
        {disabled ? <>
            {status === 0 ? <Button disabled={disabled} style={{ width: '105px', background: '#8E8E8E', color: 'white', border: '#dedcdc 1px solid', }}>To Do</Button> : null}
            {status === 1 ? <Button disabled={disabled} style={{ width: '105px', background: '#FDAA3D', color: 'white', border: '#FAC858 1px solid' }}>In-progress</Button> : null}
            {status === 2 ? <Button disabled={disabled} style={{ width: '105px', background: '#00C874', color: 'white', border: '#70D983 1px solid' }}>Completed</Button> : null}
            {status === 3 ? <Button disabled={disabled} style={{ width: '105px', background: '#E2445C', color: 'white', border: '#A969C6 1px solid' }}>Delayed</Button> : null}
            {status === 4 ? <Button disabled={disabled} style={{ width: '105px', background: '#ff8282', color: 'white', border: '#ff8282 1px solid' }}>Missed</Button> : null}

        </> :
            <Popover content={statusToggle} trigger="click" placement="bottom" open={open} onOpenChange={(val) => setOpen(val)}>
                {status === 0 ? <Button disabled={disabled} style={{ width: '105px', background: '#8E8E8E', color: 'white', border: '#dedcdc 1px solid', }}>To Do</Button> : null}
                {status === 1 ? <Button disabled={disabled} style={{ width: '105px', background: '#FDAA3D', color: 'white', border: '#FAC858 1px solid' }}>In-progress</Button> : null}
                {status === 2 ? <Button disabled={disabled} style={{ width: '105px', background: '#00C874', color: 'white', border: '#70D983 1px solid' }}>Completed</Button> : null}
                {status === 3 ? <Button disabled={disabled} style={{ width: '105px', background: '#E2445C', color: 'white', border: '#A969C6 1px solid' }}>Delayed</Button> : null}
                {status === 4 ? <Button disabled={disabled} style={{ width: '105px', background: '#ff8282', color: 'white', border: '#ff8282 1px solid' }}>Missed</Button> : null}
            </Popover>}


        {status > 100 ? <Badge.Ribbon disabled={true} color='#c72ed1b3' style={{ marginTop: -25 }} placement="start" text={
            <div>
                Approval
            </div>
        } > <Button disabled={true} style={{ width: '105px', background: '#ec61fd', color: 'black', opacity: 0.5 }}>Pending</Button></Badge.Ribbon> : null}
        {/* {status === 3 ? <Button disabled={disabled} style={{ width: '105px', background: '#E2445C', color: 'white', border: '#E2445C 1px solid' }} >Delayed</Button> : null} */}

    </div>
}

export default StatusCompo;