import { Button, Card, Col, ConfigProvider, Row, Typography } from 'antd'
import React, { useEffect, useState } from 'react'
import { EyeOutlined, PlusOutlined } from "@ant-design/icons";
import './userManagement.css'
import { getUserManagementCardData } from '../../../../../api';
import CountUp from 'react-countup';


function UserManagementCard({ user_level }) {

    const [cardData, setCardData] = useState(null)
    const [shouldAnimate, setShouldAnimate] = useState(false);

    useEffect(() => {
        setShouldAnimate(true);
        loadData()

    }, [])


    const loadData = async () => {

        let agency
        let userLevel = await window.localStorage.getItem('ntfc_level')
        if (userLevel == 6 || userLevel == 7) {
            agency = 0
        } else {
            agency = window.localStorage.getItem('ntfc_agency')
        }

        getUserManagementCardData(agency).then((out) => {

            setCardData(out.data.out)

        });

    }


    return (
        <Card style={{ cursor: "pointer" }} className='user_manage-card' onClick={() => {
            window.location.href = "/userrequestshod"
        }}>
            <div className='user-inside'>


                <div className='user-plan-div' onClick={(e) => {
                    e.stopPropagation()
                    window.location.href = "/allusers"

                }}>

                    <Typography className='user-plan-navigate-text'>
                        User
                        Management
                    </Typography>

                    {/* <Button shape="circle" className="custom-button" icon={<EyeOutlined />} /> */}
                    <EyeOutlined
                        onClick={(e) => {
                            e.stopPropagation()
                            window.location.href = "/allusers"

                        }}

                        className='action-eye' style={{ fontSize: 20, color: "white" }} />
                    {user_level == 6 || user_level == 7 &&
                        <PlusOutlined className='action-eye' style={{ fontSize: 20, color: "white" }} />
                    }

                </div>

                <div className='user-left'>
                    <span className='card-user-new' >
                        {shouldAnimate && <CountUp end={cardData && cardData.approval ? cardData.approval : 0} duration={2} />}


                    </span>
                    <div className='inner-user'>
                        <span className='card-user-content' >User requests

                        </span>
                        <span className='card-user-content-2' >have to be</span>
                        <span className='card-user-content3' >approved</span>
                    </div>


                </div>



            </div>


        </Card>
    )
}

export default UserManagementCard