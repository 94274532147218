import { Card, Col, Row, Typography } from 'antd'
import React, { useState } from 'react'
import { EyeOutlined, PlusSquareOutlined } from "@ant-design/icons";
import './actionCard.css'
import { useEffect } from 'react';
import { getActionCardData } from '../../../../../api';
import CountUp from 'react-countup';

function ActionCard({ user_level }) {


    useEffect(() => {
        setShouldAnimate(true);
        loadData()

    }, [])

    const [cardData, setCardData] = useState(null)
    const [shouldAnimate, setShouldAnimate] = useState(false);


    useEffect(() => {
        setShouldAnimate(true);
        loadData()

    }, [])

    const loadData = async () => {
        let agency
        let userLevel = await window.localStorage.getItem('ntfc_level')
        if (userLevel == 6 || userLevel == 7) {
            agency = 0
        } else {
            agency = window.localStorage.getItem('ntfc_agency')
        }

        getActionCardData(agency).then((out) => {

            setCardData(out.data.out)

        });

    }


    return (
        <Card style={{ cursor: "pointer" }} className='action-card' onClick={() => {
            window.location.href = "/requests"
        }}>
            <Row >
                <Col xs={24} lg={12}>
                    <div className='action-left-side-div'>

                        <div style={{ cursor: "pointer" }} className='action-plan-div' onClick={(e) => {
                            e.stopPropagation()
                            window.location.href = "/tasksactionplan"

                        }}>

                            <Typography className='action-plan-navigate-text'>
                                Action Plan
                                Management
                            </Typography>


                            <div style={{ display: "flex", justifyContent: "end", gap: 5 }}>
                                {/* {user_level == 5 &&

                                    {/* <PlusSquareOutlined style={{ fontSize: 25, color: "white" }} /> 
                                } */}

                                <EyeOutlined style={{ fontSize: 25, color: "white" }} />
                            </div>

                        </div>
                        <div className='action-count-div'>
                            <Typography className='action-plan-count'>
                                {shouldAnimate && <CountUp end={cardData && cardData.status_change ? cardData.status_change : 0} duration={2} />}

                            </Typography>
                            <Typography className='action-plan-b-title'>
                                <span style={{ marginTop: -24 }}>Status Change</span>
                                <span style={{ marginTop: -10 }}>Requests</span>


                            </Typography>

                        </div>





                    </div>

                </Col>
                <Col xs={24} lg={12} style={{ display: "flex", justifyContent: "center" }}>
                    <div className='action-right-side-div'>
                        <Row gutter={[8, 8]} justify={"space-around"}>
                            <Col span={12}>
                                <div style={{ border: `0.5px solid #AED8E6` }}
                                    className='action-tile-new'
                                >
                                    <span style={{ color: `#AED8E6` }} className='card-count-new' > {shouldAnimate && <CountUp end={cardData ? cardData.todo : 0} duration={2} />}</span>
                                    <span style={{ color: `#AED8E6` }} className='card-count-content' >{"To-Do"}</span>
                                </div>

                            </Col>
                            <Col span={12}>

                                <div style={{ border: `0.5px solid #0492C2` }}
                                    className='action-tile-new'
                                >
                                    <span style={{ color: `#0492C2` }} className='card-count-new' > {shouldAnimate && <CountUp end={cardData ? cardData.inprogress : 0} duration={2} />}</span>
                                    <span style={{ color: `#0492C2` }} className='card-count-content' >{"In Progress"}</span>
                                </div>
                            </Col>
                            <Col span={12}>

                                <div style={{ border: `0.5px solid #2f69b3` }}
                                    className='action-tile-new'
                                >
                                    <span style={{ color: `#2f69b3` }} className='card-count-new' > {shouldAnimate && <CountUp end={cardData ? cardData.delayed : 0} duration={2} />}</span>
                                    <span style={{ color: `#2f69b3` }} className='card-count-content' >{"Delayed"}</span>
                                </div>
                            </Col>
                            <Col span={12}>

                                <div style={{ border: `0.5px solid #4169E1` }}
                                    className='action-tile-new'
                                >
                                    <span style={{ color: `#4169E1` }} className='card-count-new' > {shouldAnimate && <CountUp end={cardData ? cardData.missed : 0} duration={2} />}</span>
                                    <span style={{ color: `#4169E1` }} className='card-count-content' >{"Missed"}</span>
                                </div>
                            </Col>
                            <Col span={24}>

                                <div style={{ border: `0.5px solid #1520A6` }}
                                    className='action-tile-new'
                                >
                                    <span style={{ color: `#1520A6` }} className='card-count-new' > {shouldAnimate && <CountUp end={cardData ? cardData.complete : 0} duration={2} />}</span>
                                    <span style={{ color: `#1520A6` }} className='card-count-content' >{"Completed"}</span>
                                </div>
                            </Col>

                        </Row>

                    </div>


                </Col>

            </Row>

        </Card>
    )
}

export default ActionCard