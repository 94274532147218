import { Badge, ConfigProvider, Popover, Segmented, Select, Space, Tag, Tooltip, Typography } from 'antd';
import { useEffect } from 'react';
import { useState, useMemo } from 'react';
import ArticlePopOverGraph from './ArticlePopoverGraph';
import { NewColorTheme } from '../../appConst';
import ArticleCustomPopOver from './ArticleCustomPopOver';


export const progressStatus = {
    CRITICAL: "critical",
    NORMAL: "normal",
    INTERMEDIATE: "intermediate"
}

const ProgressChart = ({ title, rightText, progress, articleNo, article, summaryView = false, presidentView = false }) => {
    const classSwitch = () => {
        if (progress > 60) {
            return progressStatus.NORMAL;
        }
        if (progress >= 30) {
            return progressStatus.INTERMEDIATE;
        }
        return progressStatus.CRITICAL;

    }
    const [arrowAtCenter, setArrowAtCenter] = useState(false);
    const [showArrow, setShowArrow] = useState(true);
    const [measures, setMeasures] = useState([])

    const mergedArrow = useMemo(() => {
        if (arrowAtCenter)
            return {
                pointAtCenter: true,
            };
        return showArrow;
    }, [showArrow, arrowAtCenter]);
    const [config, setConfig] = useState({});

    useEffect(() => {
        setConfig({
            // Override the background color of the Popover component
            componentSize: 'large',
            popover: {
                // Override the background color of the Popover component
                style: { backgroundColor: '#f0f2f5' }
            }
        });
        let measuresArr = []
        article?.measures.map((elment) => {
            let measure = {
                measure_no: article.article_no + "." + elment.measure_no,
                completion: elment.measure_completion
            }
            measuresArr.push(measure)

        })
        let sortedArr = measuresArr.sort((a, b) => a.measure_no - b.measure_no)
        setMeasures(sortedArr)

    }, []);

    return (
        <ConfigProvider
            {...config}
        >
            <Popover type="primary" placement="right"
                color="#d7e4f5"
                overlayClassName="custom-popover"

                title={
                    <div style={{ display: "flex", justifyContent: "start", alignItems: "center", padding: "10px", backgroundColor: NewColorTheme.primaryColor }}>
                        <Typography style={{ color: "white", fontWeight: 500, fontFamily: "Figtree", fontSize: 15 }}>{"Article " + articleNo.toString().padStart(2, '0')} </Typography>

                    </div>
                }

                content={
                    <div className='custom-scroll-div' style={{ display: "flex", flexDirection: "column", padding: "10px", width: 500, whiteSpace: "normal", color: "#004ca4", height: article?.measures.length > 5 ? "50vh" : "max-content", overflow: "auto" }}>
                        <Typography style={{ fontWeight: 800, color: NewColorTheme.primaryColor, fontFamily: "Figtree", fontSize: 12, whiteSpace: "normal", marginBottom: -10 }}>{article ? article.article : " "} </Typography>
                        <ArticleCustomPopOver article={article} />
                        {/* <ArticlePopOverGraph data={article} /> */}
                    </div>
                }
                arrow={mergedArrow}>
                <div style={{ width: "100%" }} onClick={() => {
                    if (window.localStorage.getItem('ntfc_level') != -1 && window.localStorage.getItem('ntfc_level') != 0 && window.localStorage.getItem('ntfc_level') != 2 && window.localStorage.getItem('ntfc_level') != 3) {

                        window.location.href = `/tasksactionplan?param1=${article.article_id}&param3=${summaryView ? "summary" : "newdashboard"}`
                    }
                }


                }>
                    <div style={{ marginBottom: 5, display: "flex" }}>
                        {/* <Tag color="blue"> {"Article " + articleNo.toString().padStart(2, '0')}</Tag> */}

                        <Typography className='progress-title-five' >{
                            !summaryView ?
                                "Article " + articleNo.toString().padStart(2, '0') + "." + " " + (title.length > 100 ? title.substring(0, 100).concat('..') : title) :
                                "Article " + articleNo.toString().padStart(2, '0') + "." + " " + (title.length > 100 ? title.substring(0, 100).concat('..') : title)

                        }</Typography>

                    </div>
                    <div className='progress-content-wrapper'>

                        <div className='progress-chrat-wrapper-five' >

                            <div

                                className={`progress ${classSwitch()}`}
                                style={{
                                    width: `${progress}%`,
                                    background: "linear-gradient(270deg, #00C4B0 0%, #013E8F 100%)"
                                }}
                            ></div>
                            <div className='right-text-content'>{rightText}</div>
                        </div>

                    </div>
                </div>
            </Popover>
        </ConfigProvider >


    );
}

export default ProgressChart;
