import { Modal, Row, Space, Tooltip, message, Typography, Button, Col, List, Avatar, Form, Input, Image } from 'antd';
import React, { useState, useEffect } from 'react';
import TitleBox from '../TitleBox/TitleBox';
import MemberCard from '../MemberCard/MemberCard';
import { FileTextOutlined, InboxOutlined } from '@ant-design/icons';

import { AddAgencyActionComment, UplaodActionAttachments, getActionParticipants, getAgencyActivities, getAllUsers, GetAgencyUploads } from '../../api';
import CommentBox from '../CommentBox/CommentBox';
import VirtualList from 'rc-virtual-list';
import Dragger from 'antd/es/upload/Dragger';

const { Text } = Typography;

const AddAttachmentModal = ({ action, actionType }) => {

    const [addAttachmentDialog, AddAttachmentDialog] = useState()
    const [fileTitle, setTitle] = useState(false);
    const [uplaodedFiles, setUploadedFiles] = useState([])

    useEffect(() => {
        LoadFiles()

    }, []);

    async function LoadFiles() {


        let out = await GetAgencyUploads(action.agency_id, action.action_id)

        if (out.data.status === 200) {
            setUploadedFiles(out.data.out)
        } else {
            message.error(out.data.message);
        }


    }


    useEffect(() => {
        message.config({
            maxCount: 1,
            duration: 2,
        });
    }, []);

    const [fileList, setFileList] = useState([]);

    const props = {
        name: 'file',
        multiple: false,
        action: 'https://www.mocky.io/v2/5cc8019d300000980a055e76',

        onChange(info) {

            console.log('FIle', info)
            const { status } = info.file;
            if (status !== 'uploading') {
                console.log(info.file, info.fileList);
            }
            if (status === 'done') {
                message.success(`${info.file.name} file uploaded successfully.`);
            } else if (status === 'error') {
                message.error(`${info.file.name} file upload failed.`);
            }
        },
        onDrop(e) {
            console.log('Dropped files', e.dataTransfer.files);
        },
    };

    async function handleFileUpload(file) {

        const formData = new FormData();
        formData.append('action_file', file);
        formData.append('title', fileTitle);
        formData.append('agency', action.agency_id);
        formData.append('action', action.action_id);


        let out = await UplaodActionAttachments(formData)

        if (out.data.status === 200) {
            message.success(out.data.message);
            setFileList([])
            setTitle("")
        } else {
            message.error(out.data.message);
        }


        console.log("File----------------", out)
    }

    return (
        <div>
            <div className="comment">
                <Tooltip title="Add Comment">
                    <Button onClick={() => {
                        AddAttachmentDialog(true)
                    }} shape="circle" icon={<FileTextOutlined />} />
                </Tooltip>
            </div>
            <Modal
                // title="Vertically centered modal dialog"
                centered
                open={addAttachmentDialog}
                onOk={() => AddAttachmentDialog(false)}
                onCancel={() => AddAttachmentDialog(false)}
                footer={false}

            >
                <TitleBox
                    title={"Attachments"}
                    body={
                        <div style={{ width: "100%" }}>
                            <Row gutter={16}>
                                <Col span={8}>
                                    <Row gutter={[16, 16]}>

                                        <Col span={24}>
                                            <Form>
                                                <Form.Item
                                                    // label="Comment"
                                                    name="fileTitle"
                                                    rules={[{ required: true, message: 'Please enter a filename' }]}
                                                >
                                                    <Input style={{ borderRadius: 15 }} value={fileTitle} onChange={(e) => setTitle(e.target.value)} placeholder='Enter a file title' />

                                                </Form.Item>

                                            </Form>
                                        </Col>
                                        <Col span={24} style={{ marginTop: -10 }}>
                                            <Dragger {...props}
                                                disabled={fileTitle ? false : true}
                                                beforeUpload={file => {
                                                    handleFileUpload(file);
                                                    return false; // to prevent automatic upload
                                                }}

                                            >
                                                <p className="ant-upload-drag-icon">
                                                    <InboxOutlined tyle={{ color: "#33363F" }} />
                                                </p>
                                                <p className="ant-upload-text">Click or drag a file to this area to upload</p>

                                            </Dragger>

                                        </Col>
                                    </Row>

                                </Col>
                                <Col span={16}>

                                    <List>
                                        <VirtualList
                                            data={uplaodedFiles}
                                            height={200}
                                            itemHeight={47}
                                            itemKey="id"
                                        // onScroll={onScroll}
                                        >
                                            {(item, key) => (
                                                <List.Item key={key}>
                                                    <List.Item.Meta
                                                        avatar={<Image src={item.url} />}
                                                        title={<p>{item.title}</p>}
                                                        description={"By " + item.first_name + " " + item.last_name}
                                                    />
                                                    <div>{new Date(item.created).toISOString().slice(8, 10) + "/" + new Date(item.created).toISOString().slice(5, 7)}</div>
                                                </List.Item>
                                            )}
                                        </VirtualList>
                                    </List>
                                    {/* <List
                                                                itemLayout="horizontal"
                                                                dataSource={uplaodedFiles}
                                                                renderItem={(item, index) => (
                                                                    <List.Item>
                                                                        <List.Item.Meta
                                                                            avatar={<Image src={item.url} />}
                                                                            title={<p>{item.title}</p>}
                                                                            description={"By " + item.first_name + " " + item.last_name}
                                                                        />
                                                                    </List.Item>
                                                                )}
                                                            /> */}

                                    {/* {uplaodedFiles.map((file, key) => {
                                                            return <DocumentLoader key={key} name="Agreement" member={"By Conry Henry"} size="14.48MB" />
                                                        })} */}



                                </Col>

                            </Row>


                        </div>
                    }

                />

            </Modal>
        </div>
    );
};

export default AddAttachmentModal;
