import { Badge, Card, Col, Radio, Row, Skeleton, Typography } from 'antd'
import React from 'react'
import "./actionCard.css"
import ActionDonut from './ActionDonut'
import { useEffect } from 'react'
import { useState } from 'react'
import { getActionCardData } from '../../../../../api'
import ActionCounts from './ActionCounts'
import MainCards from './UpcominDefinitiveDates'

import { NewColorTheme } from '../../../../../appConst'
import ArticleCompletionProgressCharts from './ArticleCompletionProgressCharts'


function ActionCardContent() {
    const [cardData, setCardData] = useState(null)
    const [value, setValue] = useState(4);
    useEffect(() => {
        loadData()
    }, [])

    const loadData = async () => {
        let agency
        let userLevel = await window.localStorage.getItem('ntfc_level')
        if (userLevel == 6 || userLevel == 7 || userLevel == 2 || userLevel == -1) {
            agency = 0
        } else {
            agency = window.localStorage.getItem('ntfc_agency')
        }
        getActionCardData(agency).then((out) => {
            setCardData(out.data.out)
        });
    }
    return (
        <>
            <Row gutter={[8, 8]} >
                <Col xs={24} lg={10} >
                    <Card className='dash5-card' style={{ cursor: "default", border: `2px solid ${NewColorTheme.primmaryLightColor}` }}>
                        <div className='new-action-card-new'>
                            <div
                                className='action-card-five-new'
                            >
                                <Typography className='ac-new-inner-card-title' style={{ color: NewColorTheme.primaryDarkColor }}>Overall Progress</Typography>
                            </div>
                            {cardData ?
                                <>
                                    <div>
                                        <ActionDonut data={cardData} />
                                    </div>
                                    <div>
                                        <ActionCounts cardData={cardData} />
                                    </div>
                                </> :
                                <div style={{ minWidth: "20em" }}>
                                    <Skeleton
                                        active
                                        avatar
                                        paragraph={{
                                            rows: 4,
                                        }}
                                    />
                                </div>
                            }
                        </div>
                    </Card>
                </Col>
                <Col xs={24} lg={14}>
                    <Card className='dash5-card' style={{ border: `2px solid ${NewColorTheme.primmaryLightColor}` }} >
                        <div className='new-action-card-new'  >
                            <div
                                className='action-card-five-new'
                            >
                                <Typography className='ac-new-inner-card-title' style={{ color: NewColorTheme.primaryDarkColor }}>Article Completion</Typography>
                            </div>


                            <ArticleCompletionProgressCharts height={"31vh"} val="dashboard" />

                        </div>
                    </Card>
                </Col>
                {/* <Col xs={24} lg={24} >
                    <Card className='dash5-card' style={{ border: `2px solid ${NewColorTheme.primmaryLightColor}` }}>
                        <div className='new-action-card-bottom-new'>
                            <div
                                className='action-card-five-new'
                            >
                                <Typography className='ac-new-inner-card-title' style={{ color: NewColorTheme.primaryDarkColor }}>Action Plan - Upcoming Definitive Dates</Typography>
                            </div>
                            <div>
                                <MainCards />
                            </div>

                        </div>
                    </Card>
                </Col> */}
            </Row>
        </>
    )
}

export default ActionCardContent