import {
  Button,
  Card,
  Col,
  Row,
  Table,
  Space,
  Typography,
  Segmented,
  Input,
  Select,
  Form,
  Checkbox,
  Alert,
  Upload,
  Spin
} from "antd";
import { useEffect, useState } from "react";
import Dragger from "antd/es/upload/Dragger";
import { InboxOutlined } from "@ant-design/icons";
import {
  DownloadFiles,
  addInquiryResponse,
  assignToInquiry,
  forwardInquiryTo,
  getAgencies,
  getPendingInquiries,
  getUserById,
  getUsersByAgency,
  sendInquiryReminder,
} from "../../../api";
import moment from "moment/moment";
import { NewColorTheme } from "../../../appConst";
import FullSearch from "../../../Components/FullSearch";
import { func } from "prop-types";


const PendingInquiries = ({ showMessage, setRefreshCounts }) => {

  const [loading, setlaoding] = useState(false)
  const [inquiries, setInquiries] = useState([]);
  const [inquiriesFull, setInquiriesFull] = useState([]);
  const [inquiry, setInquiry] = useState(null);
  const [response, setResponse] = useState("Reply");
  const [agencies, setAgencies] = useState([]);
  const [allAgencies, setAllAgencies] = useState([]);

  const [agency, setAgency] = useState(null);
  const [users, setUsers] = useState([]);
  const [user, setUser] = useState(null);
  const [reply, setReply] = useState("");
  const [fileList, setFileList] = useState([]);
  const [agencyError, setAgencyError] = useState(false);
  const [userError, setUserError] = useState(false);
  const [userMessage, setUserMessage] = useState("");
  const [replyError, setReplyError] = useState(false);
  const [replyErrorMessage, setReplyErrorMessage] = useState("");
  const [ntfLevel, setNtfcLevel] = useState(null);
  const [ntfcAgency, setNtfcAgency] = useState(null);
  const [agencyErrorMessage, setAgencyErrorMessage] = useState("");
  const [showReplySection, setShowReplySection] = useState(false);
  const [myAgency, setMyAgency] = useState("");
  const [form] = Form.useForm();
  const [form2] = Form.useForm();
  const [viewOnly, setviewOnly] = useState(false)
  const [isChair, setChair] = useState(false)



  useEffect(() => {
    if (window.localStorage.getItem('ntfc_level') == 6) {
      setChair(true)
    } else {
      setChair(false)
    }
    form.resetFields()
    form2.resetFields()
    setUser(null)
    setUserError(false)
    setAgencyError(false)
  }, [response])


  useEffect(() => {


    InitialloadData()
  }, []);

  async function InitialloadData() {

    let view = await window.localStorage.getItem('view_only') == 1 ? true : false
    setviewOnly(view)
    setlaoding(true)

    console.log("loading", loading)
    getPendingInquiries().then(async (res) => {
      if (res && res.data && res.data.out) {
        const dat = await Promise.all(
          res.data.out.map(async (item) => {
            return {
              ...item,
              agency_name: item?.agency,
              created_format: formatDate(item.created_at),
              assigned_format: item.assignments && item.assignments.length > 0 ? moment(item.assignments[0]["created"]).format("DD/MM/YYYY") : "-",
              assignee_format: item.assignments && item.assignments.length > 0 ? item.assignments[0]["username"] : item.answer_pending === 0 ? "NTFC" : "-",
              agency_format: item.assignments && item.assignments.length > 0 ? item.assignments[0]["agency_name"] : "-"
            };
          })
        );
        setInquiries(dat);
        setInquiriesFull(dat);
        setlaoding(false)
      }
    });
    getAgencies().then((res) => {
      if (res && res.data && res.data.out) {
        res.data.out.forEach((item, idx) => {
          if (item.value == window.localStorage.getItem("ntfc_agency")) {
            setMyAgency(item.label);
          }
        });
        setAgencies(
          res.data.out.filter(
            (element) =>
              element.value != window.localStorage.getItem("ntfc_agency")
          )
        );
        setAllAgencies(res.data.out);
      }
    });

  }

  async function getAgency(id) {
    console.log("id", id)
    const user = await getUserById(id)
    const userOrganization = user ? user.data.user.agency_name : ""

    return userOrganization
  }



  useEffect(() => {
    setUsers([]);
    form.setFieldValue("user", null);
    form2.setFieldValue("user", null);
    getUsersByAgency(agency, {}).then((res) => {
      if (res && res.data && res.data.out) {
        let recs = res.data.out;
        if (inquiry && inquiry.assignments.length > 0) {
          recs = recs.filter((obj) => obj.value !== inquiry.assignments[0].user_id)
        }
        setUsers(recs);
      }
    });
  }, [agency]);

  useEffect(() => {
    loadData();
  }, []);

  const loadData = async () => {
    setNtfcLevel(await window.localStorage.getItem("ntfc_level"));
    setNtfcAgency(await window.localStorage.getItem("ntfc_agency"));
  };

  const formatDate = (str) => {
    const dmy = str.split("T")[0].split("-");
    return `${dmy[2]}/${dmy[1]}/${dmy[0]}`;
  };

  const FetchFile = async (file) => {
    let out = await DownloadFiles(file.token);

    let blob = new Blob([out.data], { type: file.type });
    let url = window.URL.createObjectURL(blob);

    let link = document.createElement("a");
    link.href = url;

    link.download = file.url.split("-::-")[1];

    document.body.appendChild(link);
    link.click();
  };

  const columns = [
    {
      title: "Title of the Inquiry",
      dataIndex: "topic",
      key: "topic",
      width: "20%",
      render(text, record) {
        return {
          props: {
            style: {
              overflow: "hidden",
            },
          },
          children: <div>{text}</div>,
        };
      },
    },
    // {
    //     title: 'Description',
    //     dataIndex: 'description',
    //     key: 'description',
    //     width: '30%',
    //     render(text, record) {
    //         return {
    //             props: {
    //                 style: {
    //                     overflow: 'hidden'
    //                 },
    //             },
    //             children: <div>{text}</div>,
    //         };
    //     },
    // },
    {
      title: "Created By",
      dataIndex: "name",
      key: "name",
      render(text, record) {
        return {
          props: {
            style: {
              overflow: "hidden",
            },
          },
          children: <div>{text}</div>,
        };
      },
    },
    {
      title: "Created Date",
      dataIndex: "created_at",
      key: "created_at",
      width: 100,
      render: (_, { created_at }) => <>{formatDate(created_at)}</>,
    },
    {
      title: "Assignee",
      dataIndex: "assignments",
      key: "assignments",
      render: (_, { assignments }) => (
        <Space style={{ whiteSpace: "pre-line" }}>
          {assignments && assignments.length > 0
            ? assignments[0]["username"]
            : "-"}
        </Space>
      ),
    },
    {
      title: "Assigned Date",
      dataIndex: "assignments",
      key: "assignment_date",
      render: (_, { assignments }) => (
        <Space>
          {assignments && assignments.length > 0
            ? moment(assignments[0]["created"]).format("DD/MM/YYYY")
            : "-"}
        </Space>
      ),
    },
    {
      title: "Assigned Agency",
      dataIndex: "assignments",
      key: "assignment_agency",
      render: (_, { assignments }) => (
        <div style={{ whiteSpace: "pre-line" }}>
          {assignments && assignments.length > 0
            ? assignments[0].agency_name
              ? assignments[0].agency_name
              : myAgency
            : "-"}
        </div>
      ),
    },
    {
      title: "Actions",
      key: "answer_pending",
      dataIndex: "answer_pending",
      width: 100,
      render: (_, { answer_pending }) => (
        <Space>
          {answer_pending === 0 ? (
            <Button style={{ backgroundColor: "#396eea", color: "white" }}>
              {" "}
              {Number(ntfLevel) == 6 ? "New" : "Submitted"}
            </Button>
          ) : null}
          {answer_pending === 1 ? (
            <Button style={{ backgroundColor: "#FEBD5B", color: "white" }}>
              Pending
            </Button>
          ) : null}
          {answer_pending === 2 ? (
            <Button style={{ backgroundColor: "#50CD89", color: "white" }}>
              Answered
            </Button>
          ) : null}
        </Space>
      ),
    },
  ];

  const postReply = () => {
    if (reply == "" || reply == null) {
      setReplyError(true);
      setReplyErrorMessage("Reply is required");
      return;
    }

    if (replyError) {
      return;
    }

    const formData = new FormData();
    formData.append("inquiry_file", fileList);
    formData.append("inquiry", inquiry.id);
    formData.append("content", reply);
    addInquiryResponse(formData).then((out) => {
      showMessage(out.status == 200 ? 1 : 0, out.data.message);
      setResponse("Reply");
      setReply("");
      setInquiry(null);
      getPendingInquiries().then((res) => {
        if (res && res.data && res.data.out) {
          const dat = res.data.out.map((item) => {
            return {
              ...item,
              created_format: formatDate(item.created_at),
              assigned_format: item.assignments && item.assignments.length > 0 ? moment(item.assignments[0]["created"]).format("DD/MM/YYYY") : "-",
              assignee_format: item.assignments && item.assignments.length > 0 ? item.assignments[0]["username"] : item.answer_pending === 0 ? "NTFC" : "-",
              agency_format: item.assignments && item.assignments.length > 0 ? item.assignments[0]["agency_name"] : "-"
            }
          })
          setInquiries(dat);
          setInquiriesFull(dat);
        }
        setRefreshCounts(true);
      });
    });
  };

  const remind = () => {
    sendInquiryReminder({
      inquiry_id: inquiry.id,
    }).then((out) => {
      showMessage(out.status == 200 ? 1 : 0, out.data.message);
    });
  };

  const handleAgency = (value, label) => {
    setAgencyError(false);
    if (inquiry.created_agency == label.label) {
      setAgencyError(true);
      setAgencyErrorMessage("Agency cannot be the created agency");
    }

    setAgency(value);
  };

  const handleReply = (value) => {
    let pattern =
      /^(?=.*[a-zA-Z])[a-zA-Z0-9!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?\n ]+$/;

    setReply(value);

    if (value == null || value == "") {
      setReplyError(false);
      return;
    }

    if (pattern.test(value)) {
      setReplyError(false);
    } else {
      setReplyError(true);
      setReplyErrorMessage("Invalid Reply");
    }
  };

  const forwardInquiry = () => {
    // setAgencyError(false)
    // setUserError(false)

    if (!agency) {
      setAgencyErrorMessage("Agency is required");
      setAgencyError(true);
    }

    if (!user) {
      setUserMessage("User is required");
      setUserError(true);
    }

    if (!agency || !user) {
      return;
    }

    let sameUser = false;

    inquiry.assignments.forEach((element) => {
      if (user == element.user_id) {
        sameUser = true;
      }
    });

    if (sameUser) {
      setUserMessage("User is already assigned");
      setUserError(true);
      return;
    }

    forwardInquiryTo({
      id: inquiry.id,
      user_id: user,
      agency_id: agency
    }).then((out) => {
      showMessage(1, out.data.message);
      setResponse("Reply");
      setReply("");
      setInquiry(null);
      getPendingInquiries().then((res) => {
        if (res && res.data && res.data.out) {
          const dat = res.data.out.map((item) => {
            return {
              ...item,
              created_format: formatDate(item.created_at),
              assigned_format: item.assignments && item.assignments.length > 0 ? moment(item.assignments[0]["created"]).format("DD/MM/YYYY") : "-",
              assignee_format: item.assignments && item.assignments.length > 0 ? item.assignments[0]["username"] : item.answer_pending === 0 ? "NTFC" : "-",
              agency_format: item.assignments && item.assignments.length > 0 ? item.assignments[0]["agency_name"] : "-"
            }
          })
          setInquiries(dat);
          setInquiriesFull(dat);
        }
        setRefreshCounts(true);
      });
    });
  };

  async function handleFileUpload(file) {
    setFileList(file);
  }

  const props = {
    name: "file",
    multiple: false,
    onDrop(e) {
      console.log("Dropped files", e.dataTransfer.files);
    },
  };

  return (
    <div className="inqu-content-div">
      <Row gutter={[16, 16]} style={{ height: "100%" }}>
        <div className="pc-hide" style={{ width: "100%", padding: "2%" }}>
          <Alert
            message="Please choose a table row to view the data."
            type="info"
            showIcon
          />
        </div>
        <Col className="mob-hide" lg={6} xs={24} style={{ height: "100%" }}>
          <Card
            style={{
              height: "calc(70vh - 2%)",
              background: "#f5f9ff",
              maxHeight: "100%",
              overflow: "auto",
            }}
          >
            {inquiry ? (
              <div>
                <Typography.Title
                  style={{
                    color: "#005476",
                    marginTop: "10px",
                    margineLeft: "10px",
                  }}
                  level={3}
                >
                  Inquiry Details
                </Typography.Title>
                <Typography.Title
                  style={{
                    color: "#005476",
                    width: "100%",
                    display: "inline-block",
                    marginBottom: "-20px",
                    marginTop: "-10px",
                  }}
                  level={5}
                >
                  Created At
                </Typography.Title>
                <Typography.Text
                  style={{
                    color: "#005476",
                    width: "100%",
                    display: "inline-block",
                    paddingLeft: "10px",
                  }}
                >
                  {moment(inquiry.created_at).format("DD/MM/YYYY")}
                </Typography.Text>
                <Typography.Title
                  style={{
                    color: "#005476",
                    width: "100%",
                    display: "inline-block",
                    marginBottom: "-20px",
                  }}
                  level={5}
                >
                  Created By
                </Typography.Title>
                <Typography.Text
                  style={{
                    color: "#005476",
                    width: "100%",
                    display: "inline-block",
                    paddingLeft: "10px",
                  }}
                >
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <span>     {inquiry.name}</span>
                    <span> {inquiry.agency_name}</span>
                  </div>


                </Typography.Text>
                <Typography.Title
                  style={{
                    color: "#005476",
                    width: "100%",
                    display: "inline-block",
                    marginBottom: "-20px",
                  }}
                  level={5}
                >
                  Title
                </Typography.Title>
                <Typography.Text
                  style={{
                    color: "#005476",
                    width: "100%",
                    display: "inline-block",
                    paddingLeft: "10px",
                  }}
                >
                  {inquiry.topic}
                </Typography.Text>
                <Typography.Title
                  style={{
                    color: "#005476",
                    width: "100%",
                    display: "inline-block",
                    marginBottom: "-20px",
                  }}
                  level={5}
                >
                  Description
                </Typography.Title>
                <Typography.Text
                  style={{
                    color: "#005476",
                    width: "100%",
                    display: "inline-block",
                    paddingLeft: "10px",
                  }}
                >
                  {inquiry.description}
                </Typography.Text>
                {inquiry.files &&
                  inquiry.files.filter((element) => element.response_id == null)
                    .length > 0 ? (
                  <Typography.Title style={{ color: "#005476" }} level={5}>
                    Attachments
                  </Typography.Title>
                ) : null}
                {inquiry.files.map((file) => {
                  return file.response_id === null ? (
                    <div
                      className="inquiry-btn"
                      onClick={() => FetchFile(file)}
                    >
                      {file.type.includes("image") ? (
                        <img
                          src="/image.png"
                          alt="image"
                          style={{ height: 50, width: 50 }}
                        />
                      ) : (
                        <img
                          src="/file.png"
                          alt="file"
                          style={{ height: 50, width: 50 }}
                        />
                      )}
                      {file.url.toString().split("-::-")[1]}
                    </div>
                  ) : null;
                })}
                {inquiry.assignments.length > 0 ? (
                  <div>
                    <Typography.Title
                      style={{
                        color: "#005476",
                        width: "100%",
                        display: "inline-block",
                        marginBottom: "-20px",
                      }}
                      level={5}
                    >
                      Assignee
                    </Typography.Title>
                    <Typography.Text
                      style={{
                        color: "#005476",
                        width: "100%",
                        display: "inline-block",
                        paddingLeft: "10px",
                      }}
                    >
                      {inquiry.assignments[0].username}
                    </Typography.Text>
                    <Typography.Title
                      style={{
                        color: "#005476",
                        width: "100%",
                        display: "inline-block",
                        marginBottom: "-20px",
                      }}
                      level={5}
                    >
                      Assigne At
                    </Typography.Title>
                    <Typography.Text
                      style={{
                        color: "#005476",
                        width: "100%",
                        display: "inline-block",
                        paddingLeft: "10px",
                      }}
                    >
                      {moment(inquiry.assignments[0].created).format(
                        "DD/MM/YYYY"
                      )}
                    </Typography.Text>
                    <Typography.Title
                      style={{
                        color: "#005476",
                        width: "100%",
                        display: "inline-block",
                        marginBottom: "-20px",
                      }}
                      level={5}
                    >
                      Assigned Agency
                    </Typography.Title>
                    <Typography.Text
                      style={{
                        color: "#005476",
                        width: "100%",
                        display: "inline-block",
                        paddingLeft: "10px",
                      }}
                    >
                      {inquiry?.assignments[0].agency_id ?
                        allAgencies.find((el) => el.value == inquiry?.assignments[0].agency_id).label
                        :
                        inquiry.assignments[0].agency_name ? inquiry.assignments[0].agency_name : "-"}
                    </Typography.Text>
                  </div>
                ) : (
                  ""
                )}
                {/* {inquiry.files && inquiry.files.length > 0 ? (
                  <Typography.Title style={{ color: "#005476" }} level={5}>
                    Attachments
                  </Typography.Title>
                ) : null} */}
                {/* {inquiry.files.map((file) => {
                  return (
                    <div
                      className="inquiry-btn"
                      onClick={() => FetchFile(file)}
                    >
                      {file.type.includes("image") ? (
                        <img
                          src="/image.png"
                          alt="image"
                          style={{ height: 50, width: 50 }}
                        />
                      ) : (
                        <img
                          src="/file.png"
                          alt="file"
                          style={{ height: 50, width: 50 }}
                        />
                      )}
                      {file.url.toString().split("-::-")[1]}
                    </div>
                  );
                })} */}
                <br />
                {(!isChair && Number(inquiry.answer_pending) > 0 && Number(ntfLevel) > 5 && !viewOnly) ? (
                  <div>
                    <Checkbox
                      value={showReplySection}
                      onChange={(e) => setShowReplySection(e.target.checked)}
                    >
                      Change the assignee of the inquiry
                    </Checkbox>
                    <br />
                  </div>
                ) : (
                  ""
                )}
                <>{localStorage.getItem('ntfc_level') != 6 &&
                  <>
                    {showReplySection ? (
                      <div>
                        {Number(ntfLevel) > 5 ? (
                          <Segmented
                            options={["Reply", "Forward"]}
                            style={{ marginTop: 20 }}
                            value={response}
                            onChange={setResponse}
                          />
                        ) : (
                          // <Segmented options={['Reply']} style={{ marginTop: 20 }} value={response} onChange={setResponse} />
                          ""
                        )}
                      </div>
                    ) : (
                      ""
                    )}
                    {showReplySection ? (
                      <div>
                        {response === "Reply" ? (
                          <>
                            <p style={{ marginTop: "10px", marginBottom: "-2px" }}>
                              Reply <span style={{ color: "red" }}>*</span>
                            </p>
                            <Input.TextArea
                              rows={4}
                              onChange={(e) => handleReply(e.target.value)}
                              value={reply}
                              style={{
                                border: replyError ? "1px solid red" : "",
                                borderRadius: replyError ? "9.5px" : "",
                              }}
                            />
                            {replyError ? (
                              <p style={{ marginLeft: "12px", color: "#FF4D4F" }}>
                                {replyErrorMessage}
                              </p>
                            ) : (
                              ""
                            )}
                            <br />
                            <br />
                            <Typography style={{ fontWeight: 600 }}>Attachment</Typography>
                            <Typography.Paragraph style={{ margin: "15px 0px" }}>
                              Please ensure that only one attachment is uploaded at a time, and the file size should be limited to a maximum of 5MB.
                            </Typography.Paragraph>
                            <Dragger
                              {...props}
                              showUploadList={{ showProgress: true }}
                              beforeUpload={(file) => {
                                const MAX_SIZE = 5 * 1024 * 1024; // 5 MB
                                if (file.size > MAX_SIZE) {
                                  showMessage(null, "File size exceeds limit! The maximum file upload size is 5MB.",
                                  );

                                  setFileList([])
                                  return Upload.LIST_IGNORE
                                } else {
                                  handleFileUpload(file);
                                }
                                return false;
                              }}
                              maxCount={1}
                            >
                              <p className="ant-upload-drag-icon">
                                <InboxOutlined tyle={{ color: "#33363F" }} />
                              </p>
                              <p className="ant-upload-text">
                                Click or drag a file to this area to upload
                              </p>
                            </Dragger>
                          </>
                        ) : (
                          <Form form={form}>
                            <>
                              <Row gutter={16}>
                                <Col span={24}>
                                  <p>Agency</p>

                                  <Select
                                    size="large"
                                    placeholder="Agency"
                                    style={{
                                      width: "100%",
                                      border: agencyError ? "1px solid red" : "",
                                      borderRadius: agencyError ? "9.5px" : "",
                                    }}
                                    options={agencies}
                                    onChange={(value, label) => {
                                      handleAgency(value, label);
                                    }}
                                  />
                                  {agencyError ? (
                                    <p
                                      style={{ marginLeft: "12px", color: "#FF4D4F" }}
                                    >
                                      {agencyErrorMessage}
                                    </p>
                                  ) : (
                                    ""
                                  )}
                                </Col>
                                <Col span={24}>
                                  <p>User</p>
                                  <Form.Item name="user">
                                    <Select
                                      size="large"
                                      placeholder="User"
                                      style={{
                                        width: "100%",
                                        border: userError ? "1px solid red" : "",
                                        borderRadius: userError ? "9.5px" : "",
                                      }}
                                      options={users}
                                      onChange={(value) => {
                                        setUserError(false)
                                        setUser(value);
                                      }}
                                    />
                                  </Form.Item>
                                  {userError ? (
                                    <p
                                      style={{ marginLeft: "12px", color: "#FF4D4F" }}
                                    >
                                      {userMessage}
                                    </p>
                                  ) : (
                                    ""
                                  )}
                                </Col>
                              </Row>
                            </>
                          </Form>
                        )}
                      </div>
                    ) : (
                      ""
                    )}
                  </>
                }

                </>
              </div>
            ) : <div style={{ color: NewColorTheme.primaryColor }}>Please select a row for view inquiry details</div>}

            {localStorage.getItem('ntfc_level') != 6 &&
              <>
                {showReplySection ? (
                  <Space style={{ marginTop: 5, width: "100%" }} direction='vertical'>
                    <Space direction='horizontal' style={{ width: "100%" }}>
                      {inquiry && response === "Reply" ? (
                        <Button
                          style={{ flex: 1 }}
                          type="primary"
                          onClick={() => postReply()}
                          disabled={window.localStorage.getItem('view_only') == 1}
                        >
                          Add Answers
                        </Button>
                      ) : !inquiry ? null : (
                        <Button
                          style={{ flex: 1 }}
                          disabled={window.localStorage.getItem('view_only') == 1 || agencyError}
                          type="primary"
                          onClick={() => forwardInquiry()}
                        >
                          Forward
                        </Button>
                      )}
                      {inquiry &&
                        Number(window.localStorage.getItem("ntfc_level")) > 5 &&
                        Number(inquiry.answer_pending) > 0 ? (

                        <Button
                          style={{ flex: 1, width: "100%" }}
                          onClick={() => remind()}
                          type="primary"
                          disabled={window.localStorage.getItem('view_only') == 1}
                        >
                          Send Reminder
                        </Button>
                      ) : null}
                    </Space>


                    {inquiry ? (

                      <Button
                        style={{ width: "100%" }}
                        className="w-full"
                        onClick={() => { setInquiry(null); setUser(null); form.setFieldValue("user", null); form2.setFieldValue("user", null); form.resetFields(); form2.resetFields() }}
                      >
                        Close
                      </Button>
                    ) : null}
                  </Space>
                ) : (
                  <Space>
                    {inquiry ? (
                      <Button
                        onClick={() => { setInquiry(null); setUser(null); form.setFieldValue("user", null); form2.setFieldValue("user", null); form2.resetFields(); form2.resetFields() }}
                        style={{
                          color: "#005476",
                          border: "1px solid #005476",
                          marginTop: 20,
                        }}
                      >
                        Close
                      </Button>
                    ) : null}
                  </Space>
                )}
              </>
            }

          </Card>
        </Col>
        <Col lg={18} xs={24} style={{ overflow: "auto", height: "100%" }}>
          <FullSearch full={inquiriesFull} setFiltered={setInquiries} params={["topic", "name", "created_format", "assigned_format", "assignee_format", "agency_format"]} />
          <Card
            className="full-card"
            style={{
              height: "calc(70vh - 10%)",
              overflow: "auto",
              maxHeight: "100%",
            }}
          >
            {loading ?
              <div style={{ display: 'flex', height: "100%", width: "100%", justifyContent: "center", alignContent: "center" }}>
                <Spin size="lg" />
              </div>

              :
              <Table

                columns={columns}
                dataSource={inquiries}
                sticky={true}
                className="custom-table-header"
                pagination={{ pageSize: 5, total: inquiries.length }}
                rowClassName={(inq) =>
                  inquiry && inq.id === inquiry.id
                    ? "inquiry-rows-selected"
                    : "inquiry-rows"
                }
                onRow={(record, rowIndex) => {
                  return {
                    onClick: (event) => {
                      setFileList([])
                      setAgencyError(false);
                      setReplyError(false);
                      setInquiry(record);
                      setResponse("Reply");
                      setReply("");
                      setShowReplySection(
                        Number(record.answer_pending) == 0 || Number(ntfLevel) < 6
                          ? true
                          : false
                      );
                    },
                  };
                }}
              />


            }


          </Card>

        </Col>

        <Col className="pc-hide" lg={6} xs={24} style={{ height: "100%" }}>
          <Card
            style={{
              height: "calc(70vh - 2%)",
              background: "#f5f9ff",
              maxHeight: "100%",
              overflow: "auto",
            }}
          >
            {inquiry ? (
              <div>
                <Typography.Title
                  style={{
                    color: "#005476",
                    marginTop: "10px",
                    margineLeft: "10px",
                  }}
                  level={3}
                >
                  Inquiry Details
                </Typography.Title>
                <Typography.Title
                  style={{
                    color: "#005476",
                    width: "100%",
                    display: "inline-block",
                    marginBottom: "-20px",
                    marginTop: "-10px",
                  }}
                  level={5}
                >
                  Created At
                </Typography.Title>
                <Typography.Text
                  style={{
                    color: "#005476",
                    width: "100%",
                    display: "inline-block",
                    paddingLeft: "10px",
                  }}
                >
                  {moment(inquiry.created_at).format("DD/MM/YYYY")}
                </Typography.Text>
                <Typography.Title
                  style={{
                    color: "#005476",
                    width: "100%",
                    display: "inline-block",
                    marginBottom: "-20px",
                  }}
                  level={5}
                >
                  Created By
                </Typography.Title>
                <Typography.Text
                  style={{
                    color: "#005476",
                    width: "100%",
                    display: "inline-block",
                    paddingLeft: "10px",
                  }}
                >
                  {inquiry.name}
                </Typography.Text>
                <Typography.Title
                  style={{
                    color: "#005476",
                    width: "100%",
                    display: "inline-block",
                    marginBottom: "-20px",
                  }}
                  level={5}
                >
                  Title
                </Typography.Title>
                <Typography.Text
                  style={{
                    color: "#005476",
                    width: "100%",
                    display: "inline-block",
                    paddingLeft: "10px",
                  }}
                >
                  {inquiry.topic}
                </Typography.Text>
                <Typography.Title
                  style={{
                    color: "#005476",
                    width: "100%",
                    display: "inline-block",
                    marginBottom: "-20px",
                  }}
                  level={5}
                >
                  Description
                </Typography.Title>
                <Typography.Text
                  style={{
                    color: "#005476",
                    width: "100%",
                    display: "inline-block",
                    paddingLeft: "10px",
                  }}
                >
                  {inquiry.description}
                </Typography.Text>
                {inquiry.assignments.length > 0 ? (
                  <div>
                    <Typography.Title
                      style={{
                        color: "#005476",
                        width: "100%",
                        display: "inline-block",
                        marginBottom: "-20px",
                      }}
                      level={5}
                    >
                      Assignee
                    </Typography.Title>
                    <Typography.Text
                      style={{
                        color: "#005476",
                        width: "100%",
                        display: "inline-block",
                        paddingLeft: "10px",
                      }}
                    >
                      {inquiry.assignments[0].username}
                    </Typography.Text>
                    <Typography.Title
                      style={{
                        color: "#005476",
                        width: "100%",
                        display: "inline-block",
                        marginBottom: "-20px",
                      }}
                      level={5}
                    >
                      Assigne At
                    </Typography.Title>
                    <Typography.Text
                      style={{
                        color: "#005476",
                        width: "100%",
                        display: "inline-block",
                        paddingLeft: "10px",
                      }}
                    >
                      {moment(inquiry.assignments[0].created).format(
                        "DD/MM/YYYY"
                      )}
                    </Typography.Text>
                    {inquiry?.assignments.length > 0 &&
                      <>
                        <Typography.Title
                          style={{
                            color: "#005476",
                            width: "100%",
                            display: "inline-block",
                            marginBottom: "-20px",
                          }}
                          level={5}
                        >
                          Assigned Agency
                        </Typography.Title>
                        <Typography.Text
                          style={{
                            color: "#005476",
                            width: "100%",
                            display: "inline-block",
                            paddingLeft: "10px",
                          }}
                        >
                          {console.log("hello", allAgencies.find((el) => el.value == inquiry?.assignments[0].agency_id))}
                          {inquiry?.assignments[0].agency_id ?
                            allAgencies.find((el) => el.value == inquiry?.assignments[0].agency_id).label
                            :
                            inquiry.assignments[0].agency_name ? inquiry.assignments[0].agency_name : "-"}
                        </Typography.Text>
                      </>
                    }

                  </div>
                ) : (
                  ""
                )}
                {inquiry.files && inquiry.files.length > 0 ? (
                  <Typography.Title style={{ color: "#005476" }} level={5}>
                    Attachments
                  </Typography.Title>
                ) : null}
                {inquiry.files.map((file) => {
                  return (
                    <div
                      className="inquiry-btn"
                      onClick={() => FetchFile(file)}
                    >
                      {file.type.includes("image") ? (
                        <img
                          src="/image.png"
                          alt="image"
                          style={{ height: 50, width: 50 }}
                        />
                      ) : (
                        <img
                          src="/file.png"
                          alt="file"
                          style={{ height: 50, width: 50 }}
                        />
                      )}
                      {file.url.toString().split("-::-")[1]}
                    </div>
                  );
                })}
                <br />
                {!isChair && Number(inquiry.answer_pending) > 0 && Number(ntfLevel) > 5 && !viewOnly ? (
                  <div>
                    <Checkbox
                      value={showReplySection}
                      onChange={(e) => setShowReplySection(e.target.checked)}
                    >
                      Change the assignee of the inquiry
                    </Checkbox>
                    <br />
                  </div>
                ) : (
                  ""
                )}

                {showReplySection ? (
                  <div>
                    {Number(ntfLevel) > 5 ? (
                      <Segmented
                        options={["Reply", "Forward"]}
                        style={{ marginTop: 20 }}
                        value={response}
                        onChange={setResponse}
                      />
                    ) : (
                      // <Segmented options={['Reply']} style={{ marginTop: 20 }} value={response} onChange={setResponse} />
                      ""
                    )}
                  </div>
                ) : (
                  ""
                )}
                {showReplySection ? (
                  <div>
                    {response === "Reply" ? (
                      <>
                        <p style={{ marginTop: "10px", marginBottom: "-2px" }}>
                          Reply <span style={{ color: "red" }}>*</span>
                        </p>
                        <Input.TextArea
                          rows={4}
                          onChange={(e) => handleReply(e.target.value)}
                          value={reply}
                          style={{
                            border: replyError ? "1px solid red" : "",
                            borderRadius: replyError ? "9.5px" : "",
                          }}
                        />
                        {replyError ? (
                          <p style={{ marginLeft: "12px", color: "#FF4D4F" }}>
                            {replyErrorMessage}
                          </p>
                        ) : (
                          ""
                        )}
                        <br />
                        <br />
                        <Typography style={{ fontWeight: 600 }}>Attachment</Typography>
                        <Typography.Paragraph style={{ margin: "15px 0px" }}>
                          Please ensure that only one attachment is uploaded at a time, and the file size should be limited to a maximum of 5MB.
                        </Typography.Paragraph>

                        <Dragger
                          {...props}
                          showUploadList={{ showProgress: true }}
                          beforeUpload={file => {
                            const MAX_SIZE = 5 * 1024 * 1024; // 5 MB
                            if (file.size > MAX_SIZE) {
                              showMessage(false, 'File size exceeds limit! The maximum file upload size is 5MB.',
                              );

                              return false;
                            } else {
                              handleFileUpload(file);

                            }
                            return false;// to prevent automatic upload
                          }}


                          maxCount={1}
                        >
                          <p className="ant-upload-drag-icon">
                            <InboxOutlined tyle={{ color: "#33363F" }} />
                          </p>
                          <p className="ant-upload-text">
                            Click or drag a file to this area to upload
                          </p>
                        </Dragger>
                      </>
                    ) : (
                      <Form form={form2}>
                        <>
                          <Row gutter={16}>
                            <Col span={24}>
                              <p>Agency</p>

                              <Select
                                size="large"
                                placeholder="Agency"
                                style={{
                                  width: "100%",
                                  border: agencyError ? "1px solid red" : "",
                                  borderRadius: agencyError ? "9.5px" : "",
                                }}
                                options={agencies}
                                onChange={(value, label) => {
                                  handleAgency(value, label);
                                }}
                              />
                              {agencyError ? (
                                <p
                                  style={{ marginLeft: "12px", color: "#FF4D4F" }}
                                >
                                  {agencyErrorMessage}
                                </p>
                              ) : (
                                ""
                              )}
                            </Col>
                            <Col span={24}>
                              <p>User</p>
                              <Form.Item name="user">
                                <Select
                                  size="large"
                                  placeholder="User"
                                  style={{
                                    width: "100%",
                                    border: userError ? "1px solid red" : "",
                                    borderRadius: userError ? "9.5px" : "",
                                  }}
                                  options={users}
                                  onChange={(value) => {
                                    setUser(value);
                                  }}
                                />
                              </Form.Item>
                              {userError ? (
                                <p
                                  style={{ marginLeft: "12px", color: "#FF4D4F" }}
                                >
                                  {userMessage}
                                </p>
                              ) : (
                                ""
                              )}
                            </Col>
                          </Row>
                        </>
                      </Form>
                    )}
                  </div>
                ) : (
                  ""
                )}
              </div>
            ) : null}
            {showReplySection ? (
              <Space>
                {inquiry && response === "Reply" ? (
                  <Button
                    style={{
                      color: "#005476",
                      border: "1px solid #005476",
                      marginTop: 20,
                    }}
                    onClick={() => postReply()}
                    disabled={window.localStorage.getItem('view_only') == 1}
                  >
                    Add Answers
                  </Button>
                ) : !inquiry ? null : (
                  <Button
                    disabled={window.localStorage.getItem('view_only') == 1 || agencyError}
                    style={{
                      color: "#005476",
                      border: "1px solid #005476",
                      marginTop: 20,
                    }}
                    onClick={() => forwardInquiry()}
                  >
                    Forward
                  </Button>
                )}
                {inquiry &&
                  Number(window.localStorage.getItem("ntfc_level")) > 5 &&
                  Number(inquiry.answer_pending) > 0 ? (
                  <Button
                    onClick={() => remind()}
                    style={{
                      color: "#005476",
                      border: "1px solid #005476",
                      marginTop: 20,
                    }}
                    disabled={window.localStorage.getItem('view_only') == 1}
                  >
                    Send Reminder
                  </Button>
                ) : null}
                {inquiry ? (
                  <Button
                    onClick={() => {
                      setInquiry(null);
                      setUser(null);
                      form.setFieldValue("user", null);
                      form2.setFieldValue("user", null);
                      setUserError(false)
                      setAgencyError(false)

                    }}
                    style={{
                      color: "#005476",
                      border: "1px solid #005476",
                      marginTop: 20,
                    }}
                  >
                    Close
                  </Button>
                ) : null}
              </Space>
            ) : (
              <Space>
                {inquiry ? (
                  <Button
                    onClick={() => {
                      setInquiry(null);
                      setUser(null);
                      form.setFieldValue("user", null);
                      form2.setFieldValue("user", null);
                      setUserError(false)
                      setAgencyError(false)

                    }}
                    style={{
                      color: "#005476",
                      border: "1px solid #005476",
                      marginTop: 20,
                    }}
                  >
                    Close
                  </Button>
                ) : null}
              </Space>
            )}
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default PendingInquiries;
