import {
  Button,
  Card,
  Col,
  Row,
  Space,
  Typography,
  Input,
  Divider,
  Tag,
  Timeline,
  Modal,
} from "antd";
import { useEffect, useState } from "react";
import { getPublishedInquiries, unPublishInquiry } from "../../../api";
import { DownloadFiles } from "../../../api";
import { NewColorTheme } from "../../../appConst";

const FaqPanel = () => {
  const [inquiries, setInquiries] = useState([]);
  const [inquiriesFiltered, setInquiriesFiltered] = useState([]);
  const [inquiry, setInquiry] = useState(null);
  const [showUnpublish, setShowUnpublish] = useState(false);
  const [isChair, setChair] = useState(false)

  const refreshInquiries = () => {
    getPublishedInquiries().then((res) => {
      if (res && res.data && res.data.out) {
        setInquiries(res.data.out);
        setInquiriesFiltered(res.data.out);
        selectTopic(res.data.out[0]);
      }
    });
  }

  useEffect(() => {
    if (window.localStorage.getItem('ntfc_level') == 6) {
      setChair(true)
    } else {
      setChair(false)
    }
    refreshInquiries();
  }, []);

  const formatDate = (str) => {
    const dmy = str.split("T")[0].split("-");
    return `${dmy[2]}/${dmy[1]}/${dmy[0]}`;
  };

  const filterInquiries = (str) => {
    const filtered = inquiries.filter((inq) =>
      inq.topic.toString().toLowerCase().includes(str.toLowerCase())
    );
    setInquiriesFiltered([...filtered]);
  };

  const selectTopic = (item) => {
    if (inquiry && inquiry.id === item.id) {
      setInquiry(null);
    } else {
      setInquiry(item);
    }
  };

  const FetchFile = async (file) => {
    let out = await DownloadFiles(file.token);

    let blob = new Blob([out.data], { type: file.type });
    let url = window.URL.createObjectURL(blob);

    let link = document.createElement("a");
    link.href = url;

    link.download = file.url.split("-::-")[1];

    document.body.appendChild(link);
    link.click();
  };

  return (
    <div className="inqu-content-div">
      <Row gutter={[16, 16]} style={{ height: "100%" }}>
        <Col lg={8} xs={24} style={{ height: "100%" }}>
          <Card
            style={{
              height: "calc(85vh - 2%)",
              maxHeight: "100%",
              overflow: "auto",
              background: "#f5f9ff",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginBottom: 20,
              }}
            >
              <div
                style={{
                  padding: "5px 10px",
                  color: "white",
                  background: NewColorTheme.primaryColor,
                  marginRight: 5,
                  width: "30%",
                  textAlign: "center",
                  borderRadius: 5,
                }}
              >
                Inquiry
              </div>
              <Input.Search
                allowClear
                placeholder="Search"
                onSearch={filterInquiries}
                style={{ width: "100%" }}
              />
            </div>
            {inquiriesFiltered?.map((inq, idx) => (
              <div>
                <div
                  className={
                    inquiry && inquiry.id === inq.id
                      ? "faq-search-item-selected"
                      : "faq-search-item"
                  }
                  onClick={() => selectTopic(inq)}
                  key={idx}
                >
                  <Typography.Title
                    style={{ color: NewColorTheme.primaryColor, margin: 0 }}
                    level={5}
                  >
                    {inq.topic}
                  </Typography.Title>
                </div>
                <Divider style={{ margin: 5 }} />
              </div>
            ))}
          </Card>
        </Col>
        <Col lg={16} xs={24} style={{ overflow: "auto", height: "100%" }}>
          <Card
            className="full-card"
            style={{
              height: "calc(85vh - 2%)",
              overflow: "auto",
              maxHeight: "100%",
              padding: 10,
            }}
          >
            {inquiry ? (
              <Space direction="vertical" style={{ width: "100%" }}>
                <div className="inq-details-card">
                  <Typography.Title
                    level={4}
                    style={{
                      color: NewColorTheme.primaryColor,
                      marginBottom: 5,
                      fontWeight: 700,
                    }}
                  >
                    {inquiry.topic}
                  </Typography.Title>
                  <Typography.Text
                    style={{ color: NewColorTheme.primaryColor, fontSize: 15, marginBottom: 10 }}
                  >
                    {"Created on " +
                      " " +
                      formatDate(inquiry.created_at) +
                      " " +
                      "by " +
                      " "}{" "}
                    <i>{inquiry.name}</i>
                  </Typography.Text>
                  <Typography.Paragraph style={{ color: NewColorTheme.primaryColor }}>
                    {inquiry.description}
                  </Typography.Paragraph>
                  {inquiry.files?.map((file) => {
                    return file.response_id === null ? (
                      <div
                        style={{
                          cursor: "pointer",
                        }}
                        onClick={() => FetchFile(file)}
                      >
                        {file.type.includes("image") ? (
                          <img
                            src="/image.png"
                            alt="image"
                            style={{ height: 50, width: 50 }}
                          />
                        ) : (
                          <img
                            src="/file.png"
                            alt="file"
                            style={{ height: 50, width: 50 }}
                          />
                        )}
                        {file.url.toString().split("-::-")[1]}
                      </div>
                    ) : null;
                  })}
                </div>
                <Timeline
                  style={{ marginTop: 15 }}
                  items={inquiry?.responses?.map((res) => {
                    return {
                      children: (
                        <div
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          <Typography.Text style={{ color: NewColorTheme.primaryColor }}>
                            {res.content}
                          </Typography.Text>
                          <Typography.Text
                            style={{
                              color: NewColorTheme.primaryColor,
                              fontSize: 13,
                              marginBottom: 10,
                            }}
                          >
                            {"Responded on " +
                              " " +
                              formatDate(res.created) +
                              " " +
                              "by " +
                              " "}{" "}
                            <i>
                              {res.agency_name ? res.agency_name : res.username}
                            </i>
                          </Typography.Text>

                          {inquiry &&
                            inquiry?.files?.map((file) => {
                              return file.response_id === res.id ? (
                                <div
                                  className="inquiry-btn"
                                  onClick={() => FetchFile(file)}
                                >
                                  {file.type.includes("image") ? (
                                    <img
                                      src="/image.png"
                                      alt="image"
                                      style={{ height: 50, width: 50 }}
                                    />
                                  ) : (
                                    <img
                                      src="/file.png"
                                      alt="file"
                                      style={{ height: 50, width: 50 }}
                                    />
                                  )}
                                  {file.url.toString().split("-::-")[1]}
                                </div>
                              ) : null;
                            })}
                        </div>
                      ),
                    };
                  })}
                />
                {(!isChair && Number(window.localStorage.getItem('ntfc_level')) > 5) ? (
                  <Button type="primary" onClick={() => setShowUnpublish(true)}
                    disabled={window.localStorage.getItem('view_only') == 1}>
                    Unpublish Inquiry
                  </Button>
                ) : null}
              </Space>
            ) : null}

            {/* {inquiry ? <div style={{ padding: 20 }}>
                        <div style={{ display: 'flex', alignItems: 'center', marginBottom: 20 }}>
                            <div style={{ padding: '5px 10px', color: 'white', background: NewColorTheme.primaryColor, marginRight: 5, width: '25%' }} >TITLE</div>
                            <hr style={{ background: NewColorTheme.primaryColor, height: 1, width: '75%', border: 'none' }} />
                        </div>
                        <Typography.Title style={{ color: NewColorTheme.primaryColor, marginBottom: 15 }} level={5}>{inquiry.topic}</Typography.Title>
                        <div style={{ display: 'flex', alignItems: 'center', marginBottom: 10 }}>
                            <div style={{ padding: '5px 10px', color: 'white', background: NewColorTheme.primaryColor, marginRight: 5, width: '25%' }} >DESCRIPTION</div>
                            <div style={{ padding: '5px 10px', color: NewColorTheme.primaryColor, marginRight: 5, width: '25%', border: '1px solid #282d93' }} >
                                Date: {formatDate(inquiry.created_at)}
                            </div>
                            <div style={{ padding: '5px 10px', color: NewColorTheme.primaryColor, marginRight: 5, width: '35%', border: '1px solid #282d93', whiteSpace: 'nowrap', overflow: 'hidden' }} >
                                Created By: {inquiry.name}
                            </div>
                            <hr style={{ background: NewColorTheme.primaryColor, height: 1, width: '15%', border: 'none' }} />
                        </div>
                        <Typography.Text style={{ color: NewColorTheme.primaryColor }}>{inquiry.description}</Typography.Text>
                        <div style={{ display: 'flex', alignItems: 'center', marginBottom: 10, marginTop: 10 }}>
                            <div style={{ padding: '5px 10px', color: 'white', background: NewColorTheme.primaryColor, marginRight: 5, width: '25%' }} >ATTACHMENTS</div>
                            <hr style={{ background: NewColorTheme.primaryColor, height: 1, width: '75%', border: 'none' }} />
                        </div>
                        {inquiry.files.map((file) => {
                            return file.response_id === null ? <div onClick={() => FetchFile(file)}>
                                {file.type.includes("image") ?
                                    <img src="/image.png" alt="image" style={{ height: 50, width: 50 }} />
                                    : <img src="/file.png" alt="file" style={{ height: 50, width: 50 }} />}
                                {(file.url.toString().split("-::-"))[1]}
                            </div> : null
                        })}
                        <hr style={{ border: "2px solid", marginBottom: "20px" }} />
                        {inquiry.responses.map((res) => <>
                            <div style={{ display: 'flex', alignItems: 'center', marginBottom: 10 }}>
                                <div style={{ padding: '5px 10px', color: 'white', background: NewColorTheme.primaryColor, marginRight: 5, width: '25%' }} >ANSWER</div>
                                <div style={{ padding: '5px 10px', color: NewColorTheme.primaryColor, marginRight: 5, width: '25%', border: '1px solid #282d93' }} >
                                    Date: {formatDate(res.created)}
                                </div>
                                <div style={{ padding: '5px 10px', color: NewColorTheme.primaryColor, marginRight: 5, width: '35%', border: '1px solid #282d93', whiteSpace: 'nowrap', overflow: 'hidden' }} >
                                    Answered By: {res.agency_name ? res.agency_name : res.username}
                                </div>
                                <hr style={{ background: NewColorTheme.primaryColor, height: 1, width: '15%', border: 'none' }} />
                            </div>
                            <Typography.Text style={{ color: NewColorTheme.primaryColor }}>{res.content}</Typography.Text>
                            <div style={{ display: 'flex', alignItems: 'center', marginBottom: 10, marginTop: 10 }}>
                                <div style={{ padding: '5px 10px', color: 'white', background: NewColorTheme.primaryColor, marginRight: 5, width: '25%' }} >ATTACHMENTS</div>
                                <hr style={{ background: NewColorTheme.primaryColor, height: 1, width: '75%', border: 'none' }} />
                            </div>
                            {inquiry && inquiry.files.map((file) => {
                                return file.response_id === res.id ? <div className="inquiry-btn" onClick={() => FetchFile(file)}>
                                    {file.type.includes("image") ?
                                        <img src="/image.png" alt="image" style={{ height: 50, width: 50 }} />
                                        : <img src="/file.png" alt="file" style={{ height: 50, width: 50 }} />}
                                    {(file.url.toString().split("-::-"))[1]}
                                </div> : null
                            })}
                        </>)}
                    </div> : null} */}
          </Card>
        </Col>
      </Row>
      <Modal
        centered
        title="Confirm"
        open={showUnpublish}
        onOk={() => {
          unPublishInquiry({
            id: inquiry.id,
          }).then((out) => {
            setInquiry(null);
            refreshInquiries();
          });
          setShowUnpublish(false);
        }}
        onCancel={() => { setShowUnpublish(false); }}
        okButtonProps={{ style: { backgroundColor: "#005476", color: "white" } }}
      >
        <p>{`Are you sure you want to unpublish the inquiry?`}</p>
      </Modal>
    </div>
  );
};

export default FaqPanel;
