import { Button, Col, ConfigProvider, Form, Row, Select, Typography } from 'antd';
import { createChat, getAgencies, getUsersByAgencies } from '../../api';
import { useEffect, useState } from 'react';
import { NewColorTheme } from '../../appConst';
const { Text } = Typography;

const PersonalChat = ({ closeModal }) => {
    const [participants, setParticipants] = useState([]);
    const [selectedAgencies, setSelectedAgencies] = useState();
    const [agencies, setAgencies] = useState([]);
    const [currentUsers, setUsers] = useState([]);
    const [form] = Form.useForm();
    const [agenciesOptions, setAgenciesOptions] = useState([]);

    const getParticipants = async (agencies) => {
        let body = {
            agencies: agencies
        }
        await getUsersByAgencies(body).then(out => {
            setUsers(out.data.out.map((obj, index) => ({ ...obj, label: obj.designation + " - " + obj.label })));
        });
    }

    const createNewChat = () => {
        console.log(participants);
        createChat({
            group: 0,
            participants: participants ? [participants.value] : []
        }).then((out) => {
            if (out) {
                closeModal();
                console.log("NEW CHAT", out);
                form.resetFields()
                setUsers([])
                setParticipants([])
                setSelectedAgencies(null)
            }
        }).catch((err) => {
            console.log("CCHAT ERROR", err);
        })
    }
    useEffect(() => {
        form.resetFields()
        setSelectedAgencies(null)
        setAgencies(agencies.map((obj, index) => (obj.disabled = false)));
        // setAgencies([])
        setParticipants([])
    }, [closeModal])


    useEffect(() => {
        form.resetFields();
        setSelectedAgencies(null);
        setParticipants([]);
        getAgencies().then((out) => {
            if (out) {
                const options = out.data.out.map((agency) => ({
                    label: agency.label,
                    value: agency.value,
                }));
                const newOption = { label: "NTFC Secretrait User", value: 9999 };
                const updatedOptions = [newOption].concat(options);
                // options.unshift({ label: "All Agencies", value: 0 });
                setAgenciesOptions(updatedOptions);
                console.log("Agency Options:", options);
            }
        });
    }, []);

    const selectAgency = async (val) => {
        console.log("val", val)
        let newArr = []
        newArr.push(val)
        if (val) {
            form.resetFields(['participants'])
            setSelectedAgencies(val)
            // setAgencies(agencies);
            getParticipants(newArr)

        } else {
            form.resetFields()
            // setAgencies(agencies.map((obj, index) => obj.disabled = false))
        }
        console.log(val)

    }

    return <Col xs={24} >
        <Form
            form={form}
            layout='vertical'
            onFinish={createNewChat}

        >

            <Row gutter={[16, 16]} style={{ width: "100%" }}>
                <Col span={24}>
                    <Form.Item
                        label="Select Agency"
                        name="agency"
                        required={true}
                        rules={[{
                            required: true,
                            message: <p style={{ marginLeft: "12px" }}>Agency is required</p>,
                        }]}

                    >
                        <Select
                            // value={selectedAgencies}

                            placeholder="Select An Agency"
                            allowClear
                            style={{ width: "100%" }}
                            options={agenciesOptions.sort((a, b) => a.label.localeCompare(b.label))}
                            onChange={selectAgency}
                            filterOption={(inputValue, option) =>
                                option.label.toLowerCase().indexOf(inputValue.toLowerCase()) !== -1
                            }
                            showSearch
                        />
                    </Form.Item>
                </Col>
                <Col span={24} >
                    <Form.Item
                        required={true}
                        label="Select Participant"
                        name="participants"
                        rules={[{
                            required: true,
                            message: <p style={{ marginLeft: "12px" }}>Participants are required</p>,
                        }]}
                    >
                        <Select
                            showSearch
                            placeholder="Select a person"
                            optionFilterProp="children"
                            onChange={(val, opts) => setParticipants(opts)}
                            style={{ width: "100%" }}
                            value={participants}
                            filterOption={(input, option) =>
                                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                            }
                            options={currentUsers}
                        />
                    </Form.Item>
                </Col>
                <Col span={24} >
                    <div style={{ display: "flex", justifyContent: "end", alignItems: "center" }}>
                        <ConfigProvider
                            theme={{
                                token: {
                                    colorPrimary: NewColorTheme.primaryColor,
                                },
                            }}
                        >
                            <Button type='primary' size="large" style={{ width: '100%' }} htmlType='submit'
                            // onClick={createNewChat}
                            >
                                Create Chat
                            </Button>
                        </ConfigProvider>
                    </div>


                </Col>
            </Row>

        </Form>
    </Col>
}

export default PersonalChat;