import React, { Fragment } from "react";
import {
  List,
  Typography,
  Input,
  Space,
  Button,
  message,
  Tooltip,
  Form,
  Select,
  Row,
  Col,
  Spin,
  Table,
  ConfigProvider,
  Modal,
  Divider,
  Avatar,
  Card,
  FloatButton,
  Result,
} from "antd";
import {
  SettingOutlined,
  ToolOutlined,
  ControlOutlined,
  DeploymentUnitOutlined,
  BookOutlined,
  PhoneOutlined,
  EnvironmentOutlined,
  CloseOutlined,
  ContainerOutlined,
  BlockOutlined,
  PlusOutlined,
  EditOutlined,
  EyeOutlined,
  UserOutlined,
  BankOutlined,
  LoadingOutlined
} from "@ant-design/icons";
import { useEffect, useState } from "react";
import { GetAgencyDependancies, addAgency, getAgencyList, updateAgency } from "../../api";
import { AGENCY_TYPES, COUNTRY_LIST } from "../../constants";
import "./agencies.styles.css";
import CustomTable from "../../Components/Table";
import PageLayouts from "../../Components/PageLayouts";
import { useParams } from "react-router-dom";
import PhoneInput from "react-phone-input-2";

import { ToastContainer, toast, Zoom } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import FormLayouts from "../DonorsNew/Components/FormLayouts";
import CardTitle from "../../Components/ViewDialogCardTitle/CardTitle";
import FullSearch from "../../Components/FullSearch";
import { NewColorTheme } from "../../appConst";

const customTheme = {
  "@ant-modal-content": {
    padding: 0,
  },
};

const { Search } = Input;

const AddAgencies = ({ agency }) => {
  const { id } = useParams();

  const [items, setItems] = useState([]);

  const [data, setData] = useState([]);
  const [filtered_data, setFilteredData] = useState([]);
  const [full, setFull] = useState([]);

  const [selected, setSelected] = useState(null);

  const [agencyName, setAgencyName] = useState("");
  const [agencyType, setAgencyType] = useState(null);
  const [agencyDesc, setAgencyDesc] = useState("");
  const [agencyPhone, setAgencyPhone] = useState("");
  const [agencyAddress, setAgencyAddress] = useState("");
  const [numberError, setNumberError] = useState("");
  const [loaded, setLoaded] = useState(false);
  const [prevData, setPrevData] = useState([]);
  const [viewOnly, setViewOnly] = useState(false);
  const [form] = Form.useForm();
  const [currentRecord, setCurrentRecord] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [btnDisable, setDisable] = useState(true)
  const [phnValid, setPhnValid] = useState(false);
  const [isChair, setChair] = useState(false)
  const [checkDependancies, setCheckDependancies] = useState(false)
  const [dependancyData, setDependancyData] = useState()
  const [dependancyLoading, setDependancyLoading] = useState(false)
  const [typeDisable, setTypeDisable] = useState(false)




  const isFieldsTouched = () => {

    return form.isFieldsTouched();
  };

  const columns = [
    {
      title: "Agency Name",
      dataIndex: "agency_name",
      key: "agency_name",
      width: isChair ? 600 : 200
    },
    // {
    //     title: 'Action Title',
    //     dataIndex: 'label',
    //     key: 'label',

    // },

    {
      title: "Agency Type",
      dataIndex: "agency_type",
      key: "agency_type",
      width: 160,
      render: (_, { agency_type }) =>
        agency_type
          ? AGENCY_TYPES.filter((type) => type.value === agency_type)[0].label
          : "",
    },
    {
      title: "Phone Number",
      dataIndex: "phone",
      key: "phone",
      width: 140,
    },
    // {
    //   title: "Address Line 1",
    //   dataIndex: "address_1",
    //   key: "address_1",
    // },
    // {
    //   title: "Address Line 2",
    //   dataIndex: "address_2",
    //   key: "address_2",
    //   render: (_, { address_2 }) => (address_2 ? address_2 : "-"),
    // },
    {
      title: "City",
      dataIndex: "address_3",
      key: "address_3",
      width: 150,
      render: (_, { address_3 }) =>
        address_3
          ? <span style={{ whiteSpace: 'nowrap' }}>{address_3}</span>
          : "",
    },
    {
      title: "Country",
      dataIndex: "country",
      key: "country",
      render: (_, { country }) =>
        country
          ? <span style={{ whiteSpace: 'nowrap' }}>{country}</span>
          : "",
    },

    {
      title: "Action",
      key: "action",
      render: (_, record) =>
        record && !record.moreData ? (
          <div>
            {!isChair &&
              <Tooltip title="Update Agency">
                <Button
                  type="secondary"
                  size="small"
                  onClick={() => {
                    setPhnValid(true);
                    if (selected !== record) {
                      form.resetFields();
                    }
                    setDisable(true)
                    handleSelected(record);
                    setViewOnly(false);
                  }}
                  shape="circle"
                  icon={<EditOutlined style={{ color: "#946e6e" }} />}
                />
              </Tooltip>
            }

            <Tooltip title="View Agency">
              <Button
                type="secondary"
                size="small"
                onClick={() => {
                  setSelected(null);
                  form.resetFields();
                  setAgencyDesc("");
                  setCurrentRecord(record);
                  showModal(true);
                  console.log("Record", record);
                }}
                shape="circle"
                icon={<EyeOutlined style={{ color: "darkgreen" }} />}
              />
            </Tooltip>
          </div>
        ) : null,
    },
  ];

  const refreshAgencies = () => {
    getAgencyList().then((out) => {
      const data = out.data.out.map((item) => {
        return {
          ...item,
          agency_format: item.agency_type ? AGENCY_TYPES.filter((type) => type.value === item.agency_type)[0].label : "",
          phone: item.phone ? "+" + item.phone : ""
        }
      })
      setItems(data);
    });
  };

  const [messageApi, contextHolder] = message.useMessage();

  const handleSelected = (record) => {
    console.log(record);
    setSelected(record);
  };

  const success = (msg) => {
    messageApi.open({
      type: "success",
      content: msg,
      style: {
        marginTop: "30vh",
        height: "100vh",
        fontSize: "20px",
      },
      duration: 3,
    });
  };

  const error = (msg) => {
    messageApi.open({
      type: "error",
      content: msg,
      style: {
        marginTop: "30vh",
        height: "100vh",
        fontSize: "20px",
      },
      duration: 3,
    });
  };
  const handleOnChange = (value) => {
    if (/^[^0-9]*$/.test(value)) {
      form.setFieldValue("agency_name", value);
      setAgencyName(value);
      setNumberError(false);
    } else {
      form.setFieldValue("agency_name", agencyName);
      setAgencyName(agencyName);
      setNumberError(true);
    }
  };

  const onFinish = (values) => {
    console.log("Success:", values);
    if (!selected) {
      if (data.some((obj) => obj.agency_name === values.agency_name)) {
        toast.error("Agency Name already exists!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      } else {
        addAgency(values)
          .then((out) => {
            if (out.status === 200) {
              form.resetFields();
              setAgencyDesc("");
              setDisable(true)
              toast.success("The agency has been successfully added", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
              });
            } else {
              toast.error("Error on adding agency", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
              });
            }

            refreshAgencies();
          })
          .catch((err) => {
            toast.error("Error on adding agency", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            });
          });
      }
    } else {
      values.id = selected.id;

      if (
        data
          .filter((el) => el.id !== selected.id)
          .some((obj) => obj.agency_name === values.agency_name)
      ) {
        toast.error("Agency Name already exists!", {
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progressClassName: "toast-progress-bar",
          theme: "colored",
        });
      } else {

        if (isFieldsTouched()) {
          updateAgency(values).then((out) => {
            form.resetFields();
            refreshAgencies();
            setDisable(true)
            toast.success("The agency has been successfully updated", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            });

            setSelected(null);
          });
        } else {
          toast.warning("There are no changes to update the agency", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          })
          form.resetFields();
          refreshAgencies();
          setSelected(null)
        }

      }
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
    if (!selected) {
      toast.error("The agency added was unsuccessful", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    } else {
      toast.error("The agency added was unsuccessful", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
    if (!form.getFieldValue("phone")) {
      setPhnValid(false);
    }
  };

  useEffect(() => {

    if (window.localStorage.getItem('ntfc_level') == 6) {
      setChair(true)
    } else {
      setChair(false)
    }
    refreshAgencies();
    setPhnValid(true);
  }, []);

  useEffect(() => {
    setData(items);
    setFilteredData(items);
    setFull(items);
    setPrevData(items);
    setLoaded(true);
  }, [items]);

  useEffect(() => {
    if (selected) {
      setTypeDisable(true)
      form.setFieldValue("agency_name", selected.agency_name);
      form.setFieldValue("agency_type", selected.agency_type);
      form.setFieldValue("description", selected.description);
      setAgencyDesc(selected.description);
      form.setFieldValue("phone", selected.phone);
      form.setFieldValue("address_1", selected.address_1);
      form.setFieldValue("address_2", selected.address_2);
      form.setFieldValue("address_3", selected.address_3);
      form.setFieldValue("country", selected.country);
      form.setFieldValue("postal_code", selected.postal_code);
    } else {
      setTypeDisable(false)
      form.resetFields();
      setAgencyDesc("");
    }
    // eslint-disable-next-line
  }, [selected]);

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
    setCheckDependancies(false)
  };

  const getDependancies = (id) => {
    setDependancyLoading(true)
    GetAgencyDependancies(id).then((out) => {
      if (out.status == 200) {
        setDependancyData(out.data)

        if (out.data.updatable) {
          setTypeDisable(false)
        } else {
          setTypeDisable(true)
        }

      }

      console.log("agec", dependancyData)
      setTimeout(() => {
        setDependancyLoading(false)
      }, 1000);

    });
  }


  return (
    <Fragment>
      <FormLayouts
        title="Agencies"
        formTitle="Add Agency"
        formSide={!isChair ?
          <Form
            form={form}
            scrollToFirstError
            layout="vertical"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            onValuesChange={(changedValues, allValues) => {
              if (changedValues) {
                setDisable(false)
              } else {
                setDisable(true)
              }

            }}
          >
            <Row gutter={8}>
              <Col className="gutter-row" span={24}>
                <Form.Item
                  label={`Agency Name`}
                  name="agency_name"
                  required
                  rules={[
                    {
                      required: true,
                      message: (
                        <p style={{ marginLeft: "12px" }}>
                          Agency Name is required
                        </p>
                      ),
                    },
                    {
                      pattern: /^\S.*$/,
                      message: (
                        <p style={{ marginLeft: "12px" }}>
                          Agency Name is invalid
                        </p>
                      ),
                    },
                    { min: 5, message: 'At least 5 characters required' },
                  ]}
                >
                  <Input
                    type="text"
                    placeholder="Agency Name"
                    value={agencyName}
                    // onChange={(e) => handleOnChange(e.target.value)}
                    disabled={viewOnly ? true : false}
                    style={{
                      backgroundColor: viewOnly ? "#dbdbdb" : "",
                      borderRadius: "5px",
                    }}
                  />
                </Form.Item>
                {/* {numberError ?
                                    <p style={{ marginTop: "-20px", marginLeft: "12px", color: "#ff4d4f" }}>Agency Name cannot contain numbers</p>
                                    : ""} */}
              </Col>
              <Col className="gutter-row" span={24}>
                <Form.Item
                  label="Agency Type"
                  name="agency_type"
                  required
                  rules={[
                    {
                      required: true,
                      message: (
                        <p style={{ marginLeft: "12px" }}>
                          Agency Type is required
                        </p>
                      ),
                    },
                  ]}
                >
                  <Select
                    placeholder="Agency Type"
                    options={AGENCY_TYPES}

                    onChange={(e) => {
                      const flitered = AGENCY_TYPES.filter(
                        (it) => it.value === e
                      );
                      if (flitered.length > 0) {
                        setAgencyType(e);
                      }
                    }}
                    value={agencyType}
                    disabled={typeDisable ? true : false}
                    style={{
                      backgroundColor: selected
                        ? viewOnly
                          ? "#dbdbdb"
                          : "rgb(240, 240, 240)"
                        : "",
                      borderRadius: "5px",
                    }}
                  />
                </Form.Item>
                {selected &&
                  <span onClick={() => {
                    setCheckDependancies(true);
                    getDependancies(selected ? selected.id : null)

                  }} style={{
                    color: NewColorTheme.primaryColor,
                    cursor: "pointer"
                  }}
                  >
                    Edit Agency Type
                    {checkDependancies && <LoadingOutlined />} </span>

                }
              </Col>
              <Col className="gutter-row" span={24}>
                <Form.Item
                  label="Description"
                  name="description"
                  rules={[

                    {
                      pattern: /^\S.*$/,
                      message: (
                        <p style={{ marginLeft: "12px" }}>
                          Invalid description
                        </p>
                      ),
                    },
                  ]}

                >
                  <Input.TextArea
                    rows={4}
                    placeholder="Description"
                    // value={agencyDesc}
                    // onChange={(e) => {
                    //   console.log("valueee======>>", e.target.value.trim())
                    //   const trimmedValue = e.target.value;
                    //   if (trimmedValue.trim() == '') {

                    //     setShowError(true)
                    //     setAgencyDesc(trimmedValue);
                    //   } else {

                    //     setShowError(false)
                    //     setAgencyDesc(trimmedValue);
                    //     form.setFieldValue("description", e.target.value);
                    //   }

                    // }}
                    showCount
                    maxLength={500}
                    disabled={viewOnly ? true : false}
                    style={{
                      backgroundColor: viewOnly ? "#dbdbdb" : "",
                      borderRadius: "5px",
                    }}
                  />
                  {/* <div>
                    {showError &&
                      <p style={{ float: "left", color: "red" }}>
                        Invalid Description
                      </p>
                    }

                    <p style={{ float: "right", color: "black" }}>
                      {agencyDesc?.length}/200
                    </p>
                  </div> */}
                </Form.Item>
              </Col>
              <Col className="gutter-row" span={24}>
                <Form.Item
                  label="Phone Number"
                  name="phone"
                  required
                  rules={[
                    {
                      required: true,
                      message: (
                        <p style={{ marginLeft: "12px" }}>
                          Phone Number is required
                        </p>
                      ),
                    },
                    {
                      pattern: /^(?!0)\d+$/,
                      message: (
                        <p style={{ marginLeft: "12px" }}>
                          Invalid Country Code
                        </p>
                      ),
                    },
                    {
                      max: 15,
                      message: "Maximum 15 digits allowed",
                    },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (value.startsWith("94")) {
                          if (value.length === 11) {
                            setPhnValid(true);
                            return Promise.resolve();
                          } else {
                            setPhnValid(false);
                            return Promise.reject(
                              new Error(
                                "Invalid phone Number. Must be +94XXXXXXXX format."
                              )
                            );
                          }
                        } else if (!value) {
                          setPhnValid(false);
                        }
                        return Promise.resolve();
                      },
                    }),
                  ]}
                >
                  <PhoneInput
                    country={"lk"}
                    value={agencyPhone}
                    onChange={(val) => {
                      console.log(val, val ? 1 : 0);
                      setAgencyPhone(val);
                      if (val) {
                        setPhnValid(true);
                      } else {
                        setPhnValid(false);
                      }
                    }}
                    placeholder="Phone Number"
                    inputStyle={{ width: "100% !important" }}
                    // inputStyle={{ width: "100%", borderRadius: "30px" }}
                    className="select-custom-pn-ag"
                    inputProps={{
                      placeholder: "Phone Number",
                      // disabled:true
                      style: phnValid ? { color: "gray", width: "100%" } : { color: "gray", width: "100%", border: 'red 1px solid' },
                    }}
                    // country='lk'
                    // disableInitialCountryGuess={true}
                    disabled={viewOnly ? true : false}
                    style={{
                      backgroundColor: viewOnly ? "#dbdbdb" : "",
                      borderRadius: "5px",
                    }}
                  />
                </Form.Item>
              </Col>
              <Col className="gutter-row" span={24}>
                <Form.Item
                  label="Address Line 01"
                  name="address_1"
                  required
                  rules={[
                    {
                      required: true,
                      message: (
                        <p style={{ marginLeft: "12px" }}>
                          Address 1 is required
                        </p>
                      ),
                    },
                    {
                      pattern: /^\S.*$/,
                      message: (
                        <p style={{ marginLeft: "12px" }}>
                          Address 1 is invalid
                        </p>
                      ),
                    },
                  ]}
                >
                  <Input
                    type="text"
                    placeholder="Address Line 1"
                    // value={agencyAddress}
                    // onChange={(e) => setAgencyAddress(e.target.value)}
                    disabled={viewOnly ? true : false}
                    style={{
                      backgroundColor: viewOnly ? "#dbdbdb" : "",
                      borderRadius: "5px",
                    }}
                  />
                </Form.Item>
              </Col>
              <Col className="gutter-row" span={24}>
                <Form.Item
                  label="Address Line 02"
                  name="address_2"
                  rules={[
                    // {
                    //     required: true,
                    //     message: <p style={{ marginLeft: "12px" }}>Address 2 is required</p>,
                    // },
                    {
                      pattern: /^\S.*$/,
                      message: (
                        <p style={{ marginLeft: "12px" }}>
                          Address 2 is invalid
                        </p>
                      ),
                    },
                  ]}
                >
                  <Input
                    type="text"
                    placeholder="Address Line 2"
                    // value={agencyAddress}
                    // onChange={(e) => setAgencyAddress(e.target.value)}
                    disabled={viewOnly ? true : false}
                    style={{
                      backgroundColor: viewOnly ? "#dbdbdb" : "",
                      borderRadius: "5px",
                    }}
                  />
                </Form.Item>
              </Col>
              <Col className="gutter-row" span={24}>
                <Form.Item
                  label="City"
                  name="address_3"
                  rules={[
                    {
                      required: true,
                      message: (
                        <p style={{ marginLeft: "12px" }}>City is required</p>
                      ),
                    },
                    {
                      pattern: /^\S.*$/,
                      message: (
                        <p style={{ marginLeft: "12px" }}>Invalid City Name</p>
                      ),
                    },
                  ]}
                >
                  <Input
                    type="text"
                    placeholder="City"
                    // value={agencyAddress}
                    // onChange={(e) => setAgencyAddress(e.target.value)}
                    disabled={viewOnly ? true : false}
                    style={{
                      backgroundColor: viewOnly ? "#dbdbdb" : "",
                      borderRadius: "5px",
                    }}
                  />
                </Form.Item>
              </Col>
              <Col className="gutter-row" span={24}>
                <Form.Item
                  label="Postal Code"
                  name="postal_code"
                  required
                  rules={[
                    {
                      required: true,
                      message: (
                        <p style={{ marginLeft: "12px" }}>
                          Postal code is required
                        </p>
                      ),
                    },
                    {
                      pattern: /^\S.*$/,
                      message: (
                        <p style={{ marginLeft: "12px" }}>
                          Postal code is invalid
                        </p>
                      ),
                    },
                  ]}
                >
                  <Input
                    type="text"
                    placeholder="Postal Code"
                    // value={agencyAddress}
                    // onChange={(e) => setAgencyAddress(e.target.value)}
                    disabled={viewOnly ? true : false}
                    style={{
                      backgroundColor: viewOnly ? "#dbdbdb" : "",
                      borderRadius: "5px",
                    }}
                  />
                </Form.Item>
              </Col>
              <Col className="gutter-row" span={24}>
                <Form.Item
                  label="Country"
                  name="country"
                  required
                  rules={[
                    {
                      required: true,
                      message: (
                        <p style={{ marginLeft: "12px" }}>
                          Country is required
                        </p>
                      ),
                    },
                    {
                      pattern: /^\S.*$/,
                      message: (
                        <p style={{ marginLeft: "12px" }}>Country is invalid</p>
                      ),
                    },
                  ]}
                >
                  <Select
                    showSearch
                    placeholder="Country"
                    options={COUNTRY_LIST}
                    style={{
                      backgroundColor: selected
                        ? viewOnly
                          ? "#dbdbdb"
                          : "rgb(240, 240, 240)"
                        : "",
                      borderRadius: "5px",
                    }}
                  />
                </Form.Item>
              </Col>
            </Row>

            {viewOnly ? (
              <Space>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    width: "100%",
                  }}
                >
                  <Button
                    size="large"
                    type="primary"
                    danger
                    htmlType="submit"
                    icon={<CloseOutlined />}
                    onClick={() => {
                      setSelected(null);
                      setViewOnly(false);
                    }}
                  >
                    Cancel
                  </Button>
                </div>
              </Space>
            ) : (
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  width: "100%",
                }}
              >
                {!selected ? (
                  <Button
                    size="large"
                    type="primary"
                    htmlType="submit"
                    icon={<PlusOutlined />}
                    disabled={window.localStorage.getItem('view_only') == 1}
                  >
                    Add Agency
                  </Button>
                ) : (
                  <Space>
                    <Form.Item>
                      <Button
                        size="large"
                        type="primary"
                        danger
                        htmlType="submit"
                        icon={<CloseOutlined />}
                        onClick={() => {
                          setDisable(true)
                          setSelected(null)
                        }}
                      >
                        Cancel
                      </Button>
                    </Form.Item>
                    <Form.Item>
                      <Button
                        disabled={window.localStorage.getItem('view_only') == 1 || btnDisable}
                        size="large"
                        type="primary"
                        htmlType="submit"
                        icon={<EditOutlined />}
                      >
                        Update
                      </Button>
                    </Form.Item>


                  </Space>
                )}
              </div>
            )}
          </Form> : null
        }
        table={
          <div style={{ padding: 10 }}>
            <FullSearch full={full} setFiltered={setFilteredData} params={["agency_name", "agency_format", "phone", "address_3", "country"]} />
            <Table
              scroll={{
                y: 400,
              }}
              className="custom-table-header custom-table2"
              columns={columns}
              dataSource={filtered_data}
              pagination={{
                pageSize: isChair ? 5 : 10,
                total: filtered_data.length,
                showSizeChanger: false,
              }}
            />
          </div>
        }
      />

      <Modal
        closable={true}
        className="custom-modal"
        width={700}
        footer={false}
        onCancel={handleCancel}
        title={
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div>
              {" "}
              <Typography className="custom-modal-title" level={2}>
                Agency Details
              </Typography>
            </div>
          </div>
        }
        open={isModalOpen}
        onOk={handleOk}
        centered
      >
        <div className="custom-modal-body">
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <CardTitle
                icon={<BankOutlined />}
                title={"Agency Name"}
                content={currentRecord.agency_name}
              />
            </Col>
            <Col span={24}>
              <CardTitle
                icon={<BlockOutlined />}
                title={"Agency Type"}
                content={
                  currentRecord.agency_type === 1
                    ? "Government Agency"
                    : currentRecord.agency_type === 2
                      ? "Private Sector"
                      : "Donors"
                }
              />
            </Col>

            <Col span={24}>
              <CardTitle
                icon={<ContainerOutlined />}
                title={"Description"}
                content={currentRecord.description}
              />
            </Col>
            <Col span={24}>
              <CardTitle
                icon={<PhoneOutlined />}
                title={"Phone Number"}
                content={currentRecord.phone}
              />
            </Col>
            <Col span={24}>
              <CardTitle
                icon={<EnvironmentOutlined />}
                title={"Address"}
                content={
                  (currentRecord.address_1
                    ? currentRecord.address_1.replace(/,/g, '') + ", "
                    : "") +
                  (currentRecord.address_2
                    ? currentRecord.address_2.replace(/,/g, '') + ", "
                    : "") +
                  (currentRecord.address_3
                    ? currentRecord.address_3.replace(/,/g, '') + ", "
                    : "") +

                  (currentRecord.postal_code
                    ? currentRecord.postal_code + ", "
                    : "") +
                  (currentRecord.country
                    ? currentRecord.country + ". "
                    : "")


                }
              />
            </Col>
          </Row>
        </div>

        <Row gutter={[16, 16]} style={{ display: "none" }}></Row>
      </Modal>
      <Modal
        closable={false}
        className="custom-modal"
        width={700}
        footer={false}
        onCancel={handleCancel}
        maskClosable={false}
        title={
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div>
              {" "}
              <Typography className="custom-modal-title" level={2}>
                Checking Dependencies
              </Typography>
            </div>
          </div>
        }
        open={checkDependancies}
        onOk={handleOk}
        centered

      >
        <div className="custom-modal-body">
          {dependancyLoading ?
            <Result
              icon={<LoadingOutlined />}
              subTitle={`We are checking if there are any dependencies for  ${selected ? selected.agency_name : " this agency "},  you will be able to change the agency type if there're no dependencies for this agency.`}
              title="Please Wait"

            />

            : (
              dependancyData?.updatable ?

                <Result
                  status="success"
                  title="You will be able to edit agency type"
                  subTitle="There are no dependencies available for this agency"
                  extra={[
                    <Button
                      type="primary"
                      key="console"
                      onClick={() => {
                        setCheckDependancies(false)
                      }}
                    >
                      Back
                    </Button>,
                  ]}
                />
                :
                <Result
                  status="error"
                  title="You will not be able to edit agency type"
                  subTitle="There are dependencies available for this agency"
                  extra={[
                    <Button
                      type="primary"
                      key="console"
                      onClick={() => {
                        setCheckDependancies(false)
                      }}
                    >
                      Back
                    </Button>,

                  ]}

                >

                </Result>
            )
          }

        </div>

        <Row gutter={[16, 16]} style={{ display: "none" }}></Row>
      </Modal>


      <ToastContainer />
    </Fragment>
  );
};

export default AddAgencies;
