import React, { useEffect, useState } from 'react'
import { CalenderColors } from '../../../../../appConst'
import dayjs from 'dayjs';
import { Button, Card, Col, Row, Skeleton, Typography } from 'antd';
import { BuildOutlined, RocketOutlined, BlockOutlined, AppstoreOutlined } from '@ant-design/icons';
import { BiCameraMovie } from "react-icons/bi";
import { GetAgencyWiseAllArticles } from '../../../../../api';


function UpcomingTasks() {

    const [todoList, setTodoList] = useState()
    useEffect(() => {

        AgencyActions()


    }, [])

    const AgencyActions = async () => {

        const out = await GetAgencyWiseAllArticles(window.localStorage.getItem('ntfc_agency'))

        if (out.status === 200) {

            // Restructure the array that can match to the events for the calender
            const newArray = out.data.out.flatMap(item => {
                const { article_no, article, measures } = item;

                return measures.flatMap(({ measure_id, measure_name, article_no, measure_no, measure_summary, measure_def_date, actions }) => {



                    const measureObj = {
                        extendedProps: {
                            id: measure_id,
                            type: "measure",
                            measure: measure_no + ". " + measure_name,
                            article: article_no + ". " + article,
                            measure_def_date: dayjs(measure_def_date).format("DD/MM/YYYY")
                        },

                        title: article_no + "." + measure_no + " " + measure_summary,
                        start: new Date(dayjs(measure_def_date).format("DD/MM/YYYY")),
                        backgroundColor: CalenderColors.MEASURE,
                        borderColor: "white",
                        allDay: true
                    };
                    const actionObjs = actions.map(({ action_id, action_status, action, participants, action_no, action_def_date, action_summary }) => (
                        {
                            extendedProps: {
                                id: action_id,
                                type: "action",
                                action: action_no + ". " + action,
                                participants: participants,
                                status: action_status,
                                article: article_no + ". " + article,
                                measure: measure_no + ". " + measure_name,
                                measure_def_date: dayjs(measure_def_date).format("DD/MM/YYYY"),
                                action_def_date: dayjs(action_def_date).format("DD/MM/YYYY")
                            },

                            title: article_no + "." + measure_no + "." + action_no + " " + action_summary,
                            start: new Date(dayjs(action_def_date).format("DD/MM/YYYY")),
                            backgroundColor: CalenderColors.ACTIONS,
                            borderColor: "white",
                            allDay: true
                        }
                    ));
                    return [measureObj, ...actionObjs];
                });
            });

            const currentDate = new Date();

            // Define the maximum allowed number of days after the current date
            const maxDaysAfterCurrent = 30;

            // Calculate the maximum date
            const maxDate = new Date();
            maxDate.setDate(currentDate.getDate() + maxDaysAfterCurrent);

            // Filter the objects based on the date condition
            const filteredObjects = newArray.filter((obj) => {
                const measureDefDate = new Date(obj.start);
                return measureDefDate <= maxDate;
            });

            // Sort the objects by date
            const sortedObjects = filteredObjects.sort((a, b) => {
                const dateA = new Date(a.start);
                const dateB = new Date(b.start);
                return dateA - dateB;
            });

            console.log("netSortedArr --------->>", sortedObjects)
            setTodoList(sortedObjects)

        } else {
            console.log("data loading falid")
        }

    }



    useEffect(() => {

    }, [])




    const getType = (type) => {


        if (type == 0) {
            return "Meeting"
        } else if (type == 1) {
            return "Event"
        } else if (type == 2) {
            return "To Do"
        }

    }
    const getColor = (type) => {
        if (type === 0) {
            return CalenderColors.MEETING

        } else if (type === 1) {
            return CalenderColors.EVENT

        } else if (type === 2) {

            return CalenderColors.TODO
        }

    }

    const getGradient = (type) => {
        if (type == "Meeting") {
            return `linear-gradient( to right, ${CalenderColors.MEETING}  100%, #ABDCFF 100%)`
        } else if (type == "Event") {
            return `linear-gradient( to right, ${CalenderColors.EVENT}  100%, #F6CEEC 100%)`
        } else if (type == "To Do") {
            return `linear-gradient( 135deg, ${CalenderColors.TODO} 100%, #FEB692 100%)`
        } else if (type == "measure") {
            return `linear-gradient( 135deg, ${CalenderColors.MEASURE}  100%, #81FBB8 100%)`
        } else if (type == "action") {
            return `linear-gradient( 135deg, ${CalenderColors.ACTIONS}  100%, #FDEB71 100%)`
        }
    }
    return (
        <>
            {todoList ?
                <Row gutter={[16, 16]}>

                    {/* To Do List */}
                    <Col xs={24}>

                        <div className='custom-scroll-div' style={{ width: "100%", height: "28vh", overflow: "auto", padding: 7 }} >
                            {todoList?.map((element, index) => {

                                return <Card
                                    key={index}
                                    style={{
                                        height: "max-content",
                                        width: "100%",
                                        display: "flex",
                                        // backgroundImage: getGradient(element.extendedProps.type),
                                        // background: getColor(element.extendedProps.type),
                                        // padding: "10px",
                                        margin: "5px 0",
                                        padding: "10px"


                                    }}
                                >
                                    <Row gutter={16}>
                                        <Col xs={3}
                                            style={{
                                                display: "flex",
                                                width: "100%",
                                                // height: "100%",
                                                alignItems: "center",
                                                justifyContent: "center"
                                            }}
                                        >
                                            {
                                                element.extendedProps.type == "action" ?

                                                    <Button type="primary" shape="circle" icon={<RocketOutlined style={{ fontSize: 20, color: "white" }} />}>
                                                    </Button>
                                                    :
                                                    element.extendedProps.type == "measure" ?
                                                        <Button type="primary" shape="circle" icon={<BlockOutlined style={{ fontSize: 20, color: "white" }} />}>
                                                        </Button>


                                                        :
                                                        element.extendedProps.type == "Meeting" ?
                                                            <Button size='large' type="primary" shape="circle" icon={<BiCameraMovie style={{ fontSize: 22, color: "white" }} />}>
                                                            </Button> :
                                                            element.extendedProps.type == "Event" ?
                                                                <Button size='large' type="primary" shape="circle" icon={<AppstoreOutlined style={{ fontSize: 20, color: "white" }} />}>
                                                                </Button>
                                                                :
                                                                <Button size='large' type="primary" shape="circle" icon={<BuildOutlined style={{ fontSize: 22, color: "white" }} />}>
                                                                </Button>

                                            }

                                        </Col>
                                        <Col xs={15}>

                                            <div
                                                style={{
                                                    display: "flex",
                                                    // flexDirection: "column",
                                                    gap: 1

                                                    // width: "100%"
                                                }}
                                            >
                                                {/* <Tag color='yellow' style={{ width: "max-content" }}>{element.extendedProps.type}</Tag> */}
                                                <div>
                                                    <Typography
                                                        style={{
                                                            fontFamily: "Figtree",
                                                            fontSize: 14,
                                                            fontWeight: 600,
                                                            color: "black"
                                                        }}>{element.title}
                                                    </Typography>
                                                </div>


                                            </div>

                                        </Col>
                                        <Col xs={6}>
                                            <div style={{
                                                display: "flex",
                                                flexDirection: "column",
                                                width: "100%",
                                                justifyContent: "flex-end",
                                                alignItems: 'end'
                                            }}>

                                                <Typography
                                                    style={{
                                                        fontFamily: "Figtree",
                                                        fontSize: 12,
                                                        fontWeight: 500,
                                                        color: "black"
                                                    }}
                                                >{(element.start ? dayjs(element.start).format("DD/MM/YYYY") : "-")}</Typography>
                                            </div>

                                        </Col>

                                    </Row>

                                </Card>
                            })}

                        </div>

                    </Col>

                </Row> :

                <Skeleton
                    active
                    avatar
                    paragraph={{
                        rows: 4,
                    }}
                />}


        </>
    )
}

export default UpcomingTasks