import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { GetAgencyWiseAllArticles, getActionForUser } from '../../../../api'
import dayjs from 'dayjs';
import { CalenderColors } from '../../../../appConst';
import { Button, Card, Col, Empty, Row, Skeleton, Typography } from 'antd';
import { BuildOutlined, RocketOutlined, BlockOutlined, AppstoreOutlined } from '@ant-design/icons';
import { BiCameraMovie } from "react-icons/bi";



function UpcomingTasks() {


    const [todoList, setTodoList] = useState()
    useEffect(() => {

        AgencyActions()


    }, [])

    const AgencyActions = async () => {
        getActionForUser({}).then((out) => {
            // setActions(out.data.out)
            console.log("filteredData", out)
            let updatedData = out.data.out.map(item => ({ ...item, measure_def_date: item.measure_extended_def_date ?? item.measure_def_date }));
            const current_date = new Date(); // Get the current date

            // Function to compare dates
            function compareDates(a, b) {
                return new Date(a.measure_def_date) - new Date(b.measure_def_date);
            }

            // Filter the array based on the condition and sort it
            const filteredAndSortedData = updatedData.filter(item => new Date(item.measure_def_date) > current_date).sort(compareDates);
            console.log("filteredData 2", filteredAndSortedData)
            setTodoList(filteredAndSortedData)
        });


        // const out = await GetAgencyWiseAllArticles(window.localStorage.getItem('ntfc_agency'))

        // if (out.status === 200) {

        //     // Restructure the array that can match to the events for the calender
        //     const newArray = out.data.out.flatMap(item => {
        //         const { article_no, article, measures } = item;

        //         return measures.flatMap(({ measure_id, measure_name, article_no, measure_no, measure_summary, measure_def_date, actions }) => {



        //             const measureObj = {
        //                 extendedProps: {
        //                     id: measure_id,
        //                     type: "measure",
        //                     measure: measure_no + ". " + measure_name,
        //                     article: article_no + ". " + article,
        //                     measure_def_date: dayjs(measure_def_date).format("DD/MM/YYYY")
        //                 },

        //                 title: article_no + "." + measure_no + " " + measure_summary,
        //                 start: new Date(dayjs(measure_def_date).format("DD/MM/YYYY")),
        //                 backgroundColor: CalenderColors.MEASURE,
        //                 borderColor: "white",
        //                 allDay: true
        //             };
        //             const actionObjs = actions.map(({ action_id, action_status, action, participants, action_no, action_def_date, action_summary }) => (
        //                 {
        //                     extendedProps: {
        //                         id: action_id,
        //                         type: "action",
        //                         action: action_no + ". " + action,
        //                         participants: participants,
        //                         status: action_status,
        //                         article: article_no + ". " + article,
        //                         measure: measure_no + ". " + measure_name,
        //                         measure_def_date: dayjs(measure_def_date).format("DD/MM/YYYY"),
        //                         action_def_date: dayjs(action_def_date).format("DD/MM/YYYY")
        //                     },

        //                     title: article_no + "." + measure_no + "." + action_no + " " + action_summary,
        //                     start: new Date(dayjs(action_def_date).format("DD/MM/YYYY")),
        //                     backgroundColor: CalenderColors.ACTIONS,
        //                     borderColor: "white",
        //                     allDay: true
        //                 }
        //             ));
        //             return [measureObj, ...actionObjs];
        //         });
        //     });

        //     const currentDate = new Date();

        //     // Define the maximum allowed number of days after the current date
        //     const maxDaysAfterCurrent = 30;

        //     // Calculate the maximum date
        //     const maxDate = new Date();
        //     maxDate.setDate(currentDate.getDate() + maxDaysAfterCurrent);

        //     // Filter the objects based on the date condition
        //     const filteredObjects = newArray.filter((obj) => {
        //         const measureDefDate = new Date(obj.start);
        //         return measureDefDate <= maxDate;
        //     });

        //     // Sort the objects by date
        //     const sortedObjects = filteredObjects.sort((a, b) => {
        //         const dateA = new Date(a.start);
        //         const dateB = new Date(b.start);
        //         return dateA - dateB;
        //     });

        //     console.log("netSortedArr --------->>", sortedObjects)
        //     setTodoList(sortedObjects)

        // } else {
        //     console.log("data loading falid")
        // }

    }



    useEffect(() => {

    }, [])


    return (
        <>
            {todoList ?
                <Row gutter={[16, 16]}>

                    {/* To Do List */}
                    <Col xs={24}>

                        <div className='custom-scroll-div' style={{ width: "100%", height: "23vh", overflow: "auto", padding: 7 }} >
                            {todoList.length > 0 ?

                                todoList?.map((element, index) => {

                                    return <Card
                                        key={index}
                                        style={{
                                            height: "max-content",
                                            width: "100%",
                                            display: "flex",
                                            // backgroundImage: getGradient(element.extendedProps.type),
                                            // background: getColor(element.extendedProps.type),
                                            // padding: "10px",
                                            margin: "5px 0",
                                            padding: "10px",
                                            cursor: "default"


                                        }}
                                    >
                                        <Row gutter={16}>
                                            <Col xs={3}
                                                style={{
                                                    display: "flex",
                                                    width: "100%",
                                                    // height: "100%",
                                                    alignItems: "center",
                                                    justifyContent: "center"
                                                }}
                                            >


                                                <Button type="primary" shape="circle" icon={<RocketOutlined style={{ fontSize: 20, color: "white" }} />}>
                                                </Button>



                                            </Col>
                                            <Col xs={15}>

                                                <div
                                                    style={{
                                                        display: "flex",
                                                        // flexDirection: "column",
                                                        gap: 1

                                                        // width: "100%"
                                                    }}
                                                >
                                                    {/* <Tag color='yellow' style={{ width: "max-content" }}>{element.extendedProps.type}</Tag> */}
                                                    <div>
                                                        <Typography
                                                            style={{
                                                                display: 'flex',
                                                                fontFamily: "Figtree",
                                                                fontSize: 14,
                                                                fontWeight: 600,
                                                                color: "black",
                                                                textAlign: 'justify',
                                                                whiteSpace: "pre-wrap"
                                                            }}>{element.action_summary}
                                                        </Typography>
                                                    </div>


                                                </div>

                                            </Col>
                                            <Col xs={6}>
                                                <div style={{
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    width: "100%",
                                                    justifyContent: "flex-end",
                                                    alignItems: 'end'
                                                }}>

                                                    <Typography
                                                        style={{
                                                            fontFamily: "Figtree",
                                                            fontSize: 12,
                                                            fontWeight: 500,
                                                            color: "black",
                                                            whiteSpace: "nowrap"
                                                        }}
                                                    >{(element.measure_def_date ? dayjs(element.measure_def_date).format("DD/MM/YYYY") : "-")}</Typography>
                                                </div>

                                            </Col>

                                        </Row>

                                    </Card>
                                }) :
                                <Empty description="No current upcoming tasks" />

                            }

                        </div>

                    </Col>

                </Row> :

                <Skeleton
                    active
                    avatar
                    paragraph={{
                        rows: 4,
                    }}
                />}


        </>
    )
}

export default UpcomingTasks