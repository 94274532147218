import React from 'react';
import { CloseOutlined } from "@ant-design/icons";
import { Divider, Typography, Tag, Button, Tooltip, Space, message, Col, Row, Grid, Image } from 'antd';
import 'react-edit-text/dist/index.css';
import './pagelayout.styles.css';
import { useState, useEffect } from 'react';
const { useBreakpoint } = Grid;


function PageLayouts({ formSide, tableSide, formTitle, tableTitle, imageSrc, bgColor }) {


    const [data, setTblData] = useState([]);

    useEffect(() => {

    }, []);

    const { Title } = Typography;


    return (
        <div className='content'>
            <Row style={{ height: '100%' }} >
                <Col className={bgColor == "BLUE" ? "left_div" : bgColor == "PURPLE" ? "left_div_p" : "left_div"} span={6}>
                    <Row >
                        {/* <Col span={12}>
                            <Image
                                width={"100%"}
                                src={imageSrc}
                            />
                        </Col> */}
                        <Col className='main_title_contain' span={24}>
                            <Title className='main_title' level={2}>{formTitle}</Title>
                        </Col>
                    </Row>
                    <div className='seperator_div'></div>

                    <div className='form_container'>
                        {formSide}

                    </div>
                </Col>

                {/* Table Container Right Side */}
                <Col className='table_container' span={18}>
                    <div className='table_title_row'>
                        <Col className='table_side_title_contain' >
                            <Title className='table_side_title' level={2}>{tableTitle}</Title>
                        </Col>
                        <Col className='table_side_seperator_contain' >
                            <div className={bgColor == "BLUE" ? "tableSide_seperator_div" : bgColor == "PURPLE" ? "tableSide_seperator_div_p" : "tableSide_seperator_div"}></div>
                        </Col>
                        <Col style={{ position: "absolute", right: 25, top: 20 }}>
                            <Button
                                onClick={() => { window.location.href = "/newdashboard" }}
                                // disabled={disable ? true : false}
                                shape="circle"
                                icon={<CloseOutlined />}
                                style={{ width: 30, height: 30, backgroundColor: "white", color: "black", borderRadius: 50 }}
                            />
                        </Col>
                    </div>


                    <div style={{ marginTop: 20 }}>
                        {tableSide}
                    </div>

                </Col>
            </Row>

        </div>
    );
}

export default PageLayouts;
