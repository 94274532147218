import {
  Button,
  Form,
  Input,
  Space,
  message,
  Typography,
  Select,
  Card,
  Row,
  Col,
  Checkbox,
  Modal,
} from "antd";
import {
  BankOutlined,
  UserOutlined,
  UserSwitchOutlined,
  IdcardOutlined,
  MailOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import "./signup2.styles.css";
import { useEffect, useState } from "react";
import Lottie from "lottie-react";
import doneAnimation from "./done.json";
import { signup, getAgencies } from "../../api";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { ToastContainer, toast, Zoom } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { triggerFocus } from "antd/es/input/Input";
const { Title, Text } = Typography;

function Page() {
  const [form] = Form.useForm();
  const [requiredMark, setRequiredMarkType] = useState("optional");
  const onRequiredTypeChange = ({ requiredMarkValue }) => {
    setRequiredMarkType(requiredMarkValue);
  };
  const [messageApi, contextHolder] = message.useMessage();

  const success = () => {
    // successDialog()
    messageApi.open({
      type: "success",
      content: "User Registration Request Successfull.",
      style: {
        marginTop: "30vh",
        height: "100vh",
        fontSize: "20px",
      },
      duration: 3,
    });
  };

  const [showModal, setShowModal] = useState(false);
  const [agencyName, setAgencyName] = useState(null);
  const [errorMessage, setErrorMessage] = useState(false);

  const error = () => {
    messageApi.open({
      type: "error",
      content: "Sign up unsuccessful",
      style: {
        marginTop: "30vh",
        height: "100vh",
        fontSize: "20px",
      },
      duration: 3,
    });
  };

  const [pending, setPending] = useState(false);
  const [agencyType, setAgencyType] = useState(4);
  const [submitted, setSubmitted] = useState(false);
  const [agencies, setAgencies] = useState([]);
  const [allAgencies, setAllAgencies] = useState([]);
  const [checked, onChangeCheckBox] = useState(false);
  const [mobile, setMobile] = useState("");
  const [officialMobile, setOfficialMobile] = useState("");
  const [dialog, confirmationDialog] = useState(false);

  const onChange = (e) => {
    console.log("checked = ", e.target.checked);
    onChangeCheckBox(e.target.checked);
  };

  const onFinish = async (val) => {
    setPending(true);
    console.log("val-------------->>>", val);
    if (!isNaN(val.agency_name)) {
      val.agency_id = val.agency_name;
      val.agency_name = allAgencies.filter(
        (item) => item.value === val.agency_name
      )[0].label;
    } else {
      val.agency_id = 9999;
    }
    val.agency_type = agencyType;
    if (!val.user_level) {
      val.user_level = agencyType;
    }
    console.log(val);
    const out = await signup(val);
    if (out) {
      console.log("OUT----------------->>", out);
      if (out.status === 500) {
        setPending(false);
        toast.error(out.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        if (out.data.message == "Already have an account") {
          form.setFields([
            {
              name: "email",
              errors: [out.data.message],
            },
          ]);
        } else {
          form.setFields([
            {
              name: "phone",
              errors: [out.data.message],
            },
          ]);
        }

      } else {
        setPending(false);
        confirmationDialog(true);
        setSubmitted(true);
        form.resetFields();
      }
    } else {
      setPending(false);
      error();
    }
  };

  const refreshAgencies = (type) => {
    const agencies = allAgencies.filter((ag) => ag.agency_type === type);
    console.log(agencies);
    setAgencies(agencies);
  };

  const updateAgencyType = (input) => {
    setAgencyType(input);
    form.setFieldValue("agency_name", null);
    form.setFieldValue("agency_id", null);
    setAgencyName(null);
    refreshAgencies(input);
  };

  useEffect(() => {
    getAgencies().then((out) => {
      setAllAgencies(out.data.out);
    });
  }, []);
  // eslint-disable-next-line
  // useEffect(() => {
  //   refreshAgencies(1);
  // }, [allAgencies]);

  return (
    <div className="container2">
      <div
        className="container2 mobile-hide"
        style={{
          position: "relative",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
          width: "100%",
        }}
      >
        <video
          src={"/back.webm"}
          controls={false}
          autoPlay
          loop
          muted
          style={{ width: "100%", height: "100%", objectFit: "cover" }}
        />
      </div>
      {contextHolder}
      <div className="overlay"></div>
      <Card className="no-padding2 regiter-form-card-back">
        <Row gutter={16}>
          <Col span={10} className="mobile-hide">
            <div
              style={{
                padding: "25px 100px",
                height: "90vh",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "start",
                gap: 20,
              }}
            >
              {/* <img src="/ntfc.png" width={125} alt="logo" /> */}
              <Title level={1} style={{ color: "white", fontSize: 45 }}>
                Welcome!
              </Title>
              <div style={{ marginTop: -10 }}>
                <Typography.Title
                  level={2}
                  style={{ color: "white", margin: 0 }}
                >
                  NTFC Monitoring Tool
                </Typography.Title>
                {/* <Typography.Title level={2} style={{ color: "white", margin: 0 }}>Monitoring Tool</Typography.Title> */}
              </div>

              <p style={{ textAlign: "justify", color: "white", fontSize: 18 }}>
                NTFC monitoring tool is a tool designed monitor the progress of
                the TFA implementation in Sri Lanka. Donor management, Inquiry
                management, Calendar and Internal chat facility are some of the
                other features of this tool which support not only TFA
                implementation progress monitoring but also, the improving the
                efficiency of the agencies.
              </p>
              {/* <Button style={{ borderRadius: 10, color: "#142348", backgroundColor: "white", width: "50%" }} type='default' href='/signup'><b>Register Now</b></Button> */}
            </div>
          </Col>
          <Col lg={14} xs={24}>
            <div
              // className='custom-scroll-div'
              style={{
                textAlign: "center",
                // padding: '2px 100px',
                height: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",

                background: "white",
                borderRadius: "0 10px 10px 0",
                overflow: "hidden",
              }}
            >
              <div
                style={{
                  textAlign: "center",

                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",

                  background: "white",
                  borderRadius: "0 10px 10px 0",
                  overflow: "hidden",
                }}
              >
                <Form
                  form={form}
                  layout="vertical"
                  initialValues={{
                    requiredMarkValue: requiredMark,
                  }}
                  onValuesChange={onRequiredTypeChange}
                  requiredMark={requiredMark}
                  onFinish={onFinish}
                >
                  <Space direction="vertical" style={{ width: "100%" }}>
                    <Title style={{ color: "#0a225a", fontSize: 30 }}>
                      Register
                    </Title>
                    {!submitted ? (
                      <div
                        style={{
                          textAlign: "center",
                          margin: "-20px 0px 10px 0px",
                        }}
                      >
                        <Text type="secondary">Create Your NTFC Account</Text>
                      </div>
                    ) : null}
                  </Space>

                  <div
                    className="custom-scroll-div"
                    style={{
                      textAlign: "center",
                      padding: "0px 100px",
                      height: "66vh",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                      gap: 2,
                      background: "white",
                      borderRadius: "0 10px 10px 0",
                      overflow: "auto",
                    }}
                  >
                    <Row gutter={[12, 4]}>


                      <Col xs={24} md={12} lg={4}>
                        <Form.Item
                          name="title"
                          required
                          rules={[
                            {
                              required: true,
                              message: "Required",
                            },
                          ]}
                        >
                          <Select
                            placeholder="Title"
                            onChange={(value) =>
                              form.setFieldValue("title", value)
                            }
                            options={[
                              { value: "Mr.", label: "Mr." },
                              { value: "Mrs.", label: "Mrs." },
                              { value: "Ms", label: "Ms." },
                              { value: "Dr.", label: "Dr." },
                            ]}
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={24} md={12} lg={8}>
                        <Form.Item
                          name="first_name"
                          required
                          rules={[
                            {
                              required: true,
                              message: "First name is required",
                            },
                            {
                              pattern: /^(?!.*\s{2})[a-zA-Z\s]+$/,
                              message:
                                "Please enter a valid first name with alphabetical characters",
                            },
                          ]}
                        >
                          <Input
                            type="text"
                            placeholder="First Name"
                            // className="input-width-fname"
                            suffix={
                              <UserOutlined style={{ color: "#D7D3D5" }} />
                            }
                            maxLength={100}
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={24} md={12} lg={12}>
                        <Form.Item
                          name="last_name"
                          required
                          rules={[
                            {
                              required: true,
                              message: "Last Name is required",
                            },
                            {
                              pattern: /^(?!.*\s{2})[a-zA-Z\s]+$/,
                              message:
                                "Please enter a valid last name with alphabetical characters",
                            },
                          ]}
                        >
                          <Input
                            type="text"
                            placeholder="Last Name"
                            className="input-width2"
                            suffix={
                              <UserSwitchOutlined style={{ color: "#D7D3D5" }} />
                            }
                            maxLength={100}
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={24} md={12} style={{ width: "100%" }}>

                        <Form.Item

                          name="agency_type"
                          required
                          rules={[
                            {
                              required: true,
                              message: "Agency is required",
                            },
                          ]}
                        >
                          <Select
                            placeholder="Select Agency"
                            className="input-width2"
                            style={{ textAlign: "left" }}
                            onChange={(value) => {
                              updateAgencyType(value);
                              form.setFieldValue("agency_type", value);
                            }}
                            // defaultValue={4}
                            options={[
                              {
                                value: 1,
                                label: "NTFC Member Agency (Government Sector)",
                              },
                              {
                                value: 2,
                                label: "NTFC Member Agency (Private Sector) ",
                              },
                              { value: 3, label: "Donor Agency" },
                              {
                                value: -1,
                                label:
                                  "Non-NTFC Agency (Government and Private Sector)",
                              },
                            ]}
                          />
                        </Form.Item>


                      </Col>
                      <Col xs={24} md={12}>
                        {agencyType != -1 ? (
                          <Form.Item
                            name="agency_name"
                            required
                            rules={[
                              {
                                required: true,
                                message: "Agency Name is required",
                              },
                              // { min: 5, message: 'At least 5 characters required' },
                            ]}
                          >
                            <Select
                              placeholder="Agency Name"
                              onChange={(value) => {
                                form.setFieldValue("agency_name", value);
                                form.setFieldValue("agency_id", value);
                                setAgencyName(value);
                              }}
                              className="input-width2"
                              style={{ textAlign: "left" }}
                              options={agencies}
                              value={agencyName}
                            />
                          </Form.Item>
                        ) : null}
                        {agencyType == -1 ? (
                          <Form.Item
                            name="agency_name"
                            required
                            rules={[
                              {
                                required: true,
                                message: "Agency Name is required",
                              },
                              {
                                pattern: /^(?!\s)[a-zA-Z\s]+$/,

                                message: "Please enter a valid name",
                              },
                              { min: 5, message: 'At least 5 characters required' },
                            ]}
                          >
                            <Input
                              type="text"
                              placeholder="Agency Name"
                              className="input-width2"
                              suffix={
                                <BankOutlined style={{ color: "#D7D3D5" }} />
                              }
                              maxLength={100}
                            />
                          </Form.Item>
                        ) : null}
                      </Col>
                      <Col xs={24} md={12}>
                        <Form.Item
                          name="designation"
                          required
                          rules={[
                            {
                              required: true,
                              message: "Designation is required",
                            },
                            {
                              pattern: /^(?!\s)[a-zA-Z\s]+$/,

                              message: "Please enter a valid designation",
                            },
                          ]}
                        >
                          <Input
                            type="text"
                            placeholder="Designation"
                            className="input-width2"
                            suffix={
                              <IdcardOutlined style={{ color: "#D7D3D5" }} />
                            }
                            maxLength={100}
                          />
                        </Form.Item>
                      </Col>

                      {agencyType === 1 ? (
                        <Col xs={24} md={12}>
                          <Form.Item
                            name="user_level"
                            required
                            rules={[
                              {
                                required: true,
                                message: "User Level is required",
                              },
                            ]}
                          >
                            <Select
                              placeholder="User Level"
                              onChange={(value) => {
                                form.setFieldValue("user_level", value);
                              }}
                              className="input-width2"
                              style={{ textAlign: "left" }}
                              options={[
                                { value: "5", label: "Head of Agency" },
                                { value: "4", label: "Technical User" },
                              ]}
                            />
                          </Form.Item>
                        </Col>
                      ) : null}
                      <Col xs={24} md={12}>
                        <Form.Item
                          name="email"
                          required
                          rules={[

                            {
                              required: true,
                              message: "Email is required",
                            },
                            {
                              pattern: /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/,
                              message: 'Invalid Email',
                            },
                            // {
                            //   pattern: /^[ ]+$/,
                            //   message: "Invalid Email",
                            // }

                          ]}
                        >
                          <Input
                            type="text"
                            placeholder="Email"
                            className="input-width2"
                            suffix={<MailOutlined style={{ color: "#D7D3D5" }} />}
                            maxLength={100}
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={24} md={12}>
                        <Form.Item
                          name="mobile"
                          rules={[
                            {
                              required: true,
                              message: "Mobile Number is required",
                            },
                            {
                              pattern: /^\d+$/,
                              message: "Invalid Country Code",
                            },
                            {
                              max: 15,
                              message: "Maximum 15 digits allowed",
                            },
                            ({ getFieldValue }) => ({
                              validator(_, value) {
                                console.log("value", value);
                                if (value.startsWith("94")) {
                                  if (value.length === 11) {
                                    if (value[2] != "7") {
                                      return Promise.reject(
                                        new Error(
                                          "Invalid Mobile Number. Must be +947XXXXXXX format."
                                        )
                                      );
                                    } else {
                                      return Promise.resolve();
                                    }

                                  } else {
                                    return Promise.reject(
                                      new Error(
                                        "Invalid Mobile Number. Must be +94XXXXXXXX format."
                                      )
                                    );
                                  }


                                }

                                console.log(value, value.length)

                                if (value.startsWith("07")) {
                                  if (value.length === 10) {
                                    return Promise.resolve();


                                  } else {
                                    return Promise.reject(
                                      new Error(
                                        "Invalid Mobile Number. Must be 07XXXXXXX format."
                                      )
                                    );
                                  }

                                }



                                return Promise.resolve();
                              },
                            }),
                          ]}
                        >
                          <PhoneInput
                            disableCountryGuess={true}
                            prefix={true}
                            country={"lk"}
                            value={mobile}
                            onChange={setMobile}
                            placeholder="Mobile Number"
                            // inputStyle={{ width: "20.3vw" }}
                            // className="select-custom-pn2"
                            className="phone-input"
                            inputProps={{
                              placeholder: "Official Mobile Number",
                            }}
                            disableCountryCode={true}
                            disableInitialCountryGuess={false}
                            style={{
                              backgroundColor: "",
                              borderRadius: "5px",
                            }}
                          />
                        </Form.Item>
                      </Col>

                      <Col xs={24} md={12}>
                        <Form.Item
                          name="phone"
                          rules={[
                            {
                              pattern: /^\d+$/,
                              message: "Invalid Country Code",
                            },
                            {
                              max: 15,
                              message: "Maximum 15 digits allowed",
                            },
                            ({ getFieldValue }) => ({
                              validator(_, value) {
                                console.log("value", value);
                                if (value.startsWith("94")) {
                                  if (value.length === 11) {


                                  } else {
                                    return Promise.reject(
                                      new Error(
                                        "Invalid landline Number. Must be +94XXXXXXXXX format."
                                      )
                                    );
                                  }


                                }

                                if (value.startsWith("07")) {
                                  if (value.length === 10) {
                                    return Promise.resolve();


                                  } else {
                                    return Promise.reject(
                                      new Error(
                                        "Invalid Mobile Number. Must be 07XXXXXXX format."
                                      )
                                    );
                                  }

                                }
                                return Promise.resolve();
                              },
                            }),
                          ]}
                        >
                          <PhoneInput
                            disableCountryGuess={true}
                            country={"lk"}
                            value={officialMobile}
                            onChange={setOfficialMobile}
                            placeholder="Landline Number"
                            // className="phone-input"
                            // inputStyle={{ width: "20.3vw" }}
                            // className="select-custom-pn2"
                            inputProps={{
                              placeholder: "Landline Number",
                            }}
                            disableCountryCode={true}
                            disableInitialCountryGuess={false}
                            style={{
                              backgroundColor: "",
                              borderRadius: "5px",
                            }}
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={24}>
                        <Form.Item className="input-width2">
                          <Checkbox onChange={onChange}>
                            <span style={{ fontSize: 15 }}>
                              I have read and accept the Terms and Conditions and Privacy Policy.
                            </span>
                          </Checkbox>
                        </Form.Item>
                      </Col>
                      <Col xs={24}>

                        <Button
                          className="input-width2"
                          style={{
                            borderRadius: 10,
                          }}
                          disabled={checked ? false : true}
                          type="primary"
                          htmlType="submit"
                          loading={pending}
                        >
                          <b>Create Account</b>
                        </Button>
                        {!pending ? (
                          <div style={{ marginTop: 10 }}>
                            <a href="/login" style={{ textAlign: "center" }}>
                              <b>Already have an account?</b>
                            </a>
                          </div>
                        ) : null}
                      </Col>


                    </Row>

                  </div>


                </Form>


              </div>
            </div>
          </Col>
        </Row>
      </Card >
      {/* <Card className='no-padding2'
        style={{
          width: '37vw',
          margin: '4px 0px 12px -350px',
          ,
          boxShadow: 'rgba(0, 0, 0, 0.3) -5px 5px 4px'
        }}>
        <Space direction='vertical' className='signup-width' style={{ paddingTop: "10px", width: "100%" }}>
          <Title level={2} style={{ textAlign: "center" }}>Register</Title>
          {!submitted ? <div style={{ textAlign: "center", margin: "-20px 0px 10px 0px" }}><Text type="secondary">Create Your NTFC Account</Text></div> : null}
          <Form
            form={form}
            layout="vertical"
            initialValues={{
              requiredMarkValue: requiredMark,
            }}
            onValuesChange={onRequiredTypeChange}
            requiredMark={requiredMark}
            onFinish={onFinish}
          >
            <Space>
              <Form.Item name="title" required
                rules={[
                  {
                    required: true,
                    message: 'Title is required',
                  }
                ]}>
                <div className="select-custom2">
                  <Select
                    placeholder="Title"
                   
                    style={{ width: '6vw' }}
                    onChange={(value) => form.setFieldValue('title', value)}
                    options={
                      [
                        { value: 'Mr.', label: 'Mr.' },
                        { value: 'Mrs.', label: 'Mrs.' },
                        { value: 'Miss', label: 'Miss' },
                        { value: 'Dr.', label: 'Dr.' }
                      ]
                    }

                  />
                </div>
              </Form.Item>
              <Form.Item name="first_name"
                required rules={[
                  {
                    required: true,
                    message: <p style={{ marginLeft: "12px" }}>First name is required</p>,
                  },
                  {
                    pattern: /^[a-zA-Z]+$/,
                    message: <p style={{ marginLeft: "12px" }}>Please enter a valid first name with alphabetical characters</p>,
                  }
                ]}>
                <Input type='text' placeholder="First Name" style={{  width: "26vw" }}  suffix={<UserOutlined style={{ color: "#D7D3D5" }} />} />
              </Form.Item>
            </Space>
            <Space>
              <Form.Item name="last_name" required
                rules={[
                  {
                    required: true,
                    message: <p style={{ marginLeft: "12px" }}>Last Name is required</p>,
                  },
                  {
                    pattern: /^[a-zA-Z]+$/,
                    message: <p style={{ marginLeft: "12px" }}>Please enter a valid last name with alphabetical characters</p>,
                  }
                ]}>
                <Input type='text' placeholder="Last Name" style={{  width: "20.3vw" }}  suffix={<UserSwitchOutlined style={{ color: "#D7D3D5" }} />} />
              </Form.Item>
            </Space>
            <Space>
              <Form.Item name='agency_type' required>
                <div className="select-custom2">
                  <Select
                    placeholder="Select Agency"
                   
                    style={{ width: '20.3vw' }}
                    onChange={(value) => { updateAgencyType(value); form.setFieldValue('agency_type', value); }}
                    // defaultValue={4}
                    options={
                      [
                        { value: 1, label: 'Government Sector' },
                        { value: 2, label: 'Private Sector' },
                        { value: 3, label: 'Donor' },
                        { value: 4, label: 'Other' }
                      ]
                    }
                  />
                </div>
              </Form.Item>
            </Space>
            <Space>
              {agencyType !== 4 ? <Form.Item name="agency_name" required
                rules={[{
                  required: true,
                  message: <p style={{ marginLeft: "12px" }}>Agency Name is required</p>,
                }]}>
                <div className="select-custom2">
                  <Select
                   
                    placeholder="Agency Name"
                    onChange={(value) => { form.setFieldValue('agency_name', value); form.setFieldValue('agency_id', value); setAgencyName(value) }}
                    style={{ width: '20.3vw' }}
                    options={agencies}
                    value={agencyName}
                  />
                </div>
              </Form.Item> : null}
              {agencyType === 4 ? <Form.Item name="agency_name" required
                rules={[{
                  required: true,
                  message: <p style={{ marginLeft: "12px" }}>Agency Name is required</p>,
                }]}>
                <Input type='text' placeholder="Agency Name" style={{  width: "20.3vw" }}  suffix={<BankOutlined style={{ color: "#D7D3D5" }} />} />
              </Form.Item> : null}
            </Space>
            <Space>
              <Form.Item name="designation" required
                rules={[{
                  required: true,
                  message: <p style={{ marginLeft: "12px" }}>Designation is required</p>,
                }]}>
                <Input type='text' placeholder="Designation" style={{  width: "20.3vw" }}  suffix={<IdcardOutlined style={{ color: "#D7D3D5" }} />} />
              </Form.Item>
            </Space>
            <Space>
              {agencyType === 1 ? <Form.Item name="user_level" required
                rules={[{
                  required: true,
                  message: <p style={{ marginLeft: "12px" }}>User Level is required</p>,
                }]}>
                <div className="select-custom2">
                  <Select
                    placeholder="User Level"
                    onChange={(value) => { form.setFieldValue('user_level', value); }}
                    
                    style={{ width: '20.3vw' }}
                    options={
                      [
                        { value: '5', label: 'Head of Agency' },
                        { value: '4', label: 'Technical User' },

                      ]
                    }
                  />
                </div>
              </Form.Item> : null}
            </Space>
            <Space>
              <Form.Item name="email" required
                rules={[{
                  type: 'email',
                  message: <p style={{ marginLeft: "12px" }}>Invalid Email</p>,
                },
                {
                  required: true,
                  message: <p style={{ marginLeft: "12px" }}>Email is required</p>,
                },]}>
                <Input type='email' placeholder="Email" style={{  width: "20.3vw" }}  suffix={<MailOutlined style={{ color: "#D7D3D5" }} />} />
              </Form.Item>
            </Space>
            <Space>
              <Form.Item name="mobile" required
                rules={[
                  {
                    required: true,
                    message: <p style={{ marginLeft: "12px" }}>Mobile Number is required</p>,
                  },
                  {
                    pattern: /^(?!0)\d+$/,
                    message: <p style={{ marginLeft: "12px" }}>Invalid Country Code</p>,
                  }
                ]}
              >
                <PhoneInput
                  country={'lk'}
                  value={mobile}
                  onChange={setMobile}
                  placeholder="Mobile Number"
                  inputStyle={{ width: "20.3vw",  }}
                  className="select-custom-p2"
                  inputProps={{
                    style: { width: '20.3vw',  },
                  }}
                  disableInitialCountryGuess={false}
                />
              </Form.Item>
              <br />
            </Space>
            <Space>
              <Form.Item name="phone"
                rules={[
                  {
                    pattern: /^(?!0)\d+$/,
                    message: <p style={{ marginLeft: "12px" }}>Invalid Country Code</p>,
                  }
                ]}
              >
                <PhoneInput
                  country={'lk'}
                  value={officialMobile}
                  onChange={setOfficialMobile}
                  placeholder="Official Mobile Number"
                  inputStyle={{ width: "20.3vw",  }}
                  className="select-custom-pn2"
                  inputProps={{
                    placeholder: 'Official Mobile Number',
                    style: { width: '20.3vw',  },
                  }}
                  disableInitialCountryGuess={false}
                />
              </Form.Item>
            </Space>
            <Form.Item>
              <Checkbox onChange={onChange}>I have read the privacy policy and I have agreed to the terms and conditions.</Checkbox>
            </Form.Item>
            <Form.Item>
              <Space>
                <Button disabled={checked ? false : true} type='primary' htmlType="submit" loading={pending} shape="round" ><b>Create Account</b></Button>
                {!pending ? <a href='/login' style={{ marginLeft: "110px" }}><b>Already have an account?</b></a> : null}
              </Space>
            </Form.Item>
          </Form>
        </Space>

      </Card> */}
      <Modal
        closable={false}
        centered
        title="Thank you for registering with the NTFC Monitoring Tool"
        open={dialog}
        footer={
          [
            <Button
              key="submit"
              type="primary"
              onClick={() => {
                window.location.href = "/login";
              }}
            >
              Ok
            </Button>,
          ]}
      // onCancel={() => { confirmationDialog(false) }}
      // okText="Ok"
      // cancelText="No"
      >
        <div style={{ display: "flex", flexDirection: "column" }}>
          <div>
            You will receive an email verification from the NTFC Monitoring Tool.
          </div>
          <div>
            Please go to your inbox and kindly verify it and await for your
            account confirmation mail.
          </div>
        </div>
      </Modal>

      <ToastContainer />
    </div>
  );
}

export default Page;
