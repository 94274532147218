import { DatePicker, Modal, List, Typography, Input, Space, Button, message, Tooltip, Form, Select, Row, Col, Table, ConfigProvider, Divider, Card, Spin, FloatButton } from 'antd';
import { SettingOutlined, ToolOutlined, ControlOutlined, DeploymentUnitOutlined, BookOutlined, BarsOutlined, BoxPlotOutlined, BankOutlined, ContainerOutlined, AimOutlined, HeatMapOutlined, BlockOutlined, PlusOutlined, CloseOutlined, EditOutlined, EyeOutlined } from '@ant-design/icons';
import { useEffect, useState } from 'react';
import { addAction, getActionsList, updateAction, getArticles, getMeasures, getMeasuresListByArticle, getNextActionNo } from '../../api';
import TextArea from 'antd/es/input/TextArea';
import moment from 'moment';
import PageLayouts from '../../Components/PageLayouts';
import CustomTable from '../../Components/Table';

import { ToastContainer, toast, Zoom } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import FormLayouts from '../DonorsNew/Components/FormLayouts';
import CardTitle from '../../Components/ViewDialogCardTitle/CardTitle';
import { Fragment } from 'react';
import FullSearch from '../../Components/FullSearch';



const AddActions = ({ article }) => {
    const [items, setItems] = useState([]);

    const [data, setData] = useState([]);
    const [filtered_data, setFilteredData] = useState([]);
    const [full, setFull] = useState([]);
    const [articles, setArticles] = useState([]);
    const [measures, setMeasures] = useState([]);
    const [defDate, setDefDate] = useState();
    const [selectedArticle, setSelectedArticle] = useState(null);
    const [selectedMeasure, setSelectedMeasure] = useState(null);
    const [messagePopup, setMessagePopup] = useState(false);
    const [viewOnly, setViewOnly] = useState(false);
    const [actionText, setAction] = useState("");
    const [remarks, setRemarks] = useState("");
    const [currentDefDate, setCurrentDefDate] = useState(null)

    const [currentRecord, setCurrentRecord] = useState()
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [loading, setLoading] = useState(false)
    const [btnDisable, setDisable] = useState(true)
    const [isChair, setChair] = useState(false)
    const showModal = () => {
        setIsModalOpen(true);
    };
    const handleOk = () => {
        setIsModalOpen(false);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const [selected, setSelected] = useState(null);

    const [form] = Form.useForm();
    const isFieldsTouched = () => {

        return form.isFieldsTouched();
    };
    const columns = [
        {
            title: 'Action No',
            dataIndex: 'action_no',
            key: 'action_no',
            width: 100,
            render: (_, record) => (

                record ?
                    <div>{record.article_no + "." + record.measure_no + "." + record.action_no}</div>
                    : null
            ),

        },

        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            width: isChair ? 950 : 500

        },
        // {
        //     title: 'Summary',
        //     dataIndex: 'summary',
        //     key: 'summary',

        // },
        // {
        //     title: 'Definitive Date',
        //     dataIndex: 'def_date',
        //     key: 'def_date',
        //     render: (_, record) => (
        //         record ?
        //             <div>{record ? moment(record.def_date).format('DD/MM/YYYY') : "-"}</div>

        //             : null
        //     ),

        // },
        {
            title: 'Weightage',
            dataIndex: 'weightage',
            key: 'weightage',
            width: 120,
            render: (_, record) => (

                record ?
                    <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>{record.weightage}</div>
                    : null
            ),

        },

        // {
        //     title: 'Remarks',
        //     dataIndex: 'remarks',
        //     key: 'remarks',

        // },

        {
            title: 'Action ',
            key: 'action',
            render: (_, record) => (
                record && !record.moreData ?
                    <div>
                        {!isChair &&
                            <Tooltip title="Update Article">
                                <Button type="secondary" size='small'
                                    onClick={() => {

                                        if (selected !== record) {
                                            form.resetFields();
                                            setAction("")
                                            setRemarks("")
                                        }
                                        console.log("article", record)
                                        setSelectedArticle(record.article_id)
                                        setMeasurestoAutocomplete(record.article_id)
                                        setViewOnly(false);
                                        setSelected(record)
                                        setDisable(true)

                                    }} shape="circle" icon={<EditOutlined style={{ color: '#946e6e' }} />} />
                            </Tooltip>
                        }

                        <Tooltip title="View Article" >
                            <Button type="secondary" size='small' onClick={() => {
                                setSelected(null)
                                setSelectedMeasure(null)
                                setSelectedArticle(null)
                                form.resetFields();
                                setAction("")
                                setRemarks("")
                                setCurrentRecord(record)
                                showModal(true)
                                console.log("Record", record)
                            }} shape="circle" icon={<EyeOutlined style={{ color: 'darkgreen' }} />} />
                        </Tooltip>
                    </div>
                    : null
            ),
        },
    ];


    useEffect(() => {
        if (window.localStorage.getItem('ntfc_level') == 6) {
            setChair(true)
        } else {
            setChair(false)
        }
        refreshActions()
    }, []);
    const refreshActions = () => {
        setDisable(true)
        setLoading(true)
        getActionsList().then((out) => {
            const items = out.data.out.sort((a, b) => (a.article_no - b.article_no || a.measure_no - b.measure_no || a.action_no - b.action_no));
            const data = items.map((item) => { return { ...item, action_formatted: item.article_no + "." + item.measure_no + "." + item.action_no } });
            setItems(data);
            setLoading(false)
            getArticles().then((art) => {
                setArticles(art.data.out.sort((a, b) => (a.article_no - b.article_no)));
            })

        });

    }



    const filterData = (val) => {


        setFilteredData(items.filter((element) => element.article_id == val))



    }


    const clearForm = () => {
        setSelectedArticle(null)
        setSelectedMeasure(null)
        setAction("")
        setRemarks("")
        setDisable(true)
    }



    const onFinish = (values) => {


        values.def_date = defDate;

        let prev_action_no = values.action_no;
        let repeated = false;

        if (selected) {
            items.forEach(item => {
                if (item.article_id == values.article_id && item.measure_id == values.measure_id) {
                    if (item.measure_no == prev_action_no && item.action_no != selected.action_no) {
                        repeated = true;
                    }
                }
            })
        } else {
            items.forEach(item => {
                if (item.article_id == values.article_id && item.measure_id == values.measure_id) {
                    if (item.action_no == prev_action_no) {
                        repeated = true;
                    }
                }
            })
        }



        if (!selected) {
            if (repeated) {
                toast.error("Action Number Already Exists", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });

                return;
            }
            addAction(values).then((out) => {
                if (out.data.status === 200) {
                    form.resetFields();
                    setAction("")
                    setRemarks("")
                    refreshActions();
                    toast.success("Action Created", {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    });
                    clearForm()


                } else {
                    toast.error(out.data.message, {

                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    });

                }
            }).catch((err) => {
                console.log(err);
                toast.error("Error", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
            })
        } else {
            values.id = selected.id;
            if (isFieldsTouched()) {
                updateAction(values).then((out) => {
                    if (out.data.status === 200) {
                        form.resetFields();
                        setAction("")
                        setRemarks("")
                        refreshActions();
                        toast.success("Successfully Updated the Action", {

                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "colored",

                        });
                        setSelected(null);
                        clearForm()
                    } else {
                        toast.error("Action Was Not Updated", {

                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "colored",
                        });

                    }
                }).catch((err) => {
                    console.log(err);
                    toast.error("Error", {

                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    });

                })
            } else {
                toast.warning("There are no changes to update the action", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                })
                form.resetFields();
                setAction("")
                setRemarks("")
                refreshActions();
            }

        }
    };
    const onFinishFailed = (errorInfo) => {
        if (!selected) {
            toast.error("Action creation unsuccessful", {

                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });


        } else {
            toast.error("Action update unsuccessful", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });

        }
    };

    const onDateChange = (date, dateString) => {
        console.log(dateString)
        console.log(date)
        setDefDate(date);
    };




    useEffect(() => { setData(items); setFilteredData(items); setFull(items); }, [items]);

    useEffect(() => {
        if (selected) {
            form.resetFields();
            console.log("selected", selected)
            setMeasurestoAutocomplete(selected.measure_id)
            let def_date = `${new Date().getFullYear()}-${new Date().getMonth() + 1}-${new Date().getDate()}`;
            if (selected.def_date) {
                def_date = moment(selected.def_date).format("DD/MM/YYYY");
            }
            form.setFieldValue('article_id', selected.article_id)

            form.setFieldValue('measure_id', selected.measure_id)

            form.setFieldValue('action_no', selected.action_no)
            form.setFieldValue('def_date', selected.def_date)
            form.setFieldValue('summary', selected.summary)
            form.setFieldValue('action', selected.action)

            setAction(selected.action)
            setDefDate(def_date)
            form.setFieldValue('category', selected.category)
            form.setFieldValue('description', selected.description)
            form.setFieldValue('weightage', selected.weightage)
            form.setFieldValue('remarks', selected.remarks)
            setRemarks(selected.remarks)
        } else {
            form.resetFields();
            setAction("")
            setRemarks("")

        }
        // eslint-disable-next-line
    }, [selected]);

    const setMeasurestoAutocomplete = (articleNo) => {

        getMeasuresListByArticle(articleNo, {}).then((mes) => {
            const filteredArticle = ((articles.filter((itm) => itm.value === articleNo))[0])?.article_no;
            if (mes.status == 200) {
                setMeasures(mes.data.out.map((el) => {
                    return {
                        value: el.id,
                        label: `${filteredArticle}.${el.measure_no} - ${el.summary}`
                    }
                }))


            }
            if (selected) {
                setSelectedMeasure(selected.measure_id)
            }

        })
    }

    useEffect(() => {
        console.log("sleceted articel", selectedArticle)
        if (selectedArticle && selectedArticle != "") {
            setSelectedMeasure(null);
            filterData(selectedArticle);

            setMeasurestoAutocomplete(selectedArticle)
        }
        // eslint-disable-next-line
    }, [selectedArticle]);


    useEffect(() => {
        if (!selectedMeasure) {
            form.setFieldValue('action_no', '')
        } else {
            if (!selected) {
                form.resetFields(["action_no"]);

                setFilteredData(filtered_data.filter((element) => element.measure_id == selectedMeasure))
                getNextActionNo(selectedMeasure).then((out) => {
                    if (out.data && out.data.out.length > 0) {
                        const id = out.data.out[0].id;
                        if (id) {
                            form.setFieldValue('action_no', id.toString())
                        } else {
                            form.setFieldValue('action_no', '1')
                        }
                    } else {
                        form.setFieldValue('action_no', '')
                    }
                    console.log("Mes Out", out);
                })
            } else {
                console.log("Selected", selected)

                form.setFieldValue('action_no', selected.action_no)
            }

        }
    }, [selectedMeasure])

    /*   useEffect(() => {
          if (!selectedMeasure) {
              form.setFieldValue('action_no', '')
          } else {
              getNextActionNo(selectedMeasure).then((out) => {
                  if (out.data && out.data.out.length > 0) {
                      const id = out.data.out[0].id;
                      if (id) {
                          form.setFieldValue('action_no', id.toString())
                      } else {
                          form.setFieldValue('action_no', '1')
                      }
                  } else {
                      form.setFieldValue('action_no', '')
                  }
                  console.log("Mes Out", out);
              })
          }
      }, [selectedMeasure])
   */
    return <Fragment>


        <FormLayouts
            title="Actions"
            formTitle={"Add Action"}
            formSide={
                !isChair ?
                    <Form
                        form={form}
                        layout="vertical"
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                        scrollToFirstError
                        onValuesChange={(changedValues, allValues) => {
                            if (changedValues) {
                                setDisable(false)
                            } else {
                                setDisable(true)
                            }

                        }}
                    >
                        <Row gutter={16}>
                            <Col className="gutter-row" span={24}>
                                <Form.Item label="Article" name="article_id" required
                                    rules={[{
                                        required: true,
                                        message: <p style={{ marginLeft: "12px" }}>Article is required</p>,
                                    }]}
                                >
                                    <Select
                                        placeholder="Select Article"
                                        options={articles}
                                        onChange={(val) => {
                                            form.resetFields(["measure_id"])
                                            setSelectedArticle(val)


                                        }}
                                        disabled={viewOnly ? true : false}
                                        style={{ backgroundColor: viewOnly ? "#dbdbdb" : "", borderRadius: "5px" }}
                                    />
                                </Form.Item>
                            </Col>
                            <Col className="gutter-row" span={24}>
                                <Form.Item label="Measure" name="measure_id" required
                                    rules={[{
                                        required: true,
                                        message: <p style={{ marginLeft: "12px" }}>Measure is required</p>,
                                    }]}
                                >
                                    <Select
                                        placeholder="Select Measure"
                                        options={measures}
                                        onChange={(val) => {
                                            console.log("selceted------->>", val)
                                            setSelectedMeasure(val)
                                        }}
                                        disabled={viewOnly ? true : false}
                                        style={{ backgroundColor: viewOnly ? "#dbdbdb" : "", borderRadius: "5px" }}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col className="gutter-row" span={12}>
                                <Form.Item label="Action No" name="action_no" required
                                    rules={[{
                                        required: true,
                                        message: <p style={{ marginLeft: "12px" }}>Action No is required</p>,
                                    },
                                    {
                                        pattern: /^[1-9]\d{0,2}$/,
                                        message: 'Action No must be a positive number with a maximum of three digits',
                                    },]}
                                >
                                    <Input maxLength={3} type='number' placeholder="Action No" disabled={viewOnly ? true : false}
                                        style={{ backgroundColor: viewOnly ? "#dbdbdb" : "", borderRadius: "5px" }} />
                                </Form.Item>
                            </Col>
                            <Col className="gutter-row" span={24}>
                                <Form.Item label="Action Summary" name="summary" required
                                    rules={[{
                                        required: true,
                                        message: <p style={{ marginLeft: "12px" }}>Action Summary is required</p>,
                                    },
                                    {
                                        pattern: /^\S.*$/,
                                        message: (
                                            <p style={{ marginLeft: "12px" }}>
                                                Invalid action summary
                                            </p>
                                        ),
                                    },


                                    ]}
                                >
                                    <Input showCount maxLength={40} type='text' placeholder="Action" disabled={viewOnly ? true : false}
                                        style={{ backgroundColor: viewOnly ? "#dbdbdb" : "", borderRadius: "5px" }} />



                                </Form.Item>
                            </Col>
                            <Col className="gutter-row" span={24}>
                                <Form.Item label="Action" name="action"
                                    rules={[{
                                        required: true,
                                        message: <p style={{ marginLeft: "12px", marginTop: -33 }}>Action is required</p>,
                                    },
                                    {
                                        pattern: /^\S.*$/,
                                        message: (
                                            <p style={{ marginLeft: "12px" }}>
                                                Invalid action
                                            </p>
                                        ),
                                    },
                                    ]}
                                >
                                    <TextArea
                                        // value={actionText} 
                                        showCount
                                        maxLength={500} rows={4} placeholder='Action' disabled={viewOnly ? true : false}
                                        style={{ backgroundColor: viewOnly ? "#dbdbdb" : "", borderRadius: "5px" }}
                                    // onChange={(e) => {

                                    //     setAction(e.target.value);
                                    //     form.setFieldValue('action', e.target.value)
                                    // }
                                    // }


                                    />
                                    {/* <div>
                                    <p style={{ float: "right", color: "black" }}>{actionText.length}/250</p>
                                </div> */}
                                </Form.Item>
                            </Col>
                            <Col className="gutter-row" span={24}>
                                <Form.Item label="Weightage" name="weightage" required
                                    rules={[{
                                        required: true,
                                        message: <p style={{ marginLeft: "12px" }}>Weightage is required</p>,
                                    },
                                    {
                                        pattern: /^(10|[1-9])$/,
                                        message: <p style={{ marginLeft: "12px" }}>Weightage added should be between 1-10</p>
                                    }
                                    ]}
                                >
                                    <Input type='number' placeholder="Weightage" disabled={viewOnly ? true : false}
                                        style={{ backgroundColor: viewOnly ? "#dbdbdb" : "", borderRadius: "5px" }} />
                                </Form.Item>

                            </Col>
                            <Col className='gutter-row' span={24}>
                                <Form.Item label="Remarks" name="remarks" >
                                    <TextArea showCount value={remarks} maxLength={500} rows={4} placeholder='Remarks' disabled={viewOnly ? true : false}
                                        style={{ backgroundColor: viewOnly ? "#dbdbdb" : "", borderRadius: "5px" }}
                                    // onChange={(e) => {
                                    //     setRemarks(e.target.value)
                                    //     form.setFieldValue('remarks', e.target.value)
                                    // }
                                    // }
                                    />
                                    {/* <div>
                                    <p style={{ float: "right", color: "white" }}>{remarks?.length}/200</p>
                                </div> */}
                                </Form.Item>
                            </Col>
                        </Row>


                        {viewOnly ?
                            <Space>
                                <div style={{ display: "flex", justifyContent: "flex-end", width: "100%" }}>
                                    <Button size="large" type='primary' danger htmlType="submit" icon={<CloseOutlined />} onClick={() => { setSelected(null); setViewOnly(false) }}>
                                        Cancel
                                    </Button>
                                </div>
                            </Space>
                            :
                            <div style={{ display: "flex", justifyContent: "flex-end", width: "100%", marginTop: 10 }}>
                                {!selected ? <Button size='large' type='primary' htmlType="submit" icon={<PlusOutlined />}
                                    disabled={window.localStorage.getItem('view_only') == 1}>
                                    Add Action
                                </Button> :
                                    <Space style={{ marginTop: 10 }}>
                                        <Form.Item>
                                            <Button size='large' type='primary' danger htmlType="submit" icon={<CloseOutlined />} onClick={() => {
                                                setDisable(true)
                                                setSelected(null)
                                            }}>
                                                Cancel
                                            </Button>
                                        </Form.Item>
                                        <Form.Item>
                                            <Button
                                                disabled={window.localStorage.getItem('view_only') == 1 || btnDisable}
                                                size='large' type='primary' htmlType="submit" icon={<EditOutlined />} >
                                                Update
                                            </Button>
                                        </Form.Item>


                                        <Modal title="Basic Modal" open={messagePopup} onCancel={() => setMessagePopup(false)} footer={null} closable>
                                            <p>Some contents...</p>
                                            <p>Some contents...</p>
                                            <p>Some contents...</p>
                                        </Modal>
                                    </Space>}
                            </div>
                        }
                    </Form> : null


            }
            table={
                < >
                    {!loading ? <div>
                        <FullSearch full={full} setFiltered={setFilteredData} params={["action", "action_formatted", "weightage"]} />
                        <Table

                            scroll={{
                                y: 400,
                            }}
                            className='custom-table-header custom-table2'
                            columns={columns} dataSource={filtered_data}
                            pagination={{
                                pageSize: 4,
                                // total: filtered_data.length,
                                showSizeChanger: false,
                            }} /> </div> :
                        <Spin style={{ top: "40%", width: "100%", height: "100%" }}>

                        </Spin>
                    }

                </>



            }
        />

        <Modal
            width={750}
            footer={false}
            onCancel={handleCancel}
            title={
                <div div ><Typography.Title level={2}>Action Details</Typography.Title>
                    <Divider style={{ background: "#249191" }} />
                </div>}

            open={isModalOpen}
            onOk={handleOk}
            centered >

            <Row gutter={[16, 16]} >


                <Col span={24}>
                    <CardTitle
                        icon={<BlockOutlined />}
                        title={"Article"}
                        content={articles.filter(element => element.value === currentRecord?.article_id)[0]?.article_no + ". " + articles.filter(element => element.value === currentRecord?.article_id)[0]?.article}

                    />

                </Col>
                <Col span={24}>
                    <CardTitle
                        icon={<HeatMapOutlined />}
                        title={"Measure"}
                        content={currentRecord?.article_no + "." + currentRecord?.measure_no + ". " + currentRecord?.measure_name}

                    />

                </Col>
                <Col span={24}>
                    <CardTitle
                        icon={<AimOutlined />}
                        title={"Action"}
                        content={currentRecord?.action_no + ". " + currentRecord?.action}

                    />

                </Col>
                <Col span={24}>
                    <CardTitle
                        icon={<ContainerOutlined />}
                        title={"Summary"}
                        content={currentRecord?.summary}

                    />

                </Col>
                <Col span={24}>
                    <CardTitle
                        icon={<BoxPlotOutlined />}
                        title={"Weightage"}
                        content={currentRecord?.weightage}

                    />

                </Col>

                {currentRecord?.remarks &&
                    <Col span={24}>
                        <CardTitle
                            icon={<BarsOutlined />}
                            title={"Remarks"}
                            content={currentRecord.remarks}

                        />

                    </Col>
                }



            </Row>

        </Modal>

        <Modal
            closable={true}

            className="custom-modal"
            width={950}
            footer={false}
            onCancel={handleCancel}
            title={
                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                    <div>     <Typography className='custom-modal-title' level={2}>Action Details</Typography></div>


                </div>


            } open={isModalOpen} onOk={handleOk} centered>

            <div className='custom-modal-body'>
                <Row gutter={[16, 16]}>
                    <Col span={24}>
                        <Card style={{ background: "#a8cccc4a", borderRadius: 5, padding: 12 }}>
                            <Row gutter={[16, 16]}>
                                {/* <div style={{
                                    marginTop: '-31px',
                                    position: 'absolute',
                                    marginLeft: '10px'
                                }}>
                                    <Typography className='inner-tile-title'>{"Article"}</Typography>
                                </div> */}
                                <Col span={3}>
                                    <div >
                                        <span className='inner-tile-title' style={{ background: "#2652523b", padding: "5px 10px", borderRadius: 8, marginLeft: "-2%" }}>
                                            {"Article" + " " + articles.filter(element => element.value === currentRecord?.article_id)[0]?.article_no}

                                        </span>
                                    </div>

                                </Col>

                                <Col span={21} style={{
                                    paddingLeft: '8px',
                                    paddingRight: '8px',
                                    alignItems: 'center',
                                    display: 'flex'
                                }}>
                                    <Typography className='inner-tile-content' >{articles.filter(element => element.value === currentRecord?.article_id)[0]?.article} </Typography>

                                </Col>

                            </Row>

                        </Card>
                    </Col>
                    <Col span={24}>
                        <Row gutter={16}>

                            <Col span={21} offset={3}>
                                <Card style={{ background: "#a8cccc4a", borderRadius: 5, padding: 12 }}>
                                    <Row gutter={[16, 16]}>
                                        {/* <div style={{
                                            marginTop: '-31px',
                                            position: 'absolute',
                                            marginLeft: '10px'
                                        }}>
                                            <Typography className='inner-tile-title'>{"Measure"}</Typography>
                                        </div> */}
                                        <Col span={4}>
                                            <div >
                                                <span className='inner-tile-title' style={{ background: "#2652523b", padding: "5px 10px", borderRadius: 8, marginLeft: "-2%" }}>
                                                    {"Measure" + " " + currentRecord?.article_no + "." + currentRecord?.measure_no}

                                                </span>
                                            </div>

                                        </Col>
                                        <Col span={20} style={{
                                            paddingLeft: '8px',
                                            paddingRight: '8px',
                                            alignItems: 'center',
                                            display: 'flex'
                                        }}>
                                            <Typography className='inner-tile-content' >{currentRecord?.measure_name} </Typography>

                                        </Col>


                                    </Row>
                                </Card>

                            </Col>

                        </Row>

                    </Col>
                    <Col span={24}>

                        <Row gutter={16}>

                            <Col span={18} offset={6}>
                                <Card style={{ background: "#a8cccc4a", borderRadius: 5, padding: 12 }}>
                                    <Row gutter={[16, 16]}>
                                        {/* <div style={{
                                            marginTop: '-31px',
                                            position: 'absolute',
                                            marginLeft: '10px'
                                        }}>
                                            <Typography className='inner-tile-title'>{"Action"}</Typography>
                                        </div> */}
                                        <Col span={6}>
                                            <div >
                                                <span className='inner-tile-title' style={{ background: "#2652523b", padding: "5px 10px", borderRadius: 8, marginLeft: "-2%" }}>
                                                    {"Action" + " " + currentRecord?.article_no + "." + currentRecord?.measure_no + "." + currentRecord?.action_no}


                                                </span>
                                            </div>

                                        </Col>
                                        <Col span={18} style={{
                                            paddingLeft: '8px',
                                            paddingRight: '8px',
                                            alignItems: 'center',
                                            display: 'flex'
                                        }}>
                                            <Typography className='inner-tile-content' >    {currentRecord?.action} </Typography>

                                        </Col>
                                        <Col span={24}>
                                            <CardTitle
                                                icon={<ContainerOutlined />}
                                                title={"Summary"}
                                                content={currentRecord?.summary}

                                            />

                                        </Col>
                                        <Col span={24}>
                                            <CardTitle
                                                icon={<BoxPlotOutlined />}
                                                title={"Weightage"}
                                                content={currentRecord?.weightage}

                                            />

                                        </Col>

                                        {currentRecord?.remarks &&
                                            <Col span={24}>
                                                <CardTitle
                                                    icon={<BarsOutlined />}
                                                    title={"Remarks"}
                                                    content={currentRecord.remarks}

                                                />

                                            </Col>
                                        }


                                    </Row>
                                </Card>

                            </Col>

                        </Row>

                    </Col>


                </Row>


            </div>



        </Modal>

        {/* <FloatButton.Group

            trigger="click"
            type="primary"
            style={{
                right: 20,
                fontSize: 0, lineHeight: 0
            }}
            icon={<SettingOutlined />}


        >
            <Tooltip color="cyan" placement="left" title="Setup Action Plan">
                <FloatButton onClick={() => {
                    window.location.href = `/actionplan`
                }} icon={<ToolOutlined />} />
            </Tooltip>


            <Tooltip color="cyan" placement="left" title="Setup Actions">
                <FloatButton onClick={() => {
                    window.location.href = `/add-actions`
                }} icon={<ControlOutlined />} />
            </Tooltip>
            <Tooltip color="cyan" placement="left" title="Setup Measures">
                <FloatButton onClick={() => {
                    window.location.href = `/add-measures`
                }} icon={<DeploymentUnitOutlined />} />
            </Tooltip>
            <Tooltip color="cyan" placement="left" title="Setup Articles">
                <FloatButton onClick={() => {
                    window.location.href = `/add-articles`
                }} icon={<BookOutlined />} />
            </Tooltip>
            <Tooltip color="cyan" placement="left" title="Setup Agencies">
                <FloatButton onClick={() => window.location.href = `/add-agencies`} icon={<BankOutlined />} />
            </Tooltip>

        </FloatButton.Group> */}



        <ToastContainer />

    </Fragment>
}

export default AddActions;