import { GetCapacityBuildingCount, GetTechincalAssistanceCount, getUserById } from "../../api"



export const getCounts = async function () {

    const techCount = await GetTechincalAssistanceCount()

    const capacityCount = await GetCapacityBuildingCount()


    return { techCount: techCount.data.out, capCount: capacityCount.data.out }
}

export const getUserData = async function () {
    const out = await getUserById(window.localStorage.getItem('user_id'))


    if (out.status === 200) {
        let user = {
            agency: out.data.user.agency_name,
            agency_id: out.data.user.agency_id,
            designation: out.data.user.designation,
            first_name: out.data.user.first_name,
            last_name: out.data.user.last_name,
            title: out.data.user.title,
            uuid: out.data.user.uuid,
            user_level: out.data.user.user_level,
            name: out.data.user.title + " " + out.data.user.first_name + " " + out.data.user.last_name

        }

        return user

    }

}
