import { Button, Result } from "antd";
import React from "react";

function AccessDenied() {
  return (
    <div style={{height:"100vh", width:"100%", display:"flex", justifyContent:"center", alignItems:"center"}}>
      <Result
        status="error"
        title={"Access Denied"}
        subTitle="You do not have enough privileges to access this page."
        extra={
          <Button
            type="primary"
            onClick={() => {
              window.location.href = "/newdashboard";
            }}
          >
            Back to Dashborad
          </Button>
        }
      />
    </div>
  );
}

export default AccessDenied;
