import {
  Button,
  Col,
  ConfigProvider,
  Row,
  Space,
  Table,
  Tag,
  Typography,
  List,
  Avatar,
  Modal,
  Input,
} from "antd";
import VirtualList from "rc-virtual-list";
import { useEffect, useState } from "react";
import TabLayout from "./TabLayout";
import { ToastContainer, toast, Zoom } from "react-toastify";
import {
  DownloadFiles,
  donorRequestAssign,
  getAllDonorInterestInterests,
} from "../../api/index.js";
import { getUserData } from "../Donors/getCounts.js";
import moment from "moment";
import { MainColors, NewColorTheme } from "../../appConst";
import { BiCloudDownload } from "react-icons/bi";

const AgencyInterestsForProject = ({ showMessage, setRefreshCounts, data, setData }) => {
  const [currentData, setCurrentData] = useState({});
  const [user, setUser] = useState({});

  useEffect(() => {
    reloadData();
    getUserDetails();
  }, []);

  const reloadData = () => {
    getAllDonorInterestInterests().then((res) => {
      setData(res.data.out);
    });
  };

  const getUserDetails = async function () {
    const user = await getUserData();
    setUser(user);
  };

  const getStatus = (status) => {
    if (status == 0) {
      return (
        <Tag
          color={"#0492c2"}
          key={status}
          style={{ width: 120, display: "flex", justifyContent: "center" }}
        >
          {"UNPUBLISHED"}
        </Tag>
      );
    } else if (status == 1) {
      return (
        <Tag
          color={"#007613"}
          key={status}
          style={{ width: 120, display: "flex", justifyContent: "center" }}
        >
          {"PUBLISHED"}
        </Tag>
      );
    }
  };

  const columns = [
    {
      title: "Creation Date",
      dataIndex: "created",
      key: "created",
      render: (_, { created }) => (
        <p>{created ? moment(created).format("DD/MM/YYYY") : "-"}</p>
      ),
    },
    {
      title: "Agency Name",
      dataIndex: "created_user",
      key: "created_user",
    },
    {
      title: "Subject",
      dataIndex: "subject",
      key: "subject",
    },
    {
      title: "Measure",
      dataIndex: "measure_name",
      key: "measure_name",
      ellipsis: true,
    },
    {
      title: "Support Type",
      dataIndex: "assistance",
      key: "assistance",
      render: (_, { assistance }) => (
        <Tag
          color={assistance == 0 ? "#f50" : "#2a8c00"}
          key={assistance}
          style={{ width: 150, display: "flex", justifyContent: "center" }}
        >
          {assistance == 0 ? <Tag style={{ display: "flex", justifyContent: "center" }} color={NewColorTheme.primaryColor}>TS/CB</Tag> :
            <Tag style={{ display: "flex", justifyContent: "center" }} color="#08B3D7">OTHER</Tag>}

        </Tag>
      ),
    },
    {
      title: "Tentative Start Date",
      dataIndex: "implementation_from",
      key: "implementation_from",
      render: (_, { implementation_from }) => (
        <p>
          {implementation_from
            ? moment(implementation_from).format("DD/MM/YYYY")
            : "-"}
        </p>
      ),
    },
    {
      title: "Tentative End Date",
      dataIndex: "implementation_to",
      key: "implementation_to",
      render: (_, { implementation_to }) => (
        <p>
          {implementation_to
            ? moment(implementation_to).format("DD/MM/YYYY")
            : "-"}
        </p>
      ),
    },
    // {
    //     title: 'Published Date',
    //     dataIndex: 'published_date',
    //     key: 'published_date',
    //     render: (_, { published_date }) => (
    //         <p>{published_date ? moment(published_date).format("DD/MM/YYYY") : "-"}</p>
    //     ),
    // },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (_, { status }) => getStatus(status),
    },
  ];

  const fetchFile = async (file) => {
    let out = await DownloadFiles(file.token);

    let blob = new Blob([out.data], { type: file.type });
    let url = window.URL.createObjectURL(blob);

    let link = document.createElement("a");
    link.href = url;

    link.download = file.url.split("-::-")[1];

    document.body.appendChild(link);
    link.click();
  };

  const assignDonor = async (item) => {
    let data = {
      id: item.request_id,
      agency_id: item.agency_id,
      user_id: user.uuid,
    };
    console.log("item", item);
    let out = await donorRequestAssign(data);
    console.log("out", out);
    if (out.status === 200) {
      toast.success(out.data.message, {
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progressClassName: "toast-progress-bar",
      });
      reloadData();
    } else {
      toast.error(out.data.message, {
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progressClassName: "toast-progress-bar",
      });
    }
  };

  return (
    <div className="custom-scroll-div tab-layout-div">
      <TabLayout
        title="Request Details"
        formSide={
          <div>
            {currentData.id && (
              <ConfigProvider
                theme={{
                  token: {
                    colorPrimary: "#006666",
                  },
                }}
              >
                <div className="list-container">
                  <Row gutter={[16, 16]}>
                    <Col span={24}>
                      <Row style={{ width: "100%" }}>
                        <Col style={{ width: "30%" }}>
                          <Typography.Title level={5}>Subject</Typography.Title>
                        </Col>
                        <Col style={{ paddingTop: "2px" }}>
                          <Typography.Paragraph style={{ fontWeight: "500" }}>
                            {currentData.subject}
                          </Typography.Paragraph>
                        </Col>
                      </Row>
                    </Col>

                    <Col span={24}>
                      <Row style={{ width: "100%" }}>
                        <Col style={{ width: "30%" }}>
                          <Typography.Title level={5}>Measure</Typography.Title>
                        </Col>
                        <Col style={{ paddingTop: "2px" }}>
                          <Typography.Paragraph style={{ fontWeight: "500" }}>
                            {currentData.measure_no &&
                              `${currentData.measure_no} - ${currentData.measure_name}`}
                          </Typography.Paragraph>
                        </Col>
                      </Row>
                    </Col>

                    <Col span={24}>
                      <Row style={{ width: "100%" }}>
                        <Col style={{ width: "30%" }}>
                          <Typography.Title level={5}>
                            Support Type
                          </Typography.Title>
                        </Col>
                        <Col style={{ paddingTop: "2px" }}>
                          <Typography.Paragraph style={{ fontWeight: "500" }}>
                            {currentData.assistance &&
                              currentData.assistance == 0
                              ? "Technical Support"
                              : currentData.assistance &&
                                currentData.assistance == 1
                                ? "Capacity Building"
                                : currentData.assistance &&
                                  currentData.assistance == 2
                                  ? "Other"
                                  : "-"}
                          </Typography.Paragraph>
                        </Col>
                      </Row>
                    </Col>

                    <Col span={24}>
                      <Row style={{ width: "100%" }}>
                        <Col style={{ width: "30%" }}>
                          <Typography.Title level={5}>
                            Description
                          </Typography.Title>
                        </Col>
                        <Col style={{ paddingTop: "2px" }}>
                          <Typography.Paragraph style={{ fontWeight: "500" }}>
                            {currentData.description}
                          </Typography.Paragraph>
                        </Col>
                      </Row>
                    </Col>

                    <Col span={24}>
                      <Row style={{ width: "100%" }}>
                        <Col style={{ width: "30%" }}>
                          <Typography.Title level={5}>Agency</Typography.Title>
                        </Col>
                        <Col style={{ paddingTop: "2px" }}>
                          <Typography.Paragraph style={{ fontWeight: "500" }}>
                            {currentData.created_user}
                          </Typography.Paragraph>
                        </Col>
                      </Row>
                    </Col>

                    <Col span={24}>
                      <Row style={{ width: "100%" }}>
                        <Col style={{ width: "30%" }}>
                          <Typography.Title level={5}>
                            Tentative Start Date
                          </Typography.Title>
                        </Col>
                        <Col style={{ paddingTop: "2px" }}>
                          <Typography.Paragraph style={{ fontWeight: "500" }}>
                            {currentData.implementation_from
                              ? moment(currentData.implementation_from).format(
                                "DD/MM/YYYY"
                              )
                              : "-"}
                          </Typography.Paragraph>
                        </Col>
                      </Row>
                    </Col>

                    <Col span={24}>
                      <Row style={{ width: "100%" }}>
                        <Col style={{ width: "30%" }}>
                          <Typography.Title level={5}>
                            Tentative End Date
                          </Typography.Title>
                        </Col>
                        <Col style={{ paddingTop: "2px" }}>
                          <Typography.Paragraph style={{ fontWeight: "500" }}>
                            {currentData.implementation_to
                              ? moment(currentData.implementation_to).format(
                                "DD/MM/YYYY"
                              )
                              : "-"}
                          </Typography.Paragraph>
                        </Col>
                      </Row>
                    </Col>

                    <Col span={24}>
                      <Row style={{ width: "100%" }}>
                        <Col style={{ width: "100%" }}>
                          <Typography.Title level={5}>
                            Attachments
                          </Typography.Title>
                        </Col>
                      </Row>
                      <Row gstyle={{ width: "100%" }}>
                        <Col style={{ width: "100%" }}>
                          {currentData &&
                            currentData.attachments &&
                            currentData.attachments.map((file) => {
                              return (
                                <div
                                  style={{
                                    alignItems: "center",
                                    display: "flex",
                                    justifyContent: "space-between",
                                    width: "100%",
                                    cursor: "pointer",
                                    background: MainColors.LIGHTBACKCOLOR,
                                    borderRadius: 10,
                                    padding: 10,

                                    ":hover": {
                                      background: MainColors.DARKBACKCOLOR,
                                      color: "white",
                                      // Add any additional styles you want for the hover effect
                                    },
                                  }}
                                  onClick={() => fetchFile(file)}
                                >
                                  <div>
                                    {file.type.includes("image") ? (
                                      <img
                                        src="/image.png"
                                        alt="image"
                                        style={{ height: 50, width: 50 }}
                                      />
                                    ) : (
                                      <img
                                        src="/file.png"
                                        alt="file"
                                        style={{ height: 50, width: 50 }}
                                      />
                                    )}
                                    {file.url.toString().split("-::-")[1]}
                                  </div>
                                  <BiCloudDownload
                                    style={{ fontSize: 32, color: NewColorTheme.primaryColor }}
                                  />
                                </div>
                              );
                            })}
                        </Col>
                      </Row>
                    </Col>

                    <Col span={24}>
                      <Row style={{ width: "100%" }}>
                        <Col style={{ width: "50%" }}>
                          <Typography.Title level={5}>
                            Interested Agencies
                          </Typography.Title>
                        </Col>

                        <Col span={24}>
                          {currentData?.interests?.length > 0 ? (
                            <List>
                              <VirtualList
                                data={currentData.interests}
                                height={150}
                                itemHeight={47}
                                itemKey="id"
                              // onScroll={onScroll}
                              >
                                {(item, key) => (
                                  <List.Item
                                    key={key}
                                    style={{ cursor: "pointer" }}
                                  >
                                    <List.Item.Meta
                                      avatar={
                                        <Avatar
                                          src={
                                            <img
                                              src={
                                                "https://gw.alipayobjects.com/zos/rmsportal/KDpgvguMpGfqaHPjicRK.svg"
                                              }
                                              alt="avatar"
                                            />
                                          }
                                        />
                                      }
                                      // title={<p>{item.title}</p>}
                                      title={item.agency_name}
                                      // description={"By " + item.first_name + " " + item.last_name}

                                      description={
                                        new Date(item.created)
                                          .toISOString()
                                          .slice(8, 10) +
                                        "/" +
                                        new Date(item.created)
                                          .toISOString()
                                          .slice(5, 7)
                                      }
                                    />
                                    <div>
                                      <Space>
                                        <Button
                                          type="primary"
                                          onClick={() => assignDonor(item)}
                                          disabled={window.localStorage.getItem('view_only') == 1}
                                        >
                                          Assign
                                        </Button>
                                      </Space>
                                    </div>
                                  </List.Item>
                                )}
                              </VirtualList>
                            </List>
                          ) : (
                            <Typography.Paragraph>
                              There is no Interested Donors for this Project.
                            </Typography.Paragraph>
                          )}
                        </Col>
                      </Row>
                    </Col>

                    <Col span={24}>
                      {currentData.status == 0 && (
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                        >
                          <Space>
                            {/* <Button danger type="primary" htmlType="button" onClick={publishDonorRequest}> Reject</Button> */}
                            {/* <Button loading={btnLoad} onClick={() => setRejectDialogOpen(true)}> Reject</Button>
                                                        <Button loading={btnLoad} type="primary" onClick={() => setApproveDialogOpen(true)}> Approve</Button> */}
                          </Space>
                        </div>
                      )}
                    </Col>
                  </Row>
                </div>
              </ConfigProvider>
            )}
          </div>
        }
        table={
          <Table
            size="small"
            className="custom-table"
            rowClassName={(record, index) => index % 2 === 0 ? 'white-row' : 'gray-row'}

            columns={columns}
            dataSource={data}
            pagination={{
              pageSize: 8,
              total: data.length,
            }}
            onRow={(record, rowIndex) => {
              return {
                onClick: () => {
                  setCurrentData(record);
                },
              };
            }}
          />
        }
      />
      <ToastContainer />
    </div>
  );
};

export default AgencyInterestsForProject;
