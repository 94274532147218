
/*  ***********************************************************
|
|--------------this view is use as donor dashboard----------------
|
\
|*******************************************************************/

import React, { useEffect, useState } from 'react'

import DonarUser from '../Donors/components/DonarUser'
import '../Donors/components/dashboarddonors.css'
import { Button, Col, Divider, Row, Switch, Modal, Form, Input, ConfigProvider, Typography, Space, message, Spin, Table, Card, Select, DatePicker, Upload, Tag, Radio, Tooltip } from 'antd'

import { PlusOutlined, InboxOutlined, EyeOutlined, CloseOutlined, CloudUploadOutlined, CalendarOutlined, ContainerOutlined, ReconciliationOutlined, CalendarFilled, FileDoneOutlined, BorderlessTableOutlined, BoxPlotOutlined } from '@ant-design/icons';
import DashboardCards from '../Donors/components/DashboardCards';
import DonorDashboardCard from '../Donors/components/DonorDashboardCard';
import CustomTable from '../../Components/Table';
import { DownloadFiles, GetDonorRequestInterests, GetMyDonorInterests, GetPublishedDonorRequests, addContribution, addDonorInterests, addDonorRequestInterest, getAgencies, getAgencyMeasures, getDonorContribution, getMeasures, getMeasuresList, removeDonorRequestInterest, getMyAgencyContributionCreations } from '../../api';
import { getCounts, getUserData } from '../Donors/getCounts';
import TextArea from 'antd/es/input/TextArea';
import Dragger from 'antd/es/upload/Dragger';


import { ToastContainer, toast, Zoom } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { AiOutlineCloudSync } from 'react-icons/ai';
import moment from 'moment';
import NewNavBar from '../Dashboard/Newdashoard3/Components/NewNavBar';
import PageHeader from '../Inquiries/PageHeader';
import { useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import { MainColors, NewColorTheme } from '../../appConst';
import { BiCloudDownload } from "react-icons/bi";

const { confirm } = Modal;

let userDesignation = ''
let userDashboardName = ''




const DonorRequestNtfcAgency = () => {


    const [modalVisible, setModalVisible] = useState(false);
    const [data, setData] = useState([]);
    const [filtered_data, setFilteredData] = useState([]);
    const [tecCount, setTechCount] = useState(0)
    const [capCount, setCapCount] = useState(0)
    const [userData, setUserData] = useState({})
    const [currentIndex, setCurrentIndex] = useState()
    const [comment, setComment] = useState()
    const [messageApi, contextHolder] = message.useMessage();
    const [requestType, setRequestType] = useState(0)
    const [loaded, setLoad] = useState(true)
    const [form] = Form.useForm();
    const [requiredMark, setRequiredMarkType] = useState('optional');
    const [interestData, setInterestData] = useState([]);
    const [contriutiontData, setContributionData] = useState([]);
    const [description, setDescription] = useState('');
    const [support_type, setAssistance] = useState(0);
    const [measures, setMeasures] = useState([])
    const [measure, setMeasure] = useState(null);
    const [fileList, setFileList] = useState([]);
    const [fileList2, setFileList2] = useState([]);
    const [interestLoad, setInterestLoad] = useState(false);
    const [submitBtn, SetSubmitBtnLoad] = useState(false);
    const [agencies, setAgencies] = useState([]);
    const [currentCheckStatus, setCheckStatus] = useState(null);
    const [contLoaded, setContLoaded] = useState(false);
    const [isInterestModalOpen, setIsInterestModalOpen] = useState(false);

    const [agencyID, setAgencyID] = useState(null);
    const [toDate, setToDate] = useState(null);
    const [fromDate, setFromDate] = useState(null);
    const [uploadLoad, setUploadLoad] = useState(true);
    const [tstartDtae, setTStartDate] = useState();

    const [currentRecord, setCurrentRecord] = useState(null)
    const [assistDescription, setAssistDescription] = useState("")
    const [assistContact, setAssistContact] = useState("")
    const [viewDialog, setViewDialog] = useState(false)
    const handleCancelView = () => {
        setViewDialog(false)

    };

    const { id } = useParams()
    const handleSwitchChange = (checked, record) => {
        console.log("cehcekdd----------------", checked)
        setData((prevData) =>
            prevData.map((item) =>
                item.id === record.id
                    ? {
                        ...item,
                        checked,
                        modalVisible: true,
                    }
                    : item
            )
        );
    };

    const FetchFile = async (file) => {
        let out = await DownloadFiles(file.token)

        let blob = new Blob([out.data], { type: file.type });
        let url = window.URL.createObjectURL(blob);

        let link = document.createElement('a');
        link.href = url;

        link.download = (file.url.split("-::-"))[1];

        document.body.appendChild(link);
        link.click();
    }


    const interestSubmit = async () => {

        let body = {
            id: currentRecord.id
        }

        if (!currentRecord?.checked) {
            let out = await addDonorRequestInterest(body)

            if (out.status === 200) {

                toast.success(out.data.message, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
            }

        } else {
            let out2 = await removeDonorRequestInterest(body)
            if (out2.status === 200) {

                toast.success(out2.data.message, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
            }

        }




        setViewDialog(false)
        getDonorRequests(0)

    };
    const handleModalCancel = (record) => {
        setData((prevData) =>
            prevData.map((item) =>

                item.id === record.id
                    ? {
                        ...item,

                        modalVisible: false,
                    }
                    : item
            )
        );
        // Revert the switch state back to its original value
        setData((prevData) =>
            prevData.map((item) =>
                item.id === record.id
                    ? {
                        ...item,
                        checked: !item.checked,
                    }
                    : item
            )
        );
    };


    // Table Columns
    const columns = [
        {
            title: 'Created Date',
            dataIndex: 'created',
            key: 'created',
            render: (_, { created }) => (
                created ?
                    <div>
                        {dayjs(currentRecord?.created).format("DD/MM/YYYY")}
                    </div>

                    : "-"
            ),
        },

        {
            title: 'Agency',
            dataIndex: 'agency_name',
            key: 'agency_name',
            render: (_, record) => (
                record ?
                    <div>
                        {record.scope === 0 ? "National Wise" : record.agency_name}
                    </div>

                    : null
            ),
        },
        {
            title: 'Request Type',
            dataIndex: 'support_type',
            key: 'support_type',
            render: (_, { assistance }) => (
                <span
                    // color={assistance == 0 ? "#f50" : "#2a8c00"} 

                    key={assistance}
                    style={{
                        // width: 150,
                        display: 'flex',
                        justifyContent: 'center'
                    }} >
                    {
                        assistance == 0 ? "TS/CB"
                            : "OTHER"
                    }
                </span>
            ),

        },
        {
            title: 'Subject',
            dataIndex: 'subject',
            key: 'subject',

        },

        {
            title: 'Measure Name',
            dataIndex: 'measure_name',
            key: 'measure_name',
            render: (_, { measure_no, article_no, measure_name
            }) => (
                <p>{article_no + '.' + measure_no + '. ' + measure_name}</p>
            ),

        },

        {
            title: 'Description',
            dataIndex: 'description',
            key: 'description',

        },
        {
            title: 'Status',
            dataIndex: 'checked',
            key: 'checked',
            render: (_, { checked
            }) => (
                <p>{checked ? "Assisted" : "Not Assist"}</p>
            ),

        },



        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            render: (_, record, index) => (

                record ?
                    <div>
                        {console.log("recoed", record)}

                        {/* <Switch
                                type="primary"
                                checked={record.checked}
                                onChange={(checked) => handleSwitchChange(checked, record)}
                            /> */}
                        <Tooltip title="View">
                            <Button type="secondary" size='small' onClick={() => {
                                console.log("record", record)
                                setCurrentRecord(record)
                                setViewDialog(true)

                            }} shape="circle" icon={<EyeOutlined style={{ color: 'darkgreen' }} />} />
                        </Tooltip>


                    </div>

                    : null
            ),

        },


    ];
    const MyInterest = [

        {
            title: 'Measure',
            dataIndex: 'measure_name',
            key: 'measure_name',

        },

        {
            title: 'Subject',
            dataIndex: 'subject',
            key: 'subject',

        },

        {
            title: 'Support Type',
            dataIndex: 'support_type',
            key: 'support_type',
            render: (_, record) => (
                record ?
                    <div>
                        {record.support_type === 0 ? "Technical Support" : record.support_type === 1 ? "Capacity Building" : "Other"}
                    </div>

                    : null
            ),

        },

        {
            title: 'Description',
            dataIndex: 'description',
            key: 'description',

        },
        // {
        //     title: 'Attachments',
        //     dataIndex: 'attachments',
        //     key: 'attachments',

        // },
        {
            title: 'Created Date',
            dataIndex: 'created',
            key: 'created',
            render: (_, record) => (
                record ?
                    <div>
                        {moment(record.created).format("YYYY/MM/DD")}
                    </div>

                    : null
            ),
        },
        {
            title: 'Status',
            dataIndex: 'published_by',
            key: 'published_by',
            render: (_, { status }) => (
                <Tag color={status == 0 ? "#008282" : status == 1 ? "#9B0259" : NewColorTheme.primaryColor} key={status} style={{ width: 120, display: 'flex', justifyContent: 'center' }} >
                    {
                        status == 0 ? "UNPUBLISHED"
                            : status == 1 ? "PUBLISHED" : "ASSIGNED"
                    }
                </Tag>
            ),

        },
        {
            title: 'Action',
            key: 'action',
            render: (_, record) => (

                record ?
                    <div>
                        <Tooltip title="View">
                            <Button type="secondary" size='small' onClick={() => {
                                // form.resetFields();
                                // setSummary("")
                                // setDescription("")
                                setCurrentRecord(record)
                                setIsInterestModalOpen(true)
                                console.log("Record", record)

                            }} shape="circle" icon={<EyeOutlined style={{ color: 'darkgreen' }} />} />
                        </Tooltip>
                    </div>
                    : null
            ),
        },
    ];

    const MyContributions = [
        {
            title: 'Agency',
            dataIndex: 'agency_name',
            key: 'agency_name',

        },
        {
            title: 'Project  Name',
            dataIndex: 'project_name',
            key: 'project_name',

        },
        {
            title: 'Description',
            dataIndex: 'description',
            key: 'description',

        },

        {
            title: 'Donation Type',
            dataIndex: 'assistance',
            key: 'assistance',
            render: (_, record) => (
                record ?
                    <div>
                        {record.assistance === 0 ? "Technical Support" : record.assistance === 1 ? "Capacity Building" : "Other"}
                    </div>

                    : null
            ),

        },

        {
            title: 'TFA Measure',
            dataIndex: 'measure_name',
            key: 'measure_name'
        },

        // {
        //     title: 'Attachments',
        //     dataIndex: 'attachments',
        //     key: 'attachments',

        // },

        {
            title: 'Created by',
            dataIndex: 'name',
            key: 'name',
            // key: 'created_by',

        },
        // {
        //     title: 'Accepted Date',
        //     dataIndex: 'accepted_date',
        //     key: 'accepted_date',

        // },
        // {
        //     title: 'Published Date',
        //     dataIndex: 'published_date',
        //     key: 'published_date',

        // },
        {
            title: 'Start Date',
            dataIndex: 'start_date',
            key: 'start_date',
            render: (_, record) => (
                record ?
                    <div>
                        {record.start_date ? moment(record.start_date).format("DD/MM/YYYY") : "-"}
                    </div>

                    : null
            ),
        },
        {
            title: 'End Date',
            dataIndex: 'end_date',
            key: 'end_date',
            render: (_, record) => (
                record ?
                    <div>
                        {record.end_date ? moment(record.end_date).format("DD/MM/YYYY") : "-"}
                    </div>

                    : null
            ),
        },
        {
            title: 'Attached Documents',
            dataIndex: 'files',
            key: 'files',
            render: (_, { files }) => (
                files ?
                    <div>

                        {

                            files.length > 0 && files.map((element, key) => {
                                return <div key={key} onClick={() => FetchFile(element)}>
                                    <img src="/file.png" alt="file" style={{ height: 50, width: 50 }} />
                                    {(element?.url && (element?.url?.toString().split("-::-"))[1])}
                                </div>

                            })
                        }
                    </div>

                    : null
            ),
        },
        {
            title: 'status',
            dataIndex: 'completed',
            key: 'completed',
            render: (_, record) => (
                record ?
                    <div>
                        {record.completed == 0 ? "Ongoing" : "Completed"}
                    </div>

                    : null
            ),
        },
    ];

    useEffect(() => {


        console.log("id-------------->>>", id)
        getUserDetails()
        // getCount()
        handleSelectedCardIndex(parseInt(id))
        setRequestType(parseInt(id))

    }, []);

    const getData = () => {




    }


    const handleSelectedCardIndex = (index) => {

        setRequestType(index)

        if (index == 0) {
            getDonorRequests(index)

        }

        if (index === 1) {

            setContLoaded(false)

            // getDonorContribution(userData.agency_id).then((out) => {

            //     setContributionData(out.data.out);
            //     setContLoaded(true)
            // });

            getMyAgencyContributionCreations().then((out) => {
                setContributionData(out.data.out);
                setContLoaded(true)
                setCapCount(out.data.out.length)
            })


            getMeasures().then((out2) => {
                setMeasures(out2.data.out);
            });

            getAgencies().then((out) => setAgencies(out.data.out))
        }

        if (index === 2) {

            loadInterestTableData()
        }

        if (index === 4) {

            window.location.href = "/inquiries";
        }



    }
    const getDonorRequests = async function (requestType) {
        console.log("request===", requestType)

        // setLoad(false)
        const out = await GetPublishedDonorRequests()

        console.log("out", out)

        if (out.status === 200) {
            var sortedArray = out.data.out.sort((a, b) => new Date(b.created) - new Date(a.created));

            // setData(out.data.out.filter((element) => element.assistance === requestType))
            setData(sortedArray)

            // getDonorRequestInterest(out.data.out.filter((element) => element.assistance === requestType))
            getDonorRequestInterest(sortedArray)
            // setTechCount(out.data.out.filter((element) => element.assistance === 0).length)
            setTechCount(out.data.out.length)



        }

    }

    async function getDonorRequestInterest(incomingData) {
        const out = await GetDonorRequestInterests();


        const newData = incomingData.map(obj1 => {
            let isChecked = false;
            for (const obj2 of out.data.out) {
                console.log("Mapped", obj1.id, obj2.request_id);
                if (obj1.id === obj2.request_id) {
                    isChecked = true;
                    break; // Skip to the next obj1 when a match is found
                } else {
                    isChecked = false;
                }
            }

            return { ...obj1, checked: isChecked };
        });

        console.log("Mapped", newData);
        setData(newData);
        // setLoad(true)
    }

    const getUserDetails = async function () {
        const user = await getUserData()
        console.log("uuuser", user)
        setUserData(user)
        if (window.localStorage.getItem('ntfc_level') === 4) {
            userDesignation = "Government Techical"


        } else if (window.localStorage.getItem('ntfc_level') === 3) {
            // userDesignation = "Private"
            userDesignation = "Donor"


        } else if (window.localStorage.getItem('ntfc_level') === 2) {
            userDesignation = "Donor"


        } else if (window.localStorage.getItem('ntfc_level') === 1) {
            userDesignation = "Other"


        }

    }

    const getCount = async function () {




    }

    const handleChange = async (checked, index) => {

        console.log("cjeckkk", checked, index)
        setCurrentIndex(index)
        if (checked) {

            data[index].checked = false;  // Show the modal when the Switch is checked
        } else {
            data[index].checked = false;

            let body = {
                id: data[currentIndex].id
            }
            let out = await removeDonorRequestInterest(body)
            if (out.status === 200) {
                message.success(out.data.message);
            }
        }
    };

    const addInterest = async () => {

        // setData(
        //     data.map((item) => {
        //         if (item.key === currentIndex) {
        //             return { ...item, checked: true };
        //         }
        //         return item;
        //     })
        // );

        let body = {
            id: data[currentIndex].id,
            comment: comment
        }
        let out = await addDonorRequestInterest(body)

        if (out.status === 200) {

            toast.success(out.data.message, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
        }

        setModalVisible(false); // Hide the modal when OK is pressed
    };

    const onRequiredTypeChange = ({ requiredMarkValue }) => {
        setRequiredMarkType(requiredMarkValue);
    };

    const onSubmit = async (val) => {
        SetSubmitBtnLoad(true)
        console.log("measureeee", val)
        const formData = new FormData();
        formData.append('interest_file', fileList);
        formData.append('subject', val.subject);
        formData.append('description', val.description)
        formData.append('measure', val.measure);
        formData.append('agency', val.agency);
        formData.append('support_type', support_type);
        formData.append('implementation_from', fromDate);
        formData.append('implementation_to', toDate);
        setUploadLoad(false)
        let out = await addDonorInterests(formData)

        if (out.status === 200) {

            toast.success(out.data.message, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
        } else {

            toast.error("File Upload Error", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
        }
        loadInterestTableData()
        form.resetFields();
        setFileList([])
        SetSubmitBtnLoad(false)
        setUploadLoad(true)
    }

    function loadInterestTableData() {

        getAgencies().then((res) => {
            const agencyList = [
                {
                    value: -1,
                    label: "National Wise",
                    agency_type: 1
                },
                ...res.data.out,
                {
                    value: -2,
                    label: "Other",
                    agency_type: 1
                },
            ];
            setAgencies(agencyList);
        });

        getMeasures().then((out2) => {
            setMeasures(out2.data.out);
        });

        GetMyDonorInterests().then((out) => {
            setInterestData(out.data.out);
        });
    }

    async function handleFileUpload(file) {

        setFileList(file);

    }

    const props = {
        name: 'file',
        multiple: false,
        action: 'https://www.mocky.io/v2/5cc8019d300000980a055e76',

        onChange(info) {

            console.log('FIle', info)
            const { status } = info.file;
            if (status !== 'uploading') {
                console.log(info.file, info.fileList);
            }
            if (status === 'done') {
                message.success(`${info.file.name} file uploaded successfully.`);
                handleFileUpload(info.file);
            } else if (status === 'error') {
                message.error(`${info.file.name} file upload failed.`);
            }
        },
        onDrop(e) {
            console.log('Dropped files', e.dataTransfer.files);
        },
    };


    const normFile = (e) => {
        console.log('Upload event:', e);
        if (Array.isArray(e)) {
            return e;
        }
        return e?.fileList;
    };
    const [value, setValue] = useState(0);
    const onChange = (e) => {
        console.log('radio checked', e.target.value);
        setValue(e.target.value);
    };


    const submitContribution = async (val) => {

        console.log("valuesss", val)
        SetSubmitBtnLoad(true)

        const formData = new FormData();
        formData.append('contribution_file', fileList2);
        formData.append('project_name', val.subject);
        formData.append('description', val.description)
        formData.append('measure', val.measure);
        formData.append('assistance', support_type);
        formData.append('duration', value);
        formData.append('agency', val.agency);
        formData.append('completed', value);
        formData.append('start_date', toDate);
        formData.append('end_date', fromDate);
        setUploadLoad(false)
        // let formData
        let out = await addContribution(formData)

        if (out.status === 200) {

            toast.success(out.data.message, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
            form.resetFields()
            setDescription("")
        } else {

            toast.error("File Upload Error", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
            form.resetFields()
            setDescription("")
        }
        setContLoaded(false)
        // getDonorContribution(userData.agency_id).then((out) => {
        //     setContributionData(out.data.out);
        //     setContLoaded(true);
        // });
        getMyAgencyContributionCreations().then((out) => {
            setContributionData(out.data.out);
            setContLoaded(true)
        })

        form.resetFields()
        setDescription("")
        SetSubmitBtnLoad(false)
        setUploadLoad(true)
    }

    async function handleFileUpload2(file) {

        setFileList2(file);

    }

    const handleAgency = (value) => {
        // setAgencyID(value)
        if (value == -1) {

            getMeasures().then((out2) => {
                setMeasures(out2.data.out);
            });
        } else {
            getAgencyMeasures(value).then((out) => {
                setMeasures(out.data.out.map((m) => { return { value: m.measure_id, label: (m.article_no + ". " + m.measure_no + ". " + m.summary) } }));
            });
        }



    }

    const handleCancel = () => {
        setIsInterestModalOpen(false);
    };

    const props2 = {
        name: 'file',
        multiple: false,
        action: 'https://www.mocky.io/v2/5cc8019d300000980a055e76',

        onChange(info) {

            console.log('FIle', info)
            const { status } = info.file;
            if (status !== 'uploading') {
                console.log(info.file, info.fileList);
            }
            if (status === 'done') {
                message.success(`${info.file.name} file uploaded successfully.`);
                handleFileUpload2(info.file);
            } else if (status === 'error') {
                message.error(`${info.file.name} file upload failed.`);
            }
        },
        onDrop(e) {
            console.log('Dropped files', e.dataTransfer.files);
        },
    };

    const onFromDateChange = (date, dateString) => {
        console.log(dateString)
        setFromDate(dateString);


    };

    const onToDateChange = (date, dateString) => {
        setToDate(dateString);
    };


    return (
        <div style={{ overflow: "hidden" }}>      {/* <div className="sidepanel-left"></div> */}
            <div className="bgSidePanel" style={{ overflow: "hidden", background: NewColorTheme.primaryColor }}></div>
            <div className="sidepanel-left"></div>

            <div className="top-dashboard" >
                {/* <DonarUser
                    donorUser={userData.title + " " + userData.first_name + " " + userData.last_name}
                    designation={userData.designation}
                />
                <div className="divide-line">
                    <Divider orientation="left">
                        <p className='divider-text'>{userDesignation + " " + userData.agency}</p>
                    </Divider>
                </div> */}
                <PageHeader title={"Donor"} />
                <div style={{ position: "absolute", zIndex: "6", width: "100%" }}>
                    <DashboardCards
                        selectedCard={id}
                        onCardSelect={handleSelectedCardIndex}
                        primaryCardText='Donor Requests'
                        primaryCardVal={tecCount.toString()}
                        card1url="/donorrequentfcagency"
                        card2url='/donorcontribution'

                        secondaryCardText='Capacity Building Requests'
                        secondaryCardVal={capCount.toString()}

                        name='Contribution'
                        card3url='/donorcontribution'
                        profile='Profile'
                        contribution="Contribution"
                        lastCardText='Donor Interest '
                        inquirymgt="Inquiry Management"
                    />
                </div>

                {requestType === 2 ?
                    <div style={{ position: 'absolute', top: '32vh', height: '70vh', width: '100%', padding: '2% 5%' }}>
                        <Row gutter={16} style={{ height: 'fit-content' }}>
                            <Col span={6} style={{ height: '100%' }}>
                                <Card style={{ height: 'calc(70vh - 2%)', background: '#dce5ea', maxHeight: '100%', overflow: 'auto' }} >
                                    <div style={{ paddingTop: "10px", paddingLeft: "10px" }}>
                                        <Typography.Title style={{ color: '#005476' }} level={3}>Create Donor Interest</Typography.Title>
                                    </div>
                                    <Form
                                        form={form}
                                        layout="vertical"
                                        onFinish={onSubmit}
                                    >
                                        <Form.Item
                                            label="Agency Name"
                                            wrapperCol={{ span: 24 }}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Agency is required',
                                                },
                                            ]}
                                            required name="agency">
                                            <Select
                                                name="agency"
                                                placeholder="Select Agency"
                                                // size="large"
                                                style={{ width: '100%' }}
                                                options={agencies.filter(a => a.agency_type === 1)}
                                                onChange={(value) => {
                                                    form.resetFields(['measure'])
                                                    handleAgency(value)
                                                }}
                                            // options={[{ value: 999, label: "Island wise" }, ...agencies]}
                                            />
                                        </Form.Item>
                                        <Form.Item
                                            label="Measure"
                                            wrapperCol={{ span: 24 }}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Agency is required',
                                                },
                                            ]}
                                            required name="measure">
                                            <Select
                                                placeholder="Select Measure"
                                                options={measures}
                                                onChange={(val) => {
                                                    console.log("valuee-------->>>", val)
                                                    setMeasure(val)
                                                }}
                                            />
                                        </Form.Item>
                                        <Form.Item
                                            required
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Subject is required',
                                                },
                                                {
                                                    pattern: /^(?=.*[a-zA-Z])[a-zA-Z0-9!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/? ]+$/,//if field has only spaces, numbers or special characters
                                                    message: <p style={{ marginLeft: "12px" }}>Please enter a valid Subject with alphabetical characters</p>,
                                                }
                                            ]}
                                            label="Subject"
                                            wrapperCol={{ span: 24 }}
                                            name="subject"
                                        >
                                            <Input placeholder="Subject" />
                                        </Form.Item>
                                        <Form.Item
                                            label="Support Type"
                                            wrapperCol={{ span: 24 }}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Agency is required',
                                                },
                                            ]}
                                            required name="support_type">
                                            <Select
                                                placeholder="Support Type"
                                                onChange={(val) => setAssistance(val)}
                                                // defaultValue={0}
                                                options={
                                                    [
                                                        { value: 0, label: 'Technical Support' },
                                                        { value: 1, label: 'Capacity Building' },
                                                        { value: 2, label: 'Other' },
                                                    ]
                                                }
                                            />
                                        </Form.Item>
                                        <Form.Item label="Tentative Start Date" name="from_date"
                                            // required
                                            valuePropName='date'
                                        // rules={[{
                                        //     required: true,
                                        //     message: <p style={{ marginLeft: "12px" }}>Start Date is required</p>,
                                        // }]}
                                        >
                                            <DatePicker
                                                style={{ width: '100%' }}
                                                // size="large"
                                                onChange={onFromDateChange}
                                                format="DD/MM/YYYY"

                                            // value={dayjs(fromDate).isValid() ? dayjs(fromDate) : null}


                                            />
                                        </Form.Item>
                                        <Form.Item label="Tentative End Date" name="to_date"
                                            // required
                                            valuePropName='date'
                                        // rules={[{
                                        //     required: true,
                                        //     message: <p style={{ marginLeft: "12px" }}>End Date is required</p>,
                                        // }]}
                                        >
                                            <DatePicker
                                                style={{ width: '100%' }}
                                                // size="large"
                                                onChange={onToDateChange}
                                                format="DD/MM/YYYY"
                                                disabledDate={(current) => current && current.isBefore(form.getFieldValue('from_date'))}
                                            />
                                        </Form.Item>
                                        {uploadLoad ?
                                            <div style={{ margin: "30px 0px" }}>
                                                <p >
                                                    Please ensure that only one attachment is uploaded at a time, and the file size should be limited to a maximum of 5MB.
                                                </p>
                                                <Dragger {...props}

                                                    showUploadList={{ showProgress: true }}
                                                    multiple={false}
                                                    beforeUpload={file => {
                                                        const MAX_SIZE = 5 * 1024 * 1024; // 5 MB
                                                        if (file.size > MAX_SIZE) {

                                                            toast.error(
                                                                'File size exceeds limit! The maximum file upload size is 5MB.',
                                                                {
                                                                    position: "top-right",
                                                                    autoClose: 5000,
                                                                    hideProgressBar: false,
                                                                    closeOnClick: true,
                                                                    pauseOnHover: true,
                                                                    draggable: true,
                                                                    progress: undefined,
                                                                    theme: "colored",
                                                                });
                                                            return false;
                                                        } else {
                                                            handleFileUpload(file);

                                                        }
                                                        return false;// to prevent automatic upload
                                                    }}
                                                    maxCount={1}

                                                >
                                                    <p className="ant-upload-drag-icon">
                                                        <InboxOutlined tyle={{ color: "#33363F" }} />
                                                    </p>
                                                    <p className="ant-upload-text">Click or drag a file to this area to upload</p>

                                                </Dragger>
                                            </div>
                                            : ""}
                                        <Form.Item
                                            required
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Description is required',
                                                },
                                                {
                                                    pattern: /^(?=.*[a-zA-Z])[a-zA-Z0-9!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/? ]+$/,//if field has only spaces, numbers or special characters
                                                    message: <p style={{ marginLeft: "12px" }}>Please enter a valid project description with alphabetical characters</p>,
                                                }
                                            ]}
                                            label="Project Description"
                                            wrapperCol={{ span: 24 }}
                                            name="description"
                                        >
                                            <TextArea placeholder='Description' rows={4} />
                                        </Form.Item>
                                        <Form.Item
                                            wrapperCol={{ span: 24 }}
                                        >
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    justifyContent: 'flex-end',
                                                    width: '100%',
                                                }}
                                            >
                                                <ConfigProvider
                                                    theme={{
                                                        token: {
                                                            colorPrimary: '#006666',
                                                        },
                                                    }}
                                                >
                                                    <Button loading={submitBtn} htmlType="submit" size="large" type="primary">
                                                        Create
                                                    </Button>
                                                </ConfigProvider>
                                            </div>
                                        </Form.Item>
                                    </Form>


                                </Card>
                            </Col>
                            <Col span={18}>
                                <Card className="full-card custom-scroll-div" style={{ overflow: 'auto', maxHeight: '100%' }} >
                                    {/* <Card className="full-card custom-scroll-div" style={{ height: 'calc(65vh - 2%)', overflow: 'auto', maxHeight: '100%' }} > */}
                                    <Table columns={MyInterest} dataSource={interestData}
                                        pagination={{
                                            pageSize: 8,
                                            total: filtered_data.length,
                                        }} />
                                </Card>
                            </Col>


                        </Row>

                    </div>
                    : requestType === 1 ?
                        <div style={{ position: 'absolute', top: '32vh', height: '70vh', width: '100%', padding: '2% 5%' }}>
                            <Row gutter={16} style={{ height: 'fit-content' }}>
                                <Col span={6} style={{ height: '100%' }}>
                                    <Card style={{ height: 'calc(70vh - 2%)', background: '#dce5ea', maxHeight: '100%', overflow: 'auto' }} >
                                        <div style={{ paddingTop: "10px", paddingLeft: "10px" }}>
                                            <Typography.Title style={{ color: '#005476' }} level={3}>Create New Contribution</Typography.Title>
                                        </div>
                                        <Form
                                            form={form}
                                            layout="vertical"
                                            onFinish={submitContribution}
                                        >
                                            <Form.Item
                                                label="Agency Name"
                                                wrapperCol={{ span: 24 }}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Agency is required',
                                                    },
                                                ]}
                                                required name="agency">
                                                <Select
                                                    name="agency"
                                                    placeholder="Select Agency"
                                                    // size="large"
                                                    style={{ width: '100%' }}
                                                    options={agencies.filter(a => a.agency_type === 1)}
                                                    onChange={(value) => { handleAgency(value) }}
                                                // options={[{ value: 999, label: "Island wise" }, ...agencies]}
                                                />
                                            </Form.Item>
                                            <Form.Item
                                                label="Measure"
                                                wrapperCol={{ span: 24 }}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Measure is required',
                                                    },
                                                ]}
                                                required name="measure">
                                                <Select
                                                    placeholder="Select Measure"
                                                    options={measures}
                                                    onChange={(val) => setMeasure(val)}
                                                />
                                            </Form.Item>
                                            <Form.Item
                                                required
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Subject is required',
                                                    },
                                                    {
                                                        pattern: /^(?=.*[a-zA-Z])[a-zA-Z0-9!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/? ]+$/,//if field has only spaces, numbers or special characters
                                                        message: <p style={{ marginLeft: "12px" }}>Please enter a valid Subject with alphabetical characters</p>,
                                                    }
                                                ]}
                                                label="Subject"
                                                wrapperCol={{ span: 24 }}
                                                name="subject"
                                            >
                                                <Input placeholder="Subject" />
                                            </Form.Item>
                                            <Form.Item
                                                label="Support Type"
                                                wrapperCol={{ span: 24 }}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Support Type is required',
                                                    },
                                                ]}
                                                required name="support_type">
                                                <Select
                                                    placeholder="Support Type"
                                                    onChange={(val) => setAssistance(val)}
                                                    // defaultValue={0}
                                                    options={
                                                        [
                                                            { value: 0, label: 'Technical Support' },
                                                            { value: 1, label: 'Capacity Building' },
                                                            { value: 2, label: 'Other' },
                                                        ]
                                                    }
                                                />
                                            </Form.Item>
                                            <Form.Item label="Tentative Start Date" name="from_date"
                                                // required
                                                valuePropName='date'
                                            // rules={[{
                                            //     required: true,
                                            //     message: <p style={{ marginLeft: "12px" }}>Start Date is required</p>,
                                            // }]}

                                            >
                                                <DatePicker
                                                    style={{ width: '100%' }}
                                                    // size="large"
                                                    onChange={onFromDateChange}
                                                    format="DD/MM/YYYY"

                                                />
                                            </Form.Item>
                                            <Form.Item label="Tentative End Date" name="to_date"
                                                // required
                                                valuePropName='date'
                                            // rules={[{
                                            //     required: true,
                                            //     message: <p style={{ marginLeft: "12px" }}>End Date is required</p>,
                                            // }]}
                                            >
                                                <DatePicker
                                                    style={{ width: '100%' }}
                                                    // size="large"
                                                    onChange={onToDateChange}
                                                    format="DD/MM/YYYY"
                                                    disabledDate={(current) => current && current.isBefore(form.getFieldValue('from_date'))}
                                                />
                                            </Form.Item>
                                            {uploadLoad ?
                                                <div style={{ margin: "30px 0px" }}>
                                                    <p >
                                                        Please ensure that only one attachment is uploaded at a time, and the file size should be limited to a maximum of 5MB.
                                                    </p>
                                                    <Dragger {...props}

                                                        showUploadList={{ showProgress: true }}
                                                        multiple={false}
                                                        beforeUpload={file => {
                                                            const MAX_SIZE = 5 * 1024 * 1024; // 5 MB
                                                            if (file.size > MAX_SIZE) {

                                                                toast.error(
                                                                    'File size exceeds limit! The maximum file upload size is 5MB.',
                                                                    {
                                                                        position: "top-right",
                                                                        autoClose: 5000,
                                                                        hideProgressBar: false,
                                                                        closeOnClick: true,
                                                                        pauseOnHover: true,
                                                                        draggable: true,
                                                                        progress: undefined,
                                                                        theme: "colored",
                                                                    });
                                                                return false;
                                                            } else {
                                                                handleFileUpload2(file);

                                                            }
                                                            return false;
                                                            // to prevent automatic upload
                                                        }}
                                                        maxCount={1}

                                                    >
                                                        <p className="ant-upload-drag-icon">
                                                            <InboxOutlined tyle={{ color: "#33363F" }} />
                                                        </p>
                                                        <p className="ant-upload-text">Click or drag a file to this area to upload</p>

                                                    </Dragger>
                                                </div>
                                                : ""}
                                            <Form.Item
                                                required
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Description is required',
                                                    },
                                                    {
                                                        pattern: /^(?=.*[a-zA-Z])[a-zA-Z0-9!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/? ]+$/,//if field has only spaces, numbers or special characters
                                                        message: <p style={{ marginLeft: "12px" }}>Please enter a valid project description with alphabetical characters</p>,
                                                    }
                                                ]}
                                                label="Project Description"
                                                wrapperCol={{ span: 24 }}
                                                name="description"
                                            >
                                                <TextArea placeholder='Description' rows={4} />
                                            </Form.Item>
                                            <Form.Item
                                                wrapperCol={{ span: 24 }}
                                            >
                                                <div
                                                    style={{
                                                        display: 'flex',
                                                        justifyContent: 'flex-end',
                                                        width: '100%',
                                                    }}
                                                >
                                                    <ConfigProvider
                                                        theme={{
                                                            token: {
                                                                colorPrimary: '#006666',
                                                            },
                                                        }}
                                                    >
                                                        <Button loading={submitBtn} htmlType="submit" size="large" type="primary">
                                                            Create
                                                        </Button>
                                                    </ConfigProvider>
                                                </div>
                                            </Form.Item>
                                        </Form>


                                    </Card>
                                </Col>
                                <Col span={18}>
                                    {contLoaded ?
                                        <Card className="full-card custom-scroll-div" style={{ overflow: 'auto', maxHeight: '100%' }} >
                                            {/* <Card className="full-card custom-scroll-div" style={{ height: 'calc(65vh - 2%)', overflow: 'auto', maxHeight: '100%' }} > */}
                                            <Table columns={MyContributions} dataSource={contriutiontData}
                                                pagination={{
                                                    pageSize: 10,
                                                    total: filtered_data.length,
                                                }} />
                                        </Card>
                                        : ""}
                                </Col>


                            </Row>

                        </div>
                        :

                        <div style={{ position: 'absolute', top: '32vh', height: '70vh', width: '100%', padding: '2% 5%' }}>
                            {loaded ?
                                <Row>
                                    <Col span={24}>
                                        <Card className="full-card custom-scroll-div" style={{ height: 'fit-content', overflow: 'auto', maxHeight: '100%' }} >
                                            {/* <Card className="full-card custom-scroll-div" style={{ height: 'calc(65vh - 2%)', overflow: 'auto', maxHeight: '100%' }} > */}

                                            <Table columns={columns} dataSource={data}
                                                onRow={(record, rowIndex) => {
                                                    return {
                                                        onClick: () => {
                                                            setCurrentRecord(record)
                                                            setViewDialog(true)
                                                        },
                                                    };
                                                }}
                                                pagination={{
                                                    pageSize: 8,
                                                    total: filtered_data.length,
                                                }} />
                                        </Card>
                                    </Col>
                                </Row>
                                :
                                <div style={{ display: "flex", height: "100%", width: "100%", justifyContent: "center", alignItems: "center" }}>
                                    <Spin size='large' />
                                </div>
                            }

                        </div>}
            </div>
            <Modal
                closable={false}

                className="custom-modal"
                width={700}
                footer={false}
                onCancel={handleCancel}
                title={
                    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                        <div>     <Typography className='custom-modal-title' level={2}>Donor Interest Details</Typography></div>
                        <div>
                            <ConfigProvider
                                theme={{
                                    token: {
                                        colorPrimary: '#265252',
                                    },
                                }}
                            >


                                <Button
                                    onClick={handleCancel}
                                    icon={<CloseOutlined style={{ color: 'white' }} />} type="primary" shape="circle">

                                </Button>
                            </ConfigProvider>
                        </div>

                    </div>


                }
                open={isInterestModalOpen}
                onOk={handleCancel}
                centered
            >

                <div className='custom-modal-body'>
                    <Row gutter={[16, 16]}>
                        <Col span={24}>

                            <Row gutter={16}>
                                <Col span={24}>
                                    <Card style={{ background: "#a8cccc4a", borderRadius: 5, padding: 5 }}>
                                        <Row gutter={[16, 16]}>
                                            <div style={{
                                                marginTop: '-10px',
                                                position: 'absolute',
                                                // marginLeft: '-10px'
                                            }}>
                                                <Typography className='inner-tile-title'>{"Measure"}</Typography>
                                            </div>
                                            <Col span={24} style={{ marginTop: "7px", marginBottom: "-7px", paddingLeft: "20px" }}>
                                                {currentRecord?.measure_name}
                                            </Col>
                                        </Row>
                                    </Card>
                                </Col>
                            </Row>

                            <Row gutter={16} style={{ marginTop: "10px" }}>
                                <Col span={24}>
                                    <Card style={{ background: "#a8cccc4a", borderRadius: 5, padding: 5 }}>
                                        <Row gutter={[16, 16]}>
                                            <div style={{
                                                marginTop: '-10px',
                                                position: 'absolute',
                                                // marginLeft: '-10px'
                                            }}>
                                                <Typography className='inner-tile-title'>{"Subject"}</Typography>
                                            </div>
                                            <Col span={24} style={{ marginTop: "7px", marginBottom: "-7px", paddingLeft: "20px" }}>
                                                {currentRecord?.subject}
                                            </Col>
                                        </Row>
                                    </Card>
                                </Col>
                            </Row>

                            <Row gutter={16} style={{ marginTop: "10px" }}>
                                <Col span={24}>
                                    <Card style={{ background: "#a8cccc4a", borderRadius: 5, padding: 5 }}>
                                        <Row gutter={[16, 16]}>
                                            <div style={{
                                                marginTop: '-10px',
                                                position: 'absolute',
                                                // marginLeft: '-10px'
                                            }}>
                                                <Typography className='inner-tile-title'>{"Description"}</Typography>
                                            </div>
                                            <Col span={24} style={{ marginTop: "7px", marginBottom: "-7px", paddingLeft: "20px" }}>
                                                {currentRecord?.description}
                                            </Col>
                                        </Row>
                                    </Card>
                                </Col>
                            </Row>

                            <Row gutter={16} style={{ marginTop: "10px" }}>
                                <Col span={8}>
                                    <Card style={{ background: "#a8cccc4a", borderRadius: 5, padding: 5 }}>
                                        <Row gutter={[16, 16]}>
                                            <div style={{
                                                marginTop: '-10px',
                                                position: 'absolute',
                                                // marginLeft: '-10px'
                                            }}>
                                                <Typography className='inner-tile-title'>{"Support Type"}</Typography>
                                            </div>
                                            <Col span={24} style={{ marginTop: "7px", marginBottom: "-7px", paddingLeft: "20px" }}>
                                                {currentRecord?.support_type == 0 ? "Technical Support" : currentRecord?.support_type == 1 ? "Capacity Building" : "Other"}
                                            </Col>
                                        </Row>
                                    </Card>
                                </Col>
                                <Col span={8}>
                                    <Card style={{ background: "#a8cccc4a", borderRadius: 5, padding: 5 }}>
                                        <Row gutter={[16, 16]}>
                                            <div style={{
                                                marginTop: '-10px',
                                                position: 'absolute',
                                                // marginLeft: '-10px'
                                            }}>
                                                <Typography className='inner-tile-title'>{"Status"}</Typography>
                                            </div>
                                            <Col span={24} style={{ marginTop: "7px", marginBottom: "-7px", paddingLeft: "20px" }}>
                                                {currentRecord?.status == 0 ? "UNPUBLISHED" : currentRecord?.status == 1 ? "PUBLISHED" : "ASSIGNED"}
                                            </Col>
                                        </Row>
                                    </Card>
                                </Col>
                                <Col span={8}>
                                    <Card style={{ background: "#a8cccc4a", borderRadius: 5, padding: 5 }}>
                                        <Row gutter={[16, 16]}>
                                            <div style={{
                                                marginTop: '-10px',
                                                position: 'absolute',
                                                // marginLeft: '-10px'
                                            }}>
                                                <Typography className='inner-tile-title'>{"Created Date"}</Typography>
                                            </div>
                                            <Col span={24} style={{ marginTop: "7px", marginBottom: "-7px", paddingLeft: "20px" }}>
                                                {moment(currentRecord?.created).format("YYYY/MM/DD")}
                                            </Col>
                                        </Row>
                                    </Card>
                                </Col>
                            </Row>
                            {currentRecord && currentRecord?.attachments && currentRecord?.attachments.length > 0 ?
                                <Row gutter={16} style={{ marginTop: "10px" }}>
                                    <Col span={24}>
                                        <Card style={{ background: "#a8cccc4a", borderRadius: 5, padding: 5 }}>
                                            <Row gutter={[16, 16]}>
                                                <div style={{
                                                    marginTop: '-10px',
                                                    position: 'absolute',
                                                    // marginLeft: '-10px'
                                                }}>
                                                    <Typography className='inner-tile-title'>{"Attachement"}</Typography>
                                                </div>
                                                <Col span={24} style={{ marginTop: "7px", marginBottom: "-7px", paddingLeft: "20px" }}>
                                                    {currentRecord?.attachments.map((file) => {
                                                        return <div style={{
                                                            alignItems: "center",
                                                            display: "flex",
                                                            justifyContent: "space-between",
                                                            width: "100%",
                                                            cursor: "pointer",
                                                            background: MainColors.LIGHTBACKCOLOR,
                                                            borderRadius: 10,
                                                            padding: 10,

                                                            ":hover": {
                                                                background: MainColors.DARKBACKCOLOR,
                                                                color: "white",
                                                                // Add any additional styles you want for the hover effect
                                                            }
                                                        }} className="inquiry-btn" onClick={() => FetchFile(file)}>
                                                            <div>
                                                                {file.type.includes("image") ?
                                                                    <img src="/image.png" alt="image" style={{ height: 50, width: 50 }} />
                                                                    : <img src="/file.png" alt="file" style={{ height: 50, width: 50 }} />}
                                                                {(file.url.toString().split("-::-"))[1]}
                                                            </div>

                                                            <BiCloudDownload style={{ fontSize: 32, color: NewColorTheme.primaryColor }} />
                                                        </div>


                                                    })}
                                                </Col>
                                            </Row>
                                        </Card>
                                    </Col>
                                </Row>
                                : ""}
                        </Col>
                    </Row>
                </div>
            </Modal>

            {/* <Modal
                centered
                open={modalVisible}
                onOk={addInterest}
                onCancel={() => {
                    setData(
                        data.map((item) => {
                            if (item.key === switchRecord.key) {
                                return { ...item, checked: false };
                            }
                            return item;
                        })
                    );
                    setModalVisible(false);
                }


                }
            >
                <Space size={16} direction="vertical" style={{ width: "100%" }}>


                    <Typography.Text>Do you want to assist this project..?</Typography.Text>
                    <Input.TextArea style={{ borderRadius: 15 }} rows={5} value={comment} onChange={(e) => setComment(e.target.value)} placeholder='Enter Comment' />
                    {/* <TextArea style={{ width: "100%" }} placeholder="Add a comment" /> 
                </Space>
            </Modal> */}
            <Modal
                centered
                className="custom-modal"
                title={
                    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                        <div>     <Typography className='custom-modal-title' level={2}>Interest to Assist</Typography></div>


                    </div>


                }
                open={viewDialog}
                width={750}
                onCancel={handleCancelView}
                footer={[
                    !currentRecord?.checked &&

                    <Row gutter={[8, 8]} align={"top"} style={{ marginBottom: 15 }}>

                        <Col xs={24}>

                            <div style={{ display: 'flex', flexDirection: "column", alignItems: 'flex-start' }}>
                                <Typography.Title level={5}> Mark My Interest</Typography.Title>
                                <Row style={{ width: "100%" }} gutter={16} >
                                    <Col xs={12}>

                                        <Input.TextArea style={{ borderRadius: 15, width: "100%" }} rows={3} placeholder='Description' value={assistDescription} onChange={(e) => setAssistDescription(e.target.value)} />

                                    </Col>
                                    <Col xs={12}>

                                        <Input.TextArea style={{ borderRadius: 15, width: "100%" }} rows={3} placeholder='Contact Details' value={assistContact} onChange={(e) => setAssistContact(e.target.value)} />

                                    </Col>


                                </Row>


                            </div>



                        </Col>

                    </Row>,
                    <Button size="large" onClick={handleCancelView}> Cancel</Button>,
                    <Button style={{ backgroundColor: NewColorTheme.primaryColor, color: "white" }} size="large" type="primary" onClick={interestSubmit}> {currentRecord?.checked ? "Remove Assist" : "Assist"}</Button>,





                ]}

            >
                <div className='custom-modal-body custom-scroll-div' style={{ height: 550, overflow: "auto" }}>
                    <Row>
                        <Col xs={24}>
                            <Row gutter={[8, 8]} align={"top"}>
                                <Col xs={24}>
                                    <Row gutter={[8, 8]}>
                                        <Col xs={2}>
                                            <ReconciliationOutlined style={{ fontSize: 22 }} />
                                        </Col>
                                        <Col xs={22}>
                                            <div style={{ display: 'flex', flexDirection: "column", alignItems: 'flex-start' }}>
                                                <Typography.Title level={5}> Subject</Typography.Title>
                                                <Typography> {currentRecord?.subject}</Typography>
                                            </div>
                                        </Col>

                                    </Row>


                                </Col>
                                <Col xs={12}>
                                    <Row gutter={[8, 8]} align={"top"}>
                                        <Col xs={4}>
                                            <CalendarFilled style={{ fontSize: 22 }} />
                                        </Col>
                                        <Col xs={20}>

                                            <div style={{ display: 'flex', flexDirection: "column", alignItems: 'flex-start' }}>
                                                <Typography.Title level={5}> Created Date</Typography.Title>

                                                <Typography> {dayjs(currentRecord?.created).format("DD/MM/YYYY")}</Typography>

                                            </div>



                                        </Col>

                                    </Row>
                                </Col>
                                <Col xs={24}>
                                    <Row gutter={[8, 8]} align={"top"}>
                                        <Col xs={2}>
                                            <BoxPlotOutlined style={{ fontSize: 22 }} />
                                        </Col>
                                        <Col xs={22}>

                                            <div style={{ display: 'flex', flexDirection: "column", alignItems: 'flex-start' }}>
                                                <Typography.Title level={5}> Measure</Typography.Title>

                                                <Typography> {
                                                    currentRecord?.article_no + "." +
                                                    currentRecord?.measure_no + ". " +
                                                    currentRecord?.measure_name}
                                                </Typography>

                                            </div>



                                        </Col>

                                    </Row>
                                </Col>
                                <Col xs={24}>
                                    <Row gutter={[8, 8]} align={"top"}>
                                        <Col xs={2}>
                                            <BorderlessTableOutlined style={{ fontSize: 22 }} />
                                        </Col>
                                        <Col xs={22}>

                                            <div style={{ display: 'flex', flexDirection: "column", alignItems: 'flex-start' }}>
                                                <Typography.Title level={5}> Support Type</Typography.Title>

                                                <Typography> {
                                                    currentRecord?.assistance == 0 ?
                                                        "Technical Support or Capacity Building" : "Other"}
                                                </Typography>

                                            </div>



                                        </Col>

                                    </Row>
                                </Col>

                                <Col xs={8}>
                                    <Row gutter={[8, 8]} align={"top"}>
                                        <Col xs={6}>
                                            <CalendarOutlined style={{ fontSize: 22 }} />
                                        </Col>
                                        <Col xs={18}>

                                            <div style={{ display: 'flex', flexDirection: "column", alignItems: 'flex-start' }}>
                                                <Typography.Title level={5}> Tentative Start Date</Typography.Title>

                                                <Typography> {dayjs(currentRecord?.implementation_from).format("DD/MM/YYYY")}</Typography>

                                            </div>



                                        </Col>

                                    </Row>


                                </Col>

                                <Col xs={8}>
                                    <Row gutter={[8, 8]} align={"top"}>
                                        <Col xs={4}>
                                            <CalendarOutlined style={{ fontSize: 22 }} />
                                        </Col>
                                        <Col xs={20}>

                                            <div style={{ display: 'flex', flexDirection: "column", alignItems: 'flex-start' }}>
                                                <Typography.Title level={5}> Tentative End Date</Typography.Title>

                                                <Typography> {dayjs(currentRecord?.implementation_to).format("DD/MM/YYYY")}</Typography>

                                            </div>



                                        </Col>

                                    </Row>


                                </Col>
                                <Col xs={8}>
                                    <Row gutter={[8, 8]} align={"top"}>
                                        <Col xs={6}>
                                            <CalendarOutlined style={{ fontSize: 22 }} />
                                        </Col>
                                        <Col xs={18}>

                                            <div style={{ display: 'flex', flexDirection: "column", alignItems: 'flex-start' }}>
                                                <Typography.Title level={5}> Published Date</Typography.Title>

                                                <Typography> {
                                                    currentRecord?.published_date ?
                                                        dayjs(currentRecord?.published_date).format("DD/MM/YYYY")
                                                        : "-"
                                                }</Typography>

                                            </div>



                                        </Col>

                                    </Row>


                                </Col>
                                <Col xs={24}>
                                    <Row gutter={[8, 8]} align={"top"}>
                                        <Col xs={2}>
                                            <ContainerOutlined style={{ fontSize: 22 }} />
                                        </Col>
                                        <Col xs={22}>

                                            <div style={{ display: 'flex', flexDirection: "column", alignItems: 'flex-start' }}>
                                                <Typography.Title level={5}> Description</Typography.Title>

                                                <Typography> {
                                                    currentRecord?.description ?
                                                        currentRecord?.description : "-"
                                                }
                                                </Typography>

                                            </div>



                                        </Col>

                                    </Row>
                                </Col>
                                {currentRecord?.attachments?.length > 0 &&
                                    <Col xs={24}>
                                        <Row gutter={[8, 8]} align={"top"}>
                                            <Col xs={2}>
                                                <CloudUploadOutlined style={{ fontSize: 22 }} />
                                            </Col>
                                            <Col xs={22}>

                                                <div style={{ display: 'flex', flexDirection: "column", alignItems: 'flex-start' }}>
                                                    <Typography.Title level={5}> Attachment</Typography.Title>

                                                    {

                                                        currentRecord?.attachments?.length > 0 && currentRecord?.attachments.map((element, key) => {
                                                            return <div key={key} onClick={() => FetchFile(element)}>
                                                                <img src="/file.png" alt="file" style={{ height: 50, width: 50 }} />
                                                                {(element?.url && (element?.url?.toString().split("-::-"))[1])}
                                                            </div>

                                                        })
                                                    }

                                                </div>



                                            </Col>

                                        </Row>
                                    </Col>
                                }
                                <Col xs={24}>
                                    <Row gutter={[8, 8]} align={"top"}>
                                        <Col xs={2}>
                                            <FileDoneOutlined style={{ fontSize: 22 }} />
                                        </Col>
                                        <Col xs={22}>

                                            <div style={{ display: 'flex', flexDirection: "column", alignItems: 'flex-start' }}>
                                                <Typography.Title level={5}> Remarks</Typography.Title>

                                                <Typography> {
                                                    currentRecord?.reject_reason ?
                                                        currentRecord?.reject_reason :
                                                        currentRecord?.publish_reason ?
                                                            currentRecord?.publish_reason : "-"
                                                }
                                                </Typography>

                                            </div>



                                        </Col>

                                    </Row>
                                </Col>




                            </Row>


                        </Col>




                    </Row>
                </div>
                {/* <p>{record.checked ? "Are you sure to assist this project?" : "Are you sure to remove this project?"}</p> */}
            </Modal>
            <ToastContainer />


        </div>
    )
}

export default DonorRequestNtfcAgency
