import React, { useState, useEffect } from 'react';
import { Space, Table, Tag, Button, Tooltip, message, Spin, Modal, Input, Select } from 'antd';
import { CloseOutlined, CheckOutlined, CrownOutlined, TeamOutlined, EyeOutlined } from '@ant-design/icons';
import { allRegistrations, approveRegistration, getPendingApprovals, rejectRegistration, getPendingAndRejectedApprovals, getAgencies } from '../../api';
import { NewColorTheme, user_types } from '../../appConst.js';
import {
    CheckCircleOutlined,
    CloseCircleOutlined,
    SyncOutlined,
} from '@ant-design/icons';
import moment from 'moment';
import { Col, Divider, Row } from 'antd/es';
import FormLayouts from '../DonorsNew/Components/FormLayouts';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const UserRequestsHOD = () => {
    const [messageApi, contextHolder] = message.useMessage();
    const [data, setData] = useState([]);
    const [filteredData, setFilterData] = useState([]);
    const { confirm } = Modal;
    const { TextArea } = Input;
    const [rejectedReason, setRejectedReason] = useState('');
    const [visible, setVisible] = useState(false);
    const [record, setRecord] = useState({});
    const [disableOnSubmit, setDisableOnSubmit] = useState(false);
    const [loaded, setLoaded] = useState(false);
    const [approcalRecord, setApprovalRecord] = useState(false);
    const [agencies, setAgencies] = useState([]);

    useEffect(() => { getAgencies().then((out) => { setAgencies(out.data.out); }) }, []);
    const [open, setOpen] = useState(false);

    const showModal = (record) => {
        // setOpen(true);
        setApprovalRecord(record)
    };
    const hideModal = () => {
        setOpen(false);
    };

    useEffect(() => {
        getUsers();
    }, []);

    const error = () => {
        messageApi.open({
            type: 'error',
            content: 'Data retrieve unsuccessful',
            style: {
                marginTop: '30vh',
                height: '100vh',
                fontSize: '20px'
            },
            duration: 3
        });
    };

    const handleOnChange = (e) => {
        setRejectedReason(e.target.value)
        console.log(rejectedReason)
    }

    const handleOk = () => {
        setDisableOnSubmit(true)
        rejectRegistration({ uuid: record.uuid, reason: rejectedReason }).then((out) => {

            toast.success("User request has been discarded successfully", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            })
            // messageApi.open({
            //     type: 'error',
            //     content: 'User Request Discarded',
            //     style: {
            //         marginTop: '30vh',
            //         height: '100vh',
            //         fontSize: '20px'
            //     },
            //     duration: 3
            // });
            getUsers();
            setVisible(false);
            setRejectedReason("");
        })
        setDisableOnSubmit(false)
    };

    const handleCancel = () => {
        setVisible(false);
    };

    const rejectConfirm = (record) => {
        setRecord(record);
        setVisible(true);
    };

    const acceptConfirm = (record) => {
        confirm({
            centered: true,
            title: 'Please Confirm',
            icon: null,
            content: <p>Are you sure you want to accept <b>{record.title + " " + record.first_name + " " + record.last_name}</b>?</p>,
            okText: 'Yes',
            // okType: 'danger',
            cancelText: 'No',

            onOk() {
                approveRegistration({ uuid: record.uuid }).then((out) => {

                    toast.success("User Request Approved", {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    });

                    getUsers();
                })
            },
            onCancel() { },
        });
    };

    const getUsers = async () => {
        // const users = await getPendingApprovals();
        const users = await getPendingAndRejectedApprovals();
        console.log("UUUUU", users.data.out);
        if (users) {
            // const sortedUsers = users.data.out.sort((a, b) => new Date(a.created) - new Date(b.created));
            setTimeout(() => {
                setUserData(users.data.out);
            }, 1000);
        } else {
            error();
        }
    }

    const setUserData = (u_data) => {
        let filterData = u_data.filter((element) => element.status !== 3);
        filterData.forEach((element, idx) => {
            element['order'] = idx + 1;
            element['name'] = element.first_name + " " + element.last_name;
            user_types.forEach(element2 => {
                if (element2.level === element.user_level) {
                    element['user_type'] = element2.type
                }
            })
        });
        setFilterData(filterData)
        setData(filterData);
        setLoaded(true)
    }

    const columns = [
        // {
        //     title: 'Order',
        //     dataIndex: 'order',
        //     key: 'order'
        // },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            responsive: ['md'],
        },
        {
            title: 'Designation',
            dataIndex: 'designation',
            key: 'designation',
        },
        {
            title: 'User Type',
            key: 'user_type',
            dataIndex: 'user_type',
            render: (_, { user_type }) => (
                <Tag color={user_type === "SUPER ADMIN" || user_type === "SECRETARIAT" ? "#008282" : "#9B0259"} key={user_type} style={{ width: 120, display: 'flex', justifyContent: 'center' }} icon={user_type === "SUPER ADMIN" || user_type === "SECRETARIAT" ? <CrownOutlined /> : <TeamOutlined />}>
                    {user_type?.toUpperCase()}
                </Tag>
            ),
        },
        {
            title: 'Email Address',
            dataIndex: 'email',
            key: 'email',
            width: 200
        },
        {
            title: 'Mobile Number',
            dataIndex: 'mobile',
            key: 'mobile',
        },
        // {
        //     title: 'Phone Number',
        //     dataIndex: 'phone',
        //     key: 'phone',
        // },
        {
            title: 'Agency Name',
            dataIndex: 'agency_name',
            key: 'agency_name',
        },
        {
            title: 'Requested Date',
            dataIndex: 'created',
            key: 'created',
            render: (date) => moment(date).format("DD/MM/YYYY")
        },
        // {
        //     title: 'Status',
        //     key: 'status',
        //     dataIndex: 'status',
        //     render: (_, { status, declined_reason }) => (
        //         <div>
        //             {status === 3 ?
        //                 <Tooltip placement="topLeft" title={declined_reason ? <><b>Reason</b><br /> {declined_reason}</> : <><b>Reason :</b><br /> N/A</>}                        >
        //                     <Tag color="#f50" key={status} style={{ width: 120, display: 'flex', justifyContent: 'center' }} icon={<CloseCircleOutlined />} >REJECTED</Tag>
        //                 </Tooltip>
        //                 :
        //                 <Tag color={status === 0 ? "#2db7f5" : status === 1 ? "#87d068" : "default"} key={status} style={{ width: 120, display: 'flex', justifyContent: 'center' }} icon={status === 0 ? <SyncOutlined spin /> : status === 1 ? <CheckCircleOutlined /> : <CheckCircleOutlined />} >
        //                     {
        //                         status === 0 ? "PENDING" :
        //                             status === 1 ? "ACCEPTED" :
        //                                 "HOD ACCEPTED"
        //                     }
        //                 </Tag>
        //             }
        //         </div>
        //     ),
        // },
        // {
        //     title: 'Approved By',
        //     dataIndex: 'approved_by',
        //     key: 'approved_by',
        // },
        {
            title: 'Action',
            key: 'action',
            fixed: 'right',
            width: 100,
            render: (_, record) => (
                <Space size="middle">

                    {/* <Button type="primary" shape="circle" icon={<EyeOutlined />} size={'medium'} style={{ background: "gray" }}
                        disabled={record.status == 0 ? false : record.status == 2 ? false : true}
                        onClick={showModal(record)} />
 */}

                    <Tooltip title="Approve">
                        <Button type="primary" shape="circle" icon={<CheckOutlined />} size={'medium'} style={{ background: record.status === 0 ? '#70D983' : record.status === 2 ? '#70D983' : '#c4c4c4' }}
                            disabled={window.localStorage.getItem('view_only') == 1 ? true : (record.status == 0 ? false : record.status == 2 ? false : true)}
                            onClick={() => acceptConfirm(record)} />
                    </Tooltip>
                    <Tooltip title="Reject">
                        <Button type="primary" shape="circle" icon={<CloseOutlined />} size={'medium'} style={{ background: record.status === 0 ? '#FF8282' : record.status === 2 ? '#FF8282' : '#c4c4c4' }}
                            disabled={window.localStorage.getItem('view_only') == 1 ? true : (record.status == 0 ? false : record.status == 2 ? false : true)}
                            onClick={() => rejectConfirm(record)} />
                    </Tooltip>
                </Space>
            ),
        },
    ];
    const chairColumns = [

        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            responsive: ['md'],
        },
        {
            title: 'Designation',
            dataIndex: 'designation',
            key: 'designation',
        },
        {
            title: 'User Type',
            key: 'user_type',
            dataIndex: 'user_type',
            render: (_, { user_type }) => (
                <Tag color={user_type === "SUPER ADMIN" || user_type === "SECRETARIAT" ? "#008282" : "#9B0259"} key={user_type} style={{ width: 120, display: 'flex', justifyContent: 'center' }} icon={user_type === "SUPER ADMIN" || user_type === "SECRETARIAT" ? <CrownOutlined /> : <TeamOutlined />}>
                    {user_type?.toUpperCase()}
                </Tag>
            ),
        },
        {
            title: 'Email Address',
            dataIndex: 'email',
            key: 'email',
            width: 200
        },
        {
            title: 'Mobile Number',
            dataIndex: 'mobile',
            key: 'mobile',
        },
        // {
        //     title: 'Phone Number',
        //     dataIndex: 'phone',
        //     key: 'phone',
        // },
        {
            title: 'Agency Name',
            dataIndex: 'agency_name',
            key: 'agency_name',
        },
        {
            title: 'Requested Date',
            dataIndex: 'created',
            key: 'created',
            render: (date) => moment(date).format("DD/MM/YYYY")
        },


    ];
    const filterInquiries = (str) => {
        const filtered = data.filter((req) =>
            req.name.toLowerCase().includes(str.toLowerCase())
        );
        setFilterData([...filtered]);
    };
    const filterByAgency = (value) => {

        if (value) {
            let newData = data.filter(element => element.agency_id === value)
            setFilterData(newData)
        } else {
            setFilterData(data)
        }

    }
    return <>
        <div style={{ overflow: "hidden" }}>
            <div
                className="bgSidePanel"
                style={{ overflow: "hidden", background: NewColorTheme.primaryColor }}
            ></div>
            <div className="sidepanel-left" style={{ background: "white" }}></div>

            <FormLayouts
                title="User Requests"
                table={
                    <Row>
                        <div className="boxAction-task">
                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <Row gutter={[8, 8]} style={{ marginBottom: '-25px', width: "100%" }}>
                                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                        <Button type='primary' size='middle' onClick={() => window.location.href = '/allusers'}>View All Users</Button>

                                        <div style={{ width: "fit-content", paddingLeft: "10px", paddingRight: "10px", backgroundColor: "#d2e9f4", float: "right", borderRadius: 6 }}>
                                            <b>User count : {data.length}</b>
                                        </div>

                                    </Col>
                                    <Col xs={24} lg={8}>

                                        <Select
                                            placeholder="Agency Name"
                                            onChange={filterByAgency}
                                            style={{ width: '100%' }}
                                            options={agencies}
                                            // value={agencyName}
                                            allowClear
                                        />
                                    </Col>
                                    <Col xs={24} lg={8}>
                                        <Input.Search
                                            allowClear
                                            placeholder="Search by name"
                                            onSearch={filterInquiries}
                                            style={{ width: "100%" }}
                                        />

                                    </Col>

                                </Row>
                            </div>
                            {loaded ?
                                <div>
                                    <Table size='middle'
                                        columns={columns} dataSource={filteredData} style={{ margin: "50px 0 0 0 " }} scroll={{ y: 500, }} />
                                </div>


                                :
                                <Space>
                                    <Spin tip="Loading" size="large">
                                        <div className="content" />
                                    </Spin>
                                </Space>
                            }

                        </div>


                    </Row>

                }
            />


            <Modal
                title='Please Confirm'
                icon={<CloseCircleOutlined style={{ color: "red" }} />}
                visible={visible}
                onOk={() => handleOk()}
                onCancel={handleCancel}
                okText='Yes'
                okType='danger'
                cancelText='No'
                centered


            >
                <p>Are you sure you want to reject <b>{record.title + " " + record.first_name + " " + record.last_name}</b>?</p>
                <TextArea
                    placeholder="Rejected Reason"
                    allowClear
                    autoSize={{ minRows: 3, maxRows: 5 }}
                    value={rejectedReason}
                    onChange={(e) => setRejectedReason(e.target.value)}
                    disabled={disableOnSubmit}
                />
            </Modal>
            <Modal
                open={open}
                title="User Request Approval"
                // onOk={handleOk}
                // onCancel={handleCancel}
                footer={[
                    <Button key="back" onClick={rejectConfirm}
                        disabled={window.localStorage.getItem('view_only') == 1}>
                        Reject
                    </Button>,
                    <Button key="submit" type="primary" onClick={acceptConfirm}
                        disabled={window.localStorage.getItem('view_only') == 1}>
                        Approve
                    </Button>

                ]}
            >

            </Modal>
        </div>
        <ToastContainer />
    </>
}

export default UserRequestsHOD;