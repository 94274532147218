import { List, Typography, Input, Space, Button, message, Tooltip, Form, Select, Row, Col, DatePicker } from 'antd';
import { PlusOutlined, CloseOutlined, EditOutlined } from '@ant-design/icons';
import { useEffect, useState } from 'react';
import { addMeasure, getMeasuresList, updateMeasure, getArticles, getNextMeasureNo } from '../../api';
import { MEASURE_CATEGORIES } from '../../constants';
import moment from 'moment';
import dayjs from 'dayjs';

const Measures = ({ article }) => {
    const [items, setItems] = useState([]);

    const [data, setData] = useState([]);
    const [filtered_data, setFilteredData] = useState([]);
    const [articles, setArticles] = useState([]);
    const [defDate, setDefDate] = useState('');
    const [selectedArticle, setSelectedArticle] = useState(null);

    const [selected, setSelected] = useState(null);
    const [date, setDate] = useState();

    const [form] = Form.useForm();

    const refreshMeasures = () => {
        getMeasuresList().then((out) => {
            setItems(out.data.out);
        });
    }

    const [messageApi, contextHolder] = message.useMessage();

    const handleArticleOnchange = (val) => {
        setSelectedArticle(val);
        getNextMeasureNo(val).then((out) => {
            form.setFieldValue('measure_no', out.data.out[0].id ?? 1);
        });
    }

    const success = (msg) => {
        messageApi.open({
            type: 'success',
            content: msg,
            style: {
                marginTop: '30vh',
                height: '100vh',
                fontSize: '20px'
            },
            duration: 3
        });
    };

    const error = (msg) => {
        messageApi.open({
            type: 'error',
            content: msg,
            style: {
                marginTop: '30vh',
                height: '100vh',
                fontSize: '20px'
            },
            duration: 3
        });
    };

    const filterData = (val) => {
        console.log(data);
        const itms = data.filter((rec) => {
            console.log(rec.article_id, val);
            return rec.article_id === Number(val) || !val
        });
        console.log(itms); setFilteredData(itms);
    }

    const onFinish = (values) => {
        values.def_date = defDate;
        console.log('Success:', values);

        let prev_measure_no = values.measure_no;
        let repeated = false;
                
        if (selected) {
            items.forEach(item => {
                if (item.article_id == values.article_id) {
                    if (item.measure_no == prev_measure_no && item.measure_no != selected.measure_no) {
                        repeated = true;
                    }
                }
            })
        } else {
            items.forEach(item => {
                if (item.article_id == values.article_id) {
                    if (item.measure_no == prev_measure_no) {
                        repeated = true;
                    }
                }
            })
        }

        if (repeated) {
            error("Measure Number Already Exists");
            return;
        }

        if (!selected) {
            addMeasure(values).then((out) => {
                if (out.data.status === 200) {
                    form.resetFields();
                    setDate(null);
                    refreshMeasures();
                    success("Measure Created");
                } else {
                    error("Measure Was Not Created");
                }
            }).catch((err) => {
                console.log(err);
                messageApi.open({
                    type: 'error',
                    content: 'Measure creation unsuccessful',
                    style: {
                        marginTop: '30vh',
                        height: '100vh',
                        fontSize: '20px'
                    },
                    duration: 3
                });
            })
        } else {
            values.id = selected.id;
            updateMeasure(values).then((out) => {
                if (out.data.status === 200) {
                    form.resetFields();
                    refreshMeasures();
                    success("Measure Updated");
                    setSelected(null);
                } else {
                    error("Measure Was Not Updated");
                }
            }).catch((err) => {
                console.log(err);
                messageApi.open({
                    type: 'error',
                    content: 'Measure update unsuccessful',
                    style: {
                        marginTop: '30vh',
                        height: '100vh',
                        fontSize: '20px'
                    },
                    duration: 3
                });
            })
        }
    };
    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
        if (!selected) {
            error("Measure creation unsuccessful");
        } else {
            error("Measure update unsuccessful");
        }
    };

    const onDateChange = (date, dateString) => {
        setDefDate(dateString);
        setDate(dateString);
    };

    useEffect(() => {
        getArticles().then((art) => {
            setArticles(art.data.out);
        })
        refreshMeasures()
    }, []);

    useEffect(() => { setData(items); setFilteredData(items) }, [items]);

    useEffect(() => {
        if (selected) {
            let def_date = `${new Date().getFullYear()}-${new Date().getMonth() + 1}-${new Date().getDate()}`;
            if (selected.def_date) {
                def_date = moment(selected.def_date).format("DD/MM/YYYY");
            }
            form.setFieldValue('article_id', selected.article_id)
            form.setFieldValue('measure_no', selected.measure_no)
            form.setFieldValue('measure_name', selected.measure_name)
            form.setFieldValue('def_date', def_date)
            form.setFieldValue('summary', selected.summary)
            form.setFieldValue('category', selected.category)
            form.setFieldValue('description', selected.description)
        } else {
            form.resetFields();
        }
        // eslint-disable-next-line
    }, [selected]);

    useEffect(() => {
        if (selectedArticle) {
            filterData(selectedArticle);
        }
        // eslint-disable-next-line
    }, [selectedArticle]);

    return <div style={{ margin: '0 5px' }}>
        {contextHolder}
        <Row gutter={16}>
            <Col span={10}>
                <Typography.Title level={5} style={{ margin: 0 }}>Measures</Typography.Title>
                <Space direction='vertical' style={{ width: '100%' }}>
                    <Typography.Text type="secondary">Please Complete the Form...</Typography.Text>
                    <Form
                        form={form}
                        layout="vertical"
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                    >
                        <Row gutter={16}>
                            <Col className="gutter-row" span={12}>
                                <Form.Item label="Article" name="article_id" required
                                    rules={[{
                                        required: true,
                                        message: <p style={{ marginLeft: "12px" }}>Article is required</p>,
                                    }]}
                                >
                                    <Select
                                        options={articles}
                                        onChange={(val) => handleArticleOnchange(val)}
                                    />
                                </Form.Item>
                            </Col>
                            <Col className="gutter-row" span={12}>
                                <Form.Item label="Measure No" name="measure_no" required
                                    rules={[{
                                        required: true,
                                        message: <p style={{ marginLeft: "12px" }}>Measure No is required</p>,
                                    }]}
                                >
                                    <Input type='number' placeholder="Measure No" />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="gutter-row" span={24}>
                                <Form.Item label="Measure" name="measure_name" required
                                    rules={[{
                                        required: true,
                                        message: <p style={{ marginLeft: "12px" }}>Measure Name is required</p>,
                                    }]}
                                >
                                    <Input type='text' placeholder="Measure" />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col className="gutter-row" span={24}>
                                <Form.Item label="Summary" name="summary" required
                                    rules={[{
                                        required: true,
                                        message: <p style={{ marginLeft: "12px" }}>Summary is required</p>,
                                    }]}
                                >
                                    <Input type='text' placeholder="Summary" />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col className="gutter-row" span={12}>
                                <Form.Item label="Category" name="category" required
                                    rules={[{
                                        required: true,
                                        message: <p style={{ marginLeft: "12px" }}>Measure Category is required</p>,
                                    }]}
                                >
                                    <Select
                                        options={MEASURE_CATEGORIES}
                                    />
                                </Form.Item>
                            </Col>
                            <Col className="gutter-row" span={12}>
                                <Form.Item label="Definitive Date" name="def_date" required
                                    valuePropName='date'
                                    rules={[{
                                        required: true,
                                        message: <p style={{ marginLeft: "12px" }}>Definitive Date is required</p>,
                                    }]}
                                >
                                    <DatePicker
                                        style={{ width: '100%' }}
                                        onChange={onDateChange}
                                        format="DD/MM/YYYY"
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col className="gutter-row" span={24}>
                                <Form.Item label="Description" name="description">
                                    <Input.TextArea rows={4} placeholder="Description" />
                                </Form.Item>
                            </Col>
                        </Row>
                        {!selected ? <Button type='primary' htmlType="submit" icon={<PlusOutlined />}>
                            Add Measure
                        </Button> : <Space>
                            <Button type='primary' htmlType="submit" icon={<EditOutlined />} style={{ background: 'green' }}>
                                Update
                            </Button>
                            <Button type='primary' danger htmlType="submit" icon={<CloseOutlined />} onClick={() => setSelected(null)}>
                                Cancel
                            </Button>
                        </Space>}
                    </Form>
                </Space>
            </Col>
            <Col span={14}>
                <div style={{ display: 'flex', justifyContent: 'end', width: '100%', marginBottom: 10 }}>
                    <Input.Search style={{ width: 200 }} placeholder="search" onSearch={(val) => filterData(val)} />
                </div>
                <List
                    bordered
                    className='config-list'
                    style={{ height: '50vh', overflow: 'auto', borderColor: '#f0f0f0' }}
                    dataSource={filtered_data}
                    header={<div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', alignItems: 'center' }}>
                        <Row gutter={16} style={{ width: '100%' }}>
                            <Col span={3}>
                                <Typography.Text>#</Typography.Text>
                            </Col>
                            <Col span={8}>
                                <Typography.Text>Measure</Typography.Text>
                            </Col>
                            <Col span={5}>
                                <Typography.Text>Summary</Typography.Text>
                            </Col>
                            <Col span={3}>
                                <Typography.Text>Category</Typography.Text>
                            </Col>
                            <Col span={4}>
                                <Typography.Text>Definitive Date</Typography.Text>
                            </Col>
                            <Col span={1}>
                            </Col>
                        </Row>
                    </div>}
                    renderItem={(item) => (
                        <List.Item className={article && article.id === item.id ? 'config-list-item-selected' : 'config-list-item'}>
                            <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', alignItems: 'center' }}>
                                <Row gutter={16} style={{ width: '100%' }}>
                                    <Col span={3}>
                                        <Typography.Text style={article && article.id === item.id ? { color: 'white' } : {}}>{item.article_no}.{item.measure_no}</Typography.Text>
                                    </Col>
                                    <Col span={8}>
                                        <Typography.Text style={article && article.id === item.id ? { color: 'white' } : {}}>{item.measure_name}</Typography.Text>
                                    </Col>
                                    <Col span={5}>
                                        <Typography.Text style={article && article.id === item.id ? { color: 'white' } : {}}>{item.summary}</Typography.Text>
                                    </Col>
                                    <Col span={3}>
                                        <Typography.Text style={article && article.id === item.id ? { color: 'white' } : {}}>{item.category}</Typography.Text>
                                    </Col>
                                    <Col span={4}>
                                        <Typography.Text style={article && article.id === item.id ? { color: 'white' } : {}}>
                                            {item.def_date ? moment(item.def_date).format('DD/MM/YYYY') : "-"}
                                        </Typography.Text>
                                    </Col>
                                    <Col span={1}>
                                        <Tooltip title="View/Edit">
                                            <Button type="secondary" size='small' onClick={() => setSelected(item)} shape="circle" icon={<EditOutlined style={{ color: '#946e6e' }} />} />
                                        </Tooltip>
                                    </Col>
                                </Row>
                            </div>
                        </List.Item>
                    )}
                />
            </Col>
        </Row>
    </div>
}

export default Measures;