import { Card, Col, Divider, Progress, Row, Space, Tag, Typography } from 'antd'
import React, { useEffect, useState } from 'react'
import './MainCard.css'
import MainCard from './MainCard'
import { GetAgencyWiseAllArticles, getAgencyWiseDashboard, getCountryWiseDashboard, getPendingInquiryCounts, getStatusChangeRequestsCount, getUserRequestsCount } from '../../../../api';
import CountUp from 'react-countup';

import dayjs from 'dayjs';

function MainCards({ report, userData, userLevel, val }) {

    const [shouldAnimate, setShouldAnimate] = useState(false);
    const [reports, setReports] = useState(null);
    const [UserRequests, setCount] = useState({})
    const [StatusCount, setStatusCount] = useState({})
    const [InquiryCount, setInquiryCount] = useState({})
    const [articleCompletion, setArticleCompletion] = useState([]);
    const [measures, setMeasures] = useState();
    const level = window.localStorage.getItem('ntfc_level');

    useEffect(() => {
        setShouldAnimate(true);
        getArticles()
    }, [val]);

    console.log("MAIN CARD REPORT", report);


    const getArticles = async () => {

        // if (window.localStorage.getItem("ntfc_level") == 6 || window.localStorage.getItem("ntfc_level") == 3 || window.localStorage.getItem("ntfc_level") == 2 || window.localStorage.getItem("ntfc_level") == -1) {
        //     GetAgencyWiseAllArticles(0).then((out) => {
        //         console.log("measure comple", out)

        //         let measures = []

        //         out.data.out.forEach(element => {

        //             element.measures.forEach(e => {
        //                 let maesure = {
        //                     measure: e.measure_name,
        //                     def_date: dayjs(e.extended_def_date ? e.extended_def_date : e.measure_def_date).format("DD/MM/YYYY"),
        //                     category: e.category,
        //                     measure_completion: e.measure_completion,
        //                     definitiveDate: e.measure_def_date,
        //                     extented: e.extended_def_date ? true : false
        //                 }
        //                 measures.push(maesure)
        //             })


        //         });

        //         const currentDate = new Date();
        //         const maxDate = new Date();
        //         maxDate.setDate(currentDate.getDate() + 30);


        //         const filteredData = measures.filter(obj => {
        //             const defDate = new Date(obj.definitiveDate);
        //             return defDate >= currentDate;
        //         });

        //         let netSortedArr = filteredData.slice(0, 10).sort((a, b) => {
        //             const dateA = new Date(a.definitiveDate);
        //             const dateB = new Date(b.definitiveDate);
        //             return dateA - dateB;
        //         })

        //         setMeasures(netSortedArr)
        //         console.log('Measures.. ---------------------------------', filteredData)
        //         setArticleCompletion(out.data.out)
        //     })
        // } else {
        //     GetAgencyWiseAllArticles(await window.localStorage.getItem('ntfc_agency')).then((out) => {
        //         console.log("measure comple", out)

        //         let measures = []

        //         out.data.out.forEach(element => {

        //             element.measures.forEach(e => {
        //                 let maesure = {
        //                     measure: e.article_no + "." + e.measure_no + ". " + e.measure_name,
        //                     def_date: dayjs(e.extended_def_date ? e.extended_def_date : e.measure_def_date).format("DD/MM/YYYY"),
        //                     category: e.category,
        //                     measure_completion: e.measure_completion,
        //                     definitiveDate: e.measure_def_date,
        //                     extented: e.extended_def_date ? true : false,
        //                 }
        //                 measures.push(maesure)
        //             })


        //         });

        //         const currentDate = new Date();
        //         const maxDate = new Date();
        //         maxDate.setDate(currentDate.getDate() + 30);


        //         const filteredData = measures.filter(obj => {
        //             const defDate = new Date(obj.definitiveDate);
        //             return defDate >= currentDate;
        //         });

        //         let netSortedArr = filteredData.slice(0, 10).sort((a, b) => {
        //             const dateA = new Date(a.definitiveDate);
        //             const dateB = new Date(b.definitiveDate);
        //             return dateA - dateB;
        //         })

        //         setMeasures(netSortedArr)
        //         console.log('Measures.. ---------------------------------', filteredData)
        //         setArticleCompletion(out.data.out)
        //     })
        // }
        GetAgencyWiseAllArticles(val).then((out) => {
            console.log("measure comple", out)

            let measures = []

            out.data.out.forEach(element => {

                element.measures.forEach(e => {
                    let maesure = {
                        measure: e.article_no + "." + e.measure_no + " -  " + e.measure_name,
                        def_date: dayjs(e.extended_def_date ? e.extended_def_date : e.measure_def_date).format("DD/MM/YYYY"),
                        category: e.category,
                        measure_completion: e.measure_completion,
                        definitiveDate: e.measure_def_date,
                        extented: e.extended_def_date ? true : false
                    }
                    measures.push(maesure)
                })


            });

            const currentDate = new Date();
            const maxDate = new Date();
            maxDate.setDate(currentDate.getDate() + 30);


            const filteredData = measures.filter(obj => {
                const defDate = new Date(obj.definitiveDate);
                return defDate >= currentDate;
            });

            let netSortedArr = filteredData.slice(0, 10).sort((a, b) => {
                const dateA = new Date(a.definitiveDate);
                const dateB = new Date(b.definitiveDate);
                return dateA - dateB;
            })

            setMeasures(netSortedArr)
            console.log('Measures.. ---------------------------------', filteredData)
            setArticleCompletion(out.data.out)
        })

    }


    function getColor(percentage) {

        if (percentage <= 25) {
            return "#aed8e6"
        }
        if (percentage > 25 && percentage <= 85) {
            return "#0492c2"
        }
        if (percentage > 100 && percentage > 85) {
            return "#1520a6"
        }

    }

    const TabTile = ({ measure, completion, date, category, extented }) => <Row gutter={[16, 16]}>
        <Col xs={11} style={{ textAlign: "left", justifyContent: "start", alignItems: "center", display: "flex" }}>
            <Typography>{measure}</Typography>

        </Col>
        <Col xs={4} style={{ textAlign: "center", display: "flex", justifyContent: "center" }}>
            <Progress
                type="circle"
                strokeColor={getColor(completion)}
                // trailColor={getColor(action.action_completion)}
                percent={completion}
                strokeWidth={10}
                size={50}
            // format={(number) => `进行中，已完成${number}%`}
            />

        </Col>
        {extented ?
            <Col xs={5} style={{ textAlign: "center", display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" }}>
                <Typography style={{ fontSize: 12, whiteSpace: "nowrap" }}>{date} </Typography>
                {extented && <Tag style={{ marginLeft: 8 }} color="volcano">
                    Extended
                </Tag>}
            </Col> :
            <Col xs={5} style={{ textAlign: "center", display: "flex", justifyContent: "center", alignItems: "center" }}>
                <Typography style={{ fontSize: 12, whiteSpace: "nowrap" }}>{date} </Typography>
            </Col>}

        <Col xs={4} style={{ textAlign: "center", display: "flex", justifyContent: "center", alignItems: "center" }}>
            <Typography>{category === "A" ? <Tag color={"#99d8f5"}>A</Tag> : category === "B" ? <Tag color="#2db7f5">B</Tag> : <Tag color="#108ee9">C</Tag>}
            </Typography>
        </Col>
        <Col span={24}>
            <Divider />

        </Col>


    </Row>

    return (
        <div style={{
            display: "flex",
            // height: "35vh",
            width: "100%",
            // padding: "1% 5%",
            cursor: "default"
        }}>
            <div style={{
                width: '100%',
                paddingTop: '20px',
                paddingLeft: '5px',
                overflow: 'hidden',
                height: '200vh',
                paddingBottom: '29px',
                cursor: "default"
            }} >

                <div style={{ width: "100%", padding: "0 1%" }}>
                    <Row gutter={[16, 16]}>
                        <Col xs={11} style={{ textAlign: "left", cursor: "default" }}>
                            <Typography style={{ fontWeight: 700, whiteSpace: "nowrap" }}>Measure</Typography>

                        </Col>
                        <Col xs={4} style={{ textAlign: "center", cursor: "default", display: "flex", justifyContent: "center" }}>
                            <Typography style={{ fontWeight: 700, whiteSpace: "nowrap" }}>Completion</Typography>
                        </Col>
                        <Col xs={5} style={{ textAlign: "center", cursor: "default", display: "flex", justifyContent: "center" }}>
                            <Typography style={{ fontWeight: 700, whiteSpace: "nowrap" }} >Definitive Date</Typography>
                        </Col>
                        <Col xs={4} style={{ textAlign: "center", cursor: "default", display: "flex", justifyContent: "center" }}>
                            <Typography style={{ fontWeight: 700, whiteSpace: "nowrap" }}>Category</Typography>
                        </Col>
                        <Col span={24}>
                            <div
                                className=' user-measure custom-scroll-div' style={{
                                    width: '100%',
                                    paddingTop: '20px',
                                    paddingLeft: '5px',
                                    overflow: 'hidden auto',
                                    height: '190vh',
                                    paddingBottom: '29px',
                                    borderTop: '3px solid #282d93',
                                    borderRadius: '0px',
                                    cursor: "default"
                                }}
                            >
                                {measures && measures.map((e, k) => {
                                    return <TabTile
                                        measure={e.measure}
                                        completion={e.measure_completion}
                                        date={e.def_date}
                                        category={e.category}


                                    />

                                })}

                            </div>

                        </Col>



                    </Row>


                </div>
            </div>








        </div>
    )
}

export default MainCards