import { Alert, Avatar, Button, Card, Checkbox, Col, List, Row, Select, Spin, Typography } from 'antd'
import React, { useState, useEffect } from 'react'
import './donors.css'
import Title from '../../Components/TitleBox/Title'
import RowData from './RowData'
import SimpleBar from 'simplebar-react';
import 'simplebar-react/dist/simplebar.min.css';
import CustomTable from '../../Components/Table';
import { GetAllDonorList, GetDonorRequestInterests, getMeasuresList, getDonorContribution, getAllDonorRequestInterests, GetDonorInterestsByAgency } from '../../api'
import { UserOutlined, ArrowLeftOutlined } from "@ant-design/icons";
import moment from 'moment'

import { ToastContainer, toast, Zoom } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';




const data = []

export const Donors = () => {

    const [loaded, setLoad] = useState(true)
    const [loading, setLoading] = useState(false);
    const [filtered_data, setFilteredData] = useState([]);
    const [donors, setDonors] = useState([]);
    const [donorProfile, setDonorProfile] = useState([]);
    const [donorContributions, setDonorContributions] = useState([]);
    const [measures, setMeasures] = useState([]);
    const [currentMeasures, setCurrentMeasures] = useState([])
    const [donotInterest, setDonorInterests] = useState([])
    const [ip_loaded, setIPLoaded] = useState(false)
    // const [allDonors, setAllDonors] = useState([])

    // const filterData = (val) => {
    //     const itms = data.filter((rec) => {
    //         return rec.article_id === Number(val) || !val
    //     });
    //     console.log(itms); setFilteredData(itms);
    // }

    useEffect(() => {

        getAllMeasures()

    }, []);

    // Get All DOnors
    useEffect(() => {

        getAllDonors();

        // setFilteredData([{}])

    }, []);

    useEffect(() => {

        donorInterestsByAgency();

    }, []);

    const donorInterestsByAgency = async () => {
        GetDonorInterestsByAgency(38).then((out31) => {
            // setDonorInterests(out3.data.out);
        });
    }


    const handleContributions = async (donor) => {
        const out2 = await getDonorContribution(donor.id);
        // const allArr = out2.data.out.requests.concat(out2.data.out.interests);
        const allArr = out2.data.out;
        const result = [];
        for (let i = 0; i < allArr.length; i++) {
            let isDuplicate = false;

            for (let j = 0; j < result.length; j++) {
                if (allArr[i].agency === result[j].agency) {
                    result[j].agency_data.push(allArr[i]);
                    isDuplicate = true;
                    break;
                }
            }

            if (!isDuplicate) {
                result.push({
                    agency_name: allArr[i].agency_name,
                    agency: allArr[i].agency,
                    agency_data: [
                        allArr[i]
                    ]
                });
            }
        }

        result.forEach(element => {
            element["agency_name"] = donor.agency_name
            element.agency_data.forEach(element2 => {
                measures.forEach(element3 => {
                    if (element3.id == element2.measure) {
                        element2['measure_name'] = element3.measure_name
                    }
                });
            });
        });

        console.log("result", result)
        // console.log("result2",result[0].agency_data)
        setDonorContributions(result);
    }

    const getAllDonors = async function () {
        const out = await GetAllDonorList()
        console.log("out-----------", out);
        if (out.status === 200) {
            setDonors(out.data.out)
            setDonorProfile(out.data.out[0]);
            // handleContributions(out.data.out[0]);
            // getDonorRequestInterest(out.data.out[0]);
            // setDonorProfile(out.data.out[0])
            // handleContributions(out.data.out[0])
            // setDonorContributions([
            //     {
            //         name: "Agency1",
            //         measure:
            //             [
            //                 {
            //                     name: "Measure1",
            //                     content: [
            //                         {
            //                             project: "Project1",
            //                             type: "Type1",
            //                             s_date: "02/02/2023"
            //                         }
            //                     ]
            //                 },
            //                 {
            //                     name: "Measure2",
            //                     content: [
            //                         {
            //                             project: "Project1",
            //                             type: "Type1",
            //                             s_date: "02/02/2023"
            //                         },
            //                         {
            //                             project: "Project2",
            //                             type: "Type2",
            //                             s_date: "02/02/2023"
            //                         }
            //                     ]
            //                 }
            //             ]
            //     },
            //     {
            //         name: "Agency1",
            //         measure:
            //             [
            //                 {
            //                     name: "Measure1",
            //                     content: [
            //                         {
            //                             project: "Project1",
            //                             type: "Type1",
            //                             s_date: "02/02/2023"
            //                         }
            //                     ]
            //                 },
            //                 {
            //                     name: "Measure2",
            //                     content: [
            //                         {
            //                             project: "Project1",
            //                             type: "Type1",
            //                             s_date: "02/02/2023"
            //                         },
            //                         {
            //                             project: "Project2",
            //                             type: "Type2",
            //                             s_date: "02/02/2023"
            //                         }
            //                     ]
            //                 }
            //             ]
            //     }
            // ]
            // )
        }
    }

    const data = [

        // More data items can be added here
    ];

    const columns = [


        {
            title: 'TFA Related',
            dataIndex: 'tfa_related',
            key: 'tfa_related',

        },
        {
            title: 'Subject',
            dataIndex: 'subject',
            key: 'subject',

        },
        {
            title: 'Donation',
            dataIndex: 'donation',
            key: 'donation',

        },
        {
            title: 'Description',
            dataIndex: 'description',
            key: 'description',

        },
        {
            title: 'Action',
            key: 'action',
            render: (_, record) => (
                record ?
                    <div>
                        <Checkbox indeterminate />
                    </div>
                    : null
            ),
        },

    ];

    const getDonorInterests = async () => {
        GetDonorRequestInterests().then((out3) => {
            setDonorInterests(out3.data.out);
        });
    }

    const allDonorInterests = async () => {
        setIPLoaded(false)
        await getAllDonorRequestInterests().then((out4) => {
            if (out4.status === 200) {
                console.log('allout', out4.data.out)
                setFilteredData(out4.data.out)
            }
            setIPLoaded(true)
        });
    }

    GetDonorRequestInterests().then((out3) => {
        // setInterests(out3.data.out);
    });


    const getAllMeasures = async function () {

        const out = await getMeasuresList()

        if (out.status === 200) {
            setMeasures(out.data.out)
        }
    }

    const getDonorRequestInterest = async function (data) {


        console.log("data", data)
        allDonorInterests()
        const out = await GetDonorRequestInterests()
        console.log(out);
        if (out.status === 200) {
            let measures2;
            if (measures.length > 0) {
                measures2 = measures;
            } else {
                const measureOut = await getMeasuresList()
                measures2 = measureOut.data.out;
            }
            let filterData = out.data.out.filter((element) => element.agency_id === data.agency)
            // let filterData = out.data.out.filter((element) => element.user_id === userID)
            let DonorMeasures = []
            // console.log("userID", userID)
            // console.log("filterData", filterData)
            // let filterData = out.data.out;
            for (let i = 0; i < filterData.length; i++) {
                const currentObject = filterData[i];

                // Find the matching object in the second array
                const matchingObject = measures2.find(obj => obj.id === currentObject.request_id);
                console.log("matchingObject", matchingObject)
                DonorMeasures.push(matchingObject)
            }
            if (measures.length > 0 && DonorMeasures.length > 0) {
                setCurrentMeasures(DonorMeasures)
            }

            if (filterData.length === 0) {
                setCurrentMeasures([])
            }

            setLoad(true)
        }
    }

    return (
        <div className='donor-container' style={{ overflow: 'scroll' }}>
            <div style={{ display: "flex", justifyContent: "flex-start", position: 'absolute', top: 20, left: 58 }}>

                <Button
                    onClick={() => { window.location.href = "/newdashboard" }}
                    // disabled={disable ? true : false}
                    shape="circle"
                    icon={<ArrowLeftOutlined style={{ color: "#650164" }} />}
                    style={{ width: 50, height: 50, backgroundColor: "white", color: "black", borderRadius: 50, zIndex: 999 }}
                />

            </div>
            <div className="donor-upper-div" style={{ background: "rgba(94, 0, 102, 0.3)", minHeight: '200px', top: 0 }}>
            </div>
            {loaded ?
                <div style={{ padding: '70px 60px 0' }}>

                    <div className='body-container' style={{ paddingBottom: '24px' }}>
                        <Row gutter={[12, 24]} style={{ marginBottom: '24px' }}>
                            <Col xs={24} sm={24} md={10} lg={10} xl={10}>
                                {/* <Col xs={24} sm={24} md={5} lg={5} xl={5}> */}
                                <Card className='card card2' style={{ height: '80vh' }}>
                                    <Title title="Donors" />
                                    <div className='list-container'>
                                        <List
                                            dataSource={donors}
                                            renderItem={(item, key) => (
                                                <List.Item className="hover-effect" key={key} onClick={() => {

                                                    setDonorProfile(donors[key])
                                                    getDonorRequestInterest(donors[key])
                                                    handleContributions(donors[key])

                                                }}>
                                                    <List.Item.Meta
                                                        avatar={<Avatar style={{
                                                            backgroundColor: '#87d068',
                                                        }}
                                                            icon={<UserOutlined />} />}
                                                        title={<span className="donor-donortilename">{item.agency_name}</span>}
                                                        description={item.description}
                                                    />
                                                    <div>

                                                        {/* <Avatar style={{ background: "rgba(94, 0, 102, 0.3)" }} size={50}>50</Avatar> */}

                                                    </div>
                                                </List.Item>
                                            )}
                                        />
                                    </div>
                                </Card>
                            </Col>
                            {/* <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                                <Card className='card' style={{ height: '80vh', overflow: "scroll" }}>
                                    <Title title="Profile" />
                                    <div className='profile-container'>
                                        <div className='profile'>

                                        </div>
                                        <div className='profile-content'>
                                            <Typography.Title level={2}>{donorProfile.agency_name}</Typography.Title>
                                            <Typography.Paragraph style={{
                                                fontSize: '20px',
                                                marginTop: '-16px',
                                                paddingLeft: '1px'
                                            }}>{donorProfile.description}</Typography.Paragraph>

                                        </div>
                                    </div>


                                    <Title title="Interested Measures" />
                                    <div className='row-container' style={{ height: '230px', overflow: 'hidden' }}>
                                        <SimpleBar style={{ maxHeight: 300 }}>
                                            <div className='row-container'>
                                                {
                                                    currentMeasures.map((e, k) => {
                                                        return <RowData key={k} number={e ? e.measure_no ? e.measure_no : "" : ""} content={e ? e.measure_name ? e.measure_name : "" : ""} />
                                                    })
                                                }
                                            </div>
                                        </SimpleBar>

                                    </div>


                                    <Title title="Interested Projects" />
                                    <div style={{ marginTop: 25, height: 200, overflow: 'scroll' }}>
                                        {ip_loaded ?

                                            <CustomTable columns={columns} data={filtered_data}
                                                pagination={{
                                                    pageSize: 10,
                                                    total: filtered_data.length,
                                                }}
                                                style={{ width: "100%" }}
                                            />

                                            : ""}
                                    </div>

                                </Card>
                            </Col> */}
                            {/* <Col xs={24} sm={24} md={7} lg={7} xl={7}> */}
                            <Col xs={24} sm={24} md={14} lg={14} xl={14}>
                                <Card className='card card2' style={{ height: '80vh', fontSize: "12px" }}>
                                    <Title title="Contributions" />
                                    <div className='list-container'>
                                        {donorContributions.map((item, index) => (
                                            <div key={index} style={{ border: "1px solid #ed7158", marginBottom: "7px", paddingBottom: "5px" }}>
                                                <div style={{ marginRight: "2px", border: "1px solid #ed7158", width: "fit-content", color: "black", paddingLeft: "5px", paddingRight: "5px", marginTop: "-1px", marginLeft: "-1px" }}>{item.agency_name}</div>
                                                {item.agency_data.map((item2, index2) => (
                                                    <div key={index2} style={{ paddingLeft: "10px", paddingTop: "2px" }}>
                                                        <div style={{ marginRight: "2px", border: "1px solid #326379", width: "fit-content", color: "black", paddingLeft: "5px", paddingRight: "5px" }}>Measure Name: {item2.measure_name ? item2.measure_name : "-"}</div>
                                                        <div style={{ paddingLeft: "10px", paddingTop: "2px" }}>
                                                            <div style={{ marginRight: "2px", border: "1px solid #1f9a77", width: "51%", color: "black", display: "inline-block", paddingLeft: "2px", paddingRight: "2px" }}>Subject</div>
                                                            <div style={{ marginRight: "2px", border: "1px solid #1f9a77", width: "21%", color: "black", display: "inline-block", paddingLeft: "2px", paddingRight: "2px" }}>Support Type</div>
                                                            <div style={{ marginRight: "2px", border: "1px solid #1f9a77", width: "25%", color: "black", display: "inline-block", paddingLeft: "2px", paddingRight: "2px" }}>Start date</div>
                                                        </div>
                                                        {/* {item2.content.map((item3, index3) => ( */}
                                                        <div style={{ paddingLeft: "10px", paddingTop: "2px" }}>
                                                            <div style={{ marginRight: "2px", border: "1px solid #9b0259", width: "51%", color: "black", display: "inline-block", paddingLeft: "2px", paddingRight: "2px" }}>{item2.project_name}</div>
                                                            <div style={{ marginRight: "2px", border: "1px solid #9b0259", width: "21%", color: "black", display: "inline-block", paddingLeft: "2px", paddingRight: "2px" }}>{item2.assistance == 0 ? "Tech. Sup." : "C. Build."}</div>
                                                            <div style={{ marginRight: "2px", border: "1px solid #9b0259", width: "25%", color: "black", display: "inline-block", paddingLeft: "2px", paddingRight: "2px" }}>{moment(item2.created).format("DD/MM/YYYY")}</div>
                                                        </div>
                                                        {/* ))} */}
                                                    </div>
                                                ))}
                                            </div>
                                        ))}
                                    </div>
                                </Card>
                            </Col>
                        </Row>
                    </div>
                </div>
                :
                <div className="spin-container">
                    <Spin size="large" />
                </div>
            }
        </div>
    )
}
