import {
  Button,
  Col,
  ConfigProvider,
  Row,
  Space,
  Table,
  Tag,
  Typography,
  List,
  Avatar,
  Modal,
  Input,
} from "antd";
import VirtualList from "rc-virtual-list";
import { useEffect, useState } from "react";
import TabLayout from "./TabLayout";
import { ToastContainer, toast, Zoom } from "react-toastify";
import {
  getAllDonorRequestInterests,
  DownloadFiles,
  donorRequestAssign,
  donorRequestUnassign,
  getConfirmedInterestProjects,
  getConfirmedRequestProjects,
} from "../../api/index.js";
import { getUserData } from "../Donors/getCounts.js";
import moment from "moment";
import {
  CalendarOutlined,
  ContainerOutlined,
  ReconciliationOutlined,
  FlagOutlined,
  CloudUploadOutlined,
  CalendarFilled,
  FileDoneOutlined,
  BorderlessTableOutlined,
  BoxPlotOutlined,
  ContactsOutlined
} from "@ant-design/icons";
import dayjs from "dayjs";
import { MainColors, NewColorTheme } from "../../appConst";
import { BiCloudDownload } from "react-icons/bi";

const ConfirmedDonorRequests = ({ showMessage, setRefreshCounts, data, setData }) => {
  const [currentData, setCurrentData] = useState({});
  const [user, setUser] = useState({});
  const [assignDialogOpen, setAssignDialogOpen] = useState(false);
  const [this_data, setThis_data] = useState({});
  const [assignReason, setAssignReason] = useState("");
  const [reasonError, setReasonError] = useState(false);
  const [viewDialog, setViewDialog] = useState(false);
  const [isChair, setChair] = useState(false)
  const handleCancelView = () => {
    setViewDialog(false);
  };
  useEffect(() => {
    if (window.localStorage.getItem('ntfc_level') == 6) {
      setChair(true)
    } else {
      setChair(false)
    }

    reloadData();
    getUserDetails();
  }, []);

  const reloadData = () => {
    getConfirmedRequestProjects().then((res) => {
      let newData = res.data.out;
      for (const obj of newData) {
        const { interests, assignees } = obj;
        const matchingUsers = interests.filter((interest) => {
          return assignees.some(
            (assignee) => assignee.user_id === interest.user_id
          );
        });

        if (matchingUsers.length > 0) {
          matchingUsers.forEach((matchingUser) => {
            matchingUser.donorAssigned = true;
          });
        }
      }
      console.log("matchingUser------------>>", newData);
      var sortedArray = newData.sort(
        (a, b) => new Date(b.created) - new Date(a.created)
      );

      setData(sortedArray);
    });
  };

  const getUserDetails = async function () {
    const user = await getUserData();
    setUser(user);
  };

  const getStatus = (status) => {
    if (status == 2) {
      return (
        <Tag
          color={"#007613"}
          key={status}
          style={{ width: 120, display: "flex", justifyContent: "center" }}
        >
          {"ASSIGNED"}
        </Tag>
      );
    } else {
      return (
        <Tag
          color={"#0492c2"}
          key={status}
          style={{ width: 120, display: "flex", justifyContent: "center" }}
        >
          {"PENDING"}
        </Tag>
      );
    }
  };

  const columns = [
    {
      title: "Creation Date",
      dataIndex: "created",
      key: "created",
      render: (_, { created }) => (
        <p>{created ? moment(created).format("DD/MM/YYYY") : "-"}</p>
      ),
    },
    {
      title: "Agency Name",
      dataIndex: "agency_name",
      key: "agency_name",
      render: (_, { scope, agency_name }) => (
        <p>{scope == 0 ? "National Wise" : agency_name ? agency_name : "-"}</p>
      ),
    },
    {
      title: "Subject",
      dataIndex: "subject",
      key: "subject",
    },
    {
      title: "Measure",
      dataIndex: "measure_name",
      key: "measure_name",
      render: (_, { measure_no, article_no, measure_name }) => (
        <p>{article_no + "." + measure_no + ". " + measure_name}</p>
      ),
    },
    {
      title: "Support Type",
      dataIndex: "assistance",
      key: "assistance",
      render: (_, { assistance }) => (
        <p
          key={assistance}
          style={{ display: "flex", justifyContent: "center" }}
        >
          {assistance == 0 ? <Tag style={{ display: "flex", justifyContent: "center" }} color={NewColorTheme.primaryColor}>TS/CB</Tag> :
            <Tag style={{ display: "flex", justifyContent: "center" }} color="#08B3D7">OTHER</Tag>}

        </p>
      ),
    },
    {
      title: "Tentative Start Date",
      dataIndex: "implementation_from",
      key: "implementation_from",
      render: (_, { implementation_from }) => (
        <p>
          {implementation_from
            ? moment(implementation_from).format("DD/MM/YYYY")
            : "-"}
        </p>
      ),
    },
    {
      title: "Tentative End Date",
      dataIndex: "implementation_to",
      key: "implementation_to",
      render: (_, { implementation_to }) => (
        <p>
          {implementation_to
            ? moment(implementation_to).format("DD/MM/YYYY")
            : "-"}
        </p>
      ),
    },
    {
      title: "Published Date",
      dataIndex: "published_date",
      key: "published_date",
      render: (_, { created_agency_id, published_date, created }) => (
        <p>
          {created_agency_id == 0
            ? moment(created).format("DD/MM/YYYY")
            : published_date
              ? moment(published_date).format("DD/MM/YYYY")
              : "-"}
        </p>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (_, { status }) => getStatus(status),
    },
  ];

  const handleAssignDialog = (item) => { };

  const fetchFile = async (file) => {
    let out = await DownloadFiles(file.token);

    let blob = new Blob([out.data], { type: file.type });
    let url = window.URL.createObjectURL(blob);

    let link = document.createElement("a");
    link.href = url;

    link.download = file.url.split("-::-")[1];

    document.body.appendChild(link);
    link.click();
  };

  const assignDonor = async () => {

    console.log("Assigned", this_data);
    let data = {
      id: this_data.request_id,
      agency_id: this_data.agency_id,
      user_id: this_data.user_id,
      reason: assignReason,
    };

    if (!data.reason || data.reason == "" || data.reason == null) {
      setReasonError(true);
    } else {
      setReasonError(false);
      setAssignDialogOpen(false);
      let out;
      if (this_data.donorAssigned) {
        out = await donorRequestUnassign(data);
      } else {
        out = await donorRequestAssign(data);
      }

      console.log("out", out);
      if (out.status === 200) {
        toast.success(out.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        reloadData();
        setCurrentData({});
      } else {
        toast.error(out.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
      setAssignReason("");

      setViewDialog(false);
    }
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        overflow: "hidden",
      }}
    >
      <TabLayout
        title="Request Details"
        // formSide={
        //     <div>
        //         {currentData.id &&
        //             <ConfigProvider
        //                 theme={{
        //                     token: {
        //                         colorPrimary: '#006666',
        //                     },
        //                 }}>

        //                 <div className='list-container'>

        //                     <Row gutter={[16, 16]}>
        //                         <Col span={24}>
        //                             <Row style={{ width: '100%' }}>
        //                                 <Col style={{ width: '30%' }}>
        //                                     <Typography.Title level={5}>
        //                                         Subject
        //                                     </Typography.Title>
        //                                 </Col>
        //                                 <Col style={{ paddingTop: '2px' }}>
        //                                     <Typography.Paragraph style={{ fontWeight: '500' }}>
        //                                         {currentData.subject}
        //                                     </Typography.Paragraph>
        //                                 </Col>
        //                             </Row>
        //                         </Col>

        //                         <Col span={24}>
        //                             <Row style={{ width: '100%' }}>
        //                                 <Col style={{ width: '30%' }}>
        //                                     <Typography.Title level={5}>
        //                                         Measure
        //                                     </Typography.Title>
        //                                 </Col>
        //                                 <Col style={{ paddingTop: '2px' }}>
        //                                     <Typography.Paragraph style={{ fontWeight: '500' }}>
        //                                         {currentData.measure && `${currentData.measure} - ${currentData.measure_name}`}
        //                                     </Typography.Paragraph>
        //                                 </Col>
        //                             </Row>
        //                         </Col>

        //                         <Col span={24}>
        //                             <Row style={{ width: '100%' }}>
        //                                 <Col style={{ width: '30%' }}>
        //                                     <Typography.Title level={5}>
        //                                         Support Type
        //                                     </Typography.Title>
        //                                 </Col>
        //                                 <Col style={{ paddingTop: '2px' }}>
        //                                     <Typography.Paragraph style={{ fontWeight: '500' }}>
        //                                         {currentData?.assistance == 0 ? "Technical Support" : currentData?.assistance == 1 ? "Capacity Building" : "Other"}
        //                                     </Typography.Paragraph>
        //                                 </Col>
        //                             </Row>
        //                         </Col>

        //                         <Col span={24}>
        //                             <Row style={{ width: '100%' }}>
        //                                 <Col style={{ width: '30%' }}>
        //                                     <Typography.Title level={5}>
        //                                         Description
        //                                     </Typography.Title>
        //                                 </Col>
        //                                 <Col style={{ paddingTop: '2px' }}>
        //                                     <Typography.Paragraph style={{ fontWeight: '500' }}>
        //                                         {currentData.description}
        //                                     </Typography.Paragraph>
        //                                 </Col>
        //                             </Row>
        //                         </Col>

        //                         <Col span={24}>
        //                             <Row style={{ width: '100%' }}>
        //                                 <Col style={{ width: '30%' }}>
        //                                     <Typography.Title level={5}>
        //                                         Agency
        //                                     </Typography.Title>
        //                                 </Col>
        //                                 <Col style={{ paddingTop: '2px' }}>
        //                                     <Typography.Paragraph style={{ fontWeight: '500' }}>
        //                                         {currentData.created_user}
        //                                     </Typography.Paragraph>
        //                                 </Col>
        //                             </Row>
        //                         </Col>

        //                         <Col span={24}>
        //                             <Row style={{ width: '100%' }}>
        //                                 <Col style={{ width: '30%' }}>
        //                                     <Typography.Title level={5}>
        //                                         Tentative Start Date
        //                                     </Typography.Title>
        //                                 </Col>
        //                                 <Col style={{ paddingTop: '2px' }}>
        //                                     <Typography.Paragraph style={{ fontWeight: '500' }}>
        //                                         {moment(currentData.implementation_from).format("DD/MM/YYYY")}
        //                                     </Typography.Paragraph>
        //                                 </Col>
        //                             </Row>
        //                         </Col>

        //                         <Col span={24}>
        //                             <Row style={{ width: '100%' }}>
        //                                 <Col style={{ width: '30%' }}>
        //                                     <Typography.Title level={5}>
        //                                         Tentative End Date
        //                                     </Typography.Title>
        //                                 </Col>
        //                                 <Col style={{ paddingTop: '2px' }}>
        //                                     <Typography.Paragraph style={{ fontWeight: '500' }}>
        //                                         {moment(currentData.implementation_to).format("DD/MM/YYYY")}
        //                                     </Typography.Paragraph>
        //                                 </Col>
        //                             </Row>
        //                         </Col>
        //                         {currentData.attachments.length > 0 &&

        //                             <Col span={24}>
        //                                 <Row style={{ width: '100%' }}>
        //                                     <Col style={{ width: '100%' }}>
        //                                         <Typography.Title level={5}>
        //                                             Attachments
        //                                         </Typography.Title>
        //                                     </Col>
        //                                 </Row>
        //                                 <Row gstyle={{ width: '100%' }}>
        //                                     <Col style={{ width: '100%' }}>
        //                                         {currentData && currentData.attachments && currentData.attachments.map((file) => {
        //                                             return <div onClick={() => fetchFile(file)}>
        //                                                 {file.type.includes("image") ?
        //                                                     <img src="/image.png" alt="image" style={{ height: 50, width: 50 }} />
        //                                                     : <img src="/file.png" alt="file" style={{ height: 50, width: 50 }} />}
        //                                                 {(file.url.toString().split("-::-"))[1]}
        //                                             </div>
        //                                         })}
        //                                     </Col>
        //                                 </Row>
        //                             </Col>

        //                         }

        //                         <Col span={24}>
        //                             <Row style={{ width: '100%' }}>
        //                                 <Col style={{ width: '50%' }}>
        //                                     <Typography.Title level={5}>
        //                                         Interested Donors
        //                                     </Typography.Title>
        //                                 </Col>

        //                                 <Col span={24}>
        //                                     {currentData?.interests?.length > 0 ?
        //                                         <List>
        //                                             <VirtualList
        //                                                 data={currentData.interests}
        //                                                 height={150}
        //                                                 itemHeight={47}
        //                                                 itemKey="id"
        //                                             // onScroll={onScroll}
        //                                             >
        //                                                 {(item, key) => (
        //                                                     <List.Item key={key} style={{ cursor: "pointer" }}>
        //                                                         <List.Item.Meta
        //                                                             avatar={<Avatar src={<img src={'https://gw.alipayobjects.com/zos/rmsportal/KDpgvguMpGfqaHPjicRK.svg'} alt="avatar" />} />}
        //                                                             // title={<p>{item.title}</p>}
        //                                                             title={item.agency_name}
        //                                                             // description={"By " + item.first_name + " " + item.last_name}

        //                                                             description={new Date(item.created).toISOString().slice(8, 10) + "/" + new Date(item.created).toISOString().slice(5, 7)}
        //                                                         />
        //                                                         <div>
        //                                                             <Space>
        //                                                                 <Button type="primary" onClick={() => handleAssignDialog(item)}>Assign</Button>
        //                                                             </Space>
        //                                                         </div>
        //                                                     </List.Item>
        //                                                 )}
        //                                             </VirtualList>
        //                                         </List>
        //                                         : <Typography.Paragraph>
        //                                             There is no Interested Donors for this Project.
        //                                         </Typography.Paragraph>
        //                                     }
        //                                 </Col>

        //                             </Row>
        //                         </Col>
        //                         <Col span={24}>
        //                             <Row style={{ width: '100%' }}>
        //                                 <Col style={{ width: '100%' }}>
        //                                     <Typography.Title level={5}>
        //                                         Assigned Donor
        //                                     </Typography.Title>
        //                                 </Col>

        //                                 <Col style={{ paddingTop: '2px' }}>
        //                                     <Typography.Paragraph style={{ fontWeight: '500' }}>
        //                                         Assigned Donor Name
        //                                     </Typography.Paragraph>
        //                                 </Col>

        //                             </Row>
        //                         </Col>

        //                         <Col span={24}>
        //                             <Row style={{ width: '100%' }}>
        //                                 <Col style={{ width: '100%' }}>
        //                                     <Typography.Title level={5}>
        //                                         Assigned Remark
        //                                     </Typography.Title>
        //                                 </Col>

        //                                 <Col style={{ paddingTop: '2px' }}>
        //                                     <Typography.Paragraph style={{ fontWeight: '500' }}>
        //                                         Assigned Remark
        //                                     </Typography.Paragraph>
        //                                 </Col>

        //                             </Row>
        //                         </Col>

        //                         <Col span={24}>
        //                             {currentData.status == 0 &&
        //                                 <div style={{ display: "flex", justifyContent: "flex-end" }}>
        //                                     <Space >
        //                                         {/* <Button danger type="primary" htmlType="button" onClick={publishDonorRequest}> Reject</Button> */}
        //                                         {/* <Button loading={btnLoad} onClick={() => setRejectDialogOpen(true)}> Reject</Button>
        //                                         <Button loading={btnLoad} type="primary" onClick={() => setApproveDialogOpen(true)}> Approve</Button> */}
        //                                     </Space>
        //                                 </div>
        //                             }
        //                         </Col>

        //                     </Row>

        //                 </div>
        //             </ConfigProvider>
        //         }
        //     </div>

        // }
        table={
          <Table
            className="custom-table"
            rowClassName={(record, index) => index % 2 === 0 ? 'white-row' : 'gray-row'}
            size="small"
            columns={columns}
            dataSource={data}
            pagination={{
              pageSize: 8,
              total: data.length,
            }}
            scroll={{
              y: 400,
            }}
            onRow={(record, rowIndex) => {
              return {
                onClick: () => {
                  setCurrentData(record);
                  setViewDialog(true);
                },
              };
            }}
          />
        }
      />
      <ToastContainer />
      <Modal
        className="custom-modal"
        centered
        open={assignDialogOpen}
        title={
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div>
              {" "}
              <Typography className="custom-modal-title" level={2}>
                {this_data.donorAssigned ? "Unassign Donor" : "Assign Donor"}
              </Typography>
            </div>
          </div>
        }
        onOk={assignDonor}
        onCancel={() => {
          setAssignDialogOpen(false);
          setReasonError("");
          setAssignReason("");
        }}
      // okButtonProps={{ style: { backgroundColor: "#005476", color: "white" } }}
      >
        <div className="custom-modal-body">
          <Space direction="vertical" style={{ width: "100%" }} size={"middle"}>
            <Typography style={{ fontWeight: 700, fontSize: 18 }}>
              <span style={{ color: "red" }}>*</span>Remark{" "}
            </Typography>

            <Input.TextArea
              style={{
                borderRadius: 15,
                border: reasonError ? "1px solid red" : "",
              }}
              rows={5}
              placeholder="Enter Remark"
              value={assignReason}
              onChange={(e) => {
                setAssignReason(e.target.value);
                setReasonError("");
              }}
            />
            {reasonError ? (
              <p style={{ color: "red" }}>This field is required</p>
            ) : null}
          </Space>
        </div>
      </Modal>
      <Modal
        centered
        className="custom-modal"
        title={
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div>
              {" "}
              <Typography className="custom-modal-title" level={2}>
                Project Details
              </Typography>
            </div>
          </div>
        }
        open={viewDialog}
        width={750}
        onCancel={handleCancelView}
        footer={
          [
            // <Button size="large" onClick={handleCancelView}> Cancel</Button>,
            // <Button style={{ backgroundColor: NewColorTheme.primaryColor, color: "white" }} size="large" type="primary" onClick={interestSubmit}> {currentData?.checked ? "Remove Assist" : "Assist"}</Button>
          ]
        }
      >
        <div
          className="custom-modal-body custom-scroll-div"
          style={{ height: 600, overflow: "auto" }}
        >
          <Row>
            <Col xs={24}>
              <Row gutter={[8, 8]} align={"top"}>
                <Col xs={24}>
                  <Row gutter={[8, 8]}>
                    <Col xs={2}>
                      <ReconciliationOutlined style={{ fontSize: 22 }} />
                    </Col>
                    <Col xs={22}>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "flex-start",
                        }}
                      >
                        <Typography.Title level={5}>
                          {" "}
                          Subject
                        </Typography.Title>
                        <Typography> {currentData?.subject}</Typography>
                      </div>
                    </Col>
                  </Row>
                </Col>
                <Col xs={12}>
                  <Row gutter={[8, 8]} align={"top"}>
                    <Col xs={4}>
                      <CalendarFilled style={{ fontSize: 22 }} />
                    </Col>
                    <Col xs={20}>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "flex-start",
                        }}
                      >
                        <Typography.Title level={5}>
                          {" "}
                          Created Date
                        </Typography.Title>

                        <Typography>
                          {" "}
                          {dayjs(currentData?.created).format("DD/MM/YYYY")}
                        </Typography>
                      </div>
                    </Col>
                  </Row>
                </Col>
                <Col xs={24}>
                  <Row gutter={[8, 8]} align={"top"}>
                    <Col xs={2}>
                      <BoxPlotOutlined style={{ fontSize: 22 }} />
                    </Col>
                    <Col xs={22}>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "flex-start",
                        }}
                      >
                        <Typography.Title level={5}> Measure</Typography.Title>

                        <Typography>
                          {" "}
                          {currentData?.article_no +
                            "." +
                            currentData?.measure_no +
                            ". " +
                            currentData?.measure_name}
                        </Typography>
                      </div>
                    </Col>
                  </Row>
                </Col>
                <Col xs={24}>
                  <Row gutter={[8, 8]} align={"top"}>
                    <Col xs={2}>
                      <BorderlessTableOutlined style={{ fontSize: 22 }} />
                    </Col>
                    <Col xs={22}>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "flex-start",
                        }}
                      >
                        <Typography.Title level={5}>
                          {" "}
                          Support Type
                        </Typography.Title>

                        <Typography>
                          {" "}
                          {currentData?.assistance == 0
                            ? "Technical Support or Capacity Building"
                            : "Other"}
                        </Typography>
                      </div>
                    </Col>
                  </Row>
                </Col>

                <Col xs={8}>
                  <Row gutter={[8, 8]} align={"top"}>
                    <Col xs={6}>
                      <CalendarOutlined style={{ fontSize: 22 }} />
                    </Col>
                    <Col xs={18}>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "flex-start",
                        }}
                      >
                        <Typography.Title level={5}>
                          {" "}
                          Tentative Start Date
                        </Typography.Title>

                        <Typography>
                          {" "}
                          {dayjs(currentData?.implementation_from).format(
                            "DD/MM/YYYY"
                          )}
                        </Typography>
                      </div>
                    </Col>
                  </Row>
                </Col>

                <Col xs={8}>
                  <Row gutter={[8, 8]} align={"top"}>
                    <Col xs={4}>
                      <CalendarOutlined style={{ fontSize: 22 }} />
                    </Col>
                    <Col xs={20}>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "flex-start",
                        }}
                      >
                        <Typography.Title level={5}>
                          {" "}
                          Tentative End Date
                        </Typography.Title>

                        <Typography>
                          {" "}
                          {dayjs(currentData?.implementation_to).format(
                            "DD/MM/YYYY"
                          )}
                        </Typography>
                      </div>
                    </Col>
                  </Row>
                </Col>
                <Col xs={8}>
                  <Row gutter={[8, 8]} align={"top"}>
                    <Col xs={6}>
                      <CalendarOutlined style={{ fontSize: 22 }} />
                    </Col>
                    <Col xs={18}>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "flex-start",
                        }}
                      >
                        <Typography.Title level={5}>
                          {" "}
                          Published Date
                        </Typography.Title>

                        <Typography>
                          {" "}
                          {currentData?.published_date
                            ? dayjs(currentData?.published_date).format(
                              "DD/MM/YYYY"
                            )
                            : "-"}
                        </Typography>
                      </div>
                    </Col>
                  </Row>
                </Col>
                <Col xs={24}>
                  <Row gutter={[8, 8]} align={"top"}>
                    <Col xs={2}>
                      <ContainerOutlined style={{ fontSize: 22 }} />
                    </Col>
                    <Col xs={22}>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "flex-start",
                        }}
                      >
                        <Typography.Title level={5}>
                          {" "}
                          Description
                        </Typography.Title>

                        <Typography>
                          {" "}
                          {currentData?.description
                            ? currentData?.description
                            : "-"}
                        </Typography>
                      </div>
                    </Col>
                  </Row>
                </Col>
                {currentData?.attachments?.length > 0 && (
                  <Col xs={24}>
                    <Row gutter={[8, 8]} align={"top"}>
                      <Col xs={2}>
                        <CloudUploadOutlined style={{ fontSize: 22 }} />
                      </Col>
                      <Col xs={22}>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "flex-start",
                          }}
                        >
                          <Typography.Title level={5}>
                            {" "}
                            Attachment
                          </Typography.Title>

                          {currentData?.attachments?.length > 0 &&
                            currentData?.attachments.map((element, key) => {
                              return (
                                <div
                                  style={{
                                    alignItems: "center",
                                    display: "flex",
                                    justifyContent: "space-between",
                                    width: "100%",
                                    cursor: "pointer",
                                    background: MainColors.LIGHTBACKCOLOR,
                                    borderRadius: 10,
                                    padding: 10,

                                    ":hover": {
                                      background: MainColors.DARKBACKCOLOR,
                                      color: "white",
                                      // Add any additional styles you want for the hover effect
                                    },
                                  }}
                                  key={key}
                                  onClick={() => fetchFile(element)}
                                >
                                  <div>
                                    <img
                                      src="/file.png"
                                      alt="file"
                                      style={{ height: 50, width: 50 }}
                                    />
                                    {element?.url &&
                                      (element?.url
                                        ?.toString()
                                        .split("-::-"))[1]}
                                  </div>
                                  <BiCloudDownload
                                    style={{ fontSize: 32, color: NewColorTheme.primaryColor }}
                                  />
                                </div>
                              );
                            })}
                        </div>
                      </Col>
                    </Row>
                  </Col>
                )}
                <Col xs={24}>
                  <Row gutter={[8, 8]} align={"top"}>
                    <Col xs={2}>
                      <FileDoneOutlined style={{ fontSize: 22 }} />
                    </Col>
                    <Col xs={22}>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "flex-start",
                        }}
                      >
                        <Typography.Title level={5}> Remarks</Typography.Title>

                        <Typography>
                          {" "}
                          {currentData?.reject_reason
                            ? currentData?.reject_reason
                            : currentData?.publish_reason
                              ? currentData?.publish_reason
                              : "-"}
                        </Typography>
                        {currentData?.published_attachments?.length > 0 &&
                          currentData?.published_attachments.map((element, key) => {
                            return (
                              <div
                                style={{
                                  alignItems: "center",
                                  display: "flex",
                                  justifyContent: "space-between",
                                  width: "100%",
                                  cursor: "pointer",
                                  background: MainColors.LIGHTBACKCOLOR,
                                  borderRadius: 10,
                                  padding: 10,

                                  ":hover": {
                                    background: MainColors.DARKBACKCOLOR,
                                    color: "white",
                                    // Add any additional styles you want for the hover effect
                                  },
                                }}
                                key={key}
                                onClick={() => fetchFile(element)}
                              >
                                <div>
                                  <img
                                    src="/file.png"
                                    alt="file"
                                    style={{ height: 50, width: 50 }}
                                  />
                                  {element?.url &&
                                    (element?.url
                                      ?.toString()
                                      .split("-::-"))[1]}
                                </div>
                                <BiCloudDownload
                                  style={{ fontSize: 32, color: NewColorTheme.primaryColor }}
                                />
                              </div>
                            );
                          })}
                      </div>
                    </Col>
                  </Row>
                </Col>
                <Col span={24}>
                  <Row gutter={[8, 8]} align={"top"}>
                    <Col xs={2}>
                      <FlagOutlined style={{ fontSize: 22 }} />
                    </Col>
                    <Col xs={22}>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "flex-start",
                          width: "100%",
                        }}
                      >
                        <Typography.Title level={5}>
                          {" "}
                          Interested Donors
                        </Typography.Title>
                        {currentData?.interests?.length > 0 ? (
                          <List style={{ width: "100%" }}>
                            <VirtualList
                              data={currentData.interests}
                              height={150}
                              itemHeight={47}
                              itemKey="id"
                            // onScroll={onScroll}
                            >
                              {(item, key) => (
                                <List.Item
                                  key={key}
                                  style={{ cursor: "pointer" }}
                                >
                                  {console.log("item ----->>", item)}
                                  <List.Item.Meta
                                    avatar={
                                      <Avatar
                                        src={
                                          <img
                                            src={
                                              "https://gw.alipayobjects.com/zos/rmsportal/KDpgvguMpGfqaHPjicRK.svg"
                                            }
                                            alt="avatar"
                                          />
                                        }
                                      />
                                    }
                                    // title={<p>{item.title}</p>}
                                    title={item.agency_name}
                                    // description={"By " + item.first_name + " " + item.last_name}

                                    description={
                                      new Date(item.created)
                                        .toISOString()
                                        .slice(8, 10) +
                                      "/" +
                                      new Date(item.created)
                                        .toISOString()
                                        .slice(5, 7)
                                    }
                                  />
                                  {
                                    window.localStorage.getItem("ntfc_level") < 6 && item.donorAssigned ?
                                      null
                                      :
                                      <div>
                                        {!isChair &&
                                          <Space>
                                            <Button
                                              type="primary"
                                              onClick={() => {
                                                console.log("assigned Dtaa", item)
                                                setThis_data(item);
                                                setAssignDialogOpen(true);
                                              }}
                                              disabled={window.localStorage.getItem('view_only') == 1}
                                            >
                                              {" "}
                                              {!item.donorAssigned
                                                ? "Assign "
                                                : "Unassign "}
                                            </Button>
                                          </Space>
                                        }

                                      </div>
                                  }
                                </List.Item>
                              )}
                            </VirtualList>
                          </List>
                        ) : (
                          <Typography.Paragraph>
                            {" "}
                            There is no Interested Donors for this Projec
                          </Typography.Paragraph>
                        )}
                      </div>
                    </Col>
                  </Row>
                </Col>
                <>
                  <Col xs={24}>
                    <Row gutter={[8, 8]} align={"top"}>
                      <Col xs={2}>
                        <ContainerOutlined style={{ fontSize: 22 }} />
                      </Col>
                      <Col xs={22}>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "flex-start",
                          }}
                        >
                          <Typography.Title level={5}>
                            {" "}
                            Interest Description
                          </Typography.Title>

                          <Typography>
                            {currentData?.interestDescription}
                          </Typography>
                        </div>
                      </Col>
                    </Row>
                  </Col>
                  <Col xs={24}>
                    <Row gutter={[8, 8]} align={"top"}>
                      <Col xs={2}>
                        <ContactsOutlined style={{ fontSize: 22 }} />
                      </Col>
                      <Col xs={22}>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "flex-start",
                          }}
                        >
                          <Typography.Title level={5}>
                            {" "}
                            Interest Contacts
                          </Typography.Title>

                          <Typography>
                            {currentData?.interestContact}
                          </Typography>
                        </div>
                      </Col>
                    </Row>
                  </Col>
                </>
              </Row>
            </Col>
          </Row>
        </div>
        {/* <p>{record.checked ? "Are you sure to assist this project?" : "Are you sure to remove this project?"}</p> */}
      </Modal>
    </div>
  );
};

export default ConfirmedDonorRequests;
