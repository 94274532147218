import React from 'react'
import './dashboarddonors.css'
import { Card, Typography } from 'antd'
function StatDashBoarCard({ title, titleBackColor, content }) {
    return (
        <Card className='dash5-card' style={{ height: "100%" }}>
            <div style={{ height: "100%" }}>
                <div
                    className='action-card-five-new'

                >
                    <Typography className='ac-new-inner-card-title'>{title}</Typography>

                </div>
                <div
                    className="stat-content-div"
                >
                    {content}

                </div>

            </div>
        </Card>
    )
}

export default StatDashBoarCard