import { Col, Row } from 'antd'
import React from 'react'

function DonorDashboardCard({ cardClass, CardText, CardVal, textClass, valClass }) {
    return (
        <div className={cardClass}>
            <div className="cardBody">
                <Row gutter={16}>
                    <Col span={16}>
                        <p
                            className={textClass}
                        >{CardText}</p>
                    </Col>
                    <Col span={8}>
                        <p
                            className={valClass}
                        >{CardVal}</p>
                    </Col>
                </Row>
            </div>
        </div>
    )
}

export default DonorDashboardCard