import React, { useState } from 'react'
import { PlusOutlined, CloseOutlined, EditOutlined } from '@ant-design/icons';
import { Button, Tooltip } from 'antd';
import CustomTable from '../../Components/Table';

const PendingAnswers = () => {
  const [filtered_data, setFilteredData] = useState([]);
  const [selected, setSelected] = useState(null);

  const columns = [
      {
          title: 'Inquiry Description',
          dataIndex: 'inquiry_description',
          key: 'inquiry_description',

      },
      {
          title: 'Created Date',
          dataIndex: 'created_date',
          key: 'created_date',

      },

      {
          title: 'Created By',
          dataIndex: 'created_by',
          key: 'created_by',
      
      },
     
      {
          title: 'Assigned To',
          dataIndex: 'assigned_to',
          key: 'assigned_to',

      },
      
      {
          title: 'Publish to all',
          dataIndex: 'publish_to_all',
          key: 'publish_to_all',

      },
      
    //   {
    //       title: '',
    //       key: 'action',
    //       render: (_, record) => (
    //           record ?
    //               <Tooltip title="View/Edit">
    //                   <Button type="secondary" size='small' onClick={() => setSelected(record)} shape="circle" icon={<EditOutlined style={{ color: '#946e6e' }} />} />
    //               </Tooltip>
    //               : null
    //       ),
    //   },
  ];
  return (
    <div>
      <CustomTable columns={columns} 
            data={filtered_data}
            pagination={{
                pageSize: 10,
                total: filtered_data.length,
            }}
        />
    </div>
  )
}

export default PendingAnswers
