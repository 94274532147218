import { Segmented, Select, Space, Typography } from "antd";
import { useState } from "react";

const AgencySummery = ({
    lbl,
    value
}) => {

  return (
    <div className="agency-summery-box">
      <div className="agency-summery-lbl">{lbl}</div>
      <div className="agency-summery-val">{value}</div>
    </div>
  );
};

export default AgencySummery;
