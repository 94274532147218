import ReactDOM from "react-dom/client";
import React, { useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";


import Layout from "./layouts/main";
import Home from "./pages/Home";
import Login from "./pages/Login";
import Signup from "./pages/Signup";
import NoPage from "./pages/NoPage";
import Forgot from "./pages/Forgot";
import Dashboard from "./pages/Dashboard";
import Public from "./pages/Public";
import Tasks from "./pages/Tasks";
import Users from "./pages/Users/Users";
import Config from "./pages/Config";
// import Donors from "./pages/Donors";
// import Inquiries from "./pages/Inquiries";
import MonitoringTool from "./pages/MonitoringTool";
import Requests from "./pages/Requests";
import { NewDashboard } from "./pages/Dashboard/NewDashBord";
import Agencies from "./Components/PageLayouts/Agencies";
import AddAgencies from "./pages/NewPages/AddAgencies";
import AddArticles from "./pages/NewPages/AddArticle";
import AddMeasures from "./pages/NewPages/AddMeasures";
import AddActions from "./pages/NewPages/AddActions";

import ActionPlan from "./pages/Config/ActionPlan";
import TasksActionPlan from "./pages/Tasks/TasksActionPlan";
import DonorsDashBoard from "./pages/Donors/DonorsDashBoard";
// import DonorProfile from "./pages/Donors/DonorProfile"

import "antd/dist/reset.css";
import "./index.css";
import { ConfigProvider } from "antd";
import DonorInterests from "./pages/DonorsNew/DonorInterest";
import DonorRequets from "./pages/DonorsNew/DonorRequests";
import DonorRequestsNtfc from "./pages/DonorsNew/DonorRequestsNtfc";
import { Donors } from "./pages/DonorsNew/Donors";
import DonorContribution from "./pages/DonorsNew/DonorContribution";
import DonorInterestMeasure from "./pages/DonorsNew/DonorInterestMeasure";
import DonorInterestProjects from "./pages/DonorsNew/DonorInterestProjects";
import DonorRequestsAgency from "./pages/DonorsNew/DonorRequestsAgency";
import DonorRequestsValidate from "./pages/DonorsNew/DonorRequestsValidate";
import DonorRequestNtfcAgency from "./pages/DonorsNew/DonorRequestByNtfcAgency";
import FormLayouts from "./pages/DonorsNew/Components/FormLayouts";
import DonorRequestByAgency from "./pages/DonorsNew/AllDonorRequestsByAgencies";
import Inquiries from "./pages/Inquiries";
import Faq from "./pages/Faq";
import AllAssignedRequests from "./pages/DonorsNew/AllAssignedRequests";
import DonorRequestsValidateByNtfc from "./pages/DonorsNew/AllDonorInterestRequests";
import AllInterests from "./pages/DonorsNew/AllDonorInterestRequests";
import DonorInterestInterestbyAgency from "./pages/DonorsNew/AddDonorInterestInterest";
import { NewDashboard2 } from "./pages/Dashboard/NewDashBord2";
import UserRequests from "./pages/Users/UserRequests";
import UserRequestsHOD from "./pages/Users/UserRequestsHOD";
import DonorManagement from "./pages/DonorsV2/DonorManagement";
import Newdashboard3 from "./pages/Dashboard/Newdashoard3";
import Newdashboard4 from "./pages/Dashboard/NewDashbaord4";
import Newdashboard5 from "./pages/Dashboard/NewDashoard5";
import DonorDashBord from "./pages/DonorV3/DonorDashBord";
import DonorProfile from "./pages/DonorV3/DonorProfile/DonorProfile";
import AllEvents from "./pages/Dashboard/CalanderComponent/AllEvents";
import NewCalender from "./pages/Dashboard/CalanderComponent/NewCalender";
import Login2 from "./pages/Login/index2";
import Signup2 from "./pages/Signup/index2";
import Forgot2 from "./pages/Forgot/index2";
import EmailVerification from "./pages/EmailVerification/EmailVerification";
import EmailAlredayVerified from "./pages/EmailVerification/EmailAlredayVerified";
import EmailVerificationError from "./pages/EmailVerification/EmailVerificationError";
import PresidentView from "./pages/PresidentDashboard/PresidentView";
import AccessDenied from "./pages/AccessDenied/AccessDenied";
import NewIndex from "./pages/Forgot/NewIndex";


export default function App() {


  return (
    <ConfigProvider
      theme={{
        token: {
          fontFamily: "Figtree",
        },
      }}
    >
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route path="dashboard" element={<Dashboard />} />
            <Route path="home" element={<Home />} />
            <Route path="config" element={<Config />} />
            {/* <Route path="donors" element={<Donors />} /> */}
            {/* <Route path="inquiries" element={<Inquiries />} /> */}
            <Route path="agencies" element={<Agencies />} />

            <Route index element={<Login2 />} />
          </Route>

          <Route path="login2" element={<Login />} />
          <Route path="login" element={<Login2 />} />
          <Route path="signup2" element={<Signup />} />
          <Route path="signup" element={<Signup2 />} />
          <Route path="update_password" element={<Forgot2 />} />
          <Route path="update_password2" element={<Forgot />} />
          <Route path="*" element={<NoPage />} />
          <Route path="summary" element={<NewDashboard2 />} />
          <Route path="newdashboard2" element={<NewDashboard />} />
          <Route path="newdashboard6" element={<Newdashboard3 />} />
          <Route path="newdashboard" element={<Newdashboard5 />} />
          <Route path="newdashboard4" element={<Newdashboard4 />} />
          <Route path="newdashboard5" element={<Newdashboard5 />} />
          <Route path="tasksactionplan" element={<TasksActionPlan />} />
          <Route
            path="add-agencies"
            element={
              localStorage.getItem("ntfc_level") == 6 ||
                localStorage.getItem("ntfc_level") == 7 ? (
                <AddAgencies />
              ) : (
                <AccessDenied />
              )
            }
          />
          <Route
            path="add-articles"
            element={
              localStorage.getItem("ntfc_level") == 6 ||
                localStorage.getItem("ntfc_level") == 7 ? (
                <AddArticles />
              ) : (
                <AccessDenied />
              )
            }
          />
          <Route
            path="add-measures"
            element={
              localStorage.getItem("ntfc_level") == 6 ||
                localStorage.getItem("ntfc_level") == 7 ? (
                <AddMeasures />
              ) : (
                <AccessDenied />
              )
            }
          />
          <Route
            path="add-actions"
            element={
              localStorage.getItem("ntfc_level") == 6 ||
                localStorage.getItem("ntfc_level") == 7 ? (
                <AddActions />
              ) : (
                <AccessDenied />
              )
            }
          />
          <Route
            path="actionplan"
            element={
              localStorage.getItem("ntfc_level") == 6 ||
                localStorage.getItem("ntfc_level") == 7 ? (
                <ActionPlan />
              ) : (
                <AccessDenied />
              )
            }
          />
          <Route path="monitoringtool" element={<MonitoringTool />} />
          {/* <Route path="donorsdashboard" element={<DonorsDashBoard />} /> */}
          <Route path="donorprofile" element={<DonorProfile />} />

          {/* <Route path="users/:id" element={<Users />} /> */}
          <Route path="requests" element={<Requests />} />
          <Route path="tasks" element={<Tasks />} />
          <Route path="donorinterests" element={<DonorInterests />} />
          <Route path="donorrequests" element={<DonorRequets />} />
          <Route path="donorrequestsntfc" element={<DonorRequestsNtfc />} />
          <Route path="donors" element={<Donors />} />
          <Route path="donorcontribution" element={<DonorContribution />} />
          <Route
            path="donorinterestmeasure"
            element={<DonorInterestMeasure />}
          />
          <Route
            path="donorinterestprojects"
            element={<DonorInterestProjects />}
          />
          <Route path="donorrequestsagency" element={<DonorRequestsAgency />} />
          <Route
            path="donorrequestsvalidate"
            element={<DonorRequestsValidate />}
          />
          {/* <Route path="donorrequentfcagency" element={<DonorRequestNtfcAgency />} /> */}
          <Route
            path="donorsdashboard/:id"
            element={<DonorRequestNtfcAgency />}
          />
          <Route
            path="alldonorreuestsbyagency"
            element={<DonorRequestByAgency />}
          />
          <Route path="formlayput" element={<FormLayouts />} />
          <Route path="inquiries" element={<Inquiries />} />
          <Route path="inquiries/:id" element={<Inquiries />} />
          <Route path="faq" element={<Faq />} />
          <Route
            path="allassigneddonorrequest"
            element={<AllAssignedRequests />}
          />
          <Route path="getalldonorinterests" element={<AllInterests />} />
          <Route
            path="agencyinterestdonorinterest"
            element={<DonorInterestInterestbyAgency />}
          />
          <Route path="userrequestshod" element={<UserRequestsHOD />} />
          <Route path="allusers" element={<Users />} />

          {window.localStorage.getItem("ntfc_level") == 3 ?
            <Route path="donormanagement/:id" element={<DonorManagement />} /> :
            <Route path="donormanagement" element={<DonorManagement />} />
          }


          {/* Newly adeed 01/05/2023 */}
          <Route path="donordashboardv3" element={<DonorDashBord />} />
          <Route path="donorprofile3" element={<DonorProfile />} />
          <Route path="allevents" element={<AllEvents />} />
          <Route path="newcalender" element={<NewCalender />} />
          <Route path="emailverification" element={<EmailVerification />} />
          <Route path="emailverified" element={<EmailAlredayVerified />} />
          <Route
            path="emailverificationerror"
            element={<EmailVerificationError />}
          />
          <Route path="presidentdashbaord" element={<PresidentView />} />
          <Route path="password_setup" element={<NewIndex />} />
          <Route path="accessdenied" element={<AccessDenied />} />
        </Routes>
      </BrowserRouter>
    </ConfigProvider>
  );
}

const root = ReactDOM.createRoot(document.getElementById("root"));
//root.render(<App />);

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
