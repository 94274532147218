import { List, Typography, Input, Space, Button, message, Tooltip, Form, Row, Col } from 'antd';
import { PlusOutlined, CloseOutlined, EditOutlined } from '@ant-design/icons';
import { useEffect, useState } from 'react';
import { addArticle, getArticlesList, updateArticle, getNextArticleNo } from '../../api';

const Articles = ({ article }) => {
    const [items, setItems] = useState([]);

    const [data, setData] = useState([]);
    const [filtered_data, setFilteredData] = useState([]);

    const [selected, setSelected] = useState(null);

    const [form] = Form.useForm();

    const refreshArticles = () => {
        getArticlesList().then((out) => {
            setItems(out.data.out);
        });
        getNextArticleNo().then((out) => {
            form.setFieldValue('article_no', out.data.out[0].id)
        });
    }

    const [messageApi, contextHolder] = message.useMessage();

    const success = (msg) => {
        messageApi.open({
            type: 'success',
            content: msg,
            style: {
                marginTop: '30vh',
                height: '100vh',
                fontSize: '20px'
            },
            duration: 3
        });
    };

    const error = (msg) => {
        messageApi.open({
            type: 'error',
            content: msg,
            style: {
                marginTop: '30vh',
                height: '100vh',
                fontSize: '20px'
            },
            duration: 3
        });
    };

    const onFinish = (values) => {
        console.log('Success:', values);

        let prev_article_no = values.article_no;
        let repeated = false;

        if (selected) {
            items.forEach(item => {
                if (item.article_no == prev_article_no && item.article_no != selected.article_no) {
                    repeated = true;
                }
            })
        } else {
            items.forEach(item => {
                if (item.article_no == prev_article_no) {
                    repeated = true;
                }
            })
        }

        if (repeated) {
            error("Article Number Already Exists");
            return;
        }

        if (!selected) {
            addArticle(values).then((out) => {
                if (out.data.status === 200) {
                    form.resetFields();
                    refreshArticles();
                    success("Article Created");
                } else {
                    error("Article Was Not Created");
                }
            }).catch((err) => {
                console.log(err);
                messageApi.open({
                    type: 'error',
                    content: 'Article creation unsuccessful',
                    style: {
                        marginTop: '30vh',
                        height: '100vh',
                        fontSize: '20px'
                    },
                    duration: 3
                });
            })
        } else {
            values.id = selected.id;
            updateArticle(values).then((out) => {
                if (out.data.status === 200) {
                    form.resetFields();
                    refreshArticles();
                    success("Article Updated");
                    setSelected(null);
                } else {
                    error("Article Was Not Updated");
                }
            }).catch((err) => {
                console.log(err);
                messageApi.open({
                    type: 'error',
                    content: 'Article update unsuccessful',
                    style: {
                        marginTop: '30vh',
                        height: '100vh',
                        fontSize: '20px'
                    },
                    duration: 3
                });
            })
        }
    };
    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
        if (!selected) {
            error("Article creation unsuccessful");
        } else {
            error("Article update unsuccessful");
        }
    };

    useEffect(() => {
        refreshArticles();
    }, []);

    useEffect(() => { setData(items); setFilteredData(items) }, [items]);

    useEffect(() => {
        if (selected) {
            form.setFieldValue('article_no', selected.article_no)
            form.setFieldValue('article', selected.article)
            form.setFieldValue('summary', selected.summary)
        } else {
            form.resetFields();
        }
        // eslint-disable-next-line
    }, [selected]);

    return <div style={{ margin: '0 5px' }}>
        {contextHolder}
        <Row gutter={16}>
            <Col span={10}>
                <Typography.Title level={5} style={{ margin: 0 }}>Articles</Typography.Title>
                <Space direction='vertical' style={{ width: '100%' }}>
                    <Typography.Text type="secondary">Please Complete the Form...</Typography.Text>
                    <Form
                        form={form}
                        layout="vertical"
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                    >
                        <Row gutter={16}>
                            <Col className="gutter-row" span={12}>
                                <Form.Item label="Article No" name="article_no" required
                                    rules={[{
                                        required: true,
                                        message: <p style={{ marginLeft: "12px" }}>Article No is required</p>,
                                    }]}
                                >
                                    <Input type='text' placeholder="Article No" />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="gutter-row" span={24}>
                                <Form.Item label="Article Name" name="article" required
                                    rules={[{
                                        required: true,
                                        message: <p style={{ marginLeft: "12px" }}>Article Name is required</p>,
                                    }]}
                                >
                                    <Input type='text' placeholder="Article" />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col className="gutter-row" span={24}>
                                <Form.Item label="Summary" name="summary" required
                                    rules={[{
                                        required: true,
                                        message: <p style={{ marginLeft: "12px" }}>Summary is required</p>,
                                    }]}
                                >
                                    <Input type='text' placeholder="Summary" />
                                </Form.Item>
                            </Col>
                        </Row>
                        {!selected ? <Button type='primary' htmlType="submit" icon={<PlusOutlined />}>
                            Add Article
                        </Button> : <Space>
                            <Button type='primary' htmlType="submit" icon={<EditOutlined />} style={{ background: 'green' }}>
                                Update
                            </Button>
                            <Button type='primary' danger htmlType="submit" icon={<CloseOutlined />} onClick={() => setSelected(null)}>
                                Cancel
                            </Button>
                        </Space>}
                    </Form>
                </Space>
            </Col>
            <Col span={14}>
                <div style={{ display: 'flex', justifyContent: 'end', width: '100%', marginBottom: 10 }}>
                    <Input.Search style={{ width: 200 }} placeholder="search" onSearch={(val) => {
                        console.log(data);
                        const itms = data.filter((rec) => rec.article_name.includes(val));
                        console.log(itms); setFilteredData(itms);
                    }} />
                </div>
                <List
                    bordered
                    className='config-list'
                    style={{ height: '50vh', overflow: 'auto', borderColor: '#f0f0f0' }}
                    dataSource={filtered_data}
                    header={<div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', alignItems: 'center' }}>
                        <Row gutter={16} style={{ width: '100%' }}>
                            <Col span={3}>
                                <Typography.Text>#</Typography.Text>
                            </Col>
                            <Col span={10}>
                                <Typography.Text>Article</Typography.Text>
                            </Col>
                            <Col span={10}>
                                <Typography.Text>Summary</Typography.Text>
                            </Col>
                            <Col span={1}>
                            </Col>
                        </Row>
                    </div>}
                    renderItem={(item) => (
                        <List.Item className={article && article.id === item.id ? 'config-list-item-selected' : 'config-list-item'}>
                            <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', alignItems: 'center' }}>
                                <Row gutter={16} style={{ width: '100%' }}>
                                    <Col span={3}>
                                        <Typography.Text style={article && article.id === item.id ? { color: 'white' } : {}}>{item.article_no}</Typography.Text>
                                    </Col>
                                    <Col span={10}>
                                        <Typography.Text style={article && article.id === item.id ? { color: 'white' } : {}}>{item.article}</Typography.Text>
                                    </Col>
                                    <Col span={10}>
                                        <Typography.Text style={article && article.id === item.id ? { color: 'white' } : {}}>{item.summary}</Typography.Text>
                                    </Col>
                                    <Col span={1}>
                                        <Tooltip title="View/Edit">
                                            <Button type="secondary" size='small' onClick={() => setSelected(item)} shape="circle" icon={<EditOutlined style={{ color: '#946e6e' }} />} />
                                        </Tooltip>
                                    </Col>
                                </Row>
                            </div>
                        </List.Item>
                    )}
                />
            </Col>
        </Row>
    </div>
}

export default Articles;