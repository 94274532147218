import { useEffect, useState } from "react";
import 'react-quill/dist/quill.snow.css';
import FormLayouts from "./Components/FormLayouts.jsx";
import { ToastContainer, toast, Zoom } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import TitleBox from "../../Components/TitleBox/TitleBox.jsx";
import { getPendingDonorRequests, publishDonorRequest } from "../../api/index.js";
import { Button, Col, ConfigProvider, Row, Space, Table, Tag, Typography } from "antd";

const DonorRequestByAgency = () => {
    const [data, setData] = useState([])
    const [filtered_data, setFilteredData] = useState([]);
    const [currentData, setCurrentData] = useState({})
    const [btnLoad, setBtnLoad] = useState(false)

    useEffect(() => {
        getPendingAgencyDonorRequest()

    }, []);

    const getPendingAgencyDonorRequest = async () => {

        let out = await getPendingDonorRequests()

        if (out.status === 200) {

            setData(out.data.out)
            setCurrentData(out.data.out[0])
        }

    }

    const columns = [
        // {
        //     title: 'ID',
        //     dataIndex: 'id',
        //     key: 'id'
        // },
        {
            title: 'Subject',
            dataIndex: 'subject',
            key: 'subject',
        },
        {
            title: 'Measure',
            dataIndex: 'measure_name',
            key: 'measure_name',
        },
        {
            title: 'Purpose',
            dataIndex: 'scope',
            key: 'scope',
            render: (_, { scope }) => (
                <Tag color={scope == 0 ? "#c1760d" : "#326379"} key={scope} style={{ width: 120, display: 'flex', justifyContent: 'center' }} >
                    {
                        scope == 0 ? "ALL COUNTRIES"
                            : "AGENCIES"
                    }
                </Tag>
            ),
        },
        {
            title: 'Description',
            dataIndex: 'description',
            key: 'description',
        },
        // {
        //     title: 'Attachment',
        //     dataIndex: 'attachment',
        //     key: 'attachment',
        // },
        {
            title: 'Support Type',
            dataIndex: 'assistance',
            key: 'assistance',
            render: (_, { assistance }) => (
                <Tag color={assistance == 0 ? "#f50" : "#2a8c00"} key={assistance} style={{ width: 150, display: 'flex', justifyContent: 'center' }} >
                    {
                        assistance == 0 ? "TECH ASSISTANCE"
                            : "CAPACITY BUILDING"
                    }
                </Tag>
            ),
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: (_, { status }) => (
                <Tag color={status == 0 ? "#008282" : "#9B0259"} key={status} style={{ width: 120, display: 'flex', justifyContent: 'center' }} >
                    {
                        status == 0 ? "PENDING"
                            : "PUBLISHED"
                    }
                </Tag>
            ),
        },
    ];


    const publishCurrentDonorRequest = async () => {
        setBtnLoad(true)
        console.log("clicksed")
        let body = {
            id: currentData.id
        }

        let out = await publishDonorRequest(body)

        if (out.status == 200) {

            toast.success(out.data.message, {

                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progressClassName: 'toast-progress-bar',
            });
        }
        setBtnLoad(false)
        getPendingAgencyDonorRequest()
    }

    return <>
        <div style={{ display: 'flex', justifyContent: 'space-between', height: '100vh', overflow: "hidden" }}>

            <FormLayouts
                title="All Donor Requests by Agencies"
                formCardColor="white"
                formSide={
                    <div>

                        <ConfigProvider
                            theme={{
                                token: {
                                    colorPrimary: '#006666',
                                },
                            }}>

                            <TitleBox titleBoxColor="#326379" title="Request Details" />
                            <div className='list-container'>

                                <Row gutter={[16, 16]}>
                                    <Col span={24}>
                                        <Typography.Title level={4}>
                                            Subject
                                        </Typography.Title>
                                        <Typography.Paragraph>
                                            {currentData.subject}
                                        </Typography.Paragraph>

                                    </Col>
                                    <Col span={24}>
                                        <Typography.Title level={4}>
                                            Measure
                                        </Typography.Title>
                                        <Typography.Paragraph>
                                            {currentData.measure}
                                        </Typography.Paragraph>

                                    </Col>
                                    <Col span={24}>
                                        <Typography.Title level={4}>
                                            Purpose
                                        </Typography.Title>
                                        <Typography.Paragraph>
                                            {currentData.scope == 1 ? "National Wise" : "Agency Wise"}
                                        </Typography.Paragraph>

                                    </Col>
                                    <Col span={24}>
                                        <Typography.Title level={4}>
                                            Description
                                        </Typography.Title>
                                        <Typography.Paragraph>
                                            {currentData.description}
                                        </Typography.Paragraph>

                                    </Col>
                                    <Col span={24}>
                                        <Typography.Title level={4}>
                                            Support Type
                                        </Typography.Title>
                                        <Typography.Paragraph>
                                            {currentData.assistance == 0 ? "Technical Support" : currentData.assistance == 1 ? "Capacity Building" : "Other"}
                                        </Typography.Paragraph>

                                    </Col>
                                    <Col span={24}>
                                        <div style={{ display: "flex", justifyContent: "flex-end" }}>
                                            <Space >
                                                {/* <Button danger type="primary" htmlType="button" onClick={publishDonorRequest}> Reject</Button> */}
                                                <Button loading={btnLoad} type="primary" onClick={() => publishCurrentDonorRequest()}> Accept</Button>
                                            </Space>

                                        </div>
                                    </Col>

                                </Row>





                            </div>
                        </ConfigProvider>
                    </div>


                }
                table={

                    <Table
                        columns={columns}
                        dataSource={data}
                        pagination={{
                            pageSize: 8,
                            total: filtered_data.length,
                        }}
                        onRow={(record, rowIndex) => {
                            return {
                                onClick: () => {
                                    setCurrentData(record)
                                },
                            };
                        }}


                    />
                }
            />
            <ToastContainer
                transition={Zoom}
                position="top-center"
                autoClose={5000}
                hideProgressBar
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored"
                style={{ top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}
            />



        </div >
    </>

}

export default DonorRequestByAgency;