import { Badge, Avatar, Space, Typography, Card, Row, Col } from 'antd';
import { BsBell, BsCalendarEvent, BsPeople, BsUiChecks } from "react-icons/bs";
import './home.styles.css';
import EventCalendar from './EventCalendar';
import Tasks from './Tasks';
const { Title } = Typography;

function Page() {
  return (
    <div>
      <Space style={{ height: '8vh', width: '100%', borderBottom: '1px solid #e5e5e5' }}>
        <Title level={5} style={{ margin: 0 }}>My Dashboard</Title>
      </Space>
      <div className='content'>
        <Row gutter={16} style={{ marginTop: 10 }}>
          <Col className="gutter-row" span={6}>
            <Card className='reminders'>
              <Space size="large">
                <Badge count={5}>
                  <Avatar style={{ backgroundColor: 'transparent' }} shape="square" size="large" icon={<BsBell color='#dd564a' />} />
                </Badge>
                <Title style={{ margin: 0 }} type="secondary" level={5}>Reminders</Title>
              </Space>
            </Card>
          </Col>
          <Col className="gutter-row" span={6}>
            <Card className='events'>
              <Space size="large">
                <Badge count={5}>
                  <Avatar style={{ backgroundColor: 'transparent' }} shape="square" size="large" icon={<BsCalendarEvent color='#2d6a3f' />} />
                </Badge>
                <Title style={{ margin: 0 }} type="secondary" level={5}>Upcoming Events</Title>
              </Space>
            </Card>
          </Col>
          <Col className="gutter-row" span={6}>
            <Card className='meetings'>
              <Space size="large">
                <Badge count={5}>
                  <Avatar style={{ backgroundColor: 'transparent' }} shape="square" size="large" icon={<BsPeople color='#e68943' />} />
                </Badge>
                <Title style={{ margin: 0 }} type="secondary" level={5}>Upcoming Meetings</Title>
              </Space>
            </Card>
          </Col>
          <Col className="gutter-row" span={6}>
            <Card className='tasks'>
              <Space size="large">
                <Badge count={0}>
                  <Avatar style={{ backgroundColor: 'transparent' }} shape="square" size="large" icon={<BsUiChecks color='#61679c' />} />
                </Badge>
                <Title style={{ margin: 0 }} type="secondary" level={5}>Delayed Tasks</Title>
              </Space>
            </Card>
          </Col>
        </Row>
        <Row gutter={16} style={{ marginTop: 10 }}>
          <Col className="gutter-row" span={12}>
            <Title style={{ margin: 0 }} type="secondary" level={5}>Ongoing Tasks</Title>
            <Tasks/>
          </Col>
          <Col className="gutter-row" span={12}>
            <Title style={{ margin: 0 }} type="secondary" level={5}>Event Calendar</Title>
            <EventCalendar />
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default Page;
