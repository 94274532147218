import { Modal, Row, Space, Tooltip, message, Typography, Button, Col } from 'antd';
import React, { useState, useEffect } from 'react';
import TitleBox from '../TitleBox/TitleBox';
import MemberCard from '../MemberCard/MemberCard';
import { LoadingOutlined, UserAddOutlined } from '@ant-design/icons';

import { AddActionParticipant, AddSubActionParticipant, GetActionParticipants, GetSubActionParticipant, RemoveActionParticipant, RemoveSubActionParticipant, addParticipants, getActionParticipants, getAllUsers } from '../../api';
import CommentBox from '../CommentBox/CommentBox';
const { Text } = Typography;

const AddParticipantsModal = ({ action, actionType }) => {

    const [addParticipantsDialog, SetAddParticipants] = useState(false)
    const [currentActionParticipants, setCurrentActionParticipants] = useState([]);
    const [loadingMembers, setLoadMembers] = useState(false)
    const [users, setUsers] = useState([]);
    const [otherActionParticipants, setOtherActionParticipants] = useState([]);
    const [messageApi, contextHolder] = message.useMessage();

    useEffect(() => {

        LoadMembers();


    }, []);

    const LoadMembers = async (value) => {

        setLoadMembers(true)

        let allusers = []
        let currentUsers = []

        // get all Participants
        await getAllUsers(action.agency_id).then(out => {
            allusers = out.data.out

            setUsers(out.data.out)
        });
        if (actionType === "Main") {
            await GetActionParticipants(action.agency_id, action.action_id).then(out => {
                currentUsers = out.data.out
                setCurrentActionParticipants(out.data.out)

            })
        } else {
            await GetSubActionParticipant(action.agency_id, action.action_id, action.id).then(out => {
                currentUsers = out.data.out
                setCurrentActionParticipants(out.data.out)

            })
        }

        // Memebers that are in Agency but not a member for this Action
        const OtherMembers = allusers.filter((item) => !currentUsers.includes(item.uuid));
        await getCurrentUsersName(null, OtherMembers, allusers)
        // set user details for the current paricipants -- first_name, last_name...
        // await getCurrentUsersName(currentUsers, null, allusers)

    };
    const getCurrentUsersName = async (currentUsers, OtherMembers, allusers) => {

        if (allusers && currentUsers) {
            currentUsers.forEach(obj2 => {
                const obj1 = allusers.find(o => o.uuid === obj2.user_id);
                if (obj1) {
                    Object.assign(obj2, obj1);
                }
            });
            setCurrentActionParticipants(currentUsers)

        }
        if (allusers && OtherMembers) {
            OtherMembers.forEach(obj2 => {
                const obj1 = allusers.find(o => o.uuid === obj2.user_id);
                if (obj1) {
                    Object.assign(obj2, obj1);
                }
            });
            setOtherActionParticipants(OtherMembers)

        }

        setLoadMembers(false)

    }

    const addNewParticipant = async (user) => {

        let data = {
            "agency_id": action.agency_id,
            "action_id": action.action_id,
            "participant": user.uuid,
            "subaction": actionType === "Main" ? null : action.id
        }

        let out
        if (actionType === "Main") {
            out = await AddActionParticipant(data)
        } else {
            data.subaction_id = action.id
            out = await AddSubActionParticipant(data)
        }

        if (out) {
            if (out.status === 200) {
                message.success(out.data.message);
                LoadMembers()
            } else {
                message.error(out.data.message);
            }
        } else {
            message.error("Internal Server Error");
        }





    }

    const removeParticipant = async (user) => {

        let data = {
            "agency_id": user.agency_id,
            "action_id": action.action_id,
            "participant": user.user_id,

        }
        let out
        if (actionType === "Main") {
            out = await RemoveActionParticipant(data)
        } else {
            data.subaction_id = action.id
            out = await RemoveSubActionParticipant(data)
        }

        if (out.status === 200) {
            message.success(out.data.message);
            LoadMembers()
        } else {
            message.error(out.data.message);
        }

    }

    useEffect(() => {
        message.config({
            maxCount: 1,
            duration: 2,
        });
    }, []);

    return (
        <div>
            <div className="addMember">
                <Tooltip title="Add User">
                    <Button onClick={() => {
                        SetAddParticipants(true)
                        LoadMembers()
                    }} shape="circle" icon={<UserAddOutlined />} />
                </Tooltip>
            </div>
            <Modal
                // title="Vertically centered modal dialog"
                centered
                open={addParticipantsDialog}
                onOk={() => SetAddParticipants(false)}
                onCancel={() => SetAddParticipants(false)}
                footer={false}

            >
                <TitleBox
                    style={{ marginTop: -25 }}
                    title={"Add Members"}
                    body={
                        <div style={{ width: "100%", paddingTop: 15 }}>

                            {!loadingMembers ?
                                <Row gutter={[16, 16]}>
                                    {
                                        otherActionParticipants.map((c, k) => (
                                            <Tooltip title={"Add " + c.title + " " + c.first_name + " " + c.last_name} color={"#108ee9"} key={k}>
                                                <Col span={12} onClick={() => addNewParticipant(c)}>
                                                    {/* <Tooltip title="Remove Member"> */}
                                                    <div>
                                                        <CommentBox onClick={() => {
                                                            // removeParticipant(c)

                                                        }} name={c.title + " " + c.first_name + " " + c.last_name} comment={c.designation} />
                                                    </div>
                                                    {/* </Tooltip> */}
                                                    {/* <Avatar size='small'>{c}</Avatar> */}
                                                    {/* <Text>{c.title + " " + c.first_name + " " + c.last_name + " | " + c.designation}</Text> */}

                                                </Col>
                                            </Tooltip>
                                        ))
                                    }
                                </Row> :
                                <div>
                                    <LoadingOutlined />
                                </div>
                            }


                        </div>
                    }

                />
            </Modal>
        </div>
    );
};

export default AddParticipantsModal;
