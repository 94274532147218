import React from 'react'
import { useEffect } from 'react'
import { getPublishedDonorRequests } from '../../../../api'
import { useState } from 'react'
import { Avatar, Card, Col, Divider, Empty, List, Row, Skeleton, Typography } from 'antd'
import { UserOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';

function MyDonorContributions() {

    const [data, setData] = useState()
    const [loaded, setLoaded] = useState(false)

    useEffect(() => {
        loadData()
    }, [])

    const loadData = async () => {
        getPublishedDonorRequests().then(res => {
            var sortedArray = res.data.out.sort((a, b) => new Date(b.created) - new Date(a.created));

            setData(sortedArray)
            setLoaded(true)
        });

    }
    return (
        <div style={{ width: "100%" }}>
            <Card className='dash5-card' onClick={() => window.location.href = "/donormanagement/0"}>
                <div className='new-action-card-new'>

                    <Typography className='ac-new-inner-card-title'>Donor Requests</Typography>

                    {loaded ?
                        <div

                            style={{
                                width: '100%',

                                paddingLeft: '5px',
                                overflow: 'hidden',
                                height: '60vh',
                                padding: ' 0px',
                                // overflowX: "hidden"
                            }} >
                            {data.length > 0 ?

                                <div style={{ width: "100%", padding: "10px 0px" }}>
                                    <Row gutter={[16, 16]} >
                                        <Col xs={3} style={{ textAlign: "left" }}>
                                            <Typography className="denifitive-table-column-label">Created</Typography>

                                        </Col>
                                        <Col xs={4} style={{ textAlign: "left", display: "flex", justifyContent: "flex-start" }}>
                                            <Typography className="denifitive-table-column-label">Agency</Typography>

                                        </Col>
                                        <Col xs={6} style={{ textAlign: "left", display: "flex", justifyContent: "flex-start" }}>
                                            <Typography className="denifitive-table-column-label">Measure</Typography>

                                        </Col>
                                        <Col xs={4} style={{ textAlign: "left", display: "flex", }}>
                                            <Typography className="denifitive-table-column-label">Subject</Typography>
                                        </Col>
                                        <Col xs={4} style={{ textAlign: "left", display: "flex", }}>
                                            <Typography className="denifitive-table-column-label">Tentative Start Date</Typography>
                                        </Col>
                                        <Col xs={3} style={{ textAlign: "center", display: "flex", justifyContent: "center" }}>
                                            <Typography className="denifitive-table-column-label">Support Type</Typography>
                                        </Col>
                                        <Col span={24}>
                                            <div
                                                className=' user-measure custom-scroll-div' style={{
                                                    width: '100%',
                                                    paddingTop: '20px',
                                                    // paddingLeft: '5px',
                                                    overflow: 'hidden auto',
                                                    height: '30vh',
                                                    paddingBottom: '29px',
                                                    // borderTop: '3px solid #8f1fe5',
                                                    borderRadius: '0px'
                                                }}
                                            >
                                                {data && data.map((e, k) => {
                                                    return <Card style={{ margin: "5px" }}>

                                                        <Row key={k} gutter={[16, 16]}>
                                                            <Col xs={3} style={{
                                                                textAlign: "center",
                                                                justifyContent: "center",
                                                                alignItems: "start",
                                                                display: "flex",

                                                                gap: 3
                                                            }}>

                                                                <Typography style={{ fontFamily: "Figtree", fontSize: 14 }}>{dayjs(e.created).format("DD/MM/YYYY")}</Typography>

                                                            </Col>
                                                            <Col xs={4} style={{ textAlign: "left" }}>
                                                                <Typography style={{ fontFamily: "Figtree", fontSize: 14 }}>{e.agency_name ? e.agency_name : e.agency_title ? e.agency_title : ""}</Typography>



                                                            </Col>
                                                            <Col xs={6} style={{ display: "flex", textAlign: "left" }}>
                                                                <Typography style={{ fontFamily: "Figtree", fontSize: 14 }}>{e.article_no + '.' + e.measure_no + ". " + e.measure_name}</Typography>

                                                            </Col>
                                                            <Col xs={4} style={{ textAlign: " left", display: "flex" }}>
                                                                <Typography style={{ fontFamily: "Figtree", fontSize: 14 }}>{e.subject}</Typography>

                                                            </Col>
                                                            <Col xs={4} style={{ textAlign: " center", display: "flex", justifyContent: "center", alignItems: "start" }}>
                                                                <Typography style={{ fontFamily: "Figtree", fontSize: 14 }}>{e.implementation_from ? dayjs(e.implementation_from).format("DD/MM/YYYY") : "-"}</Typography>
                                                            </Col>
                                                            <Col xs={3} style={{ textAlign: "center", display: "flex", justifyContent: "center", alignItems: "start" }}>
                                                                <Typography>{e.assistance == 0 ? "TS/CB" : "OTHER"}
                                                                </Typography>
                                                            </Col>



                                                        </Row>
                                                    </Card>

                                                })}

                                            </div>

                                        </Col>



                                    </Row>


                                </div>
                                :

                                <Empty />


                            }

                        </div> :
                        <Skeleton
                            active
                            avatar
                            paragraph={{
                                rows: 4,
                            }}
                        />

                    }



                </div>
            </Card>

        </div>
    )
}

export default MyDonorContributions