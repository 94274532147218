import { Table, Button, Typography, Form, Descriptions, Divider, Alert, Tag, Space, Select, Drawer, Input, Upload, Radio, message, ConfigProvider } from "antd";
import { LinkOutlined, PlusOutlined, InboxOutlined } from '@ant-design/icons';
import { useEffect, useState } from "react";
import 'react-quill/dist/quill.snow.css';
import { addDonorInterests, addDonorRequest, getAgencies, getAgencyList, getDonorInterests, getMeasures, getMyDonorRequests } from "../../api/index.js";
import PageLayouts from "../../Components/PageLayouts/index.jsx";
import CustomTable from "../../Components/Table/index.js";
import ReactQuill from 'react-quill';
import FormLayouts from "./Components/FormLayouts.jsx";


import { ToastContainer, toast, Zoom } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { getUserData } from "../Donors/getCounts.js";
import Dragger from "antd/es/upload/Dragger.js";

const DonorRequestsNtfc = () => {
    const [selected, setSelected] = useState(null);
    const [open, setOpen] = useState(false);
    const [filtered_data, setFilteredData] = useState([]);
    const [interests, setInterests] = useState([]);
    const [purpose, setPurpose] = useState(0);
    const [agency, setAgency] = useState(null);// selected Agency
    const [agencies, setAgencies] = useState(null);//agencies list from DB
    const [measure, setMeasure] = useState(null);//selected Measure
    const [measures, setMeasures] = useState(null);//Measures list from DB
    const [assistance, setAssistance] = useState(0);
    const [subBtnLoad, setLoad] = useState(false);
    const [user, setUser] = useState({})
    const [value, setValue] = useState(1);
    const [fileList, setFileList] = useState([]);

    const fetchInterests = () => {
        getDonorInterests().then((out) => setInterests(out.data.out))
    }
    const [form] = Form.useForm();
    const showDrawer = () => {
        setOpen(true);
    };
    const onClose = () => {
        setOpen(false);
        fetchInterests();
    };
    const [requiredMark, setRequiredMarkType] = useState('optional');
    const onRequiredTypeChange = ({ requiredMarkValue }) => {
        setRequiredMarkType(requiredMarkValue);
    };
    const [description, setDescription] = useState('');
    const normFile = (e) => {
        console.log('Upload event:', e);
        if (Array.isArray(e)) {
            return e;
        }
        return e?.fileList;
    };

    const submit = (val) => {
        console.log(val);
        addDonorInterests(val).then(() => (console.log('')))
    }

    const filterData = (val) => {

    }

    const onFinish = (values) => {
        setLoad(true)

        const formData = new FormData();
        formData.append('request_file', fileList);
        formData.append('subject', values.subject);
        formData.append('description', values.description)
        formData.append('measure', values.measure);
        formData.append('assistance', values.assistance)


        values['assistance'] = assistance;


        if (purpose === 2) {
            values['agency'] = agency;
            formData.set('agency', agency)
        } else {
            values['agency'] = 999;
            formData.set('agency', 999)
        }

        if (window.localStorage.getItem('ntfc_level') !== 6 && window.localStorage.getItem('ntfc_level') !== 7) {
            values['agency'] = user.agency_id
            values['scope'] = 2;
            formData.set('agency', user.agency_id)
            formData.set('scope', 2)
            console.log("user---------")
        } else {
            values['scope'] = purpose;
            formData.set('scope', purpose)
        }


        form.setFieldValue('description', description)

        addDonorRequest(formData).then((out) => {
            if (out.data.status === 200) {
                form.resetFields();
                refreshRequests();
                setDescription("")
                toast.success("Donor Request Created Successfully", {

                    autoClose: 3000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progressClassName: 'toast-progress-bar',
                });
                setLoad(false)
                setFileList([])

            } else {
                toast.error(out.data.message, {

                    autoClose: 3000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progressClassName: 'toast-progress-bar',
                });
                setLoad(false)
                setFileList([])
            }
        }).catch((err) => {
            console.log(err);
            setDescription("")
            toast.error("Error on donor request", {

                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progressClassName: 'toast-progress-bar',
            });
            setLoad(false)
            setFileList([])
        })



    };

    const onFinishFailed = (errorInfo) => {

        toast.error("Request creation unsuccessful", {

            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progressClassName: 'toast-progress-bar',
        });
    };

    const [messageApi, contextHolder] = message.useMessage();

    const success = (msg) => {
        messageApi.open({
            type: 'success',
            content: msg,
            style: {
                marginTop: '30vh',
                height: '100vh',
                fontSize: '20px'
            },
            duration: 3
        });
    };

    const error = (msg) => {
        messageApi.open({
            type: 'error',
            content: msg,
            style: {
                marginTop: '30vh',
                height: '100vh',
                fontSize: '20px'
            },
            duration: 3
        });
    };

    const refreshRequests = () => {
        getAgencies().then((out) => {
            setAgencies(out.data.out);
        });

        getMeasures().then((out2) => {
            setMeasures(out2.data.out);
        });

        getMyDonorRequests().then((out3) => {
            setInterests(out3.data.out);
        });
    }

    const columns = [
        // {
        //     title: 'ID',
        //     dataIndex: 'id',
        //     key: 'id'
        // },
        {
            title: 'Subject',
            dataIndex: 'subject',
            key: 'subject',
        },
        {
            title: 'Measure',
            dataIndex: 'measure_name',
            key: 'measure_name',
        },
        {
            title: 'Purpose',
            dataIndex: 'scope',
            key: 'scope',
            render: (_, { scope }) => (
                <Tag color={scope == 0 ? "#c1760d" : "#326379"} key={scope} style={{ width: 120, display: 'flex', justifyContent: 'center' }} >
                    {
                        scope == 0 ? "ALL COUNTRIES"
                            : "AGENCIES"
                    }
                </Tag>
            ),
        },
        {
            title: 'Description',
            dataIndex: 'description',
            key: 'description',
        },
        // {
        //     title: 'Attachment',
        //     dataIndex: 'attachment',
        //     key: 'attachment',
        // },
        {
            title: 'Support Type',
            dataIndex: 'assistance',
            key: 'assistance',
            render: (_, { assistance }) => (
                <Tag color={assistance == 0 ? "#f50" : "#2a8c00"} key={assistance} style={{ width: 150, display: 'flex', justifyContent: 'center' }} >
                    {
                        assistance == 0 ? "TECH ASSISTANCE"
                            : "CAPACITY BUILDING"
                    }
                </Tag>
            ),
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: (_, { status }) => (
                <Tag color={status == 0 ? "#008282" : "#9B0259"} key={status} style={{ width: 120, display: 'flex', justifyContent: 'center' }} >
                    {
                        status == 0 ? "PENDING"
                            : "PUBLISHED"
                    }
                </Tag>
            ),
        },
    ];

    const onChange = (e) => {

        setValue(e.target.value);
    };

    useEffect(() => {
        getUserDetails()
        refreshRequests();
    }, []);

    const getUserDetails = async function () {

        const user = await getUserData()

        setUser(user)

        console.log("user", user)
    }
    async function handleFileUpload(file) {

        setFileList(file);

    }

    const props = {
        name: 'file',
        multiple: false,
        // action: ,

        onChange(info) {



            console.log('FIle', info)
            const { status } = info.file;
            if (status !== 'uploading') {
                console.log(info.file, info.fileList);
            }
            if (status === 'done') {

                toast.success(`${info.file.name} file uploaded successfully.`, {

                    autoClose: 3000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progressClassName: 'toast-progress-bar',
                });
                handleFileUpload(info.file);
            } else if (status === 'error') {

                toast.error(`${info.file.name} file upload failed.`, {

                    autoClose: 3000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progressClassName: 'toast-progress-bar',
                });
            }
        },
        onDrop(e) {
            console.log('Dropped files', e.dataTransfer.files);
        },
    };


    return <>
        <div style={{ display: 'flex', justifyContent: 'space-between', height: '100vh', overflow: "auto" }}>

            <FormLayouts
                title="Donor Requests"
                formSide={
                    <ConfigProvider
                        theme={{
                            token: {
                                colorPrimary: '#006666',
                            },
                        }}
                    >

                        <Form
                            form={form}
                            layout="vertical"
                            onFinish={onFinish}
                            onFinishFailed={onFinishFailed}
                        >
                            {(window.localStorage.getItem('ntfc_level') === 6 || window.localStorage.getItem('ntfc_level') === 7) &&
                                <Form.Item
                                    // label="Purpose"
                                    required name="purpose"
                                    rules={[
                                        {
                                            required: true,
                                            message: <p style={{ marginLeft: "12px" }}>Purpose is required</p>
                                        }]}

                                >
                                    <Select
                                        onChange={(val) => setPurpose(val)}
                                        placeholder="Purpose"
                                        options={
                                            [
                                                { value: 1, label: 'National Wise' },
                                                { value: 2, label: 'Agency Wise' },
                                            ]
                                        }
                                    />
                                </Form.Item>}

                            {purpose === 2 ?
                                <Form.Item
                                    required rules={[
                                        {
                                            required: true,
                                            message: <p style={{ marginLeft: "12px" }}>Agency is required</p>
                                        }]}
                                    // label="Agency" 
                                    name="agency">
                                    <Select
                                        placeholder="Agency"
                                        onChange={(val) => setAgency(val)}
                                        options={agencies.filter((element) => element.agency_type === 1)}
                                    />
                                </Form.Item>
                                : ""}
                            <Form.Item
                                // label="Subject" 
                                required rules={[
                                    {
                                        required: true,
                                        message: <p style={{ marginLeft: "12px" }}>Subject is required</p>
                                    }]} name="subject">
                                <Input type='text' placeholder="Subject" />
                            </Form.Item>
                            <Form.Item
                                // label="Measure" 
                                required rules={[
                                    {
                                        required: true,
                                        message: <p style={{ marginLeft: "12px" }}>Measure is required</p>
                                    }]} name="measure">
                                <Select
                                    placeholder="Select Measure"
                                    options={measures}
                                    onChange={(val) => setMeasure(val)}
                                />
                            </Form.Item>
                            <Form.Item
                                // label="Proposal" 
                                name="proposal">

                                <Radio.Group onChange={onChange} value={value} defaultValue={1}>
                                    <Radio type='primary' value={1}>Upload</Radio>
                                    <Radio type='primary' value={2}>Created</Radio>

                                </Radio.Group>

                            </Form.Item>

                            {value == 1 &&
                                <div style={{ margin: "30px 0" }}>
                                    <Dragger {...props}
                                        showUploadList={{ showProgress: true }}

                                        beforeUpload={file => {
                                            handleFileUpload(file);
                                            return false; // to prevent automatic upload
                                        }}

                                    >
                                        <p className="ant-upload-drag-icon">
                                            <InboxOutlined tyle={{ color: "#33363F" }} />
                                        </p>
                                        <p className="ant-upload-text">Click or drag a file to this area to upload</p>

                                    </Dragger>
                                </div>
                            }


                            <Form.Item
                                // label="Description"
                                required rules={[
                                    {
                                        required: true,
                                        message: <p style={{ marginLeft: "1px" }}>Description is required</p>
                                    }]} name="description">
                                <Input.TextArea
                                    placeholder="Description"
                                    rows={5} maxLength={200} value={description} onChange={(e) => {
                                        setDescription(e.target.value)
                                        form.setFieldValue('description', e.target.value)
                                    }} />
                                <div>
                                    <p style={{ float: "right", color: "white" }}>{description.length}/200</p>
                                </div>
                            </Form.Item>

                            <Form.Item
                                // label="Asist Type"
                                required rules={[
                                    {
                                        required: true,
                                        message: <p style={{ marginLeft: "12px", marginTop: -16 }}>Support Type is required</p>
                                    }]} name="assistance">
                                <Select
                                    placeholder="Support Type"
                                    onChange={(val) => setAssistance(val)}

                                    options={
                                        [
                                            { value: 0, label: 'Technical Support' },
                                            { value: 1, label: 'Capacity Building' },
                                        ]
                                    }
                                />
                            </Form.Item>
                            <Form.Item>
                                <Space style={{ display: "flex", justifyContent: "flex-end" }}>

                                    <ConfigProvider
                                        theme={{
                                            token: {
                                                colorPrimary: '#006666',
                                            },
                                        }}
                                    >
                                        <Button loading={subBtnLoad} type='primary' htmlType='submit'>Submit</Button>
                                    </ConfigProvider>

                                    {/* <Button onClick={}>Cancel</Button> */}
                                </Space>
                            </Form.Item>
                        </Form>
                    </ConfigProvider>
                }
                table={<Table
                    columns={columns}
                    dataSource={interests}
                    pagination={{
                        pageSize: 10,
                        total: filtered_data.length,
                    }}
                />}
            />

            <ToastContainer
                transition={Zoom}
                position="top-center"
                autoClose={5000}
                hideProgressBar
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored"
                style={{ top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}
            />



        </div>
    </>

}

export default DonorRequestsNtfc;