import { Card, Typography } from 'antd'
import React from 'react'
import '../dashboard4.css'
import { NewColorTheme } from '../../../../appConst'

function DBMainCard({ backgroundColor, titleColor, title, content, cardBackground, onClick, style }) {
    return (
        <Card className='dash5-card' style={{ border: `2px solid ${NewColorTheme.primmaryLightColor}` }}>
            <div className='new-action-card-bottom-new'>
                <div
                    className='action-card-five-new'
                >
                    <Typography className='ac-new-inner-card-title' style={{ color: NewColorTheme.primaryDarkColor }}>{title}</Typography>    </div>
                <div>
                    {content}
                </div>

            </div>
        </Card>

    )
}

export default DBMainCard