export const AGENCY_TYPES = [
  { value: 1, label: 'Government Agency' },
  { value: 2, label: 'Private Sector' },
  { value: 3, label: 'Donors' },
];

export const MEASURE_CATEGORIES = [
  { value: 'A', label: 'A' },
  { value: 'B', label: 'B' },
  { value: 'C', label: 'C' },
];

export const COUNTRY_LIST = [{
  label: "Afghanistan",
  value: "Afghanistan"
}, {
  label: "Albania",
  value: "Albania"
}, {
  label: "Algeria",
  value: "Algeria"
}, {
  label: "Andorra",
  value: "Andorra"
}, {
  label: "Angola",
  value: "Angola"
}, {
  label: "Anguilla",
  value: "Anguilla"
}, {
  label: "Antigua &amp;amp; Barbuda",
  value: "Antigua &amp;amp; Barbuda"
}, {
  label: "Argentina",
  value: "Argentina"
}, {
  label: "Armenia",
  value: "Armenia"
}, {
  label: "Aruba",
  value: "Aruba"
}, {
  label: "Australia",
  value: "Australia"
}, {
  label: "Austria",
  value: "Austria"
}, {
  label: "Azerbaijan",
  value: "Azerbaijan"
}, {
  label: "Bahamas",
  value: "Bahamas"
}, {
  label: "Bahrain",
  value: "Bahrain"
}, {
  label: "Bangladesh",
  value: "Bangladesh"
}, {
  label: "Barbados",
  value: "Barbados"
}, {
  label: "Belarus",
  value: "Belarus"
}, {
  label: "Belgium",
  value: "Belgium"
}, {
  label: "Belize",
  value: "Belize"
}, {
  label: "Benin",
  value: "Benin"
}, {
  label: "Bermuda",
  value: "Bermuda"
}, {
  label: "Bhutan",
  value: "Bhutan"
}, {
  label: "Bolivia",
  value: "Bolivia"
}, {
  label: "Bosnia &amp;amp; Herzegovina",
  value: "Bosnia &amp;amp; Herzegovina"
}, {
  label: "Botswana",
  value: "Botswana"
}, {
  label: "Brazil",
  value: "Brazil"
}, {
  label: "British Virgin Islands",
  value: "British Virgin Islands"
}, {
  label: "Brunei",
  value: "Brunei"
}, {
  label: "Bulgaria",
  value: "Bulgaria"
}, {
  label: "Burkina Faso",
  value: "Burkina Faso"
}, {
  label: "Burundi",
  value: "Burundi"
}, {
  label: "Cambodia",
  value: "Cambodia"
}, {
  label: "Cameroon",
  value: "Cameroon"
}, {
  label: "Cape Verde",
  value: "Cape Verde"
}, {
  label: "Cayman Islands",
  value: "Cayman Islands"
}, {
  label: "Chad",
  value: "Chad"
}, {
  label: "Chile",
  value: "Chile"
}, {
  label: "China",
  value: "China"
}, {
  label: "Colombia",
  value: "Colombia"
}, {
  label: "Congo",
  value: "Congo"
}, {
  label: "Cook Islands",
  value: "Cook Islands"
}, {
  label: "Costa Rica",
  value: "Costa Rica"
}, {
  label: "Cote D Ivoire",
  value: "Cote D Ivoire"
}, {
  label: "Croatia",
  value: "Croatia"
}, {
  label: "Cruise Ship",
  value: "Cruise Ship"
}, {
  label: "Cuba",
  value: "Cuba"
}, {
  label: "Cyprus",
  value: "Cyprus"
}, {
  label: "Czech Republic",
  value: "Czech Republic"
}, {
  label: "Denmark",
  value: "Denmark"
}, {
  label: "Djibouti",
  value: "Djibouti"
}, {
  label: "Dominica",
  value: "Dominica"
}, {
  label: "Dominican Republic",
  value: "Dominican Republic"
}, {
  label: "Ecuador",
  value: "Ecuador"
}, {
  label: "Egypt",
  value: "Egypt"
}, {
  label: "El Salvador",
  value: "El Salvador"
}, {
  label: "Equatorial Guinea",
  value: "Equatorial Guinea"
}, {
  label: "Estonia",
  value: "Estonia"
}, {
  label: "Ethiopia",
  value: "Ethiopia"
}, {
  label: "Falkland Islands",
  value: "Falkland Islands"
}, {
  label: "Faroe Islands",
  value: "Faroe Islands"
}, {
  label: "Fiji",
  value: "Fiji"
}, {
  label: "Finland",
  value: "Finland"
}, {
  label: "France",
  value: "France"
}, {
  label: "French Polynesia",
  value: "French Polynesia"
}, {
  label: "French West Indies",
  value: "French West Indies"
}, {
  label: "Gabon",
  value: "Gabon"
}, {
  label: "Gambia",
  value: "Gambia"
}, {
  label: "Georgia",
  value: "Georgia"
}, {
  label: "Germany",
  value: "Germany"
}, {
  label: "Ghana",
  value: "Ghana"
}, {
  label: "Gibraltar",
  value: "Gibraltar"
}, {
  label: "Greece",
  value: "Greece"
}, {
  label: "Greenland",
  value: "Greenland"
}, {
  label: "Grenada",
  value: "Grenada"
}, {
  label: "Guam",
  value: "Guam"
}, {
  label: "Guatemala",
  value: "Guatemala"
}, {
  label: "Guernsey",
  value: "Guernsey"
}, {
  label: "Guinea",
  value: "Guinea"
}, {
  label: "Guinea Bissau",
  value: "Guinea Bissau"
}, {
  label: "Guyana",
  value: "Guyana"
}, {
  label: "Haiti",
  value: "Haiti"
}, {
  label: "Honduras",
  value: "Honduras"
}, {
  label: "Hong Kong",
  value: "Hong Kong"
}, {
  label: "Hungary",
  value: "Hungary"
}, {
  label: "Iceland",
  value: "Iceland"
}, {
  label: "India",
  value: "India"
}, {
  label: "Indonesia",
  value: "Indonesia"
}, {
  label: "Iran",
  value: "Iran"
}, {
  label: "Iraq",
  value: "Iraq"
}, {
  label: "Ireland",
  value: "Ireland"
}, {
  label: "Isle of Man",
  value: "Isle of Man"
}, {
  label: "Israel",
  value: "Israel"
}, {
  label: "Italy",
  value: "Italy"
}, {
  label: "Jamaica",
  value: "Jamaica"
}, {
  label: "Japan",
  value: "Japan"
}, {
  label: "Jersey",
  value: "Jersey"
}, {
  label: "Jordan",
  value: "Jordan"
}, {
  label: "Kazakhstan",
  value: "Kazakhstan"
}, {
  label: "Kenya",
  value: "Kenya"
}, {
  label: "Kuwait",
  value: "Kuwait"
}, {
  label: "Kyrgyz Republic",
  value: "Kyrgyz Republic"
}, {
  label: "Laos",
  value: "Laos"
}, {
  label: "Latvia",
  value: "Latvia"
}, {
  label: "Lebanon",
  value: "Lebanon"
}, {
  label: "Lesotho",
  value: "Lesotho"
}, {
  label: "Liberia",
  value: "Liberia"
}, {
  label: "Libya",
  value: "Libya"
}, {
  label: "Liechtenstein",
  value: "Liechtenstein"
}, {
  label: "Lithuania",
  value: "Lithuania"
}, {
  label: "Luxembourg",
  value: "Luxembourg"
}, {
  label: "Macau",
  value: "Macau"
}, {
  label: "Macedonia",
  value: "Macedonia"
}, {
  label: "Madagascar",
  value: "Madagascar"
}, {
  label: "Malawi",
  value: "Malawi"
}, {
  label: "Malaysia",
  value: "Malaysia"
}, {
  label: "Maldives",
  value: "Maldives"
}, {
  label: "Mali",
  value: "Mali"
}, {
  label: "Malta",
  value: "Malta"
}, {
  label: "Mauritania",
  value: "Mauritania"
}, {
  label: "Mauritius",
  value: "Mauritius"
}, {
  label: "Mexico",
  value: "Mexico"
}, {
  label: "Moldova",
  value: "Moldova"
}, {
  label: "Monaco",
  value: "Monaco"
}, {
  label: "Mongolia",
  value: "Mongolia"
}, {
  label: "Montenegro",
  value: "Montenegro"
}, {
  label: "Montserrat",
  value: "Montserrat"
}, {
  label: "Morocco",
  value: "Morocco"
}, {
  label: "Mozambique",
  value: "Mozambique"
}, {
  label: "Namibia",
  value: "Namibia"
}, {
  label: "Nepal",
  value: "Nepal"
}, {
  label: "Netherlands",
  value: "Netherlands"
}, {
  label: "Netherlands Antilles",
  value: "Netherlands Antilles"
}, {
  label: "New Caledonia",
  value: "New Caledonia"
}, {
  label: "New Zealand",
  value: "New Zealand"
}, {
  label: "Nicaragua",
  value: "Nicaragua"
}, {
  label: "Niger",
  value: "Niger"
}, {
  label: "Nigeria",
  value: "Nigeria"
}, {
  label: "Norway",
  value: "Norway"
}, {
  label: "Oman",
  value: "Oman"
}, {
  label: "Pakistan",
  value: "Pakistan"
}, {
  label: "Palestine",
  value: "Palestine"
}, {
  label: "Panama",
  value: "Panama"
}, {
  label: "Papua New Guinea",
  value: "Papua New Guinea"
}, {
  label: "Paraguay",
  value: "Paraguay"
}, {
  label: "Peru",
  value: "Peru"
}, {
  label: "Philippines",
  value: "Philippines"
}, {
  label: "Poland",
  value: "Poland"
}, {
  label: "Portugal",
  value: "Portugal"
}, {
  label: "Puerto Rico",
  value: "Puerto Rico"
}, {
  label: "Qatar",
  value: "Qatar"
}, {
  label: "Reunion",
  value: "Reunion"
}, {
  label: "Romania",
  value: "Romania"
}, {
  label: "Russia",
  value: "Russia"
}, {
  label: "Rwanda",
  value: "Rwanda"
}, {
  label: "Saint Pierre &amp;amp; Miquelon",
  value: "Saint Pierre &amp;amp; Miquelon"
}, {
  label: "Samoa",
  value: "Samoa"
}, {
  label: "San Marino",
  value: "San Marino"
}, {
  label: "Satellite",
  value: "Satellite"
}, {
  label: "Saudi Arabia",
  value: "Saudi Arabia"
}, {
  label: "Senegal",
  value: "Senegal"
}, {
  label: "Serbia",
  value: "Serbia"
}, {
  label: "Seychelles",
  value: "Seychelles"
}, {
  label: "Sierra Leone",
  value: "Sierra Leone"
}, {
  label: "Singapore",
  value: "Singapore"
}, {
  label: "Slovakia",
  value: "Slovakia"
}, {
  label: "Slovenia",
  value: "Slovenia"
}, {
  label: "South Africa",
  value: "South Africa"
}, {
  label: "South Korea",
  value: "South Korea"
}, {
  label: "Spain",
  value: "Spain"
}, {
  label: "Sri Lanka",
  value: "Sri Lanka"
}, {
  label: "St Kitts &amp;amp; Nevis",
  value: "St Kitts &amp;amp; Nevis"
}, {
  label: "St Lucia",
  value: "St Lucia"
}, {
  label: "St Vincent",
  value: "St Vincent"
}, {
  label: "St. Lucia",
  value: "St. Lucia"
}, {
  label: "Sudan",
  value: "Sudan"
}, {
  label: "Suriname",
  value: "Suriname"
}, {
  label: "Swaziland",
  value: "Swaziland"
}, {
  label: "Sweden",
  value: "Sweden"
}, {
  label: "Switzerland",
  value: "Switzerland"
}, {
  label: "Syria",
  value: "Syria"
}, {
  label: "Taiwan",
  value: "Taiwan"
}, {
  label: "Tajikistan",
  value: "Tajikistan"
}, {
  label: "Tanzania",
  value: "Tanzania"
}, {
  label: "Thailand",
  value: "Thailand"
}, {
  label: "Timor L'Este",
  value: "Timor L'Este"
}, {
  label: "Togo",
  value: "Togo"
}, {
  label: "Tonga",
  value: "Tonga"
}, {
  label: "Trinidad &amp;amp; Tobago",
  value: "Trinidad &amp;amp; Tobago"
}, {
  label: "Tunisia",
  value: "Tunisia"
}, {
  label: "Turkey",
  value: "Turkey"
}, {
  label: "Turkmenistan",
  value: "Turkmenistan"
}, {
  label: "Turks &amp;amp; Caicos",
  value: "Turks &amp;amp; Caicos"
}, {
  label: "Uganda",
  value: "Uganda"
}, {
  label: "Ukraine",
  value: "Ukraine"
}, {
  label: "United Arab Emirates",
  value: "United Arab Emirates"
}, {
  label: "United Kingdom",
  value: "United Kingdom"
}, {
  label: "Uruguay",
  value: "Uruguay"
}, {
  label: "Uzbekistan",
  value: "Uzbekistan"
}, {
  label: "Venezuela",
  value: "Venezuela"
}, {
  label: "Vietnam",
  value: "Vietnam"
}, {
  label: "Virgin Islands (US)",
  value: "Virgin Islands (US)"
}, {
  label: "Yemen",
  value: "Yemen"
}, {
  label: "Zambia",
  value: "Zambia"
}, {
  label: "Zimbabwe",
  value: "Zimbabwe"
}];
