import { Card, Col, Row, Tooltip, Typography } from "antd";
import { useEffect, useState } from "react";
import { getAllInquiryCounts } from "../../../api";
import { NewColorTheme } from "../../../appConst";

const InquiryTabs = ({ tab, setTab, refreshCounts, setRefreshCounts }) => {
  const [counts, setCounts] = useState(null);

  useEffect(() => {
    getAllInquiryCounts().then((out) => {
      if (out && out.data && out.data.out) {
        setCounts(out.data.out);
      }
    });
  }, []);

  useEffect(() => {
    getAllInquiryCounts().then((out) => {
      if (out && out.data && out.data.out) {
        setCounts(out.data.out);
      }
    });
  }, [tab]);

  useEffect(() => {
    if (refreshCounts === true) {
      getAllInquiryCounts().then((out) => {
        if (out && out.data && out.data.out) {
          setCounts(out.data.out);
        }
        setRefreshCounts(false);
      });
    }
  }, [refreshCounts]);

  const TabTile = ({ title, tail, pos, ttitle }) => (
    <Col lg={6} xs={24}>
      <Tooltip title={ttitle}>
        <Card
          style={{
            height: "10vh",
            border: pos === tab ? `2px solid ${NewColorTheme.primaryColor}` : "none",
            background: "#f5f9ff",
            display: "flex",
            alignItems: "center",
            cursor: "pointer",
          }}
          onClick={() => setTab(pos)}
        >
          <Row gutter={16}>
            <Col xs={14} lg={14} style={{ textAlign: "center", display: "flex", alignItems: "center", flexDirection: "column", justifyContent: "center" }}>
              <Typography.Title
                style={{ margin: 0, color: NewColorTheme.primaryColor, fontSize: 20 }}
                level={3}
              >
                {title}
              </Typography.Title>
              <Typography.Title
                style={{ margin: 0, color: NewColorTheme.primaryColor, fontSize: 20 }}
                level={3}
              >
                Inquiries
              </Typography.Title>
            </Col>
            <Col
              xs={10}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                color: NewColorTheme.primaryColor,

              }}
            >
              {tail}
            </Col>
          </Row>
        </Card>
      </Tooltip>
    </Col>
  );

  return (
    <div style={{ paddingLeft: "40px", paddingRight: "25px", width: "100%" }}>
      <Row gutter={[16, 16]}>
        <TabTile
          ttitle="Inquiries which have published by NTFC to view by others. Only the inquiries which have received consent to publish are published"
          title="Published"
          pos={0}
          tail={
            <Typography.Title
              style={{ fontSize: 45, margin: 0, color: NewColorTheme.primaryColor }}
              level={1}
            >
              {counts ? counts.published : 0}
            </Typography.Title>
          }
        />
        <TabTile
          ttitle={"Inquiries created by me"}
          title="My"
          pos={1}
          tail={
            <Typography.Title
              style={{ fontSize: 45, margin: 0, color: NewColorTheme.primaryColor }}
              level={1}
            >
              {counts ? counts.my : 0}
            </Typography.Title>
          }
        />
        <TabTile
          ttitle={
            localStorage.getItem("ntfc_level") == "6" ||
              localStorage.getItem("ntfc_level") == "7"
              ? "Inquiries which are pending answers. NTFC secretariat can either answer or forward the answer to a relevant user"
              : "Inquiries which are pending answers from me"
          }
          title="Pending"
          pos={2}
          tail={
            <Typography.Title
              style={{ fontSize: 45, margin: 0, color: NewColorTheme.primaryColor }}
              level={1}
            >
              {counts ? counts.pending : 0}
            </Typography.Title>
          }
        />
        <TabTile
          ttitle={"Inquiries which were answered"}
          title="Answered"
          pos={3}
          tail={
            <Typography.Title
              style={{ fontSize: 45, margin: 0, color: NewColorTheme.primaryColor }}
              level={1}
            >
              {counts ? counts.answered : 0}
            </Typography.Title>
          }
        />
      </Row>
    </div>
  );
};

export default InquiryTabs;
