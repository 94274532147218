import React from 'react';
import ReactECharts from 'echarts-for-react';
import DonutLegend from '../Donut-legend/DonutLegend';
const Donut = ({ report }) => {
  const options = {
    tooltip: {
      trigger: 'item',
      formatter: '{a} <br/>{b} : {c} ({d}%)'
    },
    toolbox: {
      show: true,
      feature: {
        mark: { show: true },
        dataView: { show: false, readOnly: false },
        restore: { show: true },
        saveAsImage: { show: true }
      }
    },
    series: [
      {
        name: 'Action wise implementation percentage',
        type: 'pie',
        right: '0%',
        radius: [60, 120],
        itemStyle: {
          borderRadius: 0,
          borderWidth: 3,
          borderColor: '#fff',
          borderType: 'solid'
        },
        label: {
          show: true,
          fontSize: 14,
          formatter: '{d}%',
          position: 'inside'
        },
        emphasis: {
          label: {
            show: false
          }
        },
        color: [
          '#3346E7',
          '#DB3031',
          '#0492c2',
          '#8676FF',
          '#0492c2'
        ],
        data: report && report.data ? report.data.map((item) => { return { value: item.percentage, name: item.status } }) : []
      }
    ]
  };

  return <div>
    <ReactECharts option={options} />
    <DonutLegend />
  </div>;
};

export default Donut;