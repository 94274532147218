import React, { useState } from 'react';
import ReactECharts from 'echarts-for-react';
import { Badge, Col, Empty, Modal, Row, Tag, Typography } from 'antd';
import * as echarts from 'echarts/core';
const HBar = ({ report }) => {
  console.log("Reppoort----------------------->>", report)
  const getStyle = (type) => {
    if (type == "To Do") {
      return {
        color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
          { offset: 0, color: '#013e8f' },
          { offset: 1, color: '#012a58' }
        ])
      }
    } else if (type == "In Progress") {
      return {
        color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
          { offset: 0, color: '#0D6F8F' },
          { offset: 1, color: '#0D6F8F ' }
        ])
      }
    } else if (type == "Delayed") {
      return {
        color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
          { offset: 1, color: '#005fda' },
          { offset: 0, color: '#0076dc' }
        ])
      }
    } else if (type == "Missed") {
      return {
        color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
          { offset: 0, color: '#04E2B7 ' },
          { offset: 1, color: '#04E2B7 ' }
        ])
      }
    } else if (type == "Completed") {
      return {
        color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
          { offset: 1, color: '#007ed7' },
          { offset: 0, color: '#00b5b7' }
        ])
      }
    }

  }
  const [currentSelectedData, setCurrentSelectedData] = useState()

  function replaceStrings(strings) {
    const replacements = {
      "Department of Commerce": "DoC",
      "Sri Lanka Customs": "Customs",
      "Sri Lanka Carago": "Cargo"
    };

    const resultArray = strings.map((str) => {
      const words = str.split(/\s+/);
      const firstLetters = words
        .map((word) => word.replace(/^(of|and|[^\w])/i, "").charAt(0))
        .join("");
      return replacements[str] || firstLetters;
    });

    return resultArray;
  }
  const transformedArray = replaceStrings(report?.data?.agency_names ? report?.data?.agency_names : []);


  const options = {

    title: {
      text: 'AGENCY WISE MEASURE IMPLEMENTATION STATUS',
      subtext: 'Please note that, values displayed here are rounded values',
      left: 'center',
      top: -2,
      padding: [10, 10, 0, 0],
      // show: false,
      textStyle: {
        textTransform: 'uppercase', // Capitalize the text
        marginBottom: 50, // Add a 20px bottom margin to the title
      },
    },
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        // Use axis to trigger tooltip
        type: 'shadow' // 'shadow' as default; can also be 'line' or 'shadow',

      },
      // formatter: (params) => {
      //   console.log("Params===============>>", params)
      //   const { name, value } = params[0];
      //   return `Custom Title: ${name} - ${value}`;
      // },
      formatter: (params) => {

        return `
          <div >
            <div><strong>Click to see more details</strong></div>
           
        `;
      },


    },
    legend: {
      bottom: '1%',
      left: 'center',
      padding: [10, 0, 0, 0],
      textStyle: {
        fontSize: 12,
        margin: [0, 0, 20, 0]
      },
      // padding: [5]
    },
    toolbox: {
      show: true,
      feature: {
        mark: { show: true },
        restore: { show: false },
        saveAsImage: {
          show: true,
          name: 'Agency Wise Measure Implementation Status',
          title: 'Agency Wise Measure Implementation Status',
        }
      }
    },
    grid: {
      left: '3%',
      right: '4%',
      bottom: '10%',
      containLabel: true
    },
    yAxis: {
      max: 100,
      type: 'value',
      name: 'Completion Percentage (%)',
      nameLocation: 'center',
      axisLabel: {
        margin: 5, // Add a margin between the yAxis label and axis values
      },
      nameTextStyle: {
        padding: [0, 0, 15, 0], // Move the axis name 5 pixels away from the axis labels
        fontSize: 14, // Adjust the font size of the axis name if needed
        fontWeight: 'bold', // Adjust the font weight of the axis name if needed
      },
    },
    xAxis: {
      axisTick: {
        show: false, // Set show to false to hide the x-axis ticks
      },
      nameLocation: 'center',
      type: 'category',
      data: report && report.data ? transformedArray : [],
      axisLabel: {
        interval: 0, // display all labels
        rotate: 0, // rotate the labels for better readability
        margin: 10,   // set margin to prevent labels from being cut off
        textStyle: {
          fontSize: 10, // Change the font size here
        },
      },
      name: 'Agency',
      nameTextStyle: {
        padding: [15, 0, 0, 0], // Move the axis name 5 pixels away from the axis labels
        fontSize: 14, // Adjust the font size of the axis name if needed
        fontWeight: 'bold', // Adjust the font weight of the axis name if needed
      },
      // nameLocation: 'end',
    },
    color: [
      '#1520A6',
      '#0492C2',
      '#2f69b3',
      '#4169E1',
      '#AED8E6',
    ],
    series: [
      {


        name: 'Completed',
        type: 'bar',
        stack: 'total',
        label: {
          show: true,
          fontSize: 12,
          formatter: function (params, ticket) {
            if (params.value == 0.00 || params.value == 0) {
              return '';
            }
          }
        },
        itemStyle: getStyle('Completed'),
        emphasis: {
          focus: 'series'
        },
        data: report && report.data ? report.data.completed.map((comp, idx) => Math.round(((comp / (report.data.total[idx])) * 100))) : []
      },
      {

        name: 'In Progress',
        type: 'bar',
        stack: 'total',
        label: {
          show: true,
          fontSize: 12,
          formatter: function (params, ticket) {
            if (params.value == 0.00 || params.value == 0) {
              return '';
            }
          }
        },
        itemStyle: getStyle('In Progress'),
        emphasis: {
          focus: 'series'
        },
        data: report && report.data ? report.data.inprogress.map((comp, idx) => Math.round(((comp / (report.data.total[idx])) * 100))) : []
      },
      {

        name: 'Delayed',
        type: 'bar',
        stack: 'total',
        label: {
          show: true,
          fontSize: 12,
          formatter: function (params, ticket) {
            if (params.value == 0.00 || params.value == 0) {
              return '';
            }
          }
        },
        itemStyle: getStyle('Delayed'),
        emphasis: {
          focus: 'series'
        },
        data: report && report.data ? report.data.delayed.map((comp, idx) => Math.round(((comp / (report.data.total[idx])) * 100))) : []
      },
      {

        name: 'Missed',
        type: 'bar',
        stack: 'total',
        label: {
          color: "black",
          show: true,
          fontSize: 12,
          formatter: function (params, ticket) {
            if (params.value == 0.00 || params.value == 0) {
              return '';
            }
          }
        },
        itemStyle: getStyle('Missed'),
        emphasis: {
          focus: 'series'
        },
        data: report && report.data ? report.data.missed.map((comp, idx) => Math.round(((comp / (report.data.total[idx])) * 100))) : []
      },
      {
        itemStyle: getStyle('To Do'),
        name: 'To Do',
        type: 'bar',
        stack: 'total',
        label: {
          show: true,
          fontSize: 12,
          formatter: function (params, ticket) {
            if (params.value == 0.00 || params.value == 0) {
              return '';
            }
          }
        },

        emphasis: {
          focus: 'series'
        },
        data: report && report.data ? report.data.todo.map((comp, idx) => Math.round(((comp / (report.data.total[idx])) * 100))) : []
      }
    ]
  };
  const [viewDialog, setViewDialog] = useState(false);
  const [selected, setSelected] = useState(false);

  const onClickDonut = (params) => {
    console.log("params", params)

    let selectedAgencyID = report?.data?.agencies[params.dataIndex]
    let measureData

    if (params.seriesName == "To Do") {
      measureData = report.data.todo_arr.filter(element => element.agency_id == selectedAgencyID).filter((item, index, self) => {
        // Find the index of the first occurrence of the item
        const firstIndex = self.findIndex(obj => obj.measure_id === item.measure_id);

        // Keep the item only if its index matches the first occurrence index
        return index === firstIndex;
      })
      setCurrentSelectedData(measureData)
      console.log("Selected Agencey Data", measureData)
    }
    if (params.seriesName == "Missed") {
      measureData = report.data.missed_arr.filter(element => element.agency_id == selectedAgencyID)
        .filter((item, index, self) => {
          // Find the index of the first occurrence of the item
          const firstIndex = self.findIndex(obj => obj.measure_id === item.measure_id);

          // Keep the item only if its index matches the first occurrence index
          return index === firstIndex;
        })
      setCurrentSelectedData(measureData)
      console.log("Selected Agencey Data", measureData)
    }
    if (params.seriesName == "Completed") {
      measureData = report.data.completed_arr.filter(element => element.agency_id == selectedAgencyID)
        .filter((item, index, self) => {
          // Find the index of the first occurrence of the item
          const firstIndex = self.findIndex(obj => obj.measure_id === item.measure_id);

          // Keep the item only if its index matches the first occurrence index
          return index === firstIndex;
        })
      setCurrentSelectedData(measureData)
      console.log("Selected Agencey Data", measureData)
    }
    if (params.seriesName == "In Progress") {
      measureData = report.data.inprogress_arr.filter(element => element.agency_id == selectedAgencyID)
        .filter((item, index, self) => {
          // Find the index of the first occurrence of the item
          const firstIndex = self.findIndex(obj => obj.measure_id === item.measure_id);

          // Keep the item only if its index matches the first occurrence index
          return index === firstIndex;
        })
      setCurrentSelectedData(measureData)
      console.log("Selected Agencey Data", measureData)
    }
    if (params.seriesName == "Delayed") {
      measureData = report.data.delayed_arr.filter(element => element.agency_id == selectedAgencyID)
        .filter((item, index, self) => {
          // Find the index of the first occurrence of the item
          const firstIndex = self.findIndex(obj => obj.measure_id === item.measure_id);

          // Keep the item only if its index matches the first occurrence index
          return index === firstIndex;
        })
      setCurrentSelectedData(measureData)
      console.log("Selected Agencey Data", measureData)
    }

    setSelected(params)
    setViewDialog(true);
  }

  const handleCancel = () => {
    setViewDialog(false);

  };

  return report ? <><ReactECharts
    option={options}
    style={{ height: "80vh" }}
    onEvents={{ click: (event) => { onClickDonut(event,) }, }}

  />
    <Modal
      closable={true}
      centered
      className="custom-modal"
      width={'50%'}
      footer={false}
      onCancel={handleCancel}
      title={
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div>
            {" "}
            <Typography className="custom-modal-title" level={2}>
              {selected ? selected.name + "'s Measure Implementation - " + selected.seriesName : ""}
            </Typography>
          </div>
        </div>
      }
      open={viewDialog}
    >


      <div className="custom-modal-body">
        <Tag style={{ width: "100px", margin: "5px 0px " }} color="#1520A6">Measure List</Tag>
        <div
          className="custom-scroll-div"
          style={{
            height: 400,
            overflow: "auto",
            overflowX: 'hidden',
            width: 700,
            paddingRight: 15
          }}
        >

          <Row gutter={[8, 8]}>
            {currentSelectedData ? currentSelectedData?.map((element, index) => {
              return <Col xs={24} key={index}>
                <Row gutter={[2, 2]}>
                  <Col xs={24} md={1}>
                    <Badge color="blue" status="default" style={{ marginRight: "5px" }} />
                  </Col>
                  <Col xs={24} md={23}>
                    <span>

                      {element.measure}
                    </span>
                  </Col>


                </Row>

              </Col>

            }
            ) : <Empty />
            }
          </Row>




        </div>


      </div >
    </Modal >
  </> : null;
};

export default HBar;