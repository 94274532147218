import { Avatar, Button, Popover, Typography } from 'antd';
import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import { UserOutlined } from "@ant-design/icons";


function UserDetails({ username, designation, dashboardName }) {

    const [dateTime, setDateTime] = useState(new Date());
    const [userDesig, setUserDesig] = useState("");

    useEffect(() => {
        const intervalId = setInterval(() => {
            setDateTime(new Date());
        }, 1000);
        return () => clearInterval(intervalId);
    }, [])


    const dateOptions = {
        day: "numeric",
        month: "long",
        year: "numeric",
    };

    const timeOptions = {
        hour: "numeric",
        minute: "numeric",
        // second: "numeric",
    };

    const date = dateTime.toLocaleDateString(undefined, dateOptions);
    const time = dateTime.toLocaleTimeString(undefined, timeOptions);

    const getDesignation = (desig) => {
        console.log("Desig", desig);
        let designation_out = "";
        switch (Number(desig)) {
            case 1:
                designation_out = "Other";
                break;
            case 2:
                designation_out = "Donor";
                break;
            case 3:
                designation_out = "Private";
                break;
            case 4:
                designation_out = "Gov Technical";
                break;
            case 5:
                designation_out = "Gov HOD";
                break;
            case 6:
                designation_out = "Secretariat";
                break;
            case 7:
                designation_out = "Super Admin";
                break;
            default:
                break;
        }
        console.log("RETURNING", designation_out);
        return designation_out;
    }

    useEffect(() => {
        setUserDesig(getDesignation(designation));
    }, [designation])

    return (

        <div className="user-details" >

            <div className="name-designation">

                <Typography className="user-name" style={{ color: '#005476' }}>
                    {username}
                </Typography>
                <br />
                <Typography className="user-designation" style={{ color: '#005476' }}>
                    {userDesig}
                </Typography>


            </div>
            <Popover
                content=
                {
                    <Button

                        onClick={async () => {
                            await window.localStorage.removeItem('ntfc_token');
                            window.location.href = "/login";
                        }}>
                        Logout
                    </Button>
                }
                trigger="click">

                <Avatar className="avatar" shape="square" size={50} icon={<UserOutlined />} />

            </Popover>

            <div className="date-time">
                <Typography className="time" style={{ color: '#005476' }}>  {time}</Typography>
                <Typography className="date" style={{ color: '#005476' }}>  {date}</Typography>

            </div>



        </div>
    )
}

export default UserDetails