import { useEffect, useState } from 'react';
import { Button, Form, Input, Space, Typography, Select, Radio, message, Row, Col, Modal, Tooltip, Result } from 'antd';
import { BankOutlined, InfoCircleOutlined, LoadingOutlined, MailOutlined, UserAddOutlined, EditOutlined } from '@ant-design/icons';
import { CreateNewUser, checkDependancyUser, editUser, getAgencies, updateUserByID } from "../../api";
import { ToastContainer, toast, Slide } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import PhoneInput from 'react-phone-input-2';
import { forwardRef } from 'react';
import { useRef } from 'react';
const { Text } = Typography;

const UserEditNew = ({ loadUsers, user }) => {
    console.log("User", user)
    const [userData, setUser] = useState()
    const [checkDependancy, setCheckDependancy] = useState(false)
    const [formDisable, setFromDisable] = useState(false)


    useEffect(() => {
        console.log("user--------------->>", user)
    }, [])

    useEffect(() => {
        console.log("user", user)
        if (user) {

            form.setFieldValue('title', userData?.title)
            form.setFieldValue('first_name', userData?.first_name)
            form.setFieldValue('last_name', userData?.last_name)
            form.setFieldValue('agency_type', userData?.agency_type)
            form.setFieldValue('agency_name', userData?.agency_name)
            form.setFieldValue('designation', userData?.designation)

            form.setFieldValue('user_level', userData?.user_level)
            form.setFieldValue('email', userData?.email)
            form.setFieldValue('mobile', userData?.mobile)
            form.setFieldValue('phone', userData?.phone)
        }


    }, [userData])


    const [form] = Form.useForm();
    const [requiredMark, setRequiredMarkType] = useState('optional');
    const [agencies, setAgencies] = useState([]);
    const [agenciesMain, setAgenciesMain] = useState([]);
    const [mobile, setMobile] = useState("");
    const [officialMobile, setOfficialMobile] = useState("");
    const [agencyType, setAgencyType] = useState(4);
    const [agencyName, setAgencyName] = useState(null);




    const onFinish = async (val) => {
        console.log("submit", val)
        let newFormData = {};
        newFormData['title'] = val.title
        newFormData['first_name'] = val.first_name
        newFormData['last_name'] = val.last_name
        newFormData['agency_type'] = val.agency_type
        if (agencyType == -1) {
            newFormData['agency_name'] = val.agency_name
        } else if (agencyType == 0) {
            newFormData['agency_name'] = "Presidential"
            newFormData['agency_id'] = 9999;
        } else if (agencyType == 6) {
            newFormData['agency_name'] = "ADMIN"
            newFormData['agency_id'] = 9999;
        } else if (agencyType == 99) {
            newFormData['agency_name'] = "CO-CHAIR"
            newFormData['agency_id'] = 9999;
        } else {
            newFormData['agency_name'] = user?.agency_name
            newFormData['agency_id'] = user?.agency_id;
        }

        if (!val.user_level) {
            newFormData['user_level'] = agencyType;
        } else {
            newFormData['user_level'] = val.user_level
        }

        if (agencyType == 99) {
            newFormData['view_only'] = 1;
            newFormData['user_level'] = 6;
        }

        newFormData['designation'] = val.designation

        newFormData['mobile'] = val.mobile
        newFormData['phone'] = val.phone
        newFormData['email'] = val.email
        newFormData['uuid'] = user?.uuid

        const out = await editUser(newFormData);

        if (out) {
            form.resetFields();
            if (out.data.status === 200) {
                toast.success("User has been updated successfully", {
                    transition: Slide
                });
                onClose()
                loadUsers()

            } else {
                toast.error(out.data.message, {
                    transition: Slide
                });
            }


        } else {
            toast.error("Error on adding measure");
        }
    }

    const updateAgencyType = (input) => {

        setAgencyType(input);
        form.setFieldValue('agency_name', null);
        form.setFieldValue('agency_id', null);
        setAgencyName(null);
        refreshAgencies(input);
    }
    const refreshAgencies = (type) => {
        const filteredAgencies = agenciesMain.filter((ag) => ag.agency_type === type);

        setAgencies(filteredAgencies);
    }
    const checkDependancyFunc = async () => {
        setCheckDependancy(true)
        console.log("user ---->>", user)
        await checkDependancyUser({ user_id: user?.uuid }).then((out) => {
            console.log("out", out)
            if (!out.data.editable) {
                setFromDisable(true)

            } else {
                setFromDisable(false)
            }
            setTimeout(() => {
                setCheckDependancy(false)
            }, 2500);

            showDrawer()
        });



    }




    const [open, setOpen] = useState(false);


    const userEditCallback = () => {
        form.resetFields()
    }

    const showDrawer = (record) => {

        console.log(record)
        setOpen(true);
    };

    const onClose = () => {
        form.resetFields()
        setOpen(false);
        setUser({})

        userEditCallback();
    };
    return <>

        {/* <Button type="primary" icon={<UserAddOutlined />} onClick={() => showDrawer()} disabled={window.localStorage.getItem('view_only') == 1}>Add New User</Button> */}
        <Tooltip title="Edit User">
            <Button type="secondary" size='small'
                onClick={() => {
                    setUser(user)
                    checkDependancyFunc()


                }} shape="circle" icon={<EditOutlined style={{ color: '#946e6e' }} />} />
        </Tooltip>
        <Modal
            closable={true}
            maskClosable={false}
            className="custom-modal"
            width={650}
            footer={false}
            onCancel={onClose}
            title={
                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                    <div>     <Typography className='custom-modal-title' level={2}>Edit user</Typography></div>


                </div>


            } open={open} centered>

            <div className='custom-modal-body custom-scroll-div' style={{ height: "75vh", overflow: "auto" }} >

                {checkDependancy ?
                    <Result
                        icon={<LoadingOutlined />}
                        subTitle={`We are checking if there are any dependencies for this user,  you will be able to edit the user after the checking. Thank  you for your patience.`}
                        title="Please Wait"

                    /> :
                    <Form

                        form={form}
                        layout="vertical"
                        onFinish={onFinish}
                    >



                        <Space style={{ width: "100%" }}>
                            <Row gutter={[16, 16]}>
                                <Space>



                                    <Form.Item label="Title" name="title" required
                                        rules={[{
                                            required: true,
                                            message: "Title is Required",

                                        },]}
                                    >
                                        <Select
                                            allowClear={false}
                                            placeholder="Select Title"
                                            options={[{ value: 'Mr.', label: 'Mr.' }, { value: 'Mrs.', label: 'Mrs.' }, { value: 'Miss', label: 'Miss' }, { value: 'Dr.', label: 'Dr.' }]}

                                        />
                                    </Form.Item>


                                    <Form.Item label="First Name" name="first_name" required
                                        rules={[{
                                            required: true,
                                            message: "First name required",

                                        },
                                        {
                                            pattern: /^(?!\s)[a-zA-Z\s]+$/,

                                            message: "Please enter a valid name",
                                        },]}
                                    >
                                        <Input style={{ width: "12.3vw" }} type='text' placeholder="First Name" />
                                    </Form.Item>
                                    <Form.Item
                                        rules={[{
                                            required: true,
                                            message: "Last name required",

                                        },
                                        {
                                            pattern: /^(?!\s)[a-zA-Z\s]+$/,

                                            message: "Please enter a valid name",
                                        },]}
                                        label="Last Name" name="last_name" required >
                                        <Input style={{ width: "12.3vw" }} type='text' placeholder="Last Name" />
                                    </Form.Item>
                                </Space>

                                <Space direction='vertical' style={{ width: "100%" }}>
                                    <Form.Item
                                        rules={[{
                                            required: true,
                                            message: "Agency is required",

                                        },]}
                                        name='agency_type' label="Select Agency" required>

                                        <Select
                                            disabled={formDisable}
                                            placeholder="Select Agency"
                                            style={{ width: "100%" }}

                                            onChange={(value) => { updateAgencyType(value); form.setFieldValue('agency_type', value); }}
                                            // defaultValue={4}
                                            options={
                                                [
                                                    { value: 6, label: 'NTFC Secretariat' },
                                                    { value: 0, label: 'Presidential Secretariat' },
                                                    { value: 1, label: 'NTFC Member Agency (Government Sector)' },
                                                    { value: 2, label: 'NTFC Member Agency (Private Sector) ' },
                                                    { value: 3, label: 'Donor Agency' },
                                                    { value: -1, label: 'Non-NTFC Agency (Government and Private Sector)' },
                                                    { value: 99, label: 'Co-chair/Chair Person' }
                                                ]

                                            }
                                        />

                                    </Form.Item>

                                    {(agencyType != -1 && agencyType != 0 && agencyType != 6 && agencyType != 99) ? <Form.Item name="agency_name" required label="Agency Name"
                                        rules={[{
                                            required: true,
                                            message: "Agency Name is required",

                                        },
                                        { min: 5, message: 'At least 5 characters required' },


                                        ]}>

                                        <Select
                                            disabled={formDisable}
                                            placeholder="Agency Name"
                                            onChange={(value) => { form.setFieldValue('agency_name', value); form.setFieldValue('agency_id', value); setAgencyName(value) }}
                                            style={{ width: '100%', textAlign: 'left' }}
                                            options={agencies}
                                            value={agencyName}
                                        />

                                    </Form.Item> : null}
                                    {agencyType === -1 ? <Form.Item name="agency_name" required label="Agency Name"
                                        rules={[{
                                            required: true,
                                            message: "Agency Name is required",

                                        },
                                        {
                                            pattern: /^(?!\s)[a-zA-Z\s]+$/,

                                            message: "Please enter a valid name",
                                        },
                                        { min: 5, message: 'At least 5 characters required' },
                                        ]}>
                                        <Input disabled={formDisable} type='text' placeholder="Agency Name" style={{ width: "20.3vw" }} suffix={<BankOutlined style={{ color: "#D7D3D5" }} />} />
                                    </Form.Item> : null
                                    }


                                    <Form.Item label="Designation" name="designation" required
                                        rules={[{
                                            required: true,
                                            message: "Designation is required",

                                        },
                                        {
                                            pattern: /^(?!\s)[a-zA-Z\s]+$/,

                                            message: "Please enter a valid name",
                                        },]}
                                    >
                                        <Input disabled={formDisable} type='text' placeholder="Designation" />
                                    </Form.Item>

                                    {agencyType == 1 &&

                                        <Form.Item label="User Level" name="user_level" required
                                            rules={[{
                                                required: true,
                                                message: "User level is required",

                                            },]}
                                        >
                                            <Select
                                                disabled={formDisable}
                                                placeholder={"User Level"}
                                                allowClear={false}

                                                options={
                                                    [
                                                        { value: 1, label: 'Director General' },
                                                        { value: 5, label: 'Head of Agency' },
                                                        { value: 4, label: 'Technical User' },

                                                    ]
                                                } />
                                        </Form.Item>

                                    }


                                    <Form.Item label="Email" name="email" required
                                        rules={[{
                                            type: 'email',
                                            message: <p style={{ marginLeft: "12px" }}>Invalid Email</p>,
                                        },
                                        {
                                            required: true,
                                            message: <p style={{ marginLeft: "12px" }}>Email is required</p>,
                                        },]}>
                                        <Input type='email' placeholder="Email" style={{ width: "100%" }} suffix={<MailOutlined style={{ color: "#D7D3D5" }} />} />
                                    </Form.Item>



                                    <Form.Item name="mobile" required label="Mobile Number"
                                        rules={[
                                            {
                                                required: true,
                                                message: <p style={{ marginLeft: "12px" }}>Mobile Number is required</p>,
                                            },
                                            {
                                                pattern: /^(?!0)\d+$/,
                                                message: <p style={{ marginLeft: "12px" }}>Invalid Country Code</p>,
                                            },
                                            {
                                                max: 15,
                                                message: 'Maximum 15 digits allowed',
                                            },
                                        ]}
                                    >
                                        <PhoneInput
                                            country={'lk'}
                                            value={mobile}
                                            onChange={setMobile}
                                            placeholder="Mobile Number"
                                            inputProps={{
                                                placeholder: 'Mobile Number',

                                            }}
                                            inputStyle={{ width: "100%" }}
                                            // className="select-custom-pn"

                                            disableInitialCountryGuess={false}
                                        />
                                    </Form.Item>



                                    <Form.Item name="phone" label="Phone Number"
                                        rules={[
                                            {
                                                pattern: /^(?!0)\d+$/,
                                                message: <p style={{ marginLeft: "12px" }}>Invalid Country Code</p>,
                                            },
                                            {
                                                max: 15,
                                                message: 'Maximum 15 digits allowed',
                                            },
                                        ]}
                                    >
                                        <PhoneInput
                                            country={'lk'}
                                            value={officialMobile}
                                            onChange={setOfficialMobile}
                                            placeholder="Phone Number"
                                            inputStyle={{ width: "100%" }}
                                            // className="select-custom-pn"
                                            inputProps={{
                                                placeholder: 'Official Mobile Number',

                                            }}
                                            disableInitialCountryGuess={false}
                                        />
                                    </Form.Item>
                                    <div style={{ display: "flex", justifyContent: "flex-end" }}>
                                        <Button type='primary' htmlType='submit'>Save </Button>

                                    </div>
                                </Space>







                            </Row>



                        </Space>


                    </Form>
                }


            </div>



        </Modal>
        {/* <Text type="secondary">Add New User</Text> */}


    </>
}

export default UserEditNew;