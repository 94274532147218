import { useEffect, useState } from "react";
import { getMeasureWiseCompletion } from "../../../../api";
import Donut from '../../Country/Donut';
import MeasureWiseDonutModal from "./MeasureWiseDonutModal";
import { Modal, Row, Typography } from "antd";

const MeasureWiseDonut = ({ val }) => {

    const [repData, setRepData] = useState(null);
    const [mArray, setMArray] = useState([[], [], [], [], []]);
    const [detailed, setDetailed] = useState("");

    const refresh = (input) => {

        getMeasureWiseCompletion(input).then((out) => {
            if (out && out.data && out.data.summary) {
                const summary = out.data.summary;
                setRepData({
                    data: [{
                        percentage: summary.completed * 100,
                        status: "Completed"
                    }, {
                        percentage: summary.missed * 100,
                        status: "Missed"
                    }, {
                        percentage: summary.delayed * 100,
                        status: "Delayed"
                    }, {
                        percentage: summary.inprogress * 100,
                        status: "In Progress"
                    }, {
                        percentage: summary.todo * 100,
                        status: "To Do"
                    }]
                });
                console.log("SETTING MARRAY", out.data.measure_arr);
                setMArray(out.data.measure_arr);
            }
        });
    }

    useEffect(() => {
        refresh(0);
    }, []);

    useEffect(() => {
        refresh(val);
    }, [val]);

    return <>
        <Donut onClickDonut={(e) => { setDetailed(e.name); }} report={repData} measurewise={true} />
        <Modal
            closable={true}
            centered
            className="custom-modal"
            width={'80%'}
            footer={false}
            onCancel={() => setDetailed("")}
            title={
                <div
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                    }}
                >
                    <div>
                        {" "}
                        <Typography className="custom-modal-title" level={2}>
                            {`Measures in ${detailed} status`}
                        </Typography>
                    </div>
                </div>
            }
            open={detailed != ""}
        >
            <MeasureWiseDonutModal type={detailed} arrays={mArray} />
        </Modal >
    </>

}

export default MeasureWiseDonut;