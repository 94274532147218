import { Segmented, Select, Space, Typography } from 'antd';
import { useEffect, useState } from 'react';
import { AppstoreOutlined, GlobalOutlined, HomeOutlined } from '@ant-design/icons';
import Flag from 'react-world-flags';
import Country from './Country'
import My from './My'
import Agency from './Agency'
import { getAgencies, getAgency } from '../../api';
import { NewDashboard } from './NewDashBord';
const { Title } = Typography;

function Page() {
  const [type, setType] = useState('my');
  const [agency, setAgency] = useState();
  const [agencies, setAgencies] = useState([]);
  const [agencyName, setAgencyName] = useState("");
  const country = 'LK';
  // setCountry("LK");

  useEffect(() => {
    LoadData()
  }, []);

  const LoadData = async () => {

    setAgency(await window.localStorage.getItem('ntfc_agency'))
    getAgencies().then((out) => {
      setAgencies(out.data.out)
    })
    getAgency(await window.localStorage.getItem('ntfc_agency'), {}).then((out) => {
      if (out && out.data && out.data.out && out.data.out.length > 0) {
        setAgencyName(`- ${out.data.out[0].agency_name}`);
      }
    })
  }

  return (

    <div>
      <div style={{ height: '8vh', width: '100%', display: 'flex', justifyContent: 'space-between' }}>
        {type === "country" ? <Space>
          <Title level={2} style={{ display: 'flex', alignItems: 'center', margin: 0 }}>Country Wise Dashboard - </Title>
          {/* <Select
            defaultValue="Sri Lanka"
            style={{ width: '10vw' }}
            options={[{ value: 'LK', label: 'Sri Lanka' }, { value: 'US', label: 'United States' }, { value: 'SG', label: 'Singapore' }, { value: 'IN', label: 'India' }]}
            onChange={(val) => setCountry(val)}
          /> */}
          <Flag code={country} width={40} />
        </Space> : null}
        {type === "my" ? <Title level={2} style={{ display: 'flex', alignItems: 'center', margin: 0 }}>My Dashboard</Title> : null}
        {type === "agency" ? <Space>
          <Title level={2} style={{ display: 'flex', alignItems: 'center', margin: 0 }}>Agency Wise Dashboard - </Title>
          {agency && agency > 5 ? <Select
            value={agency}
            onChange={(sel) => setAgency(sel)}
            style={{ width: '30vw' }}
            options={agencies}
          /> : <Title level={2} style={{ display: 'flex', alignItems: 'center', margin: 0 }}>{agencyName}</Title>}
        </Space> : null}

      </div>
      <div style={{ borderBottom: '1px solid #e5e5e5' }}>
        <Space>
          <Segmented
            size='large'
            onChange={(sel) => setType(sel)}
            options={(agency && agency > 3) ? [
              {
                label: 'My Dashboard',
                value: 'my',
                icon: <HomeOutlined />,
              },
              {
                label: 'Country Wise Dashboard',
                value: 'country',
                icon: <GlobalOutlined />,
              },
              {
                label: 'Agency Wise Dashboard',
                value: 'agency',
                icon: <AppstoreOutlined />,
              },
            ] : [
              {
                label: 'My Dashboard',
                value: 'my',
                icon: <HomeOutlined />,
              },
              {
                label: 'Country Wise Dashboard',
                value: 'country',
                icon: <GlobalOutlined />,
              }
            ]}
          />
        </Space>
      </div>
      {type === "country" ? <Country /> : null}
      {type === "my" ? <My /> : null}
      {type === "agency" ? <Agency agency={agency} /> : null}
    </div>
  );
}

export default Page;
